import { handleCategoryChange } from './server';
import * as Sentry from "@sentry/browser";

export function storeUserInfo(userInfo) {
    if (userInfo) {
        localStorage.setItem("user_info", JSON.stringify(userInfo));
        Sentry.setUser({
            'id': userInfo.id,
            'email': userInfo.email
        });
    } else {
        localStorage.removeItem("user_info");
        Sentry.setUser(null);
    }
}

export function getUserInfo() {
    return JSON.parse(localStorage.getItem("user_info")) || {};
}

export function clearUserInfo() {
    for (var key in localStorage){
        if (key.startsWith("user_") || key === "auth_token") {
            localStorage.removeItem(key);
        }
    }
    Sentry.setUser(null);
}

export function storeCompanyInfo(companyInfo) {
    if (companyInfo) {
        localStorage.setItem("company_info", JSON.stringify(companyInfo));
    } else {
        localStorage.removeItem("company_info");
    }
}

export function getCompanyInfo() {
    return JSON.parse(localStorage.getItem("company_info")) || {};
}

export function clearCompanyInfo() {
    localStorage.removeItem("company_info");
    localStorage.removeItem("facility");
    localStorage.removeItem("facilityId");
}

export function storageLogout() {
    clearUserInfo();
    clearCompanyInfo();
}

export function setCategory(facilityLink, categoryId) {
    if (categoryId === "all") {
        localStorage.removeItem(`${facilityLink}:category`);
    } else {
        localStorage.setItem(`${facilityLink}:category`, categoryId);
    }
    handleCategoryChange();
}

export function getCategory(facilityLink) {
    return localStorage.getItem(`${facilityLink}:category`)
}
