import React, { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/classes.scss';
import { serverFetch, serverPost } from '../../helpers/server'
import { BaseContext, currencyFormat, getDateFormatForFacility } from '../../helpers/common'
import BaseContainer from '../../components/Container';
import Loader from '../../components/Loader';
import BaseForm from '../../components/BaseForm';
import ClassSessions from '../../components/ClassSessions';
import { useParams, useSearchParams, Link } from "react-router-dom";
import { Table, Container, Row, Col, Button } from 'react-bootstrap';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import classnames from 'classnames';
import moment from 'moment';
const _ = require("lodash");

const stripePromise = loadStripe("pk_test_O7hFOz62p9Rdhqvery4zRWhv");

function ClassView(props) {
    const { isLoggedIn, userInfo, settings, getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    let facilityNewId = localStorage.getItem("facilityId");
    const [classDetails, setClassDetails] = useState({});
    const [sessions, setSessions] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [loadingParticipants, setLoadingParticipants] = useState(true);
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const classId = searchParams.get("id");

    useEffect(() => {
        let title = getFacilityName() + " - Class";
        if (classDetails.name) {
            title += " - " + classDetails.name;
        }
        document.title = title;
    }, [classDetails]);

    useEffect(() => {
        setLoading(true);
        serverFetch(getApiUrl(`/classes/${classId}`)).then((res) => {
            setClassDetails(res)
            setLoading(false);
        })
    }, [classId]);

    useEffect(() => {
        serverFetch(getApiUrl(`/classes/${classId}/participants`)).then((res) => {
            setParticipants(res)
            setLoadingParticipants(false);
        })
    }, [classId]);

    useEffect(() => {
        // TODO(aswin): Fix this once we have an endpoint to get sessions.
        const searchData = {
            pagination: {
                from: 0,
                limit: 50
            },
            query: {
                groupId: classDetails.groupId
            }
        }
        serverPost(getApiUrl('/bookings/search'), searchData).then(res => {
            setSessions(res.results)
        })
    }, []);

    const renderSessions = (details) => {
        if (_.isEmpty(sessions)) {
            return null;
        }
        const dateFormat = getDateFormatForFacility(settings);
        return (
            <>
                <h3>Sessions</h3>
                <Row>
                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Venue</th>
                            <th>Date/Time</th>
                            {
                                classDetails.priceType != "totalPrice" &&
                                    <th></th>
                            }
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        _.map(sessions, (session, i) =>
                            <tr key={i}>
                                <td>{ i+1 }</td>
                                <td>{ session.venueName }</td>
                                <td>{ moment(session.startTimeLocal).format(dateFormat) + ", " + moment(session.startTimeLocal).format("h:mma") + " - " + moment(session.endTimeLocal).format("h:mma") }</td>
                                {
                                    classDetails.priceType != "totalPrice" &&
                                        <td>{ currencyFormat(session.cost) }</td>
                                }
                                <td>{ session.eventName }</td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
                </Row>
                <br/>
            </>
        )
    }

    return (
        <BaseContainer>
            <Loader loading={loading}>
            {
                () =>
                    <>
                        <div className="content-box">
                            <div className="content-body">
                                <Row>
                                    <Col md="9" className="text-end d-grid align-items-center">
                                    </Col>
                                    <Col md="3" className="text-end">
                                        <Link to={"/" + facilityLink + "/class/edit?id=" + classDetails.id}>
                                            <Button variant="outline-primary"><i className="fa fa-edit"/> Edit</Button>
                                        </Link>
                                    </Col>
                                </Row>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td className="info-title"><strong>Name</strong></td>
                                            <td>{classDetails.name}</td>
                                            <td className="info-title"><strong>Description</strong></td>
                                            <td>{classDetails.description}</td>
                                        </tr>
                                        <tr>
                                            <td className="info-title"><strong>Cost</strong></td>
                                            <td>{currencyFormat(classDetails.postTaxInCents/100)}</td>
                                            <td className="info-title"><strong>Capacity</strong></td>
                                            <td>{classDetails.spotsFilled} / {classDetails.capacity}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        {
                            classDetails.note &&
                                <div dangerouslySetInnerHTML={{__html: classDetails.note }} />
                        }
                        <div className="content-box">
                            <div className="content-body">
                                <ClassSessions classDetails={classDetails} sessions={sessions} />
                            </div>
                        </div>
                    </>
            }
            </Loader>
        </BaseContainer>
    )
}

export default ClassView;
