import '../../App.scss';
import '../../css/modals.scss';
import { getInvoiceExportFields, getInvoiceLineItemExportFields } from '../../helpers/exportFields';
import BaseCSVExportModal from './BaseCSVExportModal';
import {useParams} from "react-router-dom";
import {useContext} from "react";
import {BaseContext} from "../../helpers/common";
const _ = require("lodash");

function InvoicesLineItemExportModal(props) {
    let { facilityLink } = useParams();
    const { settings } = useContext(BaseContext);

    return (
        <BaseCSVExportModal
            {...props}
            fields={getInvoiceLineItemExportFields(settings)}
            filenamePrefix="Invoices-Line-Items"
            cacheKey={facilityLink + "_csv_invoice_line_items"}
            exportUrl={'/invoices/export_line_items'} />
    );

}

export default InvoicesLineItemExportModal;
