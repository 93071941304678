import $ from "jquery";
import 'jquery-ui/ui/widgets/sortable';
import React from 'react';
import { useEffect, useState, useRef, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import AddOrEditVenueCategoryModal from '../../components/modals/AddOrEditVenueCategoryModal';
import EditVenueNameModal from '../../components/modals/EditVenueNameModal';
import Notification from '../../components/Notification';
import BaseForm from '../../components/BaseForm';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import { useSearchParams } from "react-router-dom";
import { serverFetch, serverPatch, notifyEvent, serverDelete } from '../../helpers/server';
import { BaseContext, hasAccess, isPartialOrFullAdmin } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Button, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DeleteButton from "../../components/DeleteButton";
import AddOrEditVenueModal from "../../components/modals/AddOrEditVenueModal";
import { Link } from "react-router-dom";
const _ = require("lodash");

function VenueSort() {
    const { userInfo, getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [ searchParams ] = useSearchParams();
    const canUpdate = hasAccess("settings", userInfo, "update");
    const [isDefault, setIsDefault] = useState((searchParams.get('default') || String(0)) === String(1));

    const [showAddOrEditVenueCategoryModal, setShowAddOrEditVenueCategoryModal] = useState(false);
    const [showEditVenueNameModal, setShowEditVenueNameModal] = useState(false);
    const [showAddOrEditVenueModal, setShowAddOrEditVenueModal] = useState(false);
    const [venueToEdit, setVenueToEdit] = useState(null);
    const originalCategorySortDefault = useRef(null)
    const originalVenueSortDefault = useRef(null)
    const [categoryToEdit, setCategoryToEdit] = useState(null);

    const [settings, setSettings] = useState({});
    const [categories, setCategories] = useState([]);
    const [categoryAddresses, setCategoryAddresses] = useState([]);

    const [venues, setVenues] = useState([]);
    const [venueGroupings, setVenueGroupings] = useState({});
    const [userPreferences, setUserPreferences] = useState({});

    useEffect(() => {
        document.title = "Venue Sort - " + getFacilityName();
    }, []);

    useEffect(() => {
        setIsDefault((searchParams.get('default') || String(0)) === String(1));
    }, [searchParams])

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const venueGroupings = _.groupBy(venues, 'categoryId');
        let sortedVenueIds = !_.isNil(settings) ? String(settings.venueSortDefault).split(",") : [];
        originalCategorySortDefault.current = settings.categorySortDefault;
        originalVenueSortDefault.current = settings.venueSortDefault;
        if (!isDefault) {
            const rinkSort = userInfo.preference ? userInfo.preference.rinkSort: "";
            sortedVenueIds = String(rinkSort).split(",");

            originalCategorySortDefault.current = userPreferences.categorySort;
            originalVenueSortDefault.current = userPreferences.rinkSort;
        }
        _.each(venueGroupings, (venues, category) => {
            const sortedVenues = _.sortBy(venues, (sv) => _.indexOf(sortedVenueIds, String(sv.id)));
            venueGroupings[category] = sortedVenues;
        });
        setVenueGroupings(venueGroupings);
    }, [isDefault, settings, venues]);

    useEffect(() => {
        $(function() {
            $('.sortable-parent').sortable({
                items:'li.parent',
                cursor: 'grabbing',
                stop: function(event, tbody) {
                    const sortedItems = $(this).sortable("toArray");
                    if (!_.isEqual(sortedItems.join(","), originalCategorySortDefault.current)) {
                        const url = isDefault ? "/settings": "/user_preferences";
                        let data = {};
                        if (isDefault) {
                            data = {
                                categorySortDefault: sortedItems.join(","),
                                venueSortDefault: getAllVenuesSort()
                            }
                        } else {
                            data = {
                                categorySort: sortedItems.join(","),
                                rinkSort: getAllVenuesSort()
                            }
                        }
                        serverPatch(getApiUrl(url), data).then((res) => {
                            if (res) {
                                Notification.Show(t('notification.successfully_updated'));
                                notifyEvent("settings");
                                window.location.reload();
                            }
                        })
                    }
                },
            });
            $('.sortable-group').sortable({
                items:'li.child',
                cursor: 'grabbing',
                connectWith: '.sortable-group',
                stop: function(event, tbody) {
                    const venueId = tbody.item.attr('id');
                    const newCategoryId = tbody.item.parent().attr('id');
                    // Check if category id changed
                    const venue = _.find(venues, (v) => String(v.id) === String(venueId));
                    if (String(venue.categoryId) !== String(newCategoryId)) {
                        // Category changed
                        serverPatch(getApiUrl(`/venues/${venue.id}`), { categoryId: newCategoryId }).then((res) => {
                            if (res) {
                                Notification.Show(t('notification.successfully_updated'));
                                notifyEvent("settings");
                                window.location.reload();
                            }
                        })
                    }
                    const allVenueSort = getAllVenuesSort();
                    if (!_.isEqual(allVenueSort, originalVenueSortDefault.current)) {
                        const url = isDefault ? "/settings": "/user_preferences";
                        let data = {};
                        if (isDefault) {
                            data = {
                                venueSortDefault: allVenueSort
                            }
                        } else {
                            data = {
                                rinkSort: allVenueSort
                            }
                        }
                        serverPatch(getApiUrl(url), data).then((res) => {
                            if (res) {
                                Notification.Show(t('notification.successfully_updated'));
                                notifyEvent("settings");
                                originalVenueSortDefault.current = res.venueSortDefault;
                            }
                        })
                    }
                },
            });
        });
    }, [venues, venueGroupings, categories])

    const getAllVenuesSort = () => {
        let allVenueSort = [];
        $('.sortable-group').each(function () {
            let items = $(this).sortable("toArray");
            if(items && items.length > 0) {
                allVenueSort.push(items);
            }
        });
        return allVenueSort.join(",");
    }

    const fetchData = (skipCache = false) => {
        serverFetch(getApiUrl('/venues'), { skipCache, skipAddingCategory: true }).then((res) => {
            setVenues(res);
        })

        serverFetch(getApiUrl(''), { skipCache }).then((res) => {
            setSettings(res);
        })

        serverFetch(getApiUrl('/venue_categories'), { skipCache }).then((res) => {
            setCategories(res);
        })

        serverFetch(getApiUrl('/venue_categories/addresses?includeAll=true'), { skipCache }).then((res) => {
            setCategoryAddresses(res);
        })
    }

    const onModalClose = () => {
        setShowAddOrEditVenueCategoryModal(false);
        setShowEditVenueNameModal(false);
        setShowAddOrEditVenueModal(false);
        fetchData(true);
    }

    const addCategory = () => {
        setCategoryToEdit(null);
        setShowAddOrEditVenueCategoryModal(true);
    }

    const editCategory = (type) => {
        const address = _.find(categoryAddresses, (ca) => ca.categoryId === type.id);
        const typeToEdit = {
            ...address,
            ...type
        }
        setCategoryToEdit(typeToEdit);
        setShowAddOrEditVenueCategoryModal(true);
    }

    const onFieldChange = (name, value) => {
        const data = {};
        data[name] = value;
        serverPatch(getApiUrl('/settings'), data).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
                notifyEvent("settings");
                fetchData(true);
            }
        })
    }

    const editVenueName = (venue) => {
        setVenueToEdit(venue);
        setShowEditVenueNameModal(true);
    }

    const deleteVenue = (venue) => {
        const deleteVenueUrl = getApiUrl(`/venues/${venue.id}`);
        serverDelete(deleteVenueUrl, {}).then((res) => {
            Notification.Show(t('venue.list.successfully_deleted'));
            fetchData(true);
        })
    }

    const deleteCategory = (category) => {
        console.log("Deleting category " + JSON.stringify(category));

        serverDelete(getApiUrl(`/venue_categories/${category.id}`)).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_deleted'));
                notifyEvent("settings");
                fetchData(true);
            }
        })
    }

    const restoreDefault = () => {
        serverFetch(getApiUrl(''), { skipCache: true }).then((res) => {
            setSettings(res);
            const data = {
                categorySort: res.categorySortDefault,
                rinkSort: res.venueSortDefault
            }
            serverPatch(getApiUrl('/user_preferences'), data).then((res1) => {
                if (res1) {
                    Notification.Show(t('venue_sort.successfully_restored'));
                    notifyEvent("settings");
                    window.location.reload();
                }
            })
        })
    }

    const sortedCategoryIds = String(originalCategorySortDefault.current).split(",")
    const sortedCategories = _.sortBy(categories, (ca) => _.indexOf(sortedCategoryIds, String(ca.id)));
    const isPartnerAdmin = settings.isPartnerFacility;
    const isPartnerAdminOrSuperAdmin = userInfo.isSuperAdmin || (settings.isPartnerFacility && isPartialOrFullAdmin(userInfo));
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "venue-sort", { userInfo: userInfo, isPartnerFacility: settings.isPartnerFacility })}/>
            {
                canUpdate &&
                    <div className="content-box">
                        <div className="content-body">
                            <Button variant="outline-primary" onClick={addCategory}><i className="fa fa-circle-plus"/> {t('venue_sort.add_category')}</Button>
                            {
                                !isDefault &&
                                    <Button variant="outline-primary" onClick={restoreDefault}>{t('venue_sort.restore_default')}</Button>
                            }
                            {
                                isPartnerAdmin &&
                                    <Button variant="outline-primary" onClick={() => setShowAddOrEditVenueModal(true)}><i className="fa fa-circle-plus"/> Add Venue</Button>
                            }
                            {
                                isPartnerAdminOrSuperAdmin &&
                                    <Link to={"/" + facilityLink + "/setting/batchimportvenues"}>
                                        <Button variant="outline-primary"><i className="fa fa-circle-plus"/> {t("setting.venue_sort.batch_import")}</Button>
                                    </Link>
                            }
                        </div>
                    </div>
            }
            <div className="content-box">
                <div className="content-body">
                    <BaseForm initialFormFields={settings} onFieldChange={_.debounce(onFieldChange, 300)} readOnly={!canUpdate}>
                        <Row>
                            <BaseForm.Input colSpan="6" name="showAllVenueOption" type="check" label={t('venue_sort.show_all_venue_option')} />
                            <BaseForm.Input colSpan="6" name="allVenuesLabel" type="text" label={t('venue_sort.top_bar_all_venues_label')} />
                        </Row>
                    </BaseForm>
                    <br/>
                    <div className="d-md-none">
                        <p>{t('venue_sort.not_on_mobile')}</p>
                    </div>
                    <ol className="sortable ui-sortable sortable-parent">
                    {
                        _.map(sortedCategories, (category, i) =>
                            <li className="parent" key={i} id={category.id}>
                                <div className="list-header d-flex flex-row gap-12">
                                    <Button variant="alink" className="group-header" onClick={() => editCategory(category)}>{category.categoryName}</Button>
                                    <div className="flex-grow-1"/>
                                    {
                                        !(venueGroupings[category.id] && venueGroupings[category.id].length !== 0) &&
                                        <DeleteButton variant="danger" title="Delete Venue Category?" body="Are you sure you want to delete the venue category?" onDelete={() => deleteCategory(category)} size="sm"></DeleteButton>
                                    }
                                </div>
                                <ol id={category.id} className="sortable-group" data-content={t('venue_sort.drag_and_drop_venue')}>
                                {
                                    _.map(venueGroupings[category.id], (venue, j) =>
                                        <li className="child" key={j} id={venue.id}>
                                            <div className="list-header d-flex gap-12 align-items-center">
                                                <span className="flex-grow-1">{ venue.name }</span>
                                                <span className={canUpdate ? "": "hide"} style={{ cursor: "pointer", marginLeft: "10px" }} onClick={() => editVenueName(venue)}><i className="fa fa-edit"/>&nbsp;{t('common.edit')}</span>
                                                {
                                                    isPartnerAdmin &&
                                                        <DeleteButton className={isPartnerAdmin ? "": "hide"} style={{ cursor: "pointer", marginLeft: "10px" }} onDelete={() => deleteVenue(venue)}/>
                                                }
                                            </div>
                                        </li>
                                    )
                                }
                                </ol>
                            </li>
                        )
                    }
                        <li>
                            <div className="list-header group-header">
                                {t('venue_sort.uncategorized_venues')}
                            </div>
                            <ol id={0} className="sortable-group" data-content={t('venue_sort.drag_and_drop_venue')}>
                            {
                                _.map(venueGroupings[0], (venue, j) =>
                                    <li key={j} className="child" id={venue.id}>
                                        <div className="list-header d-flex gap-12 align-items-center">
                                            <span className="flex-grow-1">{ venue.name }</span>
                                            <span className={canUpdate ? "": "hide"} style={{ cursor: "pointer", marginLeft: "10px" }} onClick={() => editVenueName(venue)}><i className="fa fa-edit"/>&nbsp;{t('common.edit')}</span>
                                            {
                                                isPartnerAdmin &&
                                                <DeleteButton className={isPartnerAdmin ? "": "hide"} style={{ cursor: "pointer", marginLeft: "10px" }} onDelete={() => deleteVenue(venue)}/>
                                            }
                                        </div>
                                    </li>
                                )
                            }
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
            <AddOrEditVenueCategoryModal show={showAddOrEditVenueCategoryModal} onClose={onModalClose} itemToEdit={categoryToEdit} />
            <EditVenueNameModal show={showEditVenueNameModal} onClose={onModalClose} venue={venueToEdit} />
            <AddOrEditVenueModal show={showAddOrEditVenueModal} onClose={onModalClose} itemToEdit={venueToEdit} />
        </BaseContainer>
    );
}

export default VenueSort;
