import '../../App.scss';
import '../../css/modals.scss';
import jQuery from 'jquery';
import 'magicsuggest/magicsuggest.js';
import 'jquery-ui/ui/widgets/autocomplete';
import React, { useEffect, useState, useContext, createRef, useRef } from 'react';
import BookingGroupsSelector from '../BookingGroupsSelector';
import BookingRow from '../BookingRow';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import TabHeader from '../TabHeader';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import SingleSelectDropdown from '../SingleSelectDropdown';
import BookingPaymentInput from '../BookingPaymentInput';
import classnames from 'classnames';
import { useParams } from "react-router-dom";
import { Form, InputGroup, Button, Row, Container, Alert, Col, Table } from 'react-bootstrap';
import { getUrl, serverFetch, serverPost, serverPatch } from '../../helpers/server';
import { getValueFromEvent } from '../../helpers/input';
import { BaseContext, getDefaultColor, getRandomString, currencyFormat, getFilteredPaymentMethods, cyrb53 } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import { dayOptions, timeOptions } from '../../helpers/input'
import moment from 'moment';
const _ = require("lodash");
var cache = require('js-cache');
var calcCostCache = new cache();

function AddOrEditUserBookingModal(props) {
    const { getApiUrl, settings, isLoggedIn, userInfo } = useContext(BaseContext);
    const { facilityLink } = useParams();
    const [venues, setVenues] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [originalVenueId, setOriginalVenueId] = useState(null);
    const [originalVenue, setOriginalVenue] = useState(null);
    const [selectedVenueIds, setSelectedVenueIds] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [repeatEndType, setRepeatEndType] = useState(null);
    const [currentTab, setCurrentTab] = useState("details");
    const [showOverlap, setShowOverlap] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [initialFields, setInitialFields] = useState({});
    const formRef = createRef();
    const rowRef = createRef();
    const paymentRef = createRef();
    const groupSelectorRef = createRef();
    const [targetDate, setTargetDate] = useState(null);
    const [calcCost, setCalcCost] = useState(null);
    const [numberOfBookingRows, setNumberOfBookingRows] = useState(1);
    const [primaryGroup, setPrimaryGroup] = useState(null);
    const [userGroups, setUserGroups] = useState([]);
    const [overlapOptions, setOverlapOptions] = useState([]);
    const [error, setError] = useState(null);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cc");
    const [allowCreditCardBooking, setAllowCreditCardBooking] = useState(false);
    const [allowBookingRequest, setAllowBookingRequest] = useState(false);
    const [allowPayLater, setAllowPayLater] = useState(false);
    const [allowCCRequest, setAllowCCRequest] = useState(false);

    const [bookingSuccess, setBookingSuccess] = useState(null);

    const calcCostKeyInProgress = useRef(null);
    const { t } = useTranslation('common');

    useEffect(() => {
        if (!props.show) {
            return;
        }
        if (!_.isNil(props.booking)) {
            const originalVenueId = props.booking.extendedProps.venueId;
            setOriginalVenueId(originalVenueId);
            setInitialFields({
                "0": {
                    venueId: originalVenueId,
                    start: moment(props.booking.start),
                    end: moment(props.booking.end),
                    startTime: moment(props.booking.start).format("HH:mm:00"),
                    endTime: moment(props.booking.end).format("HH:mm:00"),
                    rate: props.booking.extendedProps.rate,
                    rateType: props.booking.extendedProps.rate !== getDefaultRateForVenue(originalVenueId) ? "custom": "standard",
                    taxRatePercent: getTaxRateForVenue(originalVenueId),
                    bPadding: getDefaultPaddingForVenue(originalVenueId),
                    eventName: props.booking.extendedProps.eventName,
                    description: props.booking.extendedProps.description,
                    bid: props.booking.extendedProps.bid
                },
                groups: props.booking.extendedProps.bookingGroups,
                color: props.booking.extendedProps.color || getDefaultColor(t),
                notes: props.booking.extendedProps.note,
                repeatConfig: props.booking.extendedProps.repeatInfo && {
                    ...props.booking.extendedProps.repeatInfo,
                    type: props.booking.extendedProps.repeatInfo.repeatType,
                    endsAfter: props.booking.extendedProps.repeatInfo.ends === 2 ? props.booking.extendedProps.repeatInfo.occurrence: 0,
                    endDate: props.booking.extendedProps.repeatInfo.ends === 3 ? props.booking.extendedProps.repeatInfo.endDate: 0,
                    on: _.map(props.booking.extendedProps.repeatInfo.repeatOn.split(","), (o) => parseInt(o.trim())),
                }
            });
            setCurrentTab("details");
            setTargetDate(moment(props.booking.start).format("YYYY-MM-DD"));
            setRepeatEndType((props.booking.extendedProps.repeatInfo && props.booking.extendedProps.repeatInfo.ends === 3) ? "date": "counter");
            setNumberOfBookingRows(1);
            setIsEditing(true);
        } else {
            if (_.isEmpty(props.selectionInfo) || _.isNil(props.selectionInfo)) {
                return;
            }
            const originalVenueId = props.selectionInfo && props.selectionInfo.venue && props.selectionInfo.venue.id;
            setOriginalVenueId(originalVenueId);
            setNumberOfBookingRows(1);
            setInitialFields({
                rate: getDefaultRateForVenue(originalVenueId),
                taxRatePercent: getTaxRateForVenue(originalVenueId),
                venueId: originalVenueId,
                startTime: moment(props.selectionInfo.start).format("HH:mm:00"),
                endTime: moment(props.selectionInfo.end).format("HH:mm:00"),
                groups: [],
                color: getDefaultColor(t),
            })
            fetchGroups();
            setTargetDate(moment(props.selectionInfo.start).format("YYYY-MM-DD"));
            setRepeatEndType("counter");
            setCurrentTab("details");
            setIsEditing(false);
        }
    }, [props.selectionInfo, props.booking, props.show]);

    const fetchGroups = () => {
        serverFetch(getApiUrl(`/user_groups`, { userId: userInfo.id })).then((res) => {
            if (res) {
                setUserGroups(res);
                const firstGroup = res.length > 0 ? {...res[0]}: null;
                if (firstGroup) {
                    firstGroup.name = firstGroup.groupName;
                    firstGroup.userEmail = firstGroup.user && firstGroup.user.email;
                    firstGroup.gid = firstGroup.id;
                    setInitialFields(prevFields => {
                        const newFields = {...prevFields};
                        newFields.groups = [firstGroup];
                        return newFields;
                    })
                }
            }
        })
    }

    useEffect(() => {
        if (!props.show) {
            setInitialFields({});
            setNumberOfBookingRows(0);
            setShowOverlap(false);
            setError(null);
            setBookingSuccess(null);
        }
    }, [props.show]);

    useEffect(() => {
        serverFetch(getApiUrl('/venues')).then((res) => {
            setVenues(res);
        })
    }, [isLoggedIn]);

    useEffect(() => {
        let originalVenue = null;
        if (originalVenueId) {
            originalVenue = _.find(venues, (v) => v.id === originalVenueId);
        }
        setOriginalVenue(originalVenue);
    }, [originalVenueId, venues])

    useEffect(() => {
        if (_.isNil(originalVenue)) {
            setAllowCreditCardBooking(false);
            setAllowBookingRequest(false);
            setAllowPayLater(false);
            setAllowCCRequest(false);
            setSelectedPaymentMethod("cc");
        } else {
            const _allowCreditCardBooking = originalVenue && originalVenue.allowCCBooking &&
                (settings.showCreditCardOnline === "yes" || settings.showCreditCardOnline === "userOnly");
            const _allowBookingRequest = originalVenue && (originalVenue.allowBookingRequest !== "no" && originalVenue.allowOnlineBooking !== "");
            const _allowPayLater = originalVenue && originalVenue.allowOnlineBooking === "yes";
            const _allowCCRequest = originalVenue && originalVenue.allowCCBooking;
            setAllowCreditCardBooking(_allowCreditCardBooking);
            setAllowBookingRequest(_allowBookingRequest);
            setAllowPayLater(_allowPayLater);
            setAllowCCRequest(_allowCCRequest);
            if (_allowCreditCardBooking) {
                setSelectedPaymentMethod("cc")
            } else if (_allowBookingRequest) {
                setSelectedPaymentMethod("request");
            } else if (_allowPayLater) {
                setSelectedPaymentMethod("later");
            }
        }
    }, [originalVenue])

    useEffect(() => {
        if (_.isNil(originalVenueId)) {
            setEventTypes([]);
            return;
        }
        serverFetch(getApiUrl('/event_types', { venueIds: [originalVenueId] }), { skipAddingCategory: true }).then((res) => {
            setEventTypes(res);
        });
    }, [originalVenueId]);

    useEffect(() => {
        if (primaryGroup) {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields['color'] = primaryGroup.customerColor || getDefaultColor(t);
                return newInitialFields;
            });
        }
    }, [primaryGroup]);

    useEffect(() => {
        updateCalcCostIfNeeded("initialFields");
    }, [initialFields, selectedPaymentMethod])

    const getTaxRateForVenue = (venueId) => {
        if (_.isNil(venueId)) {
            return 0;
        } else {
            const svenue = _.find(venues, v => v.id === venueId);
            if (svenue.overrideTax) {
                return svenue.tax1;
            } else {
                return settings.tax1;
            }
        }
    }

    const getDefaultRateForVenue = (venueId) => {
        if (_.isNil(venueId)) {
            return 0;
        } else {
            const svenue = _.find(venues, v => v.id === venueId);
            return svenue.defaultRate;
        }
    }

    const getDefaultPaddingForVenue = (venueId) => {
        if (_.isNil(venueId)) {
            return 0;
        } else {
            const svenue = _.find(venues, v => v.id === venueId);
            return svenue.defaultPadding;
        }
    }

    const venueOptions = _.map(venues, (venue, i) => { return { 'value': venue.id, 'label': venue.name } });
    const eventTypeOptions = _.map(eventTypes, (type, i) => { return { 'value': type.id, 'label': type.eventType } });

    const onFieldChange = (name, value) => {
        if (name === "groups") {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields["groups"] = value;
                return newInitialFields;
            });
            if (_.isEmpty(value)) {
                setPrimaryGroup(null);
            } else {
                setPrimaryGroup(value[0]);
            }
        } else if (name === "startTime") {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields["startTime"] = value;
                return newInitialFields;
            });
        } else if (name === "policyAgree") {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields["policyAgree"] = value;
                return newInitialFields;
            });
        } else if (name === "cc_name") {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields["cc_name"] = value;
                return newInitialFields;
            });
        } else if (name === "eventTypeId") {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields["eventTypeId"] = value;
                return newInitialFields;
            });
        } else if (_.includes(["eventName", "description"], name)) {
            setInitialFields(prevInitialFields => {
                prevInitialFields[name] = value;
                return prevInitialFields;
            });
        } else if (name === "endTime") {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields["endTime"] = value;
                return newInitialFields;
            });
        } else if (name === "save_card") {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields["save_card"] = value;
                return newInitialFields;
            });
        }
        if (_.isNil(targetDate)) {
            return;
        }
        if (rowRef.current) {
            rowRef.current.onFieldChange(name, value);
        }
        if (paymentRef.current) {
            paymentRef.current.onFieldChange(name, value);
        }
        if (groupSelectorRef.current) {
            groupSelectorRef.current.onFieldChange(name, value);
        }
        updateCalcCostIfNeeded("onFieldChange");
    }

    const updateSelectedVenueIds = () => {
        if (!formRef.current) {
            return;
        }
        const formData = formRef.current.getFormData();
        const hasData = _.every(_.range(numberOfBookingRows), (r) => {
            return _.has(formData, String(r));
        });
        if (!hasData) {
            return;
        }
        setSelectedVenueIds(_.map(_.range(numberOfBookingRows), (r) => formData[String(r)].venueId));
    }

    const isOnlineBookingActive =  allowBookingRequest || allowPayLater || allowCCRequest;
    const canBookingWithoutRequest = allowCreditCardBooking || allowPayLater;

    const updateCalcCostIfNeeded = (source) => {
        if (!formRef.current) {
            return;
        }
        const formData = formRef.current.getFormData();
        let startTime = moment(targetDate + " " + formData.startTime);
        let endTime = moment(targetDate + " " + formData.endTime);
        if (endTime.isBefore(startTime)) {
            endTime = moment(targetDate + " " + formData.endTime).add(1, 'day');
        }
        let pMethod = "unpaid";
        if (isOnlineBookingActive && !canBookingWithoutRequest) {
            pMethod = "request";
        } else if (isOnlineBookingActive && selectedPaymentMethod === "cc") {
            pMethod = "stripe";
        }

        const bookingRequests = [{
            startTimeLocal: startTime.utcOffset(0, true).format(),
            endTimeLocal: endTime.utcOffset(0, true).format(),
            rateType: "standard",
            venueId: formData.venueId,
            eventTypeId: formData.eventTypeId,
            rateAmount: 0,
            taxRatePercent: isSimpleUserBooking ? getTaxRateForVenue(formData.venueId) : formData.taxRatePercent
        }]
        const calcData = {
            primaryGroupId: (formData.groups && !_.isEmpty(formData.groups)) ? formData.groups[0].gid : null,
            bookingRequests: _.filter(bookingRequests, (r) => r !== null),
            paymentMethod: pMethod
        }

        const calcKey = cyrb53(JSON.stringify(calcData))
        if (calcCostKeyInProgress.current === calcKey) {
            // A request is already in progress with the same params so don't do anything.
            return;
        }
        calcCostKeyInProgress.current = calcKey;
        const existingValue = calcCostCache.get(calcKey);
        if (existingValue) {
            setCalcCost(existingValue);
        } else if (isOnlineBookingActive) {
            serverPost(getApiUrl("/bookings/calc_cost"), calcData).then((res) => {
                if (res) {
                    calcCostCache.set(calcKey, res, 30000);
                    setCalcCost(res);
                }
                calcCostKeyInProgress.current = null;
            });
        }
    }

    useEffect(() => {
        if (calcCost) {
            setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields["costDescription"] = `${ currencyFormat(calcCost.preTax) } + ${ currencyFormat(calcCost.taxAmount) }` + ((_.includes(settings.creditCardFeeUsage, "booking") && calcCost.fees) ? ` + ${ currencyFormat(calcCost.fees) }` : ``) + ` = ${ currencyFormat(calcCost.total) }`;
                newInitialFields.rate = calcCost.calc[0].rate;
                return newInitialFields;
            })
        }
    }, [calcCost]);

    const onError = async (response, errorMessage) => {
        if (!_.isEmpty(errorMessage.overlap)) {
            Notification.ModalShow("Please select options for the following overlaps");
            setShowOverlap(true);
            setOverlapOptions(errorMessage.overlap);
            setCurrentTab("overlap");
        } else {
            errorMessage.groups = errorMessage.groupIds;
            setError(errorMessage);
        }
    }

    const addBooking = async (data) => {
        const formData = formRef.current.getFormData();
        const start = moment(targetDate + " " + formData.startTime).utcOffset(0, true);
        const end = moment(targetDate + " " + formData.endTime).utcOffset(0, true);
        const numberOfMinutes = (end-start)/1000/60;

        const venue = _.find(venues, (v) => String(v.id) === String(formData.venueId));
        if ((numberOfMinutes < venue.minUserBooking) || (numberOfMinutes > venue.maxUserBooking)) {
            Notification.ModalShow(`Bookings require a minimum of ${venue.minUserBooking} minutes or a maximum of ${venue.maxUserBooking} minutes.`);
            return;
        }

        let bookings = []
        if (isSimpleUserBooking) {
            bookings.push({
                startTimeLocal: moment(targetDate + " " + formData.startTime).utcOffset(0, true).format(),
                endTimeLocal: moment(targetDate + " " + formData.endTime).utcOffset(0, true).format(),
                rateType: (calcCost && calcCost.calc.length > 0 && calcCost.calc[0].rateType) || "standard",
                venueId: formData.venueId,
                rate: (calcCost && calcCost.calc.length > 0 && calcCost.calc[0].rate) || formData.rate,
                eventTypeId: !_.isEmpty(eventTypes) && eventTypes[0].id,
                description: formData.description,
                eventName: formData.eventName,
                taxRate: getTaxRateForVenue(formData.venueId) || formData.taxRatePercent || "0"
            })
        } else {
            bookings.push({
                startTimeLocal: moment(targetDate + " " + formData.startTime).utcOffset(0, true).format(),
                endTimeLocal: moment(targetDate + " " + formData.endTime).utcOffset(0, true).format(),
                rateType: (calcCost && calcCost.calc.length > 0 && calcCost.calc[0].rateType) || "standard",
                venueId: formData.venueId,
                rate: formData.rate,
                eventTypeId: formData.eventTypeId,
                description: formData.description,
                eventName: formData.eventName,
                taxRate: formData.taxRatePercent || "0"
            })
        }

        let bPaymentMethod = "unpaid";
        if (selectedPaymentMethod === "cc") {
            bPaymentMethod = "stripe";
        } else if (selectedPaymentMethod === "request") {
            bPaymentMethod = "request";
        } else if (selectedPaymentMethod === "later") {
            bPaymentMethod = "unpaid";
        }
        let bookingPaymentMethod = null;
        if (paymentRef.current) {
            bookingPaymentMethod = await paymentRef.current.getPaymentMethod(formData);
            if (!bookingPaymentMethod) {
                return;
            }
        }
        let bookingColor = data.color;
        if (data.groups.length > 0) {
            bookingColor = data.groups[0].customerColor;
        }
        // if (!bookingColor) {
        //     bookingColor = getDefaultColor(t);
        // }
        const bookingData = {
            groupIds: _.map(data.groups, (g) => g.gid || g.groupId),
            bookings: bookings,
            bookingColor: bookingColor,
            notifyUser: data.notifyUser,
            paymentMethod: bPaymentMethod,
            taxAmount: calcCost.taxAmount,
            preTax: calcCost.preTax,
            total: calcCost.total,
        };
        if (showOverlap) {
            bookingData["overlapChoices"] = _.map(overlapOptions, (op) => {
                return {
                    overlapStartTime: op.overlapStartTimeLocal,
                    cancelBookingId: op.bookingId,
                    choice: op.choice
                }
            })
        }
        if (bookingPaymentMethod) {
            if (settings.paymentProcessor === "STRIPE") {
                bookingData["paymentData"] = {
                    stripe: bookingPaymentMethod
                }
                bookingData["paymentMethod"] = "stripe";
            } else if (settings.paymentProcessor === "BEANSTREAM") {
                bookingData["paymentData"] = {
                    bambora: {
                        ...bookingPaymentMethod,
                    }
                }
                bookingData["paymentMethod"] = "bambora";
            } else if (settings.paymentProcessor === "SPORTSPAY") {
                bookingData["paymentData"] = {
                    sportsPay: {
                        ...bookingPaymentMethod,
                    }
                }
                bookingData["paymentMethod"] = "sportspay";
            }
        }
        // console.log("The booking data is " + JSON.stringify(bookingData));
        setError(null);
        if (isEditing) {
            const result = await serverPatch(getApiUrl(`/bookings/${bookingData.booking.id}`), bookingData);
            if (result) {
                props.onClose(true);
            }
        } else {
            const result = await serverPost(getApiUrl('/bookings'), bookingData, {}, onError);
            if (result) {
                setShowOverlap(false);
                setBookingSuccess(result);
                setCurrentTab("success");
            }
        }
    }

    const onEndChange = (event) => {
        event.stopPropagation();
        setRepeatEndType(event.target.value);
    }

    const onAddNewBookingRow = (newVenue=null) => {
        setNumberOfBookingRows(numberOfBookingRows + 1);
        setInitialFields(prevInitialFields => {
            const newInitialFields = {...prevInitialFields};
            const venueId = _.isNil(newVenue) ? originalVenueId : newVenue.id;
            newInitialFields[String(numberOfBookingRows)] = {
                venueId: venueId,
                start: moment(props.selectionInfo.start),
                end: moment(props.selectionInfo.end),
                startTime: moment(props.selectionInfo.start).format("HH:mm:00"),
                endTime: moment(props.selectionInfo.end).format("HH:mm:00"),
                rate: getDefaultRateForVenue(venueId),
                rateType: "standard",
                taxRatePercent: getTaxRateForVenue(venueId),
                bPadding: getDefaultPaddingForVenue(venueId)
            }
            return newInitialFields;
        })
    }

    const renderPolicy = () => {
        if (originalVenue) {
            return (
                <Row className={classnames(currentTab === "policy" ? "": "hide")}>
                    <div dangerouslySetInnerHTML={ { __html: originalVenue.rentalPolicy } } />
                </Row>
            );
        } else {
            return null;
        }
    };

    const renderSuccess = () => {
        if (_.isNil(bookingSuccess)) {
            return null;
        }
        const bookings = _.filter(bookingSuccess, (b) => !b.isMargin && _.isNil(b.blockedById));
        const booking = !_.isEmpty(bookings) ? bookings[0]: null;
        if (_.isNil(booking)) {
            return null;
        }
        const isRequest = booking.paymentMethod === "request";
        return (
            <Row className={classnames(currentTab === "success" ? "": "hide")}>
                <div className="booking-success">
                    <i className="fa fa-check-circle fa-c-success fa-8x"/>
                    {
                        isRequest ?
                            <>
                            <span style={{ fontSize: "40px" }} className="gray">REQUEST SUCCESSFUL</span>
                            <h3 className="gray">REQUEST #{ booking.id }</h3>
                            <Alert variant="success" style={{ fontSize: "16px" }}><i className="fa fa-envelope"/> Request details will be sent to your email</Alert>
                            <Button variant="primary" onClick={() => props.onClose(true)}><span style={{ fontSize: "16px" }} >MAKE ANOTHER REQUEST</span></Button>
                        </>
                        : <>
                            <span style={{ fontSize: "40px" }} className="gray">BOOKING SUCCESSFUL</span>
                            <h3 className="gray">BOOKING #{ booking.id }</h3>
                            <Alert variant="success" style={{ fontSize: "16px" }}><i className="fa fa-envelope"/> Booking details will be sent to your email</Alert>
                            <Button variant="primary" onClick={() => props.onClose(true)}><span style={{ fontSize: "16px" }} >MAKE ANOTHER BOOKING</span></Button>
                        </>
                    }
                </div>
            </Row>
        );
    };

    const onOverlapChoiceSelected = (i, choice) => {
        setOverlapOptions(prevOptions => {
            const newOptions = {...prevOptions};
            newOptions[i].choice = choice;
            return newOptions;
        });
    }

    const onCancelOverlap = () => {
        setOverlapOptions([]);
        setShowOverlap(false);
        setCurrentTab("details");
    }

    const renderOverlap = () => {
        const overlapChoices = [
            { id: "yes" , label: "Yes" },
            { id: "no" , label: "No" },
            { id: "cancel" , label: "Cancel" },
            { id: "cancelNotify" , label: "Cancel & Notify" },
        ];
        return (
            <Row className={classnames(currentTab === "overlap" ? "": "hide")}>
                <Col md="12">
                    <Button variant="alink" onClick={onCancelOverlap}>Cancel & Back to Booking</Button>
                </Col>
                <Table>
                    <thead>
                        <tr>
                            <th>Overlap</th>
                            <th>Group</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Venue</th>
                            <th>Timeslot</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        _.map(overlapOptions, (op, i) =>
                            <tr key={i}>
                                <td>
                                    <SingleSelectDropdown items={overlapChoices} showSearch={false}
                                        selectedId={overlapChoices[i].choice || (settings.overlapDefaultNo ? "no": "yes")}
                                        onSelect={(choice) => onOverlapChoiceSelected(i, choice)} />
                                </td>
                                <td>{ op.groupName }</td>
                                <td>{ op.name }</td>
                                <td>{ op.phone }</td>
                                <td>{ op.email }</td>
                                <td>{ op.venueName }</td>
                                <td>{ op.startTimeLocal }</td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </Row>
        );
    };

    const renderSimpleBookingDetails = () => {
        return (
            <Row>
                <Col md="6">
                    <Button variant="alink" className="btn-complete skinny" onClick={() => Notification.ModalShow("Please select another venue from the calendar.")}>
                        <Row>
                            <BaseForm.Input name={"venueId"} label={t('forms.venue')} type="select" options={venueOptions} readOnly={true} />
                        </Row>
                    </Button>
                </Col>
                <Col md="6">
                    <BaseForm.InputGroup disabled={props.disabled} >
                        <BaseForm.SingleSelect name="startTime" options={timeOptions()} label="Start" disabled={props.disabled} />
                        <BaseForm.Divider/>
                        <BaseForm.SingleSelect name="endTime" options={timeOptions()} label="End" disabled={props.disabled} />
                    </BaseForm.InputGroup>
                </Col>
            </Row>
        )
    }

    const renderNormalBookingDetails = () => {
        return (
            <>
                <BookingRow ref={rowRef} eventTypes={eventTypes}
                    isAdminBooking={false}
                    showAddNew={false} canDelete={false}
                    usingCustomRate={false}
                />
            </>
        )
    }

    const isRepeating = (!_.isNil(props.booking) && !_.isNil(props.booking.extendedProps)) ? props.booking.extendedProps.isRepeat : false;
    const tabItems = [
        { value: "details", label: t('tabs.user_booking_modal.details'), icon: "fa-book", active: currentTab === "details", onClick: () => setCurrentTab("details"), disabled: !_.isNil(bookingSuccess) },
        { value: "policy", label: t('tabs.user_booking_modal.policy'), icon: classnames("fa-file-text"), active: currentTab === "policy", onClick: () => setCurrentTab("policy"), disabled: !_.isNil(bookingSuccess) },
        showOverlap && { value: "overlap", label: t('tabs.user_booking_modal.overlap'), icon: classnames("fa-comment", !_.isNil(initialFields.notes) ? "fa-c-success": ""), active: currentTab === "overlap", onClick: () => setCurrentTab("overlap") },
        !_.isNil(bookingSuccess) && { value: "success", label: t('tabs.user_booking_modal.success'), icon: classnames("fa-circle-check", "fa-c-success"), active: currentTab === "success", onClick: () => setCurrentTab("success") },
    ];

    const filteredPaymentMethods = getFilteredPaymentMethods(paymentMethods, settings, originalVenue, "user");
    const modalTitle = isEditing ? t('user_booking_modal.edit_booking'): t('user_booking_modal.new_booking');
    const isSimpleUserBooking = originalVenue ? originalVenue.simpleUserBook: false;

    return (
        <BaseModal {...props} size="lg">
        {
            () =>
                <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={addBooking} ref={formRef} errorFields={error}>
                    <BaseModal.Header>
                        <BaseModal.Title>{modalTitle} - <span className="pop-date">{moment(targetDate).format("ddd, MMM DD, YYYY")}</span></BaseModal.Title>
                    </BaseModal.Header>
                    <TabHeader items={tabItems} hideMore={true}/>
                    <BaseModal.Body>
                        <div className={classnames(currentTab === "details" ? "": "hide")}>
                            <BookingGroupsSelector
                                ref={groupSelectorRef}
                                name="groups"
                                label={t('forms.group_selection')}
                                required
                                max={1}
                                showGroupDetailsList={false}
                                preloadedGroups={userGroups}
                                disabled={originalVenue && (!originalVenue.usersToSelectGroups || !isOnlineBookingActive || isSimpleUserBooking) && userGroups.length <= 1}
                            />
                            { isSimpleUserBooking ? renderSimpleBookingDetails(): renderNormalBookingDetails() }
                            {
                                originalVenue && originalVenue.userCustomMsg &&
                                    <Alert variant="success"><i className="fa fa-bullhorn"/> { originalVenue.userCustomMsg }</Alert>
                            }
                            {
                                !isOnlineBookingActive &&
                                    <Alert variant="danger"><i className="fa fa-bullhorn"/> {t('user_booking_modal.online_booking_not_active')}</Alert>
                            }
                            {
                                isOnlineBookingActive &&
                                    <Row className="booking-actions">
                                        {
                                            allowCreditCardBooking &&
                                                <Col md="6" className={classnames("cc-input", selectedPaymentMethod === "cc"? "selected": "unselected")}>
                                                    <Button variant="link" className="skinny" onClick={() => setSelectedPaymentMethod("cc")}>
                                                        <span style={{ fontSize: "28px", margin:"0px" }}>{t('common.credit_card')}</span>
                                                        &nbsp;&nbsp;
                                                        <i className="fa fa-brands fa-cc-visa"/>&nbsp;&nbsp;
                                                        <i className="fa fa-brands fa-cc-mastercard"/>&nbsp;&nbsp;
                                                        <i className="fa fa-brands fa-cc-amex"/>&nbsp;&nbsp;
                                                        <i className="fa fa-brands fa-cc-discover"/>&nbsp;&nbsp;
                                                    </Button>
                                                </Col>
                                        }
                                        {
                                            allowBookingRequest &&
                                                <Col md="3" className={selectedPaymentMethod === "request"? "selected": "unselected"}>
                                                    <Button variant="danger" className="btn-complete" onClick={() => setSelectedPaymentMethod("request")}><i className="fa fa-flag" /> {t('user_booking_modal.request')}</Button>
                                                </Col>
                                        }
                                        {
                                            allowPayLater &&
                                                <Col md="3" className={selectedPaymentMethod === "later"? "selected": "unselected"}>
                                                    <Button variant="primary" className="btn-complete" onClick={() => setSelectedPaymentMethod("later")}><i className="fa fa-star" /> {t('user_booking_modal.pay_later')}</Button>
                                                </Col>
                                        }
                                        {
                                            selectedPaymentMethod === "cc" &&
                                                <Col md="!2">
                                                    <BookingPaymentInput
                                                        ref={paymentRef} prefix="ccOnline"
                                                        groupId={!_.isEmpty(initialFields.groups)? initialFields.groups[0].gid: null}
                                                        setInitialFields={setInitialFields}
                                                        total={calcCost && calcCost.total}
                                                    />
                                                    {
                                                        !_.isNil(error) && !_.isNil(error.card) &&
                                                        <span className="form-error-message">{ error.card }</span>
                                                    }
                                                </Col>
                                        }
                                    </Row>
                            }
                            {
                                selectedPaymentMethod === "request" &&
                                    <div>
                                        <br/>
                                        <Alert variant="danger"><i className="fa fa-flag"/> {t('user_booking_modal.request_approval_message')}</Alert>
                                    </div>
                            }
                        </div>
                        { renderOverlap() }
                        { renderPolicy() }
                        { renderSuccess() }
                    </BaseModal.Body>
                    {
                        isOnlineBookingActive && currentTab !== "success" &&
                            <BaseModal.Footer>
                                <Container>
                                    <Row>
                                        {
                                            !isSimpleUserBooking ?
                                                <BaseForm.Input colSpan="4" name="costDescription" label={`${t('booking_modal.total_cost')}${(selectedPaymentMethod === "cc" && calcCost && calcCost.fees) ? ` (${t('booking_modal.cost')} + ${t('booking_modal.tax')} + ${settings.creditCardFeeLabel})` : ``}`} type="text" readOnly={true} />
                                            : <Col md="4"/>
                                        }
                                        <BaseForm.Input
                                            colSpan={isSimpleUserBooking ? "10": "6"} name="policyAgree"
                                            inputWrapperClassName={"d-flex align-items-center"}
                                            label={
                                                <span>{t('user_booking_modal.agree_policy')} <Button variant="alink" className="skinny" onClick={() => setCurrentTab("policy")}>{t('user_booking_modal.rental_policy')}</Button></span>
                                            } type="check" validationLabel={t('user_booking_modal.rental_policy_label')}
                                            validations={{ isChecked: true }}
                                        />
                                        <div className="col-md-2 d-flex align-items-center">
                                            <SubmitButton disabled={false && _.isEmpty(calcCost)} className="btn-complete">{t('user_booking_modal.book_now')}</SubmitButton>
                                        </div>
                                    </Row>
                                </Container>
                            </BaseModal.Footer>
                    }
                </BaseForm>
            }
        </BaseModal>
    );

}

export default AddOrEditUserBookingModal;
