import '../../App.scss';
import ClassesHeader from './ClassesHeader';
import ClassesFooter from './ClassesFooter';
import classnames from 'classnames';
import { Container } from 'react-bootstrap';

function ClassesContainer(props) {
    return (
        <div className={classnames(props.className, "classes-container")}>
            <Container>
            <ClassesHeader {...props} />
            { props.children }
            <ClassesFooter {...props} />
            </Container>
        </div>
    );
}

export default ClassesContainer;
