import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import { serverPost } from '../../helpers/server';
import { currencyFormat, BaseContext } from '../../helpers/common';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import moment from 'moment';
const _ = require("lodash");

function AgedReport() {
    const { getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');

    const [invoiceFilter, setInvoiceFilter] = useState("yes");
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [totals, setTotals] = useState({});

    useEffect(() => {
        document.title = "Invoice Aging Report - " + getFacilityName();
    }, []);

    useEffect(() => {
        const data = {
            includeInvoices: invoiceFilter === "yes"
        };
        setLoading(true);
        serverPost(getApiUrl(`/invoices/aged_report`), data).then((res) => {
            setLoading(false);
            if (res) {
                setInvoices(res.invoices);
                setGroups(res.groups);
                setTotals(res.totals);
            }
        })
    }, [invoiceFilter]);

    const invoiceFilterOptions = [
        { 'id': 'yes', 'label': t("invoice.list.with_invoices")},
        { 'id': 'no', 'label': t("invoice.list.without_invoices")},
    ];

    const onPrint = () => {
        window.print();
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "invoices")} />
            <div className="content-box noprint">
                <div className="content-body">
                    <div className="d-flex flex-row">
                        <div className="flex-grow-1">
                            <div className="inline">
                                <SingleSelectDropdown className="inline" items={invoiceFilterOptions} selectedId={invoiceFilter} onSelect={setInvoiceFilter} />
                            </div>
                        </div>
                        <Button variant="outline-primary" onClick={onPrint}><i className="fa fa-edit fa-small"/> {t("common.print")}</Button>
                    </div>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <div className="showprint">
                        <h3>{`${ getFacilityName() } - AR Aging Summary`}</h3>
                        <h4>{`Date: ${ moment().format("MMMM D, YYYY") }`}</h4>
                    </div>
                    <Loader loading={loading}>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t('common.today')}</th>
                                    <th>{t('invoice.aged_report.n_days', { days: 30 })}</th>
                                    <th>{t('invoice.aged_report.n_days', { days: 60 })}</th>
                                    <th>{t('invoice.aged_report.n_days', { days: 90 })}</th>
                                    <th>{t('invoice.aged_report.n_days', { days: "90+" })}</th>
                                    <th>{t('common.total')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    totals &&
                                        <tr>
                                            <td><strong>{t('invoice.aged_report.account_receivable')}</strong></td>
                                            <td><strong>{ currencyFormat(totals['current']) }</strong></td>
                                            <td><strong>{ currencyFormat(totals['within30Days']) }</strong></td>
                                            <td><strong>{ currencyFormat(totals['within60Days']) }</strong></td>
                                            <td><strong>{ currencyFormat(totals['within90Days']) }</strong></td>
                                            <td><strong>{ currencyFormat(totals['moreThan90Days']) }</strong></td>
                                            <td><strong>{ currencyFormat(totals['total']) }</strong></td>
                                        </tr>
                                }
                                {
                                    _.map(groups, (group, i) =>
                                        <GroupAgedReport facilityLink={facilityLink} key={i} group={group} invoices={_.filter(invoices, (inv) => inv.groupId === group.groupId)} />
                                    )
                                }
                            </tbody>
                        </Table>
                    </Loader>
                </div>
            </div>
        </BaseContainer>
    );
}

function GroupAgedReport(props) {

    return (
        <>
            <tr>
                <td><Link to={`/${props.facilityLink}/invoice/list?groupId=${props.group.groupId}`}><strong>{ props.group.groupName }</strong></Link></td>
                <td><strong>{ currencyFormat(props.group['current']) }</strong></td>
                <td><strong>{ currencyFormat(props.group['within30Days']) }</strong></td>
                <td><strong>{ currencyFormat(props.group['within60Days']) }</strong></td>
                <td><strong>{ currencyFormat(props.group['within90Days']) }</strong></td>
                <td><strong>{ currencyFormat(props.group['moreThan90Days']) }</strong></td>
                <td><strong>{ currencyFormat(props.group['total']) }</strong></td>
            </tr>
            {
                _.map(props.invoices, (invoice, j) =>
                     <tr key={j}>
                        <td><Link to={`/${props.facilityLink}/invoice/view?uuid=${invoice.invoiceUUID}`}>{ invoice.invoiceNumber }</Link></td>
                        <td>{ currencyFormat(invoice['current']) }</td>
                        <td>{ currencyFormat(invoice['within30Days']) }</td>
                        <td>{ currencyFormat(invoice['within60Days']) }</td>
                        <td>{ currencyFormat(invoice['within90Days']) }</td>
                        <td>{ currencyFormat(invoice['moreThan90Days']) }</td>
                        <td>{ currencyFormat(invoice['total']) }</td>
                    </tr>
                )
            }
        </>
    )
}

export default AgedReport;
