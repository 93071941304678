import '../../App.scss';
import '../../css/modals.scss';
import BaseForm from '../BaseForm';
import Notification from '../Notification';
import { useTranslation } from 'react-i18next';
import {Col, Row} from 'react-bootstrap';
import { getUrl, serverPost } from '../../helpers/server';
import BaseModal from "./BaseModal";
import SubmitButton from "../SubmitButton";
import {useContext, useState} from "react";
import {BaseContext} from "../../helpers/common";

function AddOrEditLeagueAppsSiteModal(props) {
    const { t } = useTranslation("common");
    const { getApiUrl } = useContext(BaseContext);
    const [errorFields, setErrorFields] = useState(null);

    const onError = (response, errorMessage) => {
        setErrorFields(errorMessage)
    }

    const addSite = async (data) => {
        setErrorFields(null);
        const url = getApiUrl(`/partner_mappings`);
        const formData = new FormData();
        formData.append("clientId", data.clientId);
        formData.append("siteId", data.siteId);
        formData.append("p12File", data.p12File);
        const res = await serverPost(url, formData, { contentType: "multipart/form-data" }, onError)
        if (res) {
            Notification.Show("LeagueApps site successfully connected");
            props.onClose(true);
        }
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Connect to LeagueApps Site</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm onFieldChange={props.onFieldChange} onSubmit={addSite} errorFields={errorFields}>
                <BaseModal.Body>
                    <Row>
                        <BaseForm.Input colSpan="12" name="clientId" label="Client ID" type="text" required />
                        <BaseForm.Input colSpan="12" name="siteId" label="Site ID" type="text" required/>
                        <BaseForm.Input
                            colSpan="12" name="p12File" label="P12 File" type="file" rawFile required
                            accept={"application/x-pkcs12"} hidePreview fileType="siteLicense"
                        />
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">Connect Site</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default AddOrEditLeagueAppsSiteModal;
