import '../App.scss';
import { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
const _ = require("lodash");

function NoPermissionComponent(props) {
    const navigate = useNavigate();
    let { facilityLink } = useParams();
    const [title, setTitle] = useState("Oops! You don't have permission to access settings for the facility.");

    useEffect(() => {
        if (!_.isNil(props.title)) {
            setTitle(props.title);
        }
    }, [props.title]);

    return (
        <div className="content-box">
            <div className="content-body tall">
                <Row>
                    <Col md="12" className="text-center">
                        <h2 className="title">{title}</h2>
                        <br/>
                    </Col>
                    <Col md="12" className="text-center">
                        <p>Please reach out to your facility admin to provide you with appropriate permissions.</p>
                        <br/>
                    </Col>
                    <Col md="12" className="text-center">
                        <Button variant="outline-primary" onClick={() => navigate(-1)}>Back to Previous Page</Button>
                        <Button variant="success" href={`/${facilityLink}`}>Visit Main Page</Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default NoPermissionComponent;
