import '../../App.scss';
import '../../css/modals.scss';
import { useState, useEffect, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Button, ButtonGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { serverPost, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { timeOptions } from '../../helpers/input';
import moment from 'moment';
const _ = require("lodash");

function AddBookingLockerModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [venue, setVenue] = useState(null);
    const [numRows, setNumRows] = useState(1);
    const [initialFields, setInitialFields] = useState({
        items: [{}]
    });

    useEffect(() => {
        if (props.show) {
            setVenue(props.venue);
            if (props.venue) {
                setInitialFields({
                    items: [{
                        startTime: props.venue.dayStart,
                        endTime: props.venue.dayEnd
                    }]
                })
            } else {
                setInitialFields({
                    items: [{}]
                });
            }
        } else {
            setInitialFields({ items:[] });
        }
    }, [props.show, props.venue])

    const addRow = (event) => {
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            const lastItem = newFields.items[newFields.items.length - 1];
            newFields.items.push({
                startTime: lastItem.endTime,
                endTime: venue.dayEnd
            })
            return newFields;
        })
    }

    const addItem = (item) => {
        const data = {
            'startTimeLocal': moment(props.date + " " + item.startTime).utcOffset(0, true).format(),
            'endTimeLocal': moment(props.date + " " + item.endTime).utcOffset(0, true).format(),
            'venueId': venue.id,
            'groupName1': "",
            'groupName2': "",
        }
        return serverPost(getApiUrl(`/booking_lockers`), data)
    }

    const addItems = async (itemFields) => {
        const results = await Promise.all(_.map(itemFields.items, (item, i) => addItem(item)));
        props.onClose();
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Add New Row</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm onSubmit={addItems} initialFormFields={initialFields}>
                <BaseModal.Body>
                    {
                        _.map(initialFields.items, (item, i) =>
                            <Row key={i}>
                                <BaseForm.Input colSpan={6} name={"items."+String(i)+".startTime"} label="Start Time" type="select" options={timeOptions(5)} showAll={false} required />
                                <BaseForm.Input colSpan={6} name={"items."+String(i)+".endTime"} label="End Time" type="select" options={timeOptions(5)} showAll={false} required />
                            </Row>
                        )
                    }
                    <div className="d-flex flex-row justify-content-end">
                        <Button variant="primary" onClick={addRow}><i className="fa fa-plus"/> New Row</Button>
                    </div>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <ButtonGroup>
                        <SubmitButton variant="primary">{`Add Rows`}</SubmitButton>
                    </ButtonGroup>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default AddBookingLockerModal;
