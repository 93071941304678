import '../App.scss';
import { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react';
import { serverFetch, serverPost } from '../helpers/server';
import { BaseContext, getFormattedName } from '../helpers/common';
import { Row, Col, Form } from 'react-bootstrap';
import BaseForm, { BaseFormContext } from './BaseForm';
import Select from 'react-select/async-creatable'
import SingleSelectDropdown from './SingleSelectDropdown';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
const _ = require("lodash");

const BookingGroupsSelector = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        onFieldChange(name, value) {
            onFieldChange(name, value);
        },
    }));

    const { getApiUrl } = useContext(BaseContext);
    const { initialFormFields, updateFormFields, registerName, control, getValue, reset, processValidations } = useContext(BaseFormContext);
    const [customerTypes, setCustomerTypes] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [preloadedGroups, setPreloadedGroups] = useState([]);
    const selectRef = useRef(null);

    useEffect(() => {
        if (props.showGroupDetailsList) {
            serverFetch(getApiUrl('/customer_types')).then((res) => {
                setCustomerTypes(res);
            });
        }
    }, []);

    useEffect(() => {
        setPreloadedGroups(_.map(props.preloadedGroups, (g) => {
            return {
                ...g,
                groupName: g.groupName,
                name: g.groupName,
                userEmail: g.user && g.user.email,
                userPhone: g.user && g.user.phone,
                gid: g.id
            }
        }));
    }, [props.preloadedGroups]);

    const updateGroups = async (query) => {
        if (adjustedValue.length >= props.max) {
            return [];
        }
        if (!_.isNil(preloadedGroups) && !_.isEmpty(preloadedGroups)) {
            return preloadedGroups;
        } else {
            return serverPost(getApiUrl('/user_groups/autocomplete'), { query: query }).then((res) => {
                _.each(res, (r) => {
                    r.name = r.groupName;
                    r.userEmail = r.user && r.user.email;
                    r.userPhone = r.user && r.user.phone;
                    r.gid = r.id;
                })
                return res;
            });
        }
    }

    const isValidNewOption = (inputValue, value, options, accessors) => {
        if (!_.isNil(preloadedGroups) && !_.isEmpty(preloadedGroups)) {
            return false;
        }
        if (inputValue === "") {
            return false;
        }
        if (!_.isEmpty(value)) {
            // An existing group was selected.
            if (value.length >= props.max) {
                return false;
            }
        }
        return true;
    }

    const onChange = (field, value, meta) => {
        const action = meta.action;
        let selectedOptions = [];
        if (!_.isEmpty(action)) {
            selectedOptions = [...value];
        } else {
            return;
        }
        _.each(selectedOptions, (op) => {
            if (op.__isNew__) {
                op.groupName = op.label;
                op.customerTypeId = op.customerTypeId || null;
            } else {
                op.userFullName = getFormattedName(op.user);
            }
        })
        setSelectedGroups((prevSelectedGroups) => {
            const previousGroupIds = _.map(prevSelectedGroups, (g) => g.groupName);
            const newGroupIds = _.map(selectedOptions, (g) => g.groupName);
            if (JSON.stringify(previousGroupIds) !== JSON.stringify(newGroupIds)) {
                field.onChange([...selectedOptions]);
                updateFormFields(props.name, [...selectedOptions]);
                return selectedOptions;
            }
            return prevSelectedGroups;
        });
    }

    const onFieldChange = (name, value) => {
        // For future use.
    }

    let customerTypeOptions = _.map(customerTypes, (type) => { return { value: type.id, label: `${type.customerType}` } });
    customerTypeOptions.unshift({ value: null, label: "None" });

    registerName(props.name);
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const processedValidations = processValidations(props.name, props.label, validations);
    const value = getValue(initialFormFields, props.name);
    let adjustedValue = (!_.isUndefined(value) && !_.isNull(value) && !_.isNil(value)) ? value : null;
    if (adjustedValue) {
        _.each(adjustedValue, (v) => {
            v.label = v.name
        });
    }

    const getOptionsLabel = (option) => {
        if (option.__isNew__) {
            return (
                <>
                    <span className="title">{ option.label || option.value }</span><br/>
                </>
            )
        } else {
            let parentGroupDescription = null;
            if (!_.isNil(option.parentGroup)) {
                parentGroupDescription = `${option.parentGroup.groupName}`
            }
            return (
                <>
                    <span className="title">{ option.groupName }</span><br/>
                    { (option.userEmail || !parentGroupDescription) && <span className="subtitle">{ option.userEmail }</span> }
                    { parentGroupDescription && <>{ option.userEmail && <br/>}<span className="subtitle">Parent: { parentGroupDescription }</span></> }
                </>
            )
        }
    }

    useEffect(() => {
        setTimeout(() => {
            _.each(adjustedValue, (v) => {
                v.id = v.groupId;
                v.groupName = v.name;
            })
            if (selectRef.current) {
                selectRef.current.setValue(adjustedValue);
            }
            setSelectedGroups(adjustedValue);
        }, 30);
    }, [adjustedValue]);

    const isFull = !_.isNil(adjustedValue) ? adjustedValue.length >= props.max : false;
    const noOptionsMessage = isFull ? "Max number of groups selected": "Start typing to load or create a group"

    return (
        <Controller
            control={control}
            name={props.name}
            defaultValue={adjustedValue}
            rules={processedValidations}
            render={({ field }) => {
                return (
                    <>
                        <BaseForm.InputGroup className="inline">
                            <Row name={props.name}>
                                <Select
                                    disabled={props.disabled}
                                    autoFocus
                                    ref={selectRef}
                                    isMulti
                                    key={10}
                                    max={1}
                                    isValidNewOption={isValidNewOption}
                                    getOptionLabel={getOptionsLabel}
                                    getOptionValue={(s) => s.groupName}
                                    loadOptions={updateGroups}
                                    defaultValue={value}
                                    isClearable={false}
                                    className="select-container"
                                    classNamePrefix="select2"
                                    onChange={(value, meta) => onChange(field, value, meta)}
                                    noOptionsMessage={(inputValue) => { return noOptionsMessage }}
                                    formatCreateLabel={(inputValue) => { return `Create customer "${inputValue}"` }}
                                    placeholder="Start typing..."
                                    isDisabled={props.disabled}
                                />
                            </Row>
                        </BaseForm.InputGroup>
                        {
                            !_.isEmpty(selectedGroups) && props.showGroupDetailsList && <br/>
                        }
                        {
                            props.showGroupDetailsList && _.map(selectedGroups, (group, i) =>
                                <Row key={i}>
                                    <Col md="3">
                                        <BaseForm.InputGroup disabled={props.disabled || !group.__isNew__}>
                                            <BaseForm.Label>{group.__isNew__ ? group.value : group.name}</BaseForm.Label>
                                            <BaseForm.Control name={`groups.${i}.userFullName`} disabled={props.disabled || !group.__isNew__} value={group.userFullName || ""} />
                                            { (props.disabled || !group.__isNew__) && !group.__isNew__ && !_.isNil(group.parentGroup) && <div className="form-subtext">{ group.parentGroup.groupName }</div> }
                                        </BaseForm.InputGroup>
                                    </Col>
                                    <Col md="3">
                                        <BaseForm.InputGroup disabled={props.disabled || !group.__isNew__}>
                                            <BaseForm.Label>Email</BaseForm.Label>
                                            <BaseForm.Control name={`groups.${i}.userEmail`} disabled={props.disabled || !group.__isNew__}  value={group.userEmail || ""} />
                                        </BaseForm.InputGroup>
                                    </Col>
                                    <Col md="3">
                                        <BaseForm.InputGroup disabled={props.disabled || !group.__isNew__}>
                                            <BaseForm.Label>Phone</BaseForm.Label>
                                            <BaseForm.Control name={`groups.${i}.userPhone`} disabled={props.disabled || !group.__isNew__}  value={group.userPhone || ""} />
                                        </BaseForm.InputGroup>
                                    </Col>
                                    <Col md="3">
                                        <BaseForm.InputGroup disabled={props.disabled || !group.__isNew__}>
                                            <BaseForm.SingleSelect name={`groups.${i}.customerTypeId`} options={customerTypeOptions}
                                                label="Customer Type" disabled={props.disabled || !group.__isNew__}
                                                showSearch={false} />
                                        </BaseForm.InputGroup>
                                    </Col>
                                </Row>
                            )
                        }
                        <hr/>
                    </>
                );
            }}
        />
    );
})

export default BookingGroupsSelector;
