import '../../App.scss';
import '../../css/modals.scss';
import { useEffect, useState, useContext, useMemo } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import { useParams } from "react-router-dom";
import { Form, InputGroup, Button, Row, Alert, Col, ButtonGroup } from 'react-bootstrap';
import { getPaymentOptions, BaseContext, getDefaultColor } from '../../helpers/common';
import { serverFetch, serverPost } from '../../helpers/server';
import {useTranslation} from "react-i18next";
const _ = require("lodash");

function EditBulkBookingModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [eventTypes, setEventTypes] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [uniqueVenueIds, setUniqueVenueIds] = useState([]);
    const paymentOptions = getPaymentOptions();

    const initialFields = useMemo(() => {
	    return {
            popBookingColor: getDefaultColor(t)
	    };
	}, [])

    useEffect(() => {
        if (props.show) {
            if (uniqueVenueIds.length == 1) {
                serverFetch(getApiUrl('/event_types', { venueIds: uniqueVenueIds }), { skipAddingCategory: true }).then((res) => {
                    setEventTypes(res);
                })
            }
        }
    }, [uniqueVenueIds, props.show]);

    useEffect(() => {
        setBookings(props.bookings);
        setUniqueVenueIds(_.map(_.uniqBy(props.bookings, (b) => b.venueId), (b) => b.venueId));
    }, [props.bookings])

    const updateBookings = (fields) => {
        const updateFields = {
            bookingIds: _.map(bookings, (b) => b.id),
            updateInvoices: fields.updateInvoices,
            updateAgreements: fields.updateAgreements
        };
        if (fields.updateEventType) {
            updateFields.eventTypeId = fields.popEventType;
        }
        if (fields.updatePaymentType) {
            updateFields.paymentMethod = fields.popPaymentType;
        }
        if (fields.updateHourlyRate) {
            updateFields.hourlyRate = fields.popHourlyRate;
        }
        if (fields.updateTaxRate) {
            updateFields.taxRate = fields.popTaxRate;
        }
        if (fields.updateBookingColor) {
            updateFields.bookingColor = fields.popBookingColor;
        }
        if (fields.updateEventName) {
            updateFields.eventName = fields.popEventName;
        }
        if (fields.updateEventDescription) {
            updateFields.eventDescription = fields.popEventDescription;
        }

        serverPost(getApiUrl("/bookings/batch_edit"), updateFields).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
                props.onClose(true);
            }
        })
    }

    const eventTypeOptions = _.map(eventTypes, (type) => { return { value: type.id, label: `${type.eventType}` } });
    const paymentTypeOptions = _.map(paymentOptions, (op) => { return { value: op.id, label: op.label } });

    return (
        <BaseModal {...props} size="md">
            <BaseForm onSubmit={updateBookings} initialFormFields={initialFields}>
                <BaseModal.Header>
                    <BaseModal.Title>Edit Booking</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <Alert variant="info">
                        <p>Select the checkbox beside the items you want to change for the bookings you selected. Only select one venue at a time when updating Event Types.</p>
                    </Alert>
                    <Col md="12">
                        <BaseForm.InputGroup className="form-group" disabled={uniqueVenueIds.length > 1}>
                            <BaseForm.Select name="popEventType" label="Event Type" options={eventTypeOptions} disabled={uniqueVenueIds.length > 1}/>
                            <BaseForm.Check id={"updateEventType"} name={"updateEventType"} disabled={uniqueVenueIds.length > 1}/>
                        </BaseForm.InputGroup>
                        {
                            uniqueVenueIds.length > 1 &&
                                <p className="form-error-message">You can't edit event type when you have selected bookings from more than one venue.</p>
                        }
                    </Col>
                    <Col md="12">
                        <InputGroup className="form-group">
                            <BaseForm.Select name="popPaymentType" label="Payment Method" options={paymentTypeOptions} />
                            <BaseForm.Check id={"updatePaymentType"} name={"updatePaymentType"} />
                        </InputGroup>
                    </Col>
                    <Col md="12">
                        <InputGroup className="form-group">
                            <Form.Label>Hourly Rate (Dollar Value)</Form.Label>
                            <BaseForm.Control type="number" name={"popHourlyRate"}/>
                            <BaseForm.Check id={"updateHourlyRate"} name={"updateHourlyRate"} />
                        </InputGroup>
                    </Col>
                    <Col md="12">
                        <InputGroup className="form-group">
                            <Form.Label>Tax Rate (Percentage)</Form.Label>
                            <BaseForm.Control type="number" name={"popTaxRate"} step={"0.01"}/>
                            <BaseForm.Check id={"updateTaxRate"} name={"updateTaxRate"} />
                        </InputGroup>
                    </Col>
                    <Col md="12">
                        <InputGroup className="form-group">
                            <BaseForm.Color id="popBookingColor" name="popBookingColor" label="Booking Color" />
                            <BaseForm.Check id={"updateBookingColor"} name={"updateBookingColor"} />
                        </InputGroup>
                    </Col>
                    <Col md="12">
                        <InputGroup className="form-group">
                            <BaseForm.Text id="popEventName" name="popEventName" label="Event Name" placeholder="Enter an event name" />
                            <BaseForm.Check id={"updateEventName"} name={"updateEventName"} />
                        </InputGroup>
                    </Col>
                    <Col md="12">
                        <InputGroup className="form-group">
                            <BaseForm.Text id="popEventDescription" name="popEventDescription" label="Event Description" placeholder="Enter an event description" />
                            <BaseForm.Check id={"updateEventDescription"} name={"updateEventDescription"} />
                        </InputGroup>
                    </Col>
                    <BaseForm.Input type="check" id="updateInvoices" label="Update Invoices" name="updateInvoices" />
                    <BaseForm.Input type="check" id="updateAgreements" label="Update Agreements" name="updateAgreements" />
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col className="text-end">
                            <ButtonGroup>
                                <Button variant="link" onClick={() => props.onClose(false)}>Cancel</Button>
                                <SubmitButton className="btn-complete">Update Bookings</SubmitButton>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default EditBulkBookingModal;
