import '../../App.scss';
import '../../css/modals.scss';
import { getCreditExportFields } from '../../helpers/exportFields';
import BaseCSVExportModal from './BaseCSVExportModal';
import {useParams} from "react-router-dom";

function CreditsCSVExportModal(props) {
    let { facilityLink } = useParams();

    return (
        <BaseCSVExportModal
            {...props}
            fields={getCreditExportFields()}
            filenamePrefix="Credit-Report"
            cacheKey={facilityLink + "_csv_credits"}
            exportUrl={'/credits/export'} />
    );
}

export default CreditsCSVExportModal;
