import '../../App.scss';
import '../../css/modals.scss';
import React, { useState, useEffect, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import ClassFormFieldsComponent from '../ClassFormFieldsComponent';
import { Row, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BaseContext } from '../../helpers/common';
const _ = require("lodash");

function PreviewClassFormModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [form, setForm] = useState({});
    const [registrationDone, setRegistrationDone] = useState(false);

    useEffect(() => {
        setForm(props.form)
    }, [props.form]);

    return (
        <BaseModal {...props} size="lg">
            <BaseModal.Header>
                <BaseModal.Title>Preview Class Form</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <BaseForm onSubmit={() => setRegistrationDone(true)}>
                    <ClassFormFieldsComponent form={form} />
                    <SubmitButton variant="primary">Register</SubmitButton><br/>
                    <span><i>* Register is for preview purposes only. No registration will be processed. Feel free to click it.</i></span>
                    {
                        registrationDone &&
                            <>
                            <br/><br/>
                            <Alert variant="success">Registration received. All A-ok!</Alert>
                            </>
                    }
                </BaseForm>
            </BaseModal.Body>
            <BaseModal.Footer>
                <Row>
                    <Col md="12">
                        <SubmitButton variant="primary" onClick={() => props.onClose(false)}>Close</SubmitButton>
                    </Col>
                </Row>
            </BaseModal.Footer>
        </BaseModal>
    );

}

export default PreviewClassFormModal;
