import '../../App.scss';
import '../../css/modals.scss';
import {getBookingExportFields, getBookingNonFinancialExportFields} from '../../helpers/exportFields';
import BaseCSVExportModal from './BaseCSVExportModal';
import {useParams} from "react-router-dom";
import {BaseContext, hasAccess} from "../../helpers/common";
import {useContext} from "react";

function BookingsCSVExportModal(props) {
    let { facilityLink } = useParams();
    const { userInfo, settings } = useContext(BaseContext);

    const showFinancials = hasAccess("booking", userInfo, "viewWithFinancials", "any");
    const fields = showFinancials ? getBookingExportFields(settings): getBookingNonFinancialExportFields();

    return (
        <BaseCSVExportModal
            {...props}
            fields={fields}
            filenamePrefix="Bookings-Report"
            cacheKey={facilityLink + "_csv_booking"}
            exportUrl={'/bookings/export_booking_list'} />
    );
}

export default BookingsCSVExportModal;
