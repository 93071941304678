import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import EditEmailModal from '../../components/modals/EditEmailModal';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import Notification from '../../components/Notification';
import TabHeader from '../../components/TabHeader';
import { serverFetch, serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function DefaultEmailList() {
    const { userInfo, getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [emails, setEmails] = useState([]);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [showEditSMSModal, setShowEditSMSModal] = useState(false);

    useEffect(() => {
        document.title = "System Email - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (skipCache = false) => {
        setLoading(true);
        serverFetch(getApiUrl(`/emails/default`), { skipCache }).then((res) => {
            setEmails(res);
            setLoading(false);
        });
    }

    const onModalClose = () => {
        fetchData(true);
        setShowEditSMSModal(false);
    }

    const editSMS = (event, sms) => {
        event.preventDefault();
        setItemToEdit(sms);
        setShowEditSMSModal(true);
    }

    const addToFacility = (item) => {
        serverPost(getApiUrl(`/emails/${item.id}/add_to_facility`)).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
                fetchData(true);
            }
        });
    }

    const tabsParams = {
        isSuperAdmin: userInfo.isSuperAdmin
    };
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "email-sms", tabsParams)} />
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="9">
                            <Loader loading={loading}>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>{t('setting.email_sms.title')}</th>
                                            <th>{t('setting.email_sms.subject')}</th>
                                            <th>{t('setting.email_sms.add_to_facility')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(emails, (item, i) =>
                                                <tr key={i} id={item.id}>
                                                    <td className="skinny"><Button variant="alink" onClick={(event) => editSMS(event, item)}>{item.title}</Button></td>
                                                    <td className="skinny">{item.subject}</td>
                                                    <td className="skinny">
                                                        {
                                                            item.addedToFacility ?
                                                                <i className="fa fa-check-circle fa-c-success fs-16"></i> :
                                                                <Button variant="outline-primary" onClick={() => addToFacility(item)}><i className="fa fa-plus-circle fs-16"></i> {t("setting.email_sms.add_to_facility")}</Button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Loader>
                        </Col>
                    </Row>
                </div>
            </div>
            <EditEmailModal show={showEditSMSModal} onClose={onModalClose} itemToEdit={itemToEdit} />
        </BaseContainer>
    );
}

export default DefaultEmailList;
