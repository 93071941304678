import "../../App.scss";
import "../../css/modals.scss";
import { useTranslation } from "react-i18next";
import BaseModal from "./BaseModal";
import { Spinner } from "react-bootstrap";

function LoadingModal(props) {
  const { t } = useTranslation("common");
  const body = props.body ? props.body : t('modals.loading_modal')

  return (
    <BaseModal {...props} unclosable>
      <BaseModal.Body>
        <h3>{t('modals.loading_modal.please_wait')} </h3>
        <br />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
            height: "100%",
          }}
        >
          <Spinner animation="border" />
          <span style={{ paddingLeft: "15px" }}>
            {body}
          </span>
        </div>

        <br />
      </BaseModal.Body>
    </BaseModal>
  );
}

export default LoadingModal;
