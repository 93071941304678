import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import DeleteButton from '../../components/DeleteButton';
import AddOrEditMediaScreenModal from '../../components/modals/AddOrEditMediaScreenModal';
import TabHeader from '../../components/TabHeader';
import { serverFetch, serverDelete } from '../../helpers/server';
import { BaseContext, hasAccess } from '../../helpers/common';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function MediaScreenList() {
    const { getApiUrl, getFacilityName, userInfo } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [venues, setVenues] = useState([]);
    const [mediaScreens, setMediaScreens] = useState([]);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [showAddOrEditModal, setShowAddOrEditModal] = useState(false);
    const canUpdate = hasAccess("mediaScreen", userInfo, "update");

    useEffect(() => {
        document.title = "Media Screen - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const onModalClose = () => {
        fetchData(true);
        setShowAddOrEditModal(false);
    }

    const fetchData = (skipCache = false) => {
        setLoading(true);
        serverFetch(getApiUrl('/mediascreens'), { skipCache }).then((res) => {
            setMediaScreens(res);
            setLoading(false);
        })

        serverFetch(getApiUrl('/venues')).then((res) => {
            setVenues(res);
        })
    }

    const getVenueNames = (value) => {
        const venueIdString = value ? ""+value : "";
        const venueIds = venueIdString.split(",");
        const selectedVenues = _.filter(venues, (v) => _.includes(venueIds, ""+v.id));
        return _.map(selectedVenues, (v, i) => "" + v.name).join(", ");
    }

    const addItem = () => {
        setItemToEdit(null);
        setShowAddOrEditModal(true);
    }

    const editItem = (event, type) => {
        event.preventDefault();
        setItemToEdit(type);
        setShowAddOrEditModal(true);
    }

    const deleteItem = (item) => {
        const deleteItemUrl = getApiUrl(`/mediascreens/${item.id}`);
        serverDelete(deleteItemUrl, {}).then((res) => {
            fetchData(true);
        })
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "media-screen")} />
            {
                canUpdate &&
                    <div className="content-box">
                        <div className="content-body">
                            <Button variant="outline-primary" onClick={() => addItem()}><i className="fa fa-plus-circle"></i> {t('setting.media_screen.add')}</Button>
                        </div>
                    </div>
            }
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="12">
                            <Loader loading={loading}>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>{t('setting.media_screen.screen_name')}</th>
                                            <th>{t('common.venues')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody id="sortable-table">
                                        {
                                            _.map(mediaScreens, (item, i) =>
                                                <tr key={i} id={item.id}>
                                                    <td><Button variant="alink" className="text-start" onClick={canUpdate ? (event) => editItem(event, item): null}>{item.screenName}</Button></td>
                                                    <td>{getVenueNames(item.rinkId)}</td>
                                                    <td className="no-stretch">
                                                        <Button variant="outline-primary" href={`/${facilityLink}/mediascreen/preview?id=${item.id}`}><i className="fa fa-tv fa-small"/> {t('setting.media_screen.preview')}</Button>
                                                        {
                                                            canUpdate &&
                                                                <>
                                                                <Button variant="outline-primary" onClick={(event) => editItem(event, item)}><i className="fa fa-edit fa-small"/> {t('common.edit')}</Button>
                                                                <DeleteButton onDelete={() => deleteItem(item)}/>
                                                                </>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Loader>
                        </Col>
                    </Row>
                </div>
            </div>
            <AddOrEditMediaScreenModal show={showAddOrEditModal} onClose={onModalClose} itemToEdit={itemToEdit} venues={venues}/>
        </BaseContainer>
    );
}

export default MediaScreenList;
