import { useEffect, useState, useContext, useRef, createRef, forwardRef, useImperativeHandle } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import AddOrEditClassSessionModal from '../../components/modals/AddOrEditClassSessionModal'
import BaseContainer from '../../components/Container';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import DeleteButton from '../../components/DeleteButton';
import Loader from '../../components/Loader';
import { serverFetch, serverPost, serverPatch } from '../../helpers/server';
import { BaseContext, getDefaultColor, getDateFormatForFacility, currencyFormat, getEventTypesForVenue } from '../../helpers/common';
import { timeOptions } from '../../helpers/input';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Button, Row, Col, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Notification from '../../components/Notification'
import PreviewClassFormModal from "../../components/modals/PreviewClassFormModal";
import Select from 'react-select/async-creatable';
import classNames from 'classnames';
const _ = require("lodash");

function CreateOrUpdate(props) {
    const { settings, getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const navigate = useNavigate();

    const [ searchParams ] = useSearchParams();
    let classId = searchParams.get('id');
    let preselectedDate = searchParams.get('d');
    let preselectedStartTime = searchParams.get('s');
    let preselectedEndTime = searchParams.get('e');
    let preselectedVenueId = searchParams.get('v');
    let preselectedEventTypeId = searchParams.get('ev');

    const { t } = useTranslation('common');
    const formRef = createRef();
    const selectRef = useRef(null);
    const rowRefs = useRef([]);
    const [venues, setVenues] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [priceType, setPriceType] = useState("totalPrice");
    const [classForms, setClassForms] = useState([]);
    const [numberOfSessions, setNumberOfSessions] = useState(1);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
    const [classToEdit, setClassToEdit] = useState(null);
    const [initialFields, setInitialFields] = useState({});
    const [errors, setErrors] = useState({});
    const [bannerUploadError, setBannerUploadError] = useState(false);
    const [loadingVenues, setLoadingVenues] = useState(true);
    const [loadingForms, setLoadingForms] = useState(true);
    const [loadingEventTypes, setLoadingEventTypes] = useState(true);
    const [loadingClassDetails, setLoadingClassDetails] = useState(true);
    const [loading, setLoading] = useState(true);
    const [existingClassDetails, setExistingClassDetails] = useState(null);
    const [showAddOrEditClassSessionModal, setShowAddOrEditClassSessionModal] = useState(false);
    const [showPreviewClassFormModal, setShowPreviewClassFormModal] = useState(false);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        document.title = `New Class - ${getFacilityName()}`;
    }, []);

    useEffect(() => {
        setLoading(loadingVenues || loadingForms || loadingEventTypes || loadingClassDetails);
    }, [loadingVenues, loadingForms, loadingEventTypes, loadingClassDetails])

    useEffect(() => {
        setClassToEdit(props.classToEdit);
//        setIsEditing(props.classToEdit !== null && props.classToEdit !== undefined);
        if (classId) {
            serverFetch(getApiUrl(`/classes/${classId}`)).then((res) => {
                if (res) {
                    setInitialFields(prevFields => {
                        const newFields = {...prevFields, ...res};
                        newFields["taxRate"] = settings.tax1;
                        newFields["costInCents"] = res.costInCents/100;
                        newFields["formId"] = res.form && res.form.id;
                        _.map(res.sessions, (ss, i) => {
                            newFields[String(i)] = {
                                date: moment(ss.startTimeLocal).format("YYYY-MM-DD"),
                                taxRatePercent: ss.taxRate,
                                startTime: moment(ss.startTimeLocal).format("HH:mm:00"),
                                endTime: moment(ss.endTimeLocal).format("HH:mm:00"),
                                venueId: ss.venueId,
                                eventTypeId: ss.eventTypeId
                            }
                        })
                        return newFields;
                    });
                    setExistingClassDetails(res);
                    setPriceType(res.priceType);
                    setTags(res.tags);
                    updateNumberOfSessions(res.sessions.length);
                }
                setLoadingClassDetails(false);
            })
            setIsEditing(true);
        } else {
            setInitialFields({
                taxRate: settings.tax1,
                capacity: 20,
                "0": {
                    date: preselectedDate || moment().format("YYYY-MM-DD"),
                    taxRatePercent: settings.tax1,
                    startTime: preselectedStartTime,
                    endTime: preselectedEndTime,
                    venueId: preselectedVenueId,
                    eventTypeId: preselectedEventTypeId
                }
            })
            updateNumberOfSessions(1);
            setLoadingClassDetails(false);
            setIsEditing(false);
        }
    }, [props.classToEdit]);

    useEffect(() => {
        serverFetch(getApiUrl("/venues")).then((res) => {
            setVenues(res);
            setLoadingVenues(false);
        })

        serverFetch(getApiUrl("/forms")).then((res) => {
            setClassForms(res);
            setLoadingForms(false);
        })
    }, []);

    useEffect(() => {
        if (_.isEmpty(venues)) {
            setEventTypes([]);
            return;
        }
        serverFetch(getApiUrl('/event_types', { venueIds: _.map(venues, v => v.id) })).then((res) => {
            setEventTypes(res);
            setLoadingEventTypes(false);
        });
    }, [venues])

    const onFieldChange = (name, value) => {
        setBannerUploadError(false);
        if (name === "priceType") {
            setPriceType(value);
            setInitialFields(prevFields => {
                prevFields[name] = value;
                return prevFields;
            });
        } else if (_.includes(["name", "description", "capacity", "formId", "paymentOptions", "preTax", "taxRate", "bannerURL", "costInCents", "allowWaitlist"], name)) {
            setInitialFields(prevFields => {
                prevFields[name] = value;
                return prevFields;
            });
            if (name === "formId") {
                setSelectedForm(_.find(classForms, (f) => String(f.id) === String(value)))
            }
        } else if (name.endsWith("date")) {
            const parts = name.split(".");
            setInitialFields(prevFields => {
                prevFields[parts[0]][parts[1]] = value;
                return prevFields;
            });
        }
        if (_.includes(name, ".")) {
            _.each(rowRefs.current, (ref) => {
                if (ref.current) {
                    ref.current.onFieldChange(name, value);
                }
            })
        }
    }

    const previewClassForm = () => {
        if (selectedForm) {
            setShowPreviewClassFormModal(true);
        }
    }

    const resetEventTypes = (prefix) => {
        const index = prefix.split(".")[0];
        setInitialFields(prevInitialFields => {
            const newInitialFields = {...prevInitialFields};
            if (_.has(newInitialFields, String(index))) {
                newInitialFields[String(index)].eventTypeId = null;
            }
            return newInitialFields;
        });
    }

    const onAddNewSession = () => {
        setInitialFields(prevInitialFields => {
            const newInitialFields = {...prevInitialFields};
            newInitialFields[String(numberOfSessions)] = {
                date: moment().format("YYYY-MM-DD"),
                taxRate: settings.tax1
            }
            return newInitialFields;
        })
        updateNumberOfSessions(numberOfSessions + 1);
    }

    const onDeleteSession = (index) => {
        console.log("on delete session " + index);
        setInitialFields(prevInitialFields => {
            const newInitialFields = {...prevInitialFields};
            for (let i = index; i < numberOfSessions - 1; i++) {
                newInitialFields[i] = newInitialFields[i+1];
            }
            delete newInitialFields[numberOfSessions-1];
            return newInitialFields;
        })
        updateNumberOfSessions(numberOfSessions - 1);
    }

    const updateNumberOfSessions = (num) => {
        rowRefs.current = _.range(num).map((r) => createRef());
        setNumberOfSessions(num);
    }

    const addClass = async (fields) => {
        console.log("Add class " + JSON.stringify(fields));
        const formData = formRef.current.getFormData();
        console.log("Form data is " + JSON.stringify(formData));

        // Check overlaps
        _.each(_.range(numberOfSessions), (i) => {
            const start = moment(formData[String(i)].date + " " + formData[String(i)].startTime).utcOffset(0, true)
            const end = moment(formData[String(i)].date + " " + formData[String(i)].endTime).utcOffset(0, true)
            _.each(_.range(i+1, numberOfSessions), (j) => {
                const rstart = moment(formData[String(j)].date + " " + formData[String(j)].startTime).utcOffset(0, true)
                const rend = moment(formData[String(j)].date + " " + formData[String(j)].endTime).utcOffset(0, true)

                if (!rstart.isAfter(end) && !start.isAfter(rend)) {
                    // There is an overlap
                    const errors = {};
                    errors[String(i)] = {
                        date: "Overlapping"
                    }
                    errors[String(j)] = {
                        date: "Overlapping"
                    }
                    setErrors(errors);
                    console.log("The errors are " + JSON.stringify(errors));
                }
            })
        })

        const bookings = _.map(_.range(numberOfSessions), (r) => {
            const rate = fields.costInCents;
            const taxRate = fields.taxRate;
            const preTaxInCents = parseFloat(rate)*100/(1+parseFloat(taxRate)/100);
            const postTaxInCents = parseFloat(rate)*100;
            const taxInCents = postTaxInCents - preTaxInCents;
            return {
                startTimeLocal: moment(formData[String(r)].date + " " + formData[String(r)].startTime).utcOffset(0, true).format(),
                endTimeLocal: moment(formData[String(r)].date + " " + formData[String(r)].endTime).utcOffset(0, true).format(),
                venueId: formData[String(r)].venueId,
                eventTypeId: formData[String(r)].eventTypeId,
                preTaxInCents: preTaxInCents,
                taxInCents: taxInCents,
                postTaxInCents: postTaxInCents,
                rate: rate,
                taxRate: taxRate,
                id: initialFields[String(r)].bid
            }
        });

        const classData = {
            name: fields.name,
            description: fields.description,
            capacity: fields.capacity,
            priceType: fields.priceType || "totalPrice",
            paymentOptions: fields.paymentOptions,
            formId: fields.formId,
            taxRate: fields.taxRate,
            costInCents: parseFloat(fields.costInCents)*100,
            bookingColor: getDefaultColor(t),
            allowPartialRegistration: false,
            bookings: bookings,
            bannerURL: fields.bannerURL,
            tags: tags,
            allowWaitlist: fields.allowWaitlist === 'yes' ? true : false
        }
        
        if (isEditing) {
//            const bookingResult = await serverPatch(getApiUrl(`/classes/${bookingData.booking.id}`), classData, {}, onError);
            console.log("Editing class");
        } else {
            const classResult = await serverPost(getApiUrl('/classes'), classData, {}, onError);
            if (classResult) {
                navigate(`/${facilityLink}/class/classes-list-participants?id=${classResult.id}`);
            }
        }
    }

    const renderSessions = (details) => {
        if (_.isEmpty(details.sessions)) {
            return null;
        }
        const dateFormat = getDateFormatForFacility(settings);
        return (
            <div>
                <p><strong>Sessions</strong></p>
                <Row>
                    <Col lg="6">
                    <Table borderless className="skinny">
                        <tbody>
                        {
                            _.map(details.sessions, (session, i) =>
                                <tr key={i}>
                                    <td style={{ paddingLeft: "0px" }}>
                                        <strong>{ moment(session.startTimeLocal).format(dateFormat) }</strong><br/>
                                        <span>{ moment(session.startTimeLocal).format("h:mma") + " - " + moment(session.endTimeLocal).format("h:mma") }</span>
                                    </td>
                                    <td>{ session.venueName }</td>
                                    {
                                        details.priceType !== "totalPrice" &&
                                            <td>{ currencyFormat(session.cost) }</td>
                                    }
                                    <td className="text-end skinny">
                                        <span className="label">{ session.spotsFilled }/{ details.capacity }</span>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                    </Col>
                </Row>
            </div>
        )
    }

    const onError = async (response, errorMessage) => {
        console.log("The error response is " + JSON.stringify(errorMessage));
    }

    const updateClass = (fields) => {
        console.log("Update class " + JSON.stringify(fields));
        const classData = {
            name: fields.name,
            description: fields.description,
            capacity: fields.capacity,
            priceType: fields.priceType || "totalPrice",
            paymentOptions: fields.paymentOptions,
            formId: fields.formId,
            taxRate: fields.taxRate,
            costInCents: parseFloat(fields.costInCents)*100,
            bookingColor: getDefaultColor(t),
            allowPartialRegistration: false,
            bannerURL: fields.bannerURL,
            tags: tags
        }
        serverPatch(getApiUrl(`/classes/${existingClassDetails.id}`), classData).then((res) => {
            setLoading(false);
            if (res) {
                navigate(`/${facilityLink}/class/classes-list-participants?id=${existingClassDetails.id}`);
            } else {
                setBannerUploadError(true);
            }
        });
    }

    const onChange = (value, meta) => {
        const action = meta.action;
        let selectedOptions = [];
        if (!_.isEmpty(action)) {
            selectedOptions = [...value];
        } else {
            return;
        }
        let selectedTags = [];
        _.each(selectedOptions, (s) => {
            selectedTags.push(s.label);
        });
        selectedTags = _.uniq(selectedTags);
        setTags(selectedTags);
    }

    const pricePerOptions = [
        { 'label': 'Total Price For All Sessions', 'value': 'totalPrice' },
        { 'label': 'Price Per Session', 'value': 'pricePerSession' }
    ];

    const paymentOptions = [
        { 'value': 'online', 'label': 'Pay Online' },
        { 'value': 'onlineOrLater', 'label': 'Pay Online or Later' },
        { 'value': 'none', 'label': 'None' },
    ]

    const allowWaitlistOptions = [
        {'value': 'no', 'label': 'No' },
        {'value': 'yes', 'label': 'Yes' },
    ]

    const classFormOptions = _.map(classForms, (r) => {
        return { label: r.name, value: r.id }
    })

    const isValidNewOption = (inputValue, value, options, accessors) => {
        if (_.trim(inputValue) === "" || tags.includes(_.trim(inputValue))) {
            return false;
        }
        return true;
    }

    return (
        <BaseContainer>
            <div className="content-box">
                <div className="content-header">
                    <span>{ isEditing ? "Update Class": "Add New Class" }</span>
                </div>
                <div className="content-body">
                    <Loader loading={loading}>
                    {
                        () =>
                            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange} ref={formRef} onSubmit={isEditing ? updateClass : addClass} errors={errors}>
                                <strong>Class Information</strong>
                                <Row>
                                    <BaseForm.Input colSpan="4" name={'name'} label={t('forms.class_name')} type={'text'} placeholder={'Class Name'} required />
                                    <BaseForm.Input colSpan="4" name={'bannerURL'} label={"Banner"} placeholder="If empty, no banner is displayed" type="file" fileType="header_logo" />
                                </Row>
                                <Row>
                                    <Col md="8">
                                        <div className={classNames("form-group", "input-group")}>
                                            <Select
                                                ref={selectRef}
                                                isMulti
                                                isValidNewOption={isValidNewOption}
                                                defaultValue={tags.map((t) => { return { label: t, value: t } })}
                                                isClearable={false}
                                                className="select-container"
                                                classNamePrefix="select2"
                                                formatCreateLabel={(inputValue) => { return `Create tag "${inputValue}"` }}
                                                onChange={(value, meta) => onChange(value, meta)}
                                                placeholder="Start adding tags..."
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <BaseForm.Input colSpan="8" type="editor"  label={t('forms.description')} name="description" placeholder="Description" />
                                </Row>
                                <Row>
                                    <BaseForm.Input colSpan="4" name={'capacity'} label={t('forms.capacity')} type="number" placeholder="20" required />
                                    <BaseForm.Input colSpan="4" name={'formId'} label={t('forms.class_form')} type={'select'} placeholder={'Class Form'} options={classFormOptions} showSearch={false} />
                                    <Col md="4" className="d-flex align-items-center">
                                        <Button variant="alink" onClick={() => previewClassForm()}>Preview Registration Form</Button>
                                    </Col>
                                </Row>
                                <strong>Pricing and payment</strong>
                                <Row>
                                    <BaseForm.Input colSpan="3" name={'priceType'} label={"Pricing"} type={'select'} options={pricePerOptions} required showSearch={false} />
                                    <BaseForm.Input colSpan="3" name="costInCents" label={priceType === "totalPrice" ? "Price": "Price Per Session"} type="number" step="0.01" min="0.00" required leftContent="$" />
                                    <BaseForm.Input colSpan="3" name="taxRate" label={`${settings.taxLabel1} (${settings.isExclusive ? "EX": "IN"})`} type="number" step="0.01" min="0.00" required rightContent="%" />
                                    <BaseForm.Input colSpan="3" name={'paymentOptions'} label={"Payment Method"} type={'select'} options={paymentOptions} required showSearch={false} />
                                </Row>
                                <br/>
                                {
                                    isEditing ?
                                        renderSessions(existingClassDetails)
                                    : <>
                                        <strong>Sessions</strong>
                                        {
                                            _.range(numberOfSessions).map((r, i) =>
                                                <ClassSession key={i} ref={rowRefs.current[r]} prefix={`${r}.`}
                                                    index={i+1}
                                                    showPricing={false}
                                                    eventTypes={eventTypes}
                                                    venues={venues}
                                                    settings={settings}
                                                    isEditing={isEditing}
                                                    showAddNew={i === 0 && !isEditing} canDelete={i !== 0}
                                                    resetEventTypes={resetEventTypes}
                                                    onDeleteRow={() => onDeleteSession(i)}
                                                />
                                            )
                                        }                                        
                                        <Button variant="text-primary" onClick={() => onAddNewSession()}><i className="fa fa-circle-plus"/> Add New Session</Button>
                                    </>
                                }
                                <br/>
                                <br/>

                                <Row>
                                    <BaseForm.Input colSpan="3" name={'allowWaitlist'} label={"Allow waitlist"} type={'select'} options={allowWaitlistOptions} required showSearch={false} />
                                </Row>
                                <br/>

                                { bannerUploadError && (
                                    <>
                                        <span style={{color: 'red'}}>{t("classes.banner_upload_error")}</span>
                                        <br/>
                                        <br/>
                                     </>
                                )}
                                

                                <Row>
                                    <div className="col-md-3">
                                        <SubmitButton>{isEditing ? "Edit Class" : "Add Class"}</SubmitButton>
                                    </div>
                                </Row>
                            </BaseForm>
                        }
                    </Loader>
                </div>
            </div>
            <AddOrEditClassSessionModal facilityLink={facilityLink} sessionToEdit={null} show={showAddOrEditClassSessionModal} onClose={setShowAddOrEditClassSessionModal} />
            <PreviewClassFormModal show={showPreviewClassFormModal} onClose={setShowPreviewClassFormModal} form={selectedForm} />
        </BaseContainer>
    );
}

// <BaseForm.Input colSpan="4" name={'priceType'} label={"Pricing"} type={'select'} options={pricePerOptions} required showSearch={false} />

const ClassSession = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        onFieldChange(name, value) {
            onFieldChange(name, value);
        },
    }));

    const { t } = useTranslation('common');
    const [prefix, setPrefix] = useState("");
    const [venues, setVenues] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [selectedVenueId, setSelectedVenueId] = useState(null);
    const [selectedVenue, setSelectedVenue] = useState(null);

    useEffect(() => {
        setPrefix(props.prefix);
    }, [props.prefix])

    useEffect(() => {
        setVenues(props.venues);
    }, [props.venues])

    useEffect(() => {
        setEventTypes(props.eventTypes);
    }, [props.eventTypes])

    useEffect(() => {
        setSelectedVenue(_.find(venues, v => String(v.id) === String(selectedVenueId)));
    }, [selectedVenueId, venues]);

    const onFieldChange = (name, value) => {
        console.log("Session on field change " + name + ", " + value);
        if (name === (prefix + "venueId")) {
            if (String(selectedVenueId) !== String(value) && selectedVenueId !== null) {
                if (props.resetEventTypes) {
                    props.resetEventTypes(prefix);
                }
            }
            setSelectedVenueId(value);
        }
    }

    const venueEventTypes = getEventTypesForVenue(eventTypes, selectedVenueId);
    const eventTypeOptions = _.map(venueEventTypes, (type, i) => { return { 'value': type.id, 'label': type.eventType } });
    const venueOptions = _.map(venues, (venue, i) => { return { 'value': venue.id, 'label': venue.name } });
    return (
        <>
        <strong className="hide">Session {props.index}</strong>
        <Row>
            <BaseForm.Input colSpan="2" name={prefix + "venueId"} label={t('forms.venue')} type="select" options={venueOptions} showSearch={venues.length > 5} disabled={props.isEditing}/>
            {
                !props.showPricing &&
                    <BaseForm.Input colSpan="2" name={prefix + "eventTypeId"} label={t('forms.event_type')} type="select" options={eventTypeOptions} showSearch={eventTypeOptions.length > 5} disabled={props.isEditing}/>
            }
            <BaseForm.Input colSpan="2" name={prefix + "date"} type="date" label={t("common.date")} rightContent={<i className="fa fa-calendar"/>}  disabled={props.isEditing}/>
            <Col md="3">
                <BaseForm.InputGroup disabled={props.disabled} >
                    <BaseForm.SingleSelect name={prefix + "startTime"} options={timeOptions(5)} label={t('common.start_time')} disabled={props.disabled || props.isEditing} validations={{ required: true, isMultipleOfTime: 5 }}/>
                    <BaseForm.Divider/>
                    <BaseForm.SingleSelect name={prefix + "endTime"} options={timeOptions(5)} label={t('common.end_time')} disabled={props.disabled || props.isEditing} validations={{ required: true, isMultipleOfTime: 5 }}/>
                </BaseForm.InputGroup>
            </Col>
            <Col md="2" className="d-flex flex-row align-items-center">
                <DeleteButton onDelete={props.onDeleteRow}></DeleteButton>
            </Col>
        </Row>
        {
            props.showPricing &&
                <Row>
                    <BaseForm.Input colSpan="2" name={prefix + "eventTypeId"} label={'forms.event_type'} type="select" options={eventTypeOptions} showSearch={eventTypeOptions.length > 5} disabled={props.isEditing}/>
                    {
                        props.showPricing &&
                            <BaseForm.Input colSpan="2" type="number" name={prefix + "rate"} label="Rate" step="0.01" min="0" validations={{ min: 0, required: true }} disabled={props.isEditing}/>
                    }
                    {
                        props.showPricing &&
                            <BaseForm.Input colSpan="2" type="number" name={prefix + "taxRatePercent"} label={`${props.settings.taxLabel1} (${props.settings.isExclusive ? "EX": "IN"})`} rightContent="%" min="0" validations={{ min: 0, required: true }}  disabled={props.isEditing}/>
                    }

                    <hr/>
                </Row>
        }
        </>
    )
})


export default CreateOrUpdate;
