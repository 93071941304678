import React, { useEffect, useState, useContext, createRef } from 'react';
import '../App.scss';
import '../css/classes.scss';
import { serverFetch, serverPost } from '../helpers/server'
import { BaseContext, currencyFormat, getDateFormatForFacility } from '../helpers/common'
import ClassesContainer from './classes/ClassesContainer';
import SignupModal from './modals/SignupModal';
import Loader from './Loader';
import ClassFormFieldsComponent from './ClassFormFieldsComponent';
import BaseForm from './BaseForm';
import ErrorComponent from './ErrorComponent';
import { useSearchParams, Link } from "react-router-dom";
import { Table, Row, Col, Button, Alert, ButtonGroup } from 'react-bootstrap';
import classnames from 'classnames';
import moment from 'moment';
import BookingPaymentInput from "./BookingPaymentInput";
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function ClassRegistrationForm(props) {
    const { t } = useTranslation('common');
    const { isLoggedIn, userInfo, settings, getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const [classForm, setClassForm] = useState(null);
    const [classDetails, setClassDetails] = useState({});
    const [registrationDone, setRegistrationDone] = useState(false);
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [payLater, setPayLater] = useState(false);
    const [calcCost, setCalcCost] = useState({});
    const [initialPaymentFormFields, setInitialPaymentFormFields] = useState({});
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [paymentError, setPaymentError] = useState(false);
    const formRef = createRef();
    const paymentRef = createRef();
    const paymentFormRef = createRef();

    useEffect(() => {
        setClassDetails(props.classDetails);
    }, [props.classDetails])

    useEffect(() => {
        setClassForm(props.classForm);
    }, [props.classForm])

    useEffect(() => {
        updateCalcCost();
    }, [classDetails])

    const updateCalcCost = () => {
        if (!classDetails || _.isEmpty(classDetails)) {
            return;
        }
        const calcData = {
            sessionIds: _.map(classDetails.sessions, (s) => s.id)
        }
        serverPost(getApiUrl(`/classes/${classDetails.id}/register_calc_cost`), calcData).then(res => {
            if (res) {
                setCalcCost(res);
            }
        })
    }

    const onFieldChange = (name, value) => {
        if (name === "payLater") {
            setPayLater(value);
        }
    }

    const renderPaymentMethod = (details) => {
        return (
            <>
                <h4>Payment</h4>
                <br/>
                <Row>
                    <Col lg="6">
                        <Table>
                            <tbody>
                            {
                                _.map(calcCost.sessions, (ss, i) =>
                                    <tr>
                                        <td>Session</td>
                                        <td className="text-end">{ currencyFormat(ss.subtotalInCents/100) } + { currencyFormat(ss.taxInCents/100) } (taxes) =</td>
                                        <td className="text-end no-stretch">{ currencyFormat(ss.totalInCents/100) }</td>
                                    </tr>
                                )
                            }
                            {
                                calcCost.feesInCents > -10 &&
                                    <tr>
                                        <td></td>
                                        <td className="text-end">{ calcCost.feeLabel }</td>
                                        <td className="text-end no-stretch">{ currencyFormat(calcCost.feesInCents/100) }</td>
                                    </tr>
                            }
                                <tr>
                                    <td></td>
                                    <td className="text-end"><strong>Total:</strong></td>
                                    <td className="text-end"><strong>{ currencyFormat(calcCost.totalInCents/100) }</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <span><strong>Payment Details</strong></span><br/>
                <span style={{ fontSize: "14px" }}>Enter payment details to pay for the registration.</span>
                <br/>
                {
                        <>
                            <ButtonGroup aria-label="Basic example">
                                <Button variant={payLater ? "outline-primary": "primary"} className="btn-sm" onClick={() => onFieldChange("payLater", false)}>Pay Now</Button>
                                <Button variant={payLater ? "primary": "outline-primary"} className="btn-sm" onClick={() => onFieldChange("payLater", true)}>Pay Later</Button>
                            </ButtonGroup>
                            <br/>
                            <br/>
                        </>
                }
                {
                    !payLater &&
                        <BookingPaymentInput ref={paymentRef} />
                }
                {
                    paymentError &&
                        <Row>
                            <Col md="9">
                                <span className={"text-danger"}>Error processing payment, please try again later.</span>
                            </Col>
                        </Row>
                }
            </>
        );
    }

    const renderAdminPaymentMethod = () => {
        const paymentMethodOptions = [];
        return (
            <>
                <h4>Add Payment</h4><br/>
                <BaseForm ref={paymentFormRef} initialFormFields={initialPaymentFormFields}>
                    <Row>
                        <BaseForm.Input colSpan="6" type="number" step="0.01" name="amount" label="Amount Paid" leftContent="$" min="0.01" validations={{ required: true, min: 0.01 }}/>
                        <BaseForm.Input colSpan="6" type="date" name="paymentDate" label="Date Paid" rightContent={<i className="fa fa-calendar" />} />
                    </Row>
                    <Row>
                        <BaseForm.Input colSpan="4" type="select" name="paymentMethod" label="Payment Method" options={paymentMethodOptions} idField="id" required />
                        <BaseForm.Input colSpan="4" type="text" name="reference" label="Reference" />
                    </Row>
                    {
                        selectedPaymentMethod === "paidCreditOnline" &&
                            <BookingPaymentInput ref={paymentRef} />
                    }
                </BaseForm>
            </>
        )
    }

    const renderSessions = (details) => {
        if (_.isEmpty(details.sessions)) {
            return null;
        }
        const dateFormat = getDateFormatForFacility(settings);
        return (
            <>
                <h3 className="hide">Sessions</h3>
                <Row>
                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Venue</th>
                            <th>Date/Time</th>
                            {
                                classDetails.priceType !== "totalPrice" &&
                                    <th></th>
                            }
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        _.map(details.sessions, (session, i) =>
                            <tr key={i}>
                                <td>{ i+1 }</td>
                                <td>{ session.venueName }</td>
                                <td>{ moment(session.startTimeLocal).format(dateFormat) + ", " + moment(session.startTimeLocal).format("h:mma") + " - " + moment(session.endTimeLocal).format("h:mma") }</td>
                                {
                                    classDetails.priceType !== "totalPrice" &&
                                        <td>{ currencyFormat(session.cost) }</td>
                                }
                                <td><span className="label">{ session.spotsFilled }/{ details.capacity }</span></td>
                                <td>{ session.eventName }</td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
                </Row>
                <br/>
            </>
        )
    }

    const onSignupSuccess = () => {
        window.location.reload();
    }

    const onRegister = async (data) => {
        const formData = formRef.current.getFormData();
        let bookingPaymentMethod = null;
        if (paymentRef.current) {
            bookingPaymentMethod = await paymentRef.current.getPaymentMethod(formData);
            if (!bookingPaymentMethod || bookingPaymentMethod.errors) {
                setPaymentError(true);
                return;
            }
        }
        setPaymentError(false);

        const fieldValues = [];
        const keys = _.filter(_.keys(data), (k) => k.startsWith("field_"));
        _.each(keys, (key, i) => {
            fieldValues.push({ fieldId: key.substring(6), data: data[key] });
        })
        const registerData = {
            formId: classDetails.form.id,
            sessionIds: _.map(classDetails.sessions, (s) => s.id),
            payLater: payLater,
            ...calcCost,
            addToWaitlist: props.addToWaitlist,
            formResponse: {
                // userId: userInfo && userInfo.id,
                registrationUser: {
                    name: data.name,
                    email: data.email
                },
                responses: fieldValues
            }
       }
        if (bookingPaymentMethod) {
            if (settings.paymentProcessor === "STRIPE") {
                registerData["paymentData"] = {
                    stripe: bookingPaymentMethod,
                }
            } else if (settings.paymentProcessor === "SPORTSPAY") {
                registerData["paymentData"] = {
                    sportspay: bookingPaymentMethod,
                }
            }
        }
        console.log("Register " + JSON.stringify(registerData));
        serverPost(getApiUrl(`/classes/${classDetails.id}/registrations`), registerData).then(res => {
            if (res) {
                setRegistrationDone(true);
                if (props.onRegistrationComplete) {
                    props.onRegistrationComplete(res);
                }
            }
        })
    }

    const renderRegistration = () => {
        return (
            <>
                <BaseForm ref={formRef} onSubmit={onRegister} onFieldChange={onFieldChange}>
                    <div>
                        <span><strong>Registration Details</strong></span><br/>
                        <span style={{ fontSize: "14px" }}>Enter your account information below. Your registrations will be stored under this email account.</span>
                        <Row>
                            <BaseForm.Input colSpan="6" type="text" name="name" label={t('forms.name')} required/>
                            <BaseForm.Input colSpan="6" type="text" name="email" label={t('forms.email')} required/>
                        </Row>
                    </div>
                    <ClassFormFieldsComponent form={classForm} />
                    { renderPaymentMethod(classDetails) }
                    <br/>
                    <Button variant="primary" type="submit">Register</Button>
                </BaseForm>
            </>
        );
    }

    const renderRegistrationDone = () => {
        return (
            <Row>
                <Col md={{ span: 8, offset: 2}}>
                    <div className="d-flex flex-column gap-12">
                        <h1 className="d-flex justify-content-center">Registration Successful</h1>
                        <Alert className="d-flex justify-content-center" variant="info">
                            <h5>Details will be sent to your email.</h5>
                        </Alert>
                        <div className="d-flex justify-content-center">
                            <Button variant="success" onClick={() => setRegistrationDone(false)}>Submit Another Form</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        <div>
            {
                registrationDone ?
                    renderRegistrationDone()
                : renderRegistration()
            }
        </div>
    )
}

export default ClassRegistrationForm;
