import '../../App.scss';
import '../../css/modals.scss';
import { getAgreementCreationUrl, getAgreementUpdateUrl, serverFetch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Button, Row, Col, Table } from 'react-bootstrap';
const _ = require('lodash');

function AddBookingsToAgreementModal(props) {
    const { getApiUrl, facilityLink } = useContext(BaseContext);
    const navigate = useNavigate();
    const [initialFormFields, setInitialFormFields] = useState(null);
    const [agreements, setAgreements] = useState([]);

    useEffect(() => {
        if (props.show) {
            if (props.usePreloadedAgreements) {
                const orderedAgreements = _.orderBy(props.agreements, ['id'], ['desc']);
                setAgreements(orderedAgreements);
                if (!_.isEmpty(orderedAgreements)) {
                    setInitialFormFields({ selectedAgreement: orderedAgreements[0].uuid });
                }
            } else {
                serverFetch(getApiUrl('/agreements', { groupId: props.groupId })).then((res) => {
                    if (res) {
                        const orderedAgreements = _.orderBy(res, ['id'], ['desc']);
                        setAgreements(orderedAgreements);
                        if (!_.isEmpty(orderedAgreements)) {
                            setInitialFormFields({ selectedAgreement: orderedAgreements[0].uuid });
                        }
                    }
                })
            }
        }
    }, [props.show, props.groupId, props.agreements, props.usePreloadedAgreements]);

    const addToAgreement = (data) => {
        navigate(getAgreementUpdateUrl(facilityLink, data.selectedAgreement, _.map(props.bookings, (b) => b.id)))
    }

    const createNewAgreement = () => {
        navigate(getAgreementCreationUrl(facilityLink, props.groupId, _.map(props.bookings, (b) => b.id)))
    }

    if (!props.bookings) {
        return null;
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title><i className="fa fa-file"/> Add bookings to agreement</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm initialFormFields={initialFormFields} onSubmit={addToAgreement}>
                <BaseModal.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Agr #</th>
                                <th>Date</th>
                                <th>Total</th>
                                <th>Paid</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            _.map(agreements, (agreement, i) =>
                                <tr key={i}>
                                    <td>
                                        <BaseForm.CheckboxRadio name="selectedAgreement" value={agreement.uuid}/>
                                    </td>
                                    <td>{agreement.agreementNumber}</td>
                                    <td>{agreement.agreementDate}</td>
                                    <td>{agreement.totalFormatted}</td>
                                    <td>{agreement.paidAmountFormatted}</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                    {
                        _.isEmpty(agreements) &&
                            <p>There are no existing agreements for this customer. Please create a new agreement.</p>
                    }
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <Button variant="link" onClick={() => props.onClose()}>Close</Button>
                            {
                                !_.isEmpty(agreements) &&
                                    <SubmitButton variant="primary">Add to Selected Agreement</SubmitButton>
                            }
                            <Button variant="success" onClick={createNewAgreement}>Create New Agreement</Button>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default AddBookingsToAgreementModal;
