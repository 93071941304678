import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Notification from '../../components/Notification';
import BaseContainer from '../../components/Container';
import BaseForm from '../../components/BaseForm';
import TabHeader from '../../components/TabHeader';
import SubmitButton from '../../components/SubmitButton';
import { serverFetch, serverPatch, serverPost } from '../../helpers/server';
import { getFormattedName, BaseContext, hasAccess } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Table, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function NotificationList() {
    const { getApiUrl, facilityLink, getFacilityName, userInfo } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [venues, setVenues] = useState([]);
    const [adminAccounts, setAdminAccounts] = useState([]);
    const [adminVenueMap, setAdminVenueMap] = useState({});
    const [notifications, setNotifications] = useState([]);
    const [notificationMap, setNotificationMap] = useState({});
    const canUpdate = hasAccess("settings", userInfo, "update");

    useEffect(() => {
        document.title = t("setting.setting.admin_notification") + " - " + getFacilityName();
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl('/admins')).then((res) => {
            setAdminAccounts(res);
        })

        serverFetch(getApiUrl('/venues'), { skipAddingCategory: true }).then((res) => {
            setVenues(res);
        })

        fetchData();
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl('/notifications'), { skipCache }).then((res) => {
            setNotifications(res);
        })
    }

    useEffect(() => {
        if (_.isEmpty(venues) || _.isEmpty(adminAccounts)) {
            return;
        }
        setAdminVenueMap((previousAdminVenueMap) => {
            const newMap = {...previousAdminVenueMap};
            _.each(adminAccounts, (account) => {
                newMap[account.id] = newMap[account.id] || venues[0].id;
            });
            return newMap;
        });

        const newNotificationMap = {};
        _.each(adminAccounts, (account) => {
            newNotificationMap[account.id] = {};
            _.each(venues, (venue, i) => {
                newNotificationMap[account.id][venue.id] = {
                    venueId: venue.id,
                    sms: "none",
                    email: "none"
                };
            });
        })
        _.each(notifications, (notif) => {
            newNotificationMap[notif.userId][notif.venueId]['sms'] = notif.sms;
            newNotificationMap[notif.userId][notif.venueId]['email'] = notif.email;
        })
        setNotificationMap(newNotificationMap)
    }, [adminAccounts, venues, notifications]);

    const onFieldChange = (account, name, value) => {
        if (name === "venueId") {
            setAdminVenueMap((previousMap) => {
                const newMap = {...previousMap};
                newMap[account.id] = value;
                return newMap;
            });
        }
    }

    const onNotificationUpdate = (account, data) => {
        const notifData = {
            updateAllVenues: data.applyToAll,
            param: {
                userId: account.id,
                sms: data.sms,
                email: data.email,
                venueId: data.venueId,
            }
        }
        serverPost(getApiUrl(`/notifications`), notifData).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
            }
            fetchData(true);
        })
    }

    const communicationOptions = [
        { value: "none", label: t("common.none") },
        { value: "adminBookings", label: t("setting.admins.admin_book") },
        { value: "userBookings", label: t("setting.admins.user_book") },
        false && { value: "adminRequest", label: t("setting.admins.admin_req") },
        { value: "userRequest", label: t("setting.admins.user_req") },
        { value: "allBookings", label: t("setting.admins.all_book") },
        { value: "allRequest", label: t("setting.admins.all_req") },
        { value: "allBookingsRequest", label: t("setting.admins.all_book_req") },
    ]

    const getCurrentNotificationForAdminVenue = (account) => {
        if (_.has(notificationMap, account.id)) {
            return notificationMap[account.id][adminVenueMap[account.id]];
        }
        return null;
    }

    const venueOptions = _.map(venues, (v) => { return { value: v.id, label: v.name } });
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "setting-admins")}/>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="12">
                            <Table hover className="skinny">
                                <thead>
                                    <tr>
                                        <th width="20%">{t('setting.admins.name')}</th>
                                        <th width="21%">{t('common.venue')}</th>
                                        <th width="21%">{t('common.email')}</th>
                                        <th width="10%" className="text-center wrappable">{t('setting.admins.apply_to_all')}</th>
                                        <th width="8%" className="text-end">{t('common.save')}</th>
                                    </tr>
                                </thead>
                            </Table>
                            {
                                _.map(adminAccounts, (account, i) =>
                                    <BaseForm key={i} initialFormFields={getCurrentNotificationForAdminVenue(account)} onSubmit={(data) => onNotificationUpdate(account, data)}
                                        onFieldChange={(name, value) => onFieldChange(account, name, value)}>
                                        <Table hover className="skinny">
                                            <tbody>
                                                <tr key={i} id={account.id}>
                                                    <td width="18%">{getFormattedName(account)}</td>
                                                    <td width="18%">
                                                        <BaseForm.SingleSelect name="venueId" options={venueOptions} showSearch={false} disabled={!canUpdate}/>
                                                    </td>
                                                    <td width="18%">
                                                        <BaseForm.SingleSelect name="email" options={communicationOptions} showSearch={false} disabled={!canUpdate}/>
                                                    </td>
                                                    <td width="10%" className="text-center wrappable">
                                                        <BaseForm.Check className="lg-switch" name="applyToAll" disabled={!canUpdate}/>
                                                    </td>
                                                    <td width="8%" className="text-end">
                                                    {
                                                        canUpdate &&
                                                            <SubmitButton variant="success" size="md"><i className="fa fa-save"/></SubmitButton>
                                                    }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </BaseForm>
                                )
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </BaseContainer>
    );
}

export default NotificationList;
