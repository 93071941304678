import { useEffect, useState } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import BaseForm from '../../components/BaseForm';
import { Alert, Row, Col, Form, Table, ButtonToolbar, Button } from 'react-bootstrap';
import $ from 'jquery';
const _ = require("lodash");

function Playground() {

    const data = [
 {
   "link": "SMAC",
   "id": 1
 },
 {
   "link": "recdemo",
   "id": 2
 },
 {
   "link": "bmo",
   "id": 4
 },
 {
   "link": "schneiderarena",
   "id": 5
 },
 {
   "link": "coleharbourplace",
   "id": 7
 },
 {
   "link": "cb",
   "id": 10
 },
 {
   "link": "bowness",
   "id": 11
 },
 {
   "link": "holidayrinks",
   "id": 14
 },
 {
   "link": "DPSI",
   "id": 16
 },
 {
   "link": "nicholsschool",
   "id": 17
 },
 {
   "link": "thepond",
   "id": 19
 },
 {
   "link": "holidayrinks",
   "id": 20
 },
 {
   "link": "eastcoastvarsity",
   "id": 21
 },
 {
   "link": "schneiderarena",
   "id": 22
 },
 {
   "link": "paramounticeOLD",
   "id": 23
 },
 {
   "link": "paramounticeOLD",
   "id": 24
 },
 {
   "link": "paramounticeOLD",
   "id": 25
 },
 {
   "link": "paramounticeOLD",
   "id": 26
 },
 {
   "link": "paramounticeOLD",
   "id": 27
 },
 {
   "link": "dunedinicestadium",
   "id": 30
 },
 {
   "link": "brownstownsportscenter",
   "id": 32
 },
 {
   "link": "eastcoastvarsity",
   "id": 34
 },
 {
   "link": "harborcenter",
   "id": 35
 },
 {
   "link": "harborcenter",
   "id": 37
 },
 {
   "link": "waegwoltic",
   "id": 38
 },
 {
   "link": "veronaice",
   "id": 43
 },
 {
   "link": "hudsonhockey",
   "id": 45
 },
 {
   "link": "recdemo",
   "id": 46
 },
 {
   "link": "mofb",
   "id": 47
 },
 {
   "link": "paradice",
   "id": 48
 },
 {
   "link": "mountstcharlesacademy",
   "id": 49
 },
 {
   "link": "cicerotwinrinks",
   "id": 50
 },
 {
   "link": "cicerotwinrinks",
   "id": 51
 },
 {
   "link": "truro",
   "id": 52
 },
 {
   "link": "truro",
   "id": 53
 },
 {
   "link": "truro",
   "id": 55
 },
 {
   "link": "truro",
   "id": 56
 },
 {
   "link": "asc",
   "id": 57
 },
 {
   "link": "asc",
   "id": 58
 },
 {
   "link": "asc",
   "id": 59
 },
 {
   "link": "tcice",
   "id": 61
 },
 {
   "link": "memramcook",
   "id": 62
 },
 {
   "link": "townofsackville",
   "id": 63
 },
 {
   "link": "memramcook",
   "id": 64
 },
 {
   "link": "wikyhealth",
   "id": 65
 },
 {
   "link": "scheelsiceplex",
   "id": 66
 },
 {
   "link": "BIC",
   "id": 68
 },
 {
   "link": "mofb",
   "id": 69
 },
 {
   "link": "gateway",
   "id": 70
 },
 {
   "link": "paradice",
   "id": 71
 },
 {
   "link": "natsacademy",
   "id": 72
 },
 {
   "link": "BIC",
   "id": 73
 },
 {
   "link": "TheRecPlex",
   "id": 74
 },
 {
   "link": "BIC",
   "id": 75
 },
 {
   "link": "BIC",
   "id": 76
 },
 {
   "link": "lumsdenrink",
   "id": 77
 },
 {
   "link": "halifaxforum",
   "id": 78
 },
 {
   "link": "halifaxforum",
   "id": 79
 },
 {
   "link": "scottsvilleicearena",
   "id": 80
 },
 {
   "link": "waegwoltic",
   "id": 81
 },
 {
   "link": "waegwoltic",
   "id": 82
 },
 {
   "link": "waegwoltic",
   "id": 83
 },
 {
   "link": "waegwoltic",
   "id": 84
 },
 {
   "link": "ChesterfieldHockey",
   "id": 86
 },
 {
   "link": "niagarauniversity-dwyerarena",
   "id": 87
 },
 {
   "link": "cottagegroveicearena",
   "id": 88
 },
 {
   "link": "ChesterfieldHockey",
   "id": 89
 },
 {
   "link": "meridiancentre",
   "id": 90
 },
 {
   "link": "eastcoastvarsity",
   "id": 91
 },
 {
   "link": "cottagegroveicearena",
   "id": 92
 },
 {
   "link": "mofb",
   "id": 93
 },
 {
   "link": "coyotescommunityicecenter",
   "id": 94
 },
 {
   "link": "lumsdenrink",
   "id": 95
 },
 {
   "link": "lumsdenrink",
   "id": 96
 },
 {
   "link": "brittanydemo",
   "id": 97
 },
 {
   "link": "TheRecPlex",
   "id": 98
 },
 {
   "link": "TheRecPlex",
   "id": 99
 },
 {
   "link": "brittanydemo",
   "id": 100
 },
 {
   "link": "cia",
   "id": 101
 },
 {
   "link": "cia",
   "id": 102
 },
 {
   "link": "cia",
   "id": 103
 },
 {
   "link": "cia",
   "id": 104
 },
 {
   "link": "kmdhockey",
   "id": 105
 },
 {
   "link": "brittanydemo",
   "id": 106
 },
 {
   "link": "brittanydemo",
   "id": 107
 },
 {
   "link": "rodmanarena",
   "id": 108
 },
 {
   "link": "rodmanarena",
   "id": 109
 },
 {
   "link": "rodmanarena",
   "id": 110
 },
 {
   "link": "centreice",
   "id": 111
 },
 {
   "link": "centreice",
   "id": 112
 },
 {
   "link": "centreice",
   "id": 113
 },
 {
   "link": "TheRecPlex",
   "id": 114
 },
 {
   "link": "TheRecPlex",
   "id": 115
 },
 {
   "link": "TheRecPlex",
   "id": 116
 },
 {
   "link": "bgiceplex",
   "id": 117
 },
 {
   "link": "marslakeview",
   "id": 118
 },
 {
   "link": "bridgewatericearena",
   "id": 119
 },
 {
   "link": "bgiceplex",
   "id": 120
 },
 {
   "link": "bgiceplex",
   "id": 121
 },
 {
   "link": "bgiceplex",
   "id": 122
 },
 {
   "link": "bgiceplex",
   "id": 123
 },
 {
   "link": "bgiceplex",
   "id": 124
 },
 {
   "link": "bgiceplex",
   "id": 125
 },
 {
   "link": "bog_rockland",
   "id": 126
 },
 {
   "link": "foxborosportscenter",
   "id": 127
 },
 {
   "link": "foxborosportscenter",
   "id": 128
 },
 {
   "link": "foxborosportscenter",
   "id": 129
 },
 {
   "link": "ymcaofmvarena",
   "id": 130
 },
 {
   "link": "scheelsarena",
   "id": 131
 },
 {
   "link": "bridgewatericearena",
   "id": 132
 },
 {
   "link": "scheelsarena",
   "id": 133
 },
 {
   "link": "scheelsarena",
   "id": 134
 },
 {
   "link": "shamrockshockey",
   "id": 135
 },
 {
   "link": "flinticeland",
   "id": 136
 },
 {
   "link": "shamrockshockey",
   "id": 137
 },
 {
   "link": "kcicecenter",
   "id": 139
 },
 {
   "link": "kmdhockey",
   "id": 140
 },
 {
   "link": "websterarena",
   "id": 141
 },
 {
   "link": "shamrockshockey",
   "id": 142
 },
 {
   "link": "rodmanarena",
   "id": 143
 },
 {
   "link": "hawkdomerink",
   "id": 144
 },
 {
   "link": "shamrockshockey",
   "id": 145
 },
 {
   "link": "FCA",
   "id": 146
 },
 {
   "link": "FCA",
   "id": 147
 },
 {
   "link": "FCA",
   "id": 148
 },
 {
   "link": "FCA",
   "id": 149
 },
 {
   "link": "FCA",
   "id": 150
 },
 {
   "link": "FCA",
   "id": 151
 },
 {
   "link": "thayersportscenter",
   "id": 153
 },
 {
   "link": "madisoniceinc",
   "id": 154
 },
 {
   "link": "madisoniceinc",
   "id": 155
 },
 {
   "link": "CantonSportsplex",
   "id": 156
 },
 {
   "link": "falmouthicearena",
   "id": 157
 },
 {
   "link": "flyingdust",
   "id": 158
 },
 {
   "link": "etrinks",
   "id": 159
 },
 {
   "link": "etrinks",
   "id": 160
 },
 {
   "link": "rectimestest",
   "id": 161
 },
 {
   "link": "bog_rockland",
   "id": 162
 },
 {
   "link": "mprbicearenas",
   "id": 163
 },
 {
   "link": "mprbicearenas",
   "id": 164
 },
 {
   "link": "TonyKentArena",
   "id": 165
 },
 {
   "link": "cbrm",
   "id": 166
 },
 {
   "link": "iceden",
   "id": 167
 },
 {
   "link": "therinksatsummitcentre",
   "id": 168
 },
 {
   "link": "therinksatsummitcentre",
   "id": 169
 },
 {
   "link": "TonyKentArena",
   "id": 170
 },
 {
   "link": "bmo",
   "id": 171
 },
 {
   "link": "bmo",
   "id": 172
 },
 {
   "link": "redbankarmory",
   "id": 173
 },
 {
   "link": "bgiceplex",
   "id": 174
 },
 {
   "link": "bochicecenter",
   "id": 175
 },
 {
   "link": "bochicecenter",
   "id": 176
 },
 {
   "link": "rodmanarena",
   "id": 177
 },
 {
   "link": "brittanydemo",
   "id": 178
 },
 {
   "link": "foxborosportscenter",
   "id": 179
 },
 {
   "link": "foxborosportscenter",
   "id": 180
 },
 {
   "link": "tcice",
   "id": 182
 },
 {
   "link": "bmo",
   "id": 183
 },
 {
   "link": "bog_rockland",
   "id": 184
 },
 {
   "link": "appletonice",
   "id": 185
 },
 {
   "link": "appletonice",
   "id": 186
 }
];

    const prev = () => {
        setCounter(counter - 1);
    }
    const next = () => {
        setCounter(counter + 1);
    }
    const onKeyUp = (event, value) => {
        if (event.keyCode === 13) {
            const value = event.target.value;
            setCounter(parseInt(value));
        }
    }

    const [counter, setCounter] = useState(0);
    const entry = data[counter];

    const oldUrl = "https://rectimes.app/" + entry.link + "/mediascreen/preview?id=" + entry.id;
    const baseWebappUrl = process.env.REACT_APP_WEBAPP_BASE_URL || "http://localhost:3000";
    const newUrl = baseWebappUrl + "/" + entry.link + "/mediascreen/preview?id=" + entry.id;

    console.log("The urls are " + oldUrl + ", " + newUrl);

    return (
        <div className="content-container">
            <ButtonToolbar>
                <Button variant="outline-primary" onClick={prev}>Previous</Button>
                <Button variant="outline-primary" onClick={next}>Next</Button>
                <Form.Control style={{ width: "100px", display: "inline" }} type="text" defaultValue={counter} onKeyUp={onKeyUp} />
                <span>{JSON.stringify(entry)},</span>
                <span>{counter},</span>
                <span>{oldUrl},</span>
                <span>{newUrl}</span>
            </ButtonToolbar>
            <div>
            <iframe style={{ left: "0%", width: "50%", height :"1000px", float: "left", overflow: "scroll" }} src={oldUrl} />
            <iframe style={{ left: "50%", width: "50%", height :"1000px", overflow: "scroll" }} src={newUrl} />
            </div>
        </div>
    );
}

export default Playground;
