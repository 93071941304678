import '../App.scss';
import '../css/modals.scss';
import React, {useState, useEffect, useContext, useImperativeHandle, forwardRef} from 'react';
import BaseModal from './modals/BaseModal';
import BaseForm from './BaseForm';
import SubmitButton from './SubmitButton';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BaseContext } from '../helpers/common';
import { timeOptions } from '../helpers/input';
import moment from 'moment';
const _ = require("lodash");

const ClassFormFieldsComponent = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        getFieldValues(data) {
            return getFieldValues(data);
        },
    }));

    const { t } = useTranslation('common');
    const [form, setForm] = useState({});

    useEffect(() => {
        setForm(props.form)
    }, [props.form]);

    const getFieldValues = (data) => {
        const fieldValues = [];
        const keys = _.keys(data);
        _.each(keys, (key, i) => {
            if (key.startsWith("field_")) {
                fieldValues.push({ fieldId: key.substring(6), data: data[key] });
            }
        })
        return fieldValues;
    }

    const renderField = (field, i) => {
        const fieldTypeMap = {
            'text': 'text',
            'select': 'select',
            'date': 'date',
            'phone': 'text',
            'float': 'number',
            'email': 'text'
        }
        const type = fieldTypeMap[field.type] || field.type;
        const options = _.map(field.options, (o) => { return {'label': o, 'value': o}; });
        const fieldId = String(field.id);
        const extraOptions = {};
        switch(field.type) {
            case 'select':
                extraOptions.options = options;
                extraOptions.showSearch = false;
                break;
            case 'email':
                extraOptions.validations = { validEmail: true }
                break;
            case 'phone':
                extraOptions.validations = { validPhone: true }
                break;
        }
        if (type === "agree") {
            return (
                <Col md="6" key={i}>
                    <BaseForm.CheckboxRadio name={`field_${fieldId}`} type="checkbox"
                        value={true} hideLabel visibleLabel="I Agree"
                        label={field.name} validations={{ isChecked: true }} />
                </Col>
            )
        } else if (type === "agreeOrDisagree") {
            return (
                <React.Fragment key={i}>
                    <Col md="4">
                        <BaseForm.CheckboxRadio name={`field_${fieldId}`} type="radio" hideLabel
                            value={true} label="I Agree" required={field.required} />
                    </Col>
                    <Col md="4">
                        <BaseForm.CheckboxRadio name={`field_${fieldId}`} type="radio" hideLabel
                            value={false} label="I Disagree"  required={field.required} />
                    </Col>
                </React.Fragment>
            )
        } else {
            return (
                <BaseForm.Input
                    {...field} key={i} colSpan={6} id={`field_${fieldId}`} name={`field_${fieldId}`} label={field.name}
                    type={fieldTypeMap[field.type] || field.type} required={field.required}
                    {...extraOptions}
                />
            );
        }
    }

    const renderSection = (section, i) => {
        return (
            <>
            <span><strong>{ section.title }</strong></span><br/>
            {
                section.subtitle &&
                    <div style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{__html: section.subtitle }} />
            }
            <div key={i}>
                <Row>
                {
                    _.map(section.fields, (field, j) =>
                        renderField(field, j)
                    )
                }
                </Row>
                <br/>
            </div>
            </>
        );
    }

    return (
        <>
            {
                !_.isEmpty(form) && _.map(form.sections, (section, i) => renderSection(section, i))
            }
        </>
    );

})

export default ClassFormFieldsComponent;
