import { useEffect, useState, useContext, useMemo } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import SubmitButton from '../../components/SubmitButton';
import BaseForm from '../../components/BaseForm';
import {serverFetch, serverPatch, serverPost} from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { getValueFromEvent } from '../../helpers/input';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { useTranslation } from 'react-i18next';
import { Form, Button, Alert, Row } from 'react-bootstrap';
import Notification from "../../components/Notification";
import Loader from '../../components/Loader';

function SettingClasses() {
    const { settings, userInfo, getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const initialFields = useMemo(() => {}, []);
    const [loading, setLoading] = useState(true);

    const previewUrl = "/" + facilityLink + "/registration/step-1"
    const embedUrl = window.location.origin + "//assets/rectimes.embed.js?i=" + facilityLink;
    const embedGroupUrl = window.location.origin + "//assets/rectimes.embed.js?i=" + facilityLink + "&groupId=ADDGROUPID";
    const directPreviewUrl = window.location.origin + "/" + facilityLink + "/registration/step-1";
    const directGroupPreviewUrl = window.location.origin + "/" + facilityLink + "/registration/step-1?groupId=ADDGROUPID";

    useEffect(() => {
        document.title = "Classes Settings - " + getFacilityName();
    }, []);

    useEffect(() => {
        setLoading(false);
    }, []);

    const updateClassSettings = (data) => {
        serverPatch(getApiUrl('/settings'), data).then((res) => {
            Notification.Show(t('notification.successfully_updated'));
        });
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "setting-classes")}/>
            <Loader loading={loading}>
            <div className="content-box">
                <div className="content-header">
                    <span className="content-title">{t('setting_classes.settings.title')}</span>
                </div>
                <div className="content-body">
                        {/* <Row className="hide">
                            <BaseForm.Input colSpan="6" name="showClasses" type="check"
                                            label={<span>{t('setting_classes_embed.design.forms.show_classes_in_navbar')} <i className="fa fa-small fa-question-circle op-5"></i></span>} />
                            <BaseForm.Input colSpan="6" name="maxClassNumber" type="number"
                                            label={t('setting_classes_embed.design.forms.num_classes')}
                                            placeholder={t('setting_classes.settings.max_class.placeholder')} />
                            <BaseForm.Input colSpan="6" name="maxClassStartDays" type="number"
                                            label={t('setting_classes_embed.design.forms.start_showing_from')}
                                            placeholder={t('setting_classes.settings.max_class.placeholder')} />
                            <BaseForm.Input colSpan="6" name="maxClassEndDays" type="number"
                                            label={t('setting_classes_embed.design.forms.stop_showing_after')}
                                            placeholder={t('setting_classes.settings.max_class.placeholder')} />
                            <BaseForm.Input colSpan="6" name="allowClassWaitlist" type="check"
                                            label={t('setting_classes.settings.allow_wait_list')} />
                        </Row> */}
                </div>
            </div>
            <div className="content-box">
                <div className="content-header">
                    <span className="content-title">{t('setting_classes_embed.embed_code.title')}</span>
                </div>
                <div className="content-body">
                    <p>{t('setting_classes_embed.embed_code.description')}</p>
                    <pre><code>{`<script src=`+embedUrl+` async></script>`}</code></pre>
                    <p>{t('setting_classes_embed.embed_code.description_group')}</p>
                    <pre><code>{`<script src=`+embedGroupUrl+` async></script>`}</code></pre>
                </div>
            </div>
            <div className="content-box">
                <div className="content-header">
                    <span className="content-title">{t('setting_classes_embed.direct_preview.title')}</span>
                </div>
                <div className="content-body">
                    <p>{t('setting_classes_embed.direct_preview.description')}</p>
                    <pre><a href={directPreviewUrl}>{directPreviewUrl}</a></pre>
                    <p>{t('setting_classes_embed.direct_preview.description_group')}</p>
                    <pre><a href={directGroupPreviewUrl}>{directGroupPreviewUrl}</a></pre>
                </div>
            </div>
            {
                userInfo.isSuperAdmin &&
                <div className="content-box">
                    <div className="content-header">
                        <span className="content-title">{t('setting_classes_embed.design.title')}</span>
                    </div>
                    <div className="content-body">
                        <Alert variant="warning">
                            <p>Coming Soon: This section is currently only visible to RecTimes Admin Support</p>
                        </Alert>
                        <BaseForm initialFormFields={initialFields} onSubmit={updateClassSettings}>
                            <Row>
                                <BaseForm.Input colSpan="12" name="embedCss" type="editor" label={t('setting_classes_embed.design.forms.embed_css')} />
                            </Row>
                            <Row>
                                <div className="col-sm-8">
                                    <SubmitButton className="btn">{t('setting_classes_embed.design.update')}</SubmitButton>
                                    <Button href={previewUrl} variant="outline-primary" className="btn"><i className="fa fa-small fa-eye"/> {t('setting_classes_embed.design.preview')}</Button>
                                </div>
                            </Row>
                        </BaseForm>
                    </div>
                </div>
            }
            </Loader>
        </BaseContainer>
    );
}

export default SettingClasses;
