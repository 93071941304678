import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Notification from "../Notification";
import {useTranslation} from "react-i18next";
const _ = require("lodash");

function CancelBulkBookingModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [bookingIds, setBookingIds] = useState([]);

    useEffect(() => {
        setBookingIds(props.bookingIds);
    }, [props.bookingIds])

    const onSubmit = (data) => {
        const cancelData = {
            ids: bookingIds,
            updateInvoice: data.updateInvoice,
            updateAgreement: data.updateAgreement,
            notifyCustomer: data.notifyCustomer
        }
        serverPost(getApiUrl('/bookings/batch_cancel'), cancelData).then((res) => {
            if (res) {
                Notification.Show("Bookings cancelled successfully");
                props.onClose(true);
            }
        })
    }

    return (
        <BaseModal {...props}>
            <BaseForm onSubmit={onSubmit}>
                <BaseModal.Header>
                    <BaseModal.Title>Cancel Bookings</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <p>Are you sure you want to cancel { bookingIds.length } booking(s)?</p>
                    <Row>
                        <BaseForm.Input colSpan="4" name="updateInvoice" label={<span><i className="fa fa-file"/> Update INV</span>} type="check" />
                        <BaseForm.Input colSpan="4" name="updateAgreement" label={<span><i className="fa fa-pencil-square"/> Update AGR</span>} type="check" />
                        <BaseForm.Input colSpan="4" name="notifyCustomer" label={t('Notify')} type="check" />
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="danger">Cancel Bookings</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );
}

export default CancelBulkBookingModal;
