import '../../App.scss';
import '../../css/modals.scss';
import { Fragment, useState, useEffect, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import { Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { serverPost, serverPatch } from '../../helpers/server';
import { getNameForDayOfWeek, BaseContext } from '../../helpers/common';
import { timeOptions } from '../../helpers/input';
import moment from 'moment';
const _ = require("lodash");

function DailyTimesPrimeTimesModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [type, setType] = useState(null);
    const [initialDailyTimes, setInitialDailyTimes] = useState({});

    useEffect(() => {
        setType(props.type);
    }, [props.type]);

    const updateTimes = (fields) => {
        const data = {};
        if (type === "dailyTimes") {
            data["dailyBlockMessage"] = fields.dailyBlockMessage;
        }
        data[type] = _.map(_.range(7), (d) => {
            return {
                day: d,
                dayStart: fields[String(d)]["startTime"],
                dayEnd: fields[String(d)]["endTime"]
            }
        })
        serverPatch(getApiUrl(`/venues/${props.settings.id}/settings`), data).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
                props.onClose();
            }
        });
    }

    useEffect(() => {
        const dailyTimes = { 'dailyBlockMessage': props.settings.dailyBlockMessage };
        const times = props.settings[props.type];
        _.each(times, (dt) => {
            dailyTimes[dt.day] = {
                startTime: dt.startTime,
                endTime: dt.endTime
            }
        });
        setInitialDailyTimes(dailyTimes);
    }, [props.type, props.settings]);

    return (
        <BaseModal size="lg" {...props}>
        { () =>
            <>
                <BaseModal.Header>
                    <BaseModal.Title>Set Daily Times</BaseModal.Title>
                </BaseModal.Header>
                <BaseForm onSubmit={updateTimes} initialFormFields={initialDailyTimes}>
                    <BaseModal.Body>
                        <Row>
                        {
                            _.map(_.range(1, 8), (i) => {
                                const j = i % 7;
                                return (
                                    <Fragment key={j}>
                                        <BaseForm.Input colSpan="6" name={`${j}.startTime`} label={`${getNameForDayOfWeek(t, j)} Start`} type="select" options={timeOptions(5)} showAll={false} required />
                                        <BaseForm.Input colSpan="6" name={`${j}.endTime`} label={`${getNameForDayOfWeek(t, j)} End`} type="select" options={timeOptions(5)} showAll={false} required />
                                    </Fragment>
                                );
                            })
                        }
                        {
                            props.type === "dailyTimes" &&
                                <BaseForm.Input colSpan="12" type="textarea" name="dailyBlockMessage" label="Customer Message"
                                    placeholder="The message will appear when customers try to select blocked time slots." />
                        }
                        </Row>
                    </BaseModal.Body>
                    <BaseModal.Footer>
                        <Row>
                            <Col md="12" className="text-end">
                                <Button variant="link" onClick={() => props.onClose()}>Close</Button>
                                <SubmitButton variant="primary">Save</SubmitButton>
                            </Col>
                        </Row>
                    </BaseModal.Footer>
                </BaseForm>
            </>
        }
        </BaseModal>
    );

}

export default DailyTimesPrimeTimesModal;
