import '../App.scss';
import { useEffect, useState } from 'react';
import { renderChildren } from '../helpers/common';

function Loader(props) {
    const [loading, setLoading] = useState(props.loading);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    if (loading) {
        return (
            <div className="spinner-border text-secondary"/>
        );
    } else {
        return renderChildren(props);
    }
}

export default Loader;
