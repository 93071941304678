import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import BaseContainer from '../../components/Container';
import BaseForm from '../../components/BaseForm';
import TabHeader from '../../components/TabHeader';
import { serverFetch, serverPatch } from '../../helpers/server';
import { getFormattedName, BaseContext, hasAccess } from '../../helpers/common';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function ReplyToList() {
    const { getApiUrl, getFacilityName, userInfo } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [fields, setFields] = useState({});
    const [replyIds, setReplyIds] = useState([]);
    const [adminAccounts, setAdminAccounts] = useState([]);
    const canUpdate = hasAccess("settings", userInfo, "update");

    useEffect(() => {
        document.title = t("setting.admins.admin_acc") + " - " + getFacilityName();
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl('/admins')).then((res) => {
            setAdminAccounts(res);
        })

        serverFetch(getApiUrl('')).then((res) => {
            const data = {};
            const bookingReplyIds = String(res.bookingReply).split(",");
            _.each(bookingReplyIds, (id) => {
                data['booking_reply_'+id] = true;
            })

            const invoiceReplyIds = String(res.invoiceReply).split(",");
            _.each(invoiceReplyIds, (id) => {
                data['invoice_reply_'+id] = true;
            })
            const agreementReplyIds = String(res.agreementReply).split(",");
            _.each(agreementReplyIds, (id) => {
                data['agreement_reply_'+id] = true;
            })
            setReplyIds({
                "bookingReply": bookingReplyIds,
                "invoiceReply": invoiceReplyIds,
                "agreementReply": agreementReplyIds
            });
            setFields(data);
        })
    }, [facilityLink]);

    const onCheck = (key, value) => {
        const id = key.split("_")[2];
        let idVariable = null;
        if (key.startsWith("booking_reply_")) {
            idVariable = "bookingReply";
        } else if (key.startsWith("invoice_reply_")) {
            idVariable = "invoiceReply";
        } else if (key.startsWith("agreement_reply_")) {
            idVariable = "agreementReply";
        }
        const originalIds = replyIds[idVariable];

        let newIds = null;
        if (value) {
            // Checked
            newIds = _.concat(originalIds, [id]);
        } else {
            // Unchecked
            _.remove(originalIds, (i) => i === id);
            newIds = originalIds;
        }
        const data = {}
        data[idVariable] = newIds.join(",");
        replyIds[idVariable] = newIds
        serverPatch(getApiUrl("/settings"), data).then((res) => {
            setReplyIds(replyIds);
        });
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "setting-admins")}/>
            <div className="content-box">
                <div className="content-body">
                    <BaseForm initialFormFields={fields} onFieldChange={onCheck}>
                    <Row>
                        <Col md="9">
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>{t('setting.admins.name')}</th>
                                        <th>{t('setting.admins.booking')}</th>
                                        <th>{t('setting.admins.invoice')}</th>
                                        <th>{t('setting.admins.agreement')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    _.map(adminAccounts, (account, i) =>
                                        <tr key={i} id={account.id}>
                                            <td>{getFormattedName(account)}</td>
                                            <td>
                                                <BaseForm.Check className="lg-switch" name={"booking_reply_"+account.id} disabled={!canUpdate}/>
                                            </td>
                                            <td>
                                                <BaseForm.Check className="lg-switch" name={"invoice_reply_"+account.id} disabled={!canUpdate}/>
                                            </td>
                                            <td>
                                                <BaseForm.Check className="lg-switch" name={"agreement_reply_"+account.id} disabled={!canUpdate}/>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    </BaseForm>
                </div>
            </div>
        </BaseContainer>
    );
}

export default ReplyToList;
