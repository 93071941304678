import 'jquery-ui/ui/widgets/datepicker';
import { useEffect, useState } from 'react';
import '../../App.scss';
import '../../css/partner.scss';
import BaseContainer from '../../components/Container';
import {getUrl, serverFetch} from '../../helpers/server';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function PartnerDashboard() {
    const { t } = useTranslation('common');

    const [facilities, setFacilities] = useState([]);
    const [facilitiesToShow, setFacilitiesToShow] = useState([]);
    const [query, setQuery] = useState("");

    useEffect(() => {
        document.title = "Partner Dashboard";
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (skipCache = false) => {
        const url = getUrl("/api/v1/facilities", false);
        serverFetch(url, { skipCache }).then((res) => {
            setFacilities(res);
            setFacilitiesToShow(_.sortBy(res, f => f.facilityName));
        });
    }

    const onQueryChange = (newQuery) => {
        setQuery(newQuery);
        setFacilitiesToShow(() => {
            const newFacilities = facilities.filter((facility) => {
                return facility.link.toLowerCase().indexOf(newQuery.toLowerCase()) > -1 ||
                    facility.facilityName.toLowerCase().indexOf(newQuery.toLowerCase()) > -1;
            });

            const sortedFacilities = _.sortBy(newFacilities, f => f.facilityName);
            return sortedFacilities;
        });
    }

    return (
        <BaseContainer>
            <div className="content-box">
                <div className="content-body">
                    <div className="d-flex flex-row gap-6">
                        <h4>LeagueApps - Partner Dashboard</h4>
                    </div>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <h4>{t("header.facilities")}</h4>
                    <p>Here are the list of facilities managed by you. To add a new facility or connect an existing facility, please contact <a href={"mailto:support@rectimes.com"}>support@rectimes.com</a>.</p>
                    <br/>
                    <Row>
                        <Form.Control type="text" name="query" placeholder={t("facilities.list.search_facilities")} value={query} onChange={(event) => { onQueryChange(event.target.value) }}/>
                    </Row>
                    <Row>
                    {
                        _.map(facilitiesToShow, (facility, p) =>
                            <Col lg="4" key={p} className="partner-facility-item-wrapper">
                                <div className="partner-facility-item">
                                    <div className="partner-facility-item-header">
                                        <span>{ facility.facilityName }</span>
                                    </div>
                                    <div className="partner-facility-item-body">

                                    </div>
                                    <div className="partner-facility-item-footer">
                                        <span className="flex-grow-1"><strong>{ facility.rinkCount } Venues</strong></span>
                                        <Link to={`/${facility.link}`}>
                                            <Button variant="primary">Go to Facility</Button>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        )
                    }
                   </Row>
                </div>
            </div>
        </BaseContainer>
    );
}

export default PartnerDashboard;
