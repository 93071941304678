import '../../App.scss';
import '../../css/modals.scss';
import $ from 'jquery';
import 'magicsuggest/magicsuggest.js';
import 'jquery-ui/ui/widgets/autocomplete';
import React, { useEffect, useState, useContext, createRef } from 'react';
import BookingGroupsSelector from '../BookingGroupsSelector';
import Notification from '../Notification';
import Loader from '../Loader';
import BookingRow from '../BookingRow';
import TabHeader from '../TabHeader';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import classnames from 'classnames';
import { useParams } from "react-router-dom";
import {Form, InputGroup, Button, Row, Container, Col, Table} from 'react-bootstrap';
import { getUrl, serverFetch, serverPost, notifyEvent } from '../../helpers/server';
import {
    currencyFormat,
    BaseContext,
    getNameForDayOfWeek,
    hasAccess,
    getDescriptionForRepeatBookings,
    conciseTimeDisplay
} from '../../helpers/common';
import { getValueFromEvent } from '../../helpers/input';
import { useTranslation } from 'react-i18next';
import { dayOptions, timeOptions } from '../../helpers/input'
import moment from 'moment';
const _ = require("lodash");

function CancelledBookingModal(props) {
    const { getApiUrl, isLoggedIn, userInfo } = useContext(BaseContext);
    const { facilityLink } = useParams();
    const [loading, setLoading] = useState(true);
    const [booking, setBooking] = useState({});
    const [initialFields, setInitialFields] = useState({});
    const [logs, setLogs] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [currentTab, setCurrentTab] = useState("details");
    const [cancelRepeatType, setCancelRepeatType] = useState("no");
    const { t } = useTranslation('common');
    const bookingRowRef = createRef();

    useEffect(() => {
        if (!props.show) {
            return;
        }
        if (_.isNil(props.booking)) {
            return;
        }
        setBooking(props.booking);
        setInitialFields({
            ...props.booking,
            taxRatePercent: props.booking.taxPercentage,
            startTime: moment(props.booking.startTimeLocal).format("HH:mm:00"),
            endTime: moment(props.booking.endTimeLocal).format("HH:mm:00"),
            repeatStartDate: moment(props.booking.startTimeLocal).format("YYYY-MM-DD"),
            repeatEndDate: moment(props.booking.startTimeLocal).add(7, 'days').format("YYYY-MM-DD"),
            updateInvoice: true,
            updateAgreement: true,
            costDescription: `${currencyFormat(props.booking.preCost)} + ${currencyFormat(props.booking.totalTax)} = ${currencyFormat(props.booking.preCost + props.booking.totalTax)}`
        });
        setLoading(false);
        setLogs(props.booking.logs);
        serverFetch(getApiUrl('/event_types', { venueIds: [props.booking.venueId] }), { skipAddingCategory: true }).then((res) => {
            setEventTypes(res);
        });
    }, [props.booking]);

    useEffect(() => {
        if (!props.show) {
            setCurrentTab("details");
        }
    }, [props.show]);

    const repeatTypeOptions = [
        { 'value': "no", 'label': 'No' },
        { 'value': "all", 'label': 'All' },
        { 'value': "following", 'label': 'Following' },
        { 'value': "previous", 'label': 'Previous' },
        { 'value': "dateRange", 'label': 'Date Range' },
    ];

    const tabItems = [
        { value: "details", label: "Details", icon: "fa-book", active: currentTab === "details", onClick: () => setCurrentTab("details") },
        { value: "repeat", label: "Repeat", icon: classnames("fa-retweet", booking.isRepeat ? "fa-c-success": ""), active: currentTab === "repeat", onClick: () => setCurrentTab("repeat") },
        { value: "notes", label: "Notes", icon: "fa-comment", active: currentTab === "notes", onClick: () => setCurrentTab("notes") },
        { value: "changes", label: t('tabs.booking_modal.changes'), icon: "fa-edit", active: currentTab === "changes", onClick: () => setCurrentTab("changes") }
    ];

    const rightTabItems = [];
    if (!_.isEmpty(booking.invoice)) {
        rightTabItems.push({ label: booking.invoice.invoiceNumber, icon: "fa-file", link: `/${facilityLink}/invoice/view?uuid=${booking.invoice.uuid}` });
    }
    if (!_.isEmpty(booking.agreement)) {
        rightTabItems.push({ label: booking.agreement.agreementNumber, icon: "fa-pencil-square fa-large", link: `/${facilityLink}/agreement/accept?uuid=${booking.agreement.uuid}` });
    }

    const onFieldChange = (name, value) => {
        if (_.includes(["venueId", "startTime", "endTime", "eventTypeId"], name)) {
            if (bookingRowRef.current) {
                bookingRowRef.current.onFieldChange(name, value);
            }
        }
    }

    const getLogActionDescription = (log) => {
        let icon = <span/>;
        let actionName = "";
        if (log.action === "add") {
            icon = <i className="fa fa-book"/>
            actionName = "Created";
        } else if (log.action === "update") {
            icon = <i className="fa fa-edit"/>
            actionName = "Updated";
        } else if (log.action === "cancel") {
            icon = <i className="fa fa-cancel"/>
            actionName = "Cancelled";
        } else {
            console.log("The log is " + JSON.stringify(log));
        }
        return (
            <>{ icon } <span>{ actionName } : {moment(log.changedDateLocal).format("MMM D, YYYY - hh:mma")}</span></>
        );
    }

    const renderChanges = () => {
        return (
            <div className={classnames((currentTab === "changes" || currentTab === "paid") ? "": "hide")}>
                <p style={{ fontStyle: "italic" }}>All timezones are shown in the facility's timezone.</p>
                <Table>
                    <thead>
                    <tr>
                        <th>Booking Action</th>
                        <th>Time Slot</th>
                        <th>Total</th>
                        <th>Payment</th>
                        <th>Admin</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        _.map(_.sortBy(logs, (l) => moment(l.changedDateLocal)), (log, i) =>
                            <tr key={i}>
                                <td>{ getLogActionDescription(log) }</td>
                                <td>{ `${moment(log.startTimeLocal).format("ddd, MMM D, YYYY ")}${conciseTimeDisplay(moment(log.startTimeLocal))} - ${conciseTimeDisplay(moment(log.endTimeLocal))}` } ({ log.venueName })</td>
                                <td>{ currencyFormat(log.cost) }</td>
                                <td>{ `${log.paymentMethod}${log.paymentMethod === "Credit Card Online" ? ` (Fee: ${currencyFormat(log.ccFees, true)})` : ""}` }</td>
                                <td>{ log.changedByName }</td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </div>
        );
    };

    return (
        <BaseModal {...props} size="lg">
        <Loader loading={loading}>
        { () =>
            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                <BaseModal.Header>
                    <BaseModal.Title>Cancel Booking #{booking.id} - <span id="pop-date" className="pop-date">{moment(booking.startTimeLocal).format("ddd, MMM DD, YYYY")}&nbsp;&nbsp;<i className="fa fa-calendar"/></span></BaseModal.Title>
                </BaseModal.Header>
                <TabHeader items={tabItems} rightItems={rightTabItems}/>
                <BaseModal.Body>
                    <div className={classnames(currentTab === "details" ? "": "hide")}>
                        <BookingGroupsSelector name="bookingGroups" disabled={true} showGroupDetailsList={true} />
                        <BookingRow ref={bookingRowRef} eventTypes={eventTypes} disabled={true} showAllTimes={true} isAdminBooking={true} />
                    </div>
                    <Row className={classnames(currentTab === "repeat" ? "": "hide")}>
                        <div className="repeat-info">{ getDescriptionForRepeatBookings(t, booking.repeatInfo) }</div>
                    </Row>
                    <Row className={classnames(currentTab === "notes" ? "": "hide")}>
                        <BaseForm.Input colSpan="12" name="note" className="large" label={t('forms.notes')} type="textarea" placeholder={t('Private admin information about this booking')} />
                    </Row>
                    { renderChanges() }
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Container>
                        <Row>
                            <Col md="10"/>
                            <Col md="2" className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" onClick={() => props.onClose(true)}>Close</Button>
                            </Col>
                        </Row>
                    </Container>
                </BaseModal.Footer>
            </BaseForm>
        }
        </Loader>
        </BaseModal>
    );

}

export default CancelledBookingModal;
