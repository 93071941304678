import '../../App.scss';
import '../../css/modals.scss';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState, useRef, useContext} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import Notification from '../Notification';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Select from 'react-select/async'
import {BaseContext} from '../../helpers/common';
import { serverPatch } from '../../helpers/server';
import {Row, Col, Container} from 'react-bootstrap';

const _ = require('lodash');

function CopyVenueSettingsModal(props) {
    const {getApiUrl} = useContext(BaseContext);
    const {t} = useTranslation('common');
    const navigate = useNavigate();
    const [targetVenueId, setTargetVenueId] = useState(null);
    const [sourceVenueId, setSourceVenueId] = useState(null);

    useEffect(() => {
        setTargetVenueId(props.targetVenueId);
    }, [props.targetVenueId]);

    useEffect(() => {
        setSourceVenueId(props.sourceVenueId);
    }, [props.sourceVenueId]);

    const copyVenueSettings = (data) => {
        console.log("Copy settings from " + sourceVenueId + " to " + targetVenueId);
        const copyData = {
            sourceVenueId: sourceVenueId
        }
        serverPatch(getApiUrl(`/venues/${targetVenueId}/settings/copy`), copyData).then((res) => {
            if (res) {
                Notification.Show("Settings successfully copied");
                if (props.onClose) {
                    props.onClose(true);
                }
            }
        })
    }

    return (
        <>
            <BaseModal {...props}>
                <BaseForm onSubmit={copyVenueSettings}>
                    <BaseModal.Header>
                        <BaseModal.Title>Copy Venue Settings</BaseModal.Title>
                    </BaseModal.Header>
                    <BaseModal.Body>
                        <p>Are you sure you want to copy venue settings?</p>
                        <p className="italic">Note: This is an irreversible action. Please review carefully before proceeding.</p>
                    </BaseModal.Body>
                    <BaseModal.Footer>
                        <Container>
                            <div className="d-flex flex-row justify-content-end">
                                <SubmitButton>{"Copy Settings"}</SubmitButton>
                            </div>
                        </Container>
                    </BaseModal.Footer>
                </BaseForm>
            </BaseModal>
        </>
    );
}

export default CopyVenueSettingsModal;
