import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import { useEffect, useState, useRef, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import MassAgreementEmailModal from '../../components/modals/MassAgreementEmailModal';
import SingleAgreementEmailModal from '../../components/modals/SingleAgreementEmailModal';
import BaseContainer from '../../components/Container';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import ConfirmationButton from '../../components/ConfirmationButton';
import DeleteButton from '../../components/DeleteButton';
import Notification from '../../components/Notification';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import { getUrl, serverFetch, serverPost } from '../../helpers/server';
import { getLimitOptions, dateRangeClassCheck, downloadBlob, hasAccess, BaseContext,
    currencyFormat, getDateFormatForFacility, getDateFormatWithTimeForFacility } from '../../helpers/common';
import { getUserInfo, getCompanyInfo } from '../../helpers/storage';
import { useParams, useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, InputGroup, Form, Button, Row, ButtonToolbar, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import classnames from 'classnames';
import InfiniteScroll from "react-infinite-scroll-component";
const _ = require("lodash");

function List() {
    const { userInfo, getApiUrl, getFacilityName, settings } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const [hasMore, setHasMore] = useState(true);
    const [showMassAgreementEmailModal, setShowMassAgreementEmailModal] = useState(false);
    const [showSingleAgreementEmailModal, setShowSingleAgreementEmailModal] = useState(false);
    const [ searchParams ] = useSearchParams();
    let groupId = searchParams.get('groupId');

    const [searchAllQuery, setSearchAllQuery] = useState("");
    const [query, setQuery] = useState("");
    const [paymentStartDate, setPaymentStartDate] = useState(null);
    const [paymentEndDate, setPaymentEndDate] = useState(null);
    const [activeFilter, setActiveFilter] = useState("active");
    const [sentFilter, setSentFilter] = useState(null);
    const [acceptedFilter, setAcceptedFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [sortFilter, setSortFilter] = useState("idDesc");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [limit, setLimit] = useState(20);

    const [userGroup, setUserGroup] = useState({});
    const [agreements, setAgreements] = useState([]);
    const [checkedItemIds, setCheckedItemIds] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);

    const paymentStartDateRef = useRef();
    paymentStartDateRef.current = paymentStartDate;
    const paymentEndDateRef = useRef();
    paymentEndDateRef.current = paymentEndDate;
    const startDateRef = useRef();
    startDateRef.current = startDate;
    const endDateRef = useRef();
    endDateRef.current = endDate;
    const queryTimer = useRef(null);

    useEffect(() => {
        document.title = "Agreements - " + getFacilityName();
    }, []);

    useEffect(() => {
        if (groupId) {
            serverFetch(getApiUrl(`/user_groups/${groupId}`)).then((res) => {
                setUserGroup(res);
            })
        }
    }, [groupId]);

    useEffect(() => {
        groupId = searchParams.get('groupId')
        onSearch(true);
    }, [searchParams])

    useEffect(() => {
        // Load the options from cache
        const optionsString = localStorage.getItem(facilityLink + "_filter_cache_agreements");
        if (optionsString && !groupId) {
            const options = JSON.parse(optionsString);
            if (options.dateFilter) {
                setDateFilter(options.dateFilter.filterType);
                setStartDate(options.dateFilter.startDate);
                setEndDate(options.dateFilter.endDate);
            }
            if (options.sentFilter) {
                setSentFilter(options.sentFilter)
            }
            if (options.acceptedFilter) {
                setAcceptedFilter(options.acceptedFilter)
            }
            if (options.activeFilter) {
                setActiveFilter(options.activeFilter);
            }
            if (options.sort) {
                setSortFilter(options.sort);
            }
        }
    }, []);

    useEffect(() => {
        onSearch();
    }, [activeFilter, sentFilter, dateFilter, acceptedFilter, limit, query,
        groupId, sortFilter]);

    useEffect(() => {
        clearTimeout(queryTimer.current);
        queryTimer.current = setTimeout((callback, kk, vv) => {
                if (callback) {
                    callback(kk, vv);
                }
                queryTimer.current = null;
            },
            400,
            onSearch,
        );
    }, [searchAllQuery]);

    useEffect(() => {
        setTimeout(() => {
            $('#dateStart').datepicker( "destroy" );
            $('#dateStart').datepicker({
                defaultDate: 0,
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setStartDate(value);
                    setTimeout(() => $('#dateEnd').datepicker( "show" ), 100);
                }
            });
            $('#dateEnd').datepicker( "destroy" );
            $('#dateEnd').datepicker({
                defaultDate: "+1m",
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setEndDate(value)
                }
            });
        }, 100);
    }, [dateFilter, startDate, endDate]);

    useEffect(() => {
        setCheckedItems(_.filter(agreements, (b) => checkedItemIds.includes(getRowId(b))));
    }, [checkedItemIds]);

    const dateFilterChanged = (value) => {
        if (value !== "all" && sortFilter === "idDesc") {
            setSortFilter("agrDateAsc");
        }
        setDateFilter(value);
    }

    const onPrint = () => {
        if (checkedItemIds.length === 0) {
            Notification.Show(t('agreement.list.error_choose_one'));
        } else {
            _.each(checkedItems, async (agreement) => {
                const url = getApiUrl(`/agreements/${agreement.uuid}/pdf`);
                serverPost(url, {}, { noJson: true }).then((res) => {
                    downloadBlob(res, `agreement-${agreement.agreementNumber}`);
                });
            })
        }
    }

    const onEmail = () => {
        const cancelledItems = _.filter(checkedItems, (i) => i.isCancel);
        if (checkedItems.length === 0) {
            Notification.Show(t('agreement.list.error_choose_one'));
        } else if (cancelledItems.length > 0) {
            Notification.Show(t('agreement.list.error.cant_email_cancelled'));
        } else if (checkedItems.length === 1) {
            setShowSingleAgreementEmailModal(true);
        } else {
            setShowMassAgreementEmailModal(true);
        }
    }

    const onDelete = () => {
        if (checkedItemIds.length === 0) {
            Notification.Show(t('agreement.list.error_choose_one'));
        } else {
            const data = {
                uuids: _.map(checkedItems, (item) => item.uuid)
            }
            serverPost(getApiUrl(`/agreements/batch_cancel`), data).then((res) => {
                if (res) {
                    Notification.Show(t('agreement.list.successfully_deleted'));
                    setCheckedItemIds([]);
                    onSearch();
                }
            });
        }
    }

    const cancelAgreement = (agreement) => {
        serverPost(getApiUrl(`/agreements/batch_cancel`), { uuids: [agreement.uuid] }).then((res) => {
            if (res) {
                Notification.Show(t('agreement.list.successfully_deleted'));
                onSearch();
            }
        });
    }

    const onKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    }

    const getQueryParams = () => {
        return {
            groupName: query,
            sentFilter: sentFilter,
            dateFilter: dateFilter && {
                filterType: dateFilter,
                startDate: startDate,
                endDate: endDate
            },
            groupId: groupId,
            activeFilter: activeFilter,
            acceptedFilter: acceptedFilter,
            searchAllQuery: searchAllQuery,
            sort: sortFilter
        }
    }

    const onSearch = (restart = true) => {
        if (dateFilter === "dateRange" && (_.isNil(startDate) || _.isNil(endDate))) {
            return;
        }
        if (!groupId) {
            // Cache the options
            localStorage.setItem(facilityLink + "_filter_cache_agreements", JSON.stringify(getQueryParams()));
        }

        const searchUrl = getApiUrl('/agreements/search');
        const data = {
            pagination: {
                from: restart ? 0 : (agreements.length || 0),
                limit: limit,
            },
            query: getQueryParams()
        };
        if (restart) {
            setAgreements([]);
        }
        serverPost(searchUrl, data).then((res) => {
            setHasMore(res.length >= limit);
            if (restart) {
                setAgreements(res);
            } else {
                setAgreements(_.concat(agreements, res))
            }
        })
    }

    const updateChecked = (event) => {
        if (event.target.value === "checkall") {
            let newCheckedItemIds = [];
            if (event.target.checked) {
                newCheckedItemIds = _.map(agreements, (b, i) => getRowId(b));
                newCheckedItemIds.push("checkall");
            }
            setCheckedItemIds(newCheckedItemIds);
        } else {
            const newCheckedItemIds = [...checkedItemIds];
            if (event.target.checked) {
                newCheckedItemIds.push(event.target.value);
            } else {
                let index = newCheckedItemIds.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedItemIds.splice(index, 1);
                }
                index = newCheckedItemIds.indexOf("checkall");
                if (index > -1) {
                    newCheckedItemIds.splice(index, 1);
                }
            }
            setCheckedItemIds(newCheckedItemIds);
        }
    }

    function getRowId(agreement) {
        return ("" + agreement.id);
    }

    const activeFilterOptions = [
        { 'id': "active", 'label': t("common.active") },
        { 'id': "cancelled", 'label': t("common.cancelled") },
    ];

    const dateFilterOptions = [
        { 'id': "today", 'label': t("common.today") },
        { 'id': "yesterday", 'label': t("common.yesterday") },
        { 'id': "thisWeek", 'label': t("common.this_week") },
        { 'id': "lastWeek", 'label': t("common.last_week") },
        { 'id': "thisMonth", 'label': t("common.this_month") },
        { 'id': "lastMonth", 'label': t("common.last_month") },
        { 'id': "dateRange", 'label': t("common.date_range") },
    ];

    const sentFilterOptions = [
        { 'id': "sent", 'label': t("common.yes") },
        { 'id': "unsent", 'label': t("common.no") },
    ]

    const acceptedFilterOptions = [
        { 'id': "yes", 'label': t("common.yes") },
        { 'id': "no", 'label': t("common.no") },
    ]

    const sortOptions = [
        { 'id': "idDesc", 'label': t("common.id_desc") },
        { 'id': "agrDateAsc", 'label': t("agreement.list.agr_date_asc") },
        { 'id': "agrDateDesc", 'label': t("agreement.list.agr_date_desc") },
        { 'id': "amountAsc", 'label': t("agreement.list.amount_asc") },
        { 'id': "amountDesc", 'label': t("agreement.list.amount_desc") },
    ]

    const dateFormat = getDateFormatForFacility(settings);
    const dateFormatWithTime = getDateFormatWithTimeForFacility(settings);
    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        groupId: groupId,
        userGroup: userGroup,
        userInfo: userInfo,
        settings: settings
    };

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "agreements", tabsParams)} />
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-md-4">
                            <InputGroup>
                                <Form.Control type="text" className="inline" name="searchAllQuery" value={searchAllQuery} onChange={(event) => { setSearchAllQuery(event.target.value) }} onKeyPress={onKeyPress}/>
                                <InputGroup.Text className="skinny" onClick={() => onSearch()}><i className="fa fa-search"/></InputGroup.Text>
                            </InputGroup>
                        </div>
                        <div className="col-md-2">
                        </div>
                        <div className="col-md-6">
                            <div className="float-end d-flex align-items-center">
                                <SingleSelectDropdown className="inline" items={getLimitOptions()} selectedId={limit} onSelect={setLimit} menuOnly/>
                                <Button variant="outline-primary" onClick={onPrint}><i className="fa fa-print"/> {t("common.print")}</Button>
                                <Button variant="outline-primary" onClick={onEmail}><i className="fa fa-envelope"/> {t("common.email")}</Button>
                                {
                                    hasAccess("agreement", userInfo, "update") &&
                                        <DeleteButton size="md" confirmationText={checkedItemIds.length>2 && "DELETE AGREEMENTS"} warningText={checkedItemIds.length>2 && "You are trying to delete a lot of agreements? Are you sure?"} onDelete={onDelete}/>
                                }
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="9" className="text-end d-grid align-items-center">
                            <span><strong>{t("common.sort_order")}:</strong></span>
                        </Col>
                        <Col md="3">
                            <SingleSelectDropdown name="sort" selectedId={sortFilter} items={sortOptions} onSelect={setSortFilter} />
                        </Col>
                    </Row>
                    <InfiniteScroll
                        dataLength={agreements.length}
                        next={() => onSearch(false)}
                        hasMore={hasMore}
                        scrollableTarget="content_wrapper"
                    >
                        <Table hover>
                            <thead>
                                <tr>
                                    <th width="25px"></th>
                                    <th>{t('agreement.list.agreement_number')}</th>
                                    <th>{t('common.group_name')}</th>
                                    <th>{t('common.date')}</th>
                                    <th>{t('common.amount')}</th>
                                    <th>{t('agreement.list.accepted')}</th>
                                    <th>{t('common.sent')}</th>
                                    <th></th>
                                    <th>{t('agreement.list.due_date')}</th>
                                </tr>
                                <tr>
                                    <th className="controls no-stretch">
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedItemIds.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor="checkall"/>
                                        </div>
                                    </th>
                                    <th className="controls"></th>
                                    <th className="controls"><Form.Control type="text" name="query" value={query} onChange={(event) => { setQuery(event.target.value) }}/></th>
                                    <th className="controls">
                                        <SingleSelectDropdown items={dateFilterOptions} selectedId={dateFilter} onSelect={dateFilterChanged} showAll />
                                        {
                                            dateFilter === "dateRange" &&
                                                <BaseForm initialFormFields={{ dateStart: startDate, dateEnd: endDate }}>
                                                    <BaseForm.Control type="text" name="dateStart" id={"dateStart"} />
                                                    <BaseForm.Control type="text" name="dateEnd" id={"dateEnd"} />
                                                    <Button variant="outline-primary" onClick={(event) => onSearch()}><i className="fa fa-refresh fa-small"/></Button>
                                                </BaseForm>
                                        }
                                    </th>
                                    <th className="controls"></th>
                                    <th className="controls"><SingleSelectDropdown items={acceptedFilterOptions} selectedId={acceptedFilter} onSelect={setAcceptedFilter} align={"end"} showAll /></th>
                                    <th className="controls"><SingleSelectDropdown items={sentFilterOptions} selectedId={sentFilter} onSelect={setSentFilter} align={"end"} showAll /></th>
                                    <th className="controls"><SingleSelectDropdown items={activeFilterOptions} selectedId={activeFilter} onSelect={setActiveFilter} align={"end"} showAll /></th>
                                    <th className="controls"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.map(agreements, (agreement, i) =>
                                        <tr key={i} className={classnames(agreement.isCancel && "de-highlighted")}>
                                            <td width="no-stretch">
                                                <div className="checkbox check-success inline">
                                                    <input type="checkbox" className="" value={getRowId(agreement)} id={getRowId(agreement)} name="delete[]"  checked={checkedItemIds.includes(getRowId(agreement))} onChange={ (event) => updateChecked(event) }/>
                                                    <label htmlFor={getRowId(agreement)}/>
                                                </div>
                                            </td>
                                            <td><Link to={`/${facilityLink}/agreement/accept?uuid=${agreement.uuid}`}>{ agreement.agreementNumber }</Link></td>
                                            <td><Link to={`/${facilityLink}/agreement/list?groupId=${agreement.group.id}`}>{ agreement.group.name }</Link></td>
                                            <td>{ moment(agreement.agreementDate).format(dateFormat) }</td>
                                            <td className="text-end"><span className="label">{ currencyFormat(agreement.total) }</span></td>
                                            <td className="text-center">
                                                {
                                                    agreement.isAccepted ?
                                                        <BaseOverlayTrigger content={`Agreement accepted on ${moment(agreement.sentDateLocal).format(dateFormatWithTime)}`}>
                                                            <i className="fa fa-check-circle fa-c-success fs-16"></i>
                                                        </BaseOverlayTrigger>
                                                    : <span className="label">{t("common.no")}</span> }
                                            </td>
                                            <td className="text-center">
                                                {
                                                    agreement.isSent ?
                                                        <BaseOverlayTrigger content={`Agreement sent on ${moment(agreement.sentDateLocal).format(dateFormatWithTime)}`}>
                                                            <i className="fa fa-check-circle fa-c-success fs-16"></i>
                                                        </BaseOverlayTrigger>
                                                    : <span className="label">{t("common.no")}</span> }
                                            </td>
                                            <td className="text-center">
                                            {
                                                hasAccess("agreement", userInfo, "update") &&
                                                    <ButtonToolbar>
                                                        <Link to={`/${facilityLink}/agreement/update?uuid=${agreement.uuid}`}><Button variant="primary"><i className="fa fa-edit"/></Button></Link>
                                                        <ConfirmationButton variant="outline-primary" title="Delete Agreement?" body="Are you sure you want to delete the agreement?" onConfirm={() => cancelAgreement(agreement)}><i className="fa fa-x"/></ConfirmationButton>
                                                    </ButtonToolbar>
                                            }
                                            </td>
                                            <td className="text-center">
                                            {
                                                agreement.dueDateLocal ? moment(agreement.dueDateLocal).format(dateFormat) : <span className="text-black-50">None</span>
                                            }
                                            <br/>
                                            {
                                                agreement.dueDateLocal && moment(agreement.dueDateLocal).isBefore(moment()) && <span className="text-danger">Overdue by {moment(agreement.dueDateLocal).fromNow(true)}</span>
                                            }
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    hasMore ?
                                        <tr>
                                            <td colSpan={11} className="center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                    : <tr>
                                            <td colSpan={11} className="center">
                                                <div className="label">{t('agreement.list.end_of_agreements')}</div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </InfiniteScroll>
                </div>
            </div>
            <SingleAgreementEmailModal show={showSingleAgreementEmailModal} onClose={setShowSingleAgreementEmailModal} agreement={!_.isEmpty(checkedItems) ? checkedItems[0]: null} />
            <MassAgreementEmailModal show={showMassAgreementEmailModal} onClose={setShowMassAgreementEmailModal} agreements={checkedItems} />
        </BaseContainer>
    );
}

export default List;
