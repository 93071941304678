import '../../App.scss';
import '../../css/modals.scss';
import BaseCSVExportModal from './BaseCSVExportModal';
import { getCustomerExportFields } from '../../helpers/exportFields';
import {useParams} from "react-router-dom";
const _ = require("lodash");

function CustomersCSVExportModal(props) {
    let { facilityLink } = useParams();

    return (
        <BaseCSVExportModal
            {...props}
            fields={getCustomerExportFields()}
            filenamePrefix="Customers-Report"
            cacheKey={facilityLink + "_csv_customers"}
            extraItemsParams={props.extraItemsParams}
            exportUrl={'/user_groups/export'} />
    );
}

export default CustomersCSVExportModal;
