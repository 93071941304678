import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import BaseContainer from '../../components/Container';
import DeleteButton from '../../components/DeleteButton';
import AddOrEditAdminAccountModal from '../../components/modals/AddOrEditAdminAccountModal';
import TabHeader from '../../components/TabHeader';
import { serverFetch, serverDelete } from '../../helpers/server';
import { BaseContext, hasAccess } from '../../helpers/common';
import { accessLevelOptions } from '../../helpers/input';
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function AdminList() {
    const { getApiUrl, facilityLink, getFacilityName, userInfo, settings } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [adminAccounts, setAdminAccounts] = useState([]);
    const [adminAccountToEdit, setAdminAccountToEdit] = useState(null);
    const [showAddAdminAccountModal, setShowAddAdminAccountModal] = useState(false);
    const canUpdate = hasAccess("settings", userInfo, "update");

    useEffect(() => {
        document.title = t("setting.admins.admin_acc") + " - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl('/admins'), { skipCache }).then((res) => {
            const options = accessLevelOptions();
            _.each(res, (r) => {
                const op = _.find(options, (o) => o.value === r.accessLevel);
                r.adminAccessLabel = op.label;
            })
            setAdminAccounts(res);
        })
    }

    const onModalClose = () => {
        setShowAddAdminAccountModal(false);
        fetchData(true);
    }

    const addAdminAccount = () => {
        setAdminAccountToEdit(null);
        setShowAddAdminAccountModal(true);
    }

    const editAdminAccount = (account) => {
        setAdminAccountToEdit(account);
        setShowAddAdminAccountModal(true);
    }

    const deleteAdminAccount = (account) => {
        const deleteAdminUrl = getApiUrl(`/admins/${account.id}`);
        serverDelete(deleteAdminUrl, {}).then((res) => {
            fetchData(true);
        })
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "setting-admins", { partner: settings.isPartnerAdminFacility })}/>
            {
                canUpdate &&
                    <div className="content-box">
                        <div className="content-body">
                            <Button variant="outline-primary" onClick={() => addAdminAccount()}><i className="fa fa-plus-circle"></i> {t('setting.admins.add')}</Button>
                        </div>
                    </div>
            }
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-lg-9">
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>{t('setting.admins.name')}</th>
                                        <th>{t('setting.admins.access')}</th>
                                        <th>{t('setting.admins.email')}</th>
                                        <th>{t('setting.admins.phone')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.map(adminAccounts, (account, i) =>
                                            <tr key={i} id={account.id}>
                                                <td><Button variant="alink" onClick={canUpdate ? () => editAdminAccount(account): null}>{account.firstName + " " + account.lastName}</Button></td>
                                                <td>{account.adminAccessLabel}</td>
                                                <td>{account.email}</td>
                                                <td>{account.phone}</td>
                                                <td>
                                                {
                                                    canUpdate &&
                                                        <DeleteButton className="float-end" onDelete={() => deleteAdminAccount(account)}></DeleteButton>
                                                }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Row>
                </div>
            </div>
            <AddOrEditAdminAccountModal show={showAddAdminAccountModal} onClose={onModalClose} adminToEdit={adminAccountToEdit}/>
        </BaseContainer>
    );
}

export default AdminList;
