import '../App.scss';
import { serverFetch } from '../helpers/server'
import { isPartialOrFullAdmin, BaseContext } from '../helpers/common'
import { useEffect, useState, useContext } from 'react';
import { Outlet } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

function RequireAdmin(props) {
    const { userInfo, isLoggedIn, getApiUrl } = useContext(BaseContext);
    const navigate = useNavigate();
    let { facilityLink } = useParams();
    const [loading, setLoading] = useState(true);
    const authorized = isLoggedIn && isPartialOrFullAdmin(userInfo);

    useEffect(() => {
        if (authorized !== null) {
            if (!authorized) {
                navigate('/' + facilityLink);
            } else {
                setLoading(false);
            }
        }
    }, [authorized]);

    return (
      <>
      {
        !loading && <Outlet />
      }
      </>
    );
}

export default RequireAdmin;
