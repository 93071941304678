import { useEffect, useContext } from 'react';
import '../../App.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import { serverPost, getUrl } from '../../helpers/server';
import { storageLogout, storeUserInfo } from '../../helpers/storage';
import { getFacilityName, BaseContext } from '../../helpers/common';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Button, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function UserSpoof() {
    const { getApiUrl } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');

    useEffect(() => {
        document.title = "User Spoof";
    }, []);

    const spoof = (fields) => {
        const url = getApiUrl("/spoof")
        serverPost(getApiUrl("/spoof"), fields).then((res) => {
            if (res && res['token']) {
                // Store the current user info so we can restore later;
                localStorage.setItem("spoof_auth_token", localStorage.getItem("auth_token"));

                storageLogout();
                localStorage.setItem("auth_token", res['token']);
                if (res['user']) {
                    storeUserInfo(res['user']);
                }
                window.location.href = "/" + facilityLink;
            }
        })
    };

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "super-admin")}/>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-md-4">
                            <BaseForm onSubmit={spoof}>
                                <Row>
                                    <BaseForm.Input colSpan={12} name={'email'} label="Email" type={'text'} required />
                                </Row>
                                <SubmitButton className="btn-complete">Spoof</SubmitButton>
                            </BaseForm>
                        </div>
                    </Row>
                </div>
            </div>
        </BaseContainer>
    );
}

export default UserSpoof;
