import React from 'react';
import $ from 'jquery';
import { useEffect, useState, useRef, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import { serverFetch, serverPost } from '../../helpers/server';
import { getLimitOptions, BaseContext, getDateFormatForFacility, getDateFormatWithTimeForFacility } from '../../helpers/common';
import { getCompanyInfo } from '../../helpers/storage';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, InputGroup, Form, Button, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
const _ = require("lodash");

function LiveFeed() {
    const { getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [hasMore, setHasMore] = useState(true);
    const [settings, setSettings] = useState(getCompanyInfo());

    const [searchAllQuery, setSearchAllQuery] = useState("");
    const [logs, setLogs] = useState([]);
    const [logGrouping, setLogGrouping] = useState({});
    const [adminAccounts, setAdminAccounts] = useState([]);
    const [venues, setVenues] = useState([]);
    const [venueFilter, setVenueFilter] = useState(null);
    const [actionFilter, setActionFilter] = useState(null);
    const [adminFilter, setAdminFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [limit, setLimit] = useState(20);

    const startDateRef = useRef();
    startDateRef.current = startDate;
    const endDateRef = useRef();
    endDateRef.current = endDate;

    useEffect(() => {
        document.title = "Live Feed - " + getFacilityName();
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl('')).then((res) => {
            setSettings(res);
        })

        serverFetch(getApiUrl('/venues')).then((res) => {
            setVenues(res);
        })

        serverFetch(getApiUrl('/admins')).then((res) => {
            setAdminAccounts(res);
        })
    }, [facilityLink]);

    useEffect(() => {
        setLogGrouping(_.groupBy(logs, (l) => moment(l.changedDateLocal, "YYYY-MM-DD h:m:s").format("YYYY-MM-DD")));
    }, [logs]);

    useEffect(() => {
        onSearch();
    }, [dateFilter, startDate, endDate, venueFilter, actionFilter, adminFilter, searchAllQuery, limit]);

    const onKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    }

    const onFieldChange = (name, value) => {
        if (name === "startDate") {
            setStartDate(value);
        } else if (name === "endDate") {
            setEndDate(value);
        }
    }

    const onSearch = (restart = true) => {
        if (dateFilter === "dateRange" && (_.isNil(startDate) || _.isNil(endDate))) {
            return;
        }

        const searchUrl = getApiUrl(`/logs/search`);
        const data = {
            pagination: {
                from: restart ? 0 : (logs.length || 0),
                limit: limit,
            },
            query: {
                actionFilter: actionFilter,
                adminUserFilter: adminFilter,
                venueIdFilter: venueFilter,
                dateFilter: dateFilter && {
                    filterType: dateFilter,
                    startDate: startDate,
                    endDate: endDate
                },
                searchAllQuery: searchAllQuery,
            }
        };
        if (restart) {
            setLogs([]);
        }
        serverPost(searchUrl, data).then((res) => {
            setHasMore(res.length >= limit);
            if (restart) {
                setLogs(res);
            } else {
                setLogs(_.concat(logs, res))
            }
        })
    }

    useEffect(() => {
        setTimeout(() => {
            if ($(".content_wrapper").height() > $(".infinite-scroll-component__outerdiv").height() && hasMore) {
                onSearch(false);
            }
        }, 250);
    }, [logs])

    const dateFilterOptions = [
        { 'id': "today", 'label': t("common.today") },
        { 'id': "yesterday", 'label': t("common.yesterday") },
        { 'id': "thisWeek", 'label': t("common.this_week") },
        { 'id': "lastWeek", 'label': t("common.last_week") },
        { 'id': "thisMonth", 'label': t("common.this_month") },
        { 'id': "lastMonth", 'label': t("common.last_month") },
        { 'id': "dateRange", 'label': t("common.date_range") },
    ];

    const actionFieldOptions = [
        { 'id': null, 'label': t("dashboard.livefeed.all_actions") },
        { 'id': 'add', 'label': t("common.new") },
        { 'id': 'update', 'label': t("dashboard.livefeed.changed") },
        { 'id': 'cancel', 'label': t("dashboard.livefeed.cancelled") },
        { 'id': 'delete', 'label': t("dashboard.livefeed.deleted") },
    ];

    const venueOptions = _.map(venues, (venue, i) => { return { 'id': venue.id, 'label': venue.name } });
    venueOptions.unshift({ 'id': null, 'label': settings.allVenuesLabel });
    const adminFilterOptions = _.map(adminAccounts, (admin, i) => { return { 'id': admin.id, 'label': admin.firstName + " " + admin.lastName } });
    adminFilterOptions.unshift({ 'id': null, 'label': t("dashboard.livefeed.all_admins") });

    const dateFormat = getDateFormatForFacility(settings);

    const getName = (event) => {
        return (<Button variant="alink" className="skinny">{event.changedByName}</Button>);
    }

    const getTimeFrameString = (start, end) => {
        return `${moment(start).format(dateFormat + " h:mma")} - ${moment(end).format('h:mma')}`;
    }

    const getActionTimeString = (date) => {
        return (<span><i>{moment(date).format('hh:mm A')}</i></span>);
    }

    const getContentForAddEvent = (event) => {
        let description = "";
        if (event.paymentMethod === "onlineBooking") {
            description = " online ";
        }
        return (
            <>
                <i className="fa fa-book"/>
                &nbsp;
                {getName(event)}
                &nbsp;
                <span>booked{ description }</span>
                &nbsp;
                <a href={`/${facilityLink}/booking/list?groupId=${event.groupId}`} className="skinny">{event.groupName}</a>
                &nbsp;for&nbsp;
                <a href={`/${facilityLink}/index?lid=${event.bid}`} className="skinny">{getTimeFrameString(event.startTimeLocal, event.endTimeLocal)}</a>
                &nbsp;on the&nbsp;
                {event.venueName}
                &nbsp;
                {getActionTimeString(event.changedDateLocal)}
            </>
        )
    }

    const getContentForUpdateEvent = (event) => {
        const fromDescription = !_.isNil(event.previousChange) ?
            <span>from {getTimeFrameString(event.previousChange.startTimeLocal, event.previousChange.endTimeLocal)} ({event.previousChange.venueName}) ({event.previousChange.paymentMethod})</span>
        : ""
        return (
            <>
                <i className="fa fa-edit"/> {getName(event)}
                <span>
                    changed&nbsp;
                    <a href={`/${facilityLink}/booking/list?groupId=${event.groupId}`} className="skinny">{event.groupName}</a>&nbsp;{ fromDescription }
                </span>
                &nbsp;to&nbsp;
                <a href={`/${facilityLink}/index?lid=${event.bid}`} className="skinny">{getTimeFrameString(event.startTimeLocal, event.endTimeLocal)}</a> ({event.venueName}) ({event.paymentMethod}) {getActionTimeString(event.changedDateLocal)}
            </>
        )
    }

    const getContentForCancelEvent = (event) => {
        return (
            <>
                <i className="fa fa-remove"/>
                &nbsp;
                {getName(event)}
                &nbsp;
                <span>cancelled</span>
                &nbsp;
                <a href={`/${facilityLink}/booking/list?groupId=${event.groupId}`} className="skinny">{event.groupName}</a>
                &nbsp;for&nbsp;
                <a href={`/${facilityLink}/index?lid=${event.bid}`} className="skinny">{getTimeFrameString(event.startTimeLocal, event.endTimeLocal)}</a>
                &nbsp;on the&nbsp;
                {event.venueName}
                &nbsp;
                {getActionTimeString(event.changedDateLocal)}
            </>
        )
    }

    const getContentForDeleteEvent = (event) => {
        return (
            <>
                <i className="fa fa-trash"/> {getName(event)} <span>changed from</span> on {event.venueName} {getActionTimeString(event.changedDateLocal)}
            </>
        )
    }

    const getContentForEvent = (event) => {
        if (event.action === "add") {
            return getContentForAddEvent(event);
        } else if (event.action === "update") {
            return getContentForUpdateEvent(event);
        } else if (event.action === "cancel") {
            return getContentForCancelEvent(event);
        } else if (event.action === "delete") {
            return getContentForDeleteEvent(event);
        }
    };

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "livefeed")} />
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-lg-3">
                            <InputGroup>
                                <Form.Control type="text" className="inline" name="searchAllQuery" value={searchAllQuery} onChange={(event) => { setSearchAllQuery(event.target.value) }} onKeyPress={onKeyPress}/>
                                <InputGroup.Text className="skinny" onClick={() => onSearch()}><i className="fa fa-search"/></InputGroup.Text>
                            </InputGroup>
                        </div>
                        <div className="col-lg-9">
                            <div className="float-end d-flex align-items-start flex-button-group flex-wrap">
                                <div className="inline">
                                    <SingleSelectDropdown className="inline" items={getLimitOptions()} selectedId={limit} onSelect={setLimit} label={t("dashboard.livefeed.limit")} menuOnly/>
                                </div>
                                <div className="inline">
                                    <SingleSelectDropdown className="inline" items={actionFieldOptions} selectedId={actionFilter} onSelect={setActionFilter} label={t("dashboard.livefeed.actions")} />
                                </div>
                                <div className="inline">
                                    <SingleSelectDropdown items={adminFilterOptions} selectedId={adminFilter} onSelect={setAdminFilter} label={t("dashboard.livefeed.admins")} />
                                </div>
                                <div className="inline">
                                    <SingleSelectDropdown items={venueOptions} selectedId={venueFilter} onSelect={setVenueFilter} label={t("common.venues")} />
                                </div>
                                <div className="inline">
                                    <SingleSelectDropdown items={dateFilterOptions} selectedId={dateFilter} onSelect={setDateFilter} showAll label={t("common.date_range")} />
                                    {
                                        dateFilter === "dateRange" &&
                                            <BaseForm initialFormFields={{ startDate, endDate }} onFieldChange={onFieldChange}>
                                                <BaseForm.DateGroup name="startDate" label="Start Date"/>
                                                <BaseForm.DateGroup name="endDate" label="End Date"/>
                                            </BaseForm>
                                    }
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <p style={{ fontStyle: "italic" }}>All timezones are shown in the facility's timezone.</p>
                    <InfiniteScroll
                        dataLength={logs.length + Object.keys(logGrouping).length}
                        next={() => onSearch(false)}
                        hasMore={hasMore}
                        scrollableTarget="content_wrapper"
                    >
                        <Table borderless>
                            <tbody>
                                {
                                    _.map(logGrouping, (events, date) =>
                                        <React.Fragment key={date}>
                                            <tr>
                                                <td className="skinny"><strong>{moment(date, "YYYY-MM-DD h:m:s").format(dateFormat)}</strong></td>
                                            </tr>
                                            {
                                                _.map(events, (event, j) =>
                                                    <tr key={j}>
                                                        <td className="skinny">
                                                            {getContentForEvent(event)}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </React.Fragment>
                                    )
                                }
                                {
                                    hasMore ?
                                        <tr>
                                            <td colSpan={1} className="center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                    : <tr>
                                            <td colSpan={1} className="center">
                                                <div className="label">{t("dashboard.livefeed.end_of_live_feed")}</div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </InfiniteScroll>
                </div>
            </div>
        </BaseContainer>
    );
}

export default LiveFeed;
