import '../../App.scss';
import '../../css/modals.scss';
import { useState, useEffect, useContext } from 'react';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import { serverPost } from '../../helpers/server';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { serverFetch } from '../../helpers/server';
import Loader from '../Loader';

const _ = require('lodash');

function CustomerDetailsModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [customers, setCustomers] = useState([]);
    const { t } = useTranslation('common');
    const { facilityLink } = useParams();
    const [emailEnabled, setEmailEnabled] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (props.show) {
            serverFetch(getApiUrl(`/emails`)).then((res) => {
                const invoiceEmailOptionEnabled = res.find(obj => obj.tag === 'account_details' && obj.enabled === true);
                if (invoiceEmailOptionEnabled) {
                    setEmailEnabled(true);
                } else {
                    setEmailEnabled(false);
                }
                setLoading(false);
            })
        }
    }, [props.show]);
    
    const contact = async (data) => {
        const results = await Promise.all(_.map(customers, async (c) => await serverPost(getApiUrl(`/users/${c.userId}/send_account_details`), data)));
        const allGood = _.every(results, (r) => !_.isNil(r));
        if (allGood) {
            props.onClose(true);
            Notification.Show("Customer details sent to each of the customers individually.");
        }
    }

    useEffect(() => {
        if (props.show) {
            setCustomers(props.customers);
        } else {
            setCustomers([]);
        }
    }, [props.customers, props.show]);

    const getDescriptionForEmails = () => {
        let extraCustomers = [];
        let emailDescription = ""
        if (customers.length > 4) {
            emailDescription = `${_.map(customers.slice(0, 4), (c) => c.email).join(", ")} and ${customers.length - 4} others`;
        } else {
            emailDescription = _.map(customers, (c) => c.email).join(", ");
        }
        return `Sending email${customers.length>1 ? "s": ""} to ${emailDescription} ${customers.length>1? "individually": ""}`;
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Customer Details</BaseModal.Title>
            </BaseModal.Header>
            <Loader loading={loading}>
            { emailEnabled ? (
                <BaseForm onSubmit={contact}>
                    <Alert variant="info" className="skinny">This email will send customer login details and booking instructions. The default template can be edited under settings. Add additional information to the email below.</Alert>
                    <BaseModal.Body>
                        <p>{getDescriptionForEmails()}</p>
                        <Row>
                            <BaseForm.Input colSpan="12" type="editor" name="message" label="Message (optional)" placeholder="Your message" />
                        </Row>
                    </BaseModal.Body>
                    <BaseModal.Footer>
                        <Row>
                            <Col md="12" className="text-end">
                                <SubmitButton>Send Message</SubmitButton>
                            </Col>
                        </Row>
                    </BaseModal.Footer>
                </BaseForm>
            ) : (
                <BaseModal.Body>
                    <Row>
                        <p>{t('email.not_enabled_1')} <a href={`/${facilityLink}/setting/emaillist`}>{t('email.not_enabled_2')}</a> {t('email.not_enabled_3')}</p>
                    </Row>
                </BaseModal.Body>
            )}
            </Loader>
        </BaseModal>
    );

}

export default CustomerDetailsModal;
