import '../../App.scss';
import '../../css/modals.scss';
import { useState, useEffect } from 'react';
import BaseOverlayTrigger from '../BaseOverlayTrigger';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function AddOrEditPaymentMethodModal(props) {
    const [isEditing, setIsEditing] = useState(false);
    const { t } = useTranslation('common');

    useEffect(() => {
        setIsEditing(props.itemToEdit !== null);
    }, [props.itemToEdit]);

    const paymentIconOptions = [
        { 'value': "fa fa-check-circle", 'icon': 'fa-check-circle' },
        { 'value': "fa fa-credit-card", 'icon': 'fa-credit-card' },
        { 'value': "fa fa-money-bill", 'icon': 'fa-money-bill' },
        { 'value': "fa fa-edit", 'icon': 'fa-edit' },
        { 'value': "fa fa-file", 'icon': 'fa-file' },
        { 'value': "fa fa-user", 'icon': 'fa-user' },
        { 'value': "fa fa-star", 'icon': 'fa-star' },
        { 'value': "fa fa-flag", 'icon': 'fa-flag' },
        { 'value': "fa fa-pause", 'icon': 'fa-pause' },
        { 'value': "fa fa-undo fa-flip-vertical", 'icon': 'fa-undo fa-flip-vertical' },
        { 'value': "fa fa-ban", 'icon': 'fa-ban' },
        { 'value': "fa fa-circle-left", 'icon': 'fa-circle-left' },
        { 'value': "fa fa-shopping-cart", 'icon': 'fa-shopping-cart' },
        { 'value': "fa-brands fa-cc-stripe", 'icon': 'fa-cc-stripe' },
        { 'value': "fa-brands fa-cc-visa", 'icon': 'fa-cc-visa' },
        { 'value': "fa-brands fa-cc-mastercard", 'icon': 'fa-cc-mastercard' },
        { 'value': "fa-brands fa-cc-amex", 'icon': 'fa-cc-amex' },
    ];

    const processAddFields = (fields) => {
        fields.paymentTag = _.camelCase(fields.paymentType);
        return fields;
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            size="lg"
            processAddFields={processAddFields}
            itemLabel={"Payment Method"}
            itemBaseUrl={'/payment_methods'}
        >
            <Row>
                <BaseForm.Input colSpan={9} name={'paymentType'} label={t('forms.payment_name')} type={'text'} required leftContent={<i className="fa fa-credit-card"/>} />
                <BaseForm.Input colSpan={3} name={'paymentIcon'} label={t('forms.payment_icon')} type={'select'} options={paymentIconOptions} showSearch={false} />
                <BaseForm.Input colSpan={6} name={'isPayment'} label={t('forms.mark_booking_as_paid')} type={'check'} leftContent={<i className="fa fa-circle-check fa-c-success"/>} />
                <BaseForm.Input colSpan={6} name={'isClassOption'} label={t('forms.class_option')} type={'check'} leftContent={<i className="fa fa-user"/>} />
                <BaseForm.Input colSpan={6} name={'isInvOption'} label={t('forms.invoice_option')} type={'check'} leftContent={<i className="fa fa-file"/>} />
                <BaseForm.Input colSpan={6} name={'isAgrOption'} label={t('forms.agreement_option')} type={'check'} leftContent={<i className="fa fa-pencil-square"/>} />
            </Row>
            {
                isEditing &&
                    <BaseOverlayTrigger content={"System Defined tag to control payment actions. Consider this reference if changing the payment name as the action will not change."}>
                        <Col md="6">
                            <BaseForm.Input colSpan={12} name={'paymentTag'} label={t('forms.payment_tag')} type={'text'} leftContent={<i className="fa fa-circle-question"/>} disabled />
                        </Col>
                    </BaseOverlayTrigger>
            }
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditPaymentMethodModal;
