import { useTranslation } from "react-i18next";
import BaseContainer from "../../components/Container";
import { Link, useParams } from "react-router-dom";
import { getTabItems } from "../../helpers/tabs";
import TabHeader from "../../components/TabHeader";
import { useCallback, useContext, useEffect } from "react";
import { BaseContext } from "../../helpers/common";
import { useState } from "react";
import { serverFetch, serverPost } from "../../helpers/server";
import { Col, Row, Table, Form, Button } from "react-bootstrap";
import BaseForm from "../../components/BaseForm";
import SubmitButton from "../../components/SubmitButton";
import EditBulkCustomerImportModal from "../../components/modals/EditBulkCustomerImportModal";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
const _ = require("lodash");

function CustomerImport() {
    const { t } = useTranslation("common");
    const { facilityLink } = useParams();
    const { userInfo, settings, getApiUrl } = useContext(BaseContext);

    const [initialPullFields, setInitialPullFields] = useState({});
    const [sites, setSites] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [teamsToShow, setTeamsToShow] = useState(null);
    const [checkedGroupIds, setCheckedGroupIds] = useState([]);
    const [selectedSiteId, setSelectedSiteId] = useState(null);
    const [selectedSiteName, setSelectedSiteName] = useState(null);
    const [selectedProgramId, setSelectedProgramId] = useState(null);
    const [selectedProgramName, setSelectedProgramName] = useState(null);
    const [showBulkEditModal, setShowBulkEditModal] = useState(false);
    const [teams, setTeams] = useState({});
    const [customerTypes, setCustomerTypes] = useState([]);
    const [query, setQuery] = useState("");
    const [initialFields, setInitialFields] = useState({});
    const [connectedTeams, setConnectedTeams] = useState([]);
    const [newTeams, setNewTeams] = useState([]);
    const [showConnected, setShowConnected] = useState(false);
    const [teamsLoading, setTeamsLoading] = useState(true);
    const [isTeamList, setIsTeamList] = useState(false);
    const [errors, setErrors] = useState(null);

    const fetchPrograms = async (skipCache= false) => {
        const result = await serverFetch(getApiUrl(`/partner/sites/${selectedSiteId}/user_groups`), { skipCache })
        if (result) {
            let ugroups = [];
            _.each(result, r => {
                ugroups.push(r)
                if (r.subGroups) {
                    _.each(r.subGroups, g => {
                        ugroups.push({
                            ...g,
                            groupName: r.name,
                            groupId: r.id
                        });
                    })
                }
            })
            setUserGroups(ugroups);
        }
    }

    const fetchData = (skipCache = false) => {
        serverFetch(getApiUrl(`/partner/sites`), { skipCache }).then((res) => {
            if (res.length > 0){
                setSelectedSiteId(res[0].id);
                setSelectedSiteName(res[0].name);
            }
            setSites(res);
        });

        serverFetch(getApiUrl("/customer_types")).then((res) => {
            setCustomerTypes(res);
        });
    }

    useEffect(() => {
        fetchData(true);
    }, []);

    useEffect(() => {
        if (selectedSiteId) {
            fetchPrograms(true);
        }
    }, [selectedSiteId]);

    useEffect(() => {
        const filteredTeams = query.length > 0 ? _.filter(teams, (group) => {
            return group.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        }): teams;

        if (showConnected) {
            setConnectedTeams(_.filter(filteredTeams, (team) => team.userGroup !== null));
        } else {
            setNewTeams(_.filter(filteredTeams, (team) => !team.userGroup));
        }
    }, [query]);

    useEffect(() => {
        onPullGroups({
            siteId: selectedSiteId,
            programId: selectedProgramId,
        })
    }, [userGroups]);

    const onPullFieldChange = (field, value) => {
        if (field === "siteId") {
            const s = _.find(sites, s => String(s.id) === String(value))
            if (s) {
                setSelectedSiteId(s.id);
                setSelectedSiteName(s.name)
            }
            setSelectedProgramId(null);
            setInitialPullFields({
                programId: null
            })
            setInitialPullFields(prevFields => {
                const newFields = {...prevFields};
                newFields.siteId = value;
                return newFields;
            })
        } else if (field === "programId") {
            setSelectedProgramId(value);
            setInitialPullFields(prevFields => {
                const newFields = {...prevFields};
                newFields.programId = value;
                return newFields;
            })
        }
    };

    const onPullGroups = async (data) => {
        if (data.siteId) {
            setCheckedGroupIds([]);
            if (data.programId) {
                setTeamsLoading(true);
                const selectedProgram = _.find(userGroups, (po) => po.id === data.programId);
                const groupDataQuery = selectedProgram.groupId ? `?userSubgroupId=${selectedProgram.id}`: "";
                const programId = selectedProgram.groupId ? selectedProgram.groupId: selectedProgram.id;
                const receivedTeams = await serverFetch(getApiUrl(`/partner/sites/${data.siteId}/user_groups/${programId}/sub_groups${groupDataQuery}`), { skipCache: true });
                setTeams(receivedTeams);
                setTeamsToShow(receivedTeams);

                setConnectedTeams(receivedTeams.filter((team) => team.userGroup !== null));
                setNewTeams(receivedTeams.filter((team) => !team.userGroup));
                let newFields = {}

                for (const team of receivedTeams) {
                    newFields[`${team.id}_name`] = team.name;

                    if (team.userGroup) {
                        newFields[`${team.id}_name`] = team.userGroup.name;
                        newFields[`${team.id}_type`] = team.userGroup.typeId;
                        newFields[`${team.id}_color`] = team.userGroup.color;
                        newFields[`${team.id}_rate`] = team.userGroup.rate;
                    }
                }

                setSelectedSiteName(sites.filter(site => site.id === selectedSiteId)[0].name);
                setSelectedProgramName(userGroups.filter(group => group.id === selectedProgramId)[0].name);
                setInitialFields(newFields);
                setTeamsLoading(false);
                setIsTeamList(true);
                setShowConnected(false);
            } else {
                setTeams(userGroups);
                setTeamsToShow(userGroups);
                setSelectedProgramName(null)
                setNewTeams(userGroups.filter((team) => !team.userGroup));
                setConnectedTeams(userGroups.filter((team) => team.userGroup !== null));

                let newFields = {}
                for (const team of userGroups) {
                    newFields[`${team.id}_name`] = team.name;
                }
                setInitialFields(newFields);
                setIsTeamList(false);
                setShowConnected(false);
            }
       }
    };

    const onBulkEdit = (data) => {
        setInitialFields(oldFields => {
            let newFields = {...oldFields};

            checkedGroupIds.forEach(custId => {
                if (custId !== "checkall") {
                    if (data.updateCustomerType) {
                        newFields[`${custId}_type`] = data.popCustomerType;
                    }

                    if (data.updateCustomerColor) {
                        newFields[`${custId}_color`] = data.popCustomerColor;
                    }

                    if (data.updateHourlyRate) {
                        newFields[`${custId}_rate`] = data.popHourlyRate;
                    }

                    if (data.updateCustomerNamePrefix) {
                        newFields[`${custId}_name`] = data.popCustomerNamePrefix + newFields[`${custId}_name`];
                    }
                }
            });

            setShowBulkEditModal(false);
            return newFields;
        });
    }

    const updateChecked = (event) => {
        if (event.target.value === "checkall") {
            let newCheckedGroups = [];
            if (event.target.checked) {
                newCheckedGroups = _.map(_.filter(newTeams, t => t.isImportable), b => b.id.toString());
                newCheckedGroups.push("checkall");
            }
            setCheckedGroupIds(newCheckedGroups);
        } else {
            const newCheckedGroups = [...checkedGroupIds];
            if (event.target.checked) {
                newCheckedGroups.push(event.target.value.toString());
            } else {
                let index = newCheckedGroups.indexOf(event.target.value.toString());
                if (index > -1) {
                    newCheckedGroups.splice(index, 1);
                }
                index = newCheckedGroups.indexOf("checkall");
                if (index > -1) {
                    newCheckedGroups.splice(index, 1);
                }
            }
            setCheckedGroupIds(newCheckedGroups);
        }
    }

    const onError = async (response, errorMessage) => {
        if (errorMessage.groupNames) {
            setErrors(errorMessage.groupNames)
        } else if (errorMessage.remoteId) {
            setErrors(errorMessage.remoteId)
        } else if (errorMessage.message) {
            setErrors(errorMessage.message)
        }
    }

    const importGroups = async (data) => {
        if (checkedGroupIds.length === 0) {
            Notification.Show("Please select at least one group");
            return;
        }
        setErrors(null);
        const selectedTeams = _.filter(teams, (t) => _.includes(checkedGroupIds, t.id));
        const importGroups = _.map(selectedTeams, tt => {
            const g = {
                groupName: data[`${tt.id}_name`],
                groupEmail: null,
                customerColor: data[`${tt.id}_color`],
                customerTypeId: data[`${tt.id}_type`],
                rate: data[`${tt.id}_rate`] === "" ? null : data[`${tt.id}_rate`],
            }
            if (isTeamList) {
                g.remoteId = tt.remoteGroupId
                g.remoteSubId = tt.remoteSubgroupId
                g.remoteTeamId = tt.id
            } else {
                if (tt.groupId) {
                    g.remoteId = tt.groupId
                    g.remoteSubId = tt.id
                } else {
                    g.remoteId = tt.id
                }
            }
            // if (tt.type === "program" || tt.type === "camp") {
            //     g.remoteId = tt.id
            // } else if (tt.type === "subprogram" || tt.type === "clubteam") {
            //     g.remoteId = tt.groupId
            //     g.remoteSubId = tt.id
            // } else if (tt.type === "team") {
            //     g.remoteId = tt.remoteGroupId
            //     g.remoteSubId = tt.remoteSubgroupId
            //     g.remoteTeamId = tt.id
            // }
            return g;
        })
        const importData = {
            siteId: selectedSiteId,
            groups: importGroups
        };

        const result = await serverPost(getApiUrl(`/user_groups/batch_create`), importData, {}, onError);
        if (result) {
            Notification.Show(t("partner.customer_import.importing_done"))
            if (!selectedProgramId) {
                await fetchPrograms(true)
            } else {
                onPullGroups({
                    siteId: selectedSiteId,
                    programId: selectedProgramId,
                })
            }
            setShowConnected(true);
        }
    }

    const onEdit = () => {
        if (checkedGroupIds.length === 0) {
            Notification.Show("Please select at least one group");
        } else {
            setShowBulkEditModal(true)
        }
    }

    const tabsParams = {
        userInfo: userInfo,
        isPartnerFacility: settings.isPartnerFacility,
    }

    const teamsTabItems = [{
        'label': t('partner.customerimport.new_teams'),
        onClick: () => setShowConnected(false),
        active: !showConnected,
    }, {
        'label': t('partner.customerimport.connected_teams'),
        onClick: () => setShowConnected(true),
        active: showConnected,
    }];

    const onQueryChange = (newQuery) => {
        setQuery(newQuery);
        setTeamsToShow(teams.filter((group) => {

            return group.name.toLowerCase().indexOf(newQuery.toLowerCase()) > -1;
        }));

        if (showConnected) {
            setConnectedTeams(teamsToShow.filter((team) => team.userGroup !== null));
        } else {
            setNewTeams(teamsToShow.filter((team) => !team.userGroup));
        }
    }

    const siteOptions = !sites ? [] : _.map(sites, (site) => {
        return {
            label: site.name,
            value: site.id,
        }
    });

    const programOptions = !userGroups ? [] : userGroups.map((program) => {
        let label = program.name;
        if (program.groupName) {
            label = program.groupName + " -> " + program.name
        }
        return {
            label: label,
            value: program.id.toString(),
        }
    });
    programOptions.unshift({ value: null, label: "Show all programs" })

    const customerTypeOptions = !customerTypes ? [] : customerTypes.map((cusType) => {
        return {
            label: cusType.customerType,
            value: cusType.id.toString(),
        }
    });
    customerTypeOptions.unshift({value: 0, label: "None"});

    const renderConnectedTeamsTab = (teams) => {
        return (
            <div className="content-box">
                <div className="content-body">
                    <BaseForm onSubmit={importGroups} initialFormFields={initialFields}>
                        <Row>
                            <Col md="6" className="d-flex flex-column justify-content-center">
                                <span className="body2"><strong>{selectedSiteName} - {selectedProgramName}</strong></span>
                                {
                                    selectedProgramName ?
                                        <span>Teams fetched for the selected Program</span>
                                        : <span>Programs fetched for the selected site<br/>If you want to import a Team, choose a program from the "Program to Import From" dropdown.</span>
                                }
                            </Col>
                            {
                                !showConnected &&
                                <Col md="6">
                                    <div className="flex-grow-1 d-flex flex-row justify-content-end">
                                        <div className="float-end d-flex align-items-center flex-wrap">
                                            <Button variant="outline-primary" onClick={() => onEdit()}><i className="fa fa-edit fa-small"/> {t("common.edit")}</Button>
                                            <SubmitButton><i className="fa-solid fa-download" /> {t("partner.customerimport.import")}</SubmitButton>
                                        </div>
                                    </div>
                                </Col>
                            }
                        </Row>
                        <br/>
                        <Row>
                            <Col md="8">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>LeagueApps Name</th>
                                            <th>{t("partner.customerimport.rectimes_name")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        _.map(teams, (group, i) =>
                                            <tr key={i}>
                                                <td>
                                                    {group.name}
                                                    <p className="thin gray2"><i>{ group.type }</i></p>
                                                </td>
                                                <td>
                                                    <Link to={`/${facilityLink}/booking/list?groupId=${group.userGroup.id}`}>{group.userGroup.name}</Link>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </BaseForm>
                </div>
            </div>
        )
    }

    const renderImportableTeamsTab = (teams) => {
        return (
            <div className="content-box">
                <div className="content-body">
                    <BaseForm onSubmit={importGroups} initialFormFields={initialFields}>
                        <Row>
                            <Col md="6" className="d-flex flex-column justify-content-center">
                                <span className="body2"><strong>{selectedSiteName} - {selectedProgramName}</strong></span>
                                {
                                    selectedProgramName ?
                                        <span>Teams fetched for the selected Program</span>
                                    : <span>Programs fetched for the selected site<br/>If you want to import a Team, choose a program from the "Program to Import From" dropdown.</span>
                                }
                            </Col>
                            <Col md="6">
                                <div className="flex-grow-1 d-flex flex-row justify-content-end">
                                    <div className="float-end d-flex align-items-center flex-wrap">
                                        <Button variant="outline-primary" onClick={() => onEdit()}><i className="fa fa-edit fa-small"/> {t("common.edit")}</Button>
                                        <SubmitButton><i className="fa-solid fa-download" /> {t("partner.customerimport.import")}</SubmitButton>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        {
                            connectedTeams.length > 0 &&
                            <p>There are already { connectedTeams.length } programs/teams connected to existing RecTimes customers. <a href="#connected" onClick={() => setShowConnected(true)}>View connected customers</a></p>
                        }
                        {
                            errors &&
                                <p className="form-error-message text-end">{ errors }</p>
                        }
                        <Row>
                            <Col md="12">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>LeagueApps Name</th>
                                            <th>{t("partner.customerimport.rectimes_name")}</th>
                                            <th>{t("partner.customerimport.customer_type")}</th>
                                            <th>{t("partner.customerimport.custom_color")}</th>
                                            <th>{t("partner.customerimport.custom_rate")}</th>
                                        </tr>
                                        <tr>
                                            <th className="controls no-stretch">
                                                <div className="checkbox check-success inline">
                                                    <input
                                                        type="checkbox" className="" value="checkall"
                                                        id="checkall" name="delete[]"
                                                        checked={checkedGroupIds.includes("checkall")}
                                                        onChange={ (event) => updateChecked(event) }
                                                    />
                                                    <label htmlFor="checkall"/>
                                                </div>
                                            </th>
                                            <th className="controls">
                                                <Form.Control
                                                    type="text" name="query" value={query}
                                                    onChange={(event) => { setQuery(event.target.value) }}
                                                    placeholder="Search"
                                                />
                                            </th>
                                            <th className="controls"></th>
                                            <th className="controls"></th>
                                            <th className="controls"></th>
                                            <th className="controls"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        _.map(teams, (group, i) =>
                                            <tr key={i}>
                                                <td className="no-stretch">
                                                    {
                                                        group.isImportable &&
                                                            <div className="checkbox check-success inline">
                                                                <input type="checkbox" className="" value={group.id.toString()} id={group.id.toString()} name="delete[]"  checked={checkedGroupIds.includes(group.id.toString())} onChange={ (event) => updateChecked(event) } disabled={group.userGroup}/>
                                                                <label htmlFor={group.id.toString()}/>
                                                            </div>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        group.groupName &&
                                                            <span>{ group.groupName } > </span>
                                                    }
                                                    {group.name}
                                                    <p className="thin gray2"><i>{ group.type }</i></p>
                                                </td>
                                                <td>
                                                    {
                                                        group.isImportable ?
                                                            <BaseForm.Input type="text" name={`${group.id}_name`} disabled={group.userGroup}/>
                                                        : <span>Not importable</span>
                                                    }

                                                </td>
                                                <td>
                                                    {
                                                        group.isImportable &&
                                                            <BaseForm.Input
                                                                type="select" name={`${group.id}_type`}
                                                                options={customerTypeOptions}
                                                                showSearch={customerTypeOptions.length > 5}
                                                                disabled={group.userGroup}
                                                            />
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        group.isImportable &&
                                                            <BaseForm.Input
                                                                type="color" colSpan="9"
                                                                className="inline borderless"
                                                                name={`${group.id}_color`}
                                                                label={"Custom Color"}
                                                                disabled={group.userGroup}
                                                            />
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        group.isImportable &&
                                                            <BaseForm.Input colSpan="12" type="number" name={`${group.id}_rate`}
                                                                placeholder="If empty standard rates apply" leftContent="$"
                                                                disabled={group.userGroup}
                                                            />
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </BaseForm>
                </div>
            </div>
        )
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "customers", tabsParams)} />
            <div className="content-box">
                <div className="content-body">
                    <Loader loading={(siteOptions.length === 0) || (programOptions.length === 0)}>
                        <BaseForm initialFormFields={initialPullFields} onSubmit={onPullGroups} onFieldChange={onPullFieldChange}>
                            <Row>
                                <Col md="4">
                                    <BaseForm.Input type={"select"}
                                        className="inline"
                                        name={"siteId"}
                                        label={t("partner.customerimport.from_site")}
                                        options={siteOptions}
                                        showSearch={siteOptions.length > 5}
                                    />
                                </Col>
                                {
                                    selectedSiteId &&
                                        <BaseForm.Input
                                            type={"select"}
                                            colSpan={"4"}
                                            className="inline d-block"
                                            name={"programId"}
                                            label={t("partner.customerimport.from_program")}
                                            options={programOptions}
                                            showSearch={programOptions.length > 5}
                                        />
                                }
                                {
                                    selectedSiteId &&
                                    <Col md="4" className="d-flex align-items-center">
                                        <SubmitButton variant="success">
                                            {t("partner.customerimport.fetch_teams")}
                                        </SubmitButton>
                                    </Col>
                                }
                            </Row>
                        </BaseForm>
                    </Loader>
                </div>
            </div>
            <Loader loading={(siteOptions.length === 0) || (programOptions.length === 0)}>
            {
                !teamsToShow ? (
                    <div className="content-box">
                        <div className="content-body">
                            <span className="lead">{ t("partner.customerimport.none_selected") }</span>
                        </div>
                    </div>
                ) :
                (
                    (teamsToShow.length > 0) ?
                    (
                        <>
                            { connectedTeams.length > 0 && <TabHeader items={teamsTabItems} /> }
                            {
                                showConnected ?
                                    renderConnectedTeamsTab(connectedTeams)
                                : renderImportableTeamsTab(newTeams)
                            }
                        </>
                    ) : (
                        <div className="content-box">
                            <div className="content-body">
                                <span className="lead">{ t("partner.customerimport.empty_message") }</span>
                            </div>
                        </div>
                    )
                )
            }
            </Loader>
            <EditBulkCustomerImportModal
                show={showBulkEditModal}
                onClose={setShowBulkEditModal}
                customerTypeOptions={customerTypeOptions}
                onBulkEdit={onBulkEdit}
            />
        </BaseContainer>
    )
}

export default CustomerImport;
