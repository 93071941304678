import '../App.scss';
import $ from "jquery";
import { useEffect, useState, useContext } from "react";
import { Row, Table } from 'react-bootstrap';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { BaseContext, currencyFormat, getDateFormatForFacility } from '../helpers/common'
import classnames from 'classnames';
import moment from 'moment';
const _ = require("lodash");

function ClassSessions(props) {
    const { settings } = useContext(BaseContext);
    const [classDetails, setClassDetails] = useState(null);
    const [sessions, setSessions] = useState(null);

    useEffect(() => {
        setClassDetails(props.classDetails)
    }, [props.classDetails]);

    useEffect(() => {
        setSessions(props.sessions)
    }, [props.sessions]);

    if (_.isNil(classDetails)) {
        return null
    }

    const dateFormat = getDateFormatForFacility(settings);
    return (
        <>
            <h3>Sessions</h3>
            <Row>
            <Table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Venue</th>
                        <th>Date/Time</th>
                        {
                            classDetails.priceType != "totalPrice" &&
                                <th></th>
                        }
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {
                    _.map(sessions, (session, i) =>
                        <tr key={i}>
                            <td>{ i+1 }</td>
                            <td>{ session.venueName }</td>
                            <td>{ moment(session.startTimeLocal).format(dateFormat) + ", " + moment(session.startTimeLocal).format("h:mma") + " - " + moment(session.endTimeLocal).format("h:mma") }</td>
                            {
                                classDetails.priceType != "totalPrice" &&
                                    <td>{ currencyFormat(session.cost) }</td>
                            }
                            <td>{ session.eventName }</td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
            </Row>
            <br/>
        </>
    )
}

export default ClassSessions;
