import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import React, { useEffect, useState, useRef, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import DeleteButton from '../../components/DeleteButton';
import BaseContainer from '../../components/Container';
import Notification from '../../components/Notification';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import AddCustomerModal from '../../components/modals/AddCustomerModal';
import EditBulkCustomersModal from '../../components/modals/EditBulkCustomersModal';
import SalesAllocationReportModal from '../../components/modals/SalesAllocationReportModal';
import CustomerDetailsModal from '../../components/modals/CustomerDetailsModal';
import CustomerEmailModal from '../../components/modals/CustomerEmailModal';
import CustomersCSVExportModal from '../../components/modals/CustomersCSVExportModal';
import MergeCustomersModal from '../../components/modals/MergeCustomersModal';
import { serverFetch, serverPost } from '../../helpers/server';
import {getLimitOptions, dateRangeClassCheck, BaseContext, currencyFormat, isFullAdmin} from '../../helpers/common';
import { useParams, useSearchParams, Link } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, InputGroup, Form, Button, Row, ButtonGroup, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getColorOptions, hasAccess, isPartialOrFullAdmin } from '../../helpers/common';
import InfiniteScroll from "react-infinite-scroll-component";
const _ = require("lodash");

function GroupList() {
    const { isLoggedIn, getApiUrl, getFacilityName, userInfo, settings } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [salesAllocationReportType, setSalesAllocationReportType] = useState("");
    const [showSalesAllocationReportModal, setShowSalesAllocationReportModal] = useState(false);
    const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
    const [showBulkCustomersModal, setShowBulkCustomersModal] = useState(false);
    const [showCustomerDetailsModal, setShowCustomerDetailsModal] = useState(false);
    const [showCustomerEmailModal, setShowCustomerEmailModal] = useState(false);
    const [showCustomersCSVExportModal, setShowCustomersCSVExportModal] = useState(false);
    const [showMergeCustomersModal, setShowMergeCustomersModal] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [meta, setMeta] = useState({});
    const [totalResults, setTotalResults] = useState("");
    const [ searchParams ] = useSearchParams();
    const groupId = searchParams.get('groupId');
    const canUpdateCustomers = hasAccess("customers", userInfo, "update");
    const canViewFinancials = hasAccess("booking", userInfo, "viewWithFinancials", "any");

    const [searchAllQuery, setSearchAllQuery] = useState("");
    const [groupNameFilter, setGroupNameFilter] = useState(null);
    const [emailFilter, setEmailFilter] = useState(null);
    const [phoneFilter, setPhoneFilter] = useState(null);
    const [customerTypeFilter, setCustomerTypeFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState(null);
    const [colorFilter, setColorFilter] = useState(null);
    const [rateFilter, setRateFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [sortFilter, setSortFilter] = useState("groupNameNew");
    const [limit, setLimit] = useState(20);

    const [customers, setCustomers] = useState([]);
    const [customerTypes, setCustomerTypes] = useState([]);
    const [checkedCustomerIds, setCheckedCustomerIds] = useState([]);
    const [checkedCustomers, setCheckedCustomers] = useState([]);

    const [loadingCustomerTypes, setLoadingCustomerTypes] = useState(true);

    const startDateRef = useRef();
    startDateRef.current = startDate;
    const endDateRef = useRef();
    endDateRef.current = endDate;
    const queryTimer = useRef(null);

    useEffect(() => {
        document.title = "Customers - " + getFacilityName();
    }, []);

    useEffect(() => {
        // Load the options from cache
        const optionsString = localStorage.getItem(facilityLink + "_filter_cache_user_groups");
        if (optionsString && !groupId) {
            const options = JSON.parse(optionsString);
            if (options.dateFilter) {
                setDateFilter(options.dateFilter.filterType);
                setStartDate(options.dateFilter.startDate);
                startDateRef.current = options.dateFilter.startDate;
                setEndDate(options.dateFilter.endDate);
                endDateRef.current = options.dateFilter.endDate;
            }
            if (options.groupNameFilter) {
                setGroupNameFilter(options.groupNameFilter)
            }
            if (options.emailFilter) {
                setEmailFilter(options.emailFilter);
            }
            if (options.phoneFilter) {
                setPhoneFilter(options.phoneFilter);
            }
            if (options.customerTypeFilter) {
                setCustomerTypeFilter(options.customerTypeFilter);
            }
            if (options.customerRateFilter) {
                setRateFilter(options.customerRateFilter);
            }
            if (options.colorFilter) {
                setColorFilter(options.colorFilter);
            }
            if (options.activeFilter) {
                setStatusFilter(options.activeFilter);
            }
            if (options.searchAllQuery) {
                setSearchAllQuery(options.searchAllQuery);
            }
            if (options.sort) {
                setSortFilter(options.sort);
            }
        }
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl('/customer_types')).then((res) => {
            setCustomerTypes(res);
            setLoadingCustomerTypes(false);
        })
    }, [facilityLink, isLoggedIn]);

    useEffect(() => {
        onSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusFilter, groupNameFilter, emailFilter, phoneFilter, loadingCustomerTypes,
        customerTypeFilter, colorFilter, dateFilter, rateFilter, limit, sortFilter, startDate, endDate]);

    useEffect(() => {
        clearTimeout(queryTimer.current);
        queryTimer.current = setTimeout((callback, kk, vv) => {
                if (callback) {
                    callback(kk, vv);
                }
                queryTimer.current = null;
            },
            400,
            onSearch,
        );
    }, [searchAllQuery]);

    useEffect(() => {
        setTimeout(() => {
            $('#dateStart').datepicker( "destroy" );
            $('#dateStart').datepicker({
                defaultDate: 0,
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setStartDate(value);
                    setTimeout(() => $('#dateEnd').datepicker( "show" ), 100);
                }
            });
            $('#dateEnd').datepicker( "destroy" );
            $('#dateEnd').datepicker({
                defaultDate: "+1m",
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setEndDate(value)
                }
            });
        }, 100);
    }, [dateFilter, startDate, endDate]);

    const onModalClose = () => {
        setShowMergeCustomersModal(false);
        setShowAddCustomerModal(false);
        setShowBulkCustomersModal(false);
        setShowCustomerDetailsModal(false);
        setShowCustomerEmailModal(false);
        onSearch(true);
    }

    useEffect(() => {
        setCheckedCustomers(_.filter(customers, (b) => checkedCustomerIds.includes(getRowId(b))));
    }, [checkedCustomerIds]);

    const onSendSelected = (type) => {
        console.log("Send selected " + type);
        const items = checkedCustomers;
        if (items.length === 0) {
            Notification.Show(t('customers.list.error_choose_one'));
        } else {
            if (type === "details") {
                setShowCustomerDetailsModal(true);
            } else if (type === "email") {
                setShowCustomerEmailModal(true);
            }
        }
    }

    const onReport = () => {
        if (checkedCustomerIds.length === 0) {
            Notification.Show(t('customers.list.error_choose_one'));
        } else {
            setShowCustomersCSVExportModal(true);
        }
    }

    const onAdd = () => {
        setShowAddCustomerModal(true);
    }

    const onEdit = () => {
        if (checkedCustomerIds.length === 0) {
            Notification.Show(t('customers.list.error_choose_one'));
        } else {
            setShowBulkCustomersModal(true);
        }
    }

    const onMerge = () => {
        if (checkedCustomerIds.length < 2) {
            Notification.Show(t('customers.list.error_choose_two_to_merge'));
        } else {
            setShowMergeCustomersModal(true);
        }
    }

    const onDeleteError = (response, errorMessage) => {
        if (errorMessage.groupIds) {
            Notification.Show(errorMessage.groupIds);
        }
    }

    const onDelete = () => {
        if (checkedCustomerIds.length === 0) {
            Notification.Show(t('customers.list.error_choose_one'));
        } else {
            const deleteData = {
                groupIds: _.filter(checkedCustomerIds, (cid) => cid !== "checkall")
            }
            serverPost(getApiUrl('/user_groups/batch_delete'), deleteData, {}, onDeleteError).then((res) => {
                if (res) {
                    Notification.Show(t('customers.list.successfully_deleted'));
                    onSearch(true);
                }
            })
        }
    }

    const onKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    }

    const getQueryParams = () => {
        return {
            groupNameFilter: groupNameFilter,
            emailFilter: emailFilter,
            phoneFilter: phoneFilter,
            customerTypeFilter: customerTypeFilter,
            dateFilter: dateFilter && {
                filterType: dateFilter,
                startDate: startDate,
                endDate: endDate,
            },
            customerRateFilter: rateFilter,
            colorFilter: colorFilter,
            groupId: groupId,
            activeFilter: statusFilter,
            searchAllQuery: searchAllQuery,
            sort: sortFilter
        }
    }

    const onSearch = (restart = true) => {
        if (loadingCustomerTypes) {
            return;
        }
        if (dateFilter === "dateRange" && (_.isNil(startDate) || _.isNil(endDate))) {
            return;
        }
        const searchUrl = getApiUrl('/user_groups/search');
        const data = {
            pagination: {
                from: restart ? 0 : (customers.length || 0),
                limit: limit
            },
            query: getQueryParams()
        };
        if (restart) {
            setCustomers([]);
            setMeta({});
        }
        if (!groupId) {
            // Cache the options
            localStorage.setItem(facilityLink + "_filter_cache_user_groups", JSON.stringify(getQueryParams()));
        }
        serverPost(searchUrl, data).then((res) => {
            setHasMore(res.results.length >= limit);
            if (restart) {
                setCustomers(res.results);
            } else {
                setCustomers(_.concat(customers, res.results))
            }
            if (!_.isEmpty(res.meta) && restart) {
                if (!_.isNil(res.meta.totalResults)) {
                    setTotalResults(res.meta.totalResults);
                }
                setMeta(res.meta);
            }
        })
    }

    const updateChecked = (event) => {
        if (event.target.value === "checkall") {
            let newCheckedCustomers = [];
            if (event.target.checked) {
                newCheckedCustomers = _.map(customers, (b, i) => getRowId(b));
                newCheckedCustomers.push("checkall");
            }
            setCheckedCustomerIds(newCheckedCustomers);
        } else {
            const newCheckedCustomers = [...checkedCustomerIds];
            if (event.target.checked) {
                newCheckedCustomers.push(event.target.value);
            } else {
                let index = newCheckedCustomers.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedCustomers.splice(index, 1);
                }
                index = newCheckedCustomers.indexOf("checkall");
                if (index > -1) {
                    newCheckedCustomers.splice(index, 1);
                }
            }
            setCheckedCustomerIds(newCheckedCustomers);
        }
    }

    function getRowId(customer) {
        return ("" + customer.id);
    }

    const groupNameFilterOptions = [
        { 'id': "onlyMain", 'label': t("customers.list.only_main_groups") },
        { 'id': "onlySub", 'label': t("customers.list.only_sub_groups") },
    ];

    const emailFilterOptions = [
        { 'id': "yes", 'label': t("customers.list.has_email") },
        { 'id': "no", 'label': t("customers.list.no_email") },
    ];

    const phoneFilterOptions = [
        { 'id': "yes", 'label': t("customers.list.has_phone") },
        { 'id': "no", 'label': t("customers.list.no_phone") },
    ];

    const statusFilterOptions = [
        { 'id': "active", 'label': t("common.active") },
        { 'id': "inactive", 'label': t("customers.list.inactive") },
    ];

    const rateFilterOptions = [
        { 'id': "yes", 'label': t("customers.list.has_rate") },
        { 'id': "no", 'label': t("customers.list.no_rate") },
    ];

    const dateFilterOptions = [
        { 'id': null, 'label': t("common.all") + ' (' + t("common.date_range") + ')' },
        { 'id': "future", 'label': t("common.future") },
        { 'id': "today", 'label': t("common.today") },
        { 'id': "tomorrow", 'label': t("common.tomorrow") },
        { 'id': "yesterday", 'label': t("common.yesterday") },
        { 'id': "thisWeek", 'label': t("common.this_week") },
        { 'id': "nextWeek", 'label': t("common.next_week") },
        { 'id': "lastWeek", 'label': t("common.last_week") },
        { 'id': "thisMonth", 'label': t("common.this_month") },
        { 'id': "nextMonth", 'label': t("common.next_month") },
        { 'id': "lastMonth", 'label': t("common.last_month") },
        { 'id': "dateRange", 'label': t("common.date_range") },
    ];

    const colorOptions = () => {
        const options = getColorOptions(t);
        for (const op of options) {
            op['icon'] = "fa-circle";
            op['style'] = { color: op['value'] };
        }
        return options;
    }

    const sendOptions = [
        { 'id': "details", 'label': 'Customer Details', 'icon': 'fa-user' },
        { 'id': "email", 'label': 'Email Message', 'icon': 'fa-envelope' },
    ];

    const sortOptions = [
        { 'id': "groupNameA2Z", 'label': t("customers.list.group_name_az") },
        { 'id': "groupNameZ2A", 'label': t("customers.list.group_name_za") },
        { 'id': "groupNameNew", 'label': t("customers.list.group_name_new_old") },
        { 'id': "groupNameOld", 'label': t("customers.list.group_name_old_new") },
        { 'id': "fullNameA2Z", 'label': t("customers.list.full_name_az") },
        { 'id': "fullNameZ2A", 'label': t("customers.list.full_name_za") },
        { 'id': "bookingCountAsc", 'label': t("customers.list.booking_count_asc") },
        { 'id': "bookingCountDesc", 'label': t("customers.list.booking_count_desc") },
        { 'id': "creditAsc", 'label': t("customers.list.credit_asc") },
        { 'id': "creditDesc", 'label': t("customers.list.credit_desc") },
        { 'id': "valueAsc", 'label': t("customers.list.value_asc") },
        { 'id': "valueDesc", 'label': t("customers.list.value_desc") },
        { 'id': "customerRateAsc", 'label': t("customers.list.customer_rate_asc") },
        { 'id': "customerRateDesc", 'label': t("customers.list.customer_rate_desc") },
    ]

    const customerTypesOptions = _.map(customerTypes, (type, i) => { return { 'id': type.id, 'label': type.customerType } });

    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        userInfo: userInfo,
        isPartnerFacility: settings.isPartnerFacility,
    };

    const isPartnerOrSuperAdmin = settings.isPartnerFacility && (isFullAdmin(userInfo) || userInfo.isSuperAdmin);
    const isPartnerAdminOrSuperAdmin = userInfo.isSuperAdmin || (settings.isPartnerFacility && isPartialOrFullAdmin(userInfo));
    const isPartnerFacility = settings.isPartnerFacility;
    let showPartnerColumn = isPartnerOrSuperAdmin && !_.isEmpty(_.filter(customers, (c) => c.partnerMapping && !_.isEmpty(c.partnerMapping)));
    let showSubGroupsColumn = isPartnerOrSuperAdmin;
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "customers", tabsParams)} />
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col lg="2">
                            <InputGroup>
                                <Form.Control type="text" className="inline" name="searchAllQuery" value={searchAllQuery} onChange={(event) => { setSearchAllQuery(event.target.value) }} onKeyPress={onKeyPress}/>
                                <InputGroup.Text className="skinny" onClick={() => onSearch()}><i className="fa fa-search"/></InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col lg="3">
                            <div className="d-flex flex-row align-items-start">
                                <ButtonGroup className="skinny">
                                    <BaseOverlayTrigger content={t("customers.list.customers")} >
                                        <Button variant="outline-primary"><i className="fa fa-user fa-small"/> {totalResults}</Button>
                                    </BaseOverlayTrigger>
                                </ButtonGroup>
                                <SingleSelectDropdown className="inline" items={dateFilterOptions} selectedId={dateFilter} onSelect={setDateFilter} />
                            </div>
                        </Col>
                        <Col lg="7">
                            <div className="float-end d-flex align-items-center flex-wrap">
                                <SingleSelectDropdown className="inline" items={getLimitOptions()} selectedId={limit} onSelect={setLimit} menuOnly/>
                                <SingleSelectDropdown className="inline" toggleLabel={<span><i className="fa fa-small fa-envelope"/> {t("common.send")}</span>} items={sendOptions} onSelect={onSendSelected} menuOnly/>
                                {
                                    canUpdateCustomers &&
                                        <>
                                        <Button variant="outline-primary" onClick={onAdd}><i className="fa fa-circle-plus fa-small"/> {t("common.add")}</Button>

                                        { isPartnerAdminOrSuperAdmin && (
                                            <Link to={"/" + facilityLink + "/user/batch_create"}>
                                                <Button variant="outline-primary"><i className="fa fa-circle-plus fa-small"/> {t("common.batch_add")}</Button>
                                            </Link>
                                        )} 
                                        
                                        <Button variant="outline-primary" onClick={onEdit}><i className="fa fa-edit fa-small"/> {t("common.edit")}</Button>
                                        </>
                                }
                                <Button variant="success" onClick={onReport}><i className="fa fa-circle-plus fa-small"/> {t("common.new_report")}</Button>
                                {
                                    canUpdateCustomers &&
                                        <>
                                        <Button variant="danger" onClick={onMerge}>{t("customers.list.merge")}</Button>
                                        <DeleteButton size="md" onDelete={onDelete} disabled={checkedCustomerIds.length === 0}
                                            confirmationText={checkedCustomerIds.length>=2 && "DELETE CUSTOMERS"}
                                            body={checkedCustomerIds.length>=2 && "You are trying to delete multiple customers? This action can't be undone. Are you sure?"} />
                                        </>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3">
                        {
                            dateFilter === "dateRange" &&
                                <BaseForm initialFormFields={{ dateStart: startDate, dateEnd: endDate }}>
                                    <InputGroup>
                                        <BaseForm.Control type="text" className="inline" name="dateStart" id="dateStart" />
                                        <InputGroup.Text className="skinny">{t("common.to")}</InputGroup.Text>
                                        <BaseForm.Control type="text" className="inline" name="dateEnd" id="dateEnd" />
                                        <InputGroup.Text className="skinny" onClick={(event) => onSearch()}><i className="fa fa-refresh"/></InputGroup.Text>
                                    </InputGroup>
                                </BaseForm>
                        }
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="9" className="text-end d-grid align-items-center">
                            <span><strong>{t("common.sort_order")}:</strong></span>
                        </Col>
                        <Col md="3">
                            <SingleSelectDropdown name="sort" selectedId={sortFilter} items={sortOptions} onSelect={setSortFilter} />
                        </Col>
                    </Row>
                    <InfiniteScroll
                        dataLength={customers.length}
                        next={() => onSearch(false)}
                        hasMore={hasMore}
                        scrollableTarget="content_wrapper"
                    >
                        <Table hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t('common.group_name')}</th>
                                    <th>{t('common.full_name')}</th>
                                    <th>{t('common.email')}</th>
                                    <th>{t('common.phone')}</th>
                                    <th>{t('customers.list.bookings')}</th>
                                    {
                                        canViewFinancials &&
                                           <th>{t('customers.list.value')}</th>
                                    }
                                    {
                                        canViewFinancials &&
                                            <th>{t('customers.list.credit')}</th>
                                    }
                                    <th>{t('customers.list.type')}</th>
                                    <th>{t('customers.list.rate')}</th>
                                    <th></th>
                                    { showSubGroupsColumn && <th></th> }
                                    { !isPartnerFacility && <th>{t('customers.list.status')}</th> }
                                    { showPartnerColumn && <th></th> }
                                </tr>
                                <tr>
                                    <th className="controls no-stretch">
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedCustomerIds.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor="checkall"/>
                                        </div>
                                    </th>
                                    <th className="controls"><SingleSelectDropdown items={groupNameFilterOptions} selectedId={groupNameFilter} onSelect={setGroupNameFilter} showAll /></th>
                                    <th className="controls"></th>
                                    <th className="controls"><SingleSelectDropdown items={emailFilterOptions} selectedId={emailFilter} onSelect={setEmailFilter} showAll /></th>
                                    <th className="controls"><SingleSelectDropdown items={phoneFilterOptions} selectedId={phoneFilter} onSelect={setPhoneFilter} showAll /></th>
                                    <th className="controls"></th>
                                    {
                                        canViewFinancials &&
                                            <th className="controls"></th>
                                    }
                                    {
                                        canViewFinancials &&
                                            <th className="controls"></th>
                                    }
                                    <th className="controls"><SingleSelectDropdown items={customerTypesOptions} selectedId={customerTypeFilter} onSelect={setCustomerTypeFilter} align={"end"} showAll /></th>
                                    <th className="controls"><SingleSelectDropdown items={rateFilterOptions} selectedId={rateFilter} onSelect={setRateFilter} align={"end"} showAll /></th>
                                    <th className="controls text-center"><SingleSelectDropdown items={colorOptions()} selectedId={colorFilter} onSelect={setColorFilter} align={"end"} idField={'value'} showAll showSearch hideLabelOnHeader /></th>
                                    { showSubGroupsColumn && <th></th> }
                                    { !isPartnerFacility && <th className="controls"><SingleSelectDropdown items={statusFilterOptions} selectedId={statusFilter} onSelect={setStatusFilter} align={"end"} showAll /></th> }
                                    {
                                        showPartnerColumn && <th></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.map(customers, (customer, i) =>
                                        <tr key={i} className={customer.blocked ? "danger": ""}>
                                            <td className="no-stretch">
                                                <div className="checkbox check-success inline">
                                                    <input type="checkbox" className="" value={getRowId(customer)} id={getRowId(customer)} name="delete[]"  checked={checkedCustomerIds.includes(getRowId(customer))} onChange={ (event) => updateChecked(event) }/>
                                                    <label htmlFor={getRowId(customer)}/>
                                                </div>
                                            </td>
                                            <td><Link to={`/${facilityLink}/booking/list?groupId=${customer.id}`}>{ customer.groupName }</Link></td>
                                            <td>{ _.join(_.filter([customer.firstName, customer.lastName], a => a), " ") }</td>
                                            <td>{ customer.email }</td>
                                            <td>{ customer.phone }</td>
                                            <td className="text-end">{ customer.bookingsCount > 0 && <span className="label">{ customer.bookingsCount }</span> }</td>
                                            {
                                                canViewFinancials &&
                                                    <td className="text-end"><span className="label label-success">{ currencyFormat(customer.cost || 0) }</span></td>
                                            }
                                            {
                                                canViewFinancials &&
                                                    <td className="text-end"><span className="label label-info">{ currencyFormat(customer.credit || 0) }</span></td>
                                            }
                                            <td className="text-end">{ customer.type && customer.type }</td>
                                            <td className="text-end">{ customer.rate && currencyFormat(customer.rate) }</td>
                                            <td className="text-center">{ customer.color && <i className="fa fa-circle fs-16" style={{ color: customer.color }} /> }</td>
                                            {
                                                showSubGroupsColumn &&
                                                    <td>
                                                        {
                                                            customer.subGroupsCount > 0 &&
                                                                <BaseOverlayTrigger content={t("customers.list.has_subgroups", { group_name: customer.groupName, num_subgroups: customer.subGroupsCount } )} >
                                                                    <Image src={"/images/la_subgroup.png"} width={"15px"}/>
                                                                </BaseOverlayTrigger>
                                                        }
                                                    </td>
                                            }
                                            { !isPartnerFacility && <td className="text-center">
                                                { customer.active ? <i className="fa fa-check-circle fa-c-success fs-16"></i> : <i className="fa fa-check-circle fa-c-info fs-16"></i> }
                                            </td> }
                                            {
                                                showPartnerColumn &&
                                                <td>
                                                    {
                                                        !_.isEmpty(customer.partnerMapping) &&
                                                            <Image src={"/images/la_logo.png"} width={"15px"}/>
                                                    }
                                                </td>
                                            }
                                        </tr>
                                    )
                                }
                                {
                                    hasMore ?
                                        <tr>
                                            <td colSpan={20} className="center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                    : <tr>
                                            <td colSpan={20} className="center">
                                                <div className="label">{t('customers.list.end_of_customers')}</div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </InfiniteScroll>
                </div>
            </div>
            <AddCustomerModal show={showAddCustomerModal} onClose={onModalClose} />
            <EditBulkCustomersModal show={showBulkCustomersModal} onClose={onModalClose} customers={checkedCustomers} />
            <SalesAllocationReportModal show={showSalesAllocationReportModal} onClose={setShowSalesAllocationReportModal}
                type={salesAllocationReportType} />
            <CustomerDetailsModal show={showCustomerDetailsModal} onClose={onModalClose} customers={checkedCustomers} />
            <CustomerEmailModal show={showCustomerEmailModal} onClose={onModalClose} customers={checkedCustomers} />
            <CustomersCSVExportModal
                show={showCustomersCSVExportModal}
                onClose={setShowCustomersCSVExportModal}
                items={checkedCustomers}
                extraItemsParams={ !dateFilter ? null : { dateFilter: {
                    filterType: dateFilter,
                    startDate: startDate,
                    endDate: endDate,
                }}}
                criteria={getQueryParams()}
                useCriteria={checkedCustomerIds.includes("checkall")} />
            <MergeCustomersModal show={showMergeCustomersModal} onClose={onModalClose} customers={checkedCustomers} />
        </BaseContainer>
    );
}

export default GroupList;
