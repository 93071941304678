import { useEffect, useState, useContext } from 'react';
import $ from "jquery";
import '../../App.scss';
import '../../css/forms.scss';
import DeleteButton from '../../components/DeleteButton';
import BaseContainer from '../../components/Container';
import BaseForm from '../../components/BaseForm';
import TabHeader from '../../components/TabHeader';
import SubmitButton from '../../components/SubmitButton';
import { serverFetch, serverPatch, serverPost, serverDelete } from '../../helpers/server';
import { BaseContext, getDatePickerDateFormatForFacility } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Button, Row, Col, Table, InputGroup, ButtonToolbar } from 'react-bootstrap';
import Notification from '../../components/Notification';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const _ = require("lodash");

function Tax() {
    const { getApiUrl, facilityLink, getFacilityName, settings } = useContext(BaseContext);
    const { t } = useTranslation('common');

    const [taxes, setTaxes] = useState([]);
    const [numberOfRows, setNumberOfRows] = useState(1);

    useEffect(() => {
        document.title = `Tax Settings - ${getFacilityName()}`;
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl('/taxes')).then((res) => {
            setTaxes(res);
            setNumberOfRows(res.length);
        })
    }, []);

    const updateSettings = (data) => {
        const taxData = {...data};
        taxData['isExclusive'] = data['isExclusive'] === "true";
        serverPatch(getApiUrl('/settings'), taxData, {}).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
            }
        });
    }

    const updateTaxes = (row, data) => {
        const tax = taxes[row];
        data['startDate'] = $.datepicker.formatDate(dateFormat, moment(data['startDate']).toDate());
        data['endDate'] = $.datepicker.formatDate(dateFormat, moment(data['endDate']).toDate());
        if (!_.isNil(tax.id)) {
            serverPatch(getApiUrl(`/taxes/${tax.id}`), data, {}).then((res) => {
                if (res) {
                    Notification.Show(t('notification.successfully_updated'));
                }
            });
        } else {
            serverPost(getApiUrl(`/taxes`), data, {}).then((res) => {
                if (res) {
                    Notification.Show(t('notification.successfully_updated'));
                    taxes[row]['id'] = res.id;
                }
            });
        }
    }

    const onFieldChange = (name, value) => {
        const data = {};
        data[name] = value;
        serverPatch(getApiUrl('/settings'), data, {}).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
            }
        });
    }

    const onAddNew = () => {
        setNumberOfRows(numberOfRows + 1);
        taxes.push({});
        setTaxes(taxes);
    }

    const onDelete = (row) => {
        const tax = taxes[row];
        if (!_.isNil(tax.id)) {
            // Delete saved tax item
            serverDelete(getApiUrl(`/taxes/${tax.id}`)).then((res) => {
                setTaxes(taxes.splice(row));
            })
        } else {
            // Is not saved to server so just remove it.
            setTaxes(taxes.splice(row));
        }
    }

    const onUpdateBookings = () => {
        // TODO(aravind): Make an endpoint to update taxes in current bookings
    }

    const showTaxesOptions = [
        { 'value': false, 'label': t("setting.tax.inclusive") },
        { 'value': true, 'label': t("setting.tax.exclusive") },
    ]

    const dateFormat = getDatePickerDateFormatForFacility(settings);

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "taxes")}/>
            <div className="content-box">
                <div className="content-body">
                    <BaseForm initialFormFields={settings} onSubmit={updateSettings}>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" name="taxLabel1" label={t("setting.tax.tax_label")} required/>
                            <BaseForm.Input colSpan="4" type="text" name="tax1" label={t("setting.tax.tax_rate")} rightContent="%" />
                            <BaseForm.Input colSpan="3" type="select" name="isExclusive" label={t("setting.tax.show_taxes")} options={showTaxesOptions} showSearch={false} />
                            <Col md="1" className="d-flex align-items-center">
                                <SubmitButton variant="success"><i className="fa fa-save"/></SubmitButton>
                            </Col>
                        </Row>
                    </BaseForm>
                </div>
            </div>
            <div className="content-box">
                <div className="content-header">{t("setting.tax.hide_taxes")}</div>
                <div className="content-body">
                    <BaseForm initialFormFields={settings} onFieldChange={onFieldChange}>
                        <Row>
                            <BaseForm.Input colSpan="3" type="check" name="hideInvoiceTax" label={t("setting.tax.invoice_tax")} />
                            <BaseForm.Input colSpan="3" type="check" name="hideAgreementTax" label={t("setting.tax.agreement_tax")} />
                            <BaseForm.Input colSpan="3" type="check" name="hideTaxColumn" label={t("setting.tax.inv_agr_tax")} />
                        </Row>
                    </BaseForm>
                </div>
            </div>
            <div className="content-box">
                <div className="content-header">{t("setting.tax.tax_changes")}</div>
                <div className="content-body">
                    <p>{t("setting.tax.tax_changes_desc")}</p>
                    <div className="hide">
                        <Table hover>
                            <thead>
                                <tr>
                                    <th width="18%">{t('tax.tax_label')}</th>
                                    <th width="18%">{t('tax.tax')}</th>
                                    <th width="18%">{t('common.start_date')}</th>
                                    <th width="18%">{t('common.end_date')}</th>
                                    <th width="18%">{t('tax.rate')}</th>
                                    <th width="10%"></th>
                                </tr>
                            </thead>
                        </Table>
                        {
                            _.range(numberOfRows).map((r, i) =>
                                <BaseForm key={i} initialFormFields={taxes[r]} onSubmit={(data) => updateTaxes(r, data)}>
                                    <Table hover>
                                        <tbody>
                                            <tr key={i}>
                                                <td width="18%">
                                                    <BaseForm.Control2 name={"taxLabel"} type="text" label="Tax Label" hideLabel className="inline" required />
                                                </td>
                                                <td width="18%">
                                                    <BaseForm.Control2 name={"tax"} type="text" required label="Tax Rate" hideLabel rightContent="%" />
                                                </td>
                                                <td width="18%">
                                                    <BaseForm.InputGroup className={"inline"}>
                                                        <BaseForm.Date className={"inline"} name={"startDate"} dateFormat={dateFormat} label="Start Date" id={"startDate_"+r} hideLabel required />
                                                        <InputGroup.Text><i className="fa fa-calendar"/></InputGroup.Text>
                                                    </BaseForm.InputGroup>
                                                </td>
                                                <td width="18%">
                                                    <BaseForm.InputGroup className={"inline"}>
                                                        <BaseForm.Date className={"inline"} name={"endDate"} dateFormat={dateFormat} label="End Date" id={"endDate_"+r} hideLabel required />
                                                        <InputGroup.Text><i className="fa fa-calendar"/></InputGroup.Text>
                                                    </BaseForm.InputGroup>
                                                </td>
                                                <td width="18%"><BaseForm.SingleSelect name={"isExclusive"} className="inline" options={showTaxesOptions} showSearch={false} /></td>
                                                <td width="10%">
                                                    <ButtonToolbar>
                                                        <SubmitButton variant="primary"><i className="fa fa-save"/></SubmitButton>
                                                        <DeleteButton size="md" onDelete={() => onDelete(r)}/>
                                                    </ButtonToolbar>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </BaseForm>
                            )
                        }
                        <Button variant="outline-primary" onClick={onAddNew}><i className="fa fa-circle-plus" /> Add New</Button>
                        <Button variant="primary" onClick={() => onUpdateBookings()}>Update Current Bookings</Button>
                    </div>
                </div>
            </div>
        </BaseContainer>
    );
}

export default Tax;
