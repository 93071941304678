import '../../App.scss';
import '../../css/modals.scss';
import React, { useState, useEffect, useContext, createRef } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import ClassFormFieldsComponent from '../ClassFormFieldsComponent';
import ClassRegistrationForm from '../AdminClassRegistrationForm';
import {Row, Col, Alert, ButtonGroup, Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BaseContext } from '../../helpers/common';
import {serverPost} from "../../helpers/server";
import BookingPaymentInput from "../BookingPaymentInput";
const _ = require("lodash");

function AddClassParticipantModal(props) {
    const { userInfo, getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [classDetails, setClassDetails] = useState({});
    const [payLater, setPayLater] = useState({});
    const classFormFieldsRef = createRef();
    const paymentRef = createRef();

    useEffect(() => {
        setClassDetails(props.classDetails);
    }, [props.classDetails])

    return (
        <BaseModal {...props} size="lg">
            <BaseModal.Header>
                <BaseModal.Title>Add Class Participant</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <ClassRegistrationForm classDetails={classDetails} classForm={classDetails.form} addToWaitlist={props.addToWaitlist} onRegistrationComplete={() => props.onClose(true)} />
            </BaseModal.Body>
        </BaseModal>
    );

}

export default AddClassParticipantModal;
