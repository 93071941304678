import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useContext, useState, useEffect } from 'react';
import { Button, Row, Col, ButtonGroup, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { serverFetch } from '../../helpers/server';
import Loader from '../Loader';

const _ = require("lodash");

function BookingSearchEmailModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const { facilityLink } = useParams();
    const [emailEnabled, setEmailEnabled] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (props.show) {
            serverFetch(getApiUrl(`/emails`)).then((res) => {
                const invoiceEmailOptionEnabled = res.find(obj => obj.tag === 'search_availability' && obj.enabled === true);
                if (invoiceEmailOptionEnabled) {
                    setEmailEnabled(true);
                } else {
                    setEmailEnabled(false);
                }
                setLoading(false);
            })
        }
    }, [props.show]);

    const onSubmit = (data) => {
        const url = getApiUrl('/bookings/availability_search/email');
        const emailData = {
            forEmail: {
                addresses: data.email.split(","),
                customerMsg: data.customerMessage
            },
            forExport: {
                items: props.emailData
            }
        };
        serverPost(url, emailData).then((res) => {
            props.onClose();
        });
    }

    return (
        <BaseModal {...props}>
            <BaseForm onSubmit={onSubmit}>
                <BaseModal.Header>
                    <BaseModal.Title>Email</BaseModal.Title>
                </BaseModal.Header>
                <Loader loading={loading}>
                { emailEnabled ? (
                    <>
                        <BaseModal.Body>
                            <Row>
                                <Alert variant="info">
                                    Feel free to add as many emails as you like. Separate each email with a comma. (e.g. "one@rectimes.com, two@rectimes.com, three@rectimes.com")
                                </Alert>
                                <BaseForm.Input colSpan={12} name={'email'} label={"Email Addresses"} type={'text'} placeholder={'Email addresses'} validations={{required: true, validEmails: true}} />
                                <BaseForm.Input colSpan={12} name={'customerMessage'} label={"Customer Message"} type={'textarea'} validations={{required: true}} />
                            </Row>
                        </BaseModal.Body>
                        <BaseModal.Footer>
                            <Row>
                                <Col md="12" className="text-end">
                                    <SubmitButton variant="primary">Send</SubmitButton>
                                </Col>
                            </Row>
                        </BaseModal.Footer>
                    </>
                ) : (
                    <BaseModal.Body>
                        <Row>
                            <p>{t('email.not_enabled_1')} <a href={`/${facilityLink}/setting/emaillist`}>{t('email.not_enabled_2')}</a> {t('email.not_enabled_3')}</p>
                        </Row>
                    </BaseModal.Body>
                )}
                </Loader>
            </BaseForm>
        </BaseModal>
    );

}

export default BookingSearchEmailModal;
