import '../App.scss';
import { getUrl, baseGetApiUrl, serverFetch, subscribeToEvent } from '../helpers/server'
import { getCategory, setCategory, storeUserInfo } from '../helpers/storage'
import { serverPost } from '../helpers/server'
import { BaseContext, getFormattedName, sleep } from '../helpers/common'
import { useEffect, useState } from 'react';
import { Outlet, useSearchParams } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import ErrorComponent from '../components/ErrorComponent';
import BaseContainer from '../components/Container';
const _ = require("lodash");

function FacilityComponent(props) {
    let { facilityLink } = useParams();
    const [ searchParams ] = useSearchParams();
    let tempToken = searchParams.get('tempToken');
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [settings, setSettings] = useState({});
    const [loadingUserInfo, setLoadingUserInfo] = useState(true);
    const [loadingFacilityId, setLoadingFacilityId] = useState(true);

    useEffect(() => {
        if (!tempToken) {
            return;
        }
        let urlParser = new URL(window.location.href);
        urlParser.searchParams.delete('tempToken')
        const data = {
            token: tempToken
        }
        serverPost(baseGetApiUrl(facilityLink, "/refresh_token"), data).then((res) => {
            if (res) {
                localStorage.setItem("auth_token", res['token']);
                if (res['user']) {
                    storeUserInfo(res['user']);
                }
            }
            window.location.replace(urlParser.href);
        })
    })

    useEffect(() => {
        const handleError = (error) => {
            if (error.status === 404) {
                // TODO(Aswin): Find a way to get to the facility that this user has permission for
                navigate("/");
            } else {
                setLoadingUserInfo(false);
            }
        }

        const authToken = localStorage.getItem("auth_token");
        if (!authToken) {
            setLoadingUserInfo(false);
        } else {
            serverFetch(getUrl(`/api/v1/facilities/${facilityLink}/user`, false), null, handleError).then((res) => {
                if (res && !_.isEmpty(res)) {
                    const user = {...res};
                    user.fullName = getFormattedName(user);
                    setUserInfo(user);
                }
                setLoadingUserInfo(false);
            })
        }

        fetchData();
        subscribeToEvent("settings", (data) => { fetchData(true) });
    }, [facilityLink]);

    useEffect(() => {
        if (_.isEmpty(settings)) {
            return;
        }
        if (!settings.useNewWebapp) {
            const newUrl = "https://rectimes.app" + window.location.pathname + window.location.search;

            const stopRedirect = localStorage.getItem("redirect_new_webapp")
            if (!_.isNil(stopRedirect) && stopRedirect === "false") {
                return
            } else if (window.location.hostname !== "localhost") {
                window.location.href = newUrl;
            }
        }
    }, [settings])

    const fetchData = (skipCache=false) => {
        serverFetch(getUrl(`/api/v1/facilities/${facilityLink}`, false), { skipCache }).then((res) => {
            setSettings(res);
            if (res.isPartnerFacility) {
                const element = document.querySelector('meta[name="description"]');
                if (element) {
                    element.setAttribute('content', "Manage your facility spaces with LeagueApps Facilities.")
                }
            }
            if (!res.showAllVenueOption) {
                const category = getCategory(facilityLink);
                if (!category) {
                    serverFetch(getUrl(`/api/v1/facilities/${facilityLink}/venue_categories`)).then((res) => {
                        if (res && res.length > 0) {
                            setCategory(facilityLink, res[0].id)
                        }
                        setLoadingFacilityId(false);
                    });
                } else {
                    setLoadingFacilityId(false);
                }
            } else {
                setLoadingFacilityId(false);
            }
        })
    }

    const allReady = !tempToken && !loadingUserInfo && !loadingFacilityId;
    const facilityFound = !(_.isEmpty(settings) || _.isNil(settings));
    return (
        <BaseContext.Provider value={{
            userInfo,
            isLoggedIn: !_.isEmpty(userInfo),
            settings,
            facilityLink: facilityLink,
            getFacilityName: () => settings.facilityName,
            getApiUrl: (...args) => baseGetApiUrl(facilityLink, ...args)
        }}>
        {
            allReady && facilityFound && <Outlet />
        }
        {
            allReady && !facilityFound &&
                <BaseContainer>
                    <ErrorComponent />
                </BaseContainer>
        }
        </BaseContext.Provider>
    );
}

export default FacilityComponent;
