import '../../App.scss';
import '../../css/modals.scss';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import BaseForm from '../BaseForm';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function AddOrEditVenueModal(props) {
    const { t } = useTranslation('common');

    return (
        <BaseAddOrEditItemModal
            {...props}
            itemLabel={"Venue"}
            itemBaseUrl={'/venues'}
        >
            <Row>
                <BaseForm.Input colSpan={12} name={'name'} label={t('forms.venue')} type={'text'} placeholder={'Venue Name'} required />
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditVenueModal;
