import '../../App.scss';
import '../../css/modals.scss';
import {useContext, useEffect, useState, createRef} from 'react';
import { useTranslation } from 'react-i18next';
import BaseModal from './BaseModal';
import Notification from '../Notification';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Loader from '../Loader';
import {serverFetch, serverPost} from '../../helpers/server';
import { BaseContext, currencyFormat, getDateFormatForFacility } from '../../helpers/common';
import {Button, ButtonGroup, Col, Row, Table} from 'react-bootstrap';
import moment from "moment/moment";
import BookingPaymentInput from "../BookingPaymentInput";
const _ = require('lodash');

function ParticipantPaymentsModal(props) {
    const { getApiUrl, isLoggedIn, settings } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [registration, setRegistration] = useState({});
    const [participant, setParticipant] = useState(null);
    const [payments, setPayments] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [enabledPaymentMethodsError, setEnablePaymentMethodsError] = useState(false);
    const [calcCost, setCalcCost] = useState({});
    const [initialPaymentFormFields, setInitialPaymentFormFields] = useState({});
    const { t } = useTranslation('common');
    const paymentRef = createRef();
    const formRef = createRef();
    
    useEffect(() => {
        if (props.show && isLoggedIn) {
            serverFetch(getApiUrl(`/payment_methods`)).then((res) => {
                const enabledPaymentMethods = res.filter(r => r.isClassOption)
                if (enabledPaymentMethods.length >= 1) {
                    setPaymentMethods(enabledPaymentMethods)
                } else {
                    setEnablePaymentMethodsError(true);
                }
            });
        }
    }, [props.show, isLoggedIn]);

    useEffect(() => {
        setParticipant(props.participant);
    }, [props.participant])

    useEffect(() => {
        if (props.show && participant) {
            fetchRegistration();
        }
    }, [props.show, participant]);

    useEffect(() => {
        updateCalcCost();
    }, [registration])

    useEffect(() => {
        setInitialPaymentFormFields({
            'amount': Number("100").toFixed(2),
            'paymentDate': moment().format("YYYY-MM-DD"),
            'paymentMethod': !_.isEmpty(paymentMethods) ? paymentMethods[0].paymentTag: null
        });
    }, [props.classDetails, props.session, props.participant])

    useEffect(() => {
        if (_.isEmpty(calcCost)) {
            return;
        }
        const totalInCents = calcCost.totalInCents;
        const paidInCents = _.sumBy(payments, 'amountInCents');
        const dueInCents = totalInCents - paidInCents;
        setInitialPaymentFormFields(prevFields => {
            const newFields = {...prevFields};
            newFields['amount'] = parseFloat(dueInCents)/100
            return newFields;
        })
    }, [payments, calcCost])

    const updateCalcCost = () => {
        if (!registration || _.isEmpty(registration)) {
            return;
        }
        const calcData = {
            sessionIds: _.map(registration.participants, (s) => s.sessionId)
        }
        serverPost(getApiUrl(`/classes/${registration.classId}/register_calc_cost`), calcData).then(res => {
            if (res) {
                setCalcCost(res);
            }
        })
    }

    const fetchRegistration = (skipCache=false) => {
        serverFetch(getApiUrl(`/classes/${participant.classId}/registrations/${participant.registrationId}`), { skipCache }).then((res) => {
            if (res) {
                setRegistration(res);
                setPayments(res.payments);
            }
            setLoading(false);
        });
    }

    const onFieldChange = (name, value) => {
        if (name === "paymentMethod") {
            setSelectedPaymentMethod(value);
        }
    }

    const addPayment = async (data) => {
        const formData = formRef.current.getFormData();
        let bookingPaymentMethod = null;
        if (paymentRef.current) {
            bookingPaymentMethod = await paymentRef.current.getPaymentMethod(formData);
            if (!bookingPaymentMethod) {
                return;
            }
        }

        const sessionIds = registration.participants.map((participant) => participant.sessionId);
        const paymentData = {
            paymentMethod: data.paymentMethod,
            paymentDate: data.paymentDate,
            amountInCents: parseFloat(data.amount) * 100,
            note: data.note,
            calculatedCost: calcCost,
            sessionIds: sessionIds ?? null
        }
        if (bookingPaymentMethod) {
            paymentData["paymentData"] = {
                stripe: bookingPaymentMethod
            }
            paymentData["paymentMethod"] = "stripe";
        }
        console.log("The payment data is " + JSON.stringify(data));
        serverPost(getApiUrl(`/classes/${registration.classId}/registrations/${registration.id}/pay`), paymentData).then((res) => {
            if (res) {
                fetchRegistration(true);
            }
        })

        /*
        val paymentData: PaymentService.PaymentProcessorData? = null,
        val paymentMethod: String,
        val bookingIds: List<Long>? = null,
        val amountInCents: Long? = null,
        val calculatedCost: ClassRateCalc? = null,
        */
    }

    const paymentMethodOptions = _.map(paymentMethods, (m) => { return { 'id': m.paymentTag, 'label': _.replace(m.paymentType, "Paid ", ""), 'icon': m.paymentIcon }; });

    const renderPayments = () => {
        const dateFormat = getDateFormatForFacility(settings);
        if (_.isEmpty(payments)) {
            return null;
        } else {
            const hasReferences = !_.every(payments, (pp) => _.isEmpty(pp.note))
            return (
                <>
                <h5>Payments</h5>
                <Table>
                    <thead>
                        <tr>
                            <th>{t('payment.list.payment_date')}</th>
                            <th className="text-end">{t('payment.list.amount_paid')}</th>
                            <th className="text-end">{t('payment.list.type')}</th>
                            {
                                hasReferences &&
                                    <th className="text-end">{t('payment.list.reference')}</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            _.map(payments, (payment, i) =>
                                <tr key={i}>
                                    <td>{ moment(payment.createdAtLocal).format(dateFormat) }</td>
                                    <td className="text-end"><span className="label label-success">{ payment.amountFormatted }</span></td>
                                    <td className="text-end">
                                        <span className="label">{ _.replace(payment.paymentMethod, "Paid ", "") }</span>
                                    </td>
                                    {
                                        hasReferences &&
                                            <td className="text-end">
                                                <span>{ payment.note }</span>
                                            </td>
                                    }
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                </>
            )
        }
    }

    return (
        <BaseModal {...props} size="lg">
            <BaseModal.Header>
                <BaseModal.Title>Registration Details</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <Loader loading={loading}>
                {
                    () =>
                        <>
                        <h5>Class Registration Details</h5>
                        <p>
                            <span><strong>{t('common.name')}:</strong>&nbsp;</span><span>{ registration.userInfo.name }</span><br/>
                            <span><strong>{t('common.email')}:</strong>&nbsp;</span><span>{ registration.userInfo.emailId }</span><br/>
                            <span><strong>Sessions:</strong>&nbsp;</span><span>{ registration.participants.length } session(s)</span><br/>
                            <span><strong>Total Cost:</strong>&nbsp;</span><span>{ currencyFormat(registration.totalInCents/100) }</span><br/>
                            {
                                registration.isPaid ?
                                    <><span><strong>Status:</strong>&nbsp;</span><span className="label label-success">{ t('registration.class_list.paid') }</span></>
                                : payments.length > 0 ?
                                    <>
                                        <span><strong>Paid Amount:</strong>&nbsp;</span><span>{ currencyFormat(_.sumBy(payments, 'amountInCents')/100) }</span><br/>
                                        <span><strong>Status:</strong>&nbsp;</span><span className="label">{ t('registration.class_list.partially_paid') }</span>
                                    </>
                                : <><span><strong>Status:</strong>&nbsp;</span><span className="label">{ t('common.no') }</span></>
                            }
                        </p>

                        { renderPayments() }
                        <br/>
                        {
                            !registration.isPaid &&
                                <>
                                    <h5>Add Payment</h5><br/>
                                    <BaseForm ref={formRef} initialFormFields={initialPaymentFormFields} onFieldChange={onFieldChange} onSubmit={addPayment}>
                                        <Row>
                                            <BaseForm.Input colSpan="3" type="number" step="0.01" name="amount" label="Amount Paid" leftContent="$" min="0.01" validations={{ required: true, min: 0.01, max: initialPaymentFormFields['amount'] }}/>
                                            <BaseForm.Input colSpan="3" type="date" name="paymentDate" label="Date Paid" rightContent={<i className="fa fa-calendar" />} />
                                            <BaseForm.Input colSpan="3" type="select" name="paymentMethod" label="Payment Method" options={paymentMethodOptions} idField="id" required />
                                            <BaseForm.Input colSpan="3" type="text" name="note" label="Reference" />
                                        </Row>
                                        {
                                            selectedPaymentMethod === "paidCreditOnline" &&
                                                <BookingPaymentInput ref={paymentRef} />
                                        }
                                        
                                        { enabledPaymentMethodsError && (
                                            <Row>
                                                <div className="col-md-12 d-flex align-items-center" style={{color: 'red', margin: '10px 0 15px 0'}}>{t("classes.participant_payment_modal")}</div>
                                            </Row>    
                                        )}
                                        <Row>
                                            <div className="col-md-4 d-flex align-items-center">
                                                <SubmitButton disabled={enabledPaymentMethodsError}>Add Payment</SubmitButton>
                                            </div>
                                        </Row>
                                    </BaseForm>

                                </>
                        }
                        </>
                }
                </Loader>
            </BaseModal.Body>
            <BaseModal.Footer>
                <Row>
                    <Col md="12" className="text-end">
                        <Button onClick={() => props.onClose()}>Close</Button>
                    </Col>
                </Row>
            </BaseModal.Footer>
        </BaseModal>
    );

}

export default ParticipantPaymentsModal;
