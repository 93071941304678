import React, { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader';
import SetupPaymentMethodModal from '../../components/modals/SetupPaymentMethodModal';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import DeleteButton from '../../components/DeleteButton';
import {serverDelete, serverFetch} from '../../helpers/server';
import { BaseContext, getPaymentProcessorName } from '../../helpers/common';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Button, Table, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function PaymentMethods() {
    const { settings, userInfo, getApiUrl, getFacilityName } = useContext(BaseContext);
    const navigate = useNavigate();
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');

    const [showSetupPaymentMethodModal, setShowSetupPaymentMethodModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState(null);
    const [paymentSourceMap, setPaymentSourceMap] = useState({});

    const [ searchParams ] = useSearchParams();
    let redirectStatus = searchParams.get('redirect_status');
    let groupId = searchParams.get('groupId');

    useEffect(() => {
        document.title = "Payment Methods - " + getFacilityName();
    }, []);

    useEffect(() => {
        if (!groupId) {
            serverFetch(getApiUrl(`/user_groups`, { userId: userInfo.id })).then((res) => {
                if (res) {
                    setGroups(res);
                }
            })
        } else {
            serverFetch(getApiUrl(`/user_groups/${groupId}`)).then((res) => {
                if (res) {
                    setGroups([res]);
                }
            })
        }
    }, []);

    useEffect(() => {
        if (!_.isNil(groups)) {
            _.each(groups, (group) =>
                fetchPaymentSources(group)
            )
        }
    }, [groups]);

    useEffect(() => {
        if (_.isNil(redirectStatus)) {
            return;
        }
        setTimeout(() => {
            let urlParser = new URL(window.location.href);
            urlParser.searchParams.delete('setup_intent');
            urlParser.searchParams.delete('setup_intent_client_secret');
            urlParser.searchParams.delete('redirect_status');
            navigate(urlParser.pathname + urlParser.search, { replace: true });
        }, 100);
    }, [redirectStatus])

    const fetchPaymentSources = async (group, skipCache=false) => {
        await serverFetch(getApiUrl(`/user_groups/${group.id}/payment_sources`), { skipCache: true }).then((res) => {
            if (res) {
                setPaymentSourceMap(prevMap => {
                    const newMap = {...prevMap};
                    newMap[group.id] = res;
                    return newMap;
                })
                setLoading(false);
            }
        })
    }

    const onModalClose = (didUpdate) => {
        setShowSetupPaymentMethodModal(false);
        if (selectedGroup) {
            fetchPaymentSources(selectedGroup, true);
        }
    }

    const addPaymentMethod = (event, group) => {
        setShowSetupPaymentMethodModal(true);
        setSelectedGroup(group);
    }

    const deletePaymentMethod = async (group, method) => {
        await serverDelete(getApiUrl(`/user_groups/${group.id}/payment_sources/${method.id}`)).then((res) => {
            fetchPaymentSources(group, true);
        })
    }

    const renderGroup = (group, i) => {
        const methods = paymentSourceMap[group.id];
        return (
            <div key={i} className="payment-method-group">
                <h4>{ group.groupName } - Payment Methods</h4>
                <span>The following payment methods are stored in { getPaymentProcessorName(settings.paymentProcessor) }.</span>
                <Row>
                    <Col md="7">
                        <Table>
                            <tbody>
                            {
                                _.map(methods, (method, j) =>
                                    <tr key={j}>
                                        <td><i className="fa fa-credit-card"/>&nbsp;<strong style={{textTransform:"uppercase"}}>{method.card.brand}</strong></td>
                                        <td>
                                            <span>xxxx-xxxx-xxxx-{method.card.last4}</span>
                                        </td>
                                        <td>
                                            <span>Exp {_.padStart(method.card.expMonth, 2, "0")}/{method.card.expYear}</span>
                                        </td>
                                        <td>
                                            <DeleteButton onDelete={() => deletePaymentMethod(group, method)}/>
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Button variant="outline-primary" onClick={(event) => addPaymentMethod(event, group)}>
                    <i className="fa fa-circle-plus"/>&nbsp;Add New Payment Method
                </Button>
            </div>
        )
    }

    const tabsParams = groupId && {
        searchParams: groupId && `groupId=${groupId}`,
        groupId: groupId,
        userGroup: (!_.isNil(groups) && groups.length > 0) ? groups[0]: null,
        userInfo: userInfo,
        settings: settings
    };

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "non-admin-user", tabsParams)} />
            <div className="content-box">
                <div className="content-body">
                    <Loader loading={loading}>
                        {
                            _.map(groups, (group, i) =>
                                renderGroup(group, i)
                            )
                        }
                    </Loader>
                </div>
            </div>
            <SetupPaymentMethodModal
                show={showSetupPaymentMethodModal} onClose={onModalClose} group={selectedGroup} />
        </BaseContainer>
    );
}

export default PaymentMethods;
