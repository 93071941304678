import '../../App.scss';
import '../../css/modals.scss';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BaseModal from './BaseModal';
import Notification from '../Notification';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { Button, ButtonGroup } from 'react-bootstrap';

function ContactFormModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');

    const contact = (contactData) => {
        serverPost(getApiUrl('/contact_form'), contactData).then((res) => {
            Notification.Show("Contact email sent successfully");
            props.onClose();
        })
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Contact { props.headerName }</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm onSubmit={contact}>
                <BaseModal.Body>
                    <p>Send a message to { props.headerName }.</p>
                    <BaseForm.Input colSpan={12} name={'name'} label={"Name"} type={'text'} required />
                    <BaseForm.Input colSpan={12} name={'phone'} label={"Phone"} type={'text'} required />
                    <BaseForm.Input colSpan={12} name={'email'} label={t('forms.email')} placeholder="Email Address" type={'text'} required validations={{validEmail: true}} />
                    <BaseForm.Input colSpan={12} name={'message'} label={"Message"} placeholder="Your message" type={'textarea'} required />
                </BaseModal.Body>
                <BaseModal.Footer>
                    <ButtonGroup className="text-end">
                        <SubmitButton className="btn-complete">Send Message</SubmitButton>
                    </ButtonGroup>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default ContactFormModal;
