import $ from "jquery";
import 'jquery-ui/ui/widgets/sortable';
import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import DeleteButton from '../../components/DeleteButton';
import AddOrEditCustomerTypeModal from '../../components/modals/AddOrEditCustomerTypeModal';
import TabHeader from '../../components/TabHeader';
import { serverFetch, serverPost, serverDelete } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function CustomerTypeList() {
    const { getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [customerTypes, setCustomerTypes] = useState([]);
    const [initialSortedItems, setInitialSortedItems] = useState([]);
    const [customerTypeToEdit, setCustomerTypeToEdit] = useState(null);
    const [showAddCustomerTypeModal, setShowAddCustomerTypeModal] = useState(false);

    useEffect(() => {
        document.title = t("setting.customer_types.customer_types") + " - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, [facilityLink]);

    useEffect(() => {
        if (loading) {
            return;
        }
        $(function() {
            $('#sortable-table').sortable({
                items:'tr',
                cursor: 'grabbing',
                stop: function(event, tbody) {
                    const sortedItems = $(this).sortable("toArray");
                    if (JSON.stringify(sortedItems) !== JSON.stringify(initialSortedItems)) {
                        updateCustomerTypeSort(sortedItems);
                    }
                },
                helper: function(e, tr) {
                    var $originals = tr.children();
                    var $helper = tr.clone();
                    $helper.children().each(function(index) {
                        // Set helper cell sizes to match the original sizes
                        $(this).width($originals.eq(index).width());
                    });
                    return $helper;
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialSortedItems, loading]);

    const onModalClose = () => {
        fetchData(true);
        setShowAddCustomerTypeModal(false);
    }

    const fetchData = (skipCache = false) => {
        setLoading(true);
        serverFetch(getApiUrl('/customer_types'), { skipCache }).then((res) => {
            setCustomerTypes(res);
            if (!_.isEmpty(res)) {
                setInitialSortedItems(res.map(r => r.id));
            } else {
                setInitialSortedItems([]);
            }
            setLoading(false);
        })
    }

    const updateCustomerTypeSort = (orderedItems) => {
        const updateCustomerTypeUrl = getApiUrl('/customer_types/sort');
        const data = {
            'ids': orderedItems
        };
        serverPost(updateCustomerTypeUrl, data).then((res) => {
            fetchData(true);
        })
    }

    const addCustomerType = () => {
        setCustomerTypeToEdit(null);
        setShowAddCustomerTypeModal(true);
    }

    const editCustomerType = (type) => {
        setCustomerTypeToEdit(type);
        setShowAddCustomerTypeModal(true);
    }

    const deleteCustomerType = (type) => {
        const deleteCustomerTypeUrl = getApiUrl(`/customer_types/${type.id}`);
        serverDelete(deleteCustomerTypeUrl, {}).then((res) => {
            fetchData(true);
        })
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "customer-types")}/>
            <div className="content-box">
                <div className="content-body">
                    <Button variant="outline-primary" onClick={() => addCustomerType()}><i className="fa fa-plus-circle"></i> {t('setting.customer_types.add')}</Button>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-lg-9">
                            <Loader loading={loading}>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t('setting.customer_types.customer_type')}</th>
                                            <th>{t('common.sort_order')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody id="sortable-table">
                                        {
                                            _.map(customerTypes, (type, i) =>
                                                <tr key={i} id={type.id}>
                                                    <td><i className="fa fa-arrows-v" aria-hidden="true"></i></td>
                                                    <td><Button variant="alink" onClick={() => editCustomerType(type)}>{type.customerType}</Button></td>
                                                    <td>{type.sort}</td>
                                                    <td><DeleteButton onDelete={() => deleteCustomerType(type)}></DeleteButton></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Loader>
                        </div>
                    </Row>
                </div>
            </div>
            <AddOrEditCustomerTypeModal show={showAddCustomerTypeModal} onClose={onModalClose} itemToEdit={customerTypeToEdit}/>
        </BaseContainer>
    );
}

export default CustomerTypeList;
