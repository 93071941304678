import '../App.scss';
import ErrorComponent from '../components/ErrorComponent';
import BaseContainer from '../components/Container';
import { useContext } from 'react';
import { BaseContext } from '../helpers/common';
import { useEffect } from 'react';

function NotFound() {
    const { getFacilityName } = useContext(BaseContext);

    useEffect(() => {
        document.title = `${getFacilityName()} - Online Booking`;
    }, []);

    return (
        <BaseContainer>
            <ErrorComponent />
        </BaseContainer>
    );
}

export default NotFound;
