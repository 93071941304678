import { useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import { getPaymentExportFields, getPaymentExportNewFields } from '../../helpers/exportFields';
import BaseCSVExportModal from './BaseCSVExportModal';
import {useParams} from "react-router-dom"; 
import { BaseContext } from '../../helpers/common';

function PaymentsCSVExportModal(props) {
    let { facilityLink } = useParams();
    const { settings } = useContext(BaseContext);

    return (
        <BaseCSVExportModal
            {...props}
            fields={settings.useNewPaymentsTable ? getPaymentExportNewFields(settings) : getPaymentExportFields()}
            filenamePrefix="Payments-Report"
            cacheKey={facilityLink + "_csv_payments"}
            exportUrl={settings.useNewPaymentsTable ? '/payments/v2/export' : '/payments/export'} />
    );
}

export default PaymentsCSVExportModal;
