import { useEffect, useState, createRef, useContext, useRef } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import InvoiceAgreementSubdetailsModal from '../../components/modals/InvoiceAgreementSubdetailsModal';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import BaseContainer from '../../components/Container';
import BaseForm from '../../components/BaseForm';
import DeleteButton from '../../components/DeleteButton';
import TabHeader from '../../components/TabHeader';
import SubmitButton from '../../components/SubmitButton';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import { serverFetch, serverPost, serverPatch, serverDelete } from '../../helpers/server';
import { conciseTimeDisplay, getFormattedName, currencyFormat, BaseContext, cyrb53 } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { useParams, useSearchParams, Link, useNavigate } from "react-router-dom";
import { Table, Button, Row, Col, Alert, ButtonToolbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classnames from 'classnames';
const _ = require("lodash");
var cache = require('js-cache');
var calcCostCache = new cache();

function AgreementCreateOrUpdate() {
    const { userInfo, getApiUrl, getFacilityName, settings } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const [formRef, setFormRef] = useState(createRef());
    const calcCostKeyInProgress = useRef(null);

    const [showSubdetailsModal, setShowSubdetailsModal] = useState(false);
    const [subdetailsProps, setSubdetailsProps] = useState({});
    const [subdetails, setSubdetails] = useState({});
    const [bookingIds, setBookingIds] = useState("");
    const [loading, setLoading] = useState(true);
    const [ searchParams ] = useSearchParams();
    let groupId = searchParams.get('groupId');
    let uid = searchParams.get('uid');
    let uuid = searchParams.get('uuid');

    const [preTaxTotal, setPreTaxTotal] = useState(0);
    const [taxAmountTotal, setTaxAmountTotal] = useState(0);
    const [totalCostTotal, setTotalCostTotal] = useState(0);
    const [calcCost, setCalcCost] = useState({});

    const [groupInfo, setGroupInfo] = useState({});
    const [agreementTemplates, setAgreementTemplates] = useState([]);
    const [venues, setVenues] = useState([]);
    const [needsSorting, setNeedsSorting] = useState(false);
    const [venueCategories, setVenueCategories] = useState([]);
    const [venueCategoryAddresses, setVenueCategoryAddresses] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [numExtraItems, setNumExtraItems] = useState(0);
    const [addons, setAddons] = useState([]);
    const [agreementOlPay, setAgreementOlPay] = useState(null);
    const [initialFormFields, setInitialFormFields] = useState({});
    const isUpdate = !_.isNil(uuid);

    useEffect(() => {
        const action = isUpdate ? "Update" : "Create";
        document.title = `${initialFormFields.groupName} (${action} Agreement) - ${getFacilityName()}`;
    }, [initialFormFields]);

    useEffect(() => {
        groupId = searchParams.get('groupId')
        uid = searchParams.get('uid')
        uuid = searchParams.get('uuid')
    }, [searchParams]);

    useEffect(() => {
        if (venues.length > 0 && needsSorting) {
            setInitialFormFields((prevFields) => {
                const data = {...prevFields};
                data['items'] = sortItems(data['items']);
                return data;
            });
        }
    }, [venues, needsSorting]);

    const sortItems = (items) => {
        if (!items) {
            return;
        }
        let venueSort = settings.venueSortDefault;
        if (userInfo.preference && userInfo.preference.rinkSort) {
            venueSort = userInfo.preference.rinkSort;
        }
        const sortedVenueList = venueSort ? venueSort.split(","): [];
        const sortedVenueNames = [];
        _.each(sortedVenueList, v => {
            const ven = _.find(venues, vv => String(vv.id) === String(v))
            if (ven) {
                sortedVenueNames.push(ven.name);
            }
        })
        return _.sortBy(items, [(i) => moment(i.startTimeLocal), (i) => _.indexOf(sortedVenueNames, i.venueName)]);
    }

    const fetchBookingIdsAndUpdate = (isReplace) => {
        if (!uid) {
            setInitialFormFields((prevFields) => {
                const data = {...prevFields};
                data['items'] = sortItems(data['items'])
                return data;
            });
            setNeedsSorting(true);
            return;
        }
        let bids = localStorage.getItem(`agreement_uid_${uid}`)
        setBookingIds(bids);
        setInitialFormFields((prevFields) => {
            const data = {...prevFields};
            data['agreementOnlinePaymentOption'] = settings.agreementOlPay;
            data['agreementDate'] = moment().format("YYYY-MM-DD");
            data['dueDate'] = moment().format("YYYY-MM-DD");
            data['hideMonthlyCharges'] = true;
            const items = !_.isNil(data['items']) ? [...data['items']] : [];
            data['items'] = items;
            return data;
        });
        const bookingIdStrings = bids.split(",");
        const data = {
            'ids': bookingIdStrings
        }
        serverPost(getApiUrl(`/bookings/batch_get`), data).then((res) => {
            setInitialFormFields((prevFields) => {
                const data = {...prevFields};
                const items = [];
                _.each(res, (b) => {
                    const startTime = moment(b.startTimeLocal);
                    const endTime = moment(b.endTimeLocal);
                    const bdata = {
                        "bookingId": b.id,
                        "item": "Booking",
                        "venueName": b.venueName,
                        "description": `${startTime.format("ddd, MMM D, YYYY ")}${conciseTimeDisplay(startTime)} - ${conciseTimeDisplay(endTime)}`,
                        "eventName": b.eventName,
                        "hours": b.hours,
                        "rate": parseFloat(b.rate).toFixed(2),
                        "taxRate": `${b.taxPercentage}`,
                        "total": b.cost.toFixed(2),
                        "amount": b.preCost.toFixed(2),
                        "taxAmount": b.totalTax.toFixed(2),
                        "totalCost": b.cost.toFixed(2),
                        "startTimeLocal": b.startTimeLocal,
                        "endTimeLocal": b.endTimeLocal
                    }
                    items.unshift(bdata);
                });
                if (isReplace) {
                    data['items'] = items;
                } else {
                    data['items'] = _.concat(data['items'], items);
                }
                data['items'] = sortItems(data['items'])
                return data;
            });
            setNeedsSorting(true);
            setTimeout(() => {
                updateCalcCostIfNeeded();
            }, 100);
        });
    }

    useEffect(() => {
        if (isUpdate) {
            serverFetch(getApiUrl(`/agreements/${uuid}`)).then((res) => {
                setInitialFormFields((prevFields) => {
                    const data = {...prevFields, ...res};
                    data['groupId'] = res.group.id;
                    data['groupName'] = res.group.name;
                    data['agreementDate'] = moment(res.agreementDate).format("YYYY-MM-DD");
                    data['dueDate'] = moment(res.dueDate).format("YYYY-MM-DD");
                    data['hideMonthlyCharges'] = res.hideMonthlyCharges;
                    const items = !_.isNil(data['items']) ? [...data['items']] : [];
                    data["items"] = res.lineItems;
                    _.each(data["items"], (row) => {
                        row['taxAmount'] = row['total'] - row['amount'];
                    });
                    onSubdetailUpdated("contract", res.contract);
                    onSubdetailUpdated("opening", res.opening);
                    onSubdetailUpdated("notes", res.notes);
                    setNumExtraItems(res.lineItems.length);
                    return data;
                });
                setGroupInfo({...res.group, groupName: res.group.name});
                fetchBookingIdsAndUpdate(false);
                setTimeout(() => {
                    updateCalcCostIfNeeded();
                }, 100);
            });
        } else {
            fetchBookingIdsAndUpdate(true);

            serverFetch(getApiUrl(`/agreements/agreement_number`)).then((res) => {
                setInitialFormFields((prevFields) => {
                    const data = {...prevFields};
                    data['agreementNumber'] = res.agreementNumber;
                    return data;
                });
            });
        }
    }, [uid, uuid]);

    useEffect(() => {
        if (!isUpdate) {
            serverFetch(getApiUrl(`/user_groups/${groupId}`)).then((res) => {
                setGroupInfo(res);
                setInitialFormFields((prevFields) => {
                    const data = {...prevFields};
                    data['groupName'] = res.groupName;
                    data['groupId'] = res.id;
                    data['to'] = getFormattedName(res.user) || res.groupName;
                    return data;
                });
            });
        }
    }, [groupId]);

    useEffect(() => {
        if (!isUpdate) {
            serverFetch(getApiUrl(`/agreements/agreement_number`)).then((res) => {
                setInitialFormFields((prevFields) => {
                    const data = {...prevFields};
                    data['agreementNumber'] = res.agreementNumber;
                    return data;
                });
            });
        }
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl(`/venues`)).then((res) => {
            setVenues(res);
        });

        serverFetch(getApiUrl(`/venue_categories`)).then((res) => {
            setVenueCategories(res);
        });

        serverFetch(getApiUrl(`/agreement_templates`)).then((res) => {
            setAgreementTemplates(res);
        });

        serverFetch(getApiUrl(`/venue_categories/addresses`)).then((res) => {
            setVenueCategoryAddresses(res || []);
        });

        serverFetch(getApiUrl(`/addons`)).then((res) => {
            setAddons(res);
        });
    }, []);

    useEffect(() => {
        if (!_.isEmpty(calcCost)) {
            const formData = formRef.current.getFormData();
            const numberOfRows = _.keys(formData.items).length;
            if (numberOfRows !== calcCost.items.length) {
                return;
            }
            setInitialFormFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields.items = _.isNil(prevInitialFields.items) ? [] : [...prevInitialFields.items];
                _.each(_.range(numberOfRows), (r) => {
                    newInitialFields.items[String(r)] = {...newInitialFields.items[String(r)]}
                    newInitialFields.items[String(r)].hours = calcCost.items[r].hours;
                    newInitialFields.items[String(r)].rate = calcCost.items[r].rate;
                    newInitialFields.items[String(r)].amount = calcCost.items[r].subtotal;
                    newInitialFields.items[String(r)].taxRate = calcCost.items[r].taxRate;
                    newInitialFields.items[String(r)].total = calcCost.items[r].total;
                });
                return newInitialFields;
            })
        }
    }, [calcCost]);

    const updateCalcCostIfNeeded = () => {
        if (!formRef.current) {
            return;
        }
        const formData = formRef.current.getFormData();
        const items = formData.items;
        const itemData = _.map(items, (item) => {
            return {
                bookingId: item.bookingId,
                addonId: item.addonId,
                customRate: parseFloat(item.rate),
                customTaxRate: parseFloat(item.taxRate),
                hours: parseFloat(item.hours)
            }
        })
        const calcData = { items: itemData };
        const calcKey = cyrb53(JSON.stringify(calcData))
        if (calcCostKeyInProgress.current === calcKey) {
            // A request is already in progress with the same params so don't do anything.
            return;
        }
        calcCostKeyInProgress.current = calcKey;
        const existingValue = calcCostCache.get(calcKey);
        if (existingValue) {
            setCalcCost(existingValue);
        } else {
            serverPost(getApiUrl("/agreements/calc_cost"), calcData).then((res) => {
                if (res) {
                    if (calcCostKeyInProgress.current === calcKey) {
                        calcCostCache.set(calcKey, res, 30000);
                        setCalcCost(res);
                        calcCostKeyInProgress.current = null;
                    }
                } else {
                    calcCostKeyInProgress.current = null;
                }
            });
        }
    }

    const onSubdetailSelected = (name) => {
        let nprops = { name: name };
        if (name === "notes") {
            nprops['title'] = "Notes";
            nprops['notes'] = subdetails['notes'];
        } else if (name === "contract") {
            nprops['title'] = "Contract";
            nprops['contract'] = subdetails['contract'];
        } else if (name === "opening") {
            nprops['title'] = "Opening";
            nprops['opening'] = subdetails['opening'];
        }
        setSubdetailsProps(nprops);
        setShowSubdetailsModal(true);
    }

    const onSubdetailUpdated = (name, value) => {
        setSubdetails((prevSubdetails) => {
            prevSubdetails[name] = value;
            return prevSubdetails;
        });
    }

    const getTaxRate = () => {
        let taxRate = parseFloat(settings.tax1);
        if (groupInfo && groupInfo.cTax != null) {
            taxRate = parseFloat(groupInfo.cTax);
        }
        return taxRate;
    }

    const onAddonSelected = (addonId) => {
        const addon = _.find(addons, (a) => String(a.id) === addonId);
        const taxRate = getTaxRate();
        const newItem = {
            "addonId": addon.id,
            "item": addon.addon,
            "hours": 1,
            "rate": (addon.rate / (1 + taxRate/100)).toFixed(2),
            "taxRate": taxRate,
            "total": addon.rate,
            "amount": (addon.rate / (1 + taxRate/100)).toFixed(2),
            "totalCost": addon.rate.toFixed(2),
        };
        addRow(newItem);
    }

    const onAddNew = () => {
        let taxRate = getTaxRate();
        addRow({
            'hours': 1,
            'rate': parseFloat(0).toFixed(2),
            'amount': parseFloat(0).toFixed(2),
            'taxRate': taxRate,
            'total': parseFloat(0).toFixed(2),
        });
        setTimeout(() => {
            updateCalcCostIfNeeded();
        }, 100);
    }

    const addRow = (row) => {
        setInitialFormFields((prevFields) => {
            const data = {...prevFields};
            data["items"] = [...data["items"]] || [];
            data["items"].push(row);
            setNumExtraItems(numExtraItems + 1);
            return data;
        });
        setTimeout(() => {
            updateCalcCostIfNeeded();
        }, 100);
    }

    const deleteItem = (itemIndex) => {
        setInitialFormFields((prevFields) => {
            const data = {...prevFields};
            let items = [...data["items"]]
            items.splice(itemIndex, 1);
            data["items"] = items;
            setNumExtraItems(numExtraItems - 1);
            return data;
        });
        setTimeout(() => {
            updateCalcCostIfNeeded();
        }, 100);
    }

    const onFieldChange = (name, value) => {
        if (name === "agreementOnlinePaymentOption") {
            setAgreementOlPay(value);
            setInitialFormFields((prevFields) => {
                const data = {...prevFields};
                data["agreementOnlinePaymentOption"] = value;
                return data;
            });
        } else if (_.includes(["to", "categoryId", "groupName", "agreementDate", "agreementNumber",
            "hideMonthlyCharges", "agreementMin", "agreementMinDollar"], name)) {
            setInitialFormFields((prevFields) => {
                const data = {...prevFields};
                data[name] = value;
                return data;
            });
        } else if (name === "templateId") {
            const template = _.find(agreementTemplates, (t) => String(t.id) === String(value));
            onSubdetailUpdated("contract", template.contract);
            onSubdetailUpdated("opening", template.opening);
            setInitialFormFields((prevFields) => {
                const data = {...prevFields};
                data[name] = value;
                return data;
            });
        } else if (name.endsWith("rate") || name.endsWith("hours") || name.endsWith("taxRate") ||
            name.endsWith("total") || name.endsWith("amount")) {
            setTimeout(() => {
                updateCalcCostIfNeeded();
            }, 100);
        }
    }

    const onCreate = (data) => {
        const agreementData = {...data, ...subdetails};
        agreementData['groupId'] = initialFormFields['groupId'];
        agreementData['taxLabel'] = initialFormFields['taxLabel'];
        const items = _.values(data['items']);
        agreementData['items'] = items;
        agreementData['taxLabel'] = settings.taxLabel1;
        agreementData['subTotal'] = calcCost.subtotal;
        agreementData['totalTax'] = calcCost.taxAmount;
        agreementData['total'] = calcCost.total;
        agreementData['amountDue'] = calcCost.total;

        if (isUpdate) {
            serverPatch(getApiUrl(`/agreements/${uuid}`), agreementData).then((res) => {
                if (res) {
                    window.location = `/${facilityLink}/agreement/accept?uuid=${uuid}`;
                }
            })
        } else {
            serverPost(getApiUrl(`/agreements`), agreementData).then((res) => {
                if (res) {
                    localStorage.removeItem(`agreement_uid_${uid}`);
                    window.location = `/${facilityLink}/agreement/accept?uuid=${res.uuid}`;
                }
            })
        }
    }

    const isItemDisabled = (item) => {
        return !_.isNil(item.bookingId) && item.bookingId > 0;
    }

    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        groupId: initialFormFields.groupId,
        userGroup: groupInfo,
        settings: settings
    };
    const tabs = getTabItems(t, facilityLink, "invoices", tabsParams);
    if (tabs[2]) {
        tabs[2].active = true;
    }

    let venueAddresses = venueCategoryAddresses;
    if (!_.isNil(venueAddresses)) {
        venueAddresses.unshift({ 'id': 0, 'companyName': "Default Address" });
    }

    const allowPaymentOptions = [
        { value: 'no', label: 'No' },
        { value: 'yes', label: 'Yes' },
        { value: 'yesPartial', label: 'Yes - Allow Partial' },
    ];

    return (
        <BaseContainer>
            <TabHeader items={tabs}/>
            <BaseForm initialFormFields={initialFormFields} onFieldChange={onFieldChange} onSubmit={onCreate} ref={formRef} >
                <div className="content-box">
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan="4" type="select" name="addressId" label="From Address" options={venueAddresses}
                                idField="id" labelField="companyName" showSearch={false} disabled={isUpdate}/>
                            {
                                !_.isEmpty(agreementTemplates) && !isUpdate &&
                                    <BaseForm.Input colSpan="4" type="select" name="templateId" label="Templates" options={agreementTemplates}
                                        idField="id" labelField="name" showSearch={false} />
                            }
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="3" type="text" name="to" label="To (Contact Name)" required />
                            <BaseForm.Input colSpan="3" type="text" name="groupName" label="Group Name" required/>
                            <BaseForm.Input colSpan="3" type="date" name="agreementDate" label="Agreement Date" rightContent={<i className="fa fa-calendar"/>} required/>
                            <BaseForm.Input colSpan="3" type="text" name="agreementNumber" label="Agreement #" required/>
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="3" type="date" name="dueDateLocal" label="Due Date (optional)" rightContent={<i className="fa fa-calendar"/>}/>
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-body">
                        <Table hover>
                            <thead>
                                <tr>
                                    <th width="10%">{t('common.item')}</th>
                                    <th width="11%">{t('common.venue')}</th>
                                    <th width="18%">{t('common.description')}</th>
                                    <th width="12%">{t('common.event')}<span> </span>
                                        <BaseOverlayTrigger content="To show Event Names on invoices select the option under Settings - INV & AGR" >
                                            <i className="fa fa-question-circle"/>
                                        </BaseOverlayTrigger>
                                    </th>
                                    <th width="7%" className="wrappable">Hours / Count</th>
                                    <th width="10%">{t('common.rate')}</th>
                                    <th width="10%">{t('common.subtotal')}</th>
                                    <th width="7%">{t('common.tax_rate')}</th>
                                    <th width="10%">{t('common.total')}</th>
                                    <th width="5%"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                _.map(initialFormFields["items"], (item, j) =>
                                    <tr key={j}>
                                        <td width="10%">
                                            <BaseForm.Hidden name={`items.${j}.bookingId`} />
                                            <BaseForm.Hidden name={`items.${j}.addonId`} />
                                            <BaseForm.TextGroup name={`items.${j}.item`} label="Item" hideLabel required />
                                        </td>
                                        <td width="11%"><BaseForm.TextGroup name={`items.${j}.venueName`} /></td>
                                        <td width="18%"><BaseForm.TextGroup name={`items.${j}.description`} label="Description" hideLabel /></td>
                                        <td width="12%"><BaseForm.TextGroup name={`items.${j}.eventName`} /></td>
                                        <td width="7%"><BaseForm.NumberGroup name={`items.${j}.hours`} label="Hours/Count" hideLabel disabled={isItemDisabled(item)} required min="0" step="0.01"/></td>
                                        <td width="10%"><BaseForm.NumberGroup name={`items.${j}.rate`} label="Rate" hideLabel disabled={isItemDisabled(item)} required step="0.01"/></td>
                                        <td width="10%"><BaseForm.NumberGroup name={`items.${j}.amount`} label="Amount" hideLabel disabled={true} required step="0.01" /></td>
                                        <td width="7%"><BaseForm.NumberGroup name={`items.${j}.taxRate`} label="Tax Rate" hideLabel disabled={isItemDisabled(item)} required min="0" step="0.01"/></td>
                                        <td width="10%"><BaseForm.NumberGroup name={`items.${j}.total`} label="Total" hideLabel disabled={true} required  step="0.01"/></td>
                                        <td width="5%"><Button variant="outline-primary" onClick={() => deleteItem(j)}><i className="fa fa-x"/></Button></td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                        <Table borderless hover>
                            <tbody>
                                <tr>
                                    <td width="10%"><Button variant="outline-primary" onClick={onAddNew}><i className="fa fa-circle-plus"/> Add New</Button></td>
                                    <td width="10%">
                                        <SingleSelectDropdown toggleLabel="Select Addon" items={addons} onSelect={onAddonSelected} labelField={"addon"} menuOnly/>
                                    </td>
                                    <td width="25%"></td>
                                    <td width="40%" className="text-end align-bottom">Subtotal</td>
                                    <td width="10%" className="text-end align-bottom">{ currencyFormat(calcCost.subtotal) }</td>
                                    <td width="5%" className="text-end"></td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td width="10%"></td>
                                    <td width="25%"></td>
                                    <td width="40%" className="text-end">Total Tax</td>
                                    <td width="10%" className="text-end">{ currencyFormat(calcCost.taxAmount) }</td>
                                    <td width="5%" className="text-end"></td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td width="10%"></td>
                                    <td width="25%"></td>
                                    <td width="40%" className="text-end upline"><strong>Total</strong></td>
                                    <td width="10%" className="text-end upline"><strong>{ currencyFormat(calcCost.total) }</strong></td>
                                    <td width="5%" className="text-end"></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan="3" type="select" name="agreementOnlinePaymentOption" label="Payment Options" options={allowPaymentOptions}
                                idField="value" showSearch={false} />
                            {
                                (agreementOlPay === "yesMinPercent") &&
                                    <BaseForm.Input colSpan="3" type="number" name="agreementMin" label="Minimum Percentage" rightContent="%" validations={{min:0}}/>
                            }
                            {
                                (agreementOlPay === "yesMinDollar") &&
                                    <BaseForm.Input colSpan="3" type="number" name="agreementMinDollar" label="Minimum Dollar" leftContent="$" validations={{min:0}}/>
                            }
                            <BaseForm.Input colSpan="3" type="check" name="hideMonthlyCharges" label="Hide Monthly Charges?" />
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-body">
                        <Row>
                            <Col md="4" className="d-flex align-items-center">
                                <Button variant="success" onClick={() => onSubdetailSelected("contract")}>Contract</Button>
                                <Button variant="success" onClick={() => onSubdetailSelected("opening")}>Opening</Button>
                                <Button variant="success" onClick={() => onSubdetailSelected("notes")}>Notes</Button>
                            </Col>
                            <Col md="8" className="text-end">
                                <ButtonToolbar>
                                    <Link to={`/${facilityLink}/agreement/list?groupId=${initialFormFields.groupId}`}><Button variant="link">Cancel</Button></Link>
                                    <SubmitButton variant="primary">{ isUpdate ? "Update" : "Create" }</SubmitButton>
                                </ButtonToolbar>
                            </Col>
                        </Row>
                    </div>
                </div>
            </BaseForm>
            <InvoiceAgreementSubdetailsModal show={showSubdetailsModal} onClose={setShowSubdetailsModal} onUpdate={onSubdetailUpdated} {...subdetailsProps} />
        </BaseContainer>
    );
}

export default AgreementCreateOrUpdate;
