import '../App.scss';
import { useEffect, useState } from 'react';
import { Button, Popover, OverlayTrigger, Form } from 'react-bootstrap';
import $ from 'jquery';
import {useTranslation} from "react-i18next";
const _ = require("lodash");

function ConfirmationButton(props) {
    const { t } = useTranslation('common');
    const [confirmationText, setConfirmationText] = useState("");
    const [confirmationTextMatches, setConfirmationTextMatches] = useState(false);

    const onConfirmationTextChange = (value) => {
        setConfirmationText(value.toUpperCase());
    }

    useEffect(() => {
        if (!props.confirmationText || _.isNil(props.confirmationText)) {
            setConfirmationTextMatches(true);
        } else {
            setConfirmationTextMatches(props.confirmationText.toUpperCase() === confirmationText);
        }
    }, [confirmationText, props.confirmationText]);

    const onConfirm = () => {
        $('body').trigger('click');
        setConfirmationText("");
        if (props.onConfirm) {
            props.onConfirm();
        }
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">{props.title ? props.title : t('common.confirm_question')}</Popover.Header>
            <Popover.Body>
                {props.body ? props.body : t('confirmation_modal.default_body')}
                {
                    props.confirmationText &&
                        <>
                            <br/><br/>
                            {
                                props.confirmationBody ?
                                    <p><i>{props.confirmationBody}</i></p>
                                : <p><i>{t('confirmation_modal.default_confirmation_body', { confirmationText: props.confirmationText.toUpperCase() })}</i></p>
                            }
                            <Form.Control type="text" name="confirmationText" value={confirmationText} onChange={(event) => onConfirmationTextChange(event.target.value)} />
                        </>
                }
                <p className="actions">
                    <Button variant="danger" size="sm" onClick={onConfirm} disabled={!confirmationTextMatches}>{t('common.confirm')}</Button>
                </p>
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger rootClose trigger="click" placement="auto" overlay={popover}>
            <Button variant={props.variant} size={props.size} disabled={props.disabled}>{props.children}</Button>
        </OverlayTrigger>
    );
}

export default ConfirmationButton;
