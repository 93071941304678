import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import React, { useEffect, useState, useRef, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import BaseContainer from '../../components/Container';
import Notification from '../../components/Notification';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import PaymentsCSVExportModal from '../../components/modals/PaymentsCSVExportModal';
import PaymentsPDFExportModal from '../../components/modals/PaymentsPDFExportModal';
import { getUrl, serverFetch, serverPost } from '../../helpers/server';
import { getLimitOptions, dateRangeClassCheck, BaseContext, currencyFormat, getDateFormatForFacility } from '../../helpers/common';
import { useParams, useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, InputGroup, Form, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import classnames from 'classnames';
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmationButton from "../../components/ConfirmationButton";
import BaseOverlayTrigger from "../../components/BaseOverlayTrigger";
const _ = require("lodash");

function List() {
    const { isLoggedIn, getApiUrl, getFacilityName, settings, userInfo } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const [hasMore, setHasMore] = useState(true);
    const [ searchParams ] = useSearchParams();
    let groupId = searchParams.get('groupId');

    const [showCSVExportModal, setShowCSVExportModal] = useState("");
    const [showPDFExportModal, setShowPDFExportModal] = useState("");
    const [searchAllQuery, setSearchAllQuery] = useState("");
    const [query, setQuery] = useState("");
    const [venues, setVenues] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [addons, setAddons] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [appliedToFilter, setAppliedToFilter] = useState(null);
    const [venueFilter, setVenueFilter] = useState(null);
    const [itemFilter, setItemFilter] = useState(null);
    const [accountFilter, setAccountFilter] = useState(null);
    const [sentFilter, setSentFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [sortFilter, setSortFilter] = useState("paidDateDesc");
    const [paymentMethodFilter, setPaymentMethodFilter] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [limit, setLimit] = useState(20);

    const [userGroup, setUserGroup] = useState({});
    const [payments, setPayments] = useState([]);
    const [checkedItemIds, setCheckedItemIds] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);

    const startDateRef = useRef();
    startDateRef.current = startDate;
    const endDateRef = useRef();
    endDateRef.current = endDate;
    const queryTimer = useRef(null);

    useEffect(() => {
        document.title = "Payment List - " + getFacilityName();
    }, []);

    useEffect(() => {
        groupId = searchParams.get('groupId')
        onSearch();
    }, [searchParams])

    useEffect(() => {
        serverFetch(getApiUrl('/venues')).then((res) => {
            setVenues(res);
        })

        serverFetch(getApiUrl('/accounts')).then((res) => {
            setAccounts(res);
        })

        serverFetch(getApiUrl('/addons')).then((res) => {
            setAddons(res);
        })

        serverFetch(getApiUrl('/payment_methods')).then((res) => {
            setPaymentMethods(_.filter(res, (r) => r.isPayment));
        })
    }, []);

    useEffect(() => {
        if (groupId) {
            serverFetch(getApiUrl(`/user_groups/${groupId}`)).then((res) => {
                setUserGroup(res);
            })
        }
    }, [groupId]);

    useEffect(() => {
        // Load the options from cache
        const optionsString = localStorage.getItem(facilityLink + "_filter_cache_payments");
        if (optionsString && !groupId) {
            const options = JSON.parse(optionsString);
            if (options.paymentDateFilter) {
                setDateFilter(options.paymentDateFilter.filterType);
                setStartDate(options.paymentDateFilter.startDate);
                setEndDate(options.paymentDateFilter.endDate);
            }
            if (options.sentFilter) {
                setSentFilter(options.sentFilter)
            }
            if (options.itemFilter) {
                setItemFilter(options.itemFilter)
            }
            if (options.accountFilter) {
                setAccountFilter(options.accountFilter)
            }
            if (options.startDate) {
                setEndDate(options.startDate)
            }
            if (options.endDate) {
                setEndDate(options.endDate)
            }
            if (options.paymentMethodFilter) {
                setPaymentMethodFilter(options.paymentMethodFilter)
            }
            if (options.applytoFilter) {
                setAppliedToFilter(options.applytoFilter);
            }
            if (options.sort) {
                setSortFilter(options.sort);
            }
        }
    }, []);

    useEffect(() => {
        onSearch();
    }, [dateFilter, limit, query, accountFilter, appliedToFilter, itemFilter, sortFilter, paymentMethodFilter]);

    useEffect(() => {
        clearTimeout(queryTimer.current);
        queryTimer.current = setTimeout((callback, kk, vv) => {
                if (callback) {
                    callback(kk, vv);
                }
                queryTimer.current = null;
            },
            400,
            onSearch,
        );
    }, [searchAllQuery]);

    useEffect(() => {
        setTimeout(() => {
            $('#dateStart').datepicker( "destroy" );
            $('#dateStart').datepicker({
                defaultDate: 0,
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setStartDate(value);
                    setTimeout(() => $('#dateEnd').datepicker( "show" ), 100);
                }
            });
            $('#dateEnd').datepicker( "destroy" );
            $('#dateEnd').datepicker({
                defaultDate: "+1m",
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setEndDate(value)
                }
            });
        }, 100);
    }, [dateFilter, startDate, endDate]);

    useEffect(() => {
        setCheckedItems(_.filter(payments, (b) => checkedItemIds.includes(getRowId(b))));
    }, [checkedItemIds]);

    const onKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    }

    const getQueryParams = () => {
        let params = {
            groupName: query,
            paymentDateFilter: dateFilter && {
                filterType: dateFilter,
                startDate: startDate,
                endDate: endDate
            },
            groupId: groupId,
            paymentMethodFilter: paymentMethodFilter,
            appliedToFilter: appliedToFilter,
            searchAllQuery: searchAllQuery,
            sort: sortFilter
        }

        if (!settings.useNewPaymentsTable) {
            params.accountFilter = accountFilter;
            params.itemFilter = itemFilter;
        }

        return params;
    }

    const onSearch = (restart = true) => {
        if (dateFilter === "dateRange" && (_.isNil(startDate) || _.isNil(endDate))) {
            return;
        }
        if (!groupId) {
            // Cache the options
            localStorage.setItem(facilityLink + "_filter_cache_payments", JSON.stringify(getQueryParams()));
        }

        let searchUrl;
        if (!settings.useNewPaymentsTable) {
            searchUrl = getApiUrl('/payments/search');
        } else {
            searchUrl = getApiUrl('/payments/v2/search');
        }
        
        const data = {
            pagination: {
                from: restart ? 0 : (payments.length || 0),
                limit: limit,
            },
            query: getQueryParams()
        };
        if (restart) {
            setPayments([]);
        }
        serverPost(searchUrl, data).then((res) => {
            if (res) {
                setHasMore(res.length >= limit);
                if (restart) {
                    setPayments(res);
                } else {
                    setPayments(_.concat(payments, res))
                }
            } else {
                setHasMore(false)
            }
        })
    }

    const updateChecked = (event) => {
        if (event.target.value === "checkall") {
            let newCheckedItems = [];
            if (event.target.checked) {
                newCheckedItems = _.map(payments, (b, i) => getRowId(b));
                newCheckedItems.push("checkall");
            }
            setCheckedItemIds(newCheckedItems);
        } else {
            const newCheckedItems = [...checkedItemIds];
            if (event.target.checked) {
                newCheckedItems.push(event.target.value);
            } else {
                let index = newCheckedItems.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
                index = newCheckedItems.indexOf("checkall");
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
            }
            setCheckedItemIds(newCheckedItems);
        }
    }

    const onReportSelected = (type) => {
        if (checkedItems.length === 0) {
            Notification.Show(t('payment.list.error_choose_one'));
        } else {
            if (type === "csv") {
                setShowCSVExportModal(true);
            } else if (type === "pdf") {
                setShowPDFExportModal(true);
            }
        }
    }

    function getRowId(payment) {
        return ("" + payment.id);
    }

    const refundPayment = (payment) => {
        const refundUrl = getApiUrl(`/payments/refund`);

        const refundParams = {
            bookingId: payment.bookingData.bookingId,
            paymentSourceTransactionId: payment.paymentTransactionId,
            transactionId: payment.id,
        }
        serverPost(refundUrl, refundParams).then((res) => {
            if (res) {
                console.log("Refund successful");
                Notification.Show(t("payment.list.refund_successful"));
                onSearch();
            } else {
                Notification.Show(t("payment.list.error_refund"));
            }
        })
    }

    const dateFilterOptions = [
        { 'id': "today", 'label': t("common.today") },
        { 'id': "yesterday", 'label': t("common.yesterday") },
        { 'id': "thisWeek", 'label': t("common.this_week") },
        { 'id': "lastWeek", 'label': t("common.last_week") },
        { 'id': "thisMonth", 'label': t("common.this_month") },
        { 'id': "lastMonth", 'label': t("common.last_month") },
        { 'id': "dateRange", 'label': t("common.date_range") },
    ];

    const sentFilterOptions = [
        { 'id': "sent", 'label': t("common.yes") },
        { 'id': "unsent", 'label': t("common.no") },
    ]

    const appliedToFilterOptions = [
        { 'id': "booking", 'label': t("payment.list.book_num") },
        { 'id': "invoice", 'label': t("payment.list.inv_num") },
        settings.useNewPaymentsTable && settings.allowClasses && { 'id': "class", 'label': t("payment.list.class_num") },
        settings.useNewPaymentsTable && { 'id': "agreement", 'label': t("payment.list.agr_num") },
    ]

    const reportOptions = [
        { 'id': "csv", 'label': t("booking.list.csv_report"), 'icon': 'fa-list' },
    ];

    const sortOptions = [
        { 'id': "paidDateAsc", 'label': t("payment.list.paid_date_asc") },
        { 'id': "paidDateDesc", 'label': t("payment.list.paid_date_desc") },
        { 'id': "amountAsc", 'label': t("payment.list.amount_asc") },
        { 'id': "amountDesc", 'label': t("payment.list.amount_desc") },
    ]

    const paymentMethodFilterOptions = _.map(paymentMethods, (m) => { return { 'id': m.paymentTag, 'label': _.replace(m.paymentType, "Paid ", ""), 'icon': m.paymentIcon }; });
    const venueOptions = _.map(venues, (venue, i) => { return { 'value': venue.id, 'label': venue.name } });
    const accountOptions = _.map(accounts, (account, i) => { return { 'value': account.id, 'label': account.account } });
    const itemFilterOptions = _.map(addons, (addon, i) => { return { 'id': addon.id, 'label': addon.addon } });
    itemFilterOptions.unshift({ 'id': "-1", 'label': 'Bookings' });

    const dateFormat = getDateFormatForFacility(settings);
    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        groupId: groupId,
        userGroup: userGroup,
        settings: settings,
        userInfo: userInfo,
    };

    const getNewPaymentTable = () => {
        const hasFeesCol = _.some(payments, (p) => p.fees && p.fees !== 0);

        return <Table hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('common.group_name')}</th>
                            <th>{t('payment.list.payment_date')}</th>
                            <th className="text-end">{t('common.amount')}</th>
                            {
                                hasFeesCol && <th className="text-end">{settings.creditCardFeeLabel || t('payment.list.fees')}</th>
                            }
                            <th></th>
                            <th>{t('payment.list.applied_to')}</th>
                            <th>{t('invoice_payment.list.payment_type')}</th>
                        </tr>
                        <tr>
                            <th className="controls no-stretch">
                                <div className="checkbox check-success inline">
                                    <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedItemIds.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                                    <label htmlFor="checkall"/>
                                </div>
                            </th>
                            <th className="controls"><Form.Control type="text" name="query" value={query} onChange={(event) => { setQuery(event.target.value) }}/></th>
                            <th className="controls">
                                <SingleSelectDropdown items={dateFilterOptions} selectedId={dateFilter} onSelect={setDateFilter} showAll />
                                {
                                    dateFilter === "dateRange" &&
                                        <BaseForm initialFormFields={{ dateStart: startDate, dateEnd: endDate }}>
                                            <BaseForm.Control type="text" name="dateStart" id={"dateStart"} />
                                            <BaseForm.Control type="text" name="dateEnd" id={"dateEnd"} />
                                            <Button variant="outline-primary" onClick={(event) => onSearch()}><i className="fa fa-refresh fa-small"/></Button>
                                        </BaseForm>
                                }
                            </th>
                            <th className="controls"></th>
                            {
                                hasFeesCol && <th className="controls"></th>
                            }
                            <th className="controls"></th>
                            <th className="controls"><SingleSelectDropdown items={appliedToFilterOptions} selectedId={appliedToFilter} onSelect={setAppliedToFilter} align={"end"} showAll /></th>
                            <th className="controls"><SingleSelectDropdown items={paymentMethodFilterOptions} selectedId={paymentMethodFilter} onSelect={setPaymentMethodFilter} align={"end"} showAll /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            _.map(payments, (payment, i) =>
                                <tr key={i}>
                                    <td className="no-stretch">
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value={getRowId(payment)} id={getRowId(payment)} name="delete[]"  checked={checkedItemIds.includes(getRowId(payment))} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor={getRowId(payment)}/>
                                        </div>
                                    </td>
                                    <td><Link to={`/${facilityLink}/payment/list?groupId=${payment.groupId}`}>{ payment.groupName }</Link></td>
                                    <td><Link to={`/${facilityLink}/index?lid=${payment.bid}`}>{ moment(payment.paymentDateLocal).format(dateFormat) }</Link></td>
                                    <td className="text-end"><span className="label label-success">{ currencyFormat(payment.paymentAmount) }</span></td>
                                    {
                                        hasFeesCol && (
                                            payment.fees ?
                                                <td className="text-end"><span className="label label-success">{ currencyFormat(payment.fees) }</span></td>
                                                : <td className="text-end"><span className="label label-success">{ currencyFormat(0) }</span></td>
                                        )
                                    }
                                    <td className="text-center">

                                        <i className={classnames("fa", "fa-check-circle", payment.isAddedByAdmin ? "fa-c-success": "fa-c-primary", "fs-16")}></i>
                                        { payment.bookingData.isRepeat ? <span> <i className="fa fa-retweet"/></span> : <span/> }
                                    </td>
                                    <td className="text-end">
                                        <div className="d-flex flex-row gap-2 justify-content-end align-items-center">
                                            {
                                                payment.note &&
                                                <BaseOverlayTrigger content={payment.note}>
                                                    <i className="fa fa-comment gray2"/>
                                                </BaseOverlayTrigger>
                                            }
                                            <span className="label">
                                            {
                                                (payment.invoiceData && payment.invoiceData.invoiceNumber) ?
                                                    <Link to={`/${facilityLink}/invoice/view?uuid=${payment.invoiceData.invoiceUUID}`}>{`${payment.invoiceData.invoiceNumber}`}</Link>
                                                : (
                                                    (payment.bookingData && payment.bookingData.bookingId) ?
                                                    <Link to={`/${facilityLink}/index?lid=${payment.bookingData.bookingId}`}>{`#${payment.bookingData.bookingId}`}</Link>
                                                    : ((payment.classData && payment.classData.classId) ?
                                                    <Link to={`/${facilityLink}/class/classes-list-participants?id=${payment.classData.classId}`}>{`${payment.classData.className}`}</Link>
                                                    : ((payment.agreementData && payment.agreementData.agreementNumber) ?
                                                        <Link to={`/${facilityLink}/agreement/accept?uuid=${payment.agreementData.agreementUUID}`}>{`#${payment.agreementData.agreementNumber}`}</Link>
                                                        : <span>N/A</span>
                                                    )
                                                    )
                                                )
                                            }
                                            </span>
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        <span className="label">{ _.replace(payment.paymentMethod, "Paid ", "") }</span>
                                    </td>
                                </tr>
                            )
                        }
                        {
                            hasMore ?
                                <tr>
                                    <td colSpan={11} className="center">
                                        <div className="spinner-border text-secondary"/>
                                    </td>
                                </tr>
                            : <tr>
                                    <td colSpan={11} className="center">
                                        <div className="label">{t('payment.list.end_of_payments')}</div>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </Table>
    }

    const getOldPaymentTable = () => {
        return <Table hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('common.group_name')}</th>
                            <th>{t('payment.list.account')}</th>
                            <th>{t('payment.list.item')}</th>
                            <th>{t('payment.list.payment_date')}</th>
                            <th>{t('common.amount')}</th>
                            <th></th>
                            <th>{t('payment.list.applied_to')}</th>
                            <th>{t('invoice_payment.list.payment_type')}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th className="controls no-stretch">
                                <div className="checkbox check-success inline">
                                    <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedItemIds.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                                    <label htmlFor="checkall"/>
                                </div>
                            </th>
                            <th className="controls"><Form.Control type="text" name="query" value={query} onChange={(event) => { setQuery(event.target.value) }}/></th>
                            <th className="controls"><SingleSelectDropdown items={accountOptions} selectedId={accountFilter} onSelect={setAccountFilter} align={"end"} showAll idField={"value"} /></th>
                            <th className="controls"><SingleSelectDropdown items={itemFilterOptions} selectedId={itemFilter} onSelect={setItemFilter} align={"end"} showAll /></th>
                            <th className="controls">
                                <SingleSelectDropdown items={dateFilterOptions} selectedId={dateFilter} onSelect={setDateFilter} showAll />
                                {
                                    dateFilter === "dateRange" &&
                                        <BaseForm initialFormFields={{ dateStart: startDate, dateEnd: endDate }}>
                                            <BaseForm.Control type="text" name="dateStart" id={"dateStart"} />
                                            <BaseForm.Control type="text" name="dateEnd" id={"dateEnd"} />
                                            <Button variant="outline-primary" onClick={(event) => onSearch()}><i className="fa fa-refresh fa-small"/></Button>
                                        </BaseForm>
                                }
                            </th>
                            <th className="controls"></th>
                            <th className="controls"></th>
                            <th className="controls"><SingleSelectDropdown items={appliedToFilterOptions} selectedId={appliedToFilter} onSelect={setAppliedToFilter} align={"end"} showAll /></th>
                            <th className="controls"><SingleSelectDropdown items={paymentMethodFilterOptions} selectedId={paymentMethodFilter} onSelect={setPaymentMethodFilter} align={"end"} showAll /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            _.map(payments, (payment, i) =>
                                <tr key={i}>
                                    <td className="no-stretch">
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value={getRowId(payment)} id={getRowId(payment)} name="delete[]"  checked={checkedItemIds.includes(getRowId(payment))} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor={getRowId(payment)}/>
                                        </div>
                                    </td>
                                    <td><Link to={`/${facilityLink}/payment/list?groupId=${payment.groupId}`}>{ payment.groupName }</Link></td>
                                    <td>{ payment.account }</td>
                                    <td>{ payment.item }</td>
                                    <td><Link to={`/${facilityLink}/index?lid=${payment.bid}`}>{ moment(payment.paymentDate).format(dateFormat) }</Link></td>
                                    <td className="text-end"><span className="label label-success">{ currencyFormat(payment.bookingCost) }</span></td>
                                    <td className="text-center">
                                        <i className={classnames("fa", "fa-check-circle", payment.isAddedByAdmin ? "fa-c-success": "fa-c-primary", "fs-16")}></i>
                                        { payment.isRepeat ? <span> <i className="fa fa-retweet"/></span> : <span/> }
                                    </td>
                                    <td className="text-end">
                                        <span className="label">
                                        {
                                            payment.invoiceNumber ?
                                                <Link to={`/${facilityLink}/invoice/view?uuid=${payment.invoiceUUID}`}>{`${payment.invoiceNumber}`}</Link>
                                            : (
                                                payment.bookingId ? 
                                                <Link to={`/${facilityLink}/index?lid=${payment.bookingId}`}>{`#${payment.bookingId}`}</Link>
                                                : <Link to={`/${facilityLink}/class/classes-list-participants?id=${payment.classId}`}>{`#${payment.classId}`}</Link>
                                            )
                                        }
                                        </span>
                                    </td>
                                    <td className="text-end">
                                        <span className="label">{ _.replace(payment.paymentMethod, "Paid ", "") }</span>
                                    </td>
                                </tr>
                            )
                        }
                        {
                            hasMore ?
                                <tr>
                                    <td colSpan={11} className="center">
                                        <div className="spinner-border text-secondary"/>
                                    </td>
                                </tr>
                            : <tr>
                                    <td colSpan={11} className="center">
                                        <div className="label">{t('payment.list.end_of_payments')}</div>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </Table>
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "payments", tabsParams)} />
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className={settings.useNewPaymentsTable ? "col-md-6" : "col-md-2"}>
                            <InputGroup>
                                <Form.Control type="text" className="inline" name="searchAllQuery" value={searchAllQuery} onChange={(event) => { setSearchAllQuery(event.target.value) }} onKeyPress={onKeyPress}/>
                                <InputGroup.Text className="skinny" onClick={() => onSearch()}><i className="fa fa-search"/></InputGroup.Text>
                            </InputGroup>
                        </div>
                        {
                            !settings.useNewPaymentsTable && <div className="col-md-4">
                                <SingleSelectDropdown className="inline" items={venueOptions} selectedId={venueFilter} onSelect={setVenueFilter} idField={"value"} showAll />
                            </div>
                        }
                        <div className="col-md-6">
                            <div className="float-end d-flex align-items-center">
                                <SingleSelectDropdown className="inline" items={getLimitOptions()} selectedId={limit} onSelect={setLimit} menuOnly/>
                                <SingleSelectDropdown className="inline" toggleLabel={<span><i className="fa fa-small fa-circle-plus"/> {t("common.new_report")}</span>} items={reportOptions} onSelect={onReportSelected} align="end" menuOnly/>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="9" className="text-end d-grid align-items-center">
                            <span><strong>{t("common.sort_order")}:</strong></span>
                        </Col>
                        <Col md="3">
                            <SingleSelectDropdown name="sort" selectedId={sortFilter} items={sortOptions} onSelect={setSortFilter} />
                        </Col>
                    </Row>
                    <InfiniteScroll
                        dataLength={payments.length}
                        next={() => onSearch(false)}
                        hasMore={hasMore}
                        scrollableTarget="content_wrapper"
                    >
                        {settings.useNewPaymentsTable ? getNewPaymentTable() : getOldPaymentTable()}
                    </InfiniteScroll>
                </div>
            </div>
            <PaymentsCSVExportModal
                show={showCSVExportModal}
                onClose={setShowCSVExportModal}
                items={checkedItems}
                criteria={getQueryParams()}
                useCriteria={checkedItemIds.includes("checkall")} />
        </BaseContainer>
    );
}

export default List;
