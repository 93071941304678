import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import React, { useEffect, useState, useRef, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import classnames from 'classnames';
import BaseContainer from '../../components/Container';
import Notification from '../../components/Notification';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';
import ConfirmationButton from '../../components/ConfirmationButton';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import { serverFetch, serverPost, serverDelete } from '../../helpers/server';
import { hasAccess, BaseContext, currencyFormat } from '../../helpers/common';
import { timeOptions } from '../../helpers/input';
import { getLimitOptions, dateRangeClassCheck, getDateFormatForFacility } from '../../helpers/common';
import { useParams, useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, Form, Button, Row, ButtonToolbar, Col, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
const _ = require("lodash");

function List() {
    const { userInfo, getApiUrl, getFacilityName, settings } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    const [hasMore, setHasMore] = useState(true);
    const [ searchParams ] = useSearchParams();
    let groupId = searchParams.get('groupId');

    const [searchAllQuery, setSearchAllQuery] = useState("");
    const [query, setQuery] = useState("");
    const [statusFilter, setStatusFilter] = useState("active");
    const [capacityFilter, setCapacityFilter] = useState("all");
    const [priceFilter, setPriceFilter] = useState("all");
    const [dateFilter, setDateFilter] = useState(null);
    const [timeFilter, setTimeFilter] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [eventFilter, setEventFilter] = useState(null);
    const [venueFilter, setVenueFilter] = useState(null);
    const [sortFilter, setSortFilter] = useState("startTimeAsc");
    const [limit, setLimit] = useState(20);

    const [userGroup, setUserGroup] = useState({});
    const [classes, setClasses] = useState([]);
    const [venues, setVenues] = useState([]);
    const [loadingVenues, setLoadingVenues] = useState(true);
    const [eventTypes, setEventTypes] = useState([]);
    const [loadingEventTypes, setLoadingEventTypes] = useState(true);
    const [checkedItemIds, setCheckedItemIds] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);

    const startDateRef = useRef();
    startDateRef.current = startDate;
    const endDateRef = useRef();
    endDateRef.current = endDate;

    if (!settings.allowClasses) {
        console.log("Allow classes is false");
        navigate('/' + facilityLink);
    }

    useEffect(() => {
        document.title = "Classes - " + getFacilityName();
    }, []);

    useEffect(() => {
        if (groupId) {
            serverFetch(getApiUrl(`/user_groups/${groupId}`)).then((res) => {
                setUserGroup(res);
            })
        }
    }, [groupId]);

    useEffect(() => {
        groupId = searchParams.get('groupId')
        onSearch(true);
    }, [searchParams])

    useEffect(() => {
        // Load the options from cache
        const optionsString = localStorage.getItem(facilityLink + "_filter_cache_classes");
        if (optionsString && !groupId) {
            const options = JSON.parse(optionsString);
            if (options.dateFilter) {
                setDateFilter(options.dateFilter.filterType);
                setStartDate(options.dateFilter.startDate);
                setEndDate(options.dateFilter.endDate);
            }
            if (options.timeFilter) {
                setTimeFilter(options.timeFilter.filterType);
                setStartTime(options.timeFilter.startTime);
                setEndTime(options.timeFilter.endTime);
            }
            if (options.status) {
                setStatusFilter(options.status)
            }
            if (options.groupName) {
                setQuery(options.groupName)
            }
            if (options.venueFilter) {
                setVenueFilter(options.venueFilter)
            }
            if (options.eventFilter) {
                setEventFilter(options.eventFilter)
            }
            if (options.capacity) {
                setCapacityFilter(options.capacity)
            }
            if (options.price) {
                setPriceFilter(options.price)
            }
            if (options.sort) {
                setSortFilter(options.sort);
            }
        }
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl('/venues')).then((res) => {
            setVenues(res);
            setVenueFilter(_.map(res, (v, i) => v.id));
            setLoadingVenues(false);
        })
    }, []);

    useEffect(() => {
        onSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilter, timeFilter, startDate, endDate, startTime, endTime, venueFilter, limit, query, capacityFilter,
        loadingVenues, searchAllQuery, sortFilter, priceFilter, statusFilter, loadingEventTypes]);

    useEffect(() => {
        setTimeout(() => {
            $('#dateStart').datepicker( "destroy" );
            $('#dateStart').datepicker({
                defaultDate: 0,
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setStartDate(value);
                    setTimeout(() => $('#dateEnd').datepicker( "show" ), 100);
                }
            });
            $('#dateEnd').datepicker( "destroy" );
            $('#dateEnd').datepicker({
                defaultDate: "+1m",
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setEndDate(value)
                }
            });
        }, 100);
    }, [dateFilter, startDate, endDate]);

    useEffect(() => {
        if (_.isEmpty(venueFilter)) {
            setEventTypes([]);
            setEventFilter([]);
        } else {
            setLoadingEventTypes(true);
            serverFetch(getApiUrl('/event_types', { venueIds: venueFilter }), { skipAddingCategory: true }).then((res) => {
                const types = Object.keys(_.groupBy(res, 'eventType'));
                const etypes = _.map(types, (v, i) => { return { 'label': v, 'id': v }} );
                setEventTypes(etypes);
                setEventFilter(_.map(etypes, (v, i) => v.id));
                setLoadingEventTypes(false);
            })
        }
    }, [venueFilter]);

    useEffect(() => {
        setCheckedItems(_.filter(classes, (b) => checkedItemIds.includes(getRowId(b))));
    }, [checkedItemIds]);

    const dateFilterChanged = (value) => {
        setDateFilter(value);
    }

    const deleteClass = (row) => {
        serverDelete(getApiUrl(`/classes/${row.classData.id}`)).then((res) => {
            if (res) {
                Notification.Show(t('classes.list.successfully_cancelled'));
                onSearch(true);
            }
        });
    }

    const onAddNewClass = () => {
        navigate(`/${facilityLink}/class/create`);
    }

    const getQueryParams = () => {
        return {
            groupId: groupId,
            dateFilter: dateFilter && {
                filterType: dateFilter,
                startDate: startDate,
                endDate: endDate
            },
            status: statusFilter,
            groupName: query,
            venueFilter: venueFilter,
            eventFilter: eventFilter,
            capacity: capacityFilter,
            price: priceFilter,
            timeFilter: timeFilter && {
                filterType: timeFilter,
                startTime: startTime,
                endTime: endTime
            },
            sort: sortFilter,
            searchAllQuery: searchAllQuery,
        }
    }

    const onSearch = (restart = true) => {
        if (loadingEventTypes || loadingVenues) {
            return;
        }
        if (dateFilter === "dateRange" && (_.isNil(startDate) || _.isNil(endDate))) {
            return;
        }
        if (timeFilter === "timeRange" && (_.isNil(startTime) || _.isNil(endTime))) {
            return;
        }
        if (!_.isEmpty(eventTypes) && _.isEmpty(eventFilter)) {
            Notification.Show(t('classes.list.error.select_event_type'));
            return;
        }
        if (!_.isEmpty(venues) && _.isEmpty(venueFilter)) {
            Notification.Show(t('classes.list.error.select_venue'));
            return;
        }
        if (!groupId) {
            // Cache the options
            localStorage.setItem(facilityLink + "_filter_cache_classes", JSON.stringify(getQueryParams()));
        }

        const searchUrl = getApiUrl('/classes/sessions/search');
        const data = {
            pagination: {
                from: restart ? 0 : (classes.length || 0),
                limit: limit
            },
            query: getQueryParams(),
        };
        if (restart) {
            setClasses([]);
        }
        serverPost(searchUrl, data).then((res) => {
            setHasMore(res.length >= limit);
            if (restart) {
                setClasses(res.results);
            } else {
                setClasses(_.concat(classes, res.results))
            }
        })
    }

    const updateChecked = (event) => {
        if (event.target.value === "checkall") {
            let newCheckedItems = [];
            if (event.target.checked) {
                newCheckedItems = _.map(classes, (b, i) => getRowId(b));
                newCheckedItems.push("checkall");
            }
            setCheckedItemIds(newCheckedItems);
        } else {
            const newCheckedItems = [...checkedItemIds];
            if (event.target.checked) {
                newCheckedItems.push(event.target.value);
            } else {
                let index = newCheckedItems.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
                index = newCheckedItems.indexOf("checkall");
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
            }
            setCheckedItemIds(newCheckedItems);
        }
    }

    function getRowId(row) {
        return ("" + row.id);
    }

    const renderCount = (row) => {
        if (row.classData.capacity <= row.spotsFilled) {
            // Class is full
            return <div className="d-flex flex-row gap-6 justify-content-end">
                {
                    settings.allowClassWaitlist &&
                        <BaseOverlayTrigger content={t("classes.list.wait_list_count")} >
                            <span className="label label-warning">10</span>
                        </BaseOverlayTrigger>
                }
                <span className="label label-danger">{row.spotsFilled}/{row.classData.capacity}</span>
            </div>
        } else {
            // Class is not full
            if (0.8*row.classData.capacity <= row.spotsFilled) {
                return <span className="label label-warning">{row.spotsFilled}/{row.classData.capacity}</span>
            } else {
                return <span className="label">{row.spotsFilled}/{row.classData.capacity}</span>
            }
        }
    }

    const statusFilterOptions = [
        { 'id': "all", 'label': t("common.all") },
        { 'id': "active", 'label': t("common.active") },
        { 'id': "cancelled", 'label': t("common.cancelled") },
    ];

    const dateFilterOptions = [
        { 'id': "today", 'label': t("common.today") },
        { 'id': "future", 'label': t("common.future") },
        { 'id': "yesterday", 'label': t("common.yesterday") },
        { 'id': "thisWeek", 'label': t("common.this_week") },
        { 'id': "lastWeek", 'label': t("common.last_week") },
        { 'id': "thisMonth", 'label': t("common.this_month") },
        { 'id': "lastMonth", 'label': t("common.last_month") },
        { 'id': "dateRange", 'label': t("common.date_range") },
    ];

    const timeFilterOptions = [
        { 'id': "prime", 'label': t("common.prime") },
        { 'id': "nonPrime", 'label': t("common.non_prime") },
        { 'id': "dailyTime", 'label': t("common.daily_time") },
        { 'id': "timeRange", 'label': t("common.time_range") },
    ];

    const priceFilterOptions = [
        { 'id': "all", 'label': t("common.all") },
        { 'id': "free", 'label': t("common.free") },
        { 'id': "paid", 'label': t("common.paid") },
    ]

    const capacityFilterOptions = [
        { 'id': "all", 'label': t("common.all") },
        { 'id': "full", 'label': t("classes.list.full") },
        { 'id': "available", 'label': t("classes.list.available") },
    ];

    const sortOptions = [
        { 'id': "startTimeAsc", 'label': t("classes.list.start_time_asc") },
        { 'id': "startTimeDesc", 'label': t("classes.list.start_time_desc") },
    ]

    const dateFormat = getDateFormatForFacility(settings);
    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        groupId: groupId,
        userGroup: userGroup,
        userInfo: userInfo,
        settings: settings
    };

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "classes", tabsParams)} />
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6">
                            <div className="float-end d-flex align-items-center">
                                {
                                    hasAccess("booking", userInfo, "update") && <>
                                        <Button variant="primary" onClick={onAddNewClass}><i
                                            className="fa fa-circle-plus"/> {t("common.add_new")}</Button>
                                    </>
                                }
                                <SingleSelectDropdown className="inline" items={getLimitOptions()} selectedId={limit} onSelect={setLimit} menuOnly/>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="9" className="text-end d-grid align-items-center">
                            <span><strong>{t("common.sort_order")}:</strong></span>
                        </Col>
                        <Col md="3">
                            <SingleSelectDropdown name="sort" selectedId={sortFilter} items={sortOptions} onSelect={setSortFilter} />
                        </Col>
                    </Row>
                    <InfiniteScroll
                        dataLength={classes.length}
                        next={() => onSearch(false)}
                        hasMore={hasMore}
                        scrollableTarget="content_wrapper"
                    >
                        <Table hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t('classes.list.class_name')}</th>
                                    <th>{t('common.venue')}</th>
                                    <th>{t('common.date')}</th>
                                    <th>{t('common.time')}</th>
                                    <th>{t('classes.list.capacity')}</th>
                                    <th>{t('classes.list.price')}</th>
                                    <th>{t('common.status')}</th>
                                </tr>
                                <tr>
                                    <th className="controls no-stretch">
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedItemIds.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor="checkall"/>
                                        </div>
                                    </th>
                                    <th className="controls"><Form.Control type="text" name="query" value={query} onChange={(event) => { setQuery(event.target.value) }}/></th>
                                    <th className="controls"><MultiSelectDropdown items={venues} selectedItems={venueFilter} onItemsChange={setVenueFilter} labelField="name" /></th>
                                    <th className="controls">
                                        <SingleSelectDropdown items={dateFilterOptions} selectedId={dateFilter} onSelect={dateFilterChanged} showAll />
                                        {
                                            dateFilter === "dateRange" &&
                                                <BaseForm initialFormFields={{ dateStart: startDate, dateEnd: endDate }}>
                                                    <BaseForm.Control type="text" name="dateStart" id={"dateStart"} />
                                                    <BaseForm.Control type="text" name="dateEnd" id={"dateEnd"} />
                                                    <Button variant="outline-primary" onClick={(event) => onSearch()}><i className="fa fa-refresh fa-small"/></Button>
                                                </BaseForm>
                                        }
                                    </th>
                                    <th className="controls">
                                        <SingleSelectDropdown items={timeFilterOptions} selectedId={timeFilter} onSelect={setTimeFilter} showAll />
                                        {
                                            timeFilter === "timeRange" &&
                                                <>
                                                    <SingleSelectDropdown items={timeOptions()} selectedId={startTime} onSelect={setStartTime} showSearch idField="value" />
                                                    <SingleSelectDropdown items={timeOptions()} selectedId={endTime} onSelect={setEndTime} showSearch idField="value" />
                                                    <Button variant="outline-primary" onClick={(event) => onSearch()}><i className="fa fa-refresh fa-small"/></Button>
                                                </>
                                        }
                                    </th>
                                    <th className="controls"><SingleSelectDropdown items={capacityFilterOptions} selectedId={capacityFilter} onSelect={setCapacityFilter} align={"end"} showSearch={false} /></th>
                                    <th className="controls"><SingleSelectDropdown items={priceFilterOptions} selectedId={priceFilter} onSelect={setPriceFilter} align={"end"} showSearch={false} /></th>
                                    <th className="controls"><SingleSelectDropdown items={statusFilterOptions} selectedId={statusFilter} onSelect={setStatusFilter} align={"end"} showSearch={false} /></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.map(classes, (row, i) => 
                                        <tr key={i} className={classnames(!_.isNil(row.classData.deletedAt) && "de-highlighted")}>
                                            <td className="no-stretch">
                                                <div className="checkbox check-success inline">
                                                    <input type="checkbox" className="" value={getRowId(row)} id={getRowId(row)} name="delete[]"  checked={checkedItemIds.includes(getRowId(row))} onChange={ (event) => updateChecked(event) }/>
                                                    <label htmlFor={getRowId(row)}/>
                                                </div>
                                            </td>
                                            <td>
                                                <Link to={`/${facilityLink}/class/classes-list-participants?id=${row.classData.id}&s=${row.id}`}>{ row.classData.name }</Link>
                                                <br />
                                                {
                                                    _.map(row.classData.tags, (tag, i) => {
                                                        return (
                                                            <Badge className='my-2 me-1' bg='info' key={i}>{tag}</Badge>
                                                        )
                                                    })
                                                }
                                            </td>
                                            <td>{ row.venueName }</td>
                                            <td>
                                                <Link to={`/${facilityLink}/index?lid=${row.id}`}>
                                                    <span>{ moment(row.startTime).format(dateFormat) }</span>
                                                </Link>
                                            </td>
                                            <td>{ moment(row.startTime).format("h:mma") + " - " + moment(row.endTime).format("h:mma") }</td>
                                            <td className="text-end">{ renderCount(row) }</td>
                                            <td className="text-end"><span className="label">{ currencyFormat(row.classData.costInCents/100) }</span></td>
                                            <td className="text-center">
                                            {
                                                hasAccess("booking", userInfo, "update") && _.isNil(row.deletedAt) &&
                                                    <ButtonToolbar>
                                                        <Link to={`/${facilityLink}/class/update?id=${row.classData.id}`}><Button variant="outline-primary"><i className="fa fa-edit"/></Button></Link>
                                                        <Link to={`/${facilityLink}/class/classes-list-participants?id=${row.classData.id}&s=${row.id}`}><Button variant="primary"><i className="fa fa-users"/></Button></Link>
                                                    </ButtonToolbar>
                                            }
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    hasMore ?
                                        <tr>
                                            <td colSpan={11} className="center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                    : <tr>
                                            <td colSpan={11} className="center">
                                                <div className="label">{t('classes.list.end_of_classes')}</div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </InfiniteScroll>
                </div>
            </div>
        </BaseContainer>
    );
}

export default List;
