import { getUserInfo, storeUserInfo, clearUserInfo, storeCompanyInfo, getCategory } from './storage';
import Notification from '../components/Notification'
import * as Sentry from "@sentry/react";
const _ = require("lodash");

var cache = require('js-cache');
var requestCache = new cache();
var requestQueue = {};
const logInfo = false;

export function handleCategoryChange() {
    requestCache.clear();
}

export function clearRequestCache() {
    requestCache.clear();
}

export function getWebAppUrl(subUrl) {
    const baseWebAppUrl = process.env.REACT_APP_WEBAPP_BASE_URL || "http://localhost"
}

function getSubpathFromUrl(url) {
    let urlParser = new URL(url);
    let pathname = urlParser.pathname;
    const [first, api, version, facilities, facilityLink, ...rest] = pathname.split('/');
    return rest ? `/${rest.join("/")}` : rest;
}

export function getUrl(subUrl, params=null) {
    const baseServerUrl = process.env.REACT_APP_BASE_SERVER_URL || "";
    let url = baseServerUrl;
    url += subUrl;
    if (params) {
        let urlParser = new URL(url);
        _.each(params, (value, key) => {
            if (Array.isArray(value)) {
                _.each(value, (v) => {
                    urlParser.searchParams.append(`${key}[]`, v);
                })
            } else {
                urlParser.searchParams.set(key, value);
            }
            url = urlParser.toString();
        })
    }
    return url;
}

export function baseGetApiUrl(facilityLink, subUrl, params=null) {
    return getUrl(`/api/v1/facilities/${facilityLink}${subUrl}`, params);
}

export function getInvoiceCreationUrl(facilityLink, groupId, bookingIds, otherRows) {
    const prefix = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);
    localStorage.setItem(`invoice_uid_${prefix}`, bookingIds);
    localStorage.setItem(`invoice_uid_${prefix}_others`, JSON.stringify(otherRows));
    return `/${facilityLink}/invoice/create?groupId=${groupId}&uid=${prefix}`;
}

export function getInvoiceUpdateUrl(facilityLink, uuid, bookingIds) {
    const prefix = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);
    localStorage.setItem(`invoice_uid_${prefix}`, bookingIds);
    return `/${facilityLink}/invoice/update?uuid=${uuid}&uid=${prefix}`;
}

export function getAgreementCreationUrl(facilityLink, groupId, bookingIds) {
    const prefix = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);
    localStorage.setItem(`agreement_uid_${prefix}`, bookingIds);
    return `/${facilityLink}/agreement/create?groupId=${groupId}&uid=${prefix}`;
}

export function getAgreementUpdateUrl(facilityLink, uuid, bookingIds) {
    const prefix = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);
    localStorage.setItem(`agreement_uid_${prefix}`, bookingIds);
    return `/${facilityLink}/agreement/update?uuid=${uuid}&uid=${prefix}`;
}

function getFacilityLinkFromUrl(url) {
    const [pre, post] = url.split('/facilities/');
    if (post) {
        const [facilityLink, ...rest] = post.split('/');
        return facilityLink;
    } else {
        return null;
    }
}

function appendCategoryToFetchUrlIfNeeded(url, options = {}) {
    // Attach categoryId to all calls if we have one
    const skipAddingCategory = options && options.skipAddingCategory;
    const currentCategory = getCategory(getFacilityLinkFromUrl(url));
    if (!_.isNil(currentCategory) && skipAddingCategory !== true) {
        const originalUrl = new URL(url, window.location.origin);
        if (!originalUrl.searchParams.get("categoryId")) {
            // Only add categoryId if it is not already present. DON'T override it
            originalUrl.searchParams.set("categoryId", currentCategory);
        }

        if (originalUrl.origin === window.location.origin) {
            // We got a relative path as a param and we added the origin. We need to strip it out before returning it
            url = originalUrl.pathname + originalUrl.search;
        } else {
            url = originalUrl.href;
        }
    }
    return url;
}

//function appendCategoryToPostDataIfNeeded(method, data, options = {}) {
//    if (method !== "POST") {
//        return data;
//    }
//    // Only add categoryId to post data. Not Patch or delete
//
//    const skipAddingCategory = options && options.skipAddingCategory;
//    const currentCategory = getCategory(facilityLink);
//    if (!_.isNil(currentCategory) && skipAddingCategory !== true) {
//        data = data || {};
//        data['categoryId'] = currentCategory;
//    }
//    return data;
//}

export async function serverFetch(url, options = {}, onError = null) {
    logInfo && console.log("Fetching " + url + ", " + JSON.stringify(options));

    url = appendCategoryToFetchUrlIfNeeded(url, options);

    options = options || {};
    if (options.skipCache !== true) {
        const cachedValue = requestCache.get(url);
        if (cachedValue) {
            logInfo && console.log("Returning cached value");
            return Promise.resolve(copy(cachedValue));
        }
    }

    if (localStorage.getItem("auth_token") != null) {
        options['headers'] = options['headers'] || {};
        options['headers']['Authorization'] = 'Bearer ' + localStorage.getItem("auth_token");
    }
    const random = Math.floor(Math.random() * 1000);
    const queueKey = url + JSON.stringify(options);
    logInfo && console.log(random + "The queue key is " + url);
    let resultPromise = null;
    if (queueKey in requestQueue) {
        logInfo && console.log(random + "Already in queue. Waiting");
        resultPromise = requestQueue[queueKey];
    } else {
        logInfo && console.log(random + "Not in queue. Creating item");
        resultPromise = fetch(url, options)
            .then(async (res) => {
                if (res.ok) {
                    if (!options.noJson) {
                        return res.json();
                    } else {
                        return res;
                    }
                } else {
                    throw res;
                }
            })
            .catch(async (res) => {
                const status = res.status;
                const errorMessage = await res;
                if (onError) {
                    onError(errorMessage);
                }
                if (status !== 401 && !_.isNil(status) && !_.isUndefined(status)) {
                    Sentry.captureException(new Error("Server fetch failed " + url + ", " + status), {
                        extra: {
                            status: ""+status,
                            location: url,
                            options: options,
                            errorMessage: errorMessage
                        },
                    });
                }
                if (!options.suppressUnauthenticated && res.status === 401) {
                    console.log("Seems to be logged out. Go to the main page.");
                    // Unauthenticated. Redirect to main page.
                    clearUserInfo();
                    const facilityLink = getFacilityLinkFromUrl(url);
                    const redirectTo = facilityLink ? `/${facilityLink}` : '/';
                    if (window.location.pathname !== redirectTo) {
                        window.location = redirectTo;
                    }
                }
            });
        requestQueue[queueKey] = resultPromise;
    }
    const result = await resultPromise;
    delete requestQueue[queueKey];
    logInfo && console.log(random + "Resolved");
    if (result && !result.error) {
        logInfo && console.log("Storing in cache");
        requestCache.set(url, result, 60000);
    }

    // Cache company info and user info when possible
    if (url.endsWith('/api/common/settings')) {
        storeCompanyInfo(result);
    } else if (url.endsWith('/user')) {
        storeUserInfo(result);
    }

    logInfo && console.log("Done Fetching " + url);
    return copy(result);
}

function copy(result) {
    if (Array.isArray(result)) {
        return [...result];
    } else {
        return {...result};
    }
}

export async function serverPost(url, data, options = {}, onError = null) {
    return internalServerPost("POST", url, data, options, onError);
}

export async function serverPatch(url, data, options = {}, onError = null) {
    const result = await internalServerPost("PATCH", url, data, options, onError);
    let subPath = getSubpathFromUrl(url);
    if (subPath === "/settings") {
        notifyEvent('settings');
    }

    return result;
}

export async function serverPut(url, data, options = {}, onError = null) {
    options = options || {};
    options['method'] = "PUT";
    options['body'] = data;
    return await fetch(url, options)
        .then(async (res) => {
            if (res.ok) {
                return res.blob();
            } else {
                throw res;
            }
        })
}

export async function serverDelete(url) {
    return internalServerPost("DELETE", url, null, {}, null);
}

async function defaultErrorHandler(res, errorResponse) {
    if (res.status === 400) {
        let message = "";
        try {
            const errorResponse = await res.json();
            message = JSON.stringify(errorResponse);
        } catch(e) {
            // Ignore this
        }
        if (!_.isEmpty(message)) {
            Notification.Show(message)
        }
    }
}

export async function internalServerPost(method, url, data, options = {}, onError = null) {
    options = options || {};
    options['method'] = method;
    options['headers'] = options['headers'] || {};
    let contentType = 'application/json'
    if (options['contentType'] && options['contentType'] === "multipart/form-data") {
        // options['headers']['Content-Type'] = options['contentType']
        contentType = options['contentType']
    } else {
        options['headers']['Content-Type'] = 'application/json'
    }
    if (localStorage.getItem("auth_token") != null && !options.ignoreAuth) {
        options['headers']['Authorization'] = 'Bearer ' + localStorage.getItem("auth_token");
    }
    if (data !== null) {
        if (contentType === 'application/json') {
            options['body'] = JSON.stringify(data);
        } else {
            options['body'] = data;
        }
    }
    const result = await fetch(url, options)
        .then(async (res) => {
            if (res.ok) {
                if (!options.noJson) {
                    return res.json();
                } else {
                    return res.blob();
                }
            } else {
                throw res;
            }
        })
        .catch(async (res) => {
            const status = res.status;
            if (res.name === "SyntaxError") {
                return options.noJson ? "": {};
            }
            let errorMessage = {};
            try {
                errorMessage = await res.json();
            } catch {
                // Ignore if any error comes from parsing the error message.
            }
            let reportError = true;
            if (onError) {
                const handleResult = await onError(res, errorMessage);
                if (!_.isNil(handleResult)) {
                    reportError = handleResult;
                }
            } else {
                const handleResult = await defaultErrorHandler(res, errorMessage);
                if (!_.isNil(handleResult)) {
                    reportError = handleResult;
                }
            }
            if (status !== 401 && !_.isNil(status) && !_.isUndefined(status) && reportError) {
                Sentry.captureException(new Error("Server " + method + " failed " + url + ", " + status), {
                    extra: {
                        status: ""+status,
                        location: url,
                        options: options,
                        errorMessage: errorMessage
                    },
                });
            }
            if (!options.suppressUnauthenticated && res.status === 401) {
                // Unauthenticated. Redirect to main page.
                clearUserInfo();
                const facilityLink = getFacilityLinkFromUrl(url);
                const redirectTo = facilityLink ? `/${facilityLink}` : '/';
                if (window.location.pathname !== redirectTo) {
                    window.location = redirectTo;
                }
            }
        });

    if (requestCache.get(url)) {
        requestCache.del(url);
    }

    return result;
}

var subscriptions = new cache();
export function subscribeToEvent(event, callback) {
    let listeners = subscriptions.get(event);
    if (!listeners) {
        listeners = [callback];
    } else {
        listeners.push(callback);
    }
    subscriptions.set(event, listeners);
}

export function notifyEvent(event, data) {
    const listeners = subscriptions.get(event);
    if (listeners) {
        _.each(listeners, (l) => {
            l(data);
        })
    }
}
