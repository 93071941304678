import '../../App.scss';
import '../../css/modals.scss';
import { Modal, Alert } from 'react-bootstrap';
import { useEffect, useState, useContext } from 'react';
import { renderChildren, BaseContext } from '../../helpers/common';

const BaseModal = (props) => {
    const { settings } = useContext(BaseContext);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        if (props.onClose) {
            props.onClose(false);
        }
    }

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <Modal show={show} onHide={handleClose} size={props.size || "md"} className={settings.isPartnerFacility ? "partner" : ""} backdrop={props.unclosable ? 'static' : undefined}>
        { show && renderChildren(props) }
        </Modal>
    );
}

const Header = (props) => {
    return (
        <Modal.Header closeButton closeVariant="white">
            { props.children }
        </Modal.Header>
    );
}
BaseModal.Header = Header;

const Body = (props) => {
    return (
        <Modal.Body {...props}>
            <div className="notification-model-wrapper">
                <div className="notification-modal ahide noprint">
                    <Alert variant="warning">
                        Warning message
                    </Alert>
                </div>
            </div>
            { props.children }
        </Modal.Body>
    );
}
BaseModal.Body = Body;

const Title = (props) => {
    return (
        <Modal.Title>
            { props.children }
        </Modal.Title>
    );
}
BaseModal.Title = Title;

const Footer = (props) => {
    return (
        <Modal.Footer>
            { props.children }
        </Modal.Footer>
    );
}
BaseModal.Footer = Footer;

export default BaseModal;
