import '../../App.scss';
import '../../css/modals.scss';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState, useRef, useContext} from 'react';
import Notification from '../Notification';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import {BaseContext} from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import {Container} from 'react-bootstrap';

const _ = require('lodash');

function UnlinkPartnerConfirmationModal(props) {
    const {getApiUrl} = useContext(BaseContext);
    const {t} = useTranslation('common');
    const [selectedBookings, setSelectedBookings] = useState([]);

    useEffect(() => {
        if (props.bookings) {
            setSelectedBookings(props.bookings)
        }
    }, [props.bookings]);

    const unlinkFromPartner = (data) => {
        const pushData = {
            bookingIds: _.map(selectedBookings, b => b.id)
        }
        serverPost(getApiUrl("/bookings/remove_from_partners"), pushData).then((res) => {
            if (res) {
                Notification.Show("Started removing the bookings from LeagueApps.")
                props.onClose();
            }
        });
    }

    return (
        <>
            <BaseModal {...props}>
                <BaseForm onSubmit={unlinkFromPartner}>
                    <BaseModal.Header>
                        <BaseModal.Title>Unlink and Remove from LeagueApps</BaseModal.Title>
                    </BaseModal.Header>
                    <BaseModal.Body>
                        <p>Are you sure you want to unlink and remove these { selectedBookings.length } booking(s) from LeagueApps?</p>
                    </BaseModal.Body>
                    <BaseModal.Footer>
                        <Container>
                            <div className="d-flex flex-row justify-content-end">
                                <SubmitButton>Unlink and Remove</SubmitButton>
                            </div>
                        </Container>
                    </BaseModal.Footer>
                </BaseForm>
            </BaseModal>
        </>
    );
}

export default UnlinkPartnerConfirmationModal;
