import { useEffect, useState } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import BaseForm from '../../components/BaseForm';
import { Alert, Row, Col, Table, InputGroup } from 'react-bootstrap';
const _ = require("lodash");

function Playground() {
    const defaultFormFields = {
        textInput: "Default Text",
        passwordInput: "Default Password",
        dateInput: "2022-06-01",
        selectInput: "2",
        colorInput: "#dc2127",
        textareaInput: "Default textarea"
    };

    useEffect(() => {
        document.title = `Playground`;
    }, []);

    const selectOptions = [
        { value: '1', label: 'One' },
        { value: '2', label: 'Two' },
        { value: '3', label: 'Three' },
    ]

    return (
        <div className="content-container">
            <div className="screen-wrapper">
                <div className="content-box">
                    <div className="content-body">
                        <Alert variant="info"><strong>Base form input items:</strong></Alert>
                        <BaseForm initialFormFields={defaultFormFields}>
                            <Row>
                                <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput" required />
                                <BaseForm.Input colSpan="4" type="password" label="Password input" name="passwordInput" required />
                                <BaseForm.Input colSpan="4" type="check" label="Check input" name="checkInput" required />
                                <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput" required />
                                <BaseForm.Input colSpan="4" type="date" label="Date input" name="dateInput" required />
                                <BaseForm.Input colSpan="4" type="select" label="Select input" name="selectInput" options={selectOptions} required />
                                <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput" required />
                                <BaseForm.Input colSpan="4" type="color" label="Color input" name="colorInput" required />
                                <BaseForm.Input colSpan="4" type="file" label="File input" name="fileInput" required />
                                <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput" required />
                                <BaseForm.Input colSpan="4" type="textarea" label="Textarea input" name="textareaInput" required />
                            </Row>
                        </BaseForm>
                        <Alert variant="info"><strong>Base form input items: without labels</strong></Alert>
                        <BaseForm initialFormFields={defaultFormFields}>
                            <Row>
                                <BaseForm.Input colSpan="4" type="text" name="textInput" required hideLabel />
                                <BaseForm.Input colSpan="4" type="password" name="passwordInput" required hideLabel />
                                <BaseForm.Input colSpan="4" type="check" name="checkInput" required hideLabel />
                                <BaseForm.Input colSpan="4" type="text" name="textInput" required hideLabel />
                                <BaseForm.Input colSpan="4" type="date" id="dateInput2" name="dateInput" required hideLabel />
                                <BaseForm.Input colSpan="4" type="select" name="selectInput" options={selectOptions} required hideLabel />
                                <BaseForm.Input colSpan="4" type="text" name="textInput" required hideLabel />
                                <BaseForm.Input colSpan="4" type="color" name="colorInput2" required hideLabel />
                                <BaseForm.Input colSpan="4" type="file" name="fileInput" required hideLabel />
                                <BaseForm.Input colSpan="4" type="text" name="textInput" required hideLabel />
                                <BaseForm.Input colSpan="4" type="textarea" name="textareaInput" required hideLabel />
                            </Row>
                        </BaseForm>
                        <Alert variant="info"><strong>Base form input items: with side contents</strong></Alert>
                        <BaseForm initialFormFields={defaultFormFields}>
                            <Row>
                                <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput" required leftContent="L" rightContent="R" />
                                <BaseForm.Input colSpan="4" type="password" label="Password input" name="passwordInput" required leftContent="L" rightContent="R" />
                                <BaseForm.Input colSpan="4" type="check" label="Check input" name="checkInput" required leftContent="L" rightContent="R" />
                                <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput" required leftContent="L" rightContent="R" />
                                <BaseForm.Input colSpan="4" type="date" id="dateInput3" label="Date input" name="dateInput" required leftContent="L" rightContent="R" />
                                <BaseForm.Input colSpan="4" type="select" label="Select input" name="selectInput" options={selectOptions} required leftContent="L" rightContent="R" />
                                <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput" required leftContent="L" rightContent="R" />
                                <BaseForm.Input colSpan="4" type="color" label="Color input" name="colorInput2" required leftContent="L" rightContent="R" />
                                <BaseForm.Input colSpan="4" type="file" label="File input" name="fileInput" required leftContent="L" rightContent="R" />
                                <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput" required leftContent="L" rightContent="R" />
                                <BaseForm.Input colSpan="4" type="textarea" label="Textarea input" name="textareaInput" required leftContent="L" rightContent="R" />
                            </Row>
                        </BaseForm>
                        <Alert variant="info"><strong>Base form items: without .input</strong></Alert>
                        <BaseForm initialFormFields={defaultFormFields}>
                            <Row>
                                <Col md="4">
                                    <BaseForm.TextGroup type="text" name="textInput" label="Text Input" />
                                </Col>
                                <Col md="4">
                                    <BaseForm.PasswordGroup type="password" name="passwordInput" label="Password Input" />
                                </Col>
                                <Col md="4">
                                    <BaseForm.CheckGroup name="checkInput" label="Check Input" />
                                </Col>
                                <Col md="4">
                                    <BaseForm.TextGroup type="text" name="textInput" label="Text Input" />
                                </Col>
                                <Col md="4">
                                    <BaseForm.DateGroup id="dateInput4" name="dateInput" label="Date Input" />
                                </Col>
                                <Col md="4">
                                    <BaseForm.SelectGroup name="selectInput" label="Select Input" options={selectOptions} />
                                </Col>
                                <Col md="4">
                                    <BaseForm.TextGroup type="text" name="textInput" label="Text Input" />
                                </Col>
                                <Col md="4">
                                    <BaseForm.ColorGroup name="colorInput3" label="Color Input" />
                                </Col>
                                <Col md="4">
                                    <BaseForm.FileGroup name="fileInput" label="File Input" />
                                </Col>
                                <Col md="4">
                                    <BaseForm.TextGroup type="text" name="textInput" label="Text Input" />
                                </Col>
                                <Col md="4">
                                    <BaseForm.TextAreaGroup type="textarea2" name="textareaInput" label="Textarea Input" />
                                </Col>
                            </Row>
                        </BaseForm>
                        <Alert variant="info"><strong>Base form inside large input group</strong></Alert>
                        <BaseForm initialFormFields={defaultFormFields}>
                            <Row>
                                <Col md="12">
                                    <BaseForm.InputGroup>
                                        <InputGroup.Text>A</InputGroup.Text>
                                        <BaseForm.Text name="textInput" label="Text Input" />
                                        <InputGroup.Text>B</InputGroup.Text>
                                        <BaseForm.Text name="passwordInput" label="Password Input" />
                                        <InputGroup.Text>C</InputGroup.Text>
                                        <BaseForm.Check name="checkInput" label="Check Input" />
                                        <InputGroup.Text>D</InputGroup.Text>
                                    </BaseForm.InputGroup>
                                    <BaseForm.InputGroup>
                                        <InputGroup.Text>A</InputGroup.Text>
                                        <BaseForm.Text name="textInput" label="Text Input" />
                                        <InputGroup.Text>B</InputGroup.Text>
                                        <BaseForm.Date id="dateInput5" name="dateInput" label="Date Input" />
                                        <InputGroup.Text>C</InputGroup.Text>
                                        <BaseForm.Select name="selectInput" label="Select Input" options={selectOptions} />
                                        <InputGroup.Text>D</InputGroup.Text>
                                    </BaseForm.InputGroup>
                                    <BaseForm.InputGroup>
                                        <InputGroup.Text>A</InputGroup.Text>
                                        <BaseForm.Text name="textInput" label="Text Input" />
                                        <InputGroup.Text>B</InputGroup.Text>
                                        <BaseForm.Color name="colorInput3" label="Color Input" />
                                        <InputGroup.Text>C</InputGroup.Text>
                                        <BaseForm.File name="fileInput" label="File Input" />
                                        <InputGroup.Text>D</InputGroup.Text>
                                    </BaseForm.InputGroup>
                                    <BaseForm.InputGroup>
                                        <InputGroup.Text>A</InputGroup.Text>
                                        <BaseForm.Text name="textInput" label="Text Input" />
                                        <InputGroup.Text>B</InputGroup.Text>
                                        <BaseForm.TextArea type="textarea2" name="textareaInput" label="Textarea Input" />
                                        <InputGroup.Text>C</InputGroup.Text>
                                    </BaseForm.InputGroup>
                                </Col>
                            </Row>
                        </BaseForm>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Playground;
