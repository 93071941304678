import '../../App.scss';
import '../../css/modals.scss';
import { getInvoiceCreationUrl, getInvoiceUpdateUrl, serverFetch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Button, ButtonGroup, Row, Col, Table } from 'react-bootstrap';
const _ = require('lodash');

function AddBookingsToInvoiceModal(props) {
    const { getApiUrl, facilityLink } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [initialFormFields, setInitialFormFields] = useState(null);
    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        if (props.show) {
            if (props.usePreloadedInvoices) {
                const orderedInvoices = _.orderBy(props.invoices, ['id'], ['desc']);
                setInvoices(orderedInvoices);
                if (!_.isEmpty(orderedInvoices)) {
                    setInitialFormFields({ selectedInvoice: orderedInvoices[0].uuid });
                }
            } else {
                serverFetch(getApiUrl('/invoices', { groupId: props.groupId })).then((res) => {
                    if (res) {
                        const orderedInvoices = _.orderBy(res, ['id'], ['desc']);
                        setInvoices(orderedInvoices);
                        if (!_.isEmpty(orderedInvoices)) {
                            setInitialFormFields({ selectedInvoice: orderedInvoices[0].uuid });
                        }
                    }
                })
            }
        }
    }, [props.show, props.groupId, props.invoices, props.usePreloadedInvoices]);

    const addToInvoice = (data) => {
        navigate(getInvoiceUpdateUrl(facilityLink, data.selectedInvoice, _.map(props.bookings, (b) => b.id)))
    }

    const createNewInvoice = () => {
        navigate(getInvoiceCreationUrl(facilityLink, props.groupId, _.map(props.bookings, (b) => b.id)))
    }

    if (!props.bookings) {
        return null;
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title><i className="fa fa-file"/> Add to existing invoice</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm initialFormFields={initialFormFields} onSubmit={addToInvoice}>
                <BaseModal.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Inv #</th>
                                <th>Date</th>
                                <th>Total</th>
                                <th>Paid</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            _.map(invoices, (invoice, i) =>
                                <tr key={i}>
                                    <td>
                                        <BaseForm.CheckboxRadio name="selectedInvoice" value={invoice.uuid}/>
                                    </td>
                                    <td>{invoice.invoiceNumber}</td>
                                    <td>{invoice.invoiceDate}</td>
                                    <td>{invoice.totalFormatted}</td>
                                    <td>{invoice.paidAmountFormatted}</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                    {
                        _.isEmpty(invoices) &&
                            <p>There are no existing invoices for this customer. Please create a new invoice.</p>
                    }
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <Button variant="link" onClick={() => props.onClose()}>Close</Button>
                            {
                                !_.isEmpty(invoices) &&
                                    <SubmitButton variant="primary">Add to Selected Invoice</SubmitButton>
                            }
                            <Button variant="success" onClick={createNewInvoice}>Create New Invoice</Button>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default AddBookingsToInvoiceModal;
