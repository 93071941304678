import '../App.scss';
import { Button, Spinner } from 'react-bootstrap';

function SubmitButton(props) {
    return (
        <Button disabled={props.disabled} type="submit" {...props}>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            >&nbsp;</Spinner>
            <span className="submit-button-content">
            {props.children}
            </span>
        </Button>
    );
}

export default SubmitButton;
