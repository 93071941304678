import '../../App.scss';
import '../../css/modals.scss';
import { useEffect, useState, useMemo, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import TabHeader from '../TabHeader';
import SubmitButton from '../SubmitButton';
import { Button, Row, Col, Alert, Table } from 'react-bootstrap';
import { serverPost } from '../../helpers/server';
import { currencyFormat, BaseContext } from '../../helpers/common';
import moment from 'moment';
import classnames from 'classnames';
const _ = require("lodash");

function MassInvoiceCreationModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [currentTab, setCurrentTab] = useState("select-groups");
    const [checkedGroups, setCheckedGroups] = useState([]);
    const [groupData, setGroupData] = useState({});
    const defaultFields = useMemo(() => {
	    return { 'invoiceDate': moment().format("YYYY-MM-DD"), 'dueDate': moment().format("YYYY-MM-DD") };
	}, [])

    useEffect(() => {
        setGroupData(props.bookingGroupingData);
    }, [props.bookingGroupingData]);

    useEffect(() => {
        if (!props.show) {
            setCheckedGroups([]);
        }
    }, [props.show])

    const updateCheckedGroups = (event) => {
        if (event.target.value === "checkall_mass") {
            let newCheckedGroups = [];
            if (event.target.checked) {
                newCheckedGroups = _.map(groupData, (gData) => ""+gData.groupId);
                newCheckedGroups.push("checkall_mass");
            }
            setCheckedGroups(newCheckedGroups);
        } else {
            const newCheckedGroups = [...checkedGroups];
            if (event.target.checked) {
                newCheckedGroups.push(event.target.value);
            } else {
                const index = newCheckedGroups.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedGroups.splice(index, 1);
                }
            }
            setCheckedGroups(newCheckedGroups);
        }
    }

    const createInvoices = (data) => {
        const cgroups = _.filter(checkedGroups, (g) => g !== "checkall_mass");
        const invoices = _.filter(groupData, (group) => _.includes(cgroups, ""+group.groupId));
        data['invoices'] = invoices;
        if (invoices.length > 0) {
            serverPost(getApiUrl('/invoices/batch_create'), data).then((res) => {
                props.onClose(true);
            });
        } else {
            props.onClose();
        }
    }

    const tabItems = [
        { value: "select-groups", label: "Select Groups", icon: "fa-list", active: currentTab === "select-groups", onClick: () => setCurrentTab("select-groups") },
        { value: "extras", label: "Extras", icon: "fa-info-circle", active: currentTab === "extras", onClick: () => setCurrentTab("extras") }
    ];

    return (
        <BaseModal size="lg" {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Mass Invoice Options</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm initialFormFields={defaultFields} onSubmit={createInvoices}>
            <div className="content-box">
                <TabHeader items={tabItems} />
                <div className="content-body">
                    <div className={classnames(currentTab === "select-groups" ? "": "hide")}>
                        <Alert variant="info"><strong>Select the groups below to create multiple invoices at once.</strong></Alert>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th className="controls">
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value="checkall_mass" id="checkall_mass" name="delete[]" checked={checkedGroups.includes("checkall_mass")} onChange={ (event) => updateCheckedGroups(event) }/>
                                            <label htmlFor="checkall_mass"/>
                                        </div>
                                    </th>
                                    <th>Group</th>
                                    <th># of Bookings</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                _.map(groupData, (gData, k) =>
                                    <tr key={k}>
                                        <td>
                                            <div className="checkbox check-success inline">
                                                <input type="checkbox" className="" value={gData.groupId} id={gData.groupId} name="delete[]"  checked={checkedGroups.includes(""+gData.groupId)} onChange={ (event) => updateCheckedGroups(event) }/>
                                                <label htmlFor={gData.groupId}/>
                                            </div>
                                        </td>
                                        <td>{gData.groupName}</td>
                                        <td>{gData.numberOfBookings}</td>
                                        <td>{currencyFormat(gData.totalAmount)}</td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                    </div>
                    <div className={classnames(currentTab === "extras" ? "": "hide")}>
                        <Alert variant="info"><strong>This info will be added to all invoices created at this time and can be changed later under each invoice.</strong></Alert>
                        <Row>
                            <BaseForm.Input colSpan="6" name="invoiceDate" label="Invoice Date" type="date" required />
                            <BaseForm.Input colSpan="6" name="dueDate" label="Due Date" type="date" required />
                            <BaseForm.Input colSpan="12" name="notes" label="Invoice Notes" type="editor" />
                        </Row>
                    </div>
                </div>
            </div>
            <BaseModal.Footer>
                <Row>
                    <Col md="12" className="text-end">
                        <Button variant="link" onClick={() => props.onClose()}>Close</Button>
                        <SubmitButton variant="primary">Create</SubmitButton>
                    </Col>
                </Row>
            </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );
}

export default MassInvoiceCreationModal;
