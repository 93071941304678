import React, { useEffect, useState, useContext, createRef } from 'react';
import '../../App.scss';
import '../../css/classes.scss';
import { serverFetch, serverPost } from '../../helpers/server'
import { BaseContext, currencyFormat, getDateFormatForFacility } from '../../helpers/common'
import ClassesContainer from '../../components/classes/ClassesContainer';
import SignupModal from '../../components/modals/SignupModal';
import Loader from '../../components/Loader';
import ClassFormFieldsComponent from '../../components/ClassFormFieldsComponent';
import BaseForm from '../../components/BaseForm';
import ErrorComponent from '../../components/ErrorComponent';
import { useSearchParams, Link } from "react-router-dom";
import { Table, Row, Col, Button, Alert, ButtonGroup } from 'react-bootstrap';
import classnames from 'classnames';
import LoadingModal from '../../components/modals/LoadingModal';
import moment from 'moment';
import BookingPaymentInput from "../../components/BookingPaymentInput";
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function ClassesStep2(props) {
    const { t } = useTranslation('common');
    const { isLoggedIn, userInfo, settings, getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const [classForm, setClassForm] = useState(null);
    const [classDetails, setClassDetails] = useState({});
    const [classNotFound, setClassNotFound] = useState(false);
    const [waitlistAvailableError, setWaitlistAvailableError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [registrationDone, setRegistrationDone] = useState(false);
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [payLater, setPayLater] = useState(false);
    const [calcCost, setCalcCost] = useState({});
    const [isClassFull, setIsClassFull] = useState(false);
    const [searchParams] = useSearchParams();
    const classId = searchParams.get("id");
    const formRef = createRef();
    const paymentRef = createRef();

    useEffect(() => {
        let title = getFacilityName() + " - Classes";
        if (classDetails.name) {
            title += " - " + classDetails.name;
        }
        document.title = title;
    }, [classDetails]);

    useEffect(() => {
        setLoading(true);
        fetchClassDetails();
    }, [facilityLink, classId]);

    useEffect(() => {
        updateCalcCost();
    }, [classDetails])

    const fetchClassDetails = (skipCache=false) => {
        serverFetch(getApiUrl(`/classes/${classId}`), { skipCache }).then((res) => {
            console.log("The res is " + JSON.stringify(res));
            if (!_.isEmpty(res)) {
                setClassDetails(res)
                setClassForm(res.form);
                setClassNotFound(false);
            } else {
                console.log("Class not found");
                setClassNotFound(true);
            }
            setLoading(false);
            setIsClassFull(_.every(res.sessions, (ss) => ss.spotsFilled >= res.capacity));
        })
    }

    const updateCalcCost = () => {
        if (!classDetails || _.isEmpty(classDetails)) {
            return;
        }
        const calcData = {
            sessionIds: _.map(classDetails.sessions, (s) => s.id)
        }
        serverPost(getApiUrl(`/classes/${classDetails.id}/register_calc_cost`), calcData).then(res => {
            if (res) {
                setCalcCost(res);
            }
        })
    }

    const onFieldChange = (name, value) => {
        if (name === "payLater") {
            setPayLater(value);
        }
    }

    const renderPaymentMethod = (details) => {
        const showPayLater = details.paymentOptions === "onlineOrLater" || details.paymentOptions === "later";
        return (
            <>
                <span><strong>Payment Details</strong></span><br/>
                <span style={{ fontSize: "14px" }}>Enter payment details to pay for the registration.</span>
                <div className="content-box1">
                    <div className="content-body1">
                        {
                            showPayLater &&
                                <>
                                    <ButtonGroup aria-label="Basic example">
                                        <Button variant={payLater ? "outline-primary": "primary"} className="btn-sm" onClick={() => onFieldChange("payLater", false)}>Pay Now</Button>
                                        <Button variant={payLater ? "primary": "outline-primary"} className="btn-sm" onClick={() => onFieldChange("payLater", true)}>Pay Later</Button>
                                    </ButtonGroup>
                                    <br/>
                                </>
                        }
                        <br/>
                        {
                            (!showPayLater || !payLater) &&
                                <>
                                    <BookingPaymentInput ref={paymentRef} />
                                </>
                        }
                    </div>
                </div>
            </>
        );
    }

    const renderSessions = (details) => {
        if (_.isEmpty(details.sessions)) {
            return null;
        }
        const dateFormat = getDateFormatForFacility(settings);
        return (
            <div>
                <p><strong>Sessions</strong></p>
                <Row>
                    <Col lg="12">
                    <Table borderless className="skinny">
                        <tbody>
                        {
                            _.map(details.sessions, (session, i) =>
                                <tr key={i}>
                                    <td style={{ paddingLeft: "0px" }}>
                                        <strong>{ moment(session.startTimeLocal).format(dateFormat) }</strong><br/>
                                        <span>{ moment(session.startTimeLocal).format("h:mma") + " - " + moment(session.endTimeLocal).format("h:mma") }</span>
                                    </td>
                                    <td>{ session.venueName }</td>
                                    {
                                        classDetails.priceType !== "totalPrice" &&
                                            <td>{ currencyFormat(session.cost) }</td>
                                    }
                                    <td className="text-end skinny">
                                        <span className="label">{ session.spotsFilled }/{ details.capacity }</span>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                    </Col>
                </Row>
            </div>
        )
    }

    const renderPricingSummary = (details) => {
        if (_.isEmpty(details.sessions)) {
            return null;
        }
        const dateFormat = getDateFormatForFacility(settings);
        return (
            <div>
                <p><strong>Your price summary</strong></p>
                <Row>
                    <Col lg="12">
                        <Table borderless className="skinny">
                            <tbody>
                                <tr className="skinny">
                                    <td>Session(s)</td>
                                    <td className="text-end">{ currencyFormat(calcCost.subtotalInCents/100) }</td>
                                </tr>
                                <tr className="skinny">
                                    <td>Taxes ({ calcCost.taxLabel })</td>
                                    <td className="text-end">{ currencyFormat(calcCost.taxInCents/100) }</td>
                                </tr>
                                {
                                    _.includes(settings.creditCardFeeUsage, "class") && calcCost.feesInCents > 0 &&
                                        <tr className="skinny">
                                            <td>{ calcCost.feeLabel }</td>
                                            <td className="text-end no-stretch">{ currencyFormat(calcCost.feesInCents/100) }</td>
                                        </tr>
                                }
                                <tr className="skinny">
                                    <td><strong>Total Price</strong></td>
                                    <td className="text-end">{ currencyFormat(calcCost.totalInCents/100) }</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        )
    }

    const onSignupSuccess = () => {
        window.location.reload();
    }

    const onRegisterError = async (response, errorMessage) => {
        if ('waitlist' in errorMessage) {
            setWaitlistAvailableError(true)
        } else if ('responses' in errorMessage) {
            setIsClassFull(true);
        }
    }

    const onRegister = async (data) => {
        const formData = formRef.current.getFormData();
        let bookingPaymentMethod = null;
        if (paymentRef.current) {
            bookingPaymentMethod = await paymentRef.current.getPaymentMethod(formData);
            if (!bookingPaymentMethod) {
                return;
            }
        }

        const fieldValues = [];
        const keys = _.filter(_.keys(data), (k) => k.startsWith("field_"));
        _.each(keys, (key, i) => {
            fieldValues.push({ fieldId: key.substring(6), data: data[key] });
        })
        const registerData = {
            formId: classDetails.form.id,
            sessionIds: _.map(classDetails.sessions, (s) => s.id),
            payLater: payLater,
            ...calcCost,
            addToWaitlist: ((isClassFull || waitlistAvailableError) && classDetails.allowWaitlist),
            formResponse: {
                registrationUser: {
                    name: data.name,
                    email: data.email
                },
                responses: fieldValues
            }
        }
        // userId: userInfo.id,
        if (bookingPaymentMethod) {
            registerData["paymentData"] = {
                stripe: bookingPaymentMethod
            }
        }
        setRegistering(true);
        console.log("Register " + JSON.stringify(registerData));
        serverPost(getApiUrl(`/classes/${classDetails.id}/registrations`), registerData, {}, onRegisterError).then(res => {
            setRegistering(false);
            if (res) {
                setRegistrationDone(true);
                fetchClassDetails(true);
            } 
        })
    }

    const renderRegistrationForm = () => {
        return (
            <BaseForm ref={formRef} onSubmit={onRegister} onFieldChange={onFieldChange}>
                <div>
                    <span style={{ fontSize: "14px" }}>Enter your account information below. Your registrations will be stored under this email account.</span>
                    <Row>
                        <BaseForm.Input colSpan="6" type="text" name="name" label={t('forms.name')} required/>
                        <BaseForm.Input colSpan="6" type="text" name="email" label={t('forms.email')} required/>
                </Row>
                </div>
                <hr/>
                <br/>
                <ClassFormFieldsComponent form={classForm} />
                { renderPaymentMethod(classDetails) }
                { waitlistAvailableError && (
                    <div style={{ color: 'red', padding: '15px 0 20px 0', fontSize: '14px'}}>{t('classes.step_2.waitlistAvailableError')}</div>
                )}
                <Button variant="primary" type="submit">{ ((isClassFull && classDetails.allowWaitlist) || waitlistAvailableError) ? t('classes.join_waitlist') : t('classes.register')  }</Button>
               
            </BaseForm>
        )
    }

    const renderRegistration = () => {
        return (
            <Loader loading={loading}>
            {
                () =>
                    <Row>
                        <Col lg="8">
                            <div className="content-box">
                                {
                                    classDetails.bannerURL &&
                                        <div className="d-flex flex-row justify-content-center class-hero-image">
                                            <img src={classDetails.bannerURL}/>
                                        </div>
                                }
                                <div className="content-body">
                                    <h4><strong>{classDetails.name}</strong></h4>
                                    <br/>
                                    {
                                        classDetails.description &&
                                            <>
                                                <span className="hide" style={{ fontSize: "16px" }}><strong>Description:</strong></span>
                                                <div dangerouslySetInnerHTML={{__html: classDetails.description }} />
                                            </>
                                    }
                                    <br/>
                                    {
                                        classDetails.note &&
                                            <div dangerouslySetInnerHTML={{__html: classDetails.note }} />
                                    }
                                    <div className="d-block d-lg-none">
                                        { renderSessions(classDetails) }
                                        <hr/>
                                        <br/>
                                    </div>
                                    <div className="d-block d-lg-none">
                                        { renderPricingSummary(classDetails) }
                                        <hr/>
                                    </div>
                                    <br/>
                                    <span><strong>Registration Details</strong></span><br/>
                                    {
                                        (isClassFull && !classDetails.allowWaitlist) ?
                                            <Alert variant="info">This class is currently full. No further registration is allowed.</Alert>
                                        : renderRegistrationForm()
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" className="d-none d-lg-block">
                            <div className="class-wrapper">
                                { renderSessions(classDetails) }
                                <br/>
                                { renderPricingSummary(classDetails) }
                            </div>
                        </Col>
                    </Row>
            }
            </Loader>
        );
    }

    const renderRegistrationDone = () => {
        return (
            <Row>
                <Col md={{ span: 8, offset: 2}}>
                    <div className="d-flex flex-column gap-12">
                        <h1 className="d-flex justify-content-center">Registration Successful</h1>
                        <Alert className="d-flex justify-content-center" variant="info">
                            <h5>Details will be sent to your email.</h5>
                        </Alert>
                        <div className="d-flex justify-content-center">
                            <Button variant="success" onClick={() => setRegistrationDone(false)}>Submit Another Form</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }

    const renderClass = () => {
        return (
            <Loader loading={loading}>
            {
                classNotFound ?
                    <ErrorComponent title="Oops! Class registration not found."/>
                : renderRegistration()
            }
            </Loader>
        )
    }

    return (
        <ClassesContainer>
            <Link to={`/${facilityLink}/registration/step-1`}>
                <Button variant="alink"><i className="fa fa-arrow-left"/>&nbsp;Back to classes</Button>
            </Link>
            {
                registrationDone ?
                    renderRegistrationDone()
                : renderClass()
            }
            <SignupModal show={showSignupModal} onClose={setShowSignupModal} defaultToLogin={true} onSuccess={onSignupSuccess} />
            <LoadingModal show={registering} body={t("classes.step_2.loading")} />
        </ClassesContainer>
    )
}

export default ClassesStep2;
