import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import { useEffect, useState, useRef, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import Notification from '../../components/Notification';
import BookingSearchEmailModal from '../../components/modals/BookingSearchEmailModal';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';
import { serverFetch, serverPost } from '../../helpers/server';
import { downloadBlob, dateRangeClassCheck, currencyFormat, BaseContext, isNotAdmin } from '../../helpers/common';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { dayOptions, timeOptions } from '../../helpers/input'
import { Table, InputGroup, Form, Button, Row, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
const _ = require("lodash");

function Search() {
    const { getApiUrl, getFacilityName, isLoggedIn, userInfo } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');

    const [showBookingSearchEmailModal, setShowBookingSearchEmailModal] = useState(false);

    const [bookings, setBookings] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [venues, setVenues] = useState([]);
    const [numberOfRows, setNumberOfRows] = useState(1);
    const [selectedVenues, setSelectedVenues] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedStartDates, setSelectedStartDates] = useState([null]);
    const [selectedEndDates, setSelectedEndDates] = useState(null);
    const [selectedStartTimes, setSelectedStartTimes] = useState(null);
    const [selectedEndTimes, setSelectedEndTimes] = useState(null);
    const [checkedBookings, setCheckedBookings] = useState([]);
    const [emailData, setEmailData] = useState(null);

    const [searchEmailEnabled, setSearchEmailEnabled] = useState(false);
    const selectedStartDatesRef = useRef();
    selectedStartDatesRef.current = selectedStartDates;
    const selectedEndDatesRef = useRef();
    selectedEndDatesRef.current = selectedEndDates;

    useEffect(() => {
        document.title = "Search - " + getFacilityName();
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl('/venues')).then((res) => {
            let filteredVenues = res;
            if (!isLoggedIn || isNotAdmin(userInfo)) {
                filteredVenues = _.filter(res, (r) => !r.hideCalendar);
            }
            setVenues(filteredVenues);
            setSelectedVenues(_.range(numberOfRows).map((r) => _.map(filteredVenues, (v, i) => v.id)));
        })

        setSelectedDays(_.range(numberOfRows).map((r) => _.map(dayOptions(t), (d, i) => d.id)));
        setSelectedStartDates(_.range(numberOfRows).map((r) => moment().format("YYYY-MM-DD")));
        setSelectedEndDates(_.range(numberOfRows).map((r) => moment().add(3, 'days').format("YYYY-MM-DD")));
        setSelectedStartTimes(_.range(numberOfRows).map((r) => "06:00:00"));
        setSelectedEndTimes(_.range(numberOfRows).map((r) => "18:00:00"));
    }, [facilityLink]);

    useEffect(() => {
        for (let i=0; i < numberOfRows; i++) {
            $('#dateStart_' + (i+1)).datepicker( "destroy" );
            $('#dateStart_' + (i+1)).datepicker({
                defaultDate: 0,
                minDate: 0,
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, selectedStartDatesRef.current[i], selectedEndDatesRef.current[i]),
                onSelect: (value) => {
                    $('#dateStart_' + (i+1)).datepicker( "hide" );
                    const newSelectedStartDates = [...selectedStartDates];
                    newSelectedStartDates[i] = value;
                    setSelectedStartDates(newSelectedStartDates);
                    setTimeout(() => $('#dateEnd_' + (i+1)).datepicker( "show" ), 100);
                }
            });
            $('#dateEnd_' + (i+1)).datepicker( "destroy" );
            $('#dateEnd_' + (i+1)).datepicker({
                defaultDate: "+1w",
                minDate: 0,
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, selectedStartDatesRef.current[i], selectedEndDatesRef.current[i]),
                onSelect: (value) => {
                    $('#dateEnd_' + (i+1)).datepicker( "hide" );
                    const newSelectedEndDates = [...selectedEndDates];
                    newSelectedEndDates[i] = value;
                    setSelectedEndDates(newSelectedEndDates);
                }
            });
        }
    }, [selectedStartDates, selectedEndDates]);

    useEffect(() => {
        onSearch();
    }, [selectedDays, selectedVenues])

    const downloadReport = (format, filename) => {
        if (_.isEmpty(checkedBookings)) {
            Notification.Show(t('booking.search.error_choose_one'));
            return;
        }
        const filteredBookings = _.filter(bookings, (b, i) => checkedBookings.includes(getRowId(b)));
        const url = getApiUrl('/bookings/availability_search/export');
        const data = {
            "format": format,
            forExport: {
                "items": filteredBookings
            }
        };
        serverPost(url, data, { noJson: true }).then((res) => {
            downloadBlob(res, filename);
        });
    }

    const excelReport = () => {
        downloadReport("csv", 'Availability-Report.csv');
    }

    const pdfReport = () => {
        downloadReport("pdf", 'Availability-Report.pdf');
    }

    const emailReport = () => {
        if (_.isEmpty(checkedBookings)) {
            Notification.Show(t('booking.search.error_choose_one'));
            return;
        }
        const filteredBookings = _.filter(bookings, (b, i) => checkedBookings.includes(getRowId(b)));
        setEmailData(filteredBookings);
        setShowBookingSearchEmailModal(true);
    }

    const onSearch = (restart = true) => {
        if (selectedVenues.length === 0) {
            return;
        }
        for (var vs of selectedVenues) {
            if (_.isEmpty(vs)) {
                Notification.Show(t('booking.search.error_select_venue'));
                return;
            }
        }
        for (var ds of selectedDays) {
            if (_.isEmpty(ds)) {
                Notification.Show(t('booking.search.error_select_day_of_week'));
                return;
            }
        }
        const searchUrl = getApiUrl('/bookings/availability_search');
        let criteria = [];
        for (let r = 0; r < numberOfRows; r++) {
            criteria.push({
                venueIds: selectedVenues[r],
                dayOfWeek: selectedDays[r],
                startDate: selectedStartDates[r],
                endDate: selectedEndDates[r],
                startTime: selectedStartTimes[r],
                endTime: selectedEndTimes[r]
            })
        }
        const data = {
            items: criteria,
        };
        if (restart) {
            setBookings([]);
            setHasMore(true);
        }
        serverPost(searchUrl, data).then((res) => {
            if (restart) {
                setBookings(res);
            } else {
                setBookings(_.concat(bookings, res))
            }
            setHasMore(false);
        })
    }

    const addSearchRow = () => {
        setSelectedVenues([...selectedVenues].concat([_.map(venues, (v, i) => v.id)]));
        setSelectedDays([...selectedDays].concat([_.map(dayOptions(t), (d, i) => d.id)]));
        setSelectedStartDates([...selectedStartDates].concat([moment().format("YYYY-MM-DD")]));
        setSelectedEndDates([...selectedEndDates].concat([moment().add(3, 'days').format("YYYY-MM-DD")]));
        setSelectedStartTimes([...selectedStartTimes].concat(["06:00:00"]));
        setSelectedEndTimes([...selectedEndTimes].concat(["18:00:00"]));

        setNumberOfRows(numberOfRows + 1);
    }

    const deleteSearchRow = (r) => {
        const newSelectedVenues = [...selectedVenues];
        newSelectedVenues.splice(r, 1);
        setSelectedVenues(newSelectedVenues);

        const newSelectedDays = [...selectedDays];
        newSelectedDays.splice(r, 1);
        setSelectedDays(newSelectedDays);

        const newSelectedStartDates = [...selectedStartDates];
        newSelectedStartDates.splice(r, 1);
        setSelectedStartDates(newSelectedStartDates);

        const newSelectedEndDates = [...selectedEndDates];
        newSelectedEndDates.splice(r, 1);
        setSelectedEndDates(newSelectedEndDates);

        const newSelectedStartTimes = [...selectedStartTimes];
        newSelectedStartTimes.splice(r, 1);
        setSelectedStartTimes(newSelectedStartTimes);

        const newSelectedEndTimes = [...selectedEndTimes];
        newSelectedEndTimes.splice(r, 1);
        setSelectedEndTimes(newSelectedEndTimes);

        setNumberOfRows(numberOfRows - 1);
    }

    const updateSelectedVenues = (r, values) => {
        const newSelectedVenues = [...selectedVenues];
        newSelectedVenues[r] = values;
        setSelectedVenues(newSelectedVenues);
    }

    const updateSelectedDays = (r, values) => {
        const newSelectedDays = [...selectedDays];
        newSelectedDays[r] = values;
        setSelectedDays(newSelectedDays);
    }

    const onTimeChange = (r, name, value) => {
        if (_.isNil(selectedStartTimes)) {
            return;
        }
        if (name.startsWith('timeStart')) {
            const newSelectedStartTimes = [...selectedStartTimes];
            newSelectedStartTimes[r] = value;
            setSelectedStartTimes(newSelectedStartTimes);
        } else {
            const newSelectedEndTimes = [...selectedEndTimes];
            newSelectedEndTimes[r] = value;
            setSelectedEndTimes(newSelectedEndTimes);
        }
    }

    const updateChecked = (event) => {
        if (event.target.value === "checkall") {
            let newCheckedBookings = [];
            if (event.target.checked) {
                newCheckedBookings = _.map(bookings, (b, i) => getRowId(b));
                newCheckedBookings.push("checkall");
            }
            setCheckedBookings(newCheckedBookings);
        } else {
            const newCheckedBookings = [...checkedBookings];
            if (event.target.checked) {
                newCheckedBookings.push(event.target.value);
            } else {
                let index = newCheckedBookings.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedBookings.splice(index, 1);
                }
                index = newCheckedBookings.indexOf("checkall");
                if (index > -1) {
                    newCheckedBookings.splice(index, 1);
                }
            }
            setCheckedBookings(newCheckedBookings);
        }
    }

    function getRowId(booking) {
        return booking.venueId + booking.start;
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "booking-search")}/>
            <div className="content-box">
                <div className="content-body">
                    <div className="d-flex flex-row gap-6">
                        <Button variant="success" onClick={() => excelReport()}><i className="fa fa-table"></i> {t("booking.search.excel_export")}</Button>
                        <Button variant="info" onClick={() => pdfReport()}><i className="fa fa-print"></i> {t("booking.search.pdf_export")}</Button>
                        <div className="flex-grow-1 d-flex justify-content-end">
                            <Button variant="outline-primary" onClick={() => emailReport()}><i className="fa fa-envelope"></i> {t("common.email")}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    {
                        _.range(numberOfRows).map((r, i) =>
                            <Row key={i}>
                                <div className="col-md-2">
                                    <MultiSelectDropdown label={t("common.venues")} items={venues} selectedItems={selectedVenues[r]} onItemsChange={(values) => updateSelectedVenues(r, values)} labelField="name" />
                                </div>
                                <div className="col-md-2">
                                    <MultiSelectDropdown label={t("booking.search.days")} items={dayOptions(t)} selectedItems={selectedDays[r]} onItemsChange={(values) => updateSelectedDays(r, values)} />
                                </div>
                                <div className="col-md-3">
                                    <BaseForm initialFormFields={ {'dateStart[]': selectedStartDates && selectedStartDates[r], 'dateEnd[]': selectedEndDates && selectedEndDates[r]} }>
                                        <InputGroup>
                                            <Form.Label>{t("common.start_date")}</Form.Label>
                                            <BaseForm.Control type="text" className="dateStartClass" name="dateStart[]" id={"dateStart_" + (r + 1)} />
                                            <InputGroup.Text>{t("common.to")}</InputGroup.Text>
                                            <Form.Label>{t("common.end_date")}</Form.Label>
                                            <BaseForm.Control type="text" className="dateEndClass" name="dateEnd[]" id={"dateEnd_" + (r + 1)} />
                                        </InputGroup>
                                    </BaseForm>
                                </div>
                                <div className="col-md-3">
                                    <BaseForm onFieldChange={(name, value) => onTimeChange(r, name, value)} initialFormFields={ {'timeStart[]': selectedStartTimes && selectedStartTimes[r], 'timeEnd[]': selectedEndTimes && selectedEndTimes[r]} }>
                                        <InputGroup className="dropdown">
                                            <BaseForm.SingleSelect name={"timeStart[]"} options={timeOptions()} showAll={false} label={t("common.start_date")} />
                                            <InputGroup.Text>{t("common.to")}</InputGroup.Text>
                                            <BaseForm.SingleSelect name={"timeEnd[]"} options={timeOptions()} showAll={false} label={t("common.end_date")} />
                                        </InputGroup>
                                    </BaseForm>
                                </div>
                                <div className="col-md-2 d-flex align-items-center">
                                    {
                                        r === 0 ?
                                            <>
                                            <Button variant="success" onClick={() => addSearchRow()}><i className="fa fa-plus"/></Button>
                                            <Button variant="info" onClick={() => onSearch()}>{t("booking.search.search")}</Button>
                                            </>
                                        : <Button variant="danger" onClick={() => deleteSearchRow(r)}><i className="fa fa-trash"/></Button>
                                    }
                                </div>
                            </Row>
                        )
                    }
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                <Alert variant="info">{t("booking.search.info")}</Alert>
                    <InfiniteScroll
                        dataLength={bookings.length}
                        next={() => onSearch(false)}
                        hasMore={hasMore}
                        scrollableTarget="content_wrapper"
                    >
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedBookings.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor="checkall"/>
                                        </div>
                                    </th>
                                    <th>{t('common.venue')}</th>
                                    <th>{t('common.date')}</th>
                                    <th>{t('common.time')}</th>
                                    <th className="text-end">{t('booking.search.rate_hr')}</th>
                                    <th className="text-end">{t('common.total')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.map(bookings, (booking, i) =>
                                        <tr key={i}>
                                            <td>
                                                <div className="checkbox check-success inline">
                                                    <input type="checkbox" className="" value={getRowId(booking)} id={getRowId(booking)} name="delete[]"  checked={checkedBookings.includes(getRowId(booking))} onChange={ (event) => updateChecked(event) }/>
                                                    <label htmlFor={getRowId(booking)}/>
                                                </div>
                                            </td>
                                            <td>{ booking.venueName }</td>
                                            <td>{ moment(booking.start).format("ddd, MMM DD, YYYY") }</td>
                                            <td>{ moment(booking.start).format("h:mma") + " - " + moment(booking.end).format("h:mma") }</td>
                                            <td className="text-end"><span className="label">{ currencyFormat(booking.rate) }</span></td>
                                            <td className="text-end"><span className="label label-success">{ currencyFormat(booking.total) }</span></td>
                                        </tr>
                                    )
                                }
                                {
                                    hasMore ?
                                        <tr>
                                            <td colSpan={6} className="center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                    : <tr>
                                            <td colSpan={6} className="center">
                                                <div className="label">{t("booking.search.end_of_bookings")}</div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </InfiniteScroll>
                </div>
            </div>
            <BookingSearchEmailModal show={showBookingSearchEmailModal} onClose={setShowBookingSearchEmailModal} emailData={emailData} />
        </BaseContainer>
    );
}

export default Search;
