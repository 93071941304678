import '../App.scss';
import { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react';
import { serverFetch } from '../helpers/server';
import {BaseContext, getAccessibleVenues, getEventTypesForVenue} from '../helpers/common';
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import BaseForm, { BaseFormContext } from './BaseForm';
import DeleteButton from './DeleteButton';
import { useTranslation } from 'react-i18next';
import { dayOptions, timeOptions } from '../helpers/input'
const _ = require("lodash");

const BookingRow = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        onFieldChange(name, value) {
            onFieldChange(name, value);
        },
    }));

    const { getApiUrl, settings, userInfo } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [venues, setVenues] = useState([]);
    const [selectedVenueId, setSelectedVenueId] = useState(null);
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [rateType, setRateType] = useState("standard");
    const [eventTypes, setEventTypes] = useState([]);
    const [prefix, setPrefix] = useState("");
    const [showAllTimes, setShowAllTimes] = useState(false);

    useEffect(() => {
        setPrefix(props.prefix || "");
    }, [props.prefix]);

    useEffect(() => {
        serverFetch(getApiUrl('/venues')).then((res) => {
            let vs = getAccessibleVenues(res, userInfo);
            setVenues(vs);
        })
    }, []);

    useEffect(() => {
        setShowAllTimes(props.showAllTimes || false);
    }, [props.showAllTimes])

    useEffect(() => {
        setSelectedVenue(_.find(venues, v => String(v.id) === String(selectedVenueId)));
    }, [selectedVenueId, venues]);

    useEffect(() => {
        setEventTypes(props.eventTypes);
    }, [props.eventTypes]);

    const onFieldChange = (name, value) => {
        if (name === (prefix + "venueId")) {
            if (String(selectedVenueId) !== String(value) && selectedVenueId !== null) {
                if (props.resetEventTypes) {
                    props.resetEventTypes(prefix, String(value));
                }
            }
            setSelectedVenueId(value);
        } else if (name === (prefix + "rateType")) {
            setRateType(value);
        }
    }

    const venueEventTypes = getEventTypesForVenue(eventTypes, selectedVenueId);
    const venueOptions = _.map(venues, (venue, i) => { return { 'value': venue.id, 'label': venue.name } });
    let eventTypeOptions = _.map(venueEventTypes, (type, i) => { return { 'value': type.id, 'label': type.eventType } });
    if (settings.isPartnerFacility && !props.isAdminBooking) {
        eventTypeOptions.unshift({ value: null, label: "Select one" });
    }

    const paddingOptions = [
        { 'value': 0, 'label': '0' },
        { 'value': 5, 'label': '5' },
        { 'value': 10, 'label': '10' },
        { 'value': 15, 'label': '15' },
        { 'value': 20, 'label': '20' },
        { 'value': 30, 'label': '30' },
        { 'value': 45, 'label': '45' },
        { 'value': 60, 'label': '60' },
    ];

    let slotMinutes = 15;
    if (showAllTimes) {
        slotMinutes = 5;
    } else if (selectedVenue) {
        slotMinutes = selectedVenue.slotMinutes;
    }

    const showPadding = selectedVenue && selectedVenue.isPadding && (props.isAdminBooking || selectedVenue.paddingUser);
    const showActions = !props.disabled && (props.showAddNew || props.canDelete) && props.isAdminBooking;

    return (
        <>
            <Row>
                <BaseForm.Input
                    colSpan="3" name={prefix + "venueId"} label={t('forms.venue')} type="select" options={venueOptions}
                    disabled={props.disabled} readOnly={!props.isAdminBooking} showSearch={venueOptions.length > 5}
                />
                <Col md="3">
                    <BaseForm.InputGroup disabled={props.disabled} >
                        <BaseForm.SingleSelect name={prefix + "startTime"} options={timeOptions(slotMinutes)} label={t('forms.start')} disabled={props.disabled} validations={{ required: true, isMultipleOfTime: slotMinutes }}/>
                        <BaseForm.Divider/>
                        <BaseForm.SingleSelect name={prefix + "endTime"} options={timeOptions(slotMinutes)} label={t('forms.end')} disabled={props.disabled} validations={{ required: true, isMultipleOfTime: slotMinutes }}/>
                    </BaseForm.InputGroup>
                </Col>
                <Col md="3">
                    <BaseForm.InputGroup disabled={props.disabled} readOnly={!props.isAdminBooking}>
                        <BaseForm.Number name={prefix + "rate"} label={t('forms.rate')} readOnly={props.usingCustomRate || rateType === "standard"} step="0.01" min="0" validations={{ min: 0, required: true }}/>
                        {
                            props.isAdminBooking &&
                                <>
                                    <BaseForm.Divider/>
                                    <BaseForm.SingleSelectFlat name={prefix + "rateType"} />
                                </>
                        }
                    </BaseForm.InputGroup>
                </Col>
                <BaseForm.Input colSpan={showPadding ? "2": "3"} type="number" name={prefix + "taxRatePercent"} label={`${settings.taxLabel1} (${settings.isExclusive ? "EX": "IN"})`} rightContent="%" disabled={props.disabled} readOnly={!props.isAdminBooking} required />
                {
                    showPadding ?
                        <BaseForm.Input colSpan="1" type="select" name={prefix + "bPadding"} label={selectedVenue ? selectedVenue.paddingName || "" : "Pad"} options={paddingOptions} disabled={props.disabled} showSearch={false} className="tight" />
                    : <></>
                }
            </Row>
            <Row>
                <BaseForm.Input colSpan="3" name={prefix + "eventTypeId"} label={t('forms.event_type')} type="select" options={eventTypeOptions} disabled={props.disabled} showSearch={eventTypeOptions.length > 5} required />
                <BaseForm.Input colSpan="3" name={prefix + "eventName"} label={t('forms.event_name')} type="text" disabled={props.disabled} />
                <BaseForm.Input colSpan={showActions ? "5": "6"} name={prefix + "description"} label={t('forms.description')} type="textarea" disabled={props.disabled} placeholder={t('forms.description.placeholder')}/>
                {
                    !props.disabled && props.showAddNew && props.isAdminBooking &&
                        <div className="col-md-1 d-flex align-items-center justify-content-center">
                            <Button variant="success" onClick={props.onAddNew}><i className="fa fa-plus fa-small"/></Button>
                        </div>
                }
                {
                    !props.disabled && props.canDelete && props.isAdminBooking &&
                        <div className="col-md-1 d-flex align-items-center justify-content-center">
                            <DeleteButton size="md" onDelete={props.onDeleteRow}></DeleteButton>
                        </div>
                }
            </Row>
        </>
    );
})

export default BookingRow;
