import '../App.scss';
import $ from 'jquery';
import React from 'react';
import { useEffect, useState, useRef, useContext } from 'react';
import AddBookingsToInvoiceModal from '../components/modals/AddBookingsToInvoiceModal';
import AddBookingsToAgreementModal from '../components/modals/AddBookingsToAgreementModal';
import AddOrEditBookingModal from '../components/modals/AddOrEditBookingModal';
import AddOrEditUserBookingModal from '../components/modals/AddOrEditUserBookingModal';
import CancelBookingModal from '../components/modals/CancelBookingModal';
import EditCustomerProfileModal from '../components/modals/EditCustomerProfileModal';
import SignupModal from '../components/modals/SignupModal';
import CalendarWrapper from '../components/CalendarWrapper';
import BaseContainer from '../components/Container';
import SingleSelectDropdown from '../components/SingleSelectDropdown';
import Notification from '../components/Notification';
import { getInvoiceCreationUrl, getAgreementCreationUrl, serverFetch, serverPost } from '../helpers/server';
import {
    conciseTimeDisplay, getFilteredPaymentMethods, hasAccess, isPartialOrFullAdmin, isNotAdmin,
    getAccessibleVenues, GetShortedName
} from '../helpers/common';
import { currencyFormat, BaseContext } from '../helpers/common';
import {Row, Col, Button, ButtonGroup, OverlayTrigger, Popover, Image} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import moment from 'moment';
import classnames from 'classnames';
import BookingPaymentModal from "../components/modals/BookingPaymentModal";
import {setCategory} from "../helpers/storage";
import BaseOverlayTrigger from "../components/BaseOverlayTrigger";
const _ = require('lodash');

function CalendarView(props) {
    const { userInfo, isLoggedIn, settings, getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink, categoryLink } = useParams();
    const calendarRef = useRef(null);
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    const [ searchParams ] = useSearchParams();
    let lid = searchParams.get('lid');
    let eid = searchParams.get('eid');
    let bid = searchParams.get('bid');

    let m = searchParams.get('m');
    let defaultDayView = searchParams.get('v');
    let defaultView = "multi-venue";
    if (settings.customDefaultView === 0) {
        defaultView = "single";
    } else if (settings.customDefaultView === 1 && isPartialOrFullAdmin(userInfo)) {
        defaultView = "single";
    }
    if (m === String("0")) { defaultView = "single" }
    if (m === String("1")) { defaultView = "multi-venue" }
    if (m === String("2")) { defaultView = "multi-day" }
    if (m === String("3")) { defaultView = "timeline" }

    const [showAddOrEditBookingModal, setShowAddOrEditBookingModal] = useState(false);
    const [showAddOrEditUserBookingModal, setShowAddOrEditUserBookingModal] = useState(false);
    const [showCancelBookingModal, setShowCancelBookingModal] = useState(false);
    const [showEditBookingModal, setShowEditBookingModal] = useState(false);
    const [bookingForPaymentModal, setBookingForPaymentModal] = useState(null);
    const [showBookingPaymentModal, setShowBookingPaymentModal] = useState(false);
    const [showAddBookingsToInvoiceModal, setShowAddBookingsToInvoiceModal] = useState(false);
    const [bookingToAddToInvoice, setBookingToAddToInvoice] = useState(null);
    const [showAddBookingsToAgreementModal, setShowAddBookingsToAgreementModal] = useState(false);
    const [bookingToAddToAgreement, setBookingToAddToAgreement] = useState(null);
    const [showEditCustomerProfileModal, setShowEditCustomerProfileModal] = useState(false);
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [userIdToEdit, setUserIdToEdit] = useState(false);
    const [bookingToEdit, setBookingToEdit] = useState(null);
    const [selectionInfo, setSelectionInfo] = useState(null);
    const [newPaymentMethod, setNewPaymentMethod] = useState(null);
    const [groupInvoices, setGroupInvoices] = useState([]);
    const [groupAgreements, setGroupAgreements] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [loadingVenues, setLoadingVenues] = useState(true);
    const [venues, setVenues] = useState([]);
    const [dayStart, setDayStart] = useState(null);
    const [dayEnd, setDayEnd] = useState(null);
    const [maxEnd, setMaxEnd] = useState(null);
    const [resources, setResources] = useState([]);
    const [selectedVenues, setSelectedVenues] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [copiedBooking, setCopiedBooking] = useState(null);
    const canUpdateCustomers = hasAccess("customers", userInfo, "update")

    useEffect(() => {
        document.title = "Calendar - " + getFacilityName();
    }, []);

    useEffect(() => {
        if (categoryLink) {
            if (categoryLink === "all") {
                setCategory(facilityLink, "all");
                window.location = `/${facilityLink}`;
            } else {
                serverFetch(getApiUrl(`/venue_categories`), { skipAddingCategory: true }).then((res) => {
                    const selectedCategory = _.find(res, (r) => r.categoryLink === categoryLink);
                    if (selectedCategory) {
                        setCategory(facilityLink, selectedCategory.id);
                        window.location = `/${facilityLink}`;
                    }
                });
            }
        }
    }, [categoryLink]);

    useEffect(() => {
        lid = searchParams.get('lid');
        eid = searchParams.get('eid');
        bid = searchParams.get('bid');
    }, [searchParams]);

    useEffect(() => {
        if (lid) {
            const data = {
                'ids': [lid]
            }
            serverPost(getApiUrl(`/bookings/batch_get`), data).then((res) => {
                if (res) {
                    const booking = res[0];
                    calendarRef.current.gotoDate(booking.startTimeLocal);
                    calendarRef.current.gotoResource(booking.venueId);
                }
            });
        } else if (eid) {
            const data = {
                'ids': [eid]
            }
            serverPost(getApiUrl(`/bookings/batch_get`), data).then((res) => {
                if (res) {
                    const booking = res[0];
                    calendarRef.current.gotoDate(booking.startTimeLocal);
                    calendarRef.current.gotoResource(booking.venueId);
                    processBooking(booking, res)
                    onEditBooking({ ...booking, extendedProps: { ...booking }});
                }
            });
        } else if (props.handleRequestPay && bid) {
            serverFetch(getApiUrl(`/bookings/${bid}`)).then((res) => {
                if (res) {
                    setBookingForPaymentModal(res);
                    setShowBookingPaymentModal(true);
                }
            });
        } else {
            const defaultDate = localStorage.getItem("calendar_default_date");
            if (defaultDate) {
                calendarRef.current.gotoDate(defaultDate);
            }
        }
    }, [lid, eid]);

    useEffect(() => {
        serverFetch(getApiUrl('/venues')).then((res) => {
            let vs = getAccessibleVenues(res, userInfo);
            if (!isLoggedIn || isNotAdmin(userInfo)) {
                vs = _.filter(vs, (v) => !v.hideCalendar);
            }
            _.each(vs, (v) => {
                let endParts = v.dayEnd.split(":");
                if (parseInt(v.dayStart.split(":")[0]) > parseInt(endParts[0])) {
                    endParts[0] = String(parseInt(endParts[0]) + 24);
                }
                v.dStart = v.dayStart;
                v.dEnd = endParts.join(":");
            });
            setVenues(vs);
            setLoadingVenues(false);

            const minDayStart = _.minBy(vs, (s) => s.dStart);
            const maxDayEnd = _.maxBy(vs, (s) => s.dEnd);
            setDayStart(minDayStart ? minDayStart.dStart : null);
            setDayEnd(maxDayEnd ? maxDayEnd.dEnd : null);
        })

        if (isLoggedIn && isPartialOrFullAdmin(userInfo)) {
            serverFetch(getApiUrl('/payment_methods')).then((res) => {
                setPaymentMethods(res);
            });
        }
    }, []);

    useEffect(() => {
        if (_.isNil(startDate) || _.isNil(endDate) || _.isEmpty(selectedVenues)) {
            return;
        }
        fetchData();
    }, [startDate, endDate, selectedVenues]);

    const fetchData = () => {
        onSearch();
    }

    const processBooking = (booking, allBookings=[]) => {
        // Full Calendar expects the "groupId" to be something else so we save it into another variable. Don't remove/rename.
        booking.groupId1 = booking.groupId;
        booking.groupId = booking.id;
        booking.bid = booking.id;
        booking.start = booking.startTimeLocal;
        booking.end = booking.endTimeLocal;
        booking.bPadding = booking.bpadding;
        if (!_.isEmpty(venues)) {
            const venue = _.find(venues, (v) => String(v.id) === String(booking.venueId));
            if (venue && String(venue.isPadding) === String(2)) {
                booking.end = moment(booking.endTimeLocal).add(booking.bpadding, "minutes").format();
            }
        }
        booking.resourceId = booking.venueId;
        booking.editable = isLoggedIn && hasAccess("booking", userInfo, "update", booking.venueId);
        if ((booking.bookingType === 1) || (booking.blockedById !== null)) {
            booking.title = _.map(booking.bookingGroups, (u) => u.name).join("\r\n");
        }
        booking.textColor = "#3d3d3d";
        booking.displayEventTime = false;
        booking.sTime = booking.startTimeLocal;
        booking.eTime = booking.endTimeLocal;
    }

    const onSearch = () => {
        const searchUrl = getApiUrl('/bookings/get_for_calendar');
        const data = {
            venueIds: selectedVenues,
            startTimeLocal: moment(startDate).utcOffset(0, true).format(),
            endTimeLocal: moment(endDate).utcOffset(0, true).format(),
        };
        setBookings([]);
        serverPost(searchUrl, data).then((res) => {
            const books = res;
            _.each(books, (b) => {
                processBooking(b, books);
            })
            setBookings(books);
        })
    }

    const onAddToInvoice = (booking) => {
        // Close the popover
        document.body.click();
        // Don't use booking.groupId since that is nullified for the Calendar object
        const groupId = booking.extendedProps.groupId1;
        serverFetch(getApiUrl('/invoices', { groupId })).then((res) => {
            if (res) {
                if (res.length == 0) {
                    navigate(getInvoiceCreationUrl(facilityLink, groupId, [booking.id]));
                } else {
                    setGroupInvoices(res);
                    setBookingToAddToInvoice(booking);
                    setShowAddBookingsToInvoiceModal(true);
                }
            }
        })
    }

    const showCustomerProfile = (event, user) => {
        document.body.click();
        event.preventDefault();
        setShowEditCustomerProfileModal(true);
        setUserIdToEdit(user.groupId);
    }

    const onAddToAgreement = (booking) => {
        // Close the popover
        document.body.click();
        // Don't use booking.groupId since that is nullified for the Calendar object
        const groupId = booking.extendedProps.groupId1;
        serverFetch(getApiUrl('/agreements', { groupId })).then((res) => {
            if (res) {
                if (res.length == 0) {
                    navigate(getAgreementCreationUrl(facilityLink, groupId, [booking.id]));
                } else {
                    setGroupAgreements(res);
                    setBookingToAddToAgreement(booking);
                    setShowAddBookingsToAgreementModal(true);
                }
            }
        })
    }

    const onCancelBookingInitiated = (bookingId) => {
        document.body.click();
        const booking = _.find(bookings, (b) => String(b.id) === bookingId);
        setBookingToEdit(booking);
        setShowCancelBookingModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowAddBookingsToInvoiceModal(false);
        setShowAddBookingsToAgreementModal(false);
        setShowAddOrEditBookingModal(false);
        setShowAddOrEditUserBookingModal(false);
        setShowCancelBookingModal(false);
        setShowEditCustomerProfileModal(false);
        setGroupInvoices([]);

        setCopiedBooking(null);
        fetchData();
        calendarRef.current.clearSelection();
    }

    const onDatesSet = (info) => {
        setEndDate(info.endStr);
        setStartDate(info.startStr);
    }

    const onSelect = (info) => {
        const selectedVenue = _.find(venues, (v) => String(v.id) === String(info.resource.id));
        setSelectionInfo({
            start: info.start,
            end: info.end,
            venue: selectedVenue
        });
        if (isPartialOrFullAdmin(userInfo) && !hasAccess("booking", userInfo, "update", info.resource.id)) {
            Notification.Show(t('calendar.view.error.no_permission_to_edit'));
        } else if (!isLoggedIn || !hasAccess("booking", userInfo, "update", info.resource.id)) {
            const isInPast = moment(info.start).isBefore(moment());
            const hoursFromNow = moment(info.start).diff(moment(), 'hours');
            const daysFromNow = moment(info.start).diff(moment(), 'days');
            const startTime = moment(info.start).format('HH:mm:ss');
            const endTime = moment(info.end).format('HH:mm:ss');
            const isAfterAdvanceBookingDate = selectedVenue.useAdvanceBookingDate ? moment(info.start).isSameOrAfter(moment(selectedVenue.advanceBookingDate).endOf('day')) : false;
            const isAfterAdvanceBookingTime = selectedVenue.advanceBooking > 0 ? daysFromNow >= (selectedVenue.advanceBooking || 1): false;
            const isAfterNoBookStart = startTime >= selectedVenue.noBookStart;
            const isBeforeNoBookEnd = endTime <= selectedVenue.noBookEnd;

            const filteredBookings = _.filter(bookings, (b) => {
                return String(b.venueId) === String(info.resource.id) &&
                    !(moment(info.start).utcOffset(0, true) >= moment(b.end).utcOffset(0, true) ||
                    moment(info.end).utcOffset(0, true) <= moment(b.startTimeLocal).utcOffset(0, true))
            });
            const venue = _.find(venues, (v) => String(v.id) === String(info.resource.id));
            let extendedFilteredBookings = [];
            if (venue) {
                const proposedStart = moment(info.start).utcOffset(0, true);
                const proposedEnd = moment(info.end).utcOffset(0, true)
                extendedFilteredBookings = _.filter(bookings, (b) => {
                    const start = moment(info.start);
                    start.utcOffset(0, true);
                    const bookingStart = moment(b.startTimeLocal).utcOffset(0, true);
                    const bookingEnd = moment(b.endTimeLocal).utcOffset(0, true);
                    const isSameVenue = String(b.venueId) === String(info.resource.id);
                    const isStartTimeOk = proposedStart.isSame(bookingEnd) || (proposedStart >= (bookingEnd + 1000*60*venue.minBookingGap));
                    const isEndTimeOk = proposedEnd.isSame(bookingStart) || (proposedEnd <= (bookingStart - 1000*60*venue.minBookingGap));
                    return isSameVenue && !(isStartTimeOk || isEndTimeOk);
                });
            }

            if (isInPast) {
                Notification.Show(t('calendar.view.error.in_the_past'));
                calendarRef.current.clearSelection();
            } else if ((isAfterAdvanceBookingDate || isAfterAdvanceBookingTime) &&
                selectedVenue.allowBookingRequest !== "afterAdvance" &&
                selectedVenue.allowBookingRequest !== "outsideWithinAdvance") {
                let advanceBookingMessage = t('calendar.view.error.advance_booking_days', { advanceBookingDays: selectedVenue.advanceBooking });
                if (selectedVenue.advanceBookingMessage) {
                    advanceBookingMessage = selectedVenue.advanceBookingMessage;
                } else if (selectedVenue.useAdvanceBookingDate) {
                    advanceBookingMessage = t('calendar.view.error.advance_booking', { advanceBookingDate: selectedVenue.advanceBookingDate });
                }
                Notification.Show(advanceBookingMessage);
                calendarRef.current.clearSelection();
            } else if (selectedVenue.allowBookingRequest !== "outsideWithinAdvance" &&
                (selectedVenue.noBookingWithin >= 0 && selectedVenue.noBookingWithin > hoursFromNow) &&
                !(isAfterNoBookStart && isBeforeNoBookEnd)) {
                let message = t('calendar.view.error.outside_no_book', { noBookingWithin: selectedVenue.noBookingWithin, phone: settings.phone });
                if (selectedVenue.noBookMessage) {
                    message = selectedVenue.noBookMessage;
                }
                Notification.Show(message);
                calendarRef.current.clearSelection();
            } else if (selectedVenue.advanceCustom == 2) {
                if (hoursFromNow < 14 && startTime < '10:00:00') {
                    Notification.Show(`To book online for today, please select an ice time after 10:00am. Phone: ${ settings.phone }`);
                    calendarRef.current.clearSelection();
                }
            } else if (filteredBookings.length > 0) {
                Notification.Show(t('calendar.view.error.already_booked'));
            } else if (extendedFilteredBookings.length > 0) {
                Notification.Show(t('calendar.view.error.minimum_gap', { minBookingGap: venue.minBookingGap }));
            } else {
                if (!isLoggedIn) {
                    setShowSignupModal(true);
                } else {
                    setBookingToEdit(null);
                    setShowAddOrEditUserBookingModal(true);
                }
            }
        } else {
            setBookingToEdit(null);
            setShowAddOrEditBookingModal(true);
        }
    }

    const onDrop = (info) => {
        const vid = !_.isNil(info.newResource) ? parseInt(info.newResource.id): info.event.extendedProps.venueId;
        let newBookingData = {
            start: info.event.start,
            end: info.event.end,
            backgroundColor: info.event.backgroundColor,
            extendedProps: {
                ...info.event.extendedProps,
                venueId: vid
            }
        }
        if (isLoggedIn && hasAccess("booking", userInfo, "update", vid)) {
            onEditBooking(newBookingData);
        }
    }

    const onEditBooking = (booking, newMethod) => {
        document.body.click();
        setSelectionInfo(null);
        setNewPaymentMethod(newMethod || null);
        setBookingToEdit(booking);
        setShowAddOrEditBookingModal(true);
    }

    const onCopyBooking = (booking) => {
        document.body.click();
        setCopiedBooking(booking);
    }

    const onEditClass = (booking) => {
        console.log("Editing class ");
        navigate(`/${facilityLink}/class/update?id=${booking.extendedProps.classId}`);
    }

    const popover = (booking) => {
        const venue = _.find(venues, (v) => v.id === booking.extendedProps.venueId);
        const filteredPaymentMethods = getFilteredPaymentMethods(paymentMethods, settings, venue, "admin")
        const hasEditAccess = hasAccess("booking", userInfo, "update", booking.extendedProps.venueId)
        const hasViewAccess = hasAccess("booking", userInfo, "view", booking.extendedProps.venueId)
        const showIcons = isLoggedIn && isPartialOrFullAdmin(userInfo) && hasEditAccess;
        let paymentMethod = booking.extendedProps.paymentMethod;
        if (_.includes(['stripe', 'helcim', 'beanstream', 'payeezy', 'payTrace', 'bambora', 'sportspay'], paymentMethod)) {
            paymentMethod = "paidCreditOnline"
        }
        const bookingPaymentMethod = _.find(paymentMethods, (pm) => pm.paymentTag === paymentMethod);
        const isClass = String(booking.extendedProps.bookingType) === "2";
        const totalPreCost = booking.extendedProps.preCost + _.sumBy(booking.extendedProps.addons, 'preTax');
        const totalTax = booking.extendedProps.totalTax + _.sumBy(booking.extendedProps.addons, 'taxAmount');
        const totalCost = booking.extendedProps.cost + _.sumBy(booking.extendedProps.addons, 'total');
        return (
            <Popover id="popover-basic" className="booking-popover">
                <Popover.Body>
                    <Row>
                        <Col md="9" className="popover-title">
                            {
                                _.map(booking.extendedProps.bookingGroups, (group, i) =>
                                    <div className={classnames(isLoggedIn ? "group-title": null)} key={i} onClick={canUpdateCustomers ? ((event) => showCustomerProfile(event, group)): null}>
                                        <h6>{ ((booking.extendedProps.bookingType === 1) || (booking.extendedProps.blockedById !== null)) ? group.name : booking.title }</h6>
                                    </div>
                                )
                            }
                            <h6 className="subheader">{ booking.extendedProps.eventTypeName }</h6>
                        </Col>
                        <Col md="3" className="text-end">
                            <Button variant="outline-primary" size="sm" onClick={() => document.body.click()}><i className="fa fa-remove"/></Button>
                        </Col>
                        <Col md="12" className="popover-subtitle">
                            { booking.extendedProps.eventType }
                        </Col>
                        <Col md="12" className="full-row skinny">
                            {moment(booking.extendedProps.sTime).format("ddd, MMM D, YYYY h:mma") + " - " + moment(booking.extendedProps.eTime).format("h:mma")}
                        </Col>
                        {
                            showIcons &&
                                <Col md="12">
                                    <span><strong>Cost: </strong>{currencyFormat(booking.extendedProps.preCost)} + {currencyFormat(booking.extendedProps.totalTax)} {booking.extendedProps.taxLabel} = <strong>{currencyFormat(booking.extendedProps.cost)}</strong></span>
                                </Col>
                        }
                        {
                            showIcons && !_.isEmpty(booking.extendedProps.addons) &&
                                <Col md="12">
                                    {
                                        _.map(booking.extendedProps.addons, (addon, i) =>
                                            <React.Fragment key={i}>
                                                <span><strong>{addon.id} ({addon.hours}): </strong>{currencyFormat(addon.preTax)} + {currencyFormat(addon.taxAmount)} {booking.extendedProps.taxLabel} = <strong>{currencyFormat(addon.total)}</strong></span>
                                                <br/>
                                            </React.Fragment>
                                        )
                                    }
                                </Col>
                        }
                        {
                            showIcons && !_.isEmpty(booking.extendedProps.addons) &&
                                <Col md="12">
                                    <span><strong>Total Cost: </strong>{currencyFormat(totalPreCost)} + {currencyFormat(totalTax)} {booking.extendedProps.taxLabel} = <strong>{currencyFormat(totalCost)}</strong></span>
                                </Col>
                        }
                        {
                            hasViewAccess && booking.extendedProps.description &&
                                <Col md="12">
                                    <span>{booking.extendedProps.description}</span>
                                </Col>
                        }
                        {
                            hasViewAccess && booking.extendedProps.note &&
                                <Col md="12">
                                    <i className="fa fa-comment"/> {booking.extendedProps.note}
                                </Col>
                        }
                        {
                            showIcons && !isClass &&
                                <Col md="12">
                                    <SingleSelectDropdown selectedId={paymentMethod}
                                        items={filteredPaymentMethods} idField="paymentTag" labelField="paymentType"
                                        onSelect={(method) => {
                                            if (paymentMethod !== method) {
                                                onEditBooking(booking, method);
                                            }
                                        }} />
                                </Col>
                        }
                        {
                            showIcons && !isClass &&
                                <Col md="12" className="full-row">
                                    <Button variant="outline-primary" href={`/${facilityLink}/booking/list?groupId=${booking.extendedProps.groupId1}`}>
                                        <i className="fa fa-book"/> Bookings
                                    </Button>
                                </Col>
                        }
                        {
                            showIcons &&
                                <Col md="12" className="full-row">
                                    <ButtonGroup>
                                        {
                                            !isClass &&
                                                <Button variant="outline-primary" onClick={() => onCopyBooking(booking)}><i className="fa fa-copy"/> Copy</Button>
                                        }
                                        <Button variant="outline-primary" onClick={isClass ? () => onEditClass(booking) : () => onEditBooking(booking)}><i className="fa fa-edit"/> Edit</Button>
                                        <Button variant="danger" onClick={() => onCancelBookingInitiated(booking.id)}><i className="fa fa-trash"/></Button>
                                    </ButtonGroup>
                                </Col>
                        }
                        {
                            showIcons && isClass &&
                                <Col md="12" className="full-row">
                                    <Button variant="outline-primary">
                                        <i className="fa fa-users"/> Participants
                                    </Button>
                                </Col>
                        }
                        {
                            showIcons && !isClass && (hasAccess("invoices", userInfo, "update") || hasAccess("agreement", userInfo, "update")) &&
                                <Col md="12" className="full-row">
                                    <ButtonGroup>
                                        {
                                            hasAccess("invoices", userInfo, "update") &&
                                                <>
                                                    {
                                                        _.isEmpty(booking.extendedProps.invoice) ?
                                                            <Button
                                                                variant="outline-primary"
                                                                onClick={() => onAddToInvoice(booking)}
                                                            >
                                                                <span><i className="fa fa-circle-plus"/> Invoice</span>
                                                            </Button>
                                                            : <Button
                                                                variant="outline-primary"
                                                                href={`/${facilityLink}/invoice/view?uuid=${booking.extendedProps.invoice.uuid}`}
                                                            >
                                                                <span><i className="fa fa-file"/> {GetShortedName(booking.extendedProps.invoice.invoiceNumber)}</span>
                                                            </Button>
                                                    }
                                                </>
                                        }
                                        {
                                            hasAccess("agreement", userInfo, "update") &&
                                            <>
                                                {
                                                    _.isEmpty(booking.extendedProps.agreement) ?
                                                        <Button
                                                            variant="outline-primary"
                                                            onClick={() => onAddToAgreement(booking)}
                                                        >
                                                            <span><i className="fa fa-circle-plus"/> Agreement</span>
                                                        </Button>
                                                        : <Button
                                                            variant="outline-primary"
                                                            href={`/${facilityLink}/agreement/accept?uuid=${booking.extendedProps.agreement.uuid}`}
                                                        >
                                                            <span><i
                                                                className="fa fa-file"/> {GetShortedName(booking.extendedProps.agreement.agreementNumber)}</span>
                                                        </Button>
                                                }
                                            </>
                                        }
                                    </ButtonGroup>
                                </Col>
                        }
                        {
                            showIcons && bookingPaymentMethod && !bookingPaymentMethod.isPayment && !isClass &&
                                <Col md="12" className="full-row">
                                    <Button variant="primary" onClick={() => onEditBooking(booking)}>
                                        <span><i className="fa fa-credit-card"/> Pay Now</span>
                                    </Button>
                                </Col>
                        }
                        {
                            showIcons && isClass &&
                                <Col md="12" className="full-row">
                                    <Button variant="success" href={`/${facilityLink}/registration/step-2?id=${booking.extendedProps.bid}`}>
                                        <span><i className="fa fa-file-text-o"/> Register Now</span>
                                    </Button>
                                </Col>
                        }
                    </Row>
                </Popover.Body>
            </Popover>
        );
    }

    const eventContent = (args) => {
        const extraProps = args.event.extendedProps;
        const showIcons = isLoggedIn && isPartialOrFullAdmin(userInfo);

        const bPadding = extraProps.bpadding;
        const venue = _.find(venues, (v) => String(v.id) === String(extraProps.venueId));
        let interval = 0;
        if (!_.isNil(venue)) {
            interval = venue.calendarInterval;
        }
        let className = `bPad${interval}-${bPadding}`;
        let endMoment = moment(args.event.end);
        let endAnnotation = null;
        if (!_.isNil(venue) && String(venue.isPadding) === String(2)) {
            endMoment.subtract(bPadding, 'minutes');
            endAnnotation = bPadding;
        }
        return (
            <div className={classnames("fc-event-main-frame", "bPad", className)}>
                <div className="fc-event-time">
                    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover(args.event)}>
                        <div className="fc-event-time-start">
                            { `${conciseTimeDisplay(moment(args.event.start), false)} - ${conciseTimeDisplay(endMoment, true)}` }
                            { endAnnotation ? <span className="caption">{endAnnotation}</span>: "" }
                        </div>
                    </OverlayTrigger>
                    {
                        showIcons &&
                            <div className="fc-event-time-end">
                                { !_.isNil(extraProps.note) && extraProps.note !== "" ? <i className="fa fa-comment"/> : "" }
                                <BaseOverlayTrigger content={t('calendar.icon.invoice')}>
                                    { !_.isNil(extraProps.invoice) ? <i className="fa fa-file"/> : <span/> }
                                </BaseOverlayTrigger>
                                <BaseOverlayTrigger content={t('calendar.icon.agreement')}>
                                    { !_.isNil(extraProps.agreement) ? <i className="fa fa-pencil-square"/> : <span/> }
                                </BaseOverlayTrigger>
                                <BaseOverlayTrigger content={t('calendar.icon.updated')}>
                                    { extraProps.updated ? <i className="fa fa-edit"/> : <span/> }
                                </BaseOverlayTrigger>
                                <BaseOverlayTrigger content={t('calendar.icon.repeat')}>
                                    { extraProps.isRepeat ? <i className="fa fa-retweet"/> : <span/> }
                                </BaseOverlayTrigger>
                                <BaseOverlayTrigger content={t('calendar.icon.linked')}>
                                    { !_.isNil(extraProps.linkedId) ? <i className="fa fa-link"/> : <span/> }
                                </BaseOverlayTrigger>
                                <BaseOverlayTrigger content={t('calendar.icon.paid')}>
                                    { extraProps.isPaid ? <i className="fa fa-c-dark-success fa-check-circle"/> : <span/> }
                                </BaseOverlayTrigger>
                                <BaseOverlayTrigger content={t('calendar.icon.online_booking')}>
                                    { extraProps.paymentMethod === "onlineBooking" ? <i className="fa fa-star"/> : <span/> }
                                </BaseOverlayTrigger>
                                <BaseOverlayTrigger content={t('calendar.icon.hold')}>
                                    { extraProps.paymentMethod === "hold" ? <i className="fa fa-pause fa-c-yellow"/> : <span/> }
                                </BaseOverlayTrigger>
                                <BaseOverlayTrigger content={t('calendar.icon.request')}>
                                    { extraProps.paymentMethod === "request" ? <i className="fa fa-flag fa-c-danger"/> : <span/> }
                                </BaseOverlayTrigger>
                                {
                                    !_.isEmpty(extraProps.partnerMapping) &&
                                        <BaseOverlayTrigger content={t('calendar.icon.partner_synced')}>
                                            <Image src={"/images/la_logo.png"} width={"8px"}/>
                                        </BaseOverlayTrigger>
                                }
                                <i className="fa fa-remove fa-hover" onClick={() => onCancelBookingInitiated(args.event.id)}/>
                            </div>
                    }
                </div>
                <div className="fc-event-title-container">
                    <div className="fc-event-title fc-sticky">
                        <OverlayTrigger rootClose trigger="click" placement="bottom"
                            overlay={popover(args.event)} >
                            <div className="fc-event-title-description d-flex gap-1">
                                <span className="text-wrap">{ args.event.title }</span>
                                {
                                    extraProps.eventName &&
                                        <>
                                            <span className="text-wrap">({extraProps.eventName})</span>
                                        </>
                                }
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
        );
    }

    let resourceList = _.map(venues, (v, i) => {
        const startTime = v.dStart.substring(0, 5);
        let endTime = v.dEnd.substring(0, 5);
        let slotEndTime = v.dEnd;
        const startHour = parseInt(startTime.substring(0, 2));
        const endHour = parseInt(endTime.substring(0, 2));
        if (endHour <= startHour) {
            endTime = String(endHour+24) + endTime.substring(2, 5)
            slotEndTime = String(endHour+24) + slotEndTime.substring(2)
        }
        let businessHours = {
            startTime: startTime,
            endTime: endTime,
            daysOfWeek: _.range(7)
        }
        if (v.useDailyTimes) {
            businessHours = _.map(v.dailyTimes, (dt) => {
                let st = dt.startTime.substring(0, 5);
                let et = dt.endTime.substring(0, 5);
                const sh =  parseInt(st.substring(0, 2));
                const eh =  parseInt(et.substring(0, 2));
                if (eh <= sh) {
                    et = String(eh+24) + et.substring(2, 5)
                }
                return {
                    startTime: st,
                    endTime: et,
                    daysOfWeek: [dt.day]
                }
            })
        }
        const resourceData = {
            id: parseInt(v.id),
            title: v.name,
            order: parseInt(i),
            slotMinTime: v.dStart,
            slotMaxTime: slotEndTime,
            calendarInterval: v.calendarInterval,
            labelInterval: v.labelInterval,
            singleClick: v.singleClick,
            businessHours: businessHours
        };
        return resourceData;
    });

    return (
        <BaseContainer containerClassName="full">
            <CalendarWrapper
                ref={calendarRef}
                defaultView={defaultView}
                defaultDayView={defaultDayView}
                showWeekSkip={settings.showWeekSkip}
                weekStart={settings.weekStart}
                onDatesSet={onDatesSet}
                resources={resourceList}
                onResourcesSelected={setSelectedVenues}
                events={bookings}
                editable={isLoggedIn && isPartialOrFullAdmin(userInfo)}
                eventContent={eventContent}
                onSelect={(info) => {
                    setTimeout(() => {
                        onSelect(info)
                    }, 50);
                }}
                onDrop={onDrop}
            />
            <AddBookingsToInvoiceModal
                facilityLink={facilityLink}
                show={showAddBookingsToInvoiceModal}
                onClose={onModalClose}
                bookings={[bookingToAddToInvoice]}
                invoices={groupInvoices}
                groupId={bookingToAddToInvoice && bookingToAddToInvoice.extendedProps.groupId1}
                usePreloadedInvoices={true}
            />
            <AddBookingsToAgreementModal
                facilityLink={facilityLink}
                show={showAddBookingsToAgreementModal}
                onClose={onModalClose}
                bookings={[bookingToAddToAgreement]}
                agreements={groupAgreements}
                groupId={bookingToAddToAgreement && bookingToAddToAgreement.extendedProps.groupId1}
                usePreloadedAgreements={true}
            />
            <AddOrEditBookingModal
                show={showAddOrEditBookingModal}
                onClose={onModalClose}
                selectionInfo={selectionInfo}
                copiedBooking={copiedBooking}
                booking={bookingToEdit}
                newPaymentMethod={newPaymentMethod}
            />
            <AddOrEditUserBookingModal
                show={showAddOrEditUserBookingModal}
                onClose={onModalClose}
                selectionInfo={selectionInfo}
                booking={bookingToEdit}
            />
            <CancelBookingModal
                show={showCancelBookingModal}
                onClose={onModalClose}
                booking={bookingToEdit}
            />
            <EditCustomerProfileModal
                facilityLink={facilityLink}
                show={showEditCustomerProfileModal}
                onClose={onModalClose}
                groupId={userIdToEdit}
            />
            <SignupModal show={showSignupModal} onClose={setShowSignupModal} onShowPasswordReset={true} />
            <BookingPaymentModal
                show={showBookingPaymentModal}
                onClose={onModalClose}
                booking={bookingForPaymentModal}
            />
        </BaseContainer>
    );
}

export default CalendarView;
