const _ = require("lodash");

export function getValueFromEvent(event) {
    if (event.target.type === "checkbox") {
        return event.target.checked ? true: false;
    } else if (event.target.type === "file") {
        return event.target.files[0];
    }
    return event.target.value;
}

export function getOptionsFromArray(items) {
    return _.map(items, (i) => { return { value: ""+i, label: ""+i } })
}

export const accessLevelOptions = (t) => {
    return [
        { 'value': 1, 'label': 'Full Admin Access' },
        { 'value': 2, 'label': 'View Only Access' },
        { 'value': 3, 'label': 'Custom Access' },
    ];
}

export const yesNoOptions = (t) => {
    return [
        {'value': "no", 'label': t('common.no')},
        {'value': "yes", 'label': t('common.yes')},
    ];
};

export const dayOptions = (t) => {
    return [
        {'id': "0", 'label': t('days.sunday')},
        {'id': "1", 'label': t('days.monday')},
        {'id': "2", 'label': t('days.tuesday')},
        {'id': "3", 'label': t('days.wednesday')},
        {'id': "4", 'label': t('days.thursday')},
        {'id': "5", 'label': t('days.friday')},
        {'id': "6", 'label': t('days.saturday')}
    ];
};

export const timeOptions = (interval=15) => {
    const options = [];
    for (let i = 0; i < 24; i++) {
        const hour = (((i - 1) % 12) + 1);
        const hourString = String(i).padStart(2, '0');;
        for (let j = 0; j < 60/interval; j++) {
            const minute = String(interval * j).padStart(2, '0');;
            const idTime = hourString + ":" + minute + ":00";
            const labelTime = (hour > 0 ? hour : "12") + ":" + minute + (i >= 12 ? " PM":" AM");
            options.push({'value': idTime, 'label': labelTime});
        }
    }
    return options;
}