import '../App.scss';
import { serverFetch } from '../helpers/server'
import { isPartialOrFullAdmin, BaseContext, hasAccess } from '../helpers/common'
import { useEffect, useState, useContext } from 'react';
import NoPermissionComponent from '../components/NoPermissionComponent';
import BaseContainer from '../components/Container';
import { Outlet } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

function RequireSpecificAdmin(props) {
    const { userInfo, isLoggedIn, getApiUrl } = useContext(BaseContext);
    const navigate = useNavigate();
    let { facilityLink } = useParams();
    const [loading, setLoading] = useState(true);
    const authorized = isLoggedIn && isPartialOrFullAdmin(userInfo) && hasAccess(props.type, userInfo, "view");

    useEffect(() => {
        if (authorized !== null) {
//            if (!authorized) {
//                navigate('/' + facilityLink);
//            } else {
                setLoading(false);
//            }
        }
    }, [authorized]);

    return (
        <>
            {
                !loading && authorized && <Outlet />
            }
            {
                !loading && !authorized &&
                    <BaseContainer>
                        <NoPermissionComponent />
                    </BaseContainer>
            }
        </>
    );
}

export default RequireSpecificAdmin;
