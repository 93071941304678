import '../../App.scss';
import '../../css/modals.scss';
import { useEffect, useState, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Form, InputGroup, Button, Row } from 'react-bootstrap';
import { serverFetch, serverPost } from '../../helpers/server';
import { getValueFromEvent } from '../../helpers/input';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function AddOrEditClassSessionModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [venues, setVenues] = useState([]);
    const [sessionToEdit, setSessionToEdit] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setSessionToEdit(props.sessionToEdit);
        setIsEditing(props.sessionToEdit !== null && props.sessionToEdit !== undefined);
    }, [props.sessionToEdit]);

    useEffect(() => {
        serverFetch(getApiUrl('/venues')).then((res) => {
            setVenues(res);
        })
    }, [props.facilityLink]);

    const venueOptions = _.map(venues, (venue, i) => { return { 'value': venue.id, 'label': venue.name } });

    const addFacility = (facilityDataFields) => {
//        const signupUrl = "/" + props.facilityLink + "/user/useradd?hideUser=0&showUser=0&showGroupColor=0&cRateFlat=0&uBookCRate=0"
        console.log("The data is " + JSON.stringify(facilityDataFields));
        // TODO(Aravind): Post to create facility endpoint
//        serverPost(signupUrl, data).then((res) => {
//            console.log("The res is " + JSON.stringify(res));
//        })
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Add a Session</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <p>Add a new session</p>
                <BaseForm onSubmit={addFacility}>
                    <Row>
                        <BaseForm.Input colSpan={12} name={'venue'} label={t('forms.venue')} type={'select'} options={venueOptions} />
                    </Row>
                    <SubmitButton className="btn-complete">Add a Session</SubmitButton>
                </BaseForm>
            </BaseModal.Body>
        </BaseModal>
    );

}

export default AddOrEditClassSessionModal;
