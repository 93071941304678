import '../../App.scss';
import '../../css/modals.scss';
import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import { Button, Row, Col } from 'react-bootstrap';
import { serverPatch, serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import {useTranslation} from "react-i18next";
const _ = require("lodash");

function EditSMSModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [itemToEdit, setItemToEdit] = useState({});

    useEffect(() => {
        setItemToEdit(props.itemToEdit);
    }, [props.itemToEdit]);

    const updateSMS = (smsData) => {
        serverPatch(getApiUrl(`/smses/${props.itemToEdit.id}`), smsData).then((res) => {
            if (res) {
                props.onClose();
                Notification.Show(t('notification.successfully_updated'));
            }
        });
    }

    const restoreToDefault = (event) => {
        serverPost(getApiUrl(`/smses/${props.itemToEdit.id}/restore`)).then((res) => {
            if (res) {
                setItemToEdit(res);
                Notification.ModalShow("Successfully restored");
            }
        });
    }

    return (
        <BaseModal size="lg" {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Edit SMS - {itemToEdit && itemToEdit.title}</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm initialFormFields={props.itemToEdit} onSubmit={updateSMS}>
                <BaseModal.Body>
                    <Row>
                        <BaseForm.Input colSpan="4" name="title" label="Title" type="text" required />
                        <BaseForm.Input colSpan="4" name="subject" label="Subject" type="text" required />
                        <BaseForm.Input colSpan="4" name="tag" label="Tag" type="text" required />
                        <BaseForm.Input colSpan="12" name="smsDetails" type="editor" required
                            tokens={[
                                '{customerName}',
                                '{groupName}',
                                '{bookingDate}',
                                '{bookingTime}',
                                '{emailId}',
                                '{venue}',
                                '{totalCost}',
                                '{phone}',
                                '{paymentType}'
                            ]}/>
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <Button variant="outline-primary" onClick={restoreToDefault}>Restore To Default</Button>
                            <SubmitButton>Save</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );
}

export default EditSMSModal;
