import '../../App.scss';
import '../../css/modals.scss';
import {useContext, useEffect, useState, createRef} from 'react';
import { useTranslation } from 'react-i18next';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Loader from '../Loader';
import {serverFetch, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { Col, Row } from 'react-bootstrap';
const _ = require('lodash');

function MoveOffWaitlistParticipantModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [registration, setRegistration] = useState({});
    const [participant, setParticipant] = useState(null);
    const [initialPaymentFormFields, setInitialPaymentFormFields] = useState({});

    useEffect(() => {
        setParticipant(props.participant);
    }, [props.participant])

    useEffect(() => {
        if (props.show && participant) {
            fetchRegistration();
        }
    }, [props.show, participant]);

    useEffect(() => {
        setInitialPaymentFormFields({
        });
    }, [props.classDetails, props.session, props.participant])

    const fetchRegistration = (skipCache=false) => {
        serverFetch(getApiUrl(`/classes/${participant.classId}/registrations/${participant.registrationId}`), { skipCache }).then((res) => {
            if (res) {
                setRegistration(res);
            }
            setLoading(false);
        });
    }

    const moveParticipant = async (data) => {
        const moveData = {
            type: data.type,
            bookingId: participant.sessionId
        }
        if (registration.participants.length <= 1) {
            moveData["type"] = "this";
        }
        serverPatch(getApiUrl(`/classes/${registration.classId}/registrations/${registration.id}/upgrade_from_waitlist`), moveData).then((res) => {
            if (props.onClose) {
                props.onClose(true);
            }
        })
    }

    const typeOptions = [
        { value: "thisAndFuture", label: "This and future sessions" },
        { value: "this", label: "This session" },
        { value: "all", label: "All sessions" },
    ]

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>{t('classes.move_off_waitlist.modal')}</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm initialFormFields={initialPaymentFormFields} onSubmit={moveParticipant}>
            <BaseModal.Body>
                <Loader loading={loading}>
                    {
                        () =>
                            <>
                            <p>{t('classes.move_off_waitlist.modal.body')}</p>
                            <br/>
                            {
                                registration.participants.length > 1 &&
                                    <Row>
                                        <BaseForm.Input colSpan="6" type="select" name="type" label="Sessions" options={typeOptions} required showSearch={false} />
                                    </Row>
                            }
                            </>
                    }
                </Loader>
            </BaseModal.Body>
            <BaseModal.Footer>
                <Row>
                    <Col md="12" className="text-end">
                        <SubmitButton variant="primary">Move</SubmitButton>
                    </Col>
                </Row>
            </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default MoveOffWaitlistParticipantModal;
