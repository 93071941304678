import '../../App.scss';
import '../../css/modals.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import Notification from '../Notification';
import SubmitButton from '../SubmitButton';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';

function ForgotPasswordModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');

    const resetPassword = (resetData) => {
        const forgotPasswordUrl = getApiUrl('/user/forgot_password')
        const data = {
            'email': resetData.email
        }
        serverPost(forgotPasswordUrl, data).then((res) => {
            Notification.Show("Password reset email sent.")
            props.onClose();
        })
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Forgot Password</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <p>Reset your password using your account email.</p>
                <BaseForm onSubmit={resetPassword}>
                    <BaseForm.Input colSpan={12} name={'email'} label={t('forms.email')} placeholder="Enter your account email" type={'text'} required validations={{validEmail: true}} />
                    <SubmitButton className="btn-complete">Send Reset Link</SubmitButton>
                </BaseForm>
            </BaseModal.Body>
        </BaseModal>
    );

}

export default ForgotPasswordModal;
