import '../../App.scss';
import '../../css/modals.scss';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import { useEffect, useState, useRef, useContext, useMemo } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { timeOptions } from '../../helpers/input'
import { downloadBlob, dateRangeClassCheck, BaseContext } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row, Col, InputGroup } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
const _ = require('lodash');

const SalesAllocationReportModal = (props) => {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [title, setTitle] = useState(null);
    const [reportType, setReportType] = useState(null);
    const [showFormatOptions, setShowFormatOptions] = useState(false);
    const [reportUrl, setReportUrl] = useState(null);

    const [dateFilter, setDateFilter] = useState(null);
    const [timeFilter, setTimeFilter] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const initialFormFields = useMemo(() => {
        return {
            dateFilter: dateFilter || "thisMonth",
            startDate: startDate,
            endDate: endDate
        }
    }, [startDate, endDate]);

    const startDateRef = useRef();
    startDateRef.current = startDate;
    const endDateRef = useRef();
    endDateRef.current = endDate;

    const onError =  async (response, errorMessage) => {
        if (!_.isEmpty(errorMessage) && errorMessage.calUnusedCost) {
            setErrorMessage(errorMessage.calUnusedCost)
        }
    }

    const runReport = async (data) => {
        setErrorMessage(null)
        const url = getApiUrl(`/bookings/${reportUrl}`);
        const filename = `${reportType}.${data.format || "pdf"}`
        const reportData = {
            dateFilter: data.dateFilter && {
                filterType: data.dateFilter,
                startDate: data.startDate,
                endDate: data.endDate
            },
            timeFilter: data.timeFilter && {
                filterType: data.timeFilter,
                startTime: data.startTime,
                endTime: data.endTime
            },
            format: data.format,
        }
        if (!_.isNil(data.calcUnusedCost)) {
            reportData.calcUnusedCost = data.calcUnusedCost
        }
        const response = await serverPost(url, reportData, { noJson: true }, onError);
        if (response) {
            downloadBlob(response, filename);
            props.onClose();
        }
    }

    useEffect(() => {
        if (!props.show) {
            setDateFilter(null);
            setTimeFilter(null);
            setErrorMessage(null);
        }
    }, [props.show])

    useEffect(() => {
        setTimeout(() => {
            $('.sales_allocation_report #startDate').datepicker( "destroy" );
            $('.sales_allocation_report #startDate').datepicker({
                defaultDate: 0,
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setStartDate(value);
                    setTimeout(() => $('#endDate').datepicker( "show" ), 100);
                }
            });
            $('.sales_allocation_report #endDate').datepicker( "destroy" );
            $('.sales_allocation_report #endDate').datepicker({
                defaultDate: "+1w",
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setEndDate(value);
                }
            });
        }, 100);
    }, [dateFilter]);

    useEffect(() => {
        if (props.type === "sales") {
            setTitle(t('sales_allocation.sales_title'));
            setReportType("sales_report");
            setReportUrl("export_sales_pdf");
            setShowFormatOptions(false);
        } else if (props.type === "allocation") {
            setTitle(t('sales_allocation.allocation_title'));
            setReportType("allocation_report");
            setReportUrl("export_allocation_pdf");
            setShowFormatOptions(true);
        }
    }, [props.type]);

    const onFieldChange = (name, value) => {
        if (name === "dateFilter") {
            setDateFilter(value);
        } else if (name === "timeFilter") {
            setTimeFilter(value);
        }
    }

    const dateRangeOptions = [
        { 'value': "today", 'label': t('common.today') },
        { 'value': "tomorrow", 'label': t('common.tomorrow') },
        { 'value': "yesterday", 'label': t('common.yesterday') },
        { 'value': "thisWeek", 'label': t('common.this_week') },
        { 'value': "nextWeek", 'label': t('common.next_week') },
        { 'value': "lastWeek", 'label': t('common.last_week') },
        { 'value': "thisMonth", 'label': t('common.this_month') },
        { 'value': "nextMonth", 'label': t('common.next_month') },
        { 'value': "lastMonth", 'label': t('common.last_month') },
        { 'value': "dateRange", 'label': t('common.date_range') },
    ];

    const timeFilterOptions = [
        { 'value': null, 'label': t('common.all') },
        { 'value': "prime", 'label': t('common.prime') },
        { 'value': "nonPrime", 'label': t('common.non_prime') },
        { 'value': "dailyTime", 'label': t('common.daily_time') },
        { 'value': "timeRange", 'label': t('common.time_range') },
    ];

    const formatOptions = [
        { 'value': "pdf", 'label': t('sales_allocation.pdf_format') },
        { 'value': "csv", 'label': t('sales_allocation.csv_format') },
    ];

    return (
        <BaseModal {...props} >
            <BaseForm
                id="test" onSubmit={runReport} onFieldChange={onFieldChange} initialFormFields={initialFormFields}
            >
                <BaseModal.Header>
                    <BaseModal.Title>{ title }</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body className="sales_allocation_report" >
                    <p>{t('sales_allocation.description')}</p>
                    <p><strong>{t('common.date_range')}</strong></p>
                    <Row>
                        <BaseForm.Input colSpan="5" name="dateFilter" type="select" label={t('common.date_range')} options={dateRangeOptions} showSearch={false}/>
                        {
                            dateFilter === "dateRange" &&
                                <Col md="7">
                                    <BaseForm.InputGroup>
                                        <BaseForm.Label>Start Date</BaseForm.Label>
                                        <BaseForm.Control type="text" name="startDate" id="startDate" />
                                        <InputGroup.Text>to</InputGroup.Text>
                                        <BaseForm.Label>End Date</BaseForm.Label>
                                        <BaseForm.Control type="text" name="endDate" id="endDate" />
                                    </BaseForm.InputGroup>
                                </Col>
                        }
                    </Row>
                    <p><strong>{t('common.time_range')}</strong></p>
                    <Row>
                        <BaseForm.Input colSpan="5" name="timeFilter" type="select" label={t('common.time_range')} options={timeFilterOptions} showSearch={false}/>
                        {
                            timeFilter === "timeRange" &&
                                <Col md="7">
                                    <BaseForm.InputGroup>
                                        <BaseForm.SingleSelect className="dateStartClass" name="startTime"
                                            options={timeOptions()} showSearch idField="value" label={t('common.start_time')} />
                                        <InputGroup.Text>{t('common.to')}</InputGroup.Text>
                                        <BaseForm.SingleSelect className="dateEndClass" name="endTime"
                                            options={timeOptions()} showSearch idField="value" label={t('common.end_time')} />
                                    </BaseForm.InputGroup>
                                </Col>
                        }
                    </Row>
                    {
                        showFormatOptions &&
                            <>
                                <p><strong>{t('common.format')}</strong></p>
                                <Row>
                                    <BaseForm.Input colSpan="5" name="format" type="select" label={t('common.format')} options={formatOptions} showSearch={false}/>
                                    <BaseForm.Input colSpan="7" name="calcUnusedCost" type="check" label={"Calculate unused dollar amounts"} options={formatOptions} showSearch={false}/>
                                </Row>
                            </>
                    }
                    <span className="subtext">{t('sales_allocation.warning')}</span>
                </BaseModal.Body>
                <BaseModal.Footer className="text-end">
                    <div className="d-flex flex-row gap-12 align-items-center justify-content-center">
                        {
                            errorMessage && <div className="form-error-message">{errorMessage}</div>
                        }
                        <SubmitButton variant="primary">{t('sales_allocation.run_report')}</SubmitButton>
                    </div>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );
}

export default SalesAllocationReportModal;
