import '../App.scss';
import { getUrl, serverFetch } from '../helpers/server'
import { BaseContext } from '../helpers/common'
import { useEffect, useState, useContext } from 'react';
import { Outlet } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

function RequireSuperAdmin(props) {
    const { getApiUrl } = useContext(BaseContext);
    const navigate = useNavigate();
    let { facilityLink } = useParams();
    const [loading, setLoading] = useState(true);
    const [authorized, setAuthorized] = useState(null);
    const isLoggedIn = localStorage.getItem("auth_token") !== null;

    useEffect(() => {
        if (isLoggedIn) {
            serverFetch(getApiUrl('/user')).then((res) => {
                setAuthorized(res.isSuperAdmin);
            })
        } else {
            setAuthorized(false);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (authorized !== null) {
            if (!authorized) {
                navigate('/' + facilityLink);
            } else {
                setLoading(false);
            }
        }
    }, [authorized]);

    return (
      <>
      {
        !loading && <Outlet />
      }
      </>
    );
}

export default RequireSuperAdmin;
