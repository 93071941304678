import '../App.scss';
import '../css/forms.scss';
import '../js/forms.js';
import classnames from 'classnames';
import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
const _ = require("lodash");

function MultiSelectDropdown(props) {
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const idField = props.idField || 'id';
    const labelField = props.labelField || 'label';
    const prefix = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

    useEffect(() => {
        setItems(props.items || []);
    }, [props.items]);

    useEffect(() => {
        setSelectedItems(props.selectedItems || []);
    }, [props.selectedItems]);

    const onSelectAll = () => {
        const newSelectedItems = _.map(items, (p, i) => p[idField]);
        setSelectedItems(newSelectedItems);
        if (props.onItemsChange) {
            props.onItemsChange(newSelectedItems);
        }
    }

    const onSelectNone = () => {
        const newSelectedItems = [];
        setSelectedItems(newSelectedItems);
        if (props.onItemsChange) {
            props.onItemsChange(newSelectedItems);
        }
    }

    const updateSelectedItems = (value, isChecked) => {
        let newSelectedItems = [...selectedItems];
        if (isChecked) {
            newSelectedItems.push(value);
        } else {
            const index = newSelectedItems.indexOf(value);
            if (index > -1) {
                newSelectedItems.splice(index, 1);
            }
        }
        setSelectedItems(newSelectedItems);
        if (props.onItemsChange) {
            props.onItemsChange(newSelectedItems);
        }
    }

    return (
        <div className={classnames("dropdown", props.className, props.disabled ? "disabled": "")}>
            <button type="button" className="btn btn-outline-primary dropdown-toggle skinny-md" data-bs-auto-close="outside" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,-1" disabled={props.disabled}>
                <label className="form-label label dropdown">{props.label}</label>
                <div className="simple-label d-none d-md-block"><span>{selectedItems.length} Of {items.length}</span></div>
                <div className="simple-label d-block d-md-none"><span>{selectedItems.length}</span></div>
            </button>
            <ul role="menu" className="dropdown-menu">
                <li className="checkbox check-success skinny">
                    Select:<Button variant="alink" className="skinny" onClick={onSelectAll}>All</Button>
                    <Button variant="alink" className="skinny" onClick={onSelectNone}>None</Button>
                </li>
                {
                    _.map(items, (item, i) =>
                         <li key={i} className="checkbox check-success skinny">
                            <input type="checkbox" value={item[idField]} id={prefix + item[idField]} name="select[]" checked={selectedItems.includes(item[idField])} onChange={ (event) => updateSelectedItems(item[idField], event.target.checked) }/>
                            <label htmlFor={prefix + item[idField]} className="label skinny">{item[labelField]}</label>
                        </li>
                    )
                }
            </ul>
        </div>
    );
}

export default MultiSelectDropdown;
