import '../../App.scss';
import '../../css/modals.scss';
import { useEffect, useState, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import { useParams } from "react-router-dom";
import { Form, InputGroup, Button, Row, Col, ButtonGroup } from 'react-bootstrap';
import { getPaymentOptions, BaseContext } from '../../helpers/common';
import { serverFetch, serverPost } from '../../helpers/server';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function EditBulkCustomersModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { facilityLink } = useParams();
    const [customerTypes, setCustomerTypes] = useState([]);
    const [customerIds, setCustomerIds] = useState([]);
    const paymentOptions = getPaymentOptions();
    const [editError, setEditError] = useState(false);
    const { t } = useTranslation('common'); 

    useEffect(() => {
        if (props.show) {
            setEditError(false);
            serverFetch(getApiUrl('/customer_types')).then((res) => {
                setCustomerTypes(res);
            })
        }
    }, [facilityLink, props.show]);

    useEffect(() => {
        setCustomerIds(_.map(props.customers, (c) => c.id));
    }, [props.customers]);

    const updateCustomers = (fields) => {
        console.log("The fields are " + JSON.stringify(fields));
        const updateFields = {
            groupIds: customerIds
        };
        if (fields.updateCustomerType) {
            updateFields.customerTypeId = fields.popCustomerType;
        }
        if (fields.updateCustomerColor) {
            updateFields.customerColor = fields.popCustomerColor;
        }
        if (fields.updateHourlyRate) {
            updateFields.rate = fields.popHourlyRate;
        }
        if (fields.updateCustomerNamePrefix) {
            updateFields.customerNamePrefix = fields.popCustomerNamePrefix;
        }

        serverPost(getApiUrl("/user_groups/batch_update"), updateFields).then((res) => {
            if (res) {
                Notification.ModalShow("Successfully updated customers");
                props.onClose(true);
            } else {
                setEditError(true);
            }
        })
    }

    const customerTypeOptions = _.map(customerTypes, (type) => { return { value: type.id, label: `${type.customerType}` } });
    customerTypeOptions.unshift({ value: null, label: "None" });
    const paymentTypeOptions = _.map(paymentOptions, (op) => { return { value: op.id, label: op.label } });

    return (
        <BaseModal {...props} size="md">
            { props.customers.length > 10 ? (
                <>
                    <BaseModal.Header>
                        <BaseModal.Title>Edit Customer(s)</BaseModal.Title>
                    </BaseModal.Header>
                    <BaseModal.Body>
                        <Row>
                            <p>{t("modals.edit_customer.error")}</p>
                        </Row>
                    </BaseModal.Body>
                </>
            ) : 
                <BaseForm onSubmit={updateCustomers} initialFormFields={{ popCustomerColor: "#6d8bbf" }}>
                    <BaseModal.Header>
                        <BaseModal.Title>Edit Customer(s)</BaseModal.Title>
                    </BaseModal.Header>
                    <BaseModal.Body>
                        <Col md="12">
                            <BaseForm.InputGroup>
                                <BaseForm.Select name="popCustomerType" label="Customer Type" options={customerTypeOptions} showAll={false} showSearch={false}/>
                                <BaseForm.Check id="updateCustomerType" name="updateCustomerType" />
                            </BaseForm.InputGroup>
                        </Col>
                        <Col md="12">
                            <BaseForm.InputGroup>
                                <BaseForm.Color id="popCustomerColor" name="popCustomerColor" label="Customer Color" />
                                <BaseForm.Check id="updateCustomerColor" name="updateCustomerColor" />
                            </BaseForm.InputGroup>
                        </Col>
                        <Col md="12">
                            <BaseForm.InputGroup leftContent="$">
                                <BaseForm.Label>Customer Hourly Rate</BaseForm.Label>
                                <BaseForm.Control type="text" name="popHourlyRate" placeholder="When empty standard rates apply"/>
                                <BaseForm.Check id="updateHourlyRate" name="updateHourlyRate" />
                            </BaseForm.InputGroup>
                        </Col>
                        <Col md="12">
                            <BaseForm.InputGroup>
                                <BaseForm.Text name="popCustomerNamePrefix" label="Customer Name Prefix" placeholder={"2024_"}/>
                                <BaseForm.Check id="updateCustomerNamePrefix" name="updateCustomerNamePrefix" />
                            </BaseForm.InputGroup>
                        </Col>
                        { editError && (
                            <Col md="12">
                                <p style={{color: 'red'}}>{t("modals.edit_customer.error")}</p>
                            </Col>
                        )}
                    </BaseModal.Body>
                    <BaseModal.Footer>
                        <Row>
                            <Col className="text-end">
                                <Button variant="link" onClick={() => props.onClose(false)}>Cancel</Button>
                                <SubmitButton>Update Customers</SubmitButton>
                            </Col>
                        </Row>
                    </BaseModal.Footer>
                </BaseForm>
            }
            
        </BaseModal>
    );

}

export default EditBulkCustomersModal;
