import '../../App.scss';
import '../../css/modals.scss';
import { useState, useEffect } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import { Form, Button, Row } from 'react-bootstrap';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { serverPost } from '../../helpers/server';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function AddOrEditLockerRoomModal(props) {
    const { t } = useTranslation('common');
    const [venues, setVenues] = useState([]);

    useEffect(() => {
         setVenues(props.venues);
    }, [props.venues]);

    const venueOptions = _.map(venues, (venue, i) => { return { 'value': venue.id, 'label': venue.name } });

    return (
        <BaseAddOrEditItemModal
            {...props}
            itemLabel={"Locker Room"}
            itemBaseUrl={'/lockers'}
        >
            <Row>
                <BaseForm.Input colSpan={12} name="lockerName" label={t('forms.locker_name')} type="text" placeholder={t('forms.locker_name')} required />
                <BaseForm.Input colSpan={12} name="venueId" label={t('forms.venue')} type="select" options={venueOptions} required showSearch={false} />
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditLockerRoomModal;
