import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import BaseForm from '../../components/BaseForm';
import TabHeader from '../../components/TabHeader';
import Notification from '../../components/Notification';
import { serverFetch, serverPost, serverDelete, getInvoiceCreationUrl } from '../../helpers/server';
import { conciseTimeDisplay, hasAccess, BaseContext, getFormattedImageLink,
    currencyFormat } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { useParams, useSearchParams, Link, useNavigate } from "react-router-dom";
import { Table, Button, Row, Col, Alert, ButtonToolbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classnames from 'classnames';
import { loadStripe } from '@stripe/stripe-js';
const _ = require("lodash");

function AgreementPrintView() {
    const { userInfo, isLoggedIn, getApiUrl, settings, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [ searchParams ] = useSearchParams();
    let uuid = searchParams.get('uuid');

    // For the payment processing results
    let name = searchParams.get('name');
    let invoiceFee = searchParams.get('fee');
    let processor = searchParams.get('processor');
    let paymentIntent = searchParams.get('payment_intent');
    let paymentIntentClientSecret = searchParams.get('payment_intent_client_secret');
    let redirectStatus = searchParams.get('redirect_status');

    const [groupInfo, setGroupInfo] = useState({});
    const [credit, setCredit] = useState(0);
    const [groupId, setGroupId] = useState(null);
    const [agreementDetails, setAgreementDetails] = useState({});
    const [taxRateMap, setTaxRateMap] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [bookingLineItemsToInvoice, setBookingLineItemsToInvoice] = useState([]);

    useEffect(() => {
        const groupName = !_.isEmpty(agreementDetails) ? agreementDetails.group.name : ""
        document.title = `${groupName} (${agreementDetails.agreementNumber}) - ${getFacilityName()}`;

        if (!_.isEmpty(agreementDetails)) {
            const taxRateMap = agreementDetails.lineItems.reduce((map, line) => {
                const key = line.taxLabel ? `${line.taxLabel} ${line.taxRate}%` : `${agreementDetails.taxLabel} ${line.taxRate}%`;
                map[key] = (map[key] || 0) + (line.total - line.amount);
                return map;
            }, {});
            setTaxRateMap({...taxRateMap})
        } 
    }, [agreementDetails]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (groupId && isLoggedIn) {
            serverFetch(getApiUrl(`/user_groups/${groupId}`)).then((res) => {
                if (res) {
                    setGroupInfo(res);
                }
            });
        }
    }, [groupId]);

    const fetchData = (skipCache = false) => {
        serverFetch(getApiUrl(`/agreements/${uuid}`), { skipCache }).then((res) => {
            if (res) {
                setAgreementDetails(res);
                setPaymentMethods(res.paymentMethodOptions);
                const acceptFields = {
                    'groupName': res.group.name,
                };
                if (res.isAccepted) {
                    const acceptedData = _.find(res.payments, (p) => p.amount === 0);
                    acceptFields['fullName'] = acceptedData && acceptedData.userName;
                    acceptFields['email'] = acceptedData && acceptedData.emailAgr;
                    acceptFields['phone'] = acceptedData && acceptedData.phoneAgr;
                    acceptFields['paymentMethod'] = acceptedData && acceptedData.paymentMethod;
                }
                setGroupId(res.group.id);
            }
            setLoading(false);
        });
    }

    const cancelAgreement = () => {
        serverPost(getApiUrl(`/agreements/batch_cancel`), { uuids: [uuid] }).then((res) => {
            if (res) {
                Notification.Show(t('agreement.accept.successfully_deleted'));
                fetchData(true);
            }
        });
    }

    useEffect(() => {
        uuid = searchParams.get('uuid')
    }, [searchParams]);

    const getPaymentLabelForPaymentMethod = (paymentMethod) => {
        const method = _.find(paymentMethods, (m) => m.paymentTag === paymentMethod);
        return !_.isNil(method) ? method.paymentType : null;
    }

    const paymentMethodOptions = _.map(paymentMethods, (m) => { return { 'value': m.paymentTag, 'label': _.replace(m.paymentType, "Paid ", ""), 'icon': m.paymentIcon }; });

    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        groupId: groupId,
        userGroup: agreementDetails.group && {
            'groupName': agreementDetails.group.name
        }
    };
    const tabs = getTabItems(t, facilityLink, "agreements", tabsParams);
    if (groupId && tabs[2]) {
        tabs[2].active = true;
    }

    let tableColumnWidths = {
        "hours": "10%",
        "rate": "10%",
        "tax": "10%",
        "amount": "10%"
    };
    if (settings.showAgrEventName) {
        tableColumnWidths["item"] = "10%";
        tableColumnWidths["venue"] = "14%";
        tableColumnWidths["description"] = "22%";
        tableColumnWidths["event"] = "14%";
    } else {
        tableColumnWidths["item"] = "10%";
        tableColumnWidths["venue"] = "20%";
        tableColumnWidths["description"] = "30%";
    }

    let iAgreeLabel = t('agreement.accept.i_agree');
    if (settings.customIAgree) {
        iAgreeLabel = <div dangerouslySetInnerHTML={{ __html: settings.customIAgree }} />;
    }

    const needsLogin = settings.needsLoginToAcceptAgreement;
    const isExpectedUser = !_.isEmpty(groupInfo) ? groupInfo.user.id === userInfo.id : false;
    const showAcceptForm = !needsLogin || (isLoggedIn && isExpectedUser);

    return (
        <div>
            <Loader loading={loading}>
                <div className="content-box">
                    <div className={classnames("content-body", "invoice-container1", agreementDetails.isCancel ? "cancelled" : "")}>
                        <Row className="invoice-header">
                            <Col md="3">
                                <h2>{ settings.agrTitle }</h2>
                                { agreementDetails.group && <strong>{ agreementDetails.group.name }<br/></strong> }
                                { agreementDetails.to && <span>{ agreementDetails.to }<br/></span> }
                                { agreementDetails.group && agreementDetails.group.email && <span>{ agreementDetails.group.email }<br/></span> }
                                { agreementDetails.group && agreementDetails.group.phone && <span>{ agreementDetails.group.phone }<br/></span> }
                            </Col>
                            <Col md="3">
                                {
                                    !_.isEmpty(agreementDetails.address) &&
                                        <>
                                            <strong><div dangerouslySetInnerHTML={ { __html: agreementDetails.address.companyName } } /></strong>
                                            { agreementDetails.address.address1 }{ agreementDetails.address.address2 && ` ${agreementDetails.address.address2}` }<br />
                                            { agreementDetails.address.city } { agreementDetails.address.state } { agreementDetails.address.zipCode }<br/>
                                            { agreementDetails.address.phone }
                                        </>
                                }
                            </Col>
                            <Col md="3">
                                <Row>
                                    <Col md="6"><strong>Agreement date:</strong></Col>
                                    <Col md="6" className="text-end">{moment(agreementDetails.agreementDate).format("MMM DD, YYYY")}</Col>
                                    <Col md="12" className="spacing"></Col>
                                    <Col md="6"><strong>Agreement No:</strong></Col>
                                    <Col md="6" className="text-end">{agreementDetails.agreementNumber}</Col>
                                    <Col md="12" className="spacing"></Col>
                                    {
                                        settings.showBusinessNumber &&
                                            <>
                                                <Col md="6"><strong>Business No:</strong></Col>
                                                <Col md="6" className="text-end">{settings.businessNumber}</Col>
                                            </>
                                    }
                                </Row>
                            </Col>
                            <Col md="3" className="text-end">
                                <img className="invoice-logo" style={{ maxHeight: "125px", maxWidth: "125px" }} src={getFormattedImageLink(settings.logo, "https://rectimes.app/uploads/logoinv/")} />
                            </Col>
                        </Row>
                        {
                            agreementDetails.openingFilled &&
                                <div>
                                    <br/>
                                    <div dangerouslySetInnerHTML={ { __html: agreementDetails.openingFilled } } />
                                    <br/>
                                </div>
                        }
                        <Table>
                            <thead>
                                <tr>
                                    <th width={tableColumnWidths["item"]}>Item</th>
                                    <th width={tableColumnWidths["venue"]}>Venue</th>
                                    <th width={tableColumnWidths["description"]}>Description</th>
                                    {
                                        settings.showAgrEventName &&
                                            <th width={tableColumnWidths["event"]}>Event</th>
                                    }
                                    <th width={tableColumnWidths["hours"]} className="text-end">Hours</th>
                                    <th width={tableColumnWidths["rate"]} className="text-end">Rate</th>
                                    <th width={tableColumnWidths["tax"]} className="text-end">Tax</th>
                                    <th width={tableColumnWidths["amount"]} className="text-end">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                _.map(agreementDetails.lineItems, (item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td width={tableColumnWidths["item"]}>{item.item}</td>
                                            <td width={tableColumnWidths["venue"]}>{item.venueName}</td>
                                            <td width={tableColumnWidths["description"]}>
                                                <span>{item.description}</span>
                                            </td>
                                            {
                                                settings.showAgrEventName &&
                                                    <td width={tableColumnWidths["event"]}>{item.eventName}</td>
                                            }
                                            <td width={tableColumnWidths["hours"]} className="text-end">{item.hours}</td>
                                            <td width={tableColumnWidths["rate"]} className="text-end">{item.rateFormatted}</td>
                                            <td width={tableColumnWidths["tax"]} className="text-end">{item.taxRate}%</td>
                                            <td width={tableColumnWidths["amount"]} className="text-end">{item.amountFormatted}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                        <Table borderless className="skinny">
                            <tbody>
                                {
                                    !settings.hideAgreementTax &&
                                        <>
                                            <tr>
                                                <td width="50%"></td>
                                                <td width="40%" className="text-end">Subtotal</td>
                                                <td width="10%" className="text-end">{agreementDetails.subTotalFormatted}</td>
                                            </tr>
                                            { _.map(taxRateMap, (taxAmount, taxLabel) => 
                                                <tr>
                                                    <td width="50%"></td>
                                                    <td width="40%" className="text-end">{taxLabel}</td>
                                                    <td width="10%" className="text-end">${taxAmount.toFixed(2)}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td width="50%"></td>
                                                <td width="40%" className="text-end">Total Tax</td>
                                                <td width="10%" className="text-end">{agreementDetails.totalTaxFormatted}</td>
                                            </tr>
                                        </>
                                }
                                <tr>
                                    <td width="50%"></td>
                                    <td width="40%" className="text-end upline"><strong>Total</strong></td>
                                    <td width="10%" className="text-end upline"><strong>{agreementDetails.totalFormatted}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                        <br/>
                        <Table borderless className="skinny">
                            <tbody>
                            {
                                _.map(_.filter(agreementDetails.payments, (p) => p.amount > 0), (payment, i) =>
                                    <tr key={i}>
                                        <td width="100%" className="text-end">
                                            Less Payment {moment(payment.datePaid).format("MMM D, YYYY")} ({payment.paymentMethodName}){payment.reference ? ` ${payment.reference}` : ""}
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                        <Table borderless>
                            <tbody>
                                <tr>
                                    <td width="50%"></td>
                                    <td width="40%" className="text-end upline"><strong>Amount Due</strong></td>
                                    <td width="10%" className="text-end upline"><strong>{agreementDetails.amountDueFormatted}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                        {
                            !agreementDetails.hideMonthlyCharges &&
                                <div>
                                    <h2 className="invoice-header">Monthly Charges</h2>
                                    <Table className="skinny">
                                        <thead>
                                            <tr>
                                                <th width="35%">Date</th>
                                                <th>Hours</th>
                                                <th>Subtotal</th>
                                                <th>Tax</th>
                                                <th>Monthly Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            _.map(agreementDetails.monthlyCharges, (mc, i) =>
                                                <tr key={i}>
                                                    <td width="35%">{ moment(mc.startDate).format("MMM, YYYY") }</td>
                                                    <td>{ mc.hours.toFixed(2) }</td>
                                                    <td>{ currencyFormat(mc.subTotal) }</td>
                                                    <td>{ currencyFormat(mc.taxAmount) }</td>
                                                    <td>{ currencyFormat(mc.total) }</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                    <br/>
                                </div>
                        }
                        {
                            agreementDetails.notes &&
                                <div className="invoice-subdetails" dangerouslySetInnerHTML={ { __html: agreementDetails.notes } } />
                        }
                        {
                            agreementDetails.contractFilled &&
                                <div>
                                    <h2 className="invoice-header">Usage Contract</h2>
                                    <div className="invoice-subdetails" dangerouslySetInnerHTML={ { __html: agreementDetails.contractFilled } } />
                                </div>
                        }
                        <hr style={{ borderTop: "1px dashed #626262", background: "white" }}/>
                        <Table borderless className="agreement-payment-advice">
                            <tbody>
                                <tr>
                                    <td colSpan="4"><strong>Payment Method:</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan="3" width="50%" ><strong>Organization/Group Name: (Please Print)</strong></td>
                                    <td colSpan="1" width="50%" className="lined"></td>
                                </tr>
                                <tr>
                                    <td width="15%"><strong>Full Name:</strong></td>
                                    <td width="35%" className="lined"></td>
                                    <td width="15%" style={{ paddingLeft: "15px" }}><strong>Date:</strong></td>
                                    <td width="35%" className="lined"></td>
                                </tr>
                                <tr>
                                    <td width="15%"><strong>Phone:</strong></td>
                                    <td width="35%" className="lined"></td>
                                    <td width="15%" style={{ paddingLeft: "15px" }}><strong>Email:</strong></td>
                                    <td width="35%" className="lined"></td>
                                </tr>
                                <tr><td colSpan="4" style={{ height: "20px" }}></td></tr>
                                <tr>
                                    <td width="20%"><strong>Customer Signature:</strong></td>
                                    <td width="30%" className="lined"></td>
                                    <td colSpan="2" width="50%"></td>
                                </tr>
                                <tr><td colSpan="4" style={{ height: "20px" }}></td></tr>
                                <tr>
                                    <td width="15%"><strong>Staff Signature:</strong></td>
                                    <td width="35%" className="lined"></td>
                                    <td width="15%" style={{ paddingLeft: "15px" }}><strong>Date:</strong></td>
                                    <td width="35%" className="lined"></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Loader>
        </div>
    );
}

export default AgreementPrintView;
