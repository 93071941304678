import '../App.scss';
import { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react';
import { serverFetch } from '../helpers/server';
import { BaseContext } from '../helpers/common';
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import BaseForm, { BaseFormContext } from './BaseForm';
import DeleteButton from './DeleteButton';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

const BookingAddonRow = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        onFieldChange(name, value) {
            onFieldChange(name, value);
        },
    }));

    const { getApiUrl, settings } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [addons, setAddons] = useState([]);
    const [prefix, setPrefix] = useState("");

    useEffect(() => {
        setPrefix(props.prefix || "");
    }, [props.prefix]);

    useEffect(() => {
        setAddons(props.addons);
    }, [props.addons]);

    const onFieldChange = (name, value) => {

    }

    const addonsOptions = _.map(addons, (addon, i) => { return { 'value': addon.id, 'label': addon.addon } });

    return (
        <>
            <Row>
                <BaseForm.Input colSpan="3" name={prefix + "addonId"} label={t('forms.addon')} type="select" options={addonsOptions} disabled={props.disabled} showSearch={addons.length > 7}/>
                <BaseForm.Input colSpan="2" type="number" min="1" name={prefix + "count"} label={"Count"} disabled={props.disabled} />
                <div className="col-md-7 d-flex flex-row align-items-center justify-content-start">
                    <BaseForm.Hidden name={prefix + "rate"} />
                    <BaseForm.Hidden name={prefix + "preTax"} />
                    <BaseForm.Hidden name={prefix + "taxAmount"} />
                    <BaseForm.Hidden name={prefix + "total"} />
                {
                    !props.disabled && props.showAddNew &&
                        <Button variant="success" onClick={props.onAddNew}><i className="fa fa-plus fa-small"/></Button>
                }
                {
                    !props.disabled && props.canDelete &&
                        <DeleteButton size="md" onDelete={props.onDeleteRow}></DeleteButton>
                }
                </div>
                <Col md="6">
                </Col>
            </Row>
        </>
    );
})

export default BookingAddonRow;
