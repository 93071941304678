import '../../App.scss';
import '../../css/modals.scss';
import { getInvoiceExportFields } from '../../helpers/exportFields';
import BaseCSVExportModal from './BaseCSVExportModal';
import {useParams} from "react-router-dom";
import {useContext} from "react";
import {BaseContext} from "../../helpers/common";
const _ = require("lodash");

function InvoicesCSVExportModal(props) {
    let { facilityLink } = useParams();
    const { settings } = useContext(BaseContext);

    return (
        <BaseCSVExportModal
            {...props}
            fields={getInvoiceExportFields(settings)}
            filenamePrefix="Invoices-Report"
            cacheKey={facilityLink + "_csv_invoices"}
            exportUrl={'/invoices/export'} />
    );

}

export default InvoicesCSVExportModal;
