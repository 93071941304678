import { useEffect, useState, useMemo, useContext } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import DeleteButton from '../../components/DeleteButton';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import { serverFetch, serverPatch, serverPost, serverDelete } from '../../helpers/server';
import { timeOptions } from '../../helpers/input';
import { getDayOptions, BaseContext, getNameForDayOfWeek, parseTime } from '../../helpers/common';
import { useParams, useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Button, InputGroup, Row, Col, Table, ButtonToolbar } from 'react-bootstrap';
import Notification from '../../components/Notification';
import { useTranslation } from 'react-i18next';
import ConfirmationButton from "../../components/ConfirmationButton";
import moment from 'moment';
const _ = require("lodash");

function HourlyRates() {
    const { getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const [venueDetails, setVenueDetails] = useState({});
    const [eventTypes, setEventTypes] = useState([]);
    const [eventTypeRates, setEventTypeRates] = useState({});
    const [ searchParams ] = useSearchParams();
    const venueId = searchParams.get('id');
    const { t } = useTranslation('common');

    const defaultRow = useMemo(() => {
	    return {
            startDay: '1',
            endDay: '0',
            isEnabled: true
	    };
	}, [])
    const [exceptionRows, setExceptionRows] = useState([]);
    const [specialRows, setSpecialRows] = useState([]);

    useEffect(() => {
        document.title = `${venueDetails.name} (Rates) - ${getFacilityName()}`;
    }, [facilityLink, venueDetails]);

    useEffect(() => {
        serverFetch(getApiUrl(`/venues/${venueId}`)).then((res) => {
            setVenueDetails(res);
        });

        serverFetch(getApiUrl(`/venues/${venueId}/rate_specials`)).then((res) => {
            if (res) {
                _.each(res, (r) => {
                    r.startDay = String(r.startDay)
                    r.endDay = String(r.endDay)
                    r.isEnabled = false
                })
            }
            setSpecialRows(!_.isEmpty(res) ? res : [defaultRow]);
        });

        serverFetch(getApiUrl(`/venues/${venueId}/rate_exceptions`)).then((res) => {
            if (res) {
                _.each(res, (r) => {
                    r.startDay = String(r.startDay)
                    r.endDay = String(r.endDay)
                    r.isEnabled = false
                })
            }
            setExceptionRows(!_.isEmpty(res) ? res : [defaultRow]);
        });

        serverFetch(getApiUrl(`/event_types`, { venueIds: [venueId] }), { skipCache: true, skipAddingCategory: true }).then((res) => {
            const venueEventTypes = _.filter(res, (r) => String(r.rinkId) === String(venueId));
            const copiedEventTypes = (_.filter(res, (r) => _.includes(r.inheritedToVenueIds, parseInt(venueId))));
            setEventTypes([...venueEventTypes, ...copiedEventTypes]);
        })

        serverFetch(getApiUrl(`/venues/${venueId}/event_type_rates`), { skipCache: true }).then((res) => {
            if (res) {
                let etRates = {}
                _.each(res.eventTypeRates, (etr) => {
                    etRates[`eventTypeRates_${etr.eventTypeId}`] = etr.rate;
                })
                setEventTypeRates(etRates);
            }
        })
    }, [venueId, defaultRow]);

    const updateVenueSettings = (data) => {
        serverPatch(getApiUrl(`/venues/${venueId}/settings`), data).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
                serverFetch(getApiUrl(`/venues/${venueId}`), { skipCache: true }).then((res) => {
                    setVenueDetails(res);
                });
            }
        });
    }

    const updateEventTypeSpecificRates = (data) => {
        const rateData = [];
        console.log("The data is " + JSON.stringify(data));
        _.each(data, (value, key) => {
            const etID = key.split("_")[1]
            rateData.push({
                venueId: venueId,
                eventTypeId: etID,
                rate: value || null
            })
        })

        console.log("The rate data is " + JSON.stringify(rateData));
        // TODO(aswin): Save the data
        const updateData = {
            eventTypeRates: rateData
        }
        serverPost(getApiUrl(`/venues/${venueId}/event_type_rates`), updateData).then((res) => {
            if (res) {
                Notification.Show(t('setting.hourly_rates.successfully_exception_created'));
            }
        });
    }

    const addExceptionRow = () => {
        setExceptionRows([...exceptionRows, {...defaultRow}]);
    }

    const onCreateExceptionError = (response, errorMessage, i) => {
        if (response.status === 400) {
            setExceptionRows(exceptionRows.map((row, j) => {
                if (j === i) {
                    row.errorMessage = t("setting.hourly_rates.add_exception_error") + errorMessage.error;
                }
                return row;
            }));
        }
    }

    const onUpdateExceptionError = (response, errorMessage, i) => {
        if (response.status === 400) {
            setExceptionRows(exceptionRows.map((row, j) => {
                if (j === i) {
                    row.errorMessage = t("setting.hourly_rates.update_exception_error") + errorMessage.error;
                }
                return row;
            }));
        }
    }

    const updateExceptionRow = async (exceptionRow, data, i) => {
        if (exceptionRow.id) {
            await serverPatch(getApiUrl(`/venues/${venueId}/rate_exceptions/${exceptionRow.id}`), data, {}, (res, errorMessage) => onUpdateExceptionError(res, errorMessage, i)).then((res) => {
                if (res) {
                    Notification.Show(t('notification.successfully_updated'));
                    setExceptionRows(prevExceptionRows => prevExceptionRows.map((row, j) => {
                        if (j === i) {
                            res.isEnabled = false;
                            return res;
                        }
                        return row;
                    }
                    ));
                }
            });
        } else {
            // Create a new one
            await serverPost(getApiUrl(`/venues/${venueId}/rate_exceptions`), data, {}, (res, errorMessage) => onCreateExceptionError(res, errorMessage, i)).then((res) => {
                if (res) {
                    Notification.Show(t('setting.hourly_rates.successfully_exception_created'));
                    setExceptionRows(prevExceptionRows => prevExceptionRows.map((row, j) => {
                        if (j === i) {
                            res.isEnabled = false;
                            return res;
                        }
                        return row;
                    }
                    ));
                }
            });
        }
    }

    const onDeleteExceptionRow = (row) => {
        const exceptionRow = exceptionRows[row];
        if (!_.isNil(exceptionRow.id)) {
            // Delete saved tax item
            serverDelete(getApiUrl(`/venues/${venueDetails.id}/rate_exceptions/${exceptionRow.id}`)).then((res) => {
                exceptionRows.splice(row, 1);
                setExceptionRows([...exceptionRows]);
            })
        } else {
            // Is not saved to server so just remove it.
            exceptionRows.splice(row, 1);
            setExceptionRows([...exceptionRows]);
        }
    }

    const onUpdateBookingsForExceptionRow = () => {
        console.log("onUpdateBookingsForExceptionRow");
        // Create a new one
        serverPost(getApiUrl(`/venues/${venueDetails.id}/update_future_bookings`), {}).then((res) => {
            Notification.Show(t('setting.exception_rates.update_future_booking.success'));
        });
    }

    const onCreateSpecialError = (response, errorMessage, i) => {
        if (response.status === 400) {
            setSpecialRows(specialRows.map((row, j) => {
                if (j === i) {
                    row.errorMessage = t("setting.hourly_rates.add_special_error") + errorMessage.error;
                }
                return row;
            }));
        }
    }

    const onUpdateSpecialError = (response, errorMessage, i) => {
        if (response.status === 400) {
            setSpecialRows(specialRows.map((row, j) => {
                if (j === i) {
                    row.errorMessage = t("setting.hourly_rates.update_special_error") + errorMessage.error;
                }
                return row;
            }));
        }
    }

    const addSpecialRow = () => {
        setSpecialRows([...specialRows, {...defaultRow}]);
    }

    const updateSpecialRow = async (specialRow, i) => {
        console.log("Saving special row " + JSON.stringify(specialRow));
        if (specialRow.id) {
            await serverPatch(getApiUrl(`/venues/${venueId}/rate_specials/${specialRow.id}`), specialRow, {}, (res, errorMessage) => onUpdateSpecialError(res, errorMessage, i)).then((res) => {
                if (res) {
                    Notification.Show(t('notification.successfully_updated'));
                    setSpecialRows(prevSpecialRows => prevSpecialRows.map((row, j) => {
                        if (j == i) {
                            res.isEnabled = false;
                            return res;
                        }
                        return row;
                    }))
                }
            });
        } else {
            // Create a new one
            await serverPost(getApiUrl(`/venues/${venueId}/rate_specials`), specialRow, {}, (res, errorMessage) => onCreateSpecialError(res, errorMessage, i)).then((res) => {
                if (res) {
                    Notification.Show(t('setting.hourly_rates.successfully_special_created'));
                    setSpecialRows(specialRows.map((row, j) => {
                        if (j === i) {
                            res.isEnabled = false;
                            return res;
                        }
                        return row;
                    }));
                }
            });
        }
    }

    const onDeleteSpecialRow = (row) => {
        const specialRow = specialRows[row];
        if (!_.isNil(specialRow.id)) {
            // Delete saved tax item
            serverDelete(getApiUrl(`/venues/${venueDetails.id}/rate_specials/${specialRow.id}`)).then((res) => {
                specialRows.splice(row, 1);
                setSpecialRows([...specialRows]);
            })
        } else {
            // Is not saved to server so just remove it.
            specialRows.splice(row, 1);
            setSpecialRows([...specialRows]);
        }
    }

    const onFieldChange = (name, value) => {
        if (_.includes(["hideBookingPricing", "hideGreenSpecial", "exceptionChecksOnlyStartTime", "useEventTypeHourlyRates"], name)) {
            const data = {};
            data[name] = value;
            updateVenueSettings(data);
        }
    }

    const toggleExceptionRow = i => {
        setExceptionRows(prevExceptionRows => prevExceptionRows.map((row, j) => {
            if (j == i) {
                row.isEnabled = !row.isEnabled;
            }
            return row;
        }))
    }

    const toggleRateSpecial = i => {
        setSpecialRows(prevSpecialRows => prevSpecialRows.map((row, j) => {
            if (j == i) {
                row.isEnabled = !row.isEnabled;
            }
            return row;
        }))
    }

    const tabsParams = {
        searchParams: venueId && `id=${venueId}`,
        venueName: venueDetails.name
    };
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "venue-settings", tabsParams)}/>
            <div className="content-box">
                <div className="content-body">
                    <BaseForm initialFormFields={venueDetails} onFieldChange={onFieldChange} onSubmit={updateVenueSettings}>
                        <Row>
                            <BaseForm.Input colSpan="4" type="number" name="defaultRate" label={t('setting.hourly_rates.default_hourly_rate')} min="0" leftContent="$" step="0.01"
                                rightContent={<SubmitButton variant="primary"><i className="fa fa-save fa-large"/></SubmitButton>}/>
                            <BaseForm.Input colSpan="4" type="check" name="hideBookingPricing" label={t('setting.hourly_rates.hide_booking_price')} />
                            <BaseForm.Input colSpan="4" type="check" name="hideGreenSpecial" label={t('setting.hourly_rates.hide_special_colors')} />
                        </Row>
                    </BaseForm>
                </div>
            </div>
            <div className="content-box">
                <div className="content-header">
                    {t('setting.hourly_rates.event_type_rate_exceptions')}
                </div>
                <div className="content-body">
                    <BaseForm initialFormFields={venueDetails} onFieldChange={onFieldChange} onSubmit={updateVenueSettings}>
                        <Row>
                            <BaseForm.Input colSpan="4" type="check" name="useEventTypeHourlyRates" label={t('setting.hourly_rates.use_event_type_specific_rate')} />
                        </Row>
                    </BaseForm>
                    {
                        venueDetails.useEventTypeHourlyRates &&
                            <Row>
                                <Col md="6">
                                <BaseForm initialFormFields={eventTypeRates} onSubmit={updateEventTypeSpecificRates}>
                                    <Table hover className="skinny">
                                        <thead>
                                            <tr>
                                                <th width="50%">{t('forms.event_type')}</th>
                                                <th width="50%">{t('common.hourly_rate')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            _.map(eventTypes, (et, i) =>
                                                <tr key={i}>
                                                    <td>{ et.eventType }</td>
                                                    <td>
                                                        <BaseForm.Input
                                                            name={`eventTypeRates_${et.id}`} type="number" label="Hourly Rate"
                                                            placeholder={"Leave blank for default rate"}
                                                            hideLabel className="inline" min="0" step="0.01"
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                    <br/>
                                    <SubmitButton>{t('common.update')}</SubmitButton>
                                </BaseForm>
                            </Col>
                        </Row>
                    }
                </div>
            </div>
            <div className="content-box">
                <div className="content-header">
                    {t('setting.hourly_rates.rate_exceptions')}
                </div>
                <div className="content-body">
                    <BaseForm initialFormFields={venueDetails} onFieldChange={onFieldChange} onSubmit={updateVenueSettings}>
                        <Row>
                            <BaseOverlayTrigger content={t('setting.hourly_rates.booking_start_rate_calculation_overlay')} >
                                <Col md="5">
                                    <BaseForm.Input colSpan="12" type="check" name="exceptionChecksOnlyStartTime" label={<span>{t('setting.hourly_rates.booking_start_rate_calculation')} <i className="fa fa-question-circle fa-small"/></span>} />
                                </Col>
                            </BaseOverlayTrigger>
                        </Row>
                    </BaseForm>
                    <Table hover className="skinny">
                        <thead>
                            <tr>
                                <th width="28%">{t('common.date_range')}</th>
                                <th width="12%">{t('common.start_day')}</th>
                                <th width="12%">{t('common.end_day')}</th>
                                <th width="12%">{t('common.start_time')}</th>
                                <th width="11%">{t('common.end_time')}</th>
                                <th width="11%">{t('common.hourly_rate')}</th>
                                <th width="14%"></th>
                            </tr>
                        </thead>
                    </Table>
                    {
                        _.map(exceptionRows, (exceptionRow, i) =>
                            exceptionRow.isEnabled ?
                            <BaseForm key={i} initialFormFields={exceptionRow} onSubmit={(data) => updateExceptionRow(exceptionRow, data, i)}>
                                <Table hover className="skinny">
                                    <tbody>
                                        <tr>
                                            <td width="28%">
                                                <BaseForm.InputGroup className="inline">
                                                    <BaseForm.Control name="startDate" id={"startDate_exception_"+i} label={t('common.start_date')} type="date" className="inline" hideLabel required />
                                                    <InputGroup.Text>to</InputGroup.Text>
                                                    <BaseForm.Control name="endDate" id={"endDate_exception_"+i} label={t('common.end_date')} type="date" className="inline" hideLabel />
                                                    <InputGroup.Text><i className="fa fa-calendar"/></InputGroup.Text>
                                                </BaseForm.InputGroup>
                                            </td>
                                            <td width="12%">
                                                <BaseForm.Input name="startDay" hideLabel type="select" className="inline" options={getDayOptions(t)} showSearch={false} required />
                                            </td>
                                            <td width="12%">
                                                <BaseForm.Input name="endDay" hideLabel type="select" className="inline" options={getDayOptions(t)} showSearch={false} required />
                                            </td>
                                            <td width="12%">
                                                <BaseForm.Input type="select" name="startTime" options={timeOptions(5)} showAll={false} required />
                                            </td>
                                            <td width="11%">
                                                <BaseForm.Input type="select" name="endTime" options={timeOptions(5)} showAll={false} required />
                                            </td>
                                            <td width="11%">
                                                <BaseForm.Input name="rate" type="number" label="Hourly Rate" hideLabel className="inline" min="0" step="0.01" required />
                                            </td>
                                            <td width="14%" className="text-end">
                                                <ButtonToolbar>
                                                    { exceptionRow.isEnabled ?
                                                    <SubmitButton variant="primary"><i className="fa fa-save"/></SubmitButton>
                                                    :
                                                    <Button variant="primary" onClick={() => {toggleExceptionRow(i)}}><i className="fa fa-edit"/></Button>
                                                    }
                                                    <DeleteButton size="md" onDelete={() => onDeleteExceptionRow(i)}/>
                                                </ButtonToolbar>
                                            </td>
                                        </tr>
                                        {
                                            exceptionRow.errorMessage &&
                                            <tr>
                                                <td colSpan="7" className="text-end"><span className="form-error-message">{exceptionRow.errorMessage}</span></td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </BaseForm>
                            :
                            <Table hover className="skinny">
                            <tbody>
                                <tr>
                                    <td width="28%">
                                        {exceptionRow.startDate} to {exceptionRow.endDate}
                                    </td>
                                    <td width="12%">
                                        {getNameForDayOfWeek(t, exceptionRow.startDay)}
                                    </td>
                                    <td width="12%">
                                        {getNameForDayOfWeek(t, exceptionRow.endDay)}
                                    </td>
                                    <td width="12%">
                                        {moment("1999-12-31T" + exceptionRow.startTime).format("h:mm A")}
                                    </td>
                                    <td width="11%">
                                        {moment("1999-12-31T" + exceptionRow.endTime).format("h:mm A")}
                                    </td>
                                    <td width="11%">
                                        {exceptionRow.rate}
                                    </td>
                                    <td width="14%" className="text-end">
                                        <ButtonToolbar>
                                            { exceptionRow.isEnabled ?
                                            <SubmitButton variant="primary"><i className="fa fa-save"/></SubmitButton>
                                            :
                                            <Button variant="primary" onClick={() => {toggleExceptionRow(i)}}><i className="fa fa-edit"/></Button>
                                            }
                                            <DeleteButton size="md" onDelete={() => onDeleteExceptionRow(i)}/>
                                        </ButtonToolbar>
                                    </td>
                                </tr>
                                {
                                    exceptionRow.errorMessage &&
                                    <tr>
                                        <td colSpan="7" className="text-end"><span className="form-error-message">{exceptionRow.errorMessage}</span></td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                            
                        )
                    }
                    <br/>
                    <Button variant="outline-primary" onClick={addExceptionRow}><i className="fa fa-circle-plus" /> {t('common.add_new')}</Button>
                    <ConfirmationButton variant="primary" title={t('setting.exception_rates.update_future_booking.title')}
                                        body={t('setting.exception_rates.update_future_booking.body')} onConfirm={() => onUpdateBookingsForExceptionRow()}>{t('setting.hourly_rates.update_bookings')}</ConfirmationButton>
                </div>
            </div>
            <div className="content-box">
                <div className="content-header">
                    {t('setting.hourly_rates.rate_specials')}
                </div>
                <div className="content-body">
                    <Table hover className="skinny">
                        <thead>
                            <tr>
                                <th width="24%" className="wrappable">{t('common.date_range')}</th>
                                <th width="11%" className="wrappable">{t('common.start_day')}</th>
                                <th width="11%" className="wrappable">{t('common.end_day')}</th>
                                <th width="12%" className="wrappable">{t('common.start_time')}</th>
                                <th width="12%" className="wrappable">{t('common.end_time')}</th>
                                <th width="9%" className="wrappable">{t('common.hourly_rate')}</th>
                                <th width="9%" className="wrappable">{t('setting.hourly_rates.min_booking')}</th>
                                <th width="14%"></th>
                            </tr>
                        </thead>
                    </Table>
                    {
                        _.map(specialRows, (specialRow, i) =>
                            specialRow.isEnabled ?
                            <BaseForm key={i} initialFormFields={specialRow} onSubmit={(specialRow) => updateSpecialRow(specialRow, i)}>
                                <Table hover className="skinny">
                                    <tbody>
                                        <tr>
                                            <td width="24%">
                                                <BaseForm.InputGroup className="inline" disabled={!specialRow.isEnabled}>
                                                    <BaseForm.Control name="startDate" id={"startDate_special_"+i} label={t('common.start_date')} type="date" className="inline" hideLabel required />
                                                    <InputGroup.Text>to</InputGroup.Text>
                                                    <BaseForm.Control name="endDate" id={"endDate_special_"+i} label={t('common.end_date')} type="date" className="inline" hideLabel required />
                                                    <InputGroup.Text><i className="fa fa-calendar"/></InputGroup.Text>
                                                </BaseForm.InputGroup>
                                            </td>
                                            <td width="11%">
                                                <BaseForm.Input name={"startDay"} hideLabel type="select" className="inline" options={getDayOptions(t)} showSearch={false} required disabled={!specialRow.isEnabled} />
                                            </td>
                                            <td width="11%">
                                                <BaseForm.Input name={"endDay"} hideLabel type="select" className="inline" options={getDayOptions(t)} showSearch={false} required disabled={!specialRow.isEnabled} />
                                            </td>
                                            <td width="12%">
                                                <BaseForm.Input type="select" name={"startTime"} options={timeOptions(5)} showAll={false} required disabled={!specialRow.isEnabled} />
                                            </td>
                                            <td width="12%">
                                                <BaseForm.Input type="select" name={"endTime"} options={timeOptions(5)} showAll={false} required disabled={!specialRow.isEnabled} />
                                            </td>
                                            <td width="9%">
                                                <BaseForm.Input name={"rate"} type="number" label={t('common.hourly_rate')} hideLabel className="inline" min="0" step="0.01" required disabled={!specialRow.isEnabled} />
                                            </td>
                                            <td width="9%">
                                                <BaseForm.Input name={"minBooking"} type="number" label={t('setting.hourly_rates.min_booking')} hideLabel className="inline" step="1" min="0" required disabled={!specialRow.isEnabled} />
                                            </td>
                                            <td width="14%" className="text-end">
                                                <ButtonToolbar>
                                                    <BaseForm.Hidden name="id" hideLabel />
                                                    { specialRow.isEnabled ?
                                                    <SubmitButton variant="primary"><i className="fa fa-save"/></SubmitButton>
                                                    :
                                                    <Button variant="primary" onClick={() => {toggleRateSpecial(i)}}><i className="fa fa-edit"/></Button>
                                                    }
                                                    <DeleteButton size="md" onDelete={() => onDeleteSpecialRow(i)}/>
                                                </ButtonToolbar>
                                            </td>
                                        </tr>
                                        {
                                            specialRow.errorMessage &&
                                            <tr>
                                                <td colSpan="8" className="text-end"><span className="form-error-message">{specialRow.errorMessage}</span></td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </BaseForm>
                            :
                            <Table hover className="skinny">
                                <tbody>
                                        <tr>
                                            <td width="24%">
                                                {specialRow.startDate} to {specialRow.endDate}
                                            </td>
                                            <td width="11%">
                                                {getNameForDayOfWeek(t, specialRow.startDay)}
                                            </td>
                                            <td width="11%">
                                                {getNameForDayOfWeek(t, specialRow.endDay)}
                                            </td>
                                            <td width="12%">
                                                {moment("1999-12-31T" + specialRow.startTime).format("h:mm A")}
                                            </td>
                                            <td width="12%">
                                                {moment("1999-12-31T" + specialRow.endTime).format("h:mm A")}
                                            </td>
                                            <td width="9%">
                                                {specialRow.rate}
                                            </td>
                                            <td width="9%">
                                                {specialRow.minBooking}
                                            </td>
                                            <td width="14%" className="text-end">
                                                <ButtonToolbar>
                                                    {/* <BaseForm.Hidden name="id" hideLabel /> */}
                                                    { specialRow.isEnabled ?
                                                    <SubmitButton variant="primary"><i className="fa fa-save"/></SubmitButton>
                                                    :
                                                    <Button variant="primary" onClick={() => {toggleRateSpecial(i)}}><i className="fa fa-edit"/></Button>
                                                    }
                                                    <DeleteButton size="md" onDelete={() => onDeleteSpecialRow(i)}/>
                                                </ButtonToolbar>
                                            </td>
                                        </tr>
                                        {
                                            specialRow.errorMessage &&
                                            <tr>
                                                <td colSpan="8" className="text-end"><span className="form-error-message">{specialRow.errorMessage}</span></td>
                                            </tr>
                                        }
                                    </tbody>
                            </Table>
                            )
                        }
                    <br/>
                    <Button variant="outline-primary" onClick={addSpecialRow}><i className="fa fa-circle-plus" /> {t('common.add_new')}</Button>
                </div>
            </div>
            <div className="content-box hide">
                <div className="content-header">
                    {t('setting.hourly_rates.tax_override')}
                </div>
                <div className="content-body">
                    <BaseForm initialFormFields={venueDetails} onSubmit={updateVenueSettings}>
                        <Row>
                            <BaseForm.Input colSpan="5" type="check" name="overrideTax" label={t('setting.hourly_rates.override_default_tax_rate')} />
                            <BaseForm.Input colSpan="3" type="text" name="taxLabel1" label={t('setting.hourly_rates.tax_label')} required/>
                            <BaseForm.Input colSpan="3" type="number" name="tax1" label={t('setting.hourly_rates.tax_rate')} rightContent="%" />
                            <Col md="1" className="d-flex align-items-center">
                                <SubmitButton ><i className="fa fa-save"/></SubmitButton>
                            </Col>
                        </Row>
                    </BaseForm>
                </div>
            </div>
        </BaseContainer>
    );
}

export default HourlyRates;
