import '../App.scss';
import '../css/modals.scss';
import $ from 'jquery';
import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, createRef, useMemo } from 'react';
import BaseModal from './modals/BaseModal';
import BaseForm from './BaseForm';
import SubmitButton from './SubmitButton';
import { Form, Row, Col } from 'react-bootstrap';
import { BaseContext, getStripePublishableKeyForFacility } from '../helpers/common';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {serverPost} from "../helpers/server";
import Notification from "./Notification";
const _ = require('lodash');

const SportsPayCardInput = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        getPaymentMethod(data) {
            return getPaymentMethod(data);
        },
        onFieldChange(name, value) {
            onFieldChange(name, value);
        }
    }));

    const { t } = useTranslation('common');
    const { getApiUrl, settings } = useContext(BaseContext);

    const getPaymentMethod = async (data) => {
        let jshost = props.host || "https://paynew.interpaypos.com";
        let host = jshost;
        if (settings.helcimLive) {
            host = "https://svra.interpaypos.com:1443";
        }
        var apiKey = props.apiKey;

        var jsurl = jshost+"/assets/interpay_embed_test.min.js";
        if (settings.helcimLive) {
            jsurl = jshost+"/assets/rectimes_v1.0.min.js";
        }
        //load JS library and do implementation inside
        var cardNum = data.cardNum;
        var expMM = data.expMM;
        var expYYYY = data.expYY;
        var cvv = data.cvv;
        var cardData = {
            CARDNUM: cardNum,
            CARDEXPMM: expMM,
            CARDEXPYYYY: expYYYY,
            CARDCVV: cvv
        };
        const result = await new Promise((resolve, reject) => {
            //load JS dynamically from the URL you got from GetAPIKey
            $.getScript(jsurl, async function(){
                /* global InterPay */
                var interpay = new InterPay();
                var respValidate = interpay.validate(apiKey, cardData);
                if (respValidate.datavalid){
                    var respData = await interpay.tokenize(jshost, apiKey, cardData);
                    resolve(respData);
                } else {
                    const errors = {};
                    if (!respValidate.cardnum.isvalid) {
                        errors["cardNum"] = respValidate.cardnum.message;
                    }
                    if (!respValidate.cardexp.isvalid) {
                        errors["expMM"] = respValidate.cardexp.message;
                        errors["expYY"] = respValidate.cardexp.message;
                    }
                    if (!respValidate.cardcvv.isvalid) {
                        errors["cvv"] = respValidate.cardcvv.message;
                    }
                    if (!respValidate.apikey.isvalid) {
                        errors["apiKey"] = respValidate.apikey.message;
                    }
                    resolve({ errors: errors });
                }
            });
        })

        if (_.isNil(result)) {
            return null;
        } else if (!_.isNil(result.errors) || !_.isEmpty(result.errors)) {
            return result;
        } else {
            return {
                token: result.ott,
            };
        }
    }

    const onFieldChange = (name, value) => {
        if (_.includes(["cardNum", "cvv", "expMM", "expYY"], name)) {
            props.setInitialFields(prevInitialFields => {
                const newInitialFields = {...prevInitialFields};
                newInitialFields[name] = value;
                return newInitialFields;
            });
        }
    }

    const expiryMonthOptions = [
        { value: "01", label: "January" },
        { value: "02", label: "February" },
        { value: "03", label: "March" },
        { value: "04", label: "April" },
        { value: "05", label: "May" },
        { value: "06", label: "June" },
        { value: "07", label: "July" },
        { value: "08", label: "August" },
        { value: "09", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
    ];

    const expiryYearOptions = [
        { value: "2023", label: "2023" },
        { value: "2024", label: "2024" },
        { value: "2025", label: "2025" },
        { value: "2026", label: "2026" },
        { value: "2027", label: "2027" },
        { value: "2028", label: "2028" },
        { value: "2029", label: "2029" },
        { value: "2030", label: "2030" },
        { value: "2031", label: "2031" },
        { value: "2032", label: "2032" },
        { value: "2033", label: "2033" },
        { value: "2034", label: "2034" },
        { value: "2035", label: "2035" },
    ];

    return (
        <Row>
            <BaseForm.Input colSpan="12" type="text" name="cardNum" label="Card Number" required />
            <BaseForm.Input colSpan="3" type="text" name="cvv" label="CVV" required />
            <BaseForm.Input colSpan="3" type="select" name="expMM" label="Expiry Month" options={expiryMonthOptions} showSearch={false} />
            <BaseForm.Input colSpan="3" type="select" name="expYY" label="Year" options={expiryYearOptions} showSearch={false} />
        </Row>
    )
})

export default SportsPayCardInput;
