import { useEffect, useState, useCallback, useContext } from 'react';
import '../../App.scss';
import BaseContainer from '../../components/Container';
import Notification from '../../components/Notification';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import { serverFetch, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useParams, useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Comments() {
    const { getApiUrl, getFacilityName, settings, userInfo } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [userGroup, setUserGroup] = useState({});
    const [ searchParams ] = useSearchParams();
    const groupId = searchParams.get('groupId');

    useEffect(() => {
        document.title = `${userGroup.groupName} (Notes) - ${getFacilityName()}`;
    }, [userGroup]);

    const fetchData = useCallback((skipCache=false) => {
        serverFetch(getApiUrl(`/user_groups/${groupId}`), { skipCache }).then((res) => {
            setUserGroup(res);
        })
    }, [groupId])

    useEffect(() => {
        fetchData();
    }, [fetchData, facilityLink]);

    const onSubmit = (data) => {
        serverPatch(getApiUrl(`/user_groups/${groupId}`), data).then((res) => {
            if (res) {
                Notification.Show(t('setting.notes.successfully_updated'));
                fetchData(true);
            }
        })
    }

    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        groupId: groupId,
        userGroup: userGroup,
        settings: settings,
        userInfo: userInfo,
    };

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "bookings", tabsParams)} />
            <BaseForm onSubmit={onSubmit} initialFormFields={userGroup}>
                <div className="content-box">
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan="12" type="textarea" name="note" label={t('setting.notes.notes')} className="large" />
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-body">
                        <SubmitButton variant="primary">{t('common.save')}</SubmitButton>
                    </div>
                </div>
            </BaseForm>
        </BaseContainer>
    );
}

export default Comments;
