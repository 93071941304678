import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import React, { useEffect, useState, useRef, useContext, createRef } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import classnames from 'classnames';
import AddBookingsToInvoiceModal from '../../components/modals/AddBookingsToInvoiceModal';
import AddBookingsToAgreementModal from '../../components/modals/AddBookingsToAgreementModal';
import AddOrEditBookingModal from '../../components/modals/AddOrEditBookingModal';
import BaseContainer from '../../components/Container';
import Notification from '../../components/Notification';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import MassInvoiceCreationModal from '../../components/modals/MassInvoiceCreationModal';
import MassAgreementCreationModal from '../../components/modals/MassAgreementCreationModal';
import EditBulkBookingsModal from '../../components/modals/EditBulkBookingsModal';
import SalesAllocationReportModal from '../../components/modals/SalesAllocationReportModal';
import BookingsCSVExportModal from '../../components/modals/BookingsCSVExportModal';
import BookingsPDFExportModal from '../../components/modals/BookingsPDFExportModal';
import CancelBookingModal from '../../components/modals/CancelBookingModal';
import CancelledBookingModal from '../../components/modals/CancelledBookingModal';
import CancelBulkBookingModal from '../../components/modals/CancelBulkBookingModal';
import { getInvoiceCreationUrl, getAgreementCreationUrl, serverFetch, serverPost, notifyEvent } from '../../helpers/server';
import {hasAccess, BaseContext, currencyFormat, getAccessibleVenues, isFullAdmin} from '../../helpers/common';
import { getPaymentOptions, getLimitOptions, dateRangeClassCheck, getDateFormatForFacility } from '../../helpers/common';
import { getCompanyInfo } from '../../helpers/storage';
import { useParams, useSearchParams, useNavigate, Link } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { timeOptions } from '../../helpers/input'
import { Table, InputGroup, Form, Button, Row, ButtonGroup, ButtonToolbar, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getColorOptions, getDefaultColor } from '../../helpers/common';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmationButton from "../../components/ConfirmationButton";
import PushToPartnerConfirmationModal from "../../components/modals/PushToPartnerConfirmationModal";
import UnlinkPartnerConfirmationModal from "../../components/modals/UnlinkPartnerConfirmationModal";
const _ = require("lodash");

function List(props) {
    const { userInfo, isLoggedIn, getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const [salesAllocationReportType, setSalesAllocationReportType] = useState("");
    const [showCancelBookingModal, setShowCancelBookingModal] = useState(false);
    const [showCancelledBookingModal, setShowCancelledBookingModal] = useState(false);
    const [showCancelBulkBookingsModal, setShowCancelBulkBookingsModal] = useState(false);
    const [showPushToPartnerModal, setShowPushToPartnerModal] = useState(false);
    const [showUnlinkFromPartnerModal, setShowUnlinkPartnerModal] = useState(false);
    const [showSalesAllocationReportModal, setShowSalesAllocationReportModal] = useState(false);
    const [showBookingsReportModal, setShowBookingsReportModal] = useState(false);
    const [showCSVExportModal, setShowCSVExportModal] = useState(false);
    const [showPDFExportModal, setShowPDFExportModal] = useState(false);
    const [showBulkBookingsModal, setShowBulkBookingsModal] = useState(false);
    const [showMassInvoicesModal, setShowMassInvoicesModal] = useState(false);
    const [showMassAgreementsModal, setShowMassAgreementsModal] = useState(false);
    const [showAddBookingsToInvoiceModal, setShowAddBookingsToInvoiceModal] = useState(false);
    const [showAddBookingsToAgreementModal, setShowAddBookingsToAgreementModal] = useState(false);
    const [showAddOrEditBookingModal, setShowAddOrEditBookingModal] = useState(false);
    const [initialSearch, setInitialSearch] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [meta, setMeta] = useState({});
    const [settings, setSettings] = useState(getCompanyInfo());
    const [ searchParams ] = useSearchParams();
    let groupId = searchParams.get('groupId');

    const [searchAllQuery, setSearchAllQuery] = useState("");
    const [query, setQuery] = useState("");
    const [editFilter, setEditFilter] = useState("active");
    const [colorFilter, setColorFilter] = useState(null);
    const [connectedFilter, setConnectedFilter] = useState(null);
    const [agrInvFilter, setAgrInvFilter] = useState(null);
    const [paidFilter, setPaidFilter] = useState(null);
    const [timeFilter, setTimeFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startTime, setStartTime] = useState("00:00:00");
    const [endTime, setEndTime] = useState("23:45:00");
    const [venueFilter, setVenueFilter] = useState([]);
    const [eventFilter, setEventFilter] = useState([]);
    const [sortFilter, setSortFilter] = useState("idDesc");
    const [marginBlockSyncFilter, setMarginBlockSyncFilter] = useState(null);
    const [limit, setLimit] = useState(20);

    const [userGroup, setUserGroup] = useState({});
    const [bookings, setBookings] = useState([]);
    const [venues, setVenues] = useState([]);
    const [loadingVenues, setLoadingVenues] = useState(true);
    const [eventTypes, setEventTypes] = useState([]);
    const [loadingEventTypes, setLoadingEventTypes] = useState(true);
    const [checkedBookingIds, setCheckedBookingIds] = useState([]);
    const [checkedBookings, setCheckedBookings] = useState([]);
    const [bookingToEdit, setBookingToEdit] = useState(null);
    const [massBookingGroupingData, setMassBookingGroupingData] = useState([]);
    const [showEventName, setShowEventName] = useState(false);
    const [preLoadedVenueIds, setPreloadedVenueIds] = useState(null);

    const startDateRef = useRef();
    startDateRef.current = startDate;
    const endDateRef = useRef();
    endDateRef.current = endDate;
    const loadedFromOptions = createRef(false);

    useEffect(() => {
        document.title = "Bookings - " + getFacilityName();
    }, []);

    useEffect(() => {
        // Load the options from cache
        const optionsString = localStorage.getItem(facilityLink + "_filter_cache_bookings_partner");
        if (optionsString && !groupId) {
            const options = JSON.parse(optionsString);
            if (options.dateFilter) {
                setDateFilter(options.dateFilter.filterType);
                setStartDate(options.dateFilter.startDate);
                setEndDate(options.dateFilter.endDate);
            }
            if (options.timeFilter) {
                setTimeFilter(options.timeFilter.filterType);
                setStartTime(options.timeFilter.startTime);
                setEndTime(options.timeFilter.endTime);
            }
            if (options.paidFilter) {
                setPaidFilter(options.paidFilter)
            }
            if (options.agrInvFilter) {
                setAgrInvFilter(options.agrInvFilter);
            }
            if (options.colorFilter) {
                setColorFilter(options.colorFilter);
            }
            if (options.connectedFilter) {
                setConnectedFilter(options.connectedFilter);
            }
            if (options.eventFilter) {
                setEventFilter(options.eventFilter);
            }
            if (options.activeFilter) {
                setEditFilter(options.activeFilter);
            }
            if (options.marginBlockSyncFilter) {
                setMarginBlockSyncFilter(options.marginBlockSyncFilter);
            }
            if (options.venueFilter) {
                setVenueFilter(options.venueFilter);
                setPreloadedVenueIds(options.venueFilter);
            }
            if (options.sort) {
                setSortFilter(options.sort);
            }
            loadedFromOptions.current = true;
        }
    }, []);

    useEffect(() => {
        if (groupId) {
            serverFetch(getApiUrl(`/user_groups/${groupId}`)).then((res) => {
                setUserGroup(res);
            })
        }
    }, [groupId]);

    useEffect(() => {
        groupId = searchParams.get('groupId')
        onSearch(true);
    }, [searchParams])

    useEffect(() => {
        serverFetch(getApiUrl('')).then((res) => {
            setSettings(res);
        })

        serverFetch(getApiUrl('/venues')).then((res) => {
            const accessibleVenues = getAccessibleVenues(res, userInfo);
            setVenues(accessibleVenues);
            if (!loadedFromOptions.current) {
                setVenueFilter(_.map(accessibleVenues, (v, i) => v.id));
            }
            setLoadingVenues(false);
        })
    }, []);

    useEffect(() => {
        if (_.isEmpty(venueFilter)) {
            setEventTypes([]);
            setEventFilter([]);
        } else {
            setLoadingEventTypes(true);
            serverFetch(getApiUrl('/event_types', { venueIds: venueFilter }), { skipAddingCategory: true }).then((res) => {
                const types = Object.keys(_.groupBy(res, 'eventType'));
                const etypes = _.map(types, (v, i) => { return { 'label': v, 'id': v }} );
                setEventTypes(etypes);
                setEventFilter(_.map(etypes, (v, i) => v.id));
                setLoadingEventTypes(false);
            })
        }
    }, [venueFilter]);

    useEffect(() => {
        // Venues changed. Check if we need to adjust the venueFilter
        if (!loadingVenues && preLoadedVenueIds && !_.isEmpty(preLoadedVenueIds)) {
            const filteredResourceIds = _.map(_.filter(venues, (r) => _.includes(venueFilter, r.id)), (r) => r.id);
            if (!_.isEqual(venueFilter, filteredResourceIds)) {
                setVenueFilter(filteredResourceIds);
            }
        }
    }, [loadingVenues]);

    useEffect(() => {
        onSearch();
    }, [editFilter, agrInvFilter, colorFilter, paidFilter, timeFilter, dateFilter, venueFilter,
        eventFilter, limit, loadingVenues, loadingEventTypes, searchAllQuery, sortFilter, marginBlockSyncFilter, connectedFilter]);

    let queryTimeoutId = useRef();
    useEffect(() => {
        if (queryTimeoutId.current) {
            clearTimeout(queryTimeoutId.current);
        }
        queryTimeoutId.current = setTimeout(() => {
            queryTimeoutId.current = null;
            onSearch();
        }, 300);
    }, [query]);

    useEffect(() => {
        setTimeout(() => {
            $('#dateStart').datepicker( "destroy" );
            $('#dateStart').datepicker({
                defaultDate: 0,
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setStartDate(value);
                    setTimeout(() => $('#dateEnd').datepicker( "show" ), 100);
                }
            });
            $('#dateEnd').datepicker( "destroy" );
            $('#dateEnd').datepicker({
                defaultDate: "+1m",
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setEndDate(value)
                }
            });
        }, 100);
    }, [dateFilter, startDate, endDate]);

    useEffect(() => {
        setCheckedBookings(_.filter(bookings, (b) => checkedBookingIds.includes(getRowId(b))));
    }, [checkedBookingIds]);

    useEffect(() => {
        setShowEventName(_.some(bookings, (b) => (!_.isNil(b.eventName) && !_.isEmpty(b.eventName))));
    }, [bookings])

    const dateFilterChanged = (value) => {
        if (value !== "all" && sortFilter === "idDesc") {
            setSortFilter("startTimeAsc");
        }
        setDateFilter(value);
    }

    const onReportSelected = (reportType) => {
        if (reportType === "sales") {
            setSalesAllocationReportType("sales");
            setShowSalesAllocationReportModal(true);
        } else if (reportType === "csv") {
            if (checkedBookings.length === 0) {
                Notification.Show(t('booking.list.error_choose_one'));
            } else {
                setShowCSVExportModal(true);
            }
        } else if (reportType === "pdf") {
            if (checkedBookings.length === 0) {
                Notification.Show(t('booking.list.error_choose_one'));
            } else {
                setShowPDFExportModal(true);
            }
        } else if (reportType === "allocation") {
            setSalesAllocationReportType("allocation");
            setShowSalesAllocationReportModal(true);
        } else if (reportType === "booking") {
            const items = checkedBookings;
            if (items.length === 0) {
                Notification.Show(t('booking.list.error_choose_one'));
            } else {
                setShowBookingsReportModal(true);
            }
        }
    }

    const onInvoiceSelected = async (type) => {
        const invItems = _.filter(checkedBookings, (i) => i.invoice);
        const groups = _.groupBy(checkedBookings, (i) => i.groupId);
        const parentGroupOrGroups = _.groupBy(checkedBookings, (i) => {
            if (i.bookingGroups && i.bookingGroups.length > 0) {
                return i.bookingGroups[0].parentGroupId || i.bookingGroups[0].groupId
            } else {
                return i.groupId;
            }
        });
        let numberOfGroups = Object.keys(groups).length;
        if (numberOfGroups > 1) {
            // Take a shot to see if there are some subgroups
            if (Object.keys(parentGroupOrGroups).length === 1) {
                numberOfGroups = 1;
            }
        }
        if (checkedBookings.length === 0) {
            Notification.Show(t('booking.list.error_choose_one'));
        } else if (invItems.length > 0) {
            Notification.Show(t('booking.list.error_invoiced_already'));
        } else if (type === "existing") {
            if (numberOfGroups > 1) {
                Notification.Show(t('booking.list.error_one_group_only'));
            } else {
                setShowAddBookingsToInvoiceModal(true);
            }
        } else if (type === "new" && numberOfGroups === 1) {
            const isCheckedAll = _.includes(checkedBookingIds, "checkall")
            const groupId = ((Object.keys(groups).length > 1) && (Object.keys(parentGroupOrGroups).length === 1)) ? Object.keys(parentGroupOrGroups)[0] : Object.keys(groups)[0];
            let bids = [];
            if (isCheckedAll) {
                bids = await getAllBookingIdsForCriteria(getQueryParams());
            } else {
                bids = checkedBookingIds;
            }
            navigate(getInvoiceCreationUrl(facilityLink, groupId, bids));
        } else {
            const d = {}
            if (checkedBookingIds.includes("checkall")) {
                d['criteria'] = getQueryParams()
            } else {
                d['forExport'] = {
                    ids: checkedBookingIds
                }
            }
            serverPost(getApiUrl("/bookings/mass_invoice_list"), d).then(res => {
                setMassBookingGroupingData(res);
                setShowMassInvoicesModal(true);
            })
        }
    }

    const onAgreementSelected = async (type) => {
        const agrItems = _.filter(checkedBookings, (i) => i.agreement);
        const groups = _.groupBy(checkedBookings, (i) => i.groupId);
        const parentGroupOrGroups = _.groupBy(checkedBookings, (i) => {
            if (i.bookingGroups && i.bookingGroups.length > 0) {
                return i.bookingGroups[0].parentGroupId || i.bookingGroups[0].groupId
            } else {
                return i.groupId;
            }
        });
        let numberOfGroups = Object.keys(groups).length;
        if (numberOfGroups > 1) {
            // Take a shot to see if there are some subgroups
            if (Object.keys(parentGroupOrGroups).length === 1) {
                numberOfGroups = 1;
            }
        }
        if (checkedBookings.length === 0) {
            Notification.Show(t('booking.list.error_choose_one'));
        } else if (agrItems.length > 0) {
            Notification.Show(t('booking.list.error_agreemented_already'));
        } else if (type === "existing") {
            if (numberOfGroups > 1) {
                Notification.Show(t('booking.list.error_one_group_only'));
            } else {
                setShowAddBookingsToAgreementModal(true);
            }
        } else if (type === "new" && numberOfGroups === 1) {
            const isCheckedAll = _.includes(checkedBookingIds, "checkall")
            const groupId = Object.keys(groups)[0];
            let bids = [];
            if (isCheckedAll) {
                bids = await getAllBookingIdsForCriteria(getQueryParams());
            } else {
                bids = checkedBookingIds;
            }
            navigate(getAgreementCreationUrl(facilityLink, groupId, bids));
        } else {
            const d = {}
            if (checkedBookingIds.includes("checkall")) {
                d['criteria'] = getQueryParams()
            } else {
                d['forExport'] = {
                    ids: checkedBookingIds
                }
            }
            serverPost(getApiUrl("/bookings/mass_invoice_list"), d).then(res => {
                setMassBookingGroupingData(res);
                setShowMassAgreementsModal(true);
            })
        }
    }

    const getAllBookingIdsForCriteria = async (criteria) => {
        const searchUrl = getApiUrl('/bookings/search');
        let searchStart = 0;
        let hasMore = true;
        let allBids = [];
        const limit = 100;
        while (hasMore) {
            let searchData = {
                pagination: {
                    from: searchStart,
                    limit: limit
                },
                query: getQueryParams()
            };
            let searchResult = await serverPost(searchUrl, searchData);
            if (searchResult) {
                hasMore = searchResult.results.length >= limit;
                searchStart += searchResult.results.length;
                allBids = _.concat(allBids, _.map(searchResult.results, (r) => r.id))
            } else {
                hasMore = false;
            }
        }
        return allBids;
    }

    const onEdit = () => {
        if (checkedBookingIds.length === 0) {
            Notification.Show(t('booking.list.error_choose_one'));
        } else {
            setShowBulkBookingsModal(true);
        }
    }

    const onPushToPartner = () => {
        if (checkedBookingIds.length === 0) {
            Notification.Show(t('booking.list.error_choose_one'));
        } else {
            setShowPushToPartnerModal(true);
        }
    }

    const onUnlinkFromPartner = () => {
        if (checkedBookingIds.length === 0) {
            Notification.Show(t('booking.list.error_choose_one'));
        } else {
            setShowUnlinkPartnerModal(true);
        }
    }

    const onDelete = () => {
        if (checkedBookingIds.length === 0) {
            Notification.Show(t('booking.list.error_choose_one'));
        } else {
            setShowCancelBulkBookingsModal(true);
        }
    }

    const onKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    }

    const getQueryParams = () => {
        return {
            groupName: query,
            paidFilter: paidFilter,
            dateFilter: dateFilter ? {filterType: dateFilter, startDate: startDate, endDate: endDate} : null,
            timeFilter: timeFilter ? {filterType: timeFilter, startTime: startTime, endTime: endTime} : null,
            agrInvFilter: agrInvFilter,
            venueFilter: venueFilter,
            colorFilter: colorFilter,
            partnerMappingFilter: connectedFilter,
            eventFilter: eventFilter,
            groupId: groupId,
            activeFilter: editFilter,
            searchAllQuery: searchAllQuery,
            marginBlockSyncFilter: marginBlockSyncFilter,
            sort: sortFilter,
            exportableToPartner: true
        }
    }

    const onSearch = (restart = true) => {
        if (loadingEventTypes || loadingVenues) {
            return;
        }
        if (dateFilter === "dateRange" && (_.isNil(startDate) || _.isNil(endDate))) {
            return;
        }
        if (timeFilter === "timeRange" && (_.isNil(startTime) || _.isNil(endTime))) {
            return;
        }
        if (!_.isEmpty(eventTypes) && _.isEmpty(eventFilter)) {
            Notification.Show(t('booking.list.error_select_event_type'));
            return;
        }
        if (!_.isEmpty(venues) && _.isEmpty(venueFilter)) {
            Notification.Show(t('booking.list.error_select_venue'));
            return;
        }
        if (!groupId) {
            // Cache the options
            localStorage.setItem(facilityLink + "_filter_cache_bookings_partner", JSON.stringify(getQueryParams()));
        }

        const searchUrl = getApiUrl('/bookings/search');
        const data = {
            pagination: {
                from: restart ? 0 : (bookings.length || 0),
                limit: limit
            },
            query: getQueryParams()
        };
        if (restart) {
            setBookings([]);
            setMeta({});
        }
        serverPost(searchUrl, data).then((res) => {
            if (res) {
                setHasMore(res.results.length >= limit);
                if (restart) {
                    setBookings(res.results);
                } else {
                    setBookings(_.concat(bookings, res.results))
                }
                if (!_.isEmpty(res.meta) && restart) {
                    const data = {};
                    if (res.meta.totalCost) { data['totalCost'] = res.meta.totalCost; }
                    if (res.meta.numberOfBookings) { data['numberOfBookings'] = res.meta.numberOfBookings; }
                    if (res.meta.totalHours) { data['totalHours'] = res.meta.totalHours; }
                    if (!_.isEmpty(data)) {
                        setMeta(data);
                    }
                }
            }
        })
    }

    const updateChecked = (event) => {
        if (event.target.value === "checkall") {
            let newCheckedBookings = [];
            if (event.target.checked) {
                newCheckedBookings = _.map(bookings, (b, i) => getRowId(b));
                newCheckedBookings.push("checkall");
            }
            setCheckedBookingIds(newCheckedBookings);
        } else {
            const newCheckedBookings = [...checkedBookingIds];
            if (event.target.checked) {
                newCheckedBookings.push(event.target.value);
            } else {
                let index = newCheckedBookings.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedBookings.splice(index, 1);
                }
                index = newCheckedBookings.indexOf("checkall");
                if (index > -1) {
                    newCheckedBookings.splice(index, 1);
                }
            }
            setCheckedBookingIds(newCheckedBookings);
        }
    }

    const onCancelBooking = (booking) => {
        setBookingToEdit(booking);
        setShowCancelBookingModal(true);
    }

    const onEditBooking = (booking) => {
        setBookingToEdit({ ...booking, extendedProps: { ...booking }});
        setShowAddOrEditBookingModal(true);
    }

    const onShowCancelledBooking = (booking) => {
        setBookingToEdit(booking);
        setShowCancelledBookingModal(true);
    }

    const onRestoreBooking = (booking) => {
        const data = {
            ids: [booking.id],
            updateInvoice: false,
            updateAgreement: false,
            notifyCustomer: false,
        }
        serverPost(getApiUrl(`/bookings/restore`), data).then((res) => {
            if (res){
                notifyEvent("bookings");
                onSearch(true);
                Notification.Show(t('booking.list.successfully_restored'));
            }
        });
    }

    const onModalClose = (didUpdate) => {
        setShowCancelBookingModal(false);
        setShowCancelledBookingModal(false);
        setShowCancelBulkBookingsModal(false);
        setShowAddBookingsToInvoiceModal(false);
        setShowAddBookingsToAgreementModal(false);
        setShowAddOrEditBookingModal(false);
        setShowMassInvoicesModal(false);
        setShowMassAgreementsModal(false);
        setShowBulkBookingsModal(false);
        setShowPushToPartnerModal(false);
        setShowUnlinkPartnerModal(false);
        if (didUpdate) {
            setCheckedBookingIds([]);
            onSearch();
        }
    }

    const getGroupNameContent = (booking) => {
        if (!hasAccess("customers", userInfo, "view")) {
            return <span>{ booking.groupName }</span>;
        } else {
            if (booking.isMultiUser) {
                // TODO(aswin): Show multiple users here
                return (<Link to={`/${facilityLink}/booking/list?groupId=${booking.groupId}`}>{ booking.groupName }</Link>);
            } else {
                return (<Link to={`/${facilityLink}/booking/list?groupId=${booking.groupId}`}>{ booking.groupName }</Link>);
            }
        }
    }

    const getPaidContent = (booking) => {
        if (booking.isPaid) {
            return (<i className="fa fa-check-circle fa-c-success fs-16"></i>);
        } else if (booking.paymentMethod === "hold") {
            return (<i className="fa fa-pause fs-16"></i>);
        } else if (booking.paymentMethod === "request") {
            return (<i className="fa fa-flag fs-16"></i>);
        } else {
            return (<span className="label">{t('common.no')}</span>);
        }
    }

    const getTotalForBooking = (booking) => {
        return booking.cost + _.sumBy(booking.addons, 'total');
    }

    const renderPartnerStatus = (row) => {
        if (_.isNil(row.partnerMapping) || _.isEmpty(row.partnerMapping)) {
            return null;
        }

        const mapping = row.partnerMapping[0];
        if (mapping.status === "success") {
            return <div><i className="fa fa-check-circle fa-c-success fs-16"></i></div>
        } else {
            let errorMessage = "Unable to push booking"
            if (mapping.errorMessage) {
                try {
                    const jsonErrorMessage = JSON.parse(mapping.errorMessage);
                    if (jsonErrorMessage && jsonErrorMessage.responseBody) {
                        try {
                            const responseMessage = JSON.parse(jsonErrorMessage.responseBody);
                            if (responseMessage && responseMessage.error) {
                                errorMessage = responseMessage.error
                            }
                        } catch (e) {
                            errorMessage = jsonErrorMessage.responseBody;
                            console.error(e); // error in the above string (in this case, yes)!
                        }
                    }
                } catch (e) {
                    console.error(e); // error in the above string (in this case, yes)!
                }
            }
            console.log("The error message is " + row.id + ", " + typeof(errorMessage) + ", " + errorMessage);
            return <div>
                <BaseOverlayTrigger content={errorMessage}>
                    <i className="fa fa-exclamation-triangle fa-c-danger fs-16"></i>
                </BaseOverlayTrigger>
            </div>
        }
    }

    function getRowId(booking) {
        return ("" + booking.id);
    }

    const editFilterOptions = [
        { 'id': "active", 'label': t("booking.list.active") },
        { 'id': "cancelled", 'label': t("booking.list.cancelled") },
        { 'id': "overlap", 'label': t("booking.list.overlap") },
        { 'id': "overlapa", 'label': t("booking.list.overlap_A") },
        { 'id': "overlapc", 'label': t("booking.list.overlap_C") },
    ];

    const connectedOptions = [
        { 'id': "connected", 'label': t("booking.list.connected") },
        { 'id': "notConnected", 'label': t("booking.list.not_connected") },
    ];

    const timeFilterOptions = [
        { 'id': "prime", 'label': t("common.prime") },
        { 'id': "nonPrime", 'label': t("common.non_prime") },
        { 'id': "dailyTime", 'label': t("common.daily_time") },
        { 'id': "timeRange", 'label': t("common.time_range") },
    ];

    const dateFilterOptions = [
        { 'id': "future", 'label': t("common.future") },
        { 'id': "today", 'label': t("common.today") },
        { 'id': "tomorrow", 'label': t("common.tomorrow") },
        { 'id': "yesterday", 'label': t("common.yesterday") },
        { 'id': "thisWeek", 'label': t("common.this_week") },
        { 'id': "nextWeek", 'label': t("common.next_week") },
        { 'id': "lastWeek", 'label': t("common.last_week") },
        { 'id': "thisMonth", 'label': t("common.this_month") },
        { 'id': "nextMonth", 'label': t("common.next_month") },
        { 'id': "lastMonth", 'label': t("common.last_month") },
        { 'id': "dateRange", 'label': t("common.date_range") },
    ];

    const colorOptions = () => {
        const options = getColorOptions(t);
        for (const op of options) {
            op['icon'] = "fa-circle";
            op['style'] = { color: op['value'] };
        }
        return options;
    }

    const reportOptions = [
        { 'id': "csv", 'label': t("booking.list.csv_report"), 'icon': 'fa-list' },
        { 'id': "pdf", 'label': t("booking.list.pdf_report"), 'icon': 'fa-list' },
    ];
    if (hasAccess("booking", userInfo)) {
        reportOptions.push({ 'id': "sales", 'label': t("booking.list.sales_report"), 'icon': 'fa-bar-chart' });
        reportOptions.push({ 'id': "allocation", 'label': t("booking.list.alloc_report"), 'icon': 'fa-clock' });
    }

    const sortOptions = [
        { 'id': "idDesc", 'label': t("common.id_desc") },
        { 'id': "startTimeAsc", 'label': t("booking.list.start_time_asc") },
        { 'id': "startTimeDesc", 'label': t("booking.list.start_time_desc") },
        { 'id': "amountAsc", 'label': t("booking.list.amount_asc") },
        { 'id': "amountDesc", 'label': t("booking.list.amount_desc") },
    ];

    const marginBlockSyncOptions = [
        { 'id': "mainonly", 'label': t("booking.list.mainonly") },
        { 'id': "marginonly", 'label': t("booking.list.marginonly") },
        { 'id': "blocksynconly", 'label': t("booking.list.blocksynconly") },
    ];

    const dateFormat = getDateFormatForFacility(settings);
    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        groupId: groupId,
        userGroup: userGroup,
        userInfo: userInfo,
        settings: settings
    };

    const isPartnerOrSuperAdmin = settings.isPartnerFacility && (isFullAdmin(userInfo) || userInfo.isSuperAdmin);
    let showPartnerColumn = isPartnerOrSuperAdmin && !_.isEmpty(_.filter(bookings, (b) => b.partnerMappings && !_.isEmpty(b.partnerMappings)));
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "bookings", tabsParams)} onModalClose={onModalClose} />
            <div className="content-box">
                <div className="content-body">
                    <div className="d-flex flex-row gap-12 flex-wrap">
                        <div className="flex-grow-0 d-flex flex-row">
                            <div>
                                <InputGroup className="inline">
                                    <Form.Control type="text" className="inline" name="searchAllQuery" value={searchAllQuery} onChange={(event) => { setSearchAllQuery(event.target.value) }} onKeyPress={onKeyPress}/>
                                    <InputGroup.Text className="skinny" onClick={() => onSearch()}><i className="fa fa-search"/></InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="flex-grow-0 d-flex flex-row">
                            <div>
                                <ButtonGroup className="skinny">
                                    <BaseOverlayTrigger content={t("customers.list.bookings")} >
                                        <Button variant="outline-primary"><i className="fa fa-book fa-small"/> {meta.numberOfBookings}</Button>
                                    </BaseOverlayTrigger>
                                    <BaseOverlayTrigger content={t("common.hours")} >
                                        <Button variant="outline-primary"><i className="fa fa-clock fa-small"/> {meta.totalHours}</Button>
                                    </BaseOverlayTrigger>
                                    {
                                        hasAccess("booking", userInfo, "viewWithFinancials", "any") &&
                                            <BaseOverlayTrigger content={t("common.total")} >
                                                <Button variant="outline-primary"><i className="fa fa-dollar-sign fa-small"/> {meta.totalCost && currencyFormat(meta.totalCost, false)}</Button>
                                            </BaseOverlayTrigger>
                                    }
                                </ButtonGroup>
                            </div>
                        </div>
                        <div className="flex-grow-1 d-flex flex-row justify-content-end">
                            <div className="float-end d-flex align-items-center flex-wrap">
                                {
                                    settings.isPartnerFacility && (isFullAdmin(userInfo) || userInfo.isSuperAdmin) &&
                                        <Button variant="outline-primary" onClick={onPushToPartner}><Image src={"/images/la_logo.png"} width={"15px"} style={{ marginRight: "4px" }}/> Push to LeagueApps</Button>
                                }
                                {
                                    settings.isPartnerFacility && (isFullAdmin(userInfo) || userInfo.isSuperAdmin) &&
                                    <Button variant="outline-primary" onClick={onUnlinkFromPartner}><i className="fa fa-link-slash"/></Button>
                                }
                                <SingleSelectDropdown className="inline" items={getLimitOptions()} selectedId={limit} onSelect={setLimit} menuOnly/>
                                {
                                    hasAccess("booking", userInfo, "update") &&
                                    <>
                                        <Button variant="outline-primary" onClick={onEdit}><i className="fa fa-edit fa-small"/> {t("common.edit")}</Button>
                                    </>
                                }
                                <SingleSelectDropdown className="inline" toggleLabel={<span><i className="fa fa-small fa-circle-plus"/> {t("common.new_report")}</span>} items={reportOptions} onSelect={onReportSelected} align="end" menuOnly/>
                                {
                                    hasAccess("booking", userInfo, "update") &&
                                    <Button variant="danger" size="sm" onClick={() => onDelete()}><i className="fa fa-trash"/></Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <div className="d-flex flex-row gap-12 align-items-center justify-content-end flex-wrap">
                        <div className="d-flex flex-row align-items-center justify-content-end">
                            <span><strong>{t("booking.list.margins")}/{t("booking.list.blocks")}:</strong></span>
                            <div style={{ width: 200 }}>
                                <SingleSelectDropdown name="marginBlockSyncs" selectedId={marginBlockSyncFilter}
                                    allLabel={t("booking.list.all_bookings")}
                                    items={marginBlockSyncOptions} showAll onSelect={(selectedId) => {
                                    setMarginBlockSyncFilter(selectedId);
                                    setCheckedBookingIds([]);
                                }} />
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-end">
                            <span><strong>{t("common.sort_order")}:</strong></span>
                            <div style={{ width: 200 }}>
                                <SingleSelectDropdown name="sort" selectedId={sortFilter} items={sortOptions} onSelect={setSortFilter} />
                            </div>
                        </div>
                    </div>
                    <br/>
                    <InfiniteScroll
                        dataLength={bookings.length}
                        next={() => onSearch(false)}
                        hasMore={hasMore}
                        scrollableTarget="content_wrapper"
                    >
                        <Table hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t('common.group_name')}</th>
                                    <th>{t('common.venue')}</th>
                                    {
                                        showEventName &&
                                            <th>{t('common.event_name')}</th>
                                    }
                                    {
                                        hasAccess("booking", userInfo, "view", "any") &&
                                            <th>{t('booking.list.event_type')}</th>
                                    }
                                    <th>{t('common.date')}</th>
                                    <th>{t('common.time')}</th>
                                    <th>Connection Status</th>
                                    <th>{t('booking.list.booking_color')}</th>
                                    <th>{t('booking.list.status')}</th>
                                </tr>
                                <tr>
                                    <th className="controls no-stretch">
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedBookingIds.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor="checkall"/>
                                        </div>
                                    </th>
                                    <th className="controls"><Form.Control type="text" name="query" value={query} onChange={(event) => { setQuery(event.target.value) }}/></th>
                                    <th className="controls"><MultiSelectDropdown items={venues} selectedItems={venueFilter} onItemsChange={setVenueFilter} labelField="name" /></th>
                                    { showEventName && <th></th> }
                                    {
                                        hasAccess("booking", userInfo, "view", "any") &&
                                            <th className="controls"><MultiSelectDropdown items={eventTypes} selectedItems={eventFilter} onItemsChange={setEventFilter} /></th>
                                    }
                                    <th className="controls">
                                        <SingleSelectDropdown items={dateFilterOptions} selectedId={dateFilter} onSelect={dateFilterChanged} showAll />
                                        {
                                            dateFilter === "dateRange" &&
                                                <BaseForm initialFormFields={{ dateStart: startDate, dateEnd: endDate }}>
                                                    <BaseForm.Control type="text" name="dateStart" id={"dateStart"} />
                                                    <BaseForm.Control type="text" name="dateEnd" id={"dateEnd"} />
                                                    <Button variant="outline-primary" onClick={(event) => onSearch()}><i className="fa fa-refresh fa-small"/></Button>
                                                </BaseForm>
                                        }
                                    </th>
                                    <th className="controls">
                                        <SingleSelectDropdown items={timeFilterOptions} selectedId={timeFilter} onSelect={setTimeFilter} showAll />
                                        {
                                            timeFilter === "timeRange" &&
                                                <>
                                                    <SingleSelectDropdown items={timeOptions()} selectedId={startTime} onSelect={setStartTime} showSearch idField="value" />
                                                    <SingleSelectDropdown items={timeOptions()} selectedId={endTime} onSelect={setEndTime} showSearch idField="value" />
                                                    <Button variant="outline-primary" onClick={(event) => onSearch()}><i className="fa fa-refresh fa-small"/></Button>
                                                </>
                                        }
                                    </th>
                                    <th className="controls text-center"><SingleSelectDropdown items={connectedOptions} selectedId={connectedFilter} onSelect={setConnectedFilter} align={"end"} showAll /></th>
                                    <th className="controls text-center"><SingleSelectDropdown items={colorOptions()} selectedId={colorFilter} onSelect={setColorFilter} align={"end"} idField={'value'} showAll showSearch hideLabelOnHeader /></th>
                                    <th className="controls"><SingleSelectDropdown items={editFilterOptions} selectedId={editFilter} onSelect={setEditFilter} align={"end"} showAll /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.map(bookings, (booking, i) =>
                                        <tr key={i} className={classnames(booking.isCancel && "de-highlighted")}>
                                            <td className="no-stretch">
                                                <div className="checkbox check-success inline">
                                                    <input type="checkbox" className="" value={getRowId(booking)} id={getRowId(booking)} name="delete[]"  checked={checkedBookingIds.includes(getRowId(booking))} onChange={ (event) => updateChecked(event) }/>
                                                    <label htmlFor={getRowId(booking)}/>
                                                </div>
                                            </td>
                                            <td>
                                                { getGroupNameContent(booking) }
                                            </td>
                                            <td>{ booking.venueName }</td>
                                            {
                                                showEventName &&
                                                    <td>{ booking.eventName }</td>
                                            }
                                            <td>
                                            {
                                                hasAccess("booking", userInfo, "view", booking.venueId) && booking.eventTypeName
                                            }
                                            </td>
                                            <td>
                                                <Link to={`/${facilityLink}/index?lid=${booking.id}`}>
                                                    <span>{ moment(booking.startTimeLocal).format(dateFormat) }</span>
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={`/${facilityLink}/index?lid=${booking.id}`}>
                                                    <span>{ moment(booking.startTimeLocal).format("h:mma") + " - " + moment(booking.endTimeLocal).format("h:mma") }</span>
                                                </Link>
                                            </td>
                                            <td className="text-center">
                                                { renderPartnerStatus(booking) }
                                            </td>
                                            <td className="text-center"><i className="fa fa-circle fs-16" style={{ color: (booking.color || getDefaultColor(t)) }} /></td>
                                            <td className="text-center">
                                            {
                                                hasAccess("booking", userInfo, "update", booking.venueId) && (
                                                    booking.isCancel ?
                                                    <ButtonToolbar>
                                                        <Button variant="primary" onClick={() => onShowCancelledBooking(booking)}><i className="fa fa-edit"/></Button>
                                                        <ConfirmationButton variant="outline-primary" onConfirm={() => onRestoreBooking(booking)}><i className="fa fa-undo"/></ConfirmationButton>
                                                    </ButtonToolbar>
                                                :
                                                    <ButtonToolbar>
                                                        <Button variant="primary" onClick={() => onEditBooking(booking)}><i className="fa fa-edit"/></Button>
                                                        <Button variant="outline-primary" onClick={() => onCancelBooking(booking)}><i className="fa fa-remove"/></Button>
                                                    </ButtonToolbar>
                                                )
                                            }
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    hasMore ?
                                        <tr>
                                            <td colSpan={25} className="center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                    : <tr>
                                            <td colSpan={25} className="center">
                                                <div className="label">{t('booking.search.end_of_bookings')}</div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </InfiniteScroll>
                </div>
            </div>
            <AddOrEditBookingModal
                show={showAddOrEditBookingModal}
                onClose={onModalClose}
                booking={bookingToEdit}/>
            <AddBookingsToInvoiceModal
                facilityLink={facilityLink}
                show={showAddBookingsToInvoiceModal}
                onClose={onModalClose}
                bookings={checkedBookings}
                groupId={!_.isEmpty(checkedBookings) && checkedBookings[0].groupId}/>
            <AddBookingsToAgreementModal
                facilityLink={facilityLink}
                show={showAddBookingsToAgreementModal}
                onClose={onModalClose}
                bookings={checkedBookings}
                groupId={!_.isEmpty(checkedBookings) && checkedBookings[0].groupId}/>
            <MassInvoiceCreationModal
                show={showMassInvoicesModal}
                onClose={onModalClose}
                bookingGroupingData={massBookingGroupingData} />
            <MassAgreementCreationModal
                show={showMassAgreementsModal}
                onClose={onModalClose}
                bookingGroupingData={massBookingGroupingData} />
            <EditBulkBookingsModal
                show={showBulkBookingsModal}
                onClose={onModalClose}
                bookings={checkedBookings} />
            <SalesAllocationReportModal
                show={showSalesAllocationReportModal}
                onClose={setShowSalesAllocationReportModal}
                type={salesAllocationReportType} />
            <CancelBookingModal
                show={showCancelBookingModal}
                onClose={onModalClose}
                booking={bookingToEdit} />
            <CancelledBookingModal
                show={showCancelledBookingModal}
                onClose={onModalClose}
                booking={bookingToEdit} />
            <BookingsCSVExportModal
                show={showCSVExportModal}
                onClose={setShowCSVExportModal}
                items={checkedBookings}
                criteria={getQueryParams()}
                useCriteria={checkedBookingIds.includes("checkall")} />
            <BookingsPDFExportModal
                show={showPDFExportModal}
                onClose={setShowPDFExportModal}
                items={checkedBookings}
                criteria={getQueryParams()}
                useCriteria={checkedBookingIds.includes("checkall")} />
            <CancelBulkBookingModal
                show={showCancelBulkBookingsModal}
                onClose={onModalClose}
                bookingIds={_.filter(checkedBookingIds, (f) => f !== "checkall")} />
            <PushToPartnerConfirmationModal
                show={showPushToPartnerModal}
                onClose={onModalClose}
                bookings={checkedBookings}/>
            <UnlinkPartnerConfirmationModal
                show={showUnlinkFromPartnerModal}
                onClose={onModalClose}
                bookings={checkedBookings}/>
        </BaseContainer>
    );
}

export default List;
