import '../App.scss';
import LoginComponent from './LoginComponent';

function LoginPopup(props) {

    return (
        <div className="dropdown-menu dropdown-bar login-dropdown">
            <LoginComponent />
        </div>
    );
}

export default LoginPopup;
