import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import BaseContainer from '../../components/Container';
import Notification from '../../components/Notification';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import ErrorComponent from '../../components/ErrorComponent';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useSearchParams, useNavigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function ChangePassword() {
    const { getFacilityName, getApiUrl, facilityLink } = useContext(BaseContext);
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const [userGroup, setUserGroup] = useState({});
    const [ searchParams ] = useSearchParams();
    const token = searchParams.get('token');

    useEffect(() => {
        document.title = t('password_reset.page_title', { facilityName: getFacilityName() })
    }, [userGroup]);

    const onError = async (response, errorMessage) => {
        if (errorMessage.token) {
            Notification.Show(errorMessage.token);
        } else if (errorMessage.newPassword) {
            Notification.Show(errorMessage.newPassword);
        }
    }

    const onSubmit = (data) => {
        const resetData = {
            ...data,
            token: token
        }
        serverPost(getApiUrl("/user/reset_password"), resetData, {}, onError).then((res) => {
            if (res) {
                Notification.Show(t('password_reset.successfully_reset_password'))
                setTimeout(() => {
                    navigate('/' + facilityLink);
                }, 2000);
            }
        })
    }

    const tabItems = [
        { label: t('password_reset.reset'), active: true }
    ];

    return (
        <BaseContainer>
            {
                _.isEmpty(token) ?
                    <ErrorComponent title={t('password_reset.link_not_valid')}/>
                : <>
                    <TabHeader items={tabItems} />
                    <div className="content-box">
                        <div className="content-body">
                            <BaseForm onSubmit={onSubmit}>
                                <Row>
                                    <BaseForm.Input colSpan="6" type="password" name="newPassword" label={t('password_reset.new_password')}
                                        validations={{ required: true, minLength: 8 }} />
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <SubmitButton variant="primary">{t('password_reset.update_password')}</SubmitButton>
                                    </Col>
                                </Row>
                            </BaseForm>
                        </div>
                    </div>
                </>
            }
        </BaseContainer>
    );
}

export default ChangePassword;
