import { Button } from 'react-bootstrap';
import '../App.scss';
import '../css/forms.scss';
import '../js/forms.js';
import BaseForm from './BaseForm';
import { useState, useEffect, useContext } from 'react';
import { serverFetch, serverPost, getUrl } from '../helpers/server';
import { storeUserInfo } from '../helpers/storage';
import { BaseContext } from '../helpers/common';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SignupModal from './modals/SignupModal';
import SubmitButton from './SubmitButton';
import ForgotPasswordModal from './modals/ForgotPasswordModal';
import classnames from 'classnames';
const _ = require("lodash");

function LoginComponent(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    let { facilityLink } = useParams();
    const [settings, setSettings] = useState({});
    const [loginError, setLoginError] = useState(null);
    const [errorFields, setErrorFields] = useState({});
    const [showSignup, setShowSignup] = useState(true);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [showSignupModal, setShowSignupModal] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(settings)) {
            if (settings.disableSignup) {
                setShowSignup(false);
                return
            }
        }
        if (!_.isNil(props.showSignup)) {
            setShowSignup(props.showSignup);
        }
    }, [props.showSignup, settings]);

    useEffect(() => {
        serverFetch(getApiUrl('')).then((res) => {
            setSettings(res);
            if (res.disableSignup) {
                setShowSignup(false);
            }
        })
    }, []);

    const login = (loginData) => {
        const loginUrl = getApiUrl('/login', false, false)
        const data = {
            'email': loginData.email,
            'password': loginData.password
        }
        const onError = (res) => {
            if (res.status === 401) {
                setLoginError("Invalid email or password.")
            }
        };
        setLoginError(null);
        serverPost(loginUrl, data, { suppressUnauthenticated: true }, onError).then((res) => {
            if (res && res['token']) {
                localStorage.setItem("auth_token", res['token']);
                if (res['user']) {
                    storeUserInfo(res['user']);
                }
                if (props.onSuccess) {
                    props.onSuccess();
                } else {
                    window.location.href = '/' + facilityLink;
                }
            } else {
                // Show error
            }
        })
    }

    const onHideSignupAndShowForgotPassword = () => {
        setShowSignupModal(false);
        setShowForgotPasswordModal(true);
    }

    return (
        <div className="panel-body">
            <BaseForm onSubmit={login} errorFields={!_.isNil(loginError) && { email: "", password: "" }}>
                <BaseForm.Input colSpan={12} name={'email'} label={t('forms.email')} placeholder="Email Address" type={'text'} required validations={{validEmail: true}} />
                <BaseForm.Input colSpan={12} name={'password'} label={t('common.password')} placeholder="Password" type={'password'} required />
                <SubmitButton className="btn-complete">{t('common.login')}</SubmitButton>
            </BaseForm>
            <div className={classnames(showSignup ? "pt-4 pb-4": "")}>
                {
                    loginError &&
                        <p className="form-error-message">{loginError}</p>
                }
                <Button variant="link" className="float-start skinny"  onClick={() => setShowForgotPasswordModal(true)}>{t('common.forgot_password')}</Button>
            {
                showSignup &&
                    <Button variant="outline-primary" className="float-end" onClick={() => setShowSignupModal(true)}>{t('common.signup')}</Button>
            }
            </div>
            <SignupModal show={showSignupModal} onClose={setShowSignupModal} facilityLink={facilityLink} onShowPasswordReset={onHideSignupAndShowForgotPassword} />
            <ForgotPasswordModal show={showForgotPasswordModal} onClose={setShowForgotPasswordModal} />
        </div>
    );
}

export default LoginComponent;
