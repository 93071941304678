import '../../App.scss';
import '../../css/modals.scss';
import 'magicsuggest/magicsuggest.js';
import 'jquery-ui/ui/widgets/autocomplete';
import React, { useEffect, useState, useContext, createRef, useRef } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import BookingPaymentInput from '../BookingPaymentInput';
import { Button, Row, Container, Alert, Col, Table } from 'react-bootstrap';
import { serverPost } from '../../helpers/server';
import {
    BaseContext, currencyFormat, getDateFormatForFacility
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {useNavigate, useParams} from "react-router-dom";
const _ = require("lodash");
var cache = require('js-cache');

function BookingPaymentModal(props) {
    const { getApiUrl, settings } = useContext(BaseContext);
    const { facilityLink } = useParams();
    const [initialFields, setInitialFields] = useState({});
    const formRef = createRef();
    const paymentRef = createRef();
    const [primaryGroup, setPrimaryGroup] = useState(null);
    const [error, setError] = useState(null);
    const [booking, setBooking] = useState(null);
    const [isValid, setIsValid] = useState(true);
    const { t } = useTranslation('common');

    useEffect(() => {
        setBooking(props.booking);
        if (!_.isNil(props.booking)) {
            setIsValid(!_.isEmpty(props.booking));
            setInitialFields({
                groupName: props.booking.groupName,
                costDescription: `${ currencyFormat(props.booking.preCost) } + ${ currencyFormat(props.booking.totalTax) } = ${ currencyFormat(props.booking.cost) }`
            })
        } else {
            setInitialFields({})
        }
    }, [props.booking]);

    useEffect(() => {
        if (!props.show) {
            setInitialFields({
                "0": {
                    eventTypeId: null
                }
            });
            setPrimaryGroup(null);
        }
    }, [props.show]);

    const onFieldChange = (name, value) => {
        if (name === "groups") {
        }
        if (paymentRef.current) {
            paymentRef.current.onFieldChange(name, value);
        }
    }

    const onError = async (response, errorMessage) => {
        if (!_.isEmpty(errorMessage.overlap)) {
            Notification.ModalShow("Please select options for the following overlaps");
            return false;
        } else {
            let message = null;
            if (errorMessage.bPadding) {
                message = "Some settings were changed (potentially in a different window). Please refresh the page and try this again."
            }
            setError({
                message: message,
                ...errorMessage,
                "0": {
                    ...errorMessage
                }
            })
        }
    }

    const addBooking = async (data) => {
        // const formData = formRef.current.getFormData();
        let bookingPaymentMethod = null;
        if (paymentRef.current) {
            bookingPaymentMethod = await paymentRef.current.getPaymentMethod(data);
        }
        if (!bookingPaymentMethod) {
            console.log("No booking payment method");
            return;
        }
        setError(null);

        const bookingData = {}
        if (settings.paymentProcessor === "STRIPE") {
            bookingData["paymentData"] = {
                stripe: bookingPaymentMethod
            }
            bookingData["paymentMethod"] = "stripe";
        } else if (settings.paymentProcessor === "BEANSTREAM") {
            bookingData["paymentData"] = {
                bambora: {
                    ...bookingPaymentMethod,
                }
            }
            bookingData["paymentMethod"] = "bambora";
        }
        const bookingResult = await serverPost(getApiUrl(`/bookings/${booking.bookingId}/pay_online`), bookingData, {}, onError);
        if (bookingResult) {
            props.onClose(true);
            let redirectTo = `/${facilityLink}`;
            if (booking.bookingId) {
                redirectTo += `?lid=${booking.bookingId}`
            }
            window.location = redirectTo;
        }
    }

    const onClose = (event) => {
        if (props.onClose) {
            props.onClose(false);
            let redirectTo = `/${facilityLink}`;
            if (booking.bookingId) {
                redirectTo += `?lid=${booking.bookingId}`
            }
            window.location = redirectTo;
        }
    }

    const dateFormat = getDateFormatForFacility(settings);
    return (
        <BaseModal {...props} size="lg">
        {
            () =>
                <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={addBooking} ref={formRef} errorFields={error}>
                    <BaseModal.Header>
                        {
                            isValid && !booking.isPaid &&
                                <BaseModal.Title>{ booking.venueName } - {moment(booking.startTimeLocal).format(dateFormat)} { moment(booking.startTimeLocal).format("h:mma") + " - " + moment(booking.endTimeLocal).format("h:mma") }</BaseModal.Title>
                        }
                        {
                            isValid && booking.isPaid &&
                                <BaseModal.Title>Payment for Booking: #{ booking.bookingId }</BaseModal.Title>
                        }
                        {
                            !isValid &&
                                <BaseModal.Title>Payment for Booking:</BaseModal.Title>
                        }
                    </BaseModal.Header>
                    <BaseModal.Body>
                        {
                            isValid && !booking.isPaid &&
                                <div>
                                    <h2>Credit Card</h2>
                                    <BookingPaymentInput ref={paymentRef} prefix="ccOnline" groupId={primaryGroup && primaryGroup.gid} total={booking.cost} />
                                </div>
                        }
                        {
                            isValid && booking.isPaid &&
                                <div>
                                    <p>This booking has already been paid. Please reach out to the facility admin if you require further assistance.</p>
                                </div>
                        }
                        {
                            !isValid &&
                                <div>
                                    <p>This booking is either invalid or is not ready for payment collection. Please reach out to the facility admin if you require further assistance.</p>
                                </div>
                        }
                        {
                            error && error.message &&
                                <span className="form-error-message">{ error.message }</span>
                        }
                    </BaseModal.Body>
                    <BaseModal.Footer>
                        <Container>
                            {
                                !_.isEmpty(error) &&
                                    <Row className="form-error-message justify-content-end">
                                        { error.card }
                                    </Row>
                            }
                            {
                                isValid && !booking.isPaid &&
                                    <Row>
                                        <BaseForm.Input colSpan="4" name="groupName" label={"Group"} type="text" readOnly />
                                        <BaseForm.Input colSpan="4" name="costDescription" label="Total Cost" type="text" readOnly={true} />
                                        <Col md="4" className="d-flex align-items-center">
                                            <SubmitButton className="btn-complete">Pay Now</SubmitButton>
                                        </Col>
                                    </Row>
                            }
                            {
                                (!isValid || booking.isPaid) &&
                                    <Row>
                                        <Col md="8"/>
                                        <Col md="4" className="d-flex align-items-center justify-content-end">
                                            <Button onClick={onClose}>Close</Button>
                                        </Col>
                                    </Row>
                            }
                        </Container>
                    </BaseModal.Footer>
                </BaseForm>
            }
        </BaseModal>
    );

}

export default BookingPaymentModal;
