import '../../App.scss';
import '../../css/modals.scss';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState, useRef, useMemo, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import DeleteButton from '../DeleteButton';
import Notification from '../Notification';
import TabHeader from '../TabHeader';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Select from 'react-select/async'
import {getFormattedName, BaseContext, getFormattedImageLink, downloadBlob} from '../../helpers/common';
import {serverFetch, serverPost, serverPatch, serverDelete} from '../../helpers/server';
import {Button, Row, Col, Table, Alert} from 'react-bootstrap';
import classnames from 'classnames';
import moment from 'moment';
import SwitchUserModal from "./SwitchUserModal";
import BlockUserGroupModal from "./BlockUserGroupModal";

const _ = require('lodash');

function EditCustomerProfileModal(props) {
    const {getApiUrl} = useContext(BaseContext);
    const {t} = useTranslation('common');
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(null);
    const [customerTypes, setCustomerTypes] = useState([]);
    const [user, setUser] = useState({});
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [subgroupToAdd, setSubgroupToAdd] = useState(null);
    const [userGroup, setUserGroup] = useState(null);
    const [subGroups, setSubGroups] = useState([]);
    const [otherGroups, setOtherGroups] = useState([]);
    const [uploads, setUploads] = useState([]);
    const [showSwitchUserModal, setShowSwitchUserModal] = useState(false);
    const [showBlockUserGroupModal, setShowBlockUserGroupModal] = useState(false);
    const [showParentGroup, setShowParentGroup] = useState(false);

    const selectRef = useRef(null);
    const preferredGroupIdToLoad = useRef(null);
    const newGroupFields = useMemo(() => {
        return {
            id: "__new__",
            isNew: true,
            groupName: "New Group"
        }
    }, []);

    useEffect(() => {
        if (props.show) {
            serverFetch(getApiUrl(`/customer_types`)).then((res) => {
                setCustomerTypes(res);
            })
        }
    }, [props.show]);

    useEffect(() => {
        if (props.groupId) {
            serverFetch(getApiUrl(`/user_groups/${props.groupId}`), {skipCache: props.show}).then((res) => {
                setUserGroup(res);
                const user = res.user;
                const parentGroup = res.parentGroup;
                if (parentGroup != null) setShowParentGroup(true);
                user.fullName = getFormattedName(user);
                setUser(user);
            })
        }
    }, [props.groupId, props.show]);

    useEffect(() => {
        if (props.userId) {
            serverFetch(getApiUrl(`/users/${props.userId}`)).then((res) => {
                setUser(res);
            })
        }
    }, [props.userId]);

    useEffect(() => {
        if (user && !_.isEmpty(user)) {
            fetchUserGroups();
        }
    }, [user]);

    useEffect(() => {
        if (props.show) {
            setCurrentTab("details");
        } else {
            setCurrentTab(null);
        }
    }, [props.show]);

    useEffect(() => {
        if (currentTab === "uploads" && !_.isEmpty(selectedGroup)) {
            fetchUploads();
        } else if (currentTab === "subgroups" && !_.isEmpty(selectedGroup)) {
            fetchSubgroups();
        }
    }, [currentTab, selectedGroup]);

    const updateCustomer = (data) => {
        const userData = {...data};
        const [firstName, lastName] = data.fullName.split(' ');
        userData.firstName = firstName;
        userData.lastName = lastName;
        serverPatch(getApiUrl(`/users/${user.id}`), userData).then((res) => {
            Notification.ModalShow("User successfully updated");
            props.onClose(true);
        })
    }

    const onGroupFieldChange = (name, value) => {
        if (name === "id") {
            setSelectedGroup(_.find(groups, (g) => String(g.id) === String(value)));
        }
    }

    const onAddNewGroup = () => {
        setGroups((prevGroups) => {
            const newGroups = [...prevGroups];
            newGroups.push(newGroupFields);
            setSelectedGroup(newGroupFields);
            return newGroups;
        })
    }

    const updateGroup = (data) => {
        const updateData = {...data};
        if (data.id === "__new__") {
            const createData = {...data};
            createData.userId = user.id;
            serverPost(getApiUrl(`/user_groups`), createData).then((res) => {
                if (res) {
                    Notification.ModalShow("User group successfully updated");
                    preferredGroupIdToLoad.current = res.id;
                    fetchUserGroups(true);
                }
            })
        } else {
            serverPatch(getApiUrl(`/user_groups/${selectedGroup.id}`), data).then((res) => {
                if (res) {
                    Notification.ModalShow("User group successfully updated");
                    preferredGroupIdToLoad.current = selectedGroup.id;
                    fetchUserGroups(true);
                }
            })
        }
    }

    const fetchUserGroups = (skipCache = false) => {
        serverFetch(getApiUrl(`/user_groups`, {userId: user.id}), {skipCache}).then((res) => {
            if (res) {
                setGroups(res);
                if (preferredGroupIdToLoad.current) {
                    setSelectedGroup(_.find(res, (r) => String(r.id) === String(preferredGroupIdToLoad.current)));
                } else if (props.groupId) {
                    setSelectedGroup(_.find(res, (r) => String(r.id) === String(props.groupId)));
                } else {
                    setSelectedGroup(res[0]);
                }
            }
        })
    }

    const fetchUploads = (skipCache = false) => {
        serverFetch(getApiUrl(`/user_groups/${selectedGroup.id}/file_uploads`), {skipCache}).then((res) => {
            if (res) {
                setUploads(res);
            }
        })
    }

    const fetchSubgroups = (skipCache = false) => {
        serverFetch(getApiUrl(`/user_groups/${selectedGroup.id}/subgroups`), {skipCache}).then((res) => {
            if (res) {
                setSubGroups(res);
            }
        })
    }

    const fetchSubgroupsAutocomplete = async (query) => {
        return serverPost(getApiUrl('/user_groups/autocomplete'), {query: query}).then((res) => {
            const preselectedSubGroupIds = _.map(subGroups, g => g.id);
            const availableGroups = _.filter(res, r => !_.includes(preselectedSubGroupIds, r.id));
            _.each(availableGroups, r => {
                r.fullName = (r.firstName || "") + " " + (r.lastName || "");
                r.label = (
                    <>
                        <span className="title">{r.groupName}</span><br/>
                        <span className="subtitle">{r.user.email}</span>
                    </>
                );
            })
            return availableGroups;
        });
    }

    const onModalClose = (didUpdate) => {
        setShowSwitchUserModal(false);
        setShowBlockUserGroupModal(false);
        if (didUpdate) {
            if (props.onClose) {
                props.onClose(true);
            }
        }
    }

    const uploadDocument = (data) => {
        const uploadData = {
            path: data.upload,
            filename: data.upload_extra.filename,
            originalFilename: data.upload_extra.name,
            contentLength: data.upload_extra.size,
            contentType: data.upload_extra.type,
        }
        serverPost(getApiUrl(`/user_groups/${selectedGroup.id}/file_uploads`), uploadData).then((res) => {
            if (res) {
                fetchUploads(true);
            }
        })
    }

    const sendMessage = (data) => {
        if (data.method === "sendAccountDetails") {
            serverPost(getApiUrl(`/users/${user.id}/send_account_details`), data).then((res) => {
                Notification.ModalShow("User account details successfully sent");
            })
        } else if (data.method === "emailMessage") {
            serverPost(getApiUrl(`/users/${user.id}/send_email`), data).then((res) => {
                Notification.ModalShow("User email successfully sent");
            })
        } else if (data.method === "smsMessage") {

        }
    }

    const onDeleteUpload = (item) => {
        serverDelete(getApiUrl(`/user_groups/${selectedGroup.id}/file_uploads/${item.id}`)).then((res) => {
            fetchUploads(true);
        })
    }

    const onDownloadFile = (e, item) => {
        e.preventDefault()
        serverFetch(getApiUrl(`/user_groups/${selectedGroup.id}/file_uploads/${item.id}`)).then((res) => {
            fetch(res.url, {headers: res.headers}).then((res2) => {
                res2.blob().then(value => {
                    downloadBlob(value, item.filename)
                })
            })
        })
    }

    const onDeleteSubgroup = (item) => {
        serverDelete(getApiUrl(`/user_groups/${selectedGroup.id}/subgroups/${item.id}`)).then((res) => {
            fetchSubgroups(true);
        })
    }

    const onAddSubgroup = (item) => {
        const data = {
            subgroupId: subgroupToAdd.id
        }
        serverPost(getApiUrl(`/user_groups/${selectedGroup.id}/subgroups`), data).then((res) => {
            fetchSubgroups(true);
        })
    }

    const onSubgroupSelected = (group, meta) => {
        setSubgroupToAdd(group);
    }

    const unblockGroup = () => {
        serverPatch(getApiUrl(`/user_groups/${selectedGroup.id}`), { blocked: false }).then((res) => {
            if (res) {
                Notification.ModalShow("User group successfully unblocked");
                props.onClose(true);
            }
        })
    }

    const tabs = [
        {id: "details", label: t('tabs.customer_profile_modal.details'), icon: "fa-user"},
        {id: "contact", label: t('tabs.customer_profile_modal.contact'), icon: "fa-envelope"},
        {id: "uploads", label: t('tabs.customer_profile_modal.uploads'), icon: "fa-file"},
        {id: "subgroups", label: t('tabs.customer_profile_modal.subgroups'), icon: "fa-user-group"},
    ];

    const tabItems = _.map(tabs, (t) => {
        return {
            label: t.label,
            id: t.id,
            icon: t.icon,
            link: t.link,
            active: currentTab === t.id,
            onClick: (item) => {
                if (item.link) {
                    props.onClose();
                    navigate(item.link);
                } else {
                    setCurrentTab(item.id);
                }
            }
        };
    });

    const rightTabItems = [
        {
            id: "bookings",
            label: t('tabs.booking.list'),
            icon: "fa-book",
            link: `/${props.facilityLink}/booking/list?groupId=${selectedGroup && selectedGroup.id}`
        },
        {
            id: "invoices",
            label: t('tabs.customer_profile_modal.invoices'),
            icon: "fa-file",
            link: `/${props.facilityLink}/invoice/list?groupId=${selectedGroup && selectedGroup.id}`
        },
        {
            id: "agreements",
            label: t('tabs.customer_profile_modal.agreements'),
            icon: "fa-pencil-square",
            link: `/${props.facilityLink}/agreement/list?groupId=${selectedGroup && selectedGroup.id}`
        },
    ];

    const contactMethodOptions = [
        {value: "sendAccountDetails", label: t('customer_profile_modal.contact.send_account_details')},
        {value: "emailMessage", label: t('customer_profile_modal.contact.email')},
    ]

    const customerTypeOptions = _.map(customerTypes, (type) => {
        return {value: type.id, label: type.customerType};
    });
    customerTypeOptions.unshift({value: 0, label: "None"});
    const groupOptions = _.map(groups, (g) => {
        return {value: g.id, label: g.groupName};
    });

    return (
        <>
            <BaseModal size="lg" {...props} show={props.show && !showSwitchUserModal && !showBlockUserGroupModal}>
                <BaseModal.Header>
                    <BaseModal.Title>{t('customer_profile_modal.title')}</BaseModal.Title>
                </BaseModal.Header>
                <TabHeader items={tabItems} rightItems={rightTabItems} shouldAutoMatch={false} hideMore={true}/>
                <BaseModal.Body className={classnames(currentTab === "details" ? "" : "hide")}>
                    <BaseForm initialFormFields={user} onSubmit={updateCustomer}>
                        <strong>{t('customer_profile_modal.user.title')}</strong>
                        {
                            groupOptions.length > 1 &&
                                <p>{t('customer_profile_modal.user.description', { numGroups: groupOptions.length })}</p>
                        }
                        <Row>
                            <BaseForm.Input colSpan="6" type="text" name="email" label={t('forms.email')}
                                            placeholder={t('customer_profile_modal.email_description')} validations={{
                                validEmail: true,
                                userEmailCheck: {getApiUrl, except: user.email}
                            }}/>
                            <BaseForm.Input colSpan="6" type="text" name="fullName" label={t('forms.full_name')}/>
                            <BaseForm.Input colSpan="6" type="text" name="phone" label={t('common.phone')}/>
                            <BaseForm.Input colSpan="6" type="text" name="phone2" label={t('common.phone2')}/>
                            <BaseForm.Input colSpan="12" type="address" name="address" label={t('customer_profile_modal.customer_address')}
                                            placeholder={t('customer_profile_modal.enter_location')}/>
                            <Col md="12" className="text-end">
                                <SubmitButton variant="primary">{t('customer_profile_modal.user.update_user')}</SubmitButton>
                            </Col>
                        </Row>
                    </BaseForm>
                    <hr/>
                    <BaseForm initialFormFields={selectedGroup} onSubmit={updateGroup} onFieldChange={onGroupFieldChange}>
                        <strong>{t('customer_profile_modal.group.title')}</strong>
                        {
                            selectedGroup && selectedGroup.blocked &&
                                <Alert variant="danger">{t('customer_blocked_alert.message')}</Alert>
                        }
                        {showParentGroup ?
                        <div>
                        <p>{t('customer_profile_modal.group.parent_group_desc')}</p>
                        <BaseForm.Input type="text" name="parentGroup.groupName" label={t('customer_profile_modal.group.parent_group')} disabled={true}/>
                        </div>
                        :
                        <></>
                        }
                        <Row>
                            <BaseForm.Input colSpan="6" type="select" name="id" label={t('customer_profile_modal.group.switch_groups')} options={groupOptions}
                                            showSearch={false}/>
                            <Col lg="3" className="d-flex align-items-center">
                                <Button variant="success" onClick={onAddNewGroup}
                                        disabled={selectedGroup && selectedGroup.isNew}><i className="fa fa-plus"/> {t('customer_profile_modal.group.add_new_group')}
                                </Button>
                            </Col>
                            <Col lg="3" className="text-end d-flex align-items-center">
                                {
                                    selectedGroup &&
                                    <Button variant="outline-primary"
                                            href={`webcal://api.rectimes.com/api/v1/facilities/${props.facilityLink}/feed/ical?groupUUID=${selectedGroup.uuid}`}><i
                                        className="fa fa-feed"/> {t('customer_profile_modal.group.calendar_feed')}</Button>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="6" type="text" name="groupName" label={t('common.group_name')} required/>
                            <BaseForm.Input colSpan="6" type="select" name="customerTypeId" label={t('forms.customer_type')}
                                            options={customerTypeOptions} showSearch={false}/>
                            <BaseForm.Input colSpan="6" type="text" name="user.email" label={t('customer_profile_modal.group.contact')} disabled={true}/>
                            <Col md="6" className="d-flex align-items-center">
                                <Button variant="alink skinny" onClick={() => setShowSwitchUserModal(true)}>{t('customer_profile_modal.group.switch_user')}</Button>
                            </Col>
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="6" type="file" name="profileImageUrl" label={t('forms.logo')} fileType="customer_profile_image" />
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="6" type="color" name="customerColor" label={t('forms.customer_color')}/>
                            <BaseForm.Input colSpan="4" type="check" name="hideUser" label={t('forms.hide_name_on_calendar')}/>
                            <BaseForm.Input colSpan="2" type="check" name="showUser" label={t('forms.show')}/>
                            <BaseForm.Input colSpan="4" type="number" name="rate" label={t('forms.customer_rate')}
                                            placeholder={t('forms.customer_rate.placeholder')} leftContent="$" step="0.01"/>
                            <BaseForm.Input colSpan="3" type="check" name="cRateFlat" label={t('forms.flat_rate')}/>
                            <BaseForm.Input colSpan="5" type="check" name="showGroupColor" label={t('forms.show_color_on_calendar')}/>
                            <BaseForm.Input colSpan="6" type="number" name="cTax" label={t('forms.customer_tax_rate')} rightContent="%"
                                            placeholder={t('forms.customer_tax_rate.placeholder')} step="0.01"/>
                            <BaseForm.Input colSpan="6" type="check" name="uBookCRate"
                                            label={t('forms.customer_apply_customer_rate')}/>
                            <Col md="12" className="text-end">
                                {
                                    selectedGroup && !selectedGroup.isNew && (
                                        selectedGroup.blocked ?
                                            <Button variant="text-danger" onClick={unblockGroup}>{t('customer_profile_modal.group.unblock_group')}</Button>
                                        : <Button variant="text-danger" onClick={() => setShowBlockUserGroupModal(true)}>{t('customer_profile_modal.group.block_group')}</Button>
                                    )
                                }

                                <SubmitButton variant="primary">
                                    {
                                        (selectedGroup && selectedGroup.isNew) ?
                                            <span>{t('customer_profile_modal.group.create_group')}</span>
                                            : <span>{t('customer_profile_modal.group.update_group')}</span>
                                    }
                                </SubmitButton>
                            </Col>
                        </Row>
                    </BaseForm>
                </BaseModal.Body>
                <BaseModal.Body className={classnames(currentTab === "contact" ? "" : "hide")}>
                    <BaseForm onSubmit={sendMessage}>
                        <Row>
                            <BaseForm.Input name="method" type="select" label={t('forms.contact_method')}
                                            options={contactMethodOptions} showSearch={false}/>
                            <BaseForm.Input name="message" type="text" label={t('forms.message')} placeholder={t('forms.message.placeholder')}
                                            required/>
                            <Col md="12" className="text-end">
                                <SubmitButton>{t('customer_profile_modal.contact.send_message')}</SubmitButton>
                            </Col>
                        </Row>
                    </BaseForm>
                </BaseModal.Body>
                <BaseModal.Body className={classnames(currentTab === "uploads" ? "" : "hide")}>
                    <BaseForm onSubmit={uploadDocument}>
                        <Row>
                            <BaseForm.Input type="file" name="upload" label={t('forms.choose_file_to_upload')}
                                            hidePreview={true} fileType="customer_uploads"
                                            rightContent={<SubmitButton><i className="fa fa-upload"/> {t('customer_profile_modal.uploads.upload')}</SubmitButton>
                                            }/>
                            <Col md="12">
                                <Table hover>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>{t('customer_profile_modal.uploads.name')}</th>
                                        <th>{t('customer_profile_modal.uploads.uploaded')}</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        _.map(uploads, (up, i) =>
                                            <tr key={i}>
                                                <td><i
                                                    className={classnames("fa", up.contentType === "application/pdf" ? "fa-file-pdf" : "fa-image")}/>
                                                </td>
                                                <td><a href={getFormattedImageLink(up.path, `https://rectimes.app/`)}
                                                       onClick={(e) => onDownloadFile(e, up)}>{up.filename}</a></td>
                                                <td>{moment(up.createdAt).format("dddd, MMMM D, YYYY [at] h:mm a")}</td>
                                                <td><DeleteButton onDelete={() => onDeleteUpload(up)}/></td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </BaseForm>
                </BaseModal.Body>
                <BaseModal.Body className={classnames(currentTab === "subgroups" ? "" : "hide")}>
                    <Alert variant="info">{t('customer_profile_modal.subgroups.description')}</Alert>
                    <BaseForm onSubmit={onAddSubgroup}>
                        <Row>
                            <Col md="8">
                                <Select
                                    ref={selectRef}
                                    loadOptions={fetchSubgroupsAutocomplete}
                                    className="select-container"
                                    classNamePrefix="select2"
                                    onChange={(value, meta) => onSubgroupSelected(value, meta)}
                                    noOptionsMessage={(inputValue) => {
                                        return t('customer_profile_modal.subgroups.search.placeholder')
                                    }}
                                />
                            </Col>
                            <Col md="4">
                                <SubmitButton>{t('customer_profile_modal.subgroups.add_subgroup')}</SubmitButton>
                            </Col>
                        </Row>
                    </BaseForm>
                    <Row>
                        <Col md="9">
                            <Table hover>
                                <thead>
                                <tr>
                                    <th>{t('customer_profile_modal.subgroups.name')}</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    _.map(subGroups, (group, i) =>
                                        <tr key={i}>
                                            <td>{group.groupName}</td>
                                            <td><DeleteButton onDelete={() => onDeleteSubgroup(group)}/></td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </BaseModal.Body>
            </BaseModal>
            <SwitchUserModal show={showSwitchUserModal} onClose={onModalClose} group={selectedGroup} />
            <BlockUserGroupModal show={showBlockUserGroupModal} onClose={onModalClose} group={selectedGroup} />
        </>
    );

}

export default EditCustomerProfileModal;
