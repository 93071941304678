import '../../App.scss';
import '../../css/modals.scss';
import { useState, useEffect, useContext } from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { serverFetch, serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { accessLevelOptions } from '../../helpers/input';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function AddOrEditAdminAccountModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [isEditing, setIsEditing] = useState(false);
    const [accessLevel, setAccessLevel] = useState("0");
    const [customBookingAccess, setCustomBookingAccess] = useState("0");
    const [venues, setVenues] = useState([]);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [initialFormFields, setInitialFormFields] = useState({});
    const [errorFields, setErrorFields] = useState(null);
    const { t } = useTranslation('common');

    useEffect(() => {
        serverFetch(getApiUrl('/venues'), {skipAddingCategory: true}).then((res) => {
            setVenues(res);
        })
    }, []);

    useEffect(() => {
        if (props.adminToEdit) {
            let item = {...props.adminToEdit};
            if (item.accessLevel === 3) {
                const oldVenueAccess = item.customAdminAccess.venueSpecificAccess;
                item.venueSpecificAccess = {};
                _.each(oldVenueAccess, (a, i) => {
                    item.venueSpecificAccess[a.venueId] = a.accessLevel;
                })
            } else {
                item = fillWithDefaultCustomAdminAccess(item);
            }
            setCustomBookingAccess(item.customAdminAccess.booking);
            setItemToEdit(item);
            setInitialFormFields({});
        } else {
            setItemToEdit(null);
            setCustomBookingAccess(null);
            setInitialFormFields(fillWithDefaultCustomAdminAccess({}));
        }
    }, [props.adminToEdit, venues]);

    const fillWithDefaultCustomAdminAccess = (item) => {
        const venueSpecificAccess = {}
        _.each(venues, v => {
            venueSpecificAccess[String(v.id)] = "update"
        })
        item.customAdminAccess = {
            booking: "update",
            customers: "update",
            invoices: "update",
            agreements: "update",
            reports: "update",
            settings: "update",
            lockers: "update",
            mediaScreen: "update",
        }
        item.venueSpecificAccess = venueSpecificAccess
        return item;
    }

    const onFieldChange = (key, value) => {
        if (key === "accessLevel") {
            setAccessLevel(value);
        } else if (key === "customAdminAccess.booking") {
            setCustomBookingAccess(value);
        }
    }

    const cleanFields = (data) => {
        const oldVenueAccess = data.venueSpecificAccess;
        const venues = _.keys(oldVenueAccess);
        if (_.isEmpty(data.password) && isEditing) {
            delete data.password;
        }
        if (String(data.accessLevel) === String(3)) {
            data.customAdminAccess.venueSpecificAccess = [];
            _.each(venues, (v) => {
                data.customAdminAccess.venueSpecificAccess.push({ venueId: v, accessLevel: oldVenueAccess[v] })
            })
        }
        return data;
    }

    useEffect(() => {
        if (itemToEdit) {
            setAccessLevel(itemToEdit.adminGroup);
            setIsEditing(true);
        } else {
            setAccessLevel(0);
            setIsEditing(false);
        }
    }, [itemToEdit]);

    const customAccessLevelOptions = [
        { 'value': "no", 'label': 'No Access' },
        { 'value': "update", 'label': 'Full Access' },
        { 'value': "view", 'label': 'View Only' },
    ];
    const customBookingAccessLevelOptions = [
        { 'value': "no", 'label': 'No/Custom Access' },
        { 'value': "update", 'label': 'Full Access' },
        { 'value': "view", 'label': 'View Only' },
        { 'value': "viewOnlyNoFinancials", 'label': 'View Only, No Financials' },
    ];

    const addAdmin = (data) => {
        setErrorFields(null);
        if (data.password !== data.password2) {
            setErrorFields({
                password: "Password doesn't match",
                password2: "Password doesn't match"
            })
            return;
        }
        const addFields = cleanFields({...data});
        serverPost(getApiUrl('/admins'), addFields).then((res) => {
            props.onClose();
        })
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            initialFormFields={initialFormFields}
            itemToEdit={itemToEdit}
            onFieldChange={onFieldChange}
            processAddFields={cleanFields}
            processUpdateFields={cleanFields}
            errorFields={errorFields}
            addItem={addAdmin}
            size="lg"
            itemLabel={"Admin"}
            itemBaseUrl={'/admins'}
        >
            <Row>
                <BaseForm.Input colSpan={6} name={'firstName'} label={t('forms.first_name')} type={'text'} required />
                <BaseForm.Input colSpan={6} name={'lastName'} label={t('forms.last_name')} type={'text'} required />
                <BaseForm.Input colSpan={6} name={'email'} label={t('forms.email')} type={'text'} required validations={{ validEmail: true, adminEmailCheck: { getApiUrl: getApiUrl, except: (isEditing ? itemToEdit.email : null) }}} />
                <BaseForm.Input colSpan={6} name={'phone'} label={t('forms.phone')} type={'text'} />
                <BaseForm.Input colSpan={6} name={'password'} label={t('forms.password')} type={'password'} required={!isEditing} />
                <BaseForm.Input colSpan={6} name={'accessLevel'} label={t('forms.access_level')} type={'select'} options={accessLevelOptions()} showSearch={false} />
                {
                    !isEditing &&
                        <BaseForm.Input colSpan={6} name={'password2'} label={t('common.password')} type={'password'} required validations={{minLength: 8}} />
                }
            </Row>
            {
                String(accessLevel) === String(3) &&
                    <>
                        <h5>Custom Access</h5>
                        <Row>
                            <BaseForm.Input colSpan={3} name={'customAdminAccess.booking'} label={t('forms.access.booking')} type={'select'} options={customBookingAccessLevelOptions} showSearch={false} />
                            <BaseForm.Input colSpan={3} name={'customAdminAccess.customers'} label={t('forms.access.customers')} type={'select'} options={customAccessLevelOptions} showSearch={false} />
                            <BaseForm.Input colSpan={3} name={'customAdminAccess.invoices'} label={t('forms.access.invoices')} type={'select'} options={customAccessLevelOptions} showSearch={false} />
                            <BaseForm.Input colSpan={3} name={'customAdminAccess.agreements'} label={t('forms.access.agreements')} type={'select'} options={customAccessLevelOptions} showSearch={false} />
                            <BaseForm.Input colSpan={3} name={'customAdminAccess.reports'} label={t('forms.access.reports')} type={'select'} options={customAccessLevelOptions} showSearch={false} />
                            <BaseForm.Input colSpan={3} name={'customAdminAccess.settings'} label={t('forms.access.settings')} type={'select'} options={customAccessLevelOptions} showSearch={false} />
                            <BaseForm.Input colSpan={3} name={'customAdminAccess.lockers'} label={t('forms.access.lockers')} type={'select'} options={customAccessLevelOptions} showSearch={false} />
                            <BaseForm.Input colSpan={3} name={'customAdminAccess.mediaScreen'} label={t('forms.access.mediascreens')} type={'select'} options={customAccessLevelOptions} showSearch={false} />
                        </Row>
                        <h5>Venue Specific Booking Access</h5>
                        {
                            customBookingAccess !== "no" &&
                                <span className="subtext">*This admin will have access to all the venues. In order to give admins edit access to a single venue, set the "Booking" access above to "No/Custom access" and set the access below for the venue to "Full Access"</span>
                        }
                        {
                            customBookingAccess === "no" &&
                                <Row>
                                    <span className="subtext">*Venue specific booking access allows you to provide booking access to only specific venues for this admin. This doesn&#39;t not allow you to change any settings for these venues. Settings permission is required to perform any updates.</span>
                                {
                                    _.map(venues, (venue, i) =>
                                        <BaseForm.Input key={i} colSpan={3} name={'venueSpecificAccess.' + venue.id} label={venue.name} type={'select'} options={customBookingAccessLevelOptions} showSearch={false} />
                                    )
                                }
                                </Row>
                        }
                    </>
            }
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditAdminAccountModal;
