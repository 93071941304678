import '../App.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const BaseOverlayTrigger = (props) => {
    return (
        <OverlayTrigger trigger={props.content ? ['hover', 'focus']: []} placement={props.placement || "top"} overlay={<Tooltip>{props.content}</Tooltip>} >
        { props.children }
        </OverlayTrigger>
    );
}

export default BaseOverlayTrigger;
