import '../App.scss';
import $ from 'jquery';
import { Alert } from 'react-bootstrap';

function Notification(props) {
    return (
        <div className="notification ahide noprint">
            <Alert variant={"warning"}>
                Warning message
            </Alert>
        </div>
    );
}
var hidingTimeout = null;
Notification.Show = (message) => {
    $('.notification .alert').text(message);
    $('.notification').removeClass("ashow");
    $('.notification').addClass("ahide");

    setTimeout(() => {
        $('.notification').removeClass("ahide");
        $('.notification').addClass("ashow");
    }, 100);

    if (hidingTimeout) {
        clearTimeout(hidingTimeout);
    }
    hidingTimeout = setTimeout(() => {
        $('.notification').removeClass("ashow");
        $('.notification').addClass("ahide");
    }, 3000);
}

var modalHidingTimeout = null;
Notification.ModalShow = (message) => {
    $('.notification-modal .alert').text(message);
    $('.notification-modal').removeClass("ashow");
    $('.notification-modal').addClass("ahide");
    $('.notification-model-wrapper').show();

    setTimeout(() => {
        $('.notification-modal').removeClass("ahide");
        $('.notification-modal').addClass("ashow");
    }, 100);

    if (modalHidingTimeout) {
        clearTimeout(modalHidingTimeout);
    }
    modalHidingTimeout = setTimeout(() => {
        $('.notification-modal').removeClass("ashow");
        $('.notification-modal').addClass("ahide");
        $('.notification-model-wrapper').hide();
    }, 3000);
}

export default Notification;
