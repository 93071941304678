import '../../App.scss';
import '../../css/modals.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import DeleteButton from '../DeleteButton';
import Notification from '../Notification';
import TabHeader from '../TabHeader';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Select from 'react-select/async'
import { getFormattedName, BaseContext } from '../../helpers/common';
import { serverFetch, serverPost, serverPatch, serverDelete } from '../../helpers/server';
import { Button, ButtonGroup, Row, Col, Table, Alert } from 'react-bootstrap';
import classnames from 'classnames';
import moment from 'moment';
const _ = require('lodash');

function AddCustomerModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(null);
    const [customerTypes, setCustomerTypes] = useState([]);
    const [user, setUser] = useState({});

    useEffect(() => {
        if (props.show) {
            serverFetch(getApiUrl(`/customer_types`)).then((res) => {
                setCustomerTypes(res);
            })
        }
    }, [props.show]);

    useEffect(() => {
        if (props.show) {
            setCurrentTab("details");
        } else {
            setCurrentTab(null);
        }
    }, [props.show]);

    const updateCustomer = (data) => {
        serverPatch(getApiUrl(`/users/${user.id}`), data).then((res) => {
            Notification.Show("User successfully updated");
            props.onClose();
        })
    }

    const createCustomer = (data) => {
        console.log("Creating customer " + JSON.stringify(data));
        const [firstName, lastName] = data.fullName.split(' ');
        const userData = {
            email: data.email,
            firstName: firstName,
            lastName: lastName,
            password: data.password,
            phoneNumber: data.phone
        }
        serverPost(getApiUrl("/users"), userData).then((userResult) => {
            if (userResult) {
                const groupData = {
                    userId: userResult.id,
                    groupName: data.groupName,
                    customerColor: data.customerColor,
                    cTax: data.cTax,
                    customerTypeId: data.customerTypeId,
                    rate: data.rate,
                    cRateFlat: data.cRateFlat,
                    hideUser: data.hideUser,
                    showUser: data.showUser,
                    uBookCRate: data.uBookCRate,
                    showGroupColor: data.showGroupColor,
                }
                serverPost(getApiUrl("/user_groups"), groupData).then((groupResult) => {
                    if (groupResult) {
                        Notification.Show("Customer created successfully");
                        props.onClose();
                    }
                });
            }
        });
    }

    const customerTypeOptions = _.map(customerTypes, (type) => { return {value: type.id, label: type.customerType}; });
    customerTypeOptions.unshift({ value: 0, label: "None" });

    return (
        <BaseModal size="lg" {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Add Customer</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm initialFormFields={null} onSubmit={createCustomer}>
                <BaseModal.Body className={classnames(currentTab === "details" ? "": "hide")}>
                    <p><strong>User Information</strong></p>
                    <Row>
                        <BaseForm.Input colSpan="6" type="text" name="email" label="Email" placeholder="Will be used for login" validations={{ validEmail: true, required: true, userEmailCheck: { getApiUrl } }} />
                        <BaseForm.Input colSpan="6" type="text" name="fullName" label="Full Name" required />
                        <BaseForm.Input colSpan="6" type="text" name="phone" label="Phone" />
                        <BaseForm.Input colSpan="12" type="address" name="address" label="Customer Address" placeholder="Enter a location" />
                    </Row>
                    <p><strong>Group Information</strong></p>
                    <Row>
                        <BaseForm.Input colSpan="6" type="text" name="groupName" label="Group Name" required />
                        <BaseForm.Input colSpan="6" type="select" name="customerTypeId" label="Customer Type" options={customerTypeOptions} showSearch={false} />
                    </Row>
                    <Row>
                        <BaseForm.Input colSpan="6" type="color" name="customerColor" label="Customer Color" />
                        <BaseForm.Input colSpan="4" type="check" name="hideUser" label="Hide Name on Calendar" />
                        <BaseForm.Input colSpan="2" type="check" name="showUser" label="Show" />
                        <BaseForm.Input colSpan="4" type="number" name="rate" label="Customer Rate (Hourly)"
                            placeholder="If empty standard rates apply" leftContent="$"/>
                        <BaseForm.Input colSpan="3" type="check" name="cRateFlat" label="Flat Rate" />
                        <BaseForm.Input colSpan="5" type="check" name="showGroupColor" label="Show Color on Calendar" />
                        <BaseForm.Input colSpan="6" type="text" name="cTax" label="Customer Tax Rate" rightContent="%"
                            placeholder="If empty standard rates apply"/>
                        <BaseForm.Input colSpan="6" type="check" name="uBookCRate"
                            label="Apply Custom Rates to User Bookings"/>
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12">
                            <SubmitButton variant="primary">Create Customer</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default AddCustomerModal;
