import React from 'react';
import '../../App.scss';
import { Container } from 'react-bootstrap';

export default class ClassesFooter extends React.Component {

  render() {
    return (
      <Container className="footer">
          <div className="text-start">
            <p className="float-start">© 2021 RecTimes Inc. All Rights Reserved</p>
          </div>
          <div className="text-end">
            <p className="float-end"><a href="https://www.rectimes.com/terms-and-conditions/">Terms</a> | <a href="https://www.rectimes.com/privacy-policy/">Privacy Policy</a></p>
          </div>

      </Container>
    );
  }
}
