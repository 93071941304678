import React from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import { useEffect, useState, useRef, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import TabHeader from '../TabHeader';
import SubmitButton from '../SubmitButton';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'react-bootstrap';
import {getBookingExportFields, getBookingNonFinancialExportFields} from '../../helpers/exportFields';
import { serverPost } from '../../helpers/server';
import {downloadBlob, BaseContext, hasAccess} from '../../helpers/common';
import classnames from 'classnames';
import moment from 'moment';
import { useParams } from "react-router-dom";
const _ = require("lodash");

function BookingsPDFExportModal(props) {
    let { facilityLink } = useParams();
    const { userInfo, getApiUrl, settings } = useContext(BaseContext);
    const { t } = useTranslation(['common']);
    const [currentTab, setCurrentTab] = useState("report");
    const [items, setItems] = useState([]);
    const [criteria, setCriteria] = useState(null);
    const [fields, setFields] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);
    const [formRef, setFormRef] = useState(React.createRef());

    const cacheKey = facilityLink + "_pdf_booking";
    const showFinancials = hasAccess("booking", userInfo, "viewWithFinancials", "any");

    useEffect(() => {
        const totalFields = showFinancials ? getBookingExportFields(settings): getBookingNonFinancialExportFields();

        let _allFields = [];
        _.each(totalFields, (fs, j) => {
            _.each(fs.fields, (f, i) => {
                _allFields.push(f);
            })
        })
        setFields(_allFields);

        let preselectedFields = null
        const storedList = localStorage.getItem(cacheKey);
        if (storedList) {
            preselectedFields = JSON.parse(storedList);
        }
        if (!preselectedFields) {
            let newSelectedFields = _.map(_allFields, (f) => f.value);
            newSelectedFields = newSelectedFields.concat(["csvcheckall"]);
            preselectedFields = newSelectedFields;
        }
        setSelectedFields(preselectedFields);
    }, []);

    useEffect(() => {
        setCurrentTab("report");
    }, [props.show]);

    useEffect(() => {
        setItems(props.items);
    }, [props.items]);

    useEffect(() => {
        setCriteria(props.criteria);
    }, [props.criteria]);

    const updateChecked = (event) => {
        if (event.target.value === "csvcheckall") {
            let newSelectedFields = [];
            if (event.target.checked) {
                newSelectedFields = _.map(fields, (f) => f.value);
                newSelectedFields = newSelectedFields.concat(["csvcheckall"]);
            }
            setSelectedFields(newSelectedFields);
        } else {
            const newSelectedFields = [...selectedFields];
            if (event.target.checked) {
                newSelectedFields.push(event.target.value);
            } else {
                let index = newSelectedFields.indexOf(event.target.value);
                if (index > -1) {
                    newSelectedFields.splice(index, 1);
                }
                index = newSelectedFields.indexOf("csvcheckall");
                if (index > -1) {
                    newSelectedFields.splice(index, 1);
                }
            }
            setSelectedFields(newSelectedFields);
        }
    }

    const onExport = (data) => {
        let filteredFields = [...selectedFields];
        _.pull(filteredFields, "csvcheckall");

        const originalFieldOrder = _.map(fields, (f) => f.value);
        filteredFields = _.sortBy(filteredFields, (f) => _.indexOf(originalFieldOrder, f));

        localStorage.setItem(cacheKey, JSON.stringify(selectedFields));

        const exportData = {
            format: "pdf",
            fields: filteredFields,
            options: {
                orientation: data.orientation,
                title: data.title,
                companyName: data.companyName
            }
        }
        if (props.useCriteria) {
            exportData['criteria'] = criteria;
        } else {
            exportData['forExport'] = {
                ids: _.map(items, (i) => i.id)
            };
        }
        const filename = `Booking-reports-${moment().format("MMM-DD-YYYY")}.pdf`;
        serverPost(getApiUrl('/bookings/export_booking_list'), exportData, { noJson: true }).then((res) => {
            downloadBlob(res, filename);
        })
        props.onClose();
    }

    const pdfViewOptions = [
        { value: "PORTRAIT", label: "Portrait" },
        { value: "LANDSCAPE", label: "Landscape" },
    ]

    const tabItems = [
        { value: "report", label: "Report Items", icon: "fa-list", active: currentTab === "report", onClick: () => setCurrentTab("report") },
        { value: "details", label: "PDF Details", icon: "fa-info-circle", active: currentTab === "details", onClick: () => setCurrentTab("details") }
    ];

    return (
        <BaseModal {...props} size="lg">
            <BaseModal.Header>
                <BaseModal.Title>Booking Report</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm ref={formRef} onSubmit={onExport}>
                <div className="content-box">
                    <div className="content-body">
                        <div>
                            <Row>
                                <Col md="3">
                                    <div className="checkbox check-success tall"><input type="checkbox" name="fields[]" id="csvcheckall" value="csvcheckall" checked={selectedFields.includes("csvcheckall")} onChange={ (event) => updateChecked(event) }/><label htmlFor="csvcheckall">Select All</label></div>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    _.map(fields, (f, i) =>
                                        <Col md="3" key={i}>
                                            <div className="checkbox check-success tall"><input type="checkbox" name="fields[]" id={f.id} value={f.value} checked={selectedFields.includes(f.value)} onChange={ (event) => updateChecked(event) }/><label htmlFor={f.id}>{f.label}</label></div>
                                        </Col>
                                    )
                                }
                            </Row>
                            <br/>
                            <Row>
                                <BaseForm.Input colSpan="4" type="select" name="orientation" label="PDF View" options={pdfViewOptions} showSearch={false} />
                                <BaseForm.Input colSpan="4" type="text" name="title" label="Report Title" placeholder="Booking Report" />
                                <BaseForm.Input colSpan="4" type="text" name="companyName" label="Company Name" />
                                <BaseForm.Input colSpan="12" type="editor" name="reportNotes" />
                            </Row>
                        </div>
                    </div>
                </div>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <Button variant="link" onClick={() => props.onClose()}>Close</Button>
                            <SubmitButton><i className="fa fa-print"/> Export PDF</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default BookingsPDFExportModal;
