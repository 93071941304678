import '../App.scss';
import React, { useState, useEffect } from 'react';
import BaseOverlayTrigger from './BaseOverlayTrigger';
import classnames from 'classnames';
const _ = require("lodash");

function CopyableComponent(props) {
    const [value, setValue] = useState(null);
    const [copyableValue, setCopyableValue] = useState(null);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setValue(props.value || "");
        setCopyableValue(props.copyableValue || props.value || "");
    }, [props.value, props.copyableValue]);

    const onCopy = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        await navigator.clipboard.writeText(copyableValue);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, [2000])
    }

    const onToggle = (show) => {
        if (!show) {
            setTimeout(() => {
                setCopied(false);
            }, [200])
        }
    }

    const getContent = () => {
        if (copied) {
            return <span><i className="fa fa-circle-check fa-c-success"/>&nbsp;Copied</span>
        } else {
            return <span><i className="fa fa-file"/>&nbsp;Copy to Clipboard</span>
        }
    }

    return (
        <div className={classnames("", props.className)} style={{ display: "inline-block", cursor: "pointer"}} onClick={(event) => onCopy(event)}>
            <BaseOverlayTrigger content={getContent()} onToggle={onToggle}>
                <div className="d-flex flex-row gap-1 align-items-center text-gray-500 break-all">
                    <i className="fa fa-file"/>
                    <span className="text-xs" style={{ wordBreak: "break-all" }}>{ value }</span>
                </div>
            </BaseOverlayTrigger>
        </div>
    );
}

export default CopyableComponent;
