import { useEffect, useState } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import AddOrEditFacilityModal from '../../components/modals/AddOrEditFacilityModal'
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import { getUrl, serverFetch } from '../../helpers/server';
import { getFacilityName } from '../../helpers/common';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function FacilityList() {
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [facilities, setFacilities] = useState([]);
    const [facilityToEdit, setFacilityToEdit] = useState(null);
    const [showAddFacilityModal, setShowAddFacilityModal] = useState(false);

    useEffect(() => {
        document.title = "Facility List";
    }, [facilityLink]);

    useEffect(() => {
        fetchData();
    }, [facilityLink]);

    const onModalClose = () => {
        fetchData(true);
        setShowAddFacilityModal(false);
    }

    const fetchData = (skipCache = false) => {
        const url = getUrl("/api/v1/facilities", false);
        serverFetch(url, { skipCache }).then((res) => {
            setFacilities(_.sortBy(res, (r) => r.id));
        });
    }

    const addFacility = () => {
        setFacilityToEdit(null);
        setShowAddFacilityModal(true)
    }

    const editFacility = (event, facility) => {
        event.preventDefault();
        setFacilityToEdit(facility);
        setShowAddFacilityModal(true)
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "super-admin")}/>
            <div className="content-box">
                <div className="content-body">
                    <Button variant="outline-primary" onClick={() => addFacility()}><i className="fa fa-plus-circle"></i> Add Facility</Button>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Table hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Facility Name</th>
                                <th>Facility Domain Link</th>
                                <th># of Venues</th>
                                <th>View Venues</th>
                                <th>Using new app</th>
                                <th>Partner Admin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(facilities, (facility, i) =>
                                    <tr key={i}>
                                        <td>{facility.id}</td>
                                        <td><Button variant="alink" onClick={(event) => editFacility(event, facility)}>{facility.facilityName}</Button></td>
                                        <td><a target="_blank" rel="noreferrer" href={"/" + facility.link}>{facility.link}</a></td>
                                        <td>{facility.rinkCount}</td>
                                        <td><a href={"/" + facility.link + "/venue/list?fid=" + facility.id}><i className="fa fa-list"/> View List</a></td>
                                        <td>{facility.useNewWebapp ? t("common.yes") : t("common.no")}</td>
                                        <td>{facility.partnerAdmin ? <a target="_blank" rel="noreferrer" href={"/" + facility.link + "/partner/dashboard"}>{t("facilities.list.partner_dashboard")}</a> : t("common.no")}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            <AddOrEditFacilityModal show={showAddFacilityModal} onClose={onModalClose} itemToEdit={facilityToEdit} />
        </BaseContainer>
    );
}

export default FacilityList;
