import '../App.scss';
import React, { useContext } from 'react';
import { BaseContext } from '../helpers/common';
import Header from './Header';
import LeftNav from './LeftNav';
import Notification from './Notification';
import { Container } from 'react-bootstrap';
import classnames from 'classnames';

function BaseContainer(props) {
    const { settings } = useContext(BaseContext);
  return (
      <div className={classnames("content-container", settings.isPartnerFacility ? "partner" : "")}>
          <LeftNav {...props} />
          <Header {...props} />
          <div className="content_wrapper" id="content_wrapper">
              <Container fluid className={props.containerClassName}>
                <Notification {...props} />
                { props.children }
              </Container>
          </div>
      </div>
  );
}

export default BaseContainer;
