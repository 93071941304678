import '../../App.scss';
import '../../css/modals.scss';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState, useRef, useContext} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import Notification from '../Notification';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Select from 'react-select/async'
import {BaseContext} from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import {Row, Col, Container} from 'react-bootstrap';

const _ = require('lodash');

function SwitchUserModal(props) {
    const {getApiUrl} = useContext(BaseContext);
    const {t} = useTranslation('common');
    const navigate = useNavigate();
    const [group, setGroup] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const userSelectRef = useRef(null);

    useEffect(() => {
        if (props.group) {
            setGroup(props.group)
        }
    }, [props.group]);

    useEffect(() => {
        if (props.show && group) {
            setTimeout(() => {
                if (userSelectRef.current) {
                    userSelectRef.current.setValue(group.user);
                }
            }, 100)
        }
    }, [props.show, group]);

    const updateGroup = (data) => {
        const newData = {
            newUserId: selectedUser.id
        }
        serverPost(getApiUrl(`/user_groups/${group.id}/move`), newData).then((res) => {
            if (res) {
                Notification.ModalShow("User group successfully updated");
                if (props.onClose) {
                    props.onClose(true);
                }
            }
        })
    }

    const fetchUsersAutocomplete = async (query) => {
        return serverPost(getApiUrl('/users/autocomplete'), {query: query}).then((res) => {
            _.each(res, r => {
                r.fullName = (r.firstName || "") + " " + (r.lastName || "");
                if (!r.firstName && !r.lastName) {
                    r.fullName = "";
                }
                r.label = (
                    <>
                        <span className="title">{r.fullName || r.email}</span><br/>
                        <span className="subtitle">{r.email}</span>
                    </>
                );
            })
            return res;
        });
    }

    const getOptionsLabel = (option) => {
        const fullName = (option.firstName || "") + " " + (option.lastName || "");
        return (
            <>
                <span className="title">{ fullName || option.email }</span><br/>
                <span className="subtitle">{ option.email }</span>
            </>
        )
    }

    const getOptionsValue = (option) => {
        return option.id;
    }

    const onUserSelected = (user, meta) => {
        setSelectedUser(user);
    }

    return (
        <>
            <BaseModal {...props}>
                <BaseForm onSubmit={updateGroup}>
                    <BaseModal.Header>
                        <BaseModal.Title>Switch User</BaseModal.Title>
                    </BaseModal.Header>
                    <BaseModal.Body>
                        <p>Select the user, to whom, you want to assign the group to.</p>
                        <Row>
                            <Col md="8">
                                <BaseForm.InputGroup>
                                    <Select
                                        ref={userSelectRef}
                                        loadOptions={fetchUsersAutocomplete}
                                        className="select-container"
                                        classNamePrefix="select2"
                                        getOptionLabel={getOptionsLabel}
                                        getOptionValue={getOptionsValue}
                                        onChange={(value, meta) => onUserSelected(value, meta)}
                                        noOptionsMessage={(inputValue) => {
                                            return "Start typing to search for a user"
                                        }}
                                    />
                                </BaseForm.InputGroup>
                            </Col>
                        </Row>
                    </BaseModal.Body>
                    <BaseModal.Footer>
                        <Container>
                            <div className="d-flex flex-row justify-content-end">
                                <SubmitButton>{t('common.update')}</SubmitButton>
                            </div>
                        </Container>
                    </BaseModal.Footer>
                </BaseForm>
            </BaseModal>
        </>
    );
}

export default SwitchUserModal;
