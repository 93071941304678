import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseContext } from "../../helpers/common";
import { getTabItems } from "../../helpers/tabs";
import BaseContainer from "../../components/Container";
import { useParams, useSearchParams } from "react-router-dom";
import TabHeader from "../../components/TabHeader";
import {serverFetch, serverPost} from "../../helpers/server";
import BaseForm from "../../components/BaseForm";
import Notification from "../../components/Notification";
import { Button, Col, Row } from "react-bootstrap";
import SubmitButton from "../../components/SubmitButton";
import Loader from "../../components/Loader";
const _ = require('lodash');

function CustomerSync() {
    const { t } = useTranslation("common");
    const { getApiUrl, userInfo, settings } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const [ searchParams ] = useSearchParams();
    const groupId = searchParams.get('groupId');

    const [loading, setLoading] = useState(true);
    const [userGroup, setUserGroup] = useState({});
    const [sites, setSites] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [teams, setTeams] = useState([]);
    const [selectedSiteId, setSelectedSiteId] = useState(null);
    const [selectedProgramId, setSelectedProgramId] = useState(null);
    const [selectedSubprogramId, setSelectedSubprogramId] = useState(null);
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [initialFields, setInitialFields] = useState({});
    const [alreadyMapped, setAlreadyMapped] = useState(false);
    const [loadingTeams, setLoadingTeams] = useState(false);
    const [loadingPrograms, setLoadingPrograms] = useState(false);

    const fetchData = async () => {
        const userGroupResult = await serverFetch(getApiUrl(`/user_groups/${groupId}`), { skipCache: true });
        if (userGroupResult) {
            setUserGroup(userGroupResult);
            const exists = userGroupResult && userGroupResult.partnerMappings && userGroupResult.partnerMappings.length > 0;
            setAlreadyMapped(exists);
            if (exists) {
                const pmap = userGroupResult.partnerMappings[0];
                setSelectedSiteId(pmap.siteId);
                setSelectedProgramId(String(pmap.remoteId));
                setSelectedSubprogramId(String(pmap.remoteSubId));
                setSelectedTeamId(String(pmap.remoteTeamId));
                setInitialFields(pmap);
            }
        }
        setLoading(false);
    }

    const fetchSites = (skipCache=false) => {
        serverFetch(getApiUrl(`/partner/sites`), { skipCache }).then((res) => {
            setSites(res);
        });
    }

    const fetchSitePrograms = (skipCache=false) => {
        if (_.isNil(selectedSiteId)) {
            return;
        }
        setLoadingPrograms(true);
        serverFetch(getApiUrl(`/partner/sites/${selectedSiteId}/user_groups`), { skipCache }).then((res) => {
            let ugroups = [];
            _.each(res, r => {
                ugroups.push(r)
                if (r.subGroups) {
                    _.each(r.subGroups, g => {
                        ugroups.push({
                            ...g,
                            groupName: r.name,
                            groupId: r.id
                        });
                    })
                }
            })
            setPrograms(ugroups);
            setLoadingPrograms(false);
        });
    }

    const fetchSubprogramsAndTeams = async (skipCache=false) => {
        if (_.isNil(selectedProgram)) {
            return;
        }
        setLoadingTeams(true);
        const groupDataQuery = selectedProgram.groupId ? `?userSubgroupId=${selectedProgram.id}`: "";
        const programId = selectedProgram.groupId ? selectedProgram.groupId: selectedProgram.id;
        const receivedTeams = await serverFetch(getApiUrl(`/partner/sites/${selectedSiteId}/user_groups/${programId}/sub_groups${groupDataQuery}`), { skipCache: true });
        setTeams(receivedTeams);
        // serverFetch(getApiUrl(`/partner/sites/${selectedSiteId}/user_groups/${selectedProgramId}/sub_groups`), { skipCache }).then((res) => {
        //     setTeams(res);
        // });
        setLoadingTeams(false);
    };

    useEffect(() => {
        fetchData();
        // fetchUserGroup(true);
        fetchSites(true);
    }, []);

    useEffect(() => {
        setPrograms([]);
        fetchSitePrograms(true);
    }, [selectedSiteId]);

    useEffect(() => {
        setTeams([]);
        fetchSubprogramsAndTeams(true);
    }, [selectedProgram]);

    useEffect(() => {
        if (_.isEmpty(programs)) {
            setSelectedProgram(null);
        } else {
            setSelectedProgram(_.find(programs, p => p.id === selectedProgramId));
        }
    }, [selectedProgramId, programs]);

    useEffect(() => {
        if (!userGroup || _.isEmpty(sites)) {
            return;
        }
        let site = null;
        if (sites && sites.length > 0) {
            site = sites[0]
        }
        if (!site) {
            setSelectedSiteId(null);
            setSelectedProgramId(null);
        } else {
            // setSelectedSiteId(site.id);
            // const imap = {
            //     siteId: site.id
            // }
            // if (userGroup && userGroup.partnerMappings && userGroup.partnerMappings.length > 0) {
            //     const pmap = userGroup.partnerMappings[0];
            //     setSelectedSiteId(pmap.siteId);
            //     console.log("Original selected program id " + String(pmap.remoteId));
            //     imap.remoteId = String(pmap.remoteId);
            //     imap.remoteTeamId = String(pmap.remoteTeamId);
            //     setAlreadyMapped(true);
            // }
            // setInitialFields(imap);
        }
    }, [userGroup, sites]);

    const onFieldChange = (field, value) => {
        if (field === "siteId") {
            setSelectedSiteId(value);
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields.siteId = value;
                newFields.remoteId = null;
                newFields.remoteTeamId = null;
                return newFields;
            })
        } else if (field === "remoteId") {
            setSelectedProgramId(value);
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields.remoteId = value;
                newFields.remoteTeamId = null;
                return newFields;
            })
        } else if (field === "remoteTeamId") {
            setSelectedTeamId(value);
        }
    };

    const onSubmit = (data) => {
        const sprogram = _.find(programs, p => p.id === data.remoteId);
        const steam = _.find(teams, t => t.id === data.remoteTeamId);
        const updateData = {
            siteId: selectedSiteId,
            remoteId: data.remoteId,
            remoteSubId: null,
            remoteTeamId: null,
        }
        if (steam) {
            updateData.remoteId = steam.remoteGroupId;
            updateData.remoteSubId = steam.remoteSubgroupId;
            updateData.remoteTeamId = steam.id;
        } else {
            if (sprogram.groupId) {
                updateData.remoteId = sprogram.groupId;
                updateData.remoteSubId = sprogram.id;
            } else {
                updateData.remoteId = sprogram.id;
            }
        }
        serverPost(getApiUrl(`/user_groups/${groupId}/partner_mappings`), updateData).then((res) => {
            if (res) {
                setAlreadyMapped(true);
                setSelectedProgramId(String(updateData.remoteId));
                setSelectedProgram(_.find(programs, p => p.id === String(updateData.remoteId)))
                setSelectedSubprogramId(String(updateData.remoteSubId));
                setSelectedTeamId(String(updateData.remoteTeamId));
                Notification.Show("Successfully updated");
            }
        });
    };

    const onClear = async () => {
        const data = {
            groupId: groupId,
            siteId: selectedSiteId,
            remoteId: selectedProgramId,
            remoteSubId: selectedSubprogramId,
            remoteTeamId: selectedTeamId
        }
        await serverPost(getApiUrl(`/user_groups/${groupId}/clear_mapping`), data).then(res => {
            if (res) {
                setAlreadyMapped(false);
            } else {
                Notification.Show("Group has existing bookings");
            }
        })
    }

    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        groupId: groupId,
        userGroup: userGroup,
        settings: settings,
        userInfo: userInfo,
    };

    let siteOptions = sites.map((site) => {
        return { label: site.name, value: site.id }
    });

    let programOptions = programs.map((remote) => {
        let label = remote.name;
        if (remote.groupName) {
            label = remote.groupName + " -> " + remote.name
        }
        return { label: label, value: remote.id }
    });
    programOptions.unshift({ label: "None", value: null })

    let teamOptions = (selectedProgramId === null) ? [] :
        teams.map((team) => {
            return { label: team.name, value: team.id, disabled: !_.isNil(team.userGroup) || !team.isImportable }
        });

    if (!_.isNil(selectedProgram) && selectedProgram.isImportable) {
        teamOptions.unshift({ label: "None", value: null });
    }

    const selectedSite = selectedSiteId ? _.find(sites, s => s.id === selectedSiteId) : null;
    const selectedTeam = selectedTeamId ? _.find(teams, s => s.id === selectedTeamId) : null;
    const selectedSubprogram = (selectedSubprogramId && !_.isNil(selectedProgram)) ? _.find(selectedProgram.subGroups, g => g.id === selectedSubprogramId): null;

    const disableSubmit = _.isNil(selectedProgram) || loadingTeams || (selectedProgram && !selectedProgram.isImportable && !selectedTeamId);
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "bookings", tabsParams)} />
            <div className="content-box">
                <div className="content-body">
                    <Loader loading={loading}>
                    {
                        alreadyMapped ?
                            <div className="d-flex flex-column">
                                <div><strong>Site:</strong> { selectedSite && selectedSite.name }</div>
                                <Loader loading={loadingPrograms}>
                                    <div>
                                        <strong>Program:</strong> { selectedProgram && selectedProgram.name }
                                        {
                                            selectedSubprogram &&
                                                <><br/><strong>Subprogram:</strong> { selectedSubprogram && selectedSubprogram.name }</>
                                        }
                                    </div>
                                    <Loader loading={loadingTeams}>
                                        <div><strong>Team:</strong> { selectedTeam ? selectedTeam.name: "-" }</div>
                                    </Loader>
                                </Loader>
                                <br/>
                                <Col md="3">
                                    <Button onClick={() => {onClear()}}>Clear LeagueApps Sync</Button>
                                </Col>
                                {/* <div>Want to change the connected information. Please contact support.</div> */}
                            </div>
                        : <BaseForm initialFormFields={initialFields} onSubmit={onSubmit} onFieldChange={onFieldChange} >
                            <Row>
                                <Col md="6">
                                    <BaseForm.Input
                                        type="select"
                                        label={"Site"}
                                        name={"siteId"}
                                        options={siteOptions}
                                        showSearch={siteOptions.length > 6}
                                    />
                                    <Loader loading={loadingPrograms}>
                                    {
                                        selectedSiteId !== null &&
                                        <BaseForm.Input
                                            type="select"
                                            label={"Program"}
                                            name={"remoteId"}
                                            options={programOptions}
                                            showSearch={programOptions.length > 6}
                                        />
                                    }
                                    </Loader>
                                    <Loader loading={loadingTeams}>
                                    {
                                        selectedSiteId !== null && !_.isNil(selectedProgram) &&
                                        <>
                                            {
                                                !_.isEmpty(teams) ?
                                                    <BaseForm.Input
                                                        type="select" label={"Team"} name={"remoteTeamId"}
                                                        options={teamOptions} showSearch={teamOptions.length > 6}
                                                    />
                                                : <p>No teams.</p>
                                            }
                                        </>
                                    }
                                    </Loader>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="3">
                                    <SubmitButton disabled={disableSubmit}>{t("common.update")}</SubmitButton>
                                </Col>
                            </Row>
                        </BaseForm>
                    }
                    </Loader>
                </div>
            </div>
        </BaseContainer>
    )
}

export default CustomerSync;
