import './App.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Image } from 'react-bootstrap';

function Home() {
    const { t } = useTranslation('common');

    useEffect(() => {
        if (window.location.href === 'https://facilities.leagueapps.com/') {
            window.location = "https://leagueapps.com/resource/facilities/";
        }
    }, [])

    return (
        <div className="centered-container">
            <Container className="hide">
                <Row>
                    <Col md={{ span:4, offset:4 }} >
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Home;
