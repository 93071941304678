import '../App.scss';
import '../js/forms.js';
import { useParams, useNavigate } from "react-router-dom";
import SimpleModal from './modals/SimpleModal';
import SalesAllocationReportModal from './modals/SalesAllocationReportModal';
import { getCompanyInfo } from '../helpers/storage';
import { hasAccess, isNotAdmin, BaseContext, getAccessibleVenues } from '../helpers/common';
import React, { useEffect, useState, useContext } from 'react';
import { getUrl, serverFetch, subscribeToEvent } from '../helpers/server'
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

const urlReverseMap = {
    "/user/user_profile": "profile",
    "/user/payment_methods": "payment_methods",
    "/booking/search": "availability",
    "/booking/list": "bookings",
    "/user/grouplist": "customers",
    "/invoice/list": "invoices",
    "/invoice/view": "invoices",
    "/agreement/list": "agreements",
    "/class/list": "classes",
    "/dashboard/livefeed": "reports",
    "/payment/list": "reports",
    "/invoice/paymentlist": "reports",
    "/invoice/aged-report": "reports",
    "/credit/list": "reports",
    "/setting/": "settings",
    "/partner/dashboard": "partner-dashboard",
    "": "calendar"
};

function LeftNav(props) {
    const { userInfo, isLoggedIn, getApiUrl } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [showHowToBookModal, setShowHowToBookModal] = useState(false);
    const [showSalesAllocationReportModal, setShowSalesAllocationReportModal] = useState(false);
    const [salesAllocationReportType, setSalesAllocationReportType] = useState("");

    const [settings, setSettings] = useState(getCompanyInfo());
    const [venues, setVenues] = useState([]);

    let urlPath = window.location.pathname;
    let urlPathWithoutFacility = urlPath.substring(facilityLink.length + 1);
    let activeNav = "calendar";
    for (let key in urlReverseMap) {
        if (urlPathWithoutFacility.startsWith(key)) {
            activeNav = urlReverseMap[key];
            break;
        }
    }

    const partnerLeftNav = () => {
      return (
        <div id="left-nav" className="left-nav noprint">
            <div className="left-nav-menu">
                <ul>
                    <MenuItem label={t("left_nav.dashboard")} icon="fa-table-columns" link={"/" + facilityLink + "/partner/dashboard"} active={activeNav==="partner-dashboard"} />
                    <MenuItem label={t('left_nav.settings')} icon="fa-cog" active={activeNav==="settings"} >
                        <div className={activeNav==="settings" ? "sub-menu show" : "sub-menu"}>
                          <MenuItem label={t('left_nav.admin_accounts')} short="Aa" link={"/" + facilityLink + "/setting/adminlist"} />
                        </div>
                    </MenuItem>
                </ul>
            </div>
        </div>
      );
    }

    useEffect(() => {
        serverFetch(getApiUrl('/venues')).then((res) => {
            setVenues(res);
        })

        fetchData();
        subscribeToEvent("settings", (data) => { fetchData(true) });
    }, [facilityLink]);

    const showSalesModal = () => {
        setSalesAllocationReportType("sales");
        setShowSalesAllocationReportModal(true);
    }

    const showAllocationModal = () => {
        setSalesAllocationReportType("allocation");
        setShowSalesAllocationReportModal(true);
    }

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(''), { skipCache }).then((res) => {
            setSettings(res);
        })
    }

    if (settings && settings.isPartnerAdminFacility) {
      return partnerLeftNav();
    }

    const hasBookingAccessToVenues = !_.isEmpty(getAccessibleVenues(venues, userInfo));
    // TODO(Aswin): Check admin sub permissions before showing stuff
  return (
    <div id="left-nav" className="left-nav noprint">
      <div className="left-nav-menu">
        <ul>
          <MenuItem label={t('left_nav.calendar')} icon="fa-calendar-days" link={"/" + facilityLink} active={activeNav==="calendar"} />
          {
            settings.showSearch > 0 &&
              <MenuItem label={t('left_nav.availability')} icon="fa-search" link={"/" + facilityLink + "/booking/search"} active={activeNav==="availability"} />
          }
          {
            !isLoggedIn &&
              <MenuItem label={t('header.how_to_book')} icon="fa-question-circle" link={"#"} onClick={() => setShowHowToBookModal(true)} active={activeNav==="how-to-book"} />
          }
          {
            isLoggedIn && (isNotAdmin(userInfo) || hasAccess("booking", userInfo, "view") || hasBookingAccessToVenues) &&
              <>
                  <MenuItem label={t('left_nav.bookings')} icon="fa-book" link={"/" + facilityLink + "/booking/list"} active={activeNav==="bookings"} />
              </>
          }
          {
            isLoggedIn && hasAccess("customers", userInfo, "view") &&
              <>
                  <MenuItem label={t('left_nav.customers')} icon="fa-user" link={"/" + facilityLink + "/user/grouplist"} active={activeNav==="customers"} />
              </>
          }
          {
            isLoggedIn && (isNotAdmin(userInfo) || hasAccess("invoices", userInfo, "view")) &&
              <>
                  <MenuItem label={t('left_nav.invoices')} icon="fa-file" link={"/" + facilityLink + "/invoice/list"} active={activeNav==="invoices"} />
              </>
          }
          {
            isLoggedIn && (isNotAdmin(userInfo) || hasAccess("agreements", userInfo, "view")) &&
              <>
                  <MenuItem label={t('left_nav.agreements')} icon="fa-pencil-square" link={"/" + facilityLink + "/agreement/list"} active={activeNav==="agreements"} />
              </>
          }
          {
            isLoggedIn && settings.allowClasses > 0 && hasAccess("booking", userInfo, "view") &&
              <>
                  <MenuItem label={t('left_nav.classes')} icon="fa-users" link={"/" + facilityLink + "/class/list"} active={activeNav==="classes"} />
              </>
          }
          {
              isLoggedIn && hasAccess("reports", userInfo, "view") &&
              <>
                  <MenuItem label={t('left_nav.reports')} icon="fa-bar-chart" active={activeNav==="reports"} >
                      <div className={activeNav==="reports" ? "sub-menu show" : "sub-menu"}>
                          <MenuItem label={t('left_nav.live_feed')} short="Lf" link={"/" + facilityLink + "/dashboard/livefeed"} />
                          <MenuItem label={t('left_nav.payments')} short="Pm" link={"/" + facilityLink + "/payment/list"} />
                          <MenuItem label={t('left_nav.invoice_payments')} short="Ip" link={"/" + facilityLink + "/invoice/paymentlist"} />
                          <MenuItem label={t('left_nav.invoice_aging_reports')} short="Ar" link={"/" + facilityLink + "/invoice/aged-report"} />
                          <MenuItem label={t('left_nav.credit')} short="Cr" link={"/" + facilityLink + "/credit/list"} />
                          <MenuItem label={t('left_nav.sales_report')} short="Sr" link={"#"} onClick={() => showSalesModal()}  />
                          <MenuItem label={t('left_nav.allocation_report')} short="Ar" link={"#"} onClick={() => showAllocationModal()}  />
                      </div>
                  </MenuItem>
              </>
          }
          {
            isLoggedIn && hasAccess("settings", userInfo, "view") &&
              <>
                  <MenuItem label={t('left_nav.settings')} icon="fa-cog" active={activeNav==="settings"} >
                    <div className={activeNav==="settings" ? "sub-menu show" : "sub-menu"}>
                        {
                            hasAccess("settings", userInfo, "update") &&
                                <>
                                  <MenuItem label={t('left_nav.general_settings')} short="Gs" link={"/" + facilityLink + "/setting/setting"} />
                                  <MenuItem label={t('left_nav.admin_accounts')} short="Aa" link={"/" + facilityLink + "/setting/adminlist"} />
                                  <MenuItem label={t('left_nav.customer_types')} short="Ct" link={"/" + facilityLink + "/setting/customertypelist"} />
                                  {
                                    isLoggedIn && settings.allowClasses > 0 && hasAccess("booking", userInfo, "view") &&
                                      <>
                                          <MenuItem label={t('left_nav.class_settings')} short="Cs" link={"/" + facilityLink + "/setting/classes"} />
                                      </>
                                  }
                                  <MenuItem label={t('left_nav.emails')} short="Em" link={"/" + facilityLink + "/setting/emaillist"} />
                                  <MenuItem label={t('left_nav.invoice_agreement')} short="In" link={"/" + facilityLink + "/setting/agreement"} />
                                  <MenuItem label={t('left_nav.payment_systems')} short="Ps" link={"/" + facilityLink + "/setting/paymenttypelist"} />
                                  <MenuItem label={t('left_nav.taxes')} short="Tx" link={"/" + facilityLink + "/setting/tax"} />
                                  <MenuItem label={t('left_nav.accounts')} short="Ac" link={"/" + facilityLink + "/setting/accountlist"} />
                                  <MenuItem label={t('left_nav.product_add_ons')} short="Pa" link={"/" + facilityLink + "/setting/addonlist"} />
                                </>
                        }
                      <MenuItem label={t('left_nav.locker_rooms')} short="Lr" link={"/" + facilityLink + "/setting/lockers"} />
                      <MenuItem label={t('left_nav.media_screens')} short="Ms" link={"/" + facilityLink + "/setting/mediascreen"} />
                      <MenuItem label={t('left_nav.venue_sorting')} short="Vs" link={"/" + facilityLink + "/setting/venuesortlist1"} />
                      
                      {
                        hasAccess("settings", userInfo, "update") && _.map(venues, (venue, i) =>
                            <MenuItem key={i} label={venue.name} short="Vu" link={"/" + facilityLink + "/setting/booking?id=" + venue.id} />
                        )
                      }
                    </div>
                  </MenuItem>
              </>
          }
          {
            isLoggedIn && isNotAdmin(userInfo) &&
              <MenuItem label={t('header.how_to_book')} icon="fa-question-circle" link={"#"} onClick={() => setShowHowToBookModal(true)} active={activeNav==="how-to-book"} />
          }
          {
            isLoggedIn && isNotAdmin(userInfo) &&
              <>
                  <MenuItem label={t('left_nav.settings')} icon="fa-cog" active={activeNav==="settings"} >
                      <div className={activeNav==="settings" ? "sub-menu show" : "sub-menu"}>
                          <MenuItem label={"Profile"} icon="fa-user" link={"/" + facilityLink + "/user/user_profile"} active={activeNav==="profile"} />
                          {
                              (settings.showCreditCardOnline === "yes" || settings.showCreditCardOnline === "userOnly") && settings.paymentProcessor === "STRIPE" &&
                              <MenuItem label="Payment Methods" short="Pm" link={`/${facilityLink}/user/payment_methods`} />
                          }
                      </div>
                  </MenuItem>
              </>
          }
          {
            isLoggedIn && isNotAdmin(userInfo) && false &&
              <MenuItem label={t('left_nav.settings')} icon="fa-cog" active={activeNav==="settings"} >
                <div className={activeNav==="settings" ? "sub-menu show" : "sub-menu"}>
                  <MenuItem label="Account" short="Ac" link={`${facilityLink}/user/account/${settings.showCreditCardOnline}`} />
                  {
                    (settings.showCreditCardOnline === "yes" || settings.showCreditCardOnline === "userOnly") &&
                      <MenuItem label="Payment Methods" short="Pm" link={`${facilityLink}/user/payment_methods`} />
                  }
                </div>
              </MenuItem>
          }
        </ul>
      </div>
      {
        settings.isPartnerFacility &&
        <div className="left-nav-bottom-banner">
            <img src="/images/powered_by_leagueapps.png" />
        </div>
      }
      <SimpleModal show={showHowToBookModal} onClose={setShowHowToBookModal} titleIcon="fa-question-circle" title={t('header.how_to_book')} content={settings.userInstruction} />
        <SalesAllocationReportModal
            show={showSalesAllocationReportModal}
            onClose={setShowSalesAllocationReportModal}
            type={salesAllocationReportType} />
    </div>
  );
}

function MenuItem(props) {
    const navigate = useNavigate();
    const handleClick = (event, link) => {
        event.preventDefault();
        if (props.onClick) {
            props.onClick();
        } else if (link) {
            navigate(link);
        }
    }

    return (
        <>
            <li className={(props.children ? "left-nav-menu-drop " : "") + (props.active ? "show" : "")}>
                <a href={props.link} onClick={(event) => handleClick(event, props.link)} >
                    <div className="nav-title"><span>{props.label}</span></div>
                    { props.children && <span className="arrow"></span> }
                    <div className={"icon-thumbnail" + (props.active ? " active" : "")}>{ props.icon ? <i className={"fa " + props.icon}></i> : <span>{props.short}</span> }</div>
                </a>
            </li>
            {props.children}
        </>
    )
}

export default LeftNav;
