import { useEffect, useState, createRef, useContext, useRef } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import BaseContainer from '../../components/Container';
import BaseForm from '../../components/BaseForm';
import TabHeader from '../../components/TabHeader';
import SubmitButton from '../../components/SubmitButton';
import {serverPost, getInvoiceCreationUrl} from '../../helpers/server';
import {
    currencyFormat,
    BaseContext,
    hasAccess
} from '../../helpers/common';
import { useParams, useNavigate, Link } from "react-router-dom";
import {Table, Button, Row, Col, ButtonToolbar, Form} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const _ = require("lodash");

function InvoiceBulkCreate() {
    const { userInfo, getApiUrl, getFacilityName, settings } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    const [showMassInvoicesModal, setShowMassInvoicesModal] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [checkedItemIds, setCheckedItemIds] = useState([]);
    const [invoiceGroupData, setInvoiceGroupData] = useState({});
    const [dateFilter, setDateFilter] = useState("lastMonth");
    const [initialFields, setInitialFields] = useState({
        filterType: "lastMonth",
        startDate: moment().startOf('month').format("YYYY-MM-DD"),
        endDate: moment().endOf('month').format("YYYY-MM-DD"),
    });

    useEffect(() => {
        document.title = `Invoice Bulk Create - ${getFacilityName()}`;
    }, []);

    useEffect(() => {
        fetchData({ dateFilter: initialFields } );
    }, []);

    useEffect(() => {
        setInvoiceGroupData(_.keyBy(invoices, 'groupId'));
    }, [checkedItemIds]);

    const fetchData = async (data) => {
        await serverPost(getApiUrl(`/invoices/potential`), data).then((res) => {
            setInvoices(res.invoices);
        });
    }

    const updateChecked = (event) => {
        console.log("Update checked " + event);
        if (event.target.value === "checkall") {
            let newCheckedItems = [];
            if (event.target.checked) {
                newCheckedItems = _.map(invoices, (b, i) => getRowId(b));
                newCheckedItems.push("checkall");
            }
            setCheckedItemIds(newCheckedItems);
        } else {
            const newCheckedItems = [...checkedItemIds];
            if (event.target.checked) {
                newCheckedItems.push(event.target.value);
            } else {
                let index = newCheckedItems.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
                index = newCheckedItems.indexOf("checkall");
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
            }
            setCheckedItemIds(newCheckedItems);
        }
    }

    const onFieldChange = (name, value) => {
        if (name === "filterType") {
            setDateFilter(value);
        }
    }

    const onSubmit = async (data) => {
        const searchData = {
            dateFilter: data
        }
        await fetchData(searchData);
    }

    const onModalClose = () => {
        setShowMassInvoicesModal(false);
    }

    const createSingleInvoice = (event, invoice) => {
        navigate(getInvoiceCreationUrl(facilityLink, invoice.groupId, invoice.bookingIds));
    }

    const getRowId = (invoice) => {
        return String(invoice.groupId);
    }

    const tabs = [{
        label: t('tabs.invoices.invoices'),
        link: '/' + facilityLink + '/invoice/bulk_create',
        active: true
    }];

    const dateFilterOptions = [
        { 'value': "future", 'label': 'Future' },
        { 'value': "today", 'label': 'Today' },
        { 'value': "tomorrow", 'label': 'Tomorrow' },
        { 'value': "yesterday", 'label': 'Yesterday' },
        { 'value': "thisWeek", 'label': 'This Week' },
        { 'value': "nextWeek", 'label': 'Next Week' },
        { 'value': "lastWeek", 'label': 'Last Week' },
        { 'value': "thisMonth", 'label': 'This Month' },
        { 'value': "nextMonth", 'label': 'Next Month' },
        { 'value': "lastMonth", 'label': 'Last Month' },
        { 'value': "dateRange", 'label': 'Date Range' },
    ];

    return (
        <BaseContainer>
            <TabHeader items={tabs}/>
            <div className="content-box">
                <div className="content-body">
                    <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={onSubmit}>
                        <Row>
                            <Col md="3">
                                <BaseForm.Input type="select" name="filterType" options={dateFilterOptions}
                                                showSearch={false} label="Date Range" />
                            </Col>
                            {
                                dateFilter === "dateRange" &&
                                    <>
                                        <Col md="3">
                                            <BaseForm.Input type="date" name="startDate" id={"startDate"}
                                                            label="Start Date" />
                                        </Col>
                                        <Col md="3">
                                            <BaseForm.Input type="date" name="endDate" id={"endDate"}
                                                            label="End Date" />
                                        </Col>
                                    </>
                            }
                            <Col md="3" className="align-self-center">
                                <SubmitButton>Update</SubmitButton>
                            </Col>
                        </Row>
                    </BaseForm>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Table hover>
                        <thead>
                            <tr>
                                <th>
                                    <div className="checkbox check-success inline">
                                        <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedItemIds.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                                        <label htmlFor="checkall"/>
                                    </div>
                                </th>
                                <th>Group Name</th>
                                <th>No. of Invoices</th>
                                <th className="text-end">Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            _.map(invoices, (invoice, i) =>
                                <tr key={i}>
                                    <td className="no-stretch">
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value={getRowId(invoice)} id={getRowId(invoice)} name="delete[]"  checked={checkedItemIds.includes(getRowId(invoice))} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor={getRowId(invoice)}/>
                                        </div>
                                    </td>
                                    <td><Link to={`/${facilityLink}/booking/list?groupId=${invoice.group.id}`}>{ invoice.group.groupName }</Link></td>
                                    <td>{ invoice.bookingCount }</td>
                                    <td className="text-end"><span className="label">{ currencyFormat(invoice.totalSum) }</span></td>
                                    <td className="no-stretch text-center">
                                        {
                                            hasAccess("invoices", userInfo, "update") &&
                                                <Button onClick={(event) => createSingleInvoice(event, invoice)}>
                                                    <i className="fa fa-circle-plus"/> Create Invoice
                                                </Button>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        </BaseContainer>
    );
}

export default InvoiceBulkCreate;
