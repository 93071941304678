import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import { serverFetch, serverPatch, notifyEvent } from '../../helpers/server';
import {BaseContext, getCCFeeUsageOptions, hasAccess} from '../../helpers/common';
import { useParams, useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Form, Button, InputGroup, Row, Col, Alert } from 'react-bootstrap';
import Notification from '../../components/Notification';
import { useTranslation } from 'react-i18next';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';
import classnames from "classnames";
const _ = require("lodash");

function Payment() {
    const { userInfo, getApiUrl, getFacilityName, facilityLink, settings } = useContext(BaseContext);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("none");
    const [showAddedFees, setShowAddedFees] = useState(false);
    const [showFeesRow, setShowFeesRow] = useState(false);
    const [initialFields, setInitialFields] = useState({});
    const [ccFeePercent, setCCFeePercent] = useState(0);
    const [ccFeeBaseAmount, setCCFeeBaseAmount] = useState(0);
    const [ccFeeUsage, setCCFeeUsage] = useState([]);
    const { t } = useTranslation('common');

    const canView = hasAccess("settings", userInfo, "view");
    const canUpdate = hasAccess("settings", userInfo, "update");

    useEffect(() => {
        document.title = `Payment Systems - ${getFacilityName()}`;
    }, []);

    useEffect(() => {
        settings.addedFees = settings.creditCardFeeUsage.length !== 0 && settings.creditCardFeeUsage !== "none";
        setShowFeesRow(settings.paymentProcessor !== "NONE");
        setShowAddedFees(settings.addedFees);
        let tempSettings = { ...settings };
        tempSettings.creditCardMinAmountInCents = settings.creditCardMinAmountInCents / 100;
        if (settings.creditCardFeeUsage.length === 0 || settings.creditCardFeeUsage === "none") {
            tempSettings.creditCardFeeUsage = getCCFeeUsageOptions(t, settings.allowClasses).map((option) => option.id);
            if (!settings.creditCardFeeBaseAmountInCents) {
                tempSettings.creditCardFeeBaseAmountInCents = 30;
            }
            if (!settings.creditCardFeePercent) {
                tempSettings.creditCardFeePercent = 2.9;
            }
        }
        setInitialFields(tempSettings);
        setCCFeeUsage(settings.creditCardFeeUsage);
        setCCFeePercent(settings.creditCardFeePercent);
        setCCFeeBaseAmount(settings.creditCardFeeBaseAmountInCents);
    }, [settings]);

    const updateSettings = (data) => {
        if (showAddedFees && (ccFeePercent === 0) && (ccFeeBaseAmount === 0)) {
            Notification.Show(t('setting.payment.empty_cc_setting'), 'danger');
            return;
        }

        if (!showAddedFees) {
            data.creditCardFeeUsage = [];
        } else {
            if (data.creditCardMinAmountInCents) {
                data.creditCardMinAmountInCents = data.creditCardMinAmountInCents * 100;
            } else {
                if (!data.paymentProcessor) {
                    data.creditCardMinAmountInCents = 0;
                }
                else {
                    data.creditCardMinAmountInCents = settings.creditCardMinAmountInCents;
                }
            }

            data.creditCardFeeUsage = ccFeeUsage;
        }
        serverPatch(getApiUrl("/settings"), data, {}).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
                notifyEvent('settings');
            }
        });
    }

    const onFieldChange = (name, value) => {
        if (name === "paymentProcessor") {
            setSelectedPaymentMethod(value);
            if (value === "NONE") {
                setShowAddedFees(false);
                setShowFeesRow(false);
            } else {
                setShowFeesRow(true);
                setShowAddedFees(settings.addedFees);
            }
        } else if (name === "addedFees") {
            if (!value) {
                setInitialFields(oldSettings => {
                    let tempSettings = oldSettings;
                    
                    tempSettings.creditCardFeeUsage = [];

                    return tempSettings;
                })
            }
            setShowAddedFees(value);
        } else if (name === "creditCardFeePercent") {
            setCCFeePercent(parseFloat(value));
        } else if (name === "creditCardFeeBaseAmountInCents") {
            setCCFeeBaseAmount(parseFloat(value));
        }
    }

    const paymentMethodOptions = [
        { value: 'NONE', label: 'None' },
        { value: 'STRIPE', label: 'Stripe' },
        { value: 'BEANSTREAM', label: 'Beanstream/Bambora' },
        { value: 'SPORTSPAY', label: 'Sports Pay' },
    ];
    if (!_.isEmpty(settings) && settings.paymentProcessor === "HELCIM") {
        paymentMethodOptions.push({ value: 'HELCIM', label: 'Helcim' })
    }
    if (!_.isEmpty(settings) && settings.paymentProcessor === "PAYEEZY") {
        paymentMethodOptions.push({ value: 'PAYEEZY', label: 'Payeezy' })
    }
    if (!_.isEmpty(settings) && settings.paymentProcessor === "AUTHORIZE_NET") {
        paymentMethodOptions.push({ value: 'AUTHORIZE_NET', label: 'Authorize.net' })
    }
    if (!_.isEmpty(settings) && settings.paymentProcessor === "PAY_TRACE") {
        paymentMethodOptions.push({ value: 'PAY_TRACE', label: 'Paytrace' })
    }

    const showCreditCardOptions = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
        { value: 'adminOnly', label: 'Admin Only' },
        { value: 'userOnly', label: 'User Only' },
    ];

    const renderHelcimFields = () => {
        return (
            <>
                <BaseForm.Input colSpan="12" type="text" name="helcimAccount" label="Helcim Account ID"/>
                <BaseForm.Input colSpan="12" type="text" name="helcimToken" label="Helcim Gateway Token"/>
                <BaseForm.Input colSpan="12" type="check" name="helcimLive" label="Use Live Account" className="inline"/>
                <BaseForm.Input colSpan="12" type="select" name="showCreditCardOnline" label="Show Credit Card Online" options={showCreditCardOptions} showSearch={false}/>
                <p>* Above determines to whom a credit card option is shown while making a booking. However, credit card option will always be shown for invoices and agreements.</p>
            </>
        );
    }

    const renderStripeFields = () => {
        return (
            <>
                <BaseForm.Input colSpan="12" type="text" name="stripePubKey" label="Stripe Publishable Key"/>
                <BaseForm.Input colSpan="12" type="text" name="stripeAPIKey" label="Stripe API Key (Secrect Key)"/>
                <BaseForm.Input colSpan="12" type="text" name="stripeTestPubKey" label="Stripe Test Publishable Key"/>
                <BaseForm.Input colSpan="12" type="text" name="stripeTestAPIKey" label="Stripe Test API Key (Test Secrect Key)"/>
                <BaseForm.Input colSpan="12" type="check" name="helcimLive" label="Use Live Account" className="inline"/>
                <BaseForm.Input colSpan="12" type="select" name="showCreditCardOnline" label="Show Credit Card Online" options={showCreditCardOptions} showSearch={false}/>
                <p>* Above determines to whom a credit card option is shown while making a booking. However, credit card option will always be shown for invoices and agreements.</p>
            </>
        );
    }

    const renderSportsPayFields = () => {
        return (
            <>
                <BaseForm.Input colSpan="12" type="text" name="sportsPayTermID" label="Terminal ID"/>
                <BaseForm.Input colSpan="12" type="text" name="sportsPayPassword" label="Password"/>
                <BaseForm.Input colSpan="12" type="check" name="helcimLive" label="Use Live Account" className="inline"/>
                <BaseForm.Input colSpan="12" type="select" name="showCreditCardOnline" label="Show Credit Card Online" options={showCreditCardOptions} showSearch={false}/>
                <p>* Above determines to whom a credit card option is shown while making a booking. However, credit card option will always be shown for invoices and agreements.</p>
            </>
        );
    }

    const renderPayeezyFields = () => {
        return (
            <>
                <BaseForm.Input colSpan="12" type="text" name="payeezyAPI" label="Payeezy API Key"/>
                <BaseForm.Input colSpan="12" type="text" name="payeezySecret" label="Payeezy Secret Key"/>
                <BaseForm.Input colSpan="12" type="text" name="payeezyMerchant" label="Payeezy Merchant Token"/>
                <BaseForm.Input colSpan="12" type="check" name="helcimLive" label="Use Live Account" className="inline"/>
                <BaseForm.Input colSpan="12" type="select" name="showCreditCardOnline" label="Show Credit Card Online" options={showCreditCardOptions} showSearch={false}/>
                <p>* Above determines to whom a credit card option is shown while making a booking. However, credit card option will always be shown for invoices and agreements.</p>
            </>
        );
    }

    const renderBeanstreamFields = () => {
        return (
            <>
                <BaseForm.Input colSpan="12" type="text" name="beanStreamMerchantId" label="Beanstream Merchant ID"/>
                <BaseForm.Input colSpan="12" type="text" name="beanStreamAPIKey" label="Beanstream API Key"/>
                <p><strong>Beanstream/Bambora 2</strong></p>
                <p>Optional - Contact support for more details</p>
                <BaseForm.Input colSpan="12" type="text" name="beanStreamMerchantId2" label="Beanstream Merchant ID 2 (Contact Support)"/>
                <BaseForm.Input colSpan="12" type="text" name="beanStreamAPIKey2" label="Beanstream API Key 2 (Contact Support)"/>
                <p><strong>Beanstream/Bambora 3</strong></p>
                <p>Optional - Contact support for more details</p>
                <BaseForm.Input colSpan="12" type="text" name="beanStreamMerchantId3" label="Beanstream Merchant ID 3 (Contact Support)"/>
                <BaseForm.Input colSpan="12" type="text" name="beanStreamAPIKey3" label="Beanstream API Key 3 (Contact Support)"/>
                <BaseForm.Input colSpan="12" type="check" name="helcimLive" label="Use Live Account" className="inline"/>
                <BaseForm.Input colSpan="12" type="select" name="showCreditCardOnline" label="Show Credit Card Online" options={showCreditCardOptions} showSearch={false}/>
                <p>* Above determines to whom a credit card option is shown while making a booking. However, credit card option will always be shown for invoices and agreements.</p>
            </>
        );
    }

    const renderGlobalOneFields = () => {
        return (
            <>
                <BaseForm.Input colSpan="12" type="text" name="globalOneId" label="GlobalOnePay Terminal ID"/>
                <BaseForm.Input colSpan="12" type="text" name="globalOneSecret" label="GlobalOnePay Shared Secret"/>
                <BaseForm.Input colSpan="12" type="check" name="helcimLive" label="Use Live Account" className="inline"/>
                <BaseForm.Input colSpan="12" type="select" name="showCreditCardOnline" label="Show Credit Card Online" options={showCreditCardOptions} showSearch={false}/>
                <p>* Above determines to whom a credit card option is shown while making a booking. However, credit card option will always be shown for invoices and agreements.</p>
            </>
        );
    }

    const renderAuthorizeNetFields = () => {
        return (
            <>
                <BaseForm.Input colSpan="12" type="text" name="authorizeNetApi" label="Authorize.Net Login ID"/>
                <BaseForm.Input colSpan="12" type="text" name="authorizeNetKey" label="Authorize.Net Transaction Key"/>
                <BaseForm.Input colSpan="12" type="text" name="ccDescription" label="Purchase Description"/>
                <BaseForm.Input colSpan="12" type="check" name="helcimLive" label="Use Live Account" className="inline"/>
                <BaseForm.Input colSpan="12" type="select" name="showCreditCardOnline" label="Show Credit Card Online" options={showCreditCardOptions} showSearch={false}/>
                <p>* Above determines to whom a credit card option is shown while making a booking. However, credit card option will always be shown for invoices and agreements.</p>
            </>
        );
    }

    const renderPayTraceFields = () => {
        return (
            <>
                <BaseForm.Input colSpan="12" type="text" name="payTraceToken" label="PayTrace Token"/>
                <BaseForm.Input colSpan="12" type="text" name="payTraceIntegratorId" label="PayTrace Integrator ID"/>
                <BaseForm.Input colSpan="12" type="file" name="payTracePublicKey" label="PayTrace Public Key" fileType="paytrace_pem" />
                <BaseForm.Input colSpan="12" type="check" name="helcimLive" label="Use Live Account" className="inline"/>
                <BaseForm.Input colSpan="12" type="select" name="showCreditCardOnline" label="Show Credit Card Online" options={showCreditCardOptions} showSearch={false}/>
                <p>* Above determines to whom a credit card option is shown while making a booking. However, credit card option will always be shown for invoices and agreements.</p>
            </>
        );
    }

    const renderMonerisFields = () => {
        const countryOptions = [
            { value: 'CA', label: 'Canada' },
            { value: 'US', label: 'United States' },
        ];
        return (
            <>
                <BaseForm.Input colSpan="12" type="text" name="monerisStoreId" label="Moneris Store ID"/>
                <BaseForm.Input colSpan="12" type="text" name="monerisToken" label="Moneris Token"/>
                <BaseForm.Input colSpan="12" type="text" name="monerisProfileId" label="Moneris Profile ID"/>
                <BaseForm.Input colSpan="12" type="select" name="monerisCountry" label="Moneris Country" options={countryOptions} showSearch={false} />
                <BaseForm.Input colSpan="12" type="check" name="helcimLive" label="Use Live Account" className="inline"/>
                <BaseForm.Input colSpan="12" type="select" name="showCreditCardOnline" label="Show Credit Card Online" options={showCreditCardOptions} showSearch={false}/>
                <p>* Above determines to whom a credit card option is shown while making a booking. However, credit card option will always be shown for invoices and agreements.</p>
            </>
        );
    }

    const renderSelectedPaymentMethodFields = (method) => {
        if (method === "HELCIM") {
            return renderHelcimFields();
        } else if (method === "STRIPE") {
            return renderStripeFields();
        } else if (method === "PAYEEZY") {
            return renderPayeezyFields();
        } else if (method === "BEANSTREAM") {
            return renderBeanstreamFields();
        } else if (method === "AUTHORIZE_NET") {
            return renderAuthorizeNetFields();
        } else if (method === "PAY_TRACE") {
            return renderPayTraceFields();
        } else if (method === "SPORTSPAY") {
            return renderSportsPayFields();
        }
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "payment-methods")}/>
            <div className="content-box">
                <div className="content-body">
                    <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={updateSettings}>
                        <Row>
                        <Col md="4">
                        <Row>
                            <BaseForm.Input colSpan="12" type="select" name="paymentProcessor" label="Payment Method" options={paymentMethodOptions} showSearch={false} />
                            { renderSelectedPaymentMethodFields(selectedPaymentMethod) }
                        </Row>
                        </Col>
                        </Row>
                        <SubmitButton variant="primary">Save</SubmitButton>
                    </BaseForm>
                </div>
            </div>
            {
                showFeesRow &&
                    <div className={classnames("content-box", canView ? "": "hide")}>
                        <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={updateSettings}>
                            <div className="content-body">
                                <strong>{t("payment_settings.settings.credit_card_fees")}</strong>
                                <p>{t("payment_settings.settings.cc_fees_option")}</p>
                                <Row>
                                    <Col md="3">
                                        <BaseForm.Input
                                            colSpan="12" name="addedFees" type="check"
                                            label={t('payment_settings.settings.added_fees')}
                                            disabled={!canUpdate}
                                        />
                                    </Col>
                                </Row>
                                {
                                    showAddedFees &&
                                    <>
                                        <Row>
                                            <Col md="3">
                                                <MultiSelectDropdown name="creditCardFeeUsage"
                                                                label={t('payment_settings.settings.added_fees_usage')}
                                                                items={getCCFeeUsageOptions(t, settings.allowClasses)}
                                                                selectedItems={ccFeeUsage}
                                                                onItemsChange={setCCFeeUsage}
                                                                required={showAddedFees} className="form" disabled={!canUpdate}/>
                                            </Col>
                                            <Col md="3">
                                                <BaseForm.Input colSpan="12" name="creditCardFeeLabel" type="text"
                                                                label={t('payment_settings.settings.added_fees_label')}
                                                                required={showAddedFees}
                                                                placeholder="Convenience Fees" disabled={!canUpdate}/>
                                            </Col>
                                        </Row>
                                        <strong>{t("payment_settings.settings.cc_fee_structure")}</strong>
                                        <p>{t("payment_settings.settings.cc_fee_struct_expl")}</p>
                                        <Row>
                                            <Col md="3">
                                                <BaseForm.Input colSpan="12" name="creditCardFeePercent" type="number"
                                                                label={t('payment_settings.settings.added_fees_percent')}
                                                                required={showAddedFees}
                                                                min={0} step={0.01}
                                                                rightContent="%" disabled={!canUpdate}/>
                                            </Col>
                                            <Col md="3">
                                                <BaseForm.Input colSpan="12" name="creditCardFeeBaseAmountInCents" type="number"
                                                                label={t('payment_settings.settings.added_fees_base_cost')}
                                                                required={showAddedFees}
                                                                min={0} step={1}
                                                                rightContent="¢" disabled={!canUpdate}/>
                                            </Col>
                                        </Row>
                                        {
                                            !isNaN(ccFeeBaseAmount) && !Number.isInteger(ccFeeBaseAmount) &&
                                            <Row>
                                                <Col md="6">
                                                    <Alert variant="danger">{t("setting.payment.cc_base_amount_decimals")}</Alert>
                                                </Col>
                                            </Row>
                                        }
                                        {
                                            !isNaN(ccFeePercent) && !Number.isInteger(ccFeePercent * 100) &&
                                            <Row>
                                                <Col md="6">
                                                    <Alert variant="danger">{t("setting.payment.cc_percent_decimals")}</Alert>
                                                </Col>
                                            </Row>
                                        }
                                        {
                                            (ccFeePercent === 0) && (ccFeeBaseAmount === 0) &&
                                            <Row>
                                                <Col md="6">
                                                    <Alert variant="danger">{t("setting.payment.empty_cc_setting")}</Alert>
                                                </Col>
                                            </Row>
                                        }
                                        <strong>{t("payment_settings.settings.only_fees_over_amt")}</strong>
                                        <p>{t("payment_settings.settings.only_bills_over_amt")}</p>
                                        <Row>
                                            <Col md="6">
                                                <BaseForm.Input colSpan="12" name="creditCardMinAmountInCents" type="number"
                                                                label={t('payment_settings.settings.added_fees_min_amount')}
                                                                min={0} step={0.01}
                                                                leftContent="$" disabled={!canUpdate}/>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                { canUpdate && <SubmitButton variant="primary">Save</SubmitButton> }
                            </div>
                        </BaseForm>
                    </div>
            }
        </BaseContainer>
    );
}

export default Payment;
