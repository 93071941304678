import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import EditSMSModal from '../../components/modals/EditSMSModal';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import Notification from '../../components/Notification';
import TabHeader from '../../components/TabHeader';
import { serverFetch, serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function SMSList() {
    const { userInfo, getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [smses, setSmses] = useState([]);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [showEditSMSModal, setShowEditSMSModal] = useState(false);

    useEffect(() => {
        document.title = t('setting.email_sms.page_title', { facilityName: getFacilityName() });
    }, [facilityLink]);

    useEffect(() => {
        fetchData();
    }, [facilityLink]);

    const fetchData = (skipCache = false) => {
        setLoading(true);
        serverFetch(getApiUrl('/smses'), { skipCache }).then((res) => {
            setSmses(res);
            setLoading(false);
        })
    }

    const onModalClose = () => {
        fetchData(true);
        setShowEditSMSModal(false);
    }

    const editSMS = (event, sms) => {
        event.preventDefault();
        setItemToEdit(sms);
        setShowEditSMSModal(true);
    }

    const onCopyTemplates = () => {
        serverPost(getApiUrl(`/smses/duplicate_default`)).then((res) => {
            if (res.ok) {
                Notification.Show(t('setting.email_sms.successfully_copied'));
                fetchData(true);
            }
        });
    }

    const tabsParams = {
        isSuperAdmin: userInfo.isSuperAdmin
    };
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "email-sms", tabsParams)} />
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="6">
                            <Loader loading={loading}>
                            {
                                !_.isEmpty(smses) ?
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>{t('setting.email_sms.title')}</th>
                                                <th>{t('setting.email_sms.subject')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(smses, (item, i) =>
                                                    <tr key={i} id={item.id}>
                                                        <td className="skinny"><Button variant="alink" onClick={(event) => editSMS(event, item)}>{item.title}</Button></td>
                                                        <td className="skinny">{item.subject}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                : <Button variant="danger" onClick={onCopyTemplates}>{t('setting.email_sms.copy_sms_templates')}</Button>
                            }
                            </Loader>
                        </Col>
                    </Row>
                </div>
            </div>
            <EditSMSModal show={showEditSMSModal} onClose={onModalClose} itemToEdit={itemToEdit} />
        </BaseContainer>
    );
}

export default SMSList;
