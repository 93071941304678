import '../App.scss';
import { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
const _ = require("lodash");

function ErrorComponent(props) {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const [title, setTitle] = useState(t('default_error_page.title'));

    useEffect(() => {
        if (!_.isNil(props.title)) {
            setTitle(props.title);
        }
    }, [props.title]);

    return (
        <div className="content-box">
            <div className="content-body tall">
                <Row>
                    <Col md="12" className="text-center">
                        <h2 className="title">{title}</h2>
                        <br/>
                    </Col>
                    <Col md="12" className="text-center">
                        <p>{t('default_error_page.body')}</p>
                        <br/>
                    </Col>
                    <Col md="12" className="text-center">
                        <Button variant="outline-primary" onClick={() => navigate(-1)}>{t('default_error_page.previous')}</Button>
                        <Button variant="success" href="https://www.rectimes.com">{t('default_error_page.go_to_main_page')}</Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ErrorComponent;
