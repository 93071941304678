import '../App.scss';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

function Referral() {
  return (
      <div className="content-container">
        <div className="header">
            <div className="pull-center">
                <div className="header-item">
                    <div className="header-item-inner header-logo">
                      <Link to={"/"}><img src={"header-logo.png"}/><span className="d-none d-md-inline">RecTimes</span></Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="content_wrapper">
            <Container>
                <Row>
                    <Col lg={{span:8, offset:2}} >
                        <br/><br/>
                        <h4>Refer your friends to RecTimes and get up to $300!</h4>
                        <p>For each person you refer who takes a demo, <strong>you will get $100</strong>. If they go on to sign up for RecTimes, <strong>you will get $300</strong> for each facility!</p>
                        <p>Wait that's not all, for the friends you refer, we will <strong>waive their setup fee ($500 worth)</strong> for RecTimes.</p>
                    </Col>
                    <Col lg={{span:8, offset:2}} >
                        <br/>
                        <h4>Start referring today!</h4>
                        <p>Referring is super easy. Here are two ways to do it.</p>
                    </Col>
                    <Col lg={{span:8, offset:2}} >
                        <Row>
                            <Col lg="12" >
                                <ol>
                                    <li>
                                        <strong>Tell them about RecTimes</strong>
                                        <p>No better way than to directly tell them about RecTimes and have them reach out to us.</p>
                                    </li>
                                    <li>
                                        <strong>Let us know their details and we will reach out to them</strong>
                                        <p>If you are uncomfortable reaching out to them directly, we are more than happy to reach out to them and see if they are interested.</p>
                                        <Button href="https://forms.gle/7PrEXkWCBvhnmB8x5">Start referring!</Button>
                                    </li>
                                </ol>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={{span:8, offset:2}} >
                        <br/>
                        <span><strong>Redeeming Rewards</strong></span>
                        <p><i>To qualify for the reward, the person you refer must sign up for and attend a live demo with RecTimes. Your reward will be emailed to you within 30 days of their demo date or their account setup date. You can get the reward as a gift card or a credit on your next invoice.</i></p>
                    </Col>
                </Row>
            </Container>
        </div>
      </div>
  );
}

export default Referral;
