import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import { useContext, useState, useEffect} from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { Button, Row, Col, ButtonGroup, Alert, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { serverFetch } from '../../helpers/server';
import Loader from '../Loader';

const _ = require("lodash");

function MassInvoiceEmailModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [emailEnabled, setEmailEnabled] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (props.show) {
            serverFetch(getApiUrl(`/emails`)).then((res) => {
                const invoiceEmailOptionEnabled = res.find(obj => obj.tag === 'invoice' && obj.enabled === true);
                if (invoiceEmailOptionEnabled) {
                    setEmailEnabled(true);
                } else {
                    setEmailEnabled(false);
                }
                setLoading(false);
            })
        }
    }, [props.show]);

    const onSubmit = (data) => {
        const batchEmailData = {
            customerMessage: data.message,
            emails: _.map(props.invoices, (invoice, i) => {
                return {
                    invoiceUUID: invoice.uuid,
                    emailAddress: data[String(i)].group.email,
                    name: data[String(i)].to,
                    linkOnly: data[String(i)].linkOnly,
                }
            })
        }
        serverPost(getApiUrl('/invoices/batch_email'), batchEmailData).then((res) => {
            props.onClose();
            Notification.Show("Email(s) sent successfully");
        });
    }

    return (
        <BaseModal size="lg" {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Mass Invoice Email</BaseModal.Title>
            </BaseModal.Header>
            <Loader loading={loading}>
            { emailEnabled ? (
              <BaseForm initialFormFields={props.invoices} onSubmit={onSubmit}>
                 <BaseModal.Body>
                     <Table hover>
                         <thead>
                             <tr>
                                 <th>INV #</th>
                                 <th>Group</th>
                                 <th>Name</th>
                                 <th>Email</th>
                                 <th className="text-center">Link Only</th>
                             </tr>
                         </thead>
                         <tbody>
                         {
                             _.map(props.invoices, (invoice, i) =>
                                 <tr key={i}>
                                     <td>
                                         {invoice.invoiceNumber}
                                     </td>
                                     <td>{invoice.group.name}</td>
                                     <td>
                                         <BaseForm.TextGroup name={`${i}.to`} label="Name" hideLabel required />
                                     </td>
                                     <td>
                                         <BaseForm.TextGroup name={`${i}.group.email`} label="Email" hideLabel required />
                                     </td>
                                     <td className="text-center">
                                         <BaseForm.Check className="lg-switch" name={`${i}.linkOnly`} />
                                     </td>
                                 </tr>
                             )
                         }
                         </tbody>
                     </Table>
                     <Row>
                         <BaseForm.Input type="textarea" name="message" label="Customer Message" placeholder="Message" />
                     </Row>
                 </BaseModal.Body>
                 <BaseModal.Footer>
                     <Row>
                         <Col className="text-end">
                             <ButtonGroup>
                                 <SubmitButton className="btn-complete">Send</SubmitButton>
                             </ButtonGroup>
                         </Col>
                     </Row>
                 </BaseModal.Footer>
              </BaseForm>

            ) : (
                <BaseModal.Body>
                    <Row>
                        <p>{t('email.not_enabled_1')} <a href={`/${facilityLink}/setting/emaillist`}>{t('email.not_enabled_2')}</a> {t('email.not_enabled_3')}</p>
                    </Row>
                </BaseModal.Body>
            )}
           </Loader>
        </BaseModal>
    );

}

export default MassInvoiceEmailModal;
