import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import {useContext, useEffect, useState} from 'react';
import { BaseContext } from '../../helpers/common';
import {serverFetch, serverPost} from '../../helpers/server';
import { Button, Row, Col, ButtonGroup, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from "../Loader";

const _ = require("lodash");

function SingleInvoiceEmailModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [emailEnabled, setEmailEnabled] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (props.show) {
            serverFetch(getApiUrl(`/emails`)).then((res) => {
                const invoiceEmailOptionEnabled = res.find(obj => obj.tag === 'invoice' && obj.enabled === true);
                if (invoiceEmailOptionEnabled) {
                    setEmailEnabled(true);
                } else {
                    setEmailEnabled(false);
                }
                setLoading(false);
            })
        }
    }, [props.show]);

    const onSubmit = (data) => {
        const emails = _.map(data.email.split(","), (e) => e.trim());
        const emailData = {
            emails: _.map(emails, (e) => {
                return {
                    invoiceUUID: props.invoice.uuid,
                    emailAddress: e,
                    name: "",
                    linkOnly: data.sendLinkOnly
                }
            }),
            customerMessage: data.customerMessage
        };
        serverPost(getApiUrl('/invoices/batch_email'), emailData).then((res) => {
            props.onClose();
        });
    }

    return (
        <BaseModal {...props}>
            <BaseForm initialFormFields={{ email: props.invoice && props.invoice.group && props.invoice.group.email }} onSubmit={onSubmit}>
                <BaseModal.Header>
                    <BaseModal.Title>Send Invoice Email</BaseModal.Title>
                </BaseModal.Header>
                <Loader loading={loading}>
                { emailEnabled ? (
                    <>
                        <BaseModal.Body>
                            <Row>
                                <Alert variant="info">
                                    Feel free to add as many emails as you like. Separate each email with a comma. (e.g. "one@rectimes.com, two@rectimes.com, three@rectimes.com")
                                </Alert>
                                <BaseForm.Input colSpan={12} name="email" label="Email Addresses" type="text" placeholder="Email addresses" validations={{required: true, validEmails: true}} />
                                <BaseForm.Input colSpan={12} name="customerMessage" label="Customer Message (Optional)" type="textarea" placeholder="Your email message to the customer" />
                                <BaseForm.Input colSpan={12} name="sendLinkOnly" label="Send Link Only (No PDF will be Attached" type="check" />
                            </Row>
                        </BaseModal.Body>
                        <BaseModal.Footer>
                            <Row>
                                <Col className="text-end">
                                    <ButtonGroup>
                                        <SubmitButton>Send</SubmitButton>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </BaseModal.Footer>
                    </>
                ) : 
                    <BaseModal.Body>
                        <Row>
                            <p>{t('email.not_enabled_1')} <a href={`/${facilityLink}/setting/emaillist`}>{t('email.not_enabled_2')}</a> {t('email.not_enabled_3')}</p>
                        </Row>
                    </BaseModal.Body>
                }
                </Loader>
            </BaseForm>
        </BaseModal>
    );

}

export default SingleInvoiceEmailModal;
