import '../../App.scss';
import moment from 'moment';
import { useEffect, useState, useRef, useContext } from 'react';
import { serverFetch } from '../../helpers/server'
import { useParams, useSearchParams, Link } from "react-router-dom";
import { getFormattedImageLink, BaseContext } from '../../helpers/common';
import { Alert, Row, Col, Table, Image, Button } from 'react-bootstrap';
import ErrorComponent from '../../components/ErrorComponent';
const _ = require('lodash');

function Preview(props) {
    const { getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const [ searchParams ] = useSearchParams();
    const screenId = searchParams.get('id');
    const date = searchParams.get('date');

    const [notFound, setNotFound] = useState(false);
    const [counter, setCounter] = useState(1);
    const [timeString, setTimeString] = useState(null);
    const [lastRefreshString, setLastRefreshString] = useState(null);
    const [venues, setVenues] = useState([]);
    const [selectedVenues, setSelectedVenues] = useState([]);
    const [settings, setSettings] = useState({});
    const [details, setDetails] = useState({});

    const timeCounterRef = useRef(null);
    const refreshCounterRef = useRef(null);

    useEffect(() => {
        document.title = "Media Screen Preview - " + getFacilityName();
    }, []);

    useEffect(() => {
        if (timeCounterRef.current) {
            clearInterval(timeCounterRef.current);
        }
        timeCounterRef.current = setInterval(() => {
            setTimeString(moment().format('ddd, MMMM Do, h:mm:ss a'));
        }, 1000);

        setLastRefreshString(moment().format('h:mm:ss a'));
        if (refreshCounterRef.current) {
            clearInterval(refreshCounterRef.current);
        }
        refreshCounterRef.current = setInterval(() => {
            setCounter(counter + 1);
            setLastRefreshString(moment().format('h:mm:ss a'));
        }, 300000);
    }, []);

    useEffect(() => {
        fetchData();
    }, [facilityLink]);

    useEffect(() => {
        if (counter === 1) {
            return;
        }
        window.location.reload();
    }, [counter]);

    useEffect(() => {
        if (!details || !venues) {
            return;
        }

        const rinkIdsString = String(details.rinkId);
        const rinkIds = rinkIdsString.split(",");
        const svenues = _.filter(venues, (venue, i) => _.includes(rinkIds, String(venue.id)));
        const sortedVenues = _.sortBy(svenues, (sv) => _.indexOf(rinkIds, String(sv.id)));
        setSelectedVenues(sortedVenues);
    }, [details, venues]);

    const fetchData = (skipCache = false) => {
        const onError = (res) => {
            if (res.status === 404) {
                setNotFound(true);
            }
        }
        serverFetch(getApiUrl(`/mediascreens/${screenId}`), { skipCache }, onError).then((res) => {
            if (res) {
                setDetails(res);
            }
        })

        serverFetch(getApiUrl(''), { skipCache }).then((res) => {
            setSettings(res);
        })

        serverFetch(getApiUrl('/venues'), { skipCache }).then((res) => {
            setVenues(res);
        })
    }

    const logoUrl = getFormattedImageLink(settings.headerLogo, "https://rectimes.app/uploads/logoheader/");
    const messageStyle = { fontSize: "20px" }
    if (details.msMsgFS) {
        messageStyle["fontSize"] = `${details.msMsgFS}px`;
    }
    if (details.msMsgLH) {
        messageStyle["lineHeight"] = `${details.msMsgLH}px`;
    }

    return (
        <div className="content-container">
            {
                (notFound || !details.mshideTopBar) &&
                    <div className="header noprint">
                        <Row>
                            <Col lg="6" className="d-none d-lg-inline">
                                <div className="header-item">
                                    <div className="header-item-inner header-logo">
                                        <a href={"https://rectimes.app/" + facilityLink}><img alt="Company Logo" src={logoUrl}/><span className="d-none d-md-inline">{settings.headerName}</span></a>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6" className="text-end d-none d-lg-inline">
                                <div>
                                    <div className="header-clock">
                                        { timeString }
                                    </div>
                                    <div className="header-clock-subtitle">
                                        Last Refresh: { lastRefreshString }. Refreshes every 5 minutes.
                                    </div>
                                </div>
                            </Col>
                            <Col lg="12" className="text-center d-lg-none d-table">
                                <div className="header-item">
                                    <div className="header-item-inner header-logo">
                                        <Link to={"https://rectimes.app/" + facilityLink}><img alt="Company Logo" src={logoUrl}/><span className="d-none d-md-inline">{settings.headerName}</span></Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>
            }
            <div className="screen-wrapper" style={{ "paddingTop": details.mshideTopBar ? "15px": "75px" }}>
            {
                notFound &&
                    <ErrorComponent title="Oops! Media screen not found."/>
            }
            {
                details.msTopMsg &&
                    <Alert style={messageStyle} className={details.msTopMsgColor}><div dangerouslySetInnerHTML={{__html: details.msTopMsg }} /></Alert>
            }
            <Row>
            {
                _.map(selectedVenues, (venue, i) =>
                    <Col md={(!details.msStacked && selectedVenues.length) > 1 ? "6": "12"} key={i}>
                        <VenuePreview details={details} venue={venue} date={date} getApiUrl={getApiUrl}/>
                    </Col>
                )
            }
            </Row>
            {
                details.bottomImage &&
                    <Row><Col md="12"><div className="content-box">
                        <Image style={{ width: "100%" }}
                            src={getFormattedImageLink(details.bottomImage, "https://rectimes.app/uploads/mediascreenads/")}/>
                    </div></Col></Row>
            }
            {
                details.msBottomMsg &&
                    <Alert style={messageStyle} className={details.msBottomMsgColor}><div dangerouslySetInnerHTML={{__html: details.msBottomMsg }} /></Alert>
            }
            </div>
        </div>
    );
}

function VenuePreview(props) {
    const [bookingLockers, setBookingLockers] = useState([]);

    useEffect(() => {
        const dateString = props.date || moment().format("YYYY-MM-DD");
        serverFetch(props.getApiUrl(`/booking_lockers?venueId=${props.venue.id}&date=${dateString}`)).then((res) => {
            let filtered = _.filter(res, (r) => !(moment().isAfter(moment(r.startTimeLocal)) && moment().isAfter(moment(r.endTimeLocal))));
            if (props.details.noOfBookings) {
                filtered = _.slice(filtered, 0, props.details.noOfBookings);
            }
            setBookingLockers(filtered);
        })
    }, [props.venue, props.date, props.details]);

    const titleStyle = { fontSize: props.details.msTitleFS || "12px" };
    const messageStyle = { fontSize: props.details.msTimeFS || "12px" };
    const isAllEmpty = _.isEmpty(props.details.groupOne) && _.isEmpty(props.details.groupTwo) &&
        _.isEmpty(props.details.roomOne) && _.isEmpty(props.details.roomTwo);
    return (
        <div className="venue-preview">
            <div className="header-bar" style={{ background: props.venue.rinkColor || "#48b0f7" }}>
                {props.venue.name}
            </div>
            <Table>
                <thead>
                    <tr>
                        <th className="text-center" style={titleStyle}>Time</th>
                        {
                            (isAllEmpty || !_.isNil(props.details.groupOne)) &&
                                <th className="text-center dark" style={titleStyle}>{ props.details.groupOne || "Group 1" }</th>
                        }
                        {
                            (isAllEmpty || props.details.roomOne) &&
                                <th className="text-center dark" style={titleStyle}>{ props.details.roomOne || "Room" }</th>
                        }
                        {
                            (isAllEmpty || props.details.groupTwo) &&
                                <th className="text-center" style={titleStyle}>{ props.details.groupTwo || "Group 2" }</th>
                        }
                        {
                            (isAllEmpty || props.details.roomTwo) &&
                                <th className="text-center" style={titleStyle}>{ props.details.roomTwo || "Room" }</th>
                        }
                    </tr>
                </thead>
                <tbody>
                {
                    _.map(bookingLockers, (locker, i) =>
                        <tr key={i}>
                            <td className="text-center" style={messageStyle}>{ moment(locker.startTimeLocal).format("h:mma - ") + moment(locker.endTimeLocal).format("h:mma") }</td>
                            {
                                (isAllEmpty || !_.isNil(props.details.groupOne)) &&
                                    <td className="text-center dark bleft" style={messageStyle}>{ locker.groupName1 }</td>
                            }
                            {
                                (isAllEmpty || props.details.roomOne) &&
                                    <td className="text-center dark bright" style={messageStyle}>{ locker.locker1Name }</td>
                            }
                            {
                                (isAllEmpty || props.details.groupTwo) &&
                                    <td className="text-center bleft" style={messageStyle}>{ locker.groupName2 }</td>
                            }
                            {
                                (isAllEmpty || props.details.roomTwo) &&
                                    <td className="text-center bright" style={messageStyle}>{ locker.locker2Name }</td>
                            }
                        </tr>
                    )
                }
                </tbody>
            </Table>
        </div>
    )
}

export default Preview;
