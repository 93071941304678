import { Routes, Route } from "react-router-dom";
import './App.scss';
import NotFound from './pages/NotFound.js';
import FacilityComponent from './components/FacilityComponent.js';
import RequireSuperAdmin from './components/RequireSuperAdmin.js';
import RequireAuthentication from './components/RequireAuthentication.js';
import RequireAdmin from './components/RequireAdmin.js';
import RequireSpecificAdmin from './components/RequireSpecificAdmin.js';
import RequirePartnerFacility from './components/RequirePartnerFacility.js';

import PartnerDashboard from './pages/partner/Dashboard.js';

import BookingSearch from './pages/booking/Search.js';
import BookingList from './pages/booking/List.js';
import BookingPartnerList from './pages/booking/PartnerList.js';

import InvoiceList from './pages/invoice/List.js';
import InvoicePaymentList from './pages/invoice/PaymentList.js';
import InvoicePrintView from './pages/invoice/PrintView.js';
import InvoiceView from './pages/invoice/View.js';
import AgreementAccept from './pages/agreement/Accept.js';
import AgreementPrintView from './pages/agreement/PrintView.js';
import AgreementList from './pages/agreement/List.js';
import PaymentList from './pages/payment/List.js';
import CreditList from './pages/credit/List.js';
import ClassList from './pages/class/List.js';
import ClassView from './pages/class/View.js';
import UserGroupList from './pages/user/GroupList.js';
import UserComments from './pages/user/Comments.js';
import UserPaymentMethods from './pages/user/PaymentMethods.js';
import UserChangePassword from './pages/user/ChangePassword.js';
import UserProfile from './pages/user/UserProfile.js';

import LiveFeed from './pages/dashboard/LiveFeed.js';

import InvoiceAgedReport from './pages/invoice/AgedReport.js';

import Calendar from './pages/Calendar.js';
import Setting from './pages/setting/Setting.js';
import AdminList from './pages/setting/AdminList.js';
import ReplyToList from './pages/setting/ReplyToList.js';
import NotificationList from './pages/setting/NotificationList.js';
import APITokens from './pages/setting/APITokens.js';
import AccountList from './pages/setting/AccountList.js';
import SettingClasses from './pages/setting/Classes.js';
import SettingClassesEmbed from './pages/setting/ClassesEmbed.js';
import SettingClassesForms from './pages/setting/ClassesForms';
import SettingPolicy from './pages/setting/Policy';
import SettingBlockVenue from './pages/setting/BlockVenue';
import SettingMultiGroup from './pages/setting/MultiGroup';
import SettingMultiBooking from './pages/setting/MultiBooking';
import SettingMediaScreenList from './pages/setting/MediaScreenList';
import SettingSMSList from './pages/setting/SMSList';
import SettingEmailList from './pages/setting/EmailList';
import SettingDefaultEmailList from './pages/setting/DefaultEmailList';
import SettingBooking from './pages/setting/Booking';
import SettingInvAgr from './pages/setting/InvAgr';
import SettingAgreementTemplates from './pages/setting/AgreementTemplates';
import LockerRoom from './pages/setting/LockerRoom';
import CustomerTypeList from './pages/setting/CustomerTypeList';
import EventTypeList from './pages/setting/EventTypeList';
import AddOnList from './pages/setting/AddOnList';
import SettingTax from './pages/setting/Tax';
import HourlyRates from './pages/setting/HourlyRates';
import SettingPaymentMethodList from './pages/setting/PaymentMethodList';
import SettingPayment from './pages/setting/Payment';
import SettingAssignLockers from './pages/setting/AssignLockers';
import VenueSort from './pages/setting/VenueSort';
import MediaScreenPreview from './pages/mediascreen/Preview';
import Playground from './pages/internal/Playground';
import MediaScreenTest from './pages/internal/MediaScreenTest';

import InvoiceCreateOrUpdate from './pages/invoice/CreateOrUpdate';
import InvoiceBulkCreate from './pages/invoice/BulkCreate';
import AgreementCreateOrUpdate from './pages/agreement/CreateOrUpdate';
import ClassCreateOrUpdate from './pages/class/CreateOrUpdate.js';
import ClassesStep1 from './pages/registration/ClassesStep1.js';
import ClassesStep2 from './pages/registration/ClassesStep2.js';
import ClassesListParticipants from './pages/registration/ClassesListParticipants.js';
import VenueList from './pages/venue/List.js';
import FacilityList from './pages/facility/List.js';
import UserSpoof from './pages/facility/Spoof.js';
import Home from './Home.js';
import Referral from './pages/Referral.js';
import PartnerVenueSync from "./pages/partner/PartnerVenueSync.js";
import PartnerSites from "./pages/partner/PartnerSites.js";
import CustomerSync from "./pages/partner/CustomerSync.js";
import CustomerImport from "./pages/partner/CustomerImport";
import BatchImportVenues from "./pages/setting/BatchImportVenues.js";
import BatchImportUsers from "./pages/user/BatchImportUsers.js";
import ImportBooking from "./pages/booking/ImportBooking.js";

function App(props) {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/referral" element={<Referral />} />
                <Route path="/:facilityLink" element={<FacilityComponent />}>
                    <Route index element={<Calendar />} />
                    <Route path="index" element={<Calendar />} />
                    <Route path="cat/:categoryLink" element={<Calendar />} />
                    <Route path="index/index" element={<Calendar />} />
                    <Route path="booking/ccrequestpay" element={<Calendar handleRequestPay={true} />} />
                    <Route path="mediascreen/preview" element={<MediaScreenPreview />} />
                    <Route path="booking/search" element={<BookingSearch />} />
                    <Route path="registration/step-1" element={<ClassesStep1 />} />
                    <Route path="registration/step-2" element={<ClassesStep2 />} />
                    <Route path="invoice/view" element={<InvoiceView />} />
                    <Route path="invoice/pview" element={<InvoicePrintView />} />
                    <Route path="agreement/accept" element={<AgreementAccept />} />
                    <Route path="agreement/pview" element={<AgreementPrintView />} />
                    <Route path="user/change_password" element={<UserChangePassword />} />

                    <Route path="" element={<RequireAuthentication />}>
                        <Route path="booking/list" element={<BookingList />} />
                        <Route path="booking/partner_list" element={<BookingPartnerList />} />
                        <Route path="invoice/list" element={<InvoiceList />} />
                        <Route path="invoice/paymentlist" element={<InvoicePaymentList />} />
                        <Route path="agreement/list" element={<AgreementList />} />
                        <Route path="payment/list" element={<PaymentList />} />
                        <Route path="class/list" element={<ClassList />} />
                        <Route path="user/user_profile" element={<UserProfile />} />
                        <Route path="user/payment_methods" element={<UserPaymentMethods />} />

                        <Route path="" element={<RequireAdmin />}>
                            <Route path="class/create" element={<ClassCreateOrUpdate />} />
                            <Route path="class/update" element={<ClassCreateOrUpdate />} />
                            <Route path="class/view" element={<ClassView />} />
                            <Route path="invoice/create" element={<InvoiceCreateOrUpdate />} />
                            <Route path="invoice/update" element={<InvoiceCreateOrUpdate />} />
                            <Route path="invoice/bulk_create" element={<InvoiceBulkCreate />} />
                            <Route path="agreement/create" element={<AgreementCreateOrUpdate />} />
                            <Route path="agreement/update" element={<AgreementCreateOrUpdate />} />
                            <Route path="dashboard/livefeed" element={<LiveFeed />} />
                            <Route path="credit/list" element={<CreditList />} />
                            <Route path="user/grouplist" element={<UserGroupList />} />
                            <Route path="user/comments" element={<UserComments />} />

                            <Route path="invoice/aged-report" element={<InvoiceAgedReport />} />

                            <Route path="" element={<RequireSpecificAdmin type="settings" />}>
                                <Route path="setting/setting" element={<Setting />} />
                                <Route path="setting/adminlist" element={<AdminList />} />
                                <Route path="setting/replytolist" element={<ReplyToList />} />
                                <Route path="setting/notification" element={<NotificationList />} />
                                <Route path="setting/api_tokens" element={<APITokens />} />
                                <Route path="setting/accountlist" element={<AccountList />} />
                                <Route path="setting/classes-templates" element={<SettingClasses />} />
                                <Route path="setting/classes" element={<SettingClassesEmbed />} />
                                <Route path="setting/classes-forms" element={<SettingClassesForms />} />
                                <Route path="setting/customertypelist" element={<CustomerTypeList />} />
                                <Route path="setting/addonlist" element={<AddOnList />} />
                                <Route path="setting/policy" element={<SettingPolicy />} />
                                <Route path="setting/blockvenue" element={<SettingBlockVenue />} />
                                <Route path="setting/multigroup" element={<SettingMultiGroup />} />
                                <Route path="setting/multibooking" element={<SettingMultiBooking />} />
                                <Route path="setting/mediascreen" element={<SettingMediaScreenList />} />
                                <Route path="setting/smslist" element={<SettingSMSList />} />
                                <Route path="setting/emaillist" element={<SettingEmailList />} />
                                <Route path="setting/booking" element={<SettingBooking />} />
                                <Route path="setting/lockerroom" element={<LockerRoom />} />
                                <Route path="setting/lockers" element={<SettingAssignLockers />} />
                                <Route path="setting/tax" element={<SettingTax />} />
                                <Route path="setting/eventtypelist" element={<EventTypeList />} />
                                <Route path="setting/exception" element={<HourlyRates />} />
                                <Route path="setting/paymenttypelist" element={<SettingPaymentMethodList />} />
                                <Route path="setting/payment" element={<SettingPayment />} />
                                <Route path="setting/venuesortlist1" element={<VenueSort />} />
                                <Route path="setting/agreement" element={<SettingInvAgr />} />
                                <Route path="setting/agreement_templates" element={<SettingAgreementTemplates />} />
                                <Route path="user/batch_create" element={<BatchImportUsers />} />
                                <Route path="setting/batchimportvenues" element={<BatchImportVenues />} />
                                <Route path="booking/import" element={<ImportBooking />} />
                            </Route>

                            <Route path="setting/partner_venue_sync" element={<PartnerVenueSync />} />
                            <Route path="setting/partner_sites" element={<PartnerSites />} />
                            <Route path="partner/customersync" element={<CustomerSync />} />
                            <Route path="partner/customerimport" element={<CustomerImport />} />
                            <Route path="class/classes-list-participants" element={<ClassesListParticipants />} />
                        </Route>

                        <Route path="" element={<RequireSuperAdmin />}>
                            <Route path="setting/defaultemaillist" element={<SettingDefaultEmailList />} />
                            <Route path="venue/list" element={<VenueList />} />
                            <Route path="facility/list" element={<FacilityList />} />
                            <Route path="facility/spoof" element={<UserSpoof />} />
                       </Route>
                        <Route path="" element={<RequirePartnerFacility />}>
                           <Route path="partner/dashboard" element={<PartnerDashboard />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Route>

                <Route path="" element={<RequireSuperAdmin />}>
                    <Route path="internal/playground" element={<Playground />} />
                    <Route path="internal/mediascreen" element={<MediaScreenTest />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
