import '../App.scss';
import ConfirmationButton from './ConfirmationButton';
import {useTranslation} from "react-i18next";

function DeleteButton(props) {
    const { t } = useTranslation('common');

    return (
        <ConfirmationButton size={props.size || "sm"} onConfirm={props.onDelete} variant="danger"
            title={props.title || t('delete_modal.confirm_title')} body={props.body || t('delete_modal.confirm_body')}
            confirmationText={props.confirmationText}>
            <i className="fa fa-trash"/>
        </ConfirmationButton>
    );
}

export default DeleteButton;
