import $ from "jquery";
import 'jquery-ui/ui/widgets/sortable';
import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import DeleteButton from '../../components/DeleteButton';
import AddOrEditAddonModal from '../../components/modals/AddOrEditAddonModal';
import TabHeader from '../../components/TabHeader';
import { serverFetch, serverPost, serverDelete } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function AddonList() {
    const { getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [addons, setAddons] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [initialSortedItems, setInitialSortedItems] = useState([]);
    const [addonToEdit, setAddonToEdit] = useState(null);
    const [showAddAddonModal, setShowAddAddonModal] = useState(false);

    useEffect(() => {
        document.title = "Add Ons - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (loading) {
            return;
        }
        $(function() {
            $('#sortable-table').sortable({
                items:'tr',
                cursor: 'grabbing',
                stop: function(event, tbody) {
                    const sortedItems = $(this).sortable("toArray");
                    if (JSON.stringify(sortedItems) !== JSON.stringify(initialSortedItems)) {
                        updateAddonSort(sortedItems);
                    }
                },
                helper: function(e, tr) {
                    var $originals = tr.children();
                    var $helper = tr.clone();
                    $helper.children().each(function(index) {
                        // Set helper cell sizes to match the original sizes
                        $(this).width($originals.eq(index).width());
                    });
                    return $helper;
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialSortedItems, loading]);

    const onModalClose = () => {
        fetchData(true);
        setShowAddAddonModal(false);
    }

    const fetchData = (skipCache = false) => {
        setLoading(true);
        serverFetch(getApiUrl('/addons'), { skipCache }).then((res) => {
            setAddons(res);
            if (!_.isEmpty(res)) {
                setInitialSortedItems(res.map(r => String(r.id)));
            } else {
                setInitialSortedItems([]);
            }
            setLoading(false);
        })

        serverFetch(getApiUrl('/accounts'), { skipCache }).then((res) => {
            setAccounts(res);
        })
    }

    const updateAddonSort = (orderedItems) => {
        const updateAddonUrl = getApiUrl('/addons/sort');
        const data = {
            'ids': orderedItems
        };
        serverPost(updateAddonUrl, data).then((res) => {
            fetchData(true);
        })
    }

    const addAddon = () => {
        setAddonToEdit(null);
        setShowAddAddonModal(true);
    }

    const editAddon = (type) => {
        setAddonToEdit(type);
        setShowAddAddonModal(true);
    }

    const deleteAddon = (type) => {
        const deleteAddonUrl = getApiUrl(`/addons/${type.id}`);
        serverDelete(deleteAddonUrl, {}).then((res) => {
            fetchData(true);
        })
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "addons")} />
            <div className="content-box">
                <div className="content-body">
                    <Alert variant="info">
                        {t("setting.addons.alert")}
                    </Alert>
                    <Button variant="outline-primary" onClick={() => addAddon()}><i className="fa fa-plus-circle"></i> {t('setting.addons.add')}</Button>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-lg-9">
                            <Loader loading={loading}>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t('setting.addons.addon')}</th>
                                            <th>{t('common.sort_order')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody id="sortable-table">
                                        {
                                            _.map(addons, (type, i) =>
                                                <tr key={i} id={type.id}>
                                                    <td><i className="fa fa-arrows-v" aria-hidden="true"></i></td>
                                                    <td><Button variant="alink" onClick={() => editAddon(type)}>{type.addon}</Button></td>
                                                    <td>{type.sort}</td>
                                                    <td><DeleteButton onDelete={() => deleteAddon(type)}/></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Loader>
                        </div>
                    </Row>
                </div>
            </div>
            <AddOrEditAddonModal show={showAddAddonModal} onClose={onModalClose} itemToEdit={addonToEdit} accounts={accounts}/>
        </BaseContainer>
    );
}

export default AddonList;
