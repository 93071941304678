import Papa from 'papaparse';
import { useContext, useEffect, useState } from "react";
import { Button, Row, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BaseForm from "../../components/BaseForm";
import BaseContainer from "../../components/Container";
import Loader from "../../components/Loader";
import TabHeader from "../../components/TabHeader";
import { BaseContext } from '../../helpers/common';
import { serverPost, serverFetch } from "../../helpers/server";
import { getTabItems } from "../../helpers/tabs";
import { CSVLink } from 'react-csv';
import { parseTime } from '../../helpers/common';

function BatchImportVenues() {
    const { getApiUrl, facilityLink, isLoggedIn} = useContext(BaseContext);
    const { t } = useTranslation('common');

    const [venues, setVenues] = useState({});
    const [venueNames, setVenueNames] = useState(new Set()); 
    const [rawCsvData, setRawCsvData] = useState([]);
    const [formattedData, setFormattedData] = useState([]);
    const [dataErrors, setDataErrors] = useState([]);
    const [improperFileError, setImproperFileError] = useState(false);
    const [importError, setImportError] = useState(false);
    const [formattingError, setFormattingError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const updateErrors = (res) => {
        if (res["error"]) {
            setImportError(true)
        }

        res.forEach((row, rowIndex) => {
            if (row.errorMessage) {
                setImportError(true);
                Object.entries(row.errorMessage).forEach(([key, value]) => {
                    const newDataErrors = [...dataErrors];
                    newDataErrors[rowIndex][key] = value;
                    setDataErrors(newDataErrors);
                });
            }
        })
    }

    const batchImportVenues = (data) => {
        setUploading(true);
        serverPost(getApiUrl('/venues/batch_create'), data).then((res) => {
            setUploading(false);
            if (res) {
                updateErrors(res);
                setUploaded(true);
            } else {
                setImportError(true);
            }
        })
    }

    const csvTemplate = [[
        "Venue Name", 
        "Category Name", 
        "Category Link", 
        "Event Types", 
        "Day Start (HH:MM:SS)",
        "Day End (HH:MM:SS)",
        "Hide Public Calendar",
        "Hourly Rate",
        "Slot Minutes"
    ]]

    const tableHeaders = [
        "Venue Name",
        "Category Name",
        "Category Link",
        "Event Types",
        "Day Start (HH:MM:SS)",
        "Day End (HH:MM:SS)",
        "Hourly Rate",
        "Hide Public Calendar",
        "Slot Minutes"
    ];
    
    const formatData = (csvData) => {
        const csvColumns = csvData[0];
        const filteredCsvColumns = csvColumns.filter(column => column.trim() !== '');
        
        // There are not 9 columns or there is not any data (header column takes up one row so there needs to be at least 2 rows to have data)
        if (filteredCsvColumns.length !== 9 || csvData.length < 2) {
            setImproperFileError(true);
            return
        }

        const formattedData = {};
        formattedData["venues"] = []
        
        const dataErrors = []

        csvData.forEach((row, rowIndex) => {

            // this is the headers row 
            if (rowIndex === 0) {
                return
            }

            const formattedRow = {};
            const dataErrorsRow = {};
            
            row.forEach((cell, colIndex) => {
                // Venue name is required
                if (colIndex === 0) {
                    if (cell.trim() === '') {
                        dataErrorsRow["name"] = "Venue name is empty"
                        formattedRow["name"] = null;
                        setFormattingError(true);
                    } else if (venueNames.has(cell)) {
                        dataErrorsRow["name"] = "Venue name is already used"
                        formattedRow["name"] = cell; 
                        setFormattingError(true);
                    }
                    else {
                        dataErrorsRow["name"] = null;
                        formattedRow["name"] = cell;
                    }
                }
                
                // Category name is not required. The API expects null for blank fields so set it to null if blank
                if (colIndex === 1) {
                    dataErrorsRow["categoryName"] = null
                    if(cell.trim() === '') {
                        formattedRow["categoryName"] = null
                    } else {
                        formattedRow["categoryName"] = cell;
                    }
                } 

                // Category link is not required. The API expects null for blank fields so set it to null if blank
                if (colIndex === 2) {
                    dataErrorsRow["categoryLink"] = null
                    if (colIndex === 2 && cell.trim() === '') {
                        formattedRow["categoryLink"] = null
                    } else {
                        dataErrorsRow["categoryLink"] = null
                        formattedRow["categoryLink"] = cell;
                    }

                    // Check if only one of category name/link are provided
                    if (formattedRow["categoryLink"] && !formattedRow["categoryName"]) {
                        dataErrorsRow["categoryName"] = "Both category name and link are required if provided"
                    }
                    if (formattedRow["categoryName"] && !formattedRow["categoryLink"]) {
                        dataErrorsRow["categoryLink"] = "Both category name and link are required if provided"
                    }
                }
                
                if (colIndex === 3) {
                    // The eventTypes are uploaded as a string of comma-seperated values but we need to convert this to a list
                    const eventTypes = cell.split(',').map(type => type.trim());

                    if (eventTypes.length === 1 && eventTypes[0] === '') {
                        dataErrorsRow["eventTypes"] = null
                        formattedRow["eventTypes"] = null
                    } else if (!eventTypes.every(type => type !== '')) {
                        dataErrorsRow["eventTypes"] = "Event Types must be a comma-separated list of non-empty strings"
                        formattedRow["eventTypes"] = [cell]
                        setFormattingError(true);
                    } else {
                        formattedRow["eventTypes"] = eventTypes
                    }
                }
        
                if (colIndex === 4) {       
                    const parsedTime = parseTime(cell)
                    if (parsedTime) {
                        formattedRow["dayStart"] = parsedTime
                        dataErrorsRow["dayStart"] = null
                    } else {
                        formattedRow["dayStart"] = cell;
                        dataErrorsRow["dayStart"] = "Day Start must be in the format HH:MM:SS"
                        setFormattingError(true);
                    }
                }
                
                if (colIndex === 5) {       
                    const parsedTime = parseTime(cell)
                    if (parsedTime) {
                        formattedRow["dayEnd"] = parsedTime
                        dataErrorsRow["dayEnd"] = null
                    } else {
                        formattedRow["dayEnd"] = cell;
                        dataErrorsRow["dayEnd"] = "Day End must be in the format HH:MM:SS"
                        setFormattingError(true);
                    }

                    if (!dataErrorsRow["dayStart"] && !dataErrorsRow["dayEnd"]) {
                        if (formattedRow["dayStart"] > formattedRow["dayEnd"] && formattedRow["dayEnd"] !== '00:00:00') {
                            dataErrorsRow["dayStart"] = "Day Start is after day end"
                            setFormattingError(true);
                        }
                    }
                }

                if (colIndex === 6) {
                    // Validate Hourly Rate (Float)
                    const hourlyRate = parseFloat(cell);
                    if (isNaN(hourlyRate)) {
                        dataErrorsRow["defaultRate"] = "Hourly Rate must be in decimal";
                        formattedRow["defaultRate"] = cell;
                        setFormattingError(true);
                    } else if (hourlyRate && hourlyRate < 0) {
                        dataErrorsRow["defaultRate"] = "Hourly Rate can't be negative";
                        formattedRow["defaultRate"] = cell
                        setFormattingError(true);
                    } else {
                        formattedRow["defaultRate"] = hourlyRate;
                    }
                }
                
                
                if (colIndex === 7) {
                    // Format Hide Public Calendar (Boolean)
                    if (cell !== 'Yes' && cell !== 'No') {
                        dataErrorsRow["hideCalendar"] = "Hide Public Calendar must be Yes or No"
                        formattedRow["hideCalendar"] = cell;
                        setFormattingError(true);
                    } else {
                        formattedRow["hideCalendar"] = cell === 'Yes';
                    }
                }
                

                if (colIndex === 8) {
                    // Validate Slot Minutes (integer)
                    const slotMinutes = parseInt(cell);
                    if (isNaN(slotMinutes)) {
                        dataErrorsRow["slotMinutes"] = "Slot Minutes must be in integer";
                        formattedRow["slotMinutes"] = cell
                        setFormattingError(true);
                    } else if (slotMinutes && slotMinutes < 0) {
                        dataErrorsRow["slotMinutes"] = "Slot Minutes can't be negative";
                        formattedRow["slotMinutes"] = cell
                        setFormattingError(true);
                    } else {
                        formattedRow["slotMinutes"] = slotMinutes;
                    }
                }
            })
            
            formattedData["venues"].push(formattedRow);
            dataErrors.push(dataErrorsRow)
        });
        setFormattedData(formattedData);
        setDataErrors(dataErrors);
    }

    const onFieldChange = (name, file) => {
        if (name === "csv" && file != null) {
            setImproperFileError(false);
            setUploading(false);
            setUploaded(false);
            setUploadSuccess(false);
            setFormattingError(false);
            setImportError(false);
            setRawCsvData([]);
            setFormattedData([]);
            setDataErrors([]);
            
            Papa.parse(file, {
                skipEmptyLines: true,
                complete: (results) => {
                    setRawCsvData(results.data);
                }
            })

        }
    }

    useEffect(() => {
        serverFetch(getApiUrl('/venues')).then((res) => {
            const venueNamesSet = new Set(res.map(venue => venue.name));
            setVenueNames(venueNamesSet);
        })
    }, [isLoggedIn]);

    useEffect(() => {
        if (rawCsvData.length > 0) {
            setLoading(true);
            formatData(rawCsvData);
            setLoading(false);
        }
    }, [rawCsvData])

    useEffect(() => {    
        if (importError === false && uploaded === true) {
            setUploadSuccess(true);
        }
    }, [importError, uploaded]);

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "batch-import-venues")} />
            <BaseForm initialFormFields={venues} onFieldChange={onFieldChange} onSubmit={batchImportVenues}>
                <div className="content-box">
                    <div className="content-header">
                        <span className="content-title">{t('venue_settings.csv_upload')}</span>
                    </div>

                    <div className="content-body">
                        <span>{t("venue_settings.csv_template")}</span>
                        <div style={{marginTop: '10px', marginBottom: '10px'}}>
                            <CSVLink data={csvTemplate} filename={"CSV-Batch-Import-Venues-Template.csv"} target="_blank">
                                <a style={{textDecoration: 'underline'}}>{t("venue_settings.download_template")}</a>
                            </CSVLink>
                        </div>
                        
                        <ul>
                            <li>{t("forms.venue_name")}</li>
                            <li>{t("venue_settings.category_name")}</li>
                            <li>{t("venue_settings.category_link")}</li>
                            <li>{t("venue_settings.event_types_description")}</li>
                            <li>{t("venue_settings.day_start")}</li>
                            <li>{t("venue_settings.day_end")}</li>
                            <li>{t("venue_settings.hourly_rate")}</li>
                            <li>{t("venue_settings.hide_public_calendar")}</li>
                            <li>{t("venue_settings.slot_minutes")}</li>
                        </ul>

                        <Row>
                            <BaseForm.Input colSpan="6" type="file" name="csv" label="Import CSV" accept=".csv" fileType="invoice_logo" />
                        </Row>

                        <br/>
                        
                        <div>    
                            <Loader loading={uploading}>
                                <div>
                                    <Button disabled={formattedData.length === 0 || uploaded || formattingError || improperFileError} onClick={() => batchImportVenues(formattedData)}>Upload</Button>
                                    {(improperFileError || formattingError) && (
                                        <span style={{color: 'red', marginLeft: '15px'}}>{t("venue_settings.fix_errors_before_uploading")}</span>
                                    )}
                                </div>
                            </Loader>

                            {uploadSuccess && (
                                <div>
                                    <br/>
                                    <span style={{color: 'green'}}>{t("venue_settings.upload_successful")}</span>
                                </div>
                            )}  

                            {importError && (
                                <div>
                                    <br/>
                                    <span style={{color: 'red'}}>{t("venue_settings.upload_failed")}</span>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            
                <div className="content-box">
                    <div className="content-header">
                        <span className="content-title">CSV Data</span>
                    </div>
                    <div className="content-body">
                        <Loader loading={loading}>
                            { (!improperFileError && formattedData.length === 0) && (
                                <span>{t('venue_settings.csv_loaded_here')}</span>
                            )}
                            { improperFileError && (
                                <span>{t('venue_settings.file_incorrect_format')}</span>
                            )}
                            { (!improperFileError && formattedData.venues && formattedData.venues.length > 0) && (
                                    <Table>
                                        <thead>
                                            <tr>
                                                {tableHeaders.map(header => (
                                                    <th key={header}>{header}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formattedData.venues.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {Object.entries(row).map(([key, cell], colIndex) => (
                                                        <td key={colIndex}>
                                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                                {key === 'eventTypes' && (
                                                                    <Stack direction="vertical" gap={2} style={{width: '60%'}}>
                                                                        {cell && cell.map((eventType, index) => (
                                                                            <span key={index}  style={{background: '#EEEEEE', display: 'inline-block', borderRadius: '10%', textAlign: 'center'}}>{eventType}</span>
                                                                        ))}
                                                                    </Stack>
                                                                )}
                                                                {key === 'hideCalendar' && (
                                                                    <>
                                                                        <span>{(typeof(cell) === "boolean" && (cell ? 'Yes' : 'No'))}</span>
                                                                        <span>{(typeof(cell) !== "boolean" && (cell))}</span>
                                                                    </>
                                         
                                                                )}
                                                                {(key !== 'eventTypes' && key !== 'hideCalendar') && (
                                                                    <span> {(cell) && cell} </span>
                                                                )}
                                                                {(dataErrors[rowIndex][key]) && (
                                                                    <span style={{color: 'red'}}>{dataErrors[rowIndex][key]}</span>
                                                                )}
                                                            </div>
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                </Table>                                
                            )}
                        </Loader>
                    </div>
                </div>
            </BaseForm>
        </BaseContainer>
    )
}

export default BatchImportVenues