import '../App.scss';
import '../js/forms.js';
import { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import React from 'react';
import $ from 'jquery';
import classnames from 'classnames';
import { Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getUrl, serverFetch, serverPost} from '../helpers/server';
import { BaseContext } from '../helpers/common';
import { Link } from "react-router-dom";
import Loader from './Loader';
import AddOrEditBookingModal from './modals/AddOrEditBookingModal';
import moment from 'moment';
const _ = require("lodash");

function QuickView(props) {
    const { isLoggedIn, getApiUrl } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [recentBookings, setRecentBookings] = useState([]);
    const [isAdminBooking, setIsAdminBooking] = useState(false);
    const [showEditBookingModal, setShowEditBookingModal] = useState(false);
    const [bookingToEdit, setBookingToEdit] = useState(null);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (!show) {
            return;
        }
        setLoading(true);
        if (isLoggedIn) {
            serverPost(getApiUrl('/recent_bookings'), {
                "isAdminBooking": isAdminBooking,
                "pagination": {
                    "from": "0",
                    "limit": 20,
                }
            }, { skipCache: true }).then((res) => {
                setRecentBookings(res);
                setLoading(false)
            })
        }
    }, [facilityLink, isAdminBooking, show]);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    const showUserBookings = (event) => {
        event.preventDefault();
        if (loading) {
            return;
        }
        setIsAdminBooking(false);
    }

    const showAdminBookings = (event) => {
        event.preventDefault();
        if (loading) {
            return;
        }
        setIsAdminBooking(true);
    }

    const editBooking = (booking) => {
        props.onEditBooking(booking);
    }

    const cancelBooking = (booking) => {
        props.onCancelBooking(booking);
    }

    const getVariantForBooking = (booking) => {
        if (booking.paymentMethod === "request") {
            return "danger";
        } else {
            return "primary";
        }
    }

    const groupIdBaseUrl = "/" + facilityLink + "/booking/list?groupId="
    const lidBaseUrl = "/" + facilityLink + "/index?lid="

    return (
        <div id="quickview" className="quickview noprint">
            <div className="nav-wrapper alt">
                <nav className="nav">
                  <a className={classnames("nav-link", isAdminBooking ? "" : "active")} id="user-bookings" href="#" onClick={(event) => showUserBookings(event)}>{t('tabs.quickview.user_bookings')}</a>
                  <a className={classnames("nav-link", isAdminBooking ? "active" : "")} id="admin-bookings" href="#" onClick={(event) => showAdminBookings(event)}>{t('tabs.quickview.admin_bookings')}</a>
                  <Button variant="link" className="nav-link skinny" onClick={props.onHide}><i className="fa fa-x" /></Button>
                </nav>
            </div>
            <div className="quickview-body">
                <Loader loading={loading}>
                    <Table hover>
                        <tbody>
                        {
                            _.map(recentBookings, (booking, i) =>
                                <tr key={i}>
                                    <td>
                                        <div className="details"><Link to={groupIdBaseUrl + booking.groupId}>{booking.groupName + " (" + booking.venueName + ")"}</Link></div>
                                        <div className="details">
                                            <a className="plain subtext" href={lidBaseUrl + booking.id}>
                                                {moment(booking.startTimeLocal).format("ddd, MMM D, YYYY") + " (" + moment(booking.startTimeLocal).format("h:mma") + " - " + moment(booking.endTimeLocal).format("h:mma") + ")"}
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex flex-row gap-1 justify-content-end" style={{ whiteSpace: "nowrap" }}>
                                            <Button variant={getVariantForBooking(booking)} onClick={() => editBooking(booking)}><i className="fa fa-edit"></i></Button>
                                            {
                                                booking.paymentMethod === "request" &&
                                                    <Button variant="outline-primary" onClick={() => cancelBooking(booking)}><i className="fa fa-remove"/></Button>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </Loader>
            </div>
        </div>
    );
}

export default QuickView;
