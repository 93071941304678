import '../../App.scss';
import '../../css/modals.scss';
import { useEffect, useState, useRef, useContext } from 'react';
import {serverPatch, serverPost} from '../../helpers/server';
import {BaseContext} from '../../helpers/common';
import BaseForm from '../BaseForm';
import {Row, Col, Container, Alert} from 'react-bootstrap';
import BaseModal from "./BaseModal";
import SubmitButton from "../SubmitButton";
import Notification from "../Notification";
import {useTranslation} from "react-i18next";
const _ = require("lodash");

function EditVenueNameModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [venue, setVenue] = useState(null);
    const [initialFields, setInitialFields] = useState({});

    useEffect(() => {
        setVenue(props.venue);
        if (props.venue) {
            setInitialFields({
                name: props.venue.name,
                type: props.venue.type,
                ownership: props.venue.ownership
            })
        } else {
            setInitialFields({})
        }
    }, [props.venue])

    const onSubmit = (data) => {
        console.log("Updating " + JSON.stringify(data));
        serverPatch(getApiUrl(`/venues/${venue.id}`), data).then((res) => {
            Notification.Show(t('edit_venue.successfully_updated'));
            props.onClose();
        })
    }

    if (!props.show || !venue) {
        return null;
    }

    const venueOptions = [
        { value: "rink", label: "Ice Rink" },
        { value: "soccer", label: "Soccer" },
        { value: "basketball", label: "Basketball" },
        { value: "volleyball", label: "Volleyball" },
        { value: "mixed", label: "Mixed sports" },
        { value: "room", label: "Conference/Party Room" },
        { value: "other", label: "Other" },
    ]

    const ownershipOptions = [
        { value: "owned", label: "Owned" },
        { value: "rental", label: "Rented" },
    ]

    return (
        <BaseModal {...props}>
            <BaseForm initialFormFields={initialFields} onSubmit={onSubmit}>
                <BaseModal.Header>
                    <BaseModal.Title>{t('edit_venue.title')} - #{venue.id}</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <BaseForm.Input colSpan="12" name="name" label={t('forms.venue_name')} type="text" />
                    <Alert variant="info">{t('edit_venue.warning')}</Alert>

                </BaseModal.Body>
                <BaseModal.Footer>
                    <Container>
                        <div className="d-flex flex-row justify-content-end">
                            <SubmitButton>{t('common.update')}</SubmitButton>
                        </div>
                    </Container>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );
}
/*
<BaseForm.Input colSpan="12" name="type" label={t('forms.venue_type')} type="select" options={venueOptions} showSearch={false} />
<BaseForm.Input colSpan="12" name="ownership" label={t('forms.ownership_type')} type="select" options={ownershipOptions} showSearch={false} />
 */

export default EditVenueNameModal;
