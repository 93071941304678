import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import {serverFetch, serverPatch, notifyEvent, getUrl} from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Alert, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Setting() {
    const { userInfo, getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState({});
    const { t } = useTranslation('common');

    useEffect(() => {
        document.title = "General Settings - " + getFacilityName();
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl('')).then((res) => {
            setSettings(res);
            setLoading(false);
        })
    }, []);

    const updateSettings = async (settingFields) => {
        if (settings.facilityName !== settingFields.facilityName) {
            const url = getUrl(`/api/v1/facilities/${settings.link}`, false);
            serverPatch(url, { facilityName: settingFields.facilityName }).then((res) => {

            });
        }
        serverPatch(getApiUrl('/settings'), settingFields).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
                notifyEvent("settings");
            }
        })
    }

    const countryOptions = [
        { 'value': 1, 'label': t("setting.setting.canada") },
        { 'value': 2, 'label': t("setting.setting.us") },
        { 'value': 3, 'label': t("setting.setting.new_zealand") },
        { 'value': 4, 'label': t("setting.setting.australia") },
    ];

    const timezoneOptions = [
        { 'value': 1, 'label': t("setting.setting.nfl_st") },
        { 'value': 2, 'label': t("setting.setting.atl_st") },
        { 'value': 3, 'label': t("setting.setting.east_st") },
        { 'value': 4, 'label': t("setting.setting.cen_st") },
        { 'value': 5, 'label': t("setting.setting.mtn_st") },
        { 'value': 6, 'label': t("setting.setting.pac_st") },
        { 'value': 7, 'label': t("setting.setting.nzt") },
        { 'value': 8, 'label': t("setting.setting.aus_wt") },
    ];

    const weekStartOptions = [
        { 'value': 0, 'label': t("days.sunday") },
        { 'value': 1, 'label': t("days.monday") },
        { 'value': 2, 'label': t("days.tuesday") },
        { 'value': 3, 'label': t("days.wednesday") },
        { 'value': 4, 'label': t("days.thursday") },
        { 'value': 5, 'label': t("days.friday") },
        { 'value': 6, 'label': t("days.saturday") },
    ];

    const dateFormatOptions = [
        { 'value': 1, 'label': 'Sat, Feb 06, 2016' },
        { 'value': 2, 'label': 'Sat, 06 Feb, 2016' },
    ];

    const customDefaultViewOptions = [
        { 'value': 0, 'label': 'Single & Dynamic (Default)' },
        { 'value': 1, 'label': 'MultiView User Only' },
        { 'value': 2, 'label': 'MultiView Admin & User' },
    ];

    const showWeekSkipOptions = [
        { 'value': 0, 'label': 'Day Only (Default)' },
        { 'value': 1, 'label': 'Day/Week' },
        { 'value': 2, 'label': 'Day/Week/Month' },
    ];

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "setting")}/>
            <Loader loading={loading}>
                <BaseForm onSubmit={updateSettings} initialFormFields={settings}>
                    <div className="content-box">
                        <div className="content-header">
                            <span className="content-title">{t('setting.facility_details.title')}</span>
                        </div>
                        <div className="content-body">
                            <Row>
                                <BaseForm.Input colSpan="12" type="text" name="facilityName" label={t('forms.company_name')} required />
                            </Row>
                        </div>
                    </div>
                    <div className="content-box">
                        <div className="content-header">
                            <span className="content-title">{t('setting.company_details.title')}</span>
                        </div>
                        <div className="content-body">
                            <Row>
                                <BaseForm.Input colSpan="12" type="text" name="companyName" label={t('forms.company_name')} required />
                                <BaseForm.Input colSpan="6" type="text" name="address1" label={t('forms.street_address')} />
                                <BaseForm.Input colSpan="6" type="text" name="address2" label={t('forms.street_address2')} />
                                <BaseForm.Input colSpan="6" type="text" name="city" label={t('forms.city')} />
                                <BaseForm.Input colSpan="6" type="text" name="state" label={t('forms.state')} />
                                <BaseForm.Input colSpan="6" type="text" name="zipCode" label={t('forms.zip_code')} />
                                <BaseForm.Input colSpan="6" type="select" name="country" label={t('forms.country')} options={countryOptions} />
                                <BaseForm.Input colSpan="6" type="text" name="primaryEmail" label={t('forms.primary_email')} required />
                                <BaseForm.Input colSpan="6" type="text" name="phone" label={t('forms.phone')} />
                                <BaseForm.Input colSpan="6" type="text" name="website" label={t('forms.website')} />
                                <BaseForm.Input colSpan="6" type="text" name="mapLink" label={t('forms.map_link')} />
                                <BaseForm.Input colSpan="6" type="select" name="timezone" label={t('forms.timezone')} options={timezoneOptions} />
                                <Col md="6">
                                    <BaseForm.InputGroup className="form-group">
                                        <BaseForm.Text name="businessNumber" label={t('forms.business_number')} />
                                        <BaseForm.Check id="showBusinessNumber" name="showBusinessNumber" />
                                    </BaseForm.InputGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="content-box">
                        <div className="content-header">
                            <span className="content-title">{t('setting.display_settings.title')}</span>
                        </div>
                        <div className="content-body">
                            <Row>
                                <BaseForm.Input colSpan="6" type="text" name="headerName" label={t('forms.header_name')} />
                                <BaseForm.Input colSpan="6" type="file" name="headerLogo" label={t('forms.header_logo')} fileType="header_logo" />
                            </Row>
                            <Row>
                                <BaseForm.Input colSpan="12" type="editor" label={t("setting.setting.cus_instr") + " (" + t("setting.setting.how_to_book") + ")"} name="userInstruction" />
                            </Row>
                        </div>
                    </div>
                    <div className="content-box">
                        <div className="content-header">
                            <span className="content-title">{t('setting.waiver_settings.title')}</span>
                        </div>
                        <div className="content-body">
                            <Row>
                                <BaseForm.Input colSpan="6" type="text" name="waiverName" label={t('forms.waiver_name')} />
                                <BaseForm.Input colSpan="6" type="text" name="waiverLink" label={t('forms.waiver_link')} />
                            </Row>
                            <Alert variant="info">
                                <p dangerouslySetInnerHTML={{__html: t('setting.waiver_settings.how_to')}} />
                            </Alert>
                        </div>
                    </div>
                    <div className="content-box">
                        <div className="content-header">
                            <span className="content-title">{t('setting.display_options.title')}</span>
                        </div>
                        <div className="content-body">
                            <Row>
                                <BaseForm.Input colSpan="6" type="select" name="weekStart" label={t('forms.week_start')} options={weekStartOptions} showSearch={false} />
                                <BaseForm.Input colSpan="6" type="select" name="dateFormat" label={t('forms.date_format')} options={dateFormatOptions} showSearch={false} />
                                <BaseForm.Input colSpan="6" type="check" name="useMultilingual" label={t('forms.use_multilingual')} />
                                <BaseForm.Input colSpan="6" type="check" name="useMultigroupList" label={t('forms.use_multigroup_list')} />
                                <BaseForm.Input colSpan="6" type="check" name="showSearch" label={t('forms.show_search')} />
                                <BaseForm.Input colSpan="6" type="check" name="isPublicColor" label={t('forms.is_public_color')} />
                                <BaseForm.Input colSpan="6" type="check" name="allowOnlyOneBooking" label={t('forms.allow_only_one_booking')} />
                                <BaseForm.Input colSpan="6" type="check" name="disableSignup" label={t('forms.disable_signup')} />
                                <BaseForm.Input colSpan="6" type="check" name="topCount" label={t('forms.top_count')} />
                                <BaseForm.Input colSpan="6" type="check" name="overlapDefaultNo" label={t('forms.overlap_default_no')} />
                                <BaseForm.Input colSpan="6" type="check" name="enableCredit" label={t('forms.enable_credit')} />
                                <BaseForm.Input colSpan="6" type="select" name="customDefaultView" label={t('forms.custom_default_view')} options={customDefaultViewOptions} showSearch={false} />
                                <BaseForm.Input colSpan="6" type="select" name="showWeekSkip" label={t('forms.show_week_skip')} options={showWeekSkipOptions} showSearch={false} />
                                <BaseForm.Input colSpan="6" type="text" name="venueLabel" label={t('forms.venue_label')} />
                            </Row>
                        </div>
                    </div>
                    {
                        userInfo.isSuperAdmin &&
                            <div className="content-box">
                                <div className="content-header">
                                    <span className="content-title">{t("setting.setting.class_reg") + " (" + t("setting.setting.super_adm") + ")"}</span>
                                </div>
                                <div className="content-body">
                                    <Row>
                                        <BaseForm.Input colSpan="6" type="check" name="allowClasses" label={t("setting.setting.turn_on_class_reg") + " (" + t("setting.setting.beta") + ")"} />
                                    </Row>
                                </div>
                            </div>
                    }
                    <div className="content-box">
                        <div className="content-body">
                            <SubmitButton>{t('common.save')}</SubmitButton>
                        </div>
                    </div>
                </BaseForm>
            </Loader>
        </BaseContainer>
    );
}

export default Setting;
