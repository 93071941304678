import '../../App.scss';
import '../../css/modals.scss';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState, useRef, useContext} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import Notification from '../Notification';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import Select from 'react-select/async'
import {BaseContext} from '../../helpers/common';
import { serverPatch } from '../../helpers/server';
import {Row, Col, Container, Button} from 'react-bootstrap';

const _ = require('lodash');

function BlockUserGroupModal(props) {
    const {getApiUrl} = useContext(BaseContext);
    const {t} = useTranslation('common');
    const navigate = useNavigate();
    const [group, setGroup] = useState([]);

    useEffect(() => {
        if (props.group) {
            setGroup(props.group)
        }
    }, [props.group]);

    const blockGroup = (data) => {
        serverPatch(getApiUrl(`/user_groups/${group.id}`), { blocked: true }).then((res) => {
            if (res) {
                Notification.ModalShow("User group successfully blocked");
                if (props.onClose) {
                    props.onClose(true);
                }
            }
        })
    }

    return (
        <>
            <BaseModal {...props}>
                <BaseModal.Header>
                    <BaseModal.Title>Block User Group</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <p>Are you sure you want to block the group?</p>
                    <p className="form-error-message caption italic">They won't be able to make any new bookings. However, you can still make booking for this group from the admin side. Payments will still be processed.</p>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Container>
                        <div className="d-flex flex-row justify-content-end">
                            <Button variant="danger" onClick={blockGroup}>Block</Button>
                        </div>
                    </Container>
                </BaseModal.Footer>
            </BaseModal>
        </>
    );
}

export default BlockUserGroupModal;
