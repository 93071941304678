import React, { useState, useEffect, useContext } from 'react';
import '../../App.scss';
import { useParams } from "react-router-dom";
import { serverFetch } from '../../helpers/server';
import { getCompanyInfo } from '../../helpers/storage';
import { getFormattedImageLink, BaseContext } from '../../helpers/common';
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";

function ClassesHeader(props) {
    const { getApiUrl } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const [settings, setSettings] = useState(getCompanyInfo());

    useEffect(() => {
        serverFetch(getApiUrl('')).then((res) => {
            setSettings(res);
        })
    }, [facilityLink]);

    return (
      <Container>
        <div className="header-item text-start">
          <div className="header-item-inner header-logo">
            <Link to={`/${facilityLink}`}><img alt="Company Logo" src={getFormattedImageLink(settings.logo, "https://rectimes.app/uploads/logoinv/")}/><span className="header-name">{settings.headerName}</span></Link>
          </div>
        </div>
      </Container>
    );

}

export default ClassesHeader;
