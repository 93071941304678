import '../../App.scss';
import '../../css/modals.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Notification from '../Notification';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { serverPost } from '../../helpers/server';
import { getFormattedName, BaseContext } from '../../helpers/common';
import { Button, Row, Col, Alert, Table } from 'react-bootstrap';
const _ = require('lodash');

function MergeCustomersModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [initialFormFields, setInitialFormFields] = useState(null);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        const items = [...props.customers];
        _.each(items, (item) => {
            item.fullName = getFormattedName(item);
        })
        setCustomers(items);
        if (items.length > 0) {
            setInitialFormFields({selectedCustomerId: items[0].id});
        } else {
            setInitialFormFields(null);
        }
    }, [props.customers]);

    const mergeCustomers = (data) => {
        const mergeData = {
            destinationGroupId: data.selectedCustomerId,
            sourceGroupIds: _.map(_.filter(customers, (c) => String(c.id) !== String(data.selectedCustomerId)), (customer) => customer.id),
        }
        serverPost(getApiUrl('/user_groups/merge'), mergeData).then((res) => {
            if (res) {
                Notification.Show(t('customers.list.merge.successfully_merged'));
            }
            props.onClose();
        });
    }

    if (!customers) {
        return null;
    }
    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>{t('customers.list.merge.title')}</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm initialFormFields={initialFormFields} onSubmit={mergeCustomers}>
                <Alert variant="warning">
                    <div dangerouslySetInnerHTML={{__html: t('customers.list.merge.description')}}/>
                </Alert>
                <BaseModal.Body>
                    <Table hover borderless>
                        <tbody>
                        {
                            _.map(customers, (customer, i) =>
                                <tr key={i}>
                                    <td>
                                        <BaseForm.CheckboxRadio name="selectedCustomerId" value={String(customer.id)} hideLabel={true}
                                            label={`${customer.groupName} : ${customer.fullName} : ${customer.phone} : ${customer.email}`}/>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <Button variant="link" onClick={() => props.onClose()}>{t('common.close')}</Button>
                            <SubmitButton variant="primary">{t('customers.list.merge')}</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default MergeCustomersModal;
