import '../../App.scss';
import '../../css/modals.scss';
import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import { Button, Row, Col } from 'react-bootstrap';
import { serverPatch, serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import {useTranslation} from "react-i18next";
const _ = require("lodash");

function EditEmailModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [itemToEdit, setItemToEdit] = useState({});

    useEffect(() => {
        setItemToEdit(props.itemToEdit);
    }, [props.itemToEdit]);

    const updateEmail = (data) => {
        serverPatch(getApiUrl(`/emails/${props.itemToEdit.id}`), data).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
                props.onClose();
            }
        });
    }

    const restoreToDefault = (event) => {
        serverPost(getApiUrl(`/emails/${props.itemToEdit.id}/restore`)).then((res) => {
            if (res) {
                setItemToEdit(res);
                Notification.ModalShow("Successfully restored");
            }
        });
    }

    return (
        <BaseModal size="lg" {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Edit Email - {itemToEdit && itemToEdit.title}</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm initialFormFields={props.itemToEdit} onSubmit={updateEmail}>
                <BaseModal.Body>
                    <Row>
                        <BaseForm.Input colSpan="4" name="title" label="Title" type="text" required />
                        <BaseForm.Input colSpan="4" name="subject" label="Subject" type="text" required />
                        <BaseForm.Input colSpan="4" name="tag" label="Tag" type="text" required />
                        <BaseForm.Input colSpan="12" name="emailDetails" type="editor" required
                            tokens={[
                                //Booking Email default List
                                '{customerName}',
                                '{businessName}',
                                '{businessAddress}',
                                '{businessPhone}',
                                '{businessWebsite}',
                                '{city}',
                                '{streetAddress2}',
                                '{province}',
                                '{postalCode}',
                                '{primaryEmail}',
                                '{waiverPdf}',
                                '{waiverLink}',
                                //Extra Email Tokens for the above tags
                                '{groupName}',
                                '{bookingDate}',
                                '{oldBookingDate}',
                                '{bookingTime}',
                                '{oldBookingTime}',
                                '{bookingReference}',
                                '{emailId}',
                                '{venue}',
                                '{oldVenue}',
                                '{cancelReason}',
                                '{rate}',
                                '{taxPercent}',
                                '{taxType}',
                                '{taxLabel}',
                                '{bookingPreCost}',

                                '{bookintTaxAmount}',
                                '{bookingCost}',
                                '{totalPreCost}',
                                '{totalTaxAmount}',
                                '{totalCost}',
                                '{customerFirstName}',
                                '{customerLastName}',
                                '{phone}',
                                '{repeatInfo}',
                                '{adminName}',
                                '{groupName2}',
                                '{groupName3}',
                                '{groupName4}',

                                '{CPC}',
                                '{multiBookVenues}',
                                '{category}',
                                '{eventName}',
                                '{eventType}',
                                '{description}',
                                '{customField1}',
                                '{customField2}',
                                '{customField3}',
                                '{customField4}',
                                '{notes}',
                                '{paymentLink}',
                                '{updateReason}',
                                '{booking_payment_received}',
                                '{paymentLink}',
                                '{contactMessage}',
                                // Category
                                '{category}',
                                // Agreement
                                '<b>Agreement Emails Only &darr;</b>',
                                '{link}',
                                '{comment}',
                                '{agreementNumber}',
                                '{acceptDate}',
                                '{amountPaid}',
                                '{orderId}',
                                // Invoice
                                '<b>Invoice Emails Only &darr;</b>',
                                '{link}',
                                '{comment}',
                                '{invoiceNumber}',
                                '{paidDate}',
                                '{paidAmount}',
                                '{outStandingAmount}',
                                '{paymentType}',
                                 // Account Details
                                '<b>Account Details Only &darr;</b>',
                                '{passwordLink}',
                                '{message}',
                                // if(tag=='search_availability'){
                                '<b>Availability List Only &darr;</b>',
                                '{AvailabilityList}',
                                '{country}',
                                '{mapLink}',
                                '{paymentType}',
                                '{facilityName}',
                            ]}/>
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <Button variant="outline-primary" onClick={restoreToDefault}>Restore To Default</Button>
                            <SubmitButton>Save</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );
}

export default EditEmailModal;
