import '../../App.scss';
import '../../css/modals.scss';
import {useContext, useEffect, useState, createRef} from 'react';
import { useTranslation } from 'react-i18next';
import BaseModal from './BaseModal';
import Notification from '../Notification';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import Loader from '../Loader';
import {serverFetch, serverPost} from '../../helpers/server';
import { BaseContext, currencyFormat, getDateFormatForFacility } from '../../helpers/common';
import {Button, ButtonGroup, Col, Row, Table} from 'react-bootstrap';
import moment from "moment/moment";
import BookingPaymentInput from "../BookingPaymentInput";
const _ = require('lodash');

function RemoveParticipantModal(props) {
    const { getApiUrl, isLoggedIn, settings } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [registration, setRegistration] = useState({});
    const [participant, setParticipant] = useState(null);
    const [initialPaymentFormFields, setInitialPaymentFormFields] = useState({});
    const { t } = useTranslation('common');

    useEffect(() => {
        setParticipant(props.participant);
    }, [props.participant])

    useEffect(() => {
        if (props.show && participant) {
            fetchRegistration();
        }
    }, [props.show, participant]);

    useEffect(() => {
        setInitialPaymentFormFields({
        });
    }, [props.classDetails, props.session, props.participant])

    const fetchRegistration = (skipCache=false) => {
        serverFetch(getApiUrl(`/classes/${participant.classId}/registrations/${participant.registrationId}`), { skipCache }).then((res) => {
            if (res) {
                setRegistration(res);
            }
            setLoading(false);
        });
    }

    const removeParticipant = async (data) => {
        const removeData = {
            type: data.type,
            bookingId: participant.sessionId
        }
        if (registration.participants.length <= 1) {
            removeData["type"] = "this";
        }
        serverPost(getApiUrl(`/classes/${registration.classId}/registrations/${registration.id}/remove`), removeData).then((res) => {
            if (props.onClose) {
                props.onClose(true);
            }
        })
    }

    const typeOptions = [
        { value: "thisAndFuture", label: "This and future sessions" },
        { value: "this", label: "This session" },
        { value: "all", label: "All sessions" },
    ]

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Remove Participant?</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm initialFormFields={initialPaymentFormFields} onSubmit={removeParticipant}>
            <BaseModal.Body>
                <Loader loading={loading}>
                {
                    () =>
                        <>
                        <p>Are you sure you want to remove the participant from the session(s)?</p>
                        <br/>
                        {
                            registration.participants.length > 1 &&
                                <Row>
                                    <BaseForm.Input colSpan="6" type="select" name="type" label="Sessions" options={typeOptions} required showSearch={false} />
                                </Row>
                        }
                        </>
                }
                </Loader>
            </BaseModal.Body>
            <BaseModal.Footer>
                <Row>
                    <Col md="12" className="text-end">
                        <SubmitButton variant="danger">Remove</SubmitButton>
                    </Col>
                </Row>
            </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default RemoveParticipantModal;
