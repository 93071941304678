import BaseForm from "../BaseForm";
import BaseModal from "./BaseModal";
import { Row, Col, Button } from "react-bootstrap";
import SubmitButton from "../SubmitButton";

function EditBulkCustomerImportModal(props) {
    return (
        <BaseModal {...props} size="md">
            <BaseForm onSubmit={props.onBulkEdit} initialFormFields={{ popCustomerColor: "#6d8bbf" }}>
                <BaseModal.Header>
                    <BaseModal.Title>Edit Customer(s)</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <Col md="12">
                        <BaseForm.InputGroup>
                            <BaseForm.Select name="popCustomerType" label="Customer Type" options={props.customerTypeOptions} showAll={false} showSearch={false}/>
                            <BaseForm.Check id="updateCustomerType" name="updateCustomerType" />
                        </BaseForm.InputGroup>
                    </Col>
                    <Col md="12">
                        <BaseForm.InputGroup>
                            <BaseForm.Color id="popCustomerColor" name="popCustomerColor" label="Customer Color" />
                            <BaseForm.Check id="updateCustomerColor" name="updateCustomerColor" />
                        </BaseForm.InputGroup>
                    </Col>
                    <Col md="12">
                        <BaseForm.InputGroup leftContent="$">
                            <BaseForm.Label>Customer Hourly Rate</BaseForm.Label>
                            <BaseForm.Control type="text" name="popHourlyRate" placeholder="When empty standard rates apply"/>
                            <BaseForm.Check id="updateHourlyRate" name="updateHourlyRate" />
                        </BaseForm.InputGroup>
                    </Col>
                    <Col md="12">
                        <BaseForm.InputGroup>
                            <BaseForm.Text name="popCustomerNamePrefix" label="Customer Name Prefix" placeholder={"2024_"}/>
                            <BaseForm.Check id="updateCustomerNamePrefix" name="updateCustomerNamePrefix" />
                        </BaseForm.InputGroup>
                    </Col>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col className="text-end">
                            <Button variant="link" onClick={() => props.onClose(false)}>Cancel</Button>
                            <SubmitButton>Update Customers</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );


}

export default EditBulkCustomerImportModal;
