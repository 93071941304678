import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';

const SimpleModal = (props) => {
    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>{ props.titleIcon && <i className={"fa " + props.titleIcon}></i> } { props.title }</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <div dangerouslySetInnerHTML={ { __html: props.content } } />
            </BaseModal.Body>
        </BaseModal>
    );
}

export default SimpleModal;
