import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import { useEffect, useState, useRef, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Notification from '../../components/Notification';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import InvoicePaymentsCSVExportModal from '../../components/modals/InvoicePaymentsCSVExportModal';
import { serverFetch, serverPost } from '../../helpers/server';
import { getLimitOptions, dateRangeClassCheck, BaseContext, currencyFormat, getDateFormatForFacility } from '../../helpers/common';
import { getCompanyInfo } from '../../helpers/storage';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, InputGroup, Form, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import moment from 'moment';
import classnames from 'classnames';
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmationButton from "../../components/ConfirmationButton";
const _ = require("lodash");

function PaymentList() {
    const { isLoggedIn, getApiUrl, getFacilityName, settings } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [showCSVExportModal, setShowCSVExportModal] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [cSettings, setCSettings] = useState(getCompanyInfo());

    const [searchAllQuery, setSearchAllQuery] = useState("");
    const [query, setQuery] = useState("");
    const [venues, setVenues] = useState([]);
    const [invoiceFilter, setInvoiceFilter] = useState(null);
    const [dueDateFilter, setDueDateFilter] = useState(null);
    const [paymentMethodFilter, setPaymentMethodFilter] = useState(null);
    const [venueFilter, setVenueFilter] = useState(null);
    const [totalPaidFilter, setTotalPaidFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [sortFilter, setSortFilter] = useState("datePaidDesc");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [limit, setLimit] = useState(20);

    const [payments, setPayments] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);

    const startDateRef = useRef();
    startDateRef.current = startDate;
    const endDateRef = useRef();
    endDateRef.current = endDate;
    const queryTimer = useRef(null);

    useEffect(() => {
        document.title = "Invoice Payments - " + getFacilityName();
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl('')).then((res) => {
            setCSettings(res);
        })

        serverFetch(getApiUrl('/venues')).then((res) => {
            setVenues(res);
        })

        serverFetch(getApiUrl('/payment_methods')).then((res) => {
            let tempPaymentMethods = [{paymentTag: 'paidCreditOnline', paymentType: 'Paid Credit Card Online', paymentIcon: 'fa fa-credit-card', 'isInvOption': "true" }].concat(res);
            setPaymentMethods(_.filter(tempPaymentMethods, (r) => r.isInvOption));
        })
    }, [facilityLink, isLoggedIn]);

    useEffect(() => {
        // Load the options from cache
        const optionsString = localStorage.getItem(facilityLink + "_filter_cache_invoice_payments");
        if (optionsString) {
            const options = JSON.parse(optionsString);
            if (options.paymentDateFilter) {
                setDateFilter(options.paymentDateFilter.filterType);
                setStartDate(options.paymentDateFilter.startDate);
                setEndDate(options.paymentDateFilter.endDate);
            }
            if (options.venueFilter) {
                setVenueFilter(options.venueFilter)
            }
            if (options.paymentMethodFilter) {
                setPaymentMethodFilter(options.paymentMethodFilter)
            }
            if (options.paidFilter) {
                setTotalPaidFilter(options.paidFilter)
            }
            if (options.invoiceNumberFilter) {
                setInvoiceFilter(options.invoiceNumberFilter)
            }
            if (options.dueDateFilter) {
                setDueDateFilter(options.dueDateFilter);
            }
            if (options.sort) {
                setSortFilter(options.sort);
            }
        }
    }, []);

    useEffect(() => {
        onSearch();
    }, [dateFilter, limit, query, paymentMethodFilter, totalPaidFilter,
        dueDateFilter, invoiceFilter, venueFilter, sortFilter]);

    useEffect(() => {
        clearTimeout(queryTimer.current);
        queryTimer.current = setTimeout((callback, kk, vv) => {
                if (callback) {
                    callback(kk, vv);
                }
                queryTimer.current = null;
            },
            400,
            onSearch,
        );
    }, [searchAllQuery]);

    useEffect(() => {
        setTimeout(() => {
            $('#dateStart').datepicker( "destroy" );
            $('#dateStart').datepicker({
                defaultDate: 0,
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setStartDate(value);
                    setTimeout(() => $('#dateEnd').datepicker( "show" ), 100);
                }
            });
            $('#dateEnd').datepicker( "destroy" );
            $('#dateEnd').datepicker({
                defaultDate: "+1m",
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setEndDate(value)
                }
            });
        }, 100);
    }, [dateFilter, startDate, endDate]);

    const getCheckedItems = () => {
        return _.filter(payments, (b) => checkedItems.includes(getRowId(b)));
    }

    const onReport = () => {
        console.log("Report selected");
        if (checkedItems.length === 0) {
            Notification.Show(t('payment.list.error_choose_one'));
        } else {
            setShowCSVExportModal(true);
        }
    }

    const onKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    }

    const getQueryParams = () => {
        return {
            groupName: query,
            paidFilter: totalPaidFilter,
            paymentTypeFilter: paymentMethodFilter,
            venueFilter: venueFilter && [venueFilter],
            invoiceNumberFilter: invoiceFilter,
            dueDateFilter: dueDateFilter,
            paymentDateFilter: dateFilter && {
                filterType: dateFilter,
                startDate: startDate,
                endDate: endDate,
            },
            searchAllQuery: searchAllQuery,
            sort: sortFilter
        };
    }

    const onSearch = (restart = true) => {
        if (dateFilter === "dateRange" && (_.isNil(startDate) || _.isNil(endDate))) {
            return;
        }
        // Cache the options
        localStorage.setItem(facilityLink + "_filter_cache_invoice_payments", JSON.stringify(getQueryParams()));

        const searchUrl = settings.useNewPaymentsTable ? getApiUrl('/invoices/payments/v2/search') : getApiUrl('/invoices/payments/search');
        const data = {
            pagination: {
                from: restart ? 0 : (payments.length || 0),
                limit: limit,
            },
            query: getQueryParams()
        };
        if (restart) {
            setPayments([]);
        }
        serverPost(searchUrl, data).then((res) => {
            setHasMore(res.length >= limit);
            if (restart) {
                setPayments(res);
            } else {
                setPayments(_.concat(payments, res))
            }
        })
    }

    const updateChecked = (event) => {
        if (event.target.value === "checkall") {
            let newCheckedItems = [];
            if (event.target.checked) {
                newCheckedItems = _.map(payments, (b, i) => getRowId(b));
                newCheckedItems.push("checkall");
            }
            setCheckedItems(newCheckedItems);
        } else {
            const newCheckedItems = [...checkedItems];
            if (event.target.checked) {
                newCheckedItems.push(event.target.value);
            } else {
                let index = newCheckedItems.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
                index = newCheckedItems.indexOf("checkall");
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
            }
            setCheckedItems(newCheckedItems);
        }
    }

    function getRowId(payment) {
        return ("" + payment.id);
    }

    const dateFilterOptions = [
        { 'id': "today", 'label': t("common.today") },
        { 'id': "yesterday", 'label': t("common.yesterday") },
        { 'id': "thisWeek", 'label': t("common.this_week") },
        { 'id': "lastWeek", 'label': t("common.last_week") },
        { 'id': "thisMonth", 'label': t("common.this_month") },
        { 'id': "lastMonth", 'label': t("common.last_month") },
        { 'id': "dateRange", 'label': t("common.date_range") },
    ];

    const invoiceFilterOptions = [
        { 'id': "amended", 'label': t("invoice.list.amended") },
        { 'id': "amendedToday", 'label': t("invoice.list.amended_today") },
        { 'id': "amended7", 'label': t("invoice.list.amended_7") },
        { 'id': "amended30", 'label': t("invoice.list.amended_30") },
        { 'id': "amended90", 'label': t("invoice.list.amended_90") },
    ];

    const dueDateFilterOptions = [
        { 'id': "overdue", 'label': t("invoice.list.overdue") },
        { 'id': "overdue30", 'label': t("invoice.list.overdue_30") },
        { 'id': "overdue60", 'label': t("invoice.list.overdue_60") },
        { 'id': "overdue90", 'label': t("invoice.list.overdue_90") },
    ];

    const totalPaidFilterOptions = [
        { 'id': "fullyPaid", 'label': t("invoice.list.fully_paid") },
        { 'id': "partlyPaid", 'label': t("invoice.list.partly_paid") },
        { 'id': "notPaid", 'label': t("invoice.list.unpaid") },
    ]

    const sortOptions = [
        { 'id': "datePaidDesc", 'label': t("invoice.list.paid_date_desc") },
        { 'id': "invDateAsc", 'label': t("invoice.list.inv_date_asc") },
        { 'id': "invDateDesc", 'label': t("invoice.list.inv_date_desc") },
        { 'id': "amountAsc", 'label': t("invoice.list.amount_asc") },
        { 'id': "amountDesc", 'label': t("invoice.list.amount_desc") },
    ]

    const refundPayment = (payment) => {
        const refundUrl = getApiUrl(`/payments/refund`);
        const refundParams = {
            bookingId: payment.bookingId,
            paymentTransactionId: payment.paymentTransactionId
        }

        serverPost(refundUrl, refundParams).then((res) => {
            if (res) {
                console.log("Refund successful");
                Notification.Show(t("payment.list.refund_successful"));
                onSearch();
            } else {
                Notification.Show(t("payment.list.error_refund"));
            }
        })
    }

    const getNewPaymentTable = () => {
        const hasFeesCol = (settings.creditCardFeesUsage && !(_.isEmpty(settings.creditCardFeesUsage))) || _.some(payments, (p) => p.ccFeesPaid && p.ccFeesPaid !== 0);

        return <Table hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('invoice.list.invoice_number')}</th>
                            <th>{t('common.group_name')}</th>
                            <th>{t('invoice.list.due_date')}</th>
                            <th>{t('payment.list.payment_date')}</th>
                            <th>{t('invoice_payment.list.amount_paid')}</th>
                            {
                                hasFeesCol && <th className="text-end">{cSettings.creditCardFeeLabel || t('invoice_payment.list.fees_paid')}</th>
                            }
                            <th>{t('invoice_payment.list.total_paid')}</th>
                            <th>{t('invoice_payment.list.payment_type')}</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th className="controls">
                                <div className="checkbox check-success inline">
                                    <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedItems.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                                    <label htmlFor="checkall"/>
                                </div>
                            </th>
                            <th className="controls"><SingleSelectDropdown items={invoiceFilterOptions} selectedId={invoiceFilter} onSelect={setInvoiceFilter} align={"end"} showAll /></th>
                            <th className="controls"><Form.Control type="text" name="query" value={query} onChange={(event) => { setQuery(event.target.value) }}/></th>
                            <th className="controls"><SingleSelectDropdown items={dueDateFilterOptions} selectedId={dueDateFilter} onSelect={setDueDateFilter} align={"end"} showAll /></th>
                            <th className="controls">
                                <SingleSelectDropdown items={dateFilterOptions} selectedId={dateFilter} onSelect={setDateFilter} showAll />
                                {
                                    dateFilter === "dateRange" &&
                                        <BaseForm initialFormFields={{ dateStart: startDate, dateEnd: endDate }}>
                                            <BaseForm.Control type="text" name="dateStart" id={"dateStart"} />
                                            <BaseForm.Control type="text" name="dateEnd" id={"dateEnd"} />
                                            <Button variant="outline-primary" onClick={(event) => onSearch()}><i className="fa fa-refresh fa-small"/></Button>
                                        </BaseForm>
                                }
                            </th>
                            <th className="controls"></th>
                            {
                                hasFeesCol && <th className="controls"></th>
                            }
                            <th className="controls"><SingleSelectDropdown items={totalPaidFilterOptions} selectedId={totalPaidFilter} onSelect={setTotalPaidFilter} align={"end"} showAll /></th>
                            <th className="controls"><SingleSelectDropdown items={paymentMethodOptions} selectedId={paymentMethodFilter} onSelect={setPaymentMethodFilter} align={"end"} showAll /></th>
                            <th className="controls"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            _.map(payments, (payment, i) =>
                                <tr key={i} className={classnames(payment.isCancel && "de-highlighted")}>
                                    <td>
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value={getRowId(payment)} id={getRowId(payment)} name="delete[]"  checked={checkedItems.includes(getRowId(payment))} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor={getRowId(payment)}/>
                                        </div>
                                    </td>
                                    <td><Link to={`/${facilityLink}/invoice/view?uuid=${payment.invoiceUUID}`}>{ payment.invoiceNumber }</Link></td>
                                    <td><Link to={`/${facilityLink}/booking/list?groupId=${payment.groupId}`}>{ payment.groupName }</Link></td>
                                    <td className={classnames(moment(payment.dueDate).isBefore(moment(), 'date') && "overdue")}>{ moment(payment.dueDate).format(dateFormat) }</td>
                                    <td>{ moment(payment.datePaid).format(dateFormat) }</td>
                                    <td className="text-end"><span className="label label-success">{ currencyFormat(payment.amountPaid) }</span></td>
                                    {
                                        hasFeesCol && (
                                            payment.ccFeesPaid ?
                                                <td className="text-end"><span className="label label-success">{ payment.ccFeesPaidFormatted }</span></td>
                                                : <td className="text-end"><span className="label label-success">{ currencyFormat(0) }</span></td>
                                        )
                                    }
                                    <td className="text-end">
                                    {
                                        payment.amountDue > 0 ?
                                            <span className="label label-warning">{ currencyFormat(payment.invoiceTotalPaid) }</span>
                                        : <span className="label label-success">{ currencyFormat(payment.invoiceTotalPaid) }</span>
                                    }
                                    </td>
                                    <td className="text-end">
                                        <span className="label">{ _.replace(payment.paymentMethod, "Paid ", "") }</span>
                                    </td>
                                    <td className="text-end">
                                        {
                                            payment.isRefundable && <ConfirmationButton variant="danger" size="sm" title="Refund Payment?" body="Are you sure you want to refund the payment?" onConfirm={() => refundPayment(payment)}>
                                                <i className="fa fa-undo"/>
                                            </ConfirmationButton>
                                        }
                                        {
                                            payment.isRefunded && <span className="label label-danger">{t("payment.list.refunded")}</span>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                        {
                            hasMore ?
                                <tr>
                                    <td colSpan={11} className="center">
                                        <div className="spinner-border text-secondary"/>
                                    </td>
                                </tr>
                            : <tr>
                                    <td colSpan={11} className="center">
                                        <div className="label">{t('payment.list.end_of_payments')}</div>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </Table>
    }

    const getOldPaymentTable = () => {
        return <Table hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('invoice.list.invoice_number')}</th>
                            <th>{t('common.group_name')}</th>
                            <th>{t('invoice.list.due_date')}</th>
                            <th>{t('payment.list.payment_date')}</th>
                            <th>{t('invoice_payment.list.amount_paid')}</th>
                            <th>{t('invoice_payment.list.total_paid')}</th>
                            <th>{t('invoice_payment.list.payment_type')}</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th className="controls">
                                <div className="checkbox check-success inline">
                                    <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedItems.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                                    <label htmlFor="checkall"/>
                                </div>
                            </th>
                            <th className="controls"><SingleSelectDropdown items={invoiceFilterOptions} selectedId={invoiceFilter} onSelect={setInvoiceFilter} align={"end"} showAll /></th>
                            <th className="controls"><Form.Control type="text" name="query" value={query} onChange={(event) => { setQuery(event.target.value) }}/></th>
                            <th className="controls"><SingleSelectDropdown items={dueDateFilterOptions} selectedId={dueDateFilter} onSelect={setDueDateFilter} align={"end"} showAll /></th>
                            <th className="controls">
                                <SingleSelectDropdown items={dateFilterOptions} selectedId={dateFilter} onSelect={setDateFilter} showAll />
                                {
                                    dateFilter === "dateRange" &&
                                        <BaseForm initialFormFields={{ dateStart: startDate, dateEnd: endDate }}>
                                            <BaseForm.Control type="text" name="dateStart" id={"dateStart"} />
                                            <BaseForm.Control type="text" name="dateEnd" id={"dateEnd"} />
                                            <Button variant="outline-primary" onClick={(event) => onSearch()}><i className="fa fa-refresh fa-small"/></Button>
                                        </BaseForm>
                                }
                            </th>
                            <th className="controls"></th>
                            <th className="controls"><SingleSelectDropdown items={totalPaidFilterOptions} selectedId={totalPaidFilter} onSelect={setTotalPaidFilter} align={"end"} showAll /></th>
                            <th className="controls"><SingleSelectDropdown items={paymentMethodOptions} selectedId={paymentMethodFilter} onSelect={setPaymentMethodFilter} align={"end"} showAll /></th>
                            <th className="controls"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            _.map(payments, (payment, i) =>
                                <tr key={i} className={classnames(payment.isCancel && "de-highlighted")}>
                                    <td>
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value={getRowId(payment)} id={getRowId(payment)} name="delete[]"  checked={checkedItems.includes(getRowId(payment))} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor={getRowId(payment)}/>
                                        </div>
                                    </td>
                                    <td><Link to={`/${facilityLink}/invoice/view?uuid=${payment.invoiceUUID}`}>{ payment.invoiceNumber }</Link></td>
                                    <td><Link to={`/${facilityLink}/booking/list?groupId=${payment.groupId}`}>{ payment.groupName }</Link></td>
                                    <td className={classnames(moment(payment.dueDate).isBefore(moment(), 'date') && "overdue")}>{ moment(payment.dueDate).format(dateFormat) }</td>
                                    <td>{ moment(payment.datePaid).format(dateFormat) }</td>
                                    <td className="text-end"><span className="label label-success">{ currencyFormat(payment.amountPaid) }</span></td>
                                    <td className="text-end">
                                    {
                                        payment.amountDue > 0 ?
                                            <span className="label label-warning">{ currencyFormat(payment.invoiceTotalPaid) }</span>
                                        : <span className="label label-success">{ currencyFormat(payment.invoiceTotalPaid) }</span>
                                    }
                                    </td>
                                    <td className="text-end">
                                        <span className="label">{ _.replace(payment.paymentMethod, "Paid ", "") }</span>
                                    </td>
                                    <td className="text-end">
                                        {
                                            payment.isRefundable && <ConfirmationButton variant="danger" size="sm" title="Refund Payment?" body="Are you sure you want to refund the payment?" onConfirm={() => refundPayment(payment)}>
                                                <i className="fa fa-undo"/>
                                            </ConfirmationButton>
                                        }
                                        {
                                            payment.isRefunded && <span className="label label-danger">{t("payment.list.refunded")}</span>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                        {
                            hasMore ?
                                <tr>
                                    <td colSpan={11} className="center">
                                        <div className="spinner-border text-secondary"/>
                                    </td>
                                </tr>
                            : <tr>
                                    <td colSpan={11} className="center">
                                        <div className="label">{t('payment.list.end_of_payments')}</div>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </Table>
    }

    const paymentMethodOptions = _.map(paymentMethods, (m) => { return { 'id': m.paymentTag, 'label': _.replace(m.paymentType, "Paid ", ""), 'icon': m.paymentIcon }; });
    const venueOptions = _.map(venues, (venue, i) => { return { 'value': venue.id, 'label': venue.name } });
    const dateFormat = getDateFormatForFacility(cSettings);
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "invoices")} />
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-md-2">
                            <InputGroup>
                                <Form.Control type="text" className="inline" name="searchAllQuery" value={searchAllQuery} onChange={(event) => { setSearchAllQuery(event.target.value) }} onKeyPress={onKeyPress}/>
                                <InputGroup.Text className="skinny" onClick={() => onSearch()}><i className="fa fa-search"/></InputGroup.Text>
                            </InputGroup>
                        </div>
                        <div className="col-md-4">
                            <SingleSelectDropdown className="inline" items={venueOptions} selectedId={venueFilter} onSelect={setVenueFilter} idField={"value"} showAll />
                        </div>
                        <div className="col-md-6">
                            <div className="float-end d-flex align-items-center">
                                <SingleSelectDropdown className="inline" items={getLimitOptions()} selectedId={limit} onSelect={setLimit} menuOnly/>
                                <Button variant="outline-primary" onClick={onReport}><i className="fa fa-circle-plus"/> {t("common.new_report")}</Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="9" className="text-end d-grid align-items-center">
                            <span><strong>{t("common.sort_order")}:</strong></span>
                        </Col>
                        <Col md="3">
                            <SingleSelectDropdown name="sort" selectedId={sortFilter} items={sortOptions} onSelect={setSortFilter} />
                        </Col>
                    </Row>
                    <InfiniteScroll
                        dataLength={payments.length}
                        next={() => onSearch(false)}
                        hasMore={hasMore}
                        scrollableTarget="content_wrapper"
                    >
                        {settings.useNewPaymentsTable ? getNewPaymentTable() : getOldPaymentTable()}
                    </InfiniteScroll>
                </div>
            </div>
            <InvoicePaymentsCSVExportModal
                show={showCSVExportModal}
                onClose={setShowCSVExportModal}
                items={getCheckedItems()}
                criteria={getQueryParams()}
                creditCardOption={(settings.creditCardFeesUsage && !(_.isEmpty(settings.creditCardFeesUsage))) || _.some(_.filter(payments, (p) => _.includes(checkedItems, p.id.toString())), (p) => p.ccFeesPaid && p.ccFeesPaid !== 0)}
                useCriteria={checkedItems.includes("checkall")} />
        </BaseContainer>
    );
}

export default PaymentList;
