import '../App.scss';
import '../css/modals.scss';
import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, createRef, useMemo } from 'react';
import BaseModal from './modals/BaseModal';
import BaseForm from './BaseForm';
import SubmitButton from './SubmitButton';
import { Form, Row, Col } from 'react-bootstrap';
import { BaseContext, getStripePublishableKeyForFacility } from '../helpers/common';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement, CardElement } from '@stripe/react-stripe-js';
import classnames from 'classnames';
import {serverPost} from "../helpers/server";
import Notification from "./Notification";
const _ = require('lodash');

const BamboraCardInput = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async getPaymentMethod(data) {
            return getPaymentMethod(data);
        },
        onFieldChange(name, value) {
            onFieldChange(name, value);
        }
    }));

    const { t } = useTranslation('common');
    const { getApiUrl, settings } = useContext(BaseContext);
    const [initialized, setInitialized] = useState(false);
    const [cardReady, setCardReady] = useState(false);
    const [bamboraCheckout, setBamboraCheckout] = useState(null);

    const getPaymentMethod = async (data) => {
        if (!cardReady) {
            return null;
        } else {
            const result = await new Promise((resolve, reject) => {
                var callback = function(result) {
                    resolve(result);
                };

                bamboraCheckout.createToken(callback);
            })
            if (result.error) {
                console.log("Token error " + result.error);
            } else {
                return {
                    cardHolderName: data.cardHolderName,
                    token: result.token,
                    billingAddress: {
                        zipcode: data.zipcode
                    }
                };
            }
            return null;
        }
    }

    const onFieldChange = (name, value) => {
        if (props.setInitialFields) {
            if (_.includes(["cardHolderName", "zipcode"], name)) {
                props.setInitialFields(prevInitialFields => {
                    const newInitialFields = {...prevInitialFields};
                    newInitialFields[name] = value;
                    return newInitialFields;
                });
            }
        }
    }

    useEffect(() => {
        if (initialized) {
            return;
        }
        setInitialized(true);
        /* global customcheckout */
        setTimeout(() => {
            (function() {
                var customCheckout = customcheckout();
                var isCardNumberComplete = false;
                var isCVVComplete = false;
                var isExpiryComplete = false;

                var customCheckoutController = {
                    init: function() {
                        console.log('checkout.init()');
                        this.createInputs();
                        this.addListeners();
                    },
                    createInputs: function() {
                        console.log('checkout.createInputs()');
                        var style = {
                            error: {
                                color: 'red',
                                ':focus': {
                                    fontStyle: 'italic',
                                },
                            },
                            base: {
                                color: '#626262',
                                fontSize: '13px',
                                padding: '10px'
                            }
                        };

                        var classes = {
                            error: 'my-error-class',
                            base: 'bambora-base-class'
                        };

                        var options = {
                            placeholder: t('payment_modal.card_number'),
                            style: style,
                            classes: classes,
                        };

                        // Create and mount the inputs
                        options.placeholder = t('payment_modal.card_number');
                        customCheckout.create('card-number', options).mount('#card-number');

                        options.placeholder = t('payment_modal.cvv');
                        customCheckout.create('cvv', options).mount('#card-cvv');

                        options.placeholder = t('payment_modal.expiry');
                        customCheckout.create('expiry', options).mount('#card-expiry');
                    },
                    addListeners: function() {
                        var self = this;

                        // listen for submit button
                        if (document.getElementById('checkout-form') !== null) {
                            document
                                .getElementById('checkout-form')
                                .addEventListener('submit', self.onSubmit.bind(self));
                        }

                        customCheckout.on('empty', function(event) {
                            console.log('empty: ' + JSON.stringify(event));

                            if (event.empty) {
                                if (event.field === 'card-number') {
                                    isCardNumberComplete = false;
                                } else if (event.field === 'cvv') {
                                    isCVVComplete = false;
                                } else if (event.field === 'expiry') {
                                    isExpiryComplete = false;
                                }
                            }
                        });

                        customCheckout.on('complete', function(event) {
                            console.log('complete: ' + JSON.stringify(event));

                            if (event.field === 'card-number') {
                                isCardNumberComplete = true;
                                self.hideErrorForId('card-number');
                            } else if (event.field === 'cvv') {
                                isCVVComplete = true;
                                self.hideErrorForId('card-cvv');
                            } else if (event.field === 'expiry') {
                                isExpiryComplete = true;
                                self.hideErrorForId('card-expiry');
                            }
                            setCardReady(isCardNumberComplete && isCVVComplete && isExpiryComplete);
                        });

                        customCheckout.on('error', function(event) {
                            console.log('error: ' + JSON.stringify(event));

                            if (event.field === 'card-number') {
                                isCardNumberComplete = false;
                                self.showErrorForId('card-number', event.message);
                            } else if (event.field === 'cvv') {
                                isCVVComplete = false;
                                self.showErrorForId('card-cvv', event.message);
                            } else if (event.field === 'expiry') {
                                isExpiryComplete = false;
                                self.showErrorForId('card-expiry', event.message);
                            }
                        });
                    },
                    onSubmit: function(event) {
                        var self = this;

                        console.log('checkout.onSubmit()');

                        event.preventDefault();
                        self.toggleProcessingScreen();

                        var callback = function(result) {
                            console.log('token result : ' + JSON.stringify(result));

                            if (result.error) {
                                self.processTokenError(result.error);
                            } else {
                                self.processTokenSuccess(result.token);
                            }
                        };

                        console.log('checkout.createToken()');
                        customCheckout.createToken(callback);
                    },
                    hideErrorForId: function(id) {
                        console.log('hideErrorForId: ' + id);

                        var element = document.getElementById(id);

                        if (element !== null) {
                            var errorElement = document.getElementById(id + '-error');
                            if (errorElement !== null) {
                                errorElement.innerHTML = '';
                            }

                            var bootStrapParent = document.getElementById(id + '-bootstrap');
                            if (bootStrapParent !== null) {
                                bootStrapParent.classList.remove('has-error');
                                bootStrapParent.classList.add('has-success');
                            }
                        } else {
                            console.log('showErrorForId: Could not find ' + id);
                        }
                    },
                    showErrorForId: function(id, message) {
                        console.log('showErrorForId: ' + id + ' ' + message);

                        var element = document.getElementById(id);

                        if (element !== null) {
                            var errorElement = document.getElementById(id + '-error');
                            if (errorElement !== null) {
                                errorElement.innerHTML = message;
                            }

                            var bootStrapParent = document.getElementById(id + '-bootstrap');
                            if (bootStrapParent !== null) {
                                bootStrapParent.classList.add('has-error');
                                bootStrapParent.classList.remove('has-success');
                            }
                        } else {
                            console.log('showErrorForId: Could not find ' + id);
                        }
                    },
                    toggleProcessingScreen: function() {
                        var processingScreen = document.getElementById('processing-screen');
                        if (processingScreen) {
                            processingScreen.classList.toggle('visible');
                        }
                    },
                    showErrorFeedback: function(message) {
                        var xMark = '\u2718';
                        this.feedback = document.getElementById('feedback');
                        this.feedback.innerHTML = xMark + ' ' + message;
                        this.feedback.classList.add('error');
                    },
                    showSuccessFeedback: function(message) {
                        var checkMark = '\u2714';
                        this.feedback = document.getElementById('feedback');
                        this.feedback.innerHTML = checkMark + ' ' + message;
                        this.feedback.classList.add('success');
                    },
                    processTokenError: function(error) {
                        error = JSON.stringify(error, undefined, 2);
                        console.log('processTokenError: ' + error);

                        this.showErrorFeedback(
                            'Error creating token: </br>' + JSON.stringify(error, null, 4)
                        );
                        this.toggleProcessingScreen();
                    },
                    processTokenSuccess: function(token) {
                        console.log('processTokenSuccess: ' + token);
                    },
                };

                customCheckoutController.init();
                setBamboraCheckout(customCheckout);
            })();
        }, 100);
    }, [initialized])

    return (
        <Row>
            <BaseForm.Input colSpan="5" type="text" name="cardHolderName" label={t('payment_modal.full_name')} required />
            <Col md="8">
                <BaseForm.InputGroup required>
                    <Form.Label>{t('payment_modal.card_details')}</Form.Label>
                    <div className="d-flex flex-row gap-12 align-items-center">
                        <div id="card-number" className="form-control borderless flex-grow-1" />
                        <div id="card-cvv" className="form-control borderless" style={{ width: "100px" }} />
                        <div id="card-expiry" className="form-control borderless" style={{ width: "100px" }} />
                    </div>
                    <div className="d-flex flex-row gap-12 align-items-center">
                        <span id="card-number-error" className="form-error-message borderless flex-grow-1" />
                        <span id="card-cvv-error" className="form-error-message borderless" style={{ width: "100px" }} />
                        <span id="card-expiry-error" className="form-error-message borderless" style={{ width: "100px" }} />
                    </div>
                </BaseForm.InputGroup>
            </Col>
            <Col md="4" className="d-flex align-items-center">
                <BaseForm.Input colSpan="12" type="text" name="zipcode" label={t('payment_modal.zip_code')} required />
            </Col>
        </Row>
    )
})

export default BamboraCardInput;
