import '../../App.scss';
import '../../css/modals.scss';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import TabHeader from '../TabHeader';
import LoginComponent from '../LoginComponent';
import Notification from '../Notification';
import { Button, Row, Col } from 'react-bootstrap';
import { getUrl, serverPost } from '../../helpers/server';
import { storeUserInfo } from '../../helpers/storage';
import { validateEmail, BaseContext } from '../../helpers/common';
const _ = require('lodash');

function SignupModal(props) {
    const { getApiUrl, facilityLink, settings } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [emailCheckCompleted, setEmailCheckCompleted] = useState(false);
    const [emailCheckPassed, setEmailCheckPassed] = useState(false);
    const [groupCheckCompleted, setGroupCheckCompleted] = useState(false);
    const [groupCheckPassed, setGroupCheckPassed] = useState(false);
    const [currentTab, setCurrentTab] = useState("signup");

    const signup = (signupData) => {
        const nameParts = signupData.fullName.split(" ");
        const data = {...signupData};
        data["firstName"] = nameParts[0];
        data["lastName"] = nameParts.length > 1 ? nameParts[1] : "";
        serverPost(getApiUrl("/users"), data).then((res) => {
            if (res) {
                setCurrentTab("login");
                setTimeout(() => {
                    Notification.ModalShow("Successfully registered");
                }, 100)
            } else {
                // Show error
            }
        })
    }

    useEffect(() => {
        if (!_.isNil(props.defaultToLogin)) {
            setCurrentTab("login");
        }
    }, [props.defaultToLogin])

    useEffect(() => {
        setEmailCheckCompleted(false);
        setGroupCheckCompleted(false);
    }, [props.show]);

    const onEmailBlur = (event) => {
        event.preventDefault();
        const value = event.target.value;
        setEmailCheckCompleted(false);
        if (validateEmail(value)) {
            const data = { email: value }
            serverPost(getApiUrl("/users/emailcheck"), data).then((res) => {
                setEmailCheckCompleted(true);
                setEmailCheckPassed(!res.exists);
            })
        }
    }

    const onGroupBlur = (event) => {
        event.preventDefault();
        const value = event.target.value;
        setGroupCheckCompleted(false);
        const data = { name: value }
        serverPost(getApiUrl("/user_groups/check_name"), data).then((res) => {
            setGroupCheckPassed(!res);
            setGroupCheckCompleted(true);
        })
    }

    const tabItems = [
        { value: "signup", label: "Signup", active: currentTab === "signup", onClick: () => setCurrentTab("signup") },
        { value: "login", label: "Login", active: currentTab === "login", onClick: () => setCurrentTab("login") },
    ];

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>
                {
                    currentTab === "signup" ?
                        <span>Sign up</span>
                    : <span>Login</span>
                }
                </BaseModal.Title>
            </BaseModal.Header>
            <TabHeader items={tabItems} />
            {
                currentTab === "signup" &&
                    <>
                    {
                        !settings.disableSignup ?
                            <BaseModal.Body>
                                <h2>We make it easy to book online.</h2>
                                <p>Create your online booking account. Already have an account? Go back and login.</p>
                                <BaseForm onSubmit={signup} errorFields={
                                    groupCheckCompleted && !groupCheckPassed && {
                                        groupName: "Group Name already exists. Please select a different name"
                                    }
                                }>
                                    <Row>
                                        <BaseForm.Input colSpan="12" name="email" label={t('forms.email')} type="text"
                                            placeholder="Will be used to login" validations={{required: true, validEmail: true}}
                                            onBlur={onEmailBlur}
                                            rightContent={
                                                emailCheckCompleted &&
                                                    (emailCheckPassed ?
                                                        <i className="fa fa-check-circle fa-c-success fa-2x"/> :
                                                        <i className="fa fa-times-circle fa-c-danger fa-2x"/>)
                                            }/>
                                        <Col md="12" className="text-center">
                                        {
                                            emailCheckCompleted && !emailCheckPassed &&
                                                <p>
                                                    <span>Looks like you may already have an account.</span>
                                                    <Button variant="alink" className="skinny" onClick={props.onShowPasswordReset}>RESET OR CREATE YOUR PASSWORD</Button>
                                                </p>
                                        }
                                        </Col>
                                        <BaseForm.Input colSpan="12" name="groupName" label="Group Name"
                                            placeholder="i.e. Team Name, Screen Name" type="text" required
                                            onBlur={onGroupBlur}
                                            rightContent={
                                                groupCheckCompleted &&
                                                    (groupCheckPassed ?
                                                        <i className="fa fa-check-circle fa-c-success fa-2x"/> :
                                                        <i className="fa fa-times-circle fa-c-danger fa-2x"/>)
                                            }/>
                                        <BaseForm.Input colSpan="12" name="fullName" label="Full Name"
                                            placeholder="John Doe" type="text" required />
                                        <BaseForm.Input colSpan="12" name="phoneNumber" label="Phone" placeholder="999 999-9999"
                                            type="text" required />
                                        <BaseForm.Input colSpan="12" name="password" label="Password" placeholder="8 Character Minimum"
                                            type="password" validations={{required: true, minLength: 8}} />
                                    </Row>
                                    <p className="text-center">By creating an account you are agreeing to the <a href="https://www.rectimes.com/terms" target="_blank" rel="noreferrer" className="bold">Terms of service and Privacy Policy <i className="fa fa-external-link fs-9"></i></a>.</p>
                                    <SubmitButton variant="primary" className="btn-complete">Create Your Account</SubmitButton>
                                </BaseForm>
                            </BaseModal.Body>
                        : <BaseModal.Body>
                            <h4>Please Contact { settings.facilityName } directly to create your user account.</h4>
                            <Row>
                                <Col md="6">
                                {
                                    settings.phone &&
                                        <h5><a href={"tel:" + settings.phone}><i className="fa fa-phone"></i> {settings.phone}</a></h5>
                                }
                                </Col>
                                <Col md="6">
                                {
                                    settings.primaryEmail &&
                                        <h5><a href={"mailto:" + settings.primaryEmail}><i className="fa fa-envelope"></i> {settings.primaryEmail}</a></h5>
                                }
                                </Col>
                            </Row>
                        </BaseModal.Body>
                    }
                    </>
            }
            {
                currentTab === "login" &&
                    <BaseModal.Body>
                        <LoginComponent showSignup={false} onSuccess={props.onSuccess} />
                    </BaseModal.Body>
            }
        </BaseModal>
    );

}

export default SignupModal;
