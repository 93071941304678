import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import { useEffect, useState, useRef, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import AddCreditModal from '../../components/modals/AddCreditModal';
import CreditsCSVExportModal from '../../components/modals/CreditsCSVExportModal';
import Notification from '../../components/Notification';
import BaseContainer from '../../components/Container';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import ConfirmationButton from '../../components/ConfirmationButton';
import { getUrl, serverFetch, serverPost } from '../../helpers/server';
import { getLimitOptions, dateRangeClassCheck, BaseContext, currencyFormat, getDateFormatForFacility, hasAccess } from '../../helpers/common';
import { getUserInfo, getCompanyInfo } from '../../helpers/storage';
import { useParams, useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, InputGroup, Form, Button, Row, ButtonToolbar, ButtonGroup, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import classnames from 'classnames';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
const _ = require("lodash");

function List() {
    const { isLoggedIn, getApiUrl, getFacilityName, userInfo } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const [showAddCreditModal, setShowAddCreditModal] = useState(false);
    const [showCSVExportModal, setShowCSVExportModal] = useState(false);
    const [userGroup, setUserGroup] = useState({});
    const [hasMore, setHasMore] = useState(true);
    const [meta, setMeta] = useState({});
    const [settings, setSettings] = useState(getCompanyInfo());
    const [ searchParams ] = useSearchParams();
    let groupId = searchParams.get('groupId');
    const canUpdateCustomers = hasAccess("customers", userInfo, "update");

    const [searchAllQuery, setSearchAllQuery] = useState("");
    const [query, setQuery] = useState("");
    const [typeFilter, setTypeFilter] = useState(null);
    const [sourceFilter, setSourceFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState("active");
    const [dateFilter, setDateFilter] = useState(null);
    const [sortFilter, setSortFilter] = useState("createdDesc");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [limit, setLimit] = useState(20);

    const [credits, setCredits] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);

    const startDateRef = useRef();
    startDateRef.current = startDate;
    const endDateRef = useRef();
    endDateRef.current = endDate;
    const queryTimer = useRef(null);

    useEffect(() => {
        document.title = "Credit - " + getFacilityName();
    }, []);

    useEffect(() => {
        groupId = searchParams.get('groupId')
        onSearch(true);
    }, [searchParams])

    useEffect(() => {
        serverFetch(getApiUrl('')).then((res) => {
            setSettings(res);
        })
    }, []);

    useEffect(() => {
        if (groupId) {
            serverFetch(getApiUrl(`/user_groups/${groupId}`)).then((res) => {
                setUserGroup(res);
            })
        }
    }, [groupId]);

    useEffect(() => {
        onSearch();
    }, [dateFilter, limit, query, sourceFilter, typeFilter, statusFilter, sortFilter]);

    useEffect(() => {
        clearTimeout(queryTimer.current);
        queryTimer.current = setTimeout((callback, kk, vv) => {
                if (callback) {
                    callback(kk, vv);
                }
                queryTimer.current = null;
            },
            400,
            onSearch,
        );
    }, [searchAllQuery]);

    useEffect(() => {
        setTimeout(() => {
            $('#dateStart').datepicker( "destroy" );
            $('#dateStart').datepicker({
                defaultDate: 0,
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setStartDate(value);
                    setTimeout(() => $('#dateEnd').datepicker( "show" ), 100);
                }
            });
            $('#dateEnd').datepicker( "destroy" );
            $('#dateEnd').datepicker({
                defaultDate: "+1m",
                dateFormat: "yy-mm-dd",
                hideIfNoPrevNext: true,
                beforeShowDay: (date) => dateRangeClassCheck(date, startDateRef.current, endDateRef.current),
                onSelect: (value) => {
                    setEndDate(value)
                }
            });
        }, 100);
    }, [dateFilter, startDate, endDate]);

    const getCheckedItems = () => {
        return _.filter(credits, (b) => checkedItems.includes(getRowId(b)));
    }

    const onKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    }

    const getQueryParams = () => {
        return {
            groupName: query,
            dateFilter: dateFilter && {
                filterType: dateFilter,
                startDate: startDate,
                endDate: endDate,
            },
            applyToFilter: sourceFilter,
            typeFilter: typeFilter,
            activeFilter: statusFilter,
            groupId: groupId,
            searchAllQuery: searchAllQuery,
            sort: sortFilter
        };
    }

    const onSearch = (restart = true) => {
        if (dateFilter === "dateRange" && (_.isNil(startDate) || _.isNil(endDate))) {
            return;
        }
        const searchUrl = getApiUrl('/credits/search');
        const data = {
            pagination: {
                from: restart ? 0 : (credits.length || 0),
                limit: limit,
            },
            query: getQueryParams()
        };
        if (restart) {
            setCredits([]);
            setMeta({});
        }
        serverPost(searchUrl, data).then((res) => {
            setHasMore(res.results.length >= limit);
            if (restart) {
                setCredits(res.results);
            } else {
                setCredits(_.concat(credits, res.results))
            }
            if (res.meta && restart) {
                setMeta(res.meta);
            }
        })
    }

    const onReport = () => {
        const checkedItems = getCheckedItems();
        if (checkedItems.length === 0) {
            Notification.Show(t('credit.list.error_choose_one'));
        } else {
            setShowCSVExportModal(true);
        }
    }

    const onCreateCredit = () => {
        setShowAddCreditModal(true);
    }

    const voidCredit = (credit) => {
        const data = {
            ids: [credit.id]
        }
        serverPost(getApiUrl('/credits/batch_delete'), data).then((res) => {
            onSearch(true);
        });
    }

    const onModalClose = () => {
        onSearch(true);
        setShowAddCreditModal(false);
    }

    const updateChecked = (event) => {
        if (event.target.value === "checkall") {
            let newCheckedItems = [];
            if (event.target.checked) {
                newCheckedItems = _.map(credits, (b, i) => getRowId(b));
                newCheckedItems.push("checkall");
            }
            setCheckedItems(newCheckedItems);
        } else {
            const newCheckedItems = [...checkedItems];
            if (event.target.checked) {
                newCheckedItems.push(event.target.value);
            } else {
                let index = newCheckedItems.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
                index = newCheckedItems.indexOf("checkall");
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
            }
            setCheckedItems(newCheckedItems);
        }
    }

    function getRowId(payment) {
        return ("" + payment.id);
    }

    const dateFilterOptions = [
        { 'id': "today", 'label': t("common.today") },
        { 'id': "yesterday", 'label': t("common.yesterday") },
        { 'id': "thisWeek", 'label': t("common.this_week") },
        { 'id': "lastWeek", 'label': t("common.last_week") },
        { 'id': "thisMonth", 'label': t("common.this_month") },
        { 'id': "lastMonth", 'label': t("common.last_month") },
        { 'id': "dateRange", 'label': t("common.date_range") },
    ];

    const sourceFilterOptions = [
        { 'id': "booking", 'label': t("credit.list.booking") },
        { 'id': "invoice", 'label': t("credit.list.invoice") },
        { 'id': "manual", 'label': t("credit.list.manual") },
    ];

    const typeFilterOptions = [
        { 'id': "credit", 'label': t("credit.list.credit") },
        { 'id': "debit", 'label': t("credit.list.debit") },
    ];

    const statusFilterOptions = [
        { 'id': "active", 'label': t("common.active") },
        { 'id': "cancelled", 'label': t("credit.list.void") },
    ];

    const sortOptions = [
        { 'id': "createdDesc", 'label': t("credit.list.cred_date_desc") },
        { 'id': "duesAsc", 'label': t("credit.list.amount_asc") },
        { 'id': "duesDesc", 'label': t("credit.list.amount_desc") },
    ]

    const dateFormat = getDateFormatForFacility(settings);
    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        groupId: groupId,
        userGroup: userGroup,
        settings: settings,
        userInfo: userInfo,
    };

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "credit", tabsParams)} />
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-md-2">
                            <InputGroup>
                                <Form.Control type="text" className="inline" name="searchAllQuery" value={searchAllQuery} onChange={(event) => { setSearchAllQuery(event.target.value) }} onKeyPress={onKeyPress}/>
                                <InputGroup.Text className="skinny" onClick={() => onSearch()}><i className="fa fa-search"/></InputGroup.Text>
                            </InputGroup>
                        </div>
                        <div className="col-md-4">
                            <ButtonGroup className="skinny">
                                <BaseOverlayTrigger content={t("credit.list.filtered_balance")} >
                                    <Button variant="outline-primary"><i className="fa fa-dollar-sign fa-small"/> {currencyFormat(meta.creditsTotal, false)}</Button>
                                </BaseOverlayTrigger>
                            </ButtonGroup>
                        </div>
                        <div className="col-md-6">
                            <div className="float-end d-flex align-items-center">
                                <Button variant="outline-primary" onClick={onCreateCredit}><i className="fa fa-circle-plus"/> {t("credit.list.add_new_credit")}</Button>
                                <SingleSelectDropdown className="inline" items={getLimitOptions()} selectedId={limit} onSelect={setLimit} menuOnly/>
                                <Button variant="outline-primary" onClick={onReport}><i className="fa fa-circle-plus"/> {t("common.new_report")}</Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="9" className="text-end d-grid align-items-center">
                            <span><strong>{t("common.sort_order")}:</strong></span>
                        </Col>
                        <Col md="3">
                            <SingleSelectDropdown name="sort" selectedId={sortFilter} items={sortOptions} onSelect={setSortFilter} />
                        </Col>
                    </Row>
                    <InfiniteScroll
                        dataLength={credits.length}
                        next={() => onSearch(false)}
                        hasMore={hasMore}
                        scrollableTarget="content_wrapper"
                    >
                        <Table hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t('common.group_name')}</th>
                                    <th>{t('credit.list.source')}</th>
                                    <th>{t('common.date')}</th>
                                    <th>{t('credit.list.type')}</th>
                                    <th>{t('common.amount')}</th>
                                    <th>{t('credit.list.status')}</th>
                                </tr>
                                <tr>
                                    <th className="controls">
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedItems.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor="checkall"/>
                                        </div>
                                    </th>
                                    <th className="controls"><Form.Control type="text" name="query" value={query} onChange={(event) => { setQuery(event.target.value) }}/></th>
                                    <th className="controls"><SingleSelectDropdown items={sourceFilterOptions} selectedId={sourceFilter} onSelect={setSourceFilter} align={"end"} showAll /></th>
                                    <th className="controls">
                                        <SingleSelectDropdown items={dateFilterOptions} selectedId={dateFilter} onSelect={setDateFilter} showAll />
                                        {
                                            dateFilter === "dateRange" &&
                                                <BaseForm initialFormFields={{ dateStart: startDate, dateEnd: endDate }}>
                                                    <BaseForm.Control type="text" name="dateStart" id={"dateStart"} />
                                                    <BaseForm.Control type="text" name="dateEnd" id={"dateEnd"} />
                                                    <Button variant="outline-primary" className="btn-complete" onClick={(event) => onSearch()}><i className="fa fa-refresh fa-small"/></Button>
                                                </BaseForm>
                                        }
                                    </th>
                                    <th className="controls"><SingleSelectDropdown items={typeFilterOptions} selectedId={typeFilter} onSelect={setTypeFilter} align={"end"} showAll /></th>
                                    <th className="controls"></th>
                                    <th className="controls"><SingleSelectDropdown items={statusFilterOptions} selectedId={statusFilter} onSelect={setStatusFilter} align={"end"} showAll /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.map(credits, (credit, i) =>
                                        <tr key={i} className={classnames(credit.isCancel && "de-highlighted")}>
                                            <td>
                                                <div className="checkbox check-success inline">
                                                    <input type="checkbox" className="" value={getRowId(credit)} id={getRowId(credit)} name="delete[]"  checked={checkedItems.includes(getRowId(credit))} onChange={ (event) => updateChecked(event) }/>
                                                    <label htmlFor={getRowId(credit)}/>
                                                </div>
                                            </td>
                                            <td><Link to={`/${facilityLink}/credit/list?groupId=${credit.groupId}`}>{ credit.groupName }</Link></td>
                                            <td>
                                                {
                                                    credit.creditSource === "booking" &&
                                                        <Link to={`/${facilityLink}/index?lid=${credit.booking.id}`}><span className="label full">{ `#${credit.booking.id}` }</span></Link>
                                                }
                                                {
                                                    credit.creditSource === "manual" &&
                                                        <span className="label full">{credit.creditNote}</span>
                                                }
                                                {
                                                    credit.creditSource === "invoice" &&
                                                        <Link to={`/${facilityLink}/invoice/view?uuid=${credit.invoice.uuid}`}><span className="label full">{credit.invoice.invoiceNumber}</span></Link>
                                                }
                                            </td>
                                            <td className="text-end"><span className="label">{ moment(credit.createdAtLocal).format(dateFormat) }</span></td>
                                            <td className="text-end">
                                            {
                                                credit.creditType === "credit" ?
                                                    <span className="label label-success">{t("credit.list.credit").toUpperCase()}</span>
                                                : <span className="label label-danger">{t("credit.list.debit").toUpperCase()}</span>
                                            }
                                            </td>
                                            <td className="text-end">
                                            {
                                                credit.creditType === "credit" ?
                                                    <span className="label label-success">{ currencyFormat(credit.amount) }</span>
                                                : <span className="label label-danger">{ currencyFormat(-1*credit.amount) }</span>
                                            }
                                            </td>
                                            <td className="text-center">
                                            {
                                                !credit.isCancel && canUpdateCustomers &&
                                                    <ButtonToolbar>
                                                        <ConfirmationButton variant="outline-primary" title={t('credit.list.void.title')}
                                                            body={t('credit.list.void.body')}
                                                            onConfirm={() => voidCredit(credit)}>
                                                            <i className="fa fa-remove"/>
                                                        </ConfirmationButton>
                                                    </ButtonToolbar>
                                            }
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    hasMore ?
                                        <tr>
                                            <td colSpan={11} className="center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                    : <tr>
                                            <td colSpan={11} className="center">
                                                <div className="label">{t('credit.list.end_of_credits')}</div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </InfiniteScroll>
                </div>
            </div>
            <AddCreditModal
                group={userGroup}
                show={showAddCreditModal}
                onClose={onModalClose}
                />
            <CreditsCSVExportModal
                show={showCSVExportModal}
                onClose={setShowCSVExportModal}
                items={getCheckedItems()}
                criteria={getQueryParams()}
                useCriteria={checkedItems.includes("checkall")} />
        </BaseContainer>
    );
}

export default List;
