import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import BaseContainer from '../../components/Container';
import Notification from '../../components/Notification';
import Loader from '../../components/Loader';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import { serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useParams } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {getTabItems} from "../../helpers/tabs";

function UserProfile() {
    const { getFacilityName, userInfo, getApiUrl } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = `${getFacilityName()} - User Profile`;
    }, []);

    useEffect(() => {
        fetchData();
    }, [facilityLink]);

    const fetchData = () => {
        setLoading(false);
    }

    const onSubmit = (data) => {
        serverPatch(getApiUrl(`/users/${userInfo.id}`), data).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
            }
        })
    }

    return (
        <BaseContainer>
            <Loader loading={loading}>
            { () => {
                return (
                    <>
                        <TabHeader items={getTabItems(t, facilityLink, "non-admin-user")} />
                        <div className="content-box">
                            <div className="content-body">
                                <BaseForm initialFormFields={userInfo} onSubmit={onSubmit}>
                                    <Row><Col md="6"><Row>
                                        <BaseForm.Input colSpan="12" type="text" name="email" label="Email" disabled={true} />
                                        <BaseForm.Input colSpan="12" type="text" name="firstName" label="First Name" />
                                        <BaseForm.Input colSpan="12" type="text" name="lastName" label="Last Name" />
                                        <BaseForm.Input colSpan="12" type="text" name="phone" label="Phone"/>
                                    </Row></Col></Row>
                                    <Row>
                                        <Col md="12">
                                            <SubmitButton variant="primary">Update</SubmitButton>
                                        </Col>
                                    </Row>
                                </BaseForm>
                            </div>
                        </div>
                    </>
                );
            }}
            </Loader>
        </BaseContainer>
    );
}

export default UserProfile;
