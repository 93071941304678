import { useTranslation } from "react-i18next";
import BaseContainer from "../../components/Container";
import TabHeader from "../../components/TabHeader";
import { getTabItems } from "../../helpers/tabs";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { BaseContext } from "../../helpers/common";
import {Button, Col, Row, Table} from "react-bootstrap";
import { serverFetch } from "../../helpers/server";
import Loader from "../../components/Loader";
import AddOrEditLeagueAppsSiteModal from "../../components/modals/AddOrEditLeagueAppsSiteModal";
const _ = require('lodash');

function PartnerSites() {
    const { userInfo, getApiUrl, settings } = useContext(BaseContext);
    const { t } = useTranslation("common");
    let { facilityLink } = useParams();
    const [showAddSiteModal, setShowAddSiteModal] = useState(false);
    const [partnerSites, setPartnerSites] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = (skipCache = false) => {
        serverFetch(getApiUrl("/partner/sites"), { skipCache }).then((res) => {
            setPartnerSites(res);
            setLoading(false);
        });
    }
    
    useEffect(() => {
        fetchData(true);
    }, [facilityLink]);

    const onModalClose = (didUpdate) => {
        setShowAddSiteModal(false);
        if (didUpdate) {
            fetchData(true);
        }
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "venue-sort", { userInfo: userInfo, isPartnerFacility: settings.isPartnerFacility })}/>
            <Loader loading={loading}>
                <div className="content-box">
                    <div className="content-body">
                        <Row>
                            <Col md="4">
                                {
                                    _.isEmpty(partnerSites) ?
                                        <h5>No LeagueApps sites connected.</h5>
                                    : <Table>
                                            <thead>
                                            <tr>
                                                <th>Site Name</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                _.map(partnerSites, (site, i) =>
                                                    <tr key={i}>
                                                        <td>{site.name} (ID: {site.id})</td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </Table>
                                }
                            </Col>
                            <Col md="9">
                                <br/>
                                <Button variant="primary" onClick={() => setShowAddSiteModal(true)}>Add new site</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Loader>
            <AddOrEditLeagueAppsSiteModal
                show={showAddSiteModal}
                onClose={onModalClose}
            />
        </BaseContainer>
    );
}

export default PartnerSites;
