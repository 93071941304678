import '../../App.scss';
import '../../css/modals.scss';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import Notification from '../Notification';
import { useTranslation } from 'react-i18next';
import { InputGroup, Row } from 'react-bootstrap';
import { getUrl, serverPatch, serverPost } from '../../helpers/server';

function AddOrEditFacilityModal(props) {
    const { t } = useTranslation("common");

    const addFacility = (data) => {
        const url = getUrl(`/api/v1/facilities`, false);
        serverPost(url, data).then((res) => {
            Notification.Show("Facility successfully created");
            props.onClose();
        })
    }

    const updateFacility = (data) => {
        const url = getUrl(`/api/v1/facilities/${props.itemToEdit.link}`, false);
        serverPatch(url, data).then((res) => {
            Notification.Show(t('notification.successfully_updated'));
            props.onClose();
        });
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            addItem={addFacility}
            updateItem={updateFacility}
            itemLabel={"Facility"}
        >
            <Row>
                <BaseForm.Input colSpan="12" name="facilityName" label="Facility Name" type="text" placeholder="Facility Name" required />
                <BaseForm.Input colSpan="12" name="link" label="Facility Link" type="text" required leftContent="app.rectimes.com/"/>
                <BaseForm.Input colSpan="12" name="partnerId" label="Partner id" type="number"  placeholder="1"/>
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditFacilityModal;
