import { useEffect, useState } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import SubmitButton from '../../components/SubmitButton';
import { getUrl, serverFetch, serverPost, serverDelete } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Form, Button, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function SettingClasses() {
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [newTemplateFields, updateNewTemplateFields] = useState({});
    const [existingTemplates, setExistingTemplates] = useState([]);
    const [loadingTemplates, setLoadingTemplates] = useState(false);

    useEffect(() => {
        // TODO(aswin): add facility name here
        document.title = "Classes Settings - ";
    }, []);

    useEffect(() => {
        setLoadingTemplates(true);
        loadAllClassTemplates();
    }, [facilityLink]);

    function loadAllClassTemplates() {
        serverFetch(getUrl('/api/classes/templates'), { skipCache: true }).then((res) => {
            const templates = {};
            res.forEach((item) => {
                templates[item.id] = item;
            })
            setExistingTemplates(templates);
            setLoadingTemplates(false);
        });
    }

    const updateNewFields = (event, key) => {
        event.stopPropagation();
        const newFields = {...newTemplateFields};
        newFields[key] = event.target.value;
        updateNewTemplateFields(newFields);
    }

    const updateExistingFields = (event, templateId, key) => {
        event.stopPropagation();
        const templates = {...existingTemplates};
        templates[templateId][key] = event.target.value;
        setExistingTemplates(templates);
    }

    const createNewTemplate = (event) => {
        event.preventDefault();
        const createTemplateUrl = getUrl('/api/classes/templates');
        const data = newTemplateFields;
        serverPost(createTemplateUrl, data).then((res) => {
            updateNewTemplateFields({});
            loadAllClassTemplates();
        })
    }

    const updateTemplate = (event, templateId) => {
        event.preventDefault();
        const updateTemplateUrl = getUrl('/api/classes/templates');
        const data = existingTemplates[templateId];
        serverPost(updateTemplateUrl, data).then((res) => {
            updateNewTemplateFields({});
        })
    }

    const deleteTemplate = (event, templateId) => {
        event.preventDefault();
        const deleteTemplateUrl = getUrl('/api/classes/templates/' + templateId);
        serverDelete(deleteTemplateUrl).then((res) => {
            loadAllClassTemplates();
        })
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "setting-classes")}/>
            <div className="content-box">
                <div className="content-header">
                    <span className="content-title">{t('setting_classes.create_template.title')}</span>
                </div>
                <div className="content-body">
                    <p>{t('setting_classes.create_template.description')}</p>

                    <Form id="createTemplateForm" onSubmit={createNewTemplate}>
                        <Row>
                            <div className="col-sm-4">
                                <Form.Group className="form-group required">
                                    <Form.Label>{t('forms.event_name')}</Form.Label>
                                    <Form.Control type="text" required value={newTemplateFields['eventName'] || ""} onChange={(event) => { updateNewFields(event, "eventName"); }}/>
                                </Form.Group>
                            </div>
                            <div className="col-sm-4">
                                <Form.Group className="form-group">
                                    <Form.Label>{t('forms.description')}</Form.Label>
                                    <Form.Control type="text" value={newTemplateFields['description'] || ""} onChange={(event) => { updateNewFields(event, "description"); }}/>
                                </Form.Group>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-sm-4">
                                <InputGroup className="form-group required">
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Label>{t('forms.price')}</Form.Label>
                                    <Form.Control type="text" required value={newTemplateFields['price'] || ""} onChange={(event) => { updateNewFields(event, "price"); }}/>
                                </InputGroup>
                            </div>
                            <div className="col-sm-4">
                                <Form.Group className="form-group required">
                                    <Form.Label>{t('forms.capacity')}</Form.Label>
                                    <Form.Control type="text" required value={newTemplateFields['capacity'] || ""} onChange={(event) => { updateNewFields(event, "capacity"); }}/>
                                </Form.Group>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-sm-8">
                                <SubmitButton className="btn">{t('setting_classes.create_template.create_template')}</SubmitButton>
                            </div>
                        </Row>
                    </Form>
                </div>
            </div>
            <div className="content-box">
                <div className="content-header">
                    <span className="content-title">{t('setting_classes.existing_templates.title')}</span>
                </div>
                <div className="content-body">
                    {
                        loadingTemplates &&
                            <div className="spinner-border text-secondary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                    }
                    {
                        _.map(existingTemplates, (template, i) =>
                            <div key={i}>
                                <Form onSubmit={(event) => updateTemplate(event, template.id)}>
                                    <Row>
                                        <div className="col-sm-4">
                                            <Form.Group className="form-group required">
                                                <Form.Label>{t('forms.event_name')}</Form.Label>
                                                <Form.Control type="text" required value={template.eventName} onChange={(event) => { updateExistingFields(event, template.id, "eventName"); }}/>
                                            </Form.Group>
                                        </div>
                                        <div className="col-sm-4">
                                            <Form.Group className="form-group">
                                                <Form.Label>{t('forms.description')}</Form.Label>
                                                <Form.Control type="text" value={template.description} onChange={(event) => { updateExistingFields(event, template.id, "description"); }}/>
                                            </Form.Group>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-sm-4">
                                            <InputGroup className="form-group required">
                                                <InputGroup.Text>$</InputGroup.Text>
                                                <Form.Label>{t('forms.price')}</Form.Label>
                                                <Form.Control type="text" value={template.price} onChange={(event) => { updateExistingFields(event, template.id, "price"); }}/>
                                            </InputGroup>
                                        </div>
                                        <div className="col-sm-4">
                                            <Form.Group className="form-group required">
                                                <Form.Label>{t('forms.capacity')}</Form.Label>
                                                <Form.Control type="text" value={template.capacity} required onChange={(event) => { updateExistingFields(event, template.id, "capacity"); }}/>
                                            </Form.Group>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-sm-12">
                                            <SubmitButton>{t('setting_classes.existing_templates.update_template')}</SubmitButton>
                                            <Button variant="danger" onClick={(event) => { deleteTemplate(event, template.id) }}>{t('setting_classes.existing_templates.delete_template')}</Button>
                                        </div>
                                    </Row>
                                </Form>
                                <hr/>
                            </div>
                        )
                    }
                </div>
            </div>
        </BaseContainer>
    );
}

export default SettingClasses;
