import '../../App.scss';
import '../../css/modals.scss';
import { getInvoiceCreationUrl, getInvoiceUpdateUrl, serverFetch } from '../../helpers/server';
import { BaseContext, currencyFormat } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Button, ButtonGroup, Row, Col, Table } from 'react-bootstrap';
import moment from 'moment';
const _ = require('lodash');

function ChooseAgreementMonthModal(props) {
    const { getApiUrl, facilityLink } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [initialFormFields, setInitialFormFields] = useState({});
    const [monthlyCharges, setMonthlyCharges] = useState([]);
    const [checkedMonths, setCheckedMonths] = useState([]);
    const navigate = useNavigate();

    const createInvoice = (data) => {
        console.log("The data is " + JSON.stringify(data));
        let bookings = [];
        _.each(checkedMonths, (month) => {
            bookings = _.concat(bookings, monthlyCharges[month]);
        })
        console.log("The bookings are " + bookings.length);
        navigate(getInvoiceCreationUrl(facilityLink, props.groupId, _.map(bookings, (b) => b.bookingId)))
    }

    useEffect(() => {
        const mcs = _.groupBy(props.bookingLineItems, (item) => moment(item.startTimeLocal).startOf('month').format("MMM, yyyy"))
        setMonthlyCharges(mcs);
        if (_.keys(mcs).length > 0) {
            setInitialFormFields({
                selectedMonth: _.keys(mcs)[0]
            });
        } else {
        }
    }, [props.bookingLineItems]);

    const updateChecked = (event) => {
        if (event.target.value === "checkall") {
            let newCheckedMonths = [];
            if (event.target.checked) {
                newCheckedMonths = _.keys(monthlyCharges);
                newCheckedMonths.push("checkall");
            }
            setCheckedMonths(newCheckedMonths);
        } else {
            const newCheckedMonths = [...checkedMonths];
            if (event.target.checked) {
                newCheckedMonths.push(event.target.value);
            } else {
                let index = newCheckedMonths.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedMonths.splice(index, 1);
                }
                index = newCheckedMonths.indexOf("checkall");
                if (index > -1) {
                    newCheckedMonths.splice(index, 1);
                }
            }
            setCheckedMonths(newCheckedMonths);
        }
    }

    if (!props.show) {
        return null;
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title><i className="fa fa-file"/> Create invoice</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm initialFormFields={initialFormFields} onSubmit={createInvoice}>
                <BaseModal.Body>
                    <Table hover className="skinny">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Date</th>
                                <th>Hours</th>
                                <th>Monthly Total</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            _.map(monthlyCharges, (items, mc) =>
                                <tr key={mc}>
                                    <td>
                                        <div className="checkbox check-success inline">
                                            <input type="checkbox" className="" value={mc} id={mc} name="month[]"  checked={checkedMonths.includes(mc)} onChange={ (event) => updateChecked(event) }/>
                                            <label htmlFor={mc}/>
                                        </div>
                                    </td>
                                    <td>{ mc } </td>
                                    <td>{ _.sumBy(items, 'hours').toFixed(2) }</td>
                                    <td>{ currencyFormat(_.sumBy(items, 'total')) }</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <Button variant="link" onClick={() => props.onClose()}>Close</Button>
                            <Button variant="success" type="submit">Create Invoice</Button>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default ChooseAgreementMonthModal;
