import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader'
import AddOrEditVenueModal from '../../components/modals/AddOrEditVenueModal'
import BaseContainer from '../../components/Container';
import DeleteButton from '../../components/DeleteButton';
import Notification from '../../components/Notification';
import TabHeader from '../../components/TabHeader';
import { serverFetch, serverDelete } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useParams } from "react-router-dom";
import { Button, Table, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function VenueList() {
    const { getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [venues, setVenues] = useState([]);
    const [venueToEdit, setVenueToEdit] = useState(null);
    const [showAddOrEditVenueModal, setShowAddOrEditVenueModal] = useState(false);

    useEffect(() => {
        document.title = "Venue List - " + getFacilityName();
    }, [facilityLink]);

    useEffect(() => {
        fetchData();
    }, [facilityLink]);

    const onModalClose = () => {
        fetchData(true);
        setShowAddOrEditVenueModal(false);
    }

    const fetchData = (skipCache = false) => {
        setLoading(true);
        serverFetch(getApiUrl('/venues'), { skipCache }).then((res) => {
            setVenues(res);
            setLoading(false);
        })
    }

    const addVenue = () => {
        setVenueToEdit(null);
        setShowAddOrEditVenueModal(true);
    }

    const editVenue = (event, type) => {
        event.preventDefault();
        setVenueToEdit(type);
        setShowAddOrEditVenueModal(true);
    }

    const deleteVenue = (venue) => {
        const deleteVenueUrl = getApiUrl(`/venues/${venue.id}`);
        serverDelete(deleteVenueUrl, {}).then((res) => {
            Notification.Show(t('venue.list.successfully_deleted'));
            fetchData(true);
        })
    }

    const tabItems = [{
        'label': t('tabs.venues.add'),
        'link': '/' + facilityLink + '/venue/list' + window.location.search,
    }];

    return (
        <BaseContainer>
            <TabHeader items={tabItems}/>
            <div className="content-box">
                <div className="content-body">
                    <Button variant="outline-primary" onClick={() => addVenue()}><i className="fa fa-plus-circle"></i> Add Venue</Button>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-lg-9">
                            <Loader loading={loading}>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Venue Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(venues, (venue, i) =>
                                                <tr key={i}>
                                                    <td>{venue.id}</td>
                                                    <td><Button variant="alink" onClick={(event) => editVenue(event, venue)}>{venue.name}</Button></td>
                                                    <td><DeleteButton onDelete={() => deleteVenue(venue)}/></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Loader>
                        </div>
                    </Row>
                </div>
            </div>
            <AddOrEditVenueModal show={showAddOrEditVenueModal} onClose={onModalClose} itemToEdit={venueToEdit} />
        </BaseContainer>
    );
}

export default VenueList;
