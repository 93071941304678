import React, { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/classes.scss';
import '../../css/table.scss';
import { getUrl, serverFetch, serverPost } from '../../helpers/server'
import {BaseContext, getDateFormatForFacility, currencyFormat} from '../../helpers/common'
import ClassesContainer from '../../components/classes/ClassesContainer';
import { useParams, useSearchParams } from "react-router-dom";
import { Table, Button, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from "moment/moment";
const _ = require("lodash");

function ClassesStep1(props) {
    const { settings, getApiUrl, getFacilityName } = useContext(BaseContext);
    const { t } = useTranslation('common');
    let { facilityLink } = useParams();
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [limit, setLimit] = useState(100);
    const [searchParams] = useSearchParams();
    const groupId = searchParams.get('groupId');

    useEffect(() => {
        document.title = "Classes - " + getFacilityName();
    }, []);

    const getQueryParams = () => {
        let tagStr = searchParams.get('tag') ? searchParams.get('tag') : searchParams.get('tags');
        return {
            dateFilter: {
                filterType: "future"
            },
            capacity: "all",
            sort: "startTimeAsc",
            tags: tagStr ? tagStr.split(',') : null,
        }
    }

    const onSearch = (restart = true) => {
        const searchUrl = getApiUrl('/classes/sessions/search');
        const data = {
            pagination: {
                from: restart ? 0 : (classes.length || 0),
                limit: limit
            },
            query: getQueryParams(),
        };
        if (restart) {
            setClasses([]);
        }
        serverPost(searchUrl, data, { ignoreAuth: true }).then((res) => {
            setHasMore(res.length >= limit);
            if (restart) {
                setClasses(res.results);
            } else {
                setClasses(_.concat(classes, res.results))
            }
        })
    }

    useEffect(() => {
        onSearch(true);
    }, [])

    const renderStatus = (row) => {
        const classUrl = '/' + facilityLink + '/registration/step-2?id=' + row.classData.id;
        if (row.classData.capacity <= row.spotsFilled) {
            // Class is full
            if (row.classData.allowWaitlist) {
                return <Button variant="warning" href={classUrl}>{t('classes.join_waitlist')}</Button>
            } else {
                return <Button variant="danger" href={classUrl}>{t('classes.list.full')}</Button>
            }
        } else {
            return <Button variant="primary" href={classUrl}>{t('classes.list.book_now')}</Button>
        }
    }

    const getClassCostDescription = (classDetails) => {
        if (classDetails.classData.priceType === "totalPrice") {
            return <span className="label">{currencyFormat(classDetails.classData.costInCents/100)}</span>
        } else {
            return <span className="label">{currencyFormat(classDetails.classData.costInCents/100)}/session</span>
        }
    }

    const dateFormat = getDateFormatForFacility(settings);
    return (
        <ClassesContainer>
            <Container className="classes-container">
                <Row>
                {
                    loading ?
                        <p>Loading classes...</p>
                    : !_.isEmpty(classes) ?
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Venue</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Capacity</th>
                                        <th>Price</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    _.map(classes, (cs, i) =>
                                        <tr key={cs.id}>
                                            <td><strong>{cs.classData.name}</strong></td>
                                            <td>{cs.venueName}</td>
                                            <td>{ moment(cs.startTime).format(dateFormat) }</td>
                                            <td>{ moment(cs.startTime).format("h:mma") + " - " + moment(cs.endTime).format("h:mma") }</td>
                                            <td><span className="label">{cs.spotsFilled} / {cs.classData.capacity}</span></td>
                                            <td>{ getClassCostDescription(cs) }</td>
                                            <td className="text-end">{ renderStatus(cs) }</td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        : <p className="alert alert-info text-start">There are no currently no available classes.</p>
                }
                </Row>
            </Container>
        </ClassesContainer>
    );

}

export default ClassesStep1;
