import '../App.scss';
import '../css/modals.scss';
import React, { useState, useEffect, useContext, createRef, forwardRef, useImperativeHandle } from 'react';
import { serverPost, serverFetch } from '../helpers/server';
import { BaseContext, getStripePublishableKeyForFacility } from '../helpers/common';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
const _ = require('lodash');

const StripeSetupPaymentMethodModal = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async onSubmit(data) {
            await onSubmit(data);
        },
        onFieldChange(name, value) {
            onFieldChange(name, value);
        }
    }));

    const { t } = useTranslation('common');
    const formRef = createRef();
    const { settings, getApiUrl } = useContext(BaseContext);
    const [paymentIntent, setPaymentIntent] = useState(null);

    useEffect(() => {
        if (props.group) {
            serverPost(getApiUrl(`/user_groups/${props.group.id}/payment_sources/save_intent`)).then((res) => {
                if (res) {
                    setPaymentIntent(res);
                }
            })
        }
    }, [props.group])

    const onFieldChange = (name, value) => {
    }

    const onSubmit = async (data) => {
        if (formRef.current) {
            await formRef.current.onSubmit(data);
        }
    }

    const publishableKey = getStripePublishableKeyForFacility(settings);
    const stripePromise = loadStripe(publishableKey);
    const options = paymentIntent && {
        clientSecret: paymentIntent.clientSecret,
        appearance: {
            theme: 'none',
            rules: {
                '.Input': {
                    border: '1px solid #C4C4C4',
                    padding: '11px'
                },
                '.Input::placeholder': {
                    color: '#21252950'
                },
                '.Input:focus': {
                    border: '1px solid #C4C4C4',
                    outline: 'none'
                },
                '.Input--invalid': {
                    border: '1px solid #D32F2F',
                    backgroundColor: '#FFEBEE'
                },
                '.Error': {
                    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue","Ubuntu", sans-serif',
                    color: '#D32F2F',
                    fontSize: "14px",
                    lineHeight: "22px",
                    fontWeight: "400",
                    marginTop: "0px"
                },
                '.Label': {
                    fontSize: "14px",
                    lineHeight: "22px",
                    fontWeight: "600",
                    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue","Ubuntu", sans-serif',
                    marginBottom: '4px',
                    color: "#474758"
                }
            }
        },
    };

    if (_.isNil(paymentIntent)) {
        return (<div className="spinner-border text-secondary"/>);
    } else {
        return (
            <Elements stripe={stripePromise} options={options}>
                <SetupForm ref={formRef} group={props.group} redirectUrl={props.redirectUrl}/>
            </Elements>
        )
    }
})

const SetupForm = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async onSubmit(data) {
            await onSubmit(data);
        },
    }));

    const { t } = useTranslation('common');
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);

    useEffect(() => {
        if (_.isNil(props.group)) {
            setRedirectUrl(null);
        }
    }, [props.group])

    useEffect(() => {
        setRedirectUrl(props.redirectUrl);
    }, [props.redirectUrl])

    const onSubmit = async (data) => {
        if (!stripe || !elements) {
            return;
        }

        setErrorMessage(null);
        const {error} = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: redirectUrl,
            },
        });

        if (error) {
            setErrorMessage(error.message);
        } else {

        }
    };

    return (
        <>
            <br/>
            <PaymentElement />
            {errorMessage && <div className="form-error-message">{errorMessage}</div>}
        </>
    )
})

export default StripeSetupPaymentMethodModal;
