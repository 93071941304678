import '../../App.scss';
import '../../css/modals.scss';
import React, { useEffect, useState, useContext } from 'react';
import BaseModal from './BaseModal';
import { useTranslation } from 'react-i18next';
import { downloadBlob, BaseContext } from '../../helpers/common';
import { Row, Col, Button } from 'react-bootstrap';
import { serverPost } from '../../helpers/server';
import moment from 'moment';
const _ = require("lodash");

function BaseCSVExportModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation(['common']);
    const [fields, setFields] = useState([]);
    const [allFields, setAllFields] = useState([]);
    const [items, setItems] = useState([]);
    const [criteria, setCriteria] = useState(null);
    const [selectedFields, setSelectedFields] = useState([]);

    useEffect(() => {
        setFields(props.fields);

        let _allFields = [];
        _.each(fields, (fs, j) => {
            _.each(fs.fields, (f, i) => {
                _allFields.push(f);
            })
        })
        setAllFields(_allFields);

        let preselectedFields = null
        if (props.cacheKey) {
            const storedList = localStorage.getItem(props.cacheKey);
            if (storedList) {
                preselectedFields = JSON.parse(storedList);
            }
        }
        if (!preselectedFields) {
            let newSelectedFields = _.map(_allFields, (f) => f.value);
            newSelectedFields = newSelectedFields.concat(["csvcheckall"]);
            preselectedFields = newSelectedFields;
        }
        setSelectedFields(preselectedFields);
    }, [props.fields])

    useEffect(() => {
        setItems(props.items);
    }, [props.items]);

    useEffect(() => {
        setCriteria(props.criteria);
    }, [props.criteria]);

    const updateChecked = (event) => {
        if (event.target.value === "csvcheckall") {
            let newSelectedFields = [];
            if (event.target.checked) {
                newSelectedFields = _.map(allFields, (f) => f.value);
                newSelectedFields = newSelectedFields.concat(["csvcheckall"]);
            }
            setSelectedFields(newSelectedFields);
        } else {
            const newSelectedFields = [...selectedFields];
            if (event.target.checked) {
                newSelectedFields.push(event.target.value);
            } else {
                let index = newSelectedFields.indexOf(event.target.value);
                if (index > -1) {
                    newSelectedFields.splice(index, 1);
                }
                index = newSelectedFields.indexOf("csvcheckall");
                if (index > -1) {
                    newSelectedFields.splice(index, 1);
                }
            }
            setSelectedFields(newSelectedFields);
        }
    }

    const onExport = (selectedFields) => {
        let filteredFields = [...selectedFields];
        _.pull(filteredFields, "csvcheckall");

        const originalFieldOrder = _.map(allFields, (f) => f.value);
        filteredFields = _.sortBy(filteredFields, (f) => _.indexOf(originalFieldOrder, f));

        if (props.cacheKey) {
            localStorage.setItem(props.cacheKey, JSON.stringify(selectedFields));
        }

        const filename = `${props.filenamePrefix}-${moment().format("MMM-DD-YYYY")}.csv`;
        const data = {
            format: "csv",
            fields: filteredFields,
        }
        if (props.useCriteria) {
            data['criteria'] = criteria;
        } else {
            data['forExport'] = {
                ids: _.map(items, (i) => i.id),
                ...props.extraItemsParams
            };
        }
        serverPost(getApiUrl(props.exportUrl), data, { noJson: true }).then((res) => {
            downloadBlob(res, filename);
        })
        props.onClose();
    }

    return (
        <BaseModal {...props} size="lg">
            <BaseModal.Header>
                <BaseModal.Title>{t('csv_export.title')}</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <Row>
                    <Col md="3">
                        <div className="checkbox check-success tall"><input type="checkbox" name="fields[]" id="csvcheckall" value="csvcheckall" checked={selectedFields.includes("csvcheckall")} onChange={ (event) => updateChecked(event) }/><label htmlFor="csvcheckall">Select All</label></div>
                    </Col>
                </Row>
                {
                    _.map(fields, (fs, j) =>
                        <React.Fragment key={j}>
                            {
                                fs.title &&
                                    <h6><b>{fs.title}</b></h6>
                            }
                            <Row>
                            {
                                _.map(fs.fields, (f, i) =>
                                    <Col md="3" key={i}>
                                        <div className="checkbox check-success tall"><input type="checkbox" name="fields[]" id={f.id} value={f.value} checked={selectedFields.includes(f.value)} onChange={ (event) => updateChecked(event) }/><label htmlFor={f.id}>{f.label}</label></div>
                                    </Col>
                                )
                            }
                            </Row>
                        </React.Fragment>
                    )
                }
            </BaseModal.Body>
            <BaseModal.Footer>
                <Row>
                    <Col md="12" className="text-end">
                        <Button variant="link" onClick={() => props.onClose()}>{t("common.close")}</Button>
                        <Button variant="primary" onClick={() => onExport(selectedFields)}>Export CSV</Button>
                    </Col>
                </Row>
            </BaseModal.Footer>
        </BaseModal>
    );

}

export default BaseCSVExportModal;
