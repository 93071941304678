import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import Notification from '../../components/Notification';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import BaseForm from '../../components/BaseForm';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import SubmitButton from '../../components/SubmitButton';
import { serverFetch, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function SettingInvAgr() {
    const { getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [settings, setSettings] = useState({});
    const [invoiceOlPay, setInvoiceOlPay] = useState(null);
    const [agreementOlPay, setAgreementOlPay] = useState(null);

    useEffect(() => {
        document.title = t('setting.inv_agr.page_title', { facilityName: getFacilityName() });
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl('')).then((res) => {
            setSettings(res);
        })
    }, []);

    const onFieldChange = (name, value) => {
        if (name === "invoiceOlPay") {
            setInvoiceOlPay(value);
        } else if (name === "agreementOlPay") {
            setAgreementOlPay(value);
        }
    }

    const updateSettings = (data) => {
        serverPatch(getApiUrl('/settings'), data).then((res) => {
            Notification.Show(t('notification.successfully_updated'));
        });
    }

    const invoiceAllowPaymentOptions = [
        { value: 'no', label: t("common.no") },
        { value: 'yes', label: t("common.yes") },
        { value: 'yesPartial', label: t("setting.inv_agr.yes_partial") },
        { value: 'yesWithFee', label: t("setting.inv_agr.yes_fee") },
        { value: 'yesPartialFee', label: t("setting.inv_agr.yes_partial_fee") },
    ];

    const agreementAllowPaymentOptions = [
        { value: 'no', label: t("common.no") },
        { value: 'yes', label: t("common.yes") },
        { value: 'yesPartial', label: t("setting.inv_agr.yes_partial") },
    ];

    const pdfOptions = [
        { value: "paymentAdvise", label: t("setting.inv_agr.pay_advice") },
        { value: "ccLink", label: t("setting.inv_agr.online_link") },
        { value: "showBothAdviseLink", label: t("setting.inv_agr.online_link_pay_advice") },
        { value: "CustomInvFoot", label: t("setting.inv_agr.custom_only") },
        { value: "linkAndCustom", label: t("setting.inv_agr.custom_link_custom") },
        { value: "AdviseAndCustom", label: t("setting.inv_agr.pay_advice_custom") },
        { value: "BlankInvFoot", label: t("setting.inv_agr.blank") },
    ];

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "inv-agr")}/>
            <BaseForm initialFormFields={settings} onFieldChange={onFieldChange} onSubmit={updateSettings}>
                <div className="content-box">
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan="6" type="file" name="logo" label={t("setting.inv_agr.invoice_logo")} fileType="invoice_logo" />
                            <BaseForm.Input colSpan="6" type="check" name="showAgrEventName" label={t("setting.inv_agr.show_event_name")} />
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-header">
                        <span className="content-title">{t("setting.inv_agr.invoice_options")}</span>
                    </div>
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan="6" type="text" name="invTitle" label={t("setting.inv_agr.invoice_title")} />
                            <BaseForm.Input colSpan="6" type="text" name="invPayCompany" label={t("setting.inv_agr.company_name")} />
                            <BaseForm.Input colSpan="4" type="select" name="invoicePdfOptions" label={t("setting.inv_agr.pdf_options")} options={pdfOptions} />
                            <BaseForm.Input colSpan="4" type="select" name="invoiceOlPay" label={t("setting.inv_agr.allow_INV")} options={invoiceAllowPaymentOptions} showSearch={false} />
                            {
                                (invoiceOlPay === "yesWithFee" || invoiceOlPay === "yesPartialFee") &&
                                    <BaseForm.Input colSpan="4" type="number" name="invoiceFee" label={t("setting.inv_agr.payment_fee")} rightContent="%"/>
                            }
                            <BaseForm.Input colSpan="4" type="check" name="useRawSubtotalForInvoice" label={t("setting.inv_agr.calculate_totals")} options={invoiceAllowPaymentOptions} showSearch={false} />
                            <BaseForm.Input colSpan="4" type="check" name="useAccountingCodesOnInvoice" label={t("setting.inv_agr.use_accounting_codes")} options={invoiceAllowPaymentOptions} showSearch={false} />
                        </Row>
                        <Row>
                            <strong>{t("setting.inv_agr.custom_payment_info")}</strong>
                            <BaseForm.Input colSpan="12" type="editor" name="customInvFooter"
                                tokens={[
                                    '{companyName}',
                                    '{address1}',
                                    '{groupName}',
                                    '{to}', //Change this to be more understandable ie: CustomerName
                                    '{customerEmail}',
                                    '{customerPhone}',
                                    '{customerAddress}'

                                ]}/>
                            <strong>{t("setting.inv_agr.custom_contract_info")}</strong>
                            <BaseForm.Input colSpan="12" type="editor" name="customInvFooterTwo"
                                tokens={[
                                    '{companyName}',
                                    '{address1}',
                                    '{groupName}',
                                    '{to}', //Change this to be more understandable ie: CustomerName
                                    '{customerEmail}',
                                    '{customerPhone}',
                                    '{customerAddress}'

                                ]}/>
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-header">
                        <span className="content-title">{t("setting.inv_agr.agreement_options")}</span>
                    </div>
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" name="agrTitle" label={t("setting.inv_agr.agreement_title")} />
                            <BaseForm.Input colSpan="4" type="check" name="hideMonthlyCharges" label={t("setting.inv_agr.hide_monthly")} />
                            <BaseOverlayTrigger content={"Choose if you like the relevant user to be logged in, in order to accept the agreement."} >
                                <Col md="4">
                                    <BaseForm.CheckGroup name="needsLoginToAcceptAgreement" label={<span>{t("setting.inv_agr.need_user_login")} <i className="fa fa-question-circle fa-small"/></span>} />
                                </Col>
                            </BaseOverlayTrigger>
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="4" type="select" name="agreementOlPay" label={t("setting.inv_agr.allow_AGR")} options={agreementAllowPaymentOptions} showSearch={false} />
                            {
                                agreementOlPay === "yesMinPercent" &&
                                    <BaseForm.Input colSpan="4" type="number" name="agreementMin" label="Minimum Fee Required" rightContent="%" required/>
                            }
                            {
                                agreementOlPay === "yesMinDollar" &&
                                    <BaseForm.Input colSpan="4" type="number" name="agreementMinDollar" label="Minimum Fee Required" leftContent="$" required/>
                            }
                            <strong>{t("setting.inv_agr.custom_agree")}</strong>
                            <BaseForm.Input colSpan="12" type="editor" name="customIAgree"
                                tokens={[
                                    '{companyName}',
                                    '{address1}',
                                    '{groupName}',
                                    '{to}', //Change this to be more understandable ie: CustomerName
                                    '{customerEmail}',
                                    '{customerPhone}',
                                    '{customerAddress}'
                                ]}/>
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-body">
                        <SubmitButton>{t('common.save')}</SubmitButton>
                    </div>
                </div>
            </BaseForm>
        </BaseContainer>
    );
}

export default SettingInvAgr;
