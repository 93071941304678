import '../../App.scss';
import '../../css/modals.scss';
import { useContext, useEffect, useState } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Row, Col, Button } from 'react-bootstrap';
import { serverPost } from '../../helpers/server';
import { BaseContext, downloadBlob } from '../../helpers/common';
import moment from 'moment';
const _ = require("lodash");

function InvoicesXeroExportModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [items, setItems] = useState([]);
    const [criteria, setCriteria] = useState(null);

    useEffect(() => {
        setItems(props.invoices);
    }, [props.invoices]);

    useEffect(() => {
        setCriteria(props.criteria);
    }, [props.criteria]);

    const onExport = (dataInfo) => {
        if (dataInfo !== undefined) {
            const filename = `${props.filenamePrefix}-${moment().format("MMM-DD-YYYY")}.csv`;
            const data = {}
    
            if (props.useCriteria) {
                data['criteria'] = criteria;
            } else {
                data['forExport'] = {
                    ids: _.map(items, (i) => i.id)
                };
            }
    
            for (const [key, value] of Object.entries(dataInfo)) {
                if (key !== "trackingName1") {
                    data[key] = value;
                } else {
                    data['trackingName'] = value;
                }
            }
            
            console.log("Exporting " + JSON.stringify(data));
    
            serverPost(getApiUrl("/invoices/xero_export"), data, { noJson: true }).then((res) => {
                downloadBlob(res, filename);
            })
            props.onClose();
        }
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Xero Export</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm onSubmit={onExport}>
            <BaseModal.Body>
                <Row>
                    <BaseForm.Input colSpan="4" type="text" name="accountCode" label="AccountCode" placeholder="Xero sales account #" />
                    <BaseForm.Input colSpan="4" type="text" name="taxType" label="TaxType" placeholder="Xero sales account #" />
                    <BaseForm.Input colSpan="4" type="text" name="trackingName1" label="TrackingName1" placeholder="Venue Type: Rink, Field, Court" />
                </Row>
            </BaseModal.Body>
            <BaseModal.Footer>
                <Row>
                    <Col md="12" className="text-end">
                        <Button variant="link" onClick={() => props.onClose()}>Close</Button>
                        <SubmitButton variant="primary">Export Xero</SubmitButton>
                    </Col>
                </Row>
            </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default InvoicesXeroExportModal;
