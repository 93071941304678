import $ from "jquery";
import '../App.scss';
import '../css/forms.scss';
import '../js/forms.js';
import React, {createRef} from 'react';
import classnames from 'classnames';
import { useState, useEffect, useRef, useMemo } from 'react';
import { Dropdown, FormControl, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
const _ = require("lodash");

function SingleSelectDropdown(props) {
    const [items, setItems] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [preselectedId, setPreselectedId] = useState(null);
    const idField = props.idField || 'id';
    const labelField = props.labelField || 'label';
    const searchRef = createRef();
    const { t } = useTranslation('common');
    const prefix = useMemo(() => {
	    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
	}, [])

    useEffect(() => {
        const pitems = [...props.items];
        if (props.showAll) {
            const allItem = {};
            allItem[idField] = _.isUndefined(props.allId) ? null : props.allId;
            allItem[labelField] = props.allLabel || t("common.all");
            pitems.unshift(allItem);
        }
        setItems(pitems);
    }, [props.items]);

    useEffect(() => {
        if (preselectedId) {
            props.onSelect(preselectedId);
        }
    }, [preselectedId]);

    useEffect(() => {
        setSelectedId(props.selectedId);
        if (!props.menuOnly && props.onSelect && (!_.isEqual(props.selectedId, selectedId))) {
            const sid = props.selectedId;
            setTimeout((originalSelectedId) => {
                setSelectedId((prevSelectedId) => {
                    if (originalSelectedId === prevSelectedId) {
                        setPreselectedId(prevSelectedId);
                    }
                    return prevSelectedId;
                })
            }, 100, sid);
        }
    }, [props.selectedId]);

    useEffect(() => {
        if (_.isEmpty(items)) {
            return;
        }
        setSelectedId((prevSelectedId) => {
            const selectedItem = _.find(items, (i) => ""+i[idField] === ""+prevSelectedId);
            setSelectedItem(selectedItem);
            return prevSelectedId;
        });
    }, [selectedId, items]);

    const onSelect = (eventKey, event) => {
        if (!props.menuOnly) {
            setSelectedId(eventKey);
        }
        if (props.onSelect) {
            props.onSelect(eventKey);
        }
    }

    const onToggle = (show, event) => {
        if (show) {
            setTimeout(() => {
                const currentTop = $('.dropdown-menu.show ul').scrollTop();
                const firstActive = $('.dropdown-menu.show .dropdown-item.active').first();
                if (firstActive.length > 0) {
                    const offset = firstActive.position().top;
                    $('.dropdown-menu.show ul').scrollTop(currentTop + offset - 100);
                }
                if (props.showSearch && searchRef.current != null) {
                    searchRef.current.focus();
                }
            }, 30);
        }
    }

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
                {
                    props.showSearch &&
                        <FormControl ref={searchRef} autoFocus className="my-1"
                            onChange={(e) => setValue(e.target.value)} value={value} />
                }
                <ul className="list-unstyled">
                {
                    React.Children.toArray(children).filter(
                    (child) =>
                        !value || child.props.value.toLowerCase().includes(value.toLowerCase()),)
                }
                </ul>
            </div>
        );
      },
    );
    const showLabel = !(props.hideLabel || _.isNil(props.label));

    return (
        <Dropdown align={props.align || "start"} onSelect={onSelect} onToggle={onToggle}
            className={classnames(props.className, (showLabel ? "inline": "") )} disabled={props.disabled || props.readOnly}>
            <Dropdown.Toggle variant="outline-primary" className="single" id={"dropdown-"+prefix} disabled={props.disabled || props.readOnly}>
                {
                    showLabel &&
                        <Form.Label>{props.label}</Form.Label>
                }
                {
                    props.toggleLabel ||
                        (selectedItem &&
                            <div className="simple-label">
                            {
                                selectedItem.icon &&
                                    <>
                                        <i className={"fa " + selectedItem.icon} style={selectedItem.style}/>
                                    </>
                            }
                            <span>&nbsp;</span>
                            {
                                (!props.hideLabelOnHeader || selectedItem[idField] === null) &&
                                    <span className={props.hideLabelWhenSmall ? "d-none d-md-inline-block": ""}>{ selectedItem[labelField] }</span>
                            }
                            </div>
                        )
                }
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu} className="single" disabled={props.disabled}>
            {
                items.map((item, key) => {
                    if (!item) {
                        return null;
                    } else if (item.divider) {
                        return <hr key={key}/>
                    } else {
                        return (
                            <Dropdown.Item key={key} eventKey={item[idField]} value={item[labelField]} active={item[idField]==selectedId} disabled={item.disabled}>
                                {
                                    item.icon &&
                                        <>
                                            <i className={"fa " + item.icon} style={item.style}/>
                                            <span> </span>
                                        </>
                                }
                                <span>{item[labelField]}</span>
                            </Dropdown.Item>
                        );
                    }
                })
            }
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default SingleSelectDropdown;
