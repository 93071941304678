import React, { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Notification from '../../components/Notification';
import BaseContainer from '../../components/Container';
import BaseForm from '../../components/BaseForm';
import TabHeader from '../../components/TabHeader';
import SubmitButton from '../../components/SubmitButton';
import {serverDelete, serverFetch, serverPatch, serverPost} from '../../helpers/server';
import { getFormattedName, BaseContext, hasAccess } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import {Table, Row, Col, Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SimpleModal from "../../components/modals/SimpleModal";
import ConfirmationButton from "../../components/ConfirmationButton";
import CopyableComponent from "../../components/CopyableComponent";
import DeleteButton from "../../components/DeleteButton";
const _ = require("lodash");

function APITokens() {
    const { getApiUrl, facilityLink, getFacilityName, userInfo } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [apiTokens, setApiTokens] = useState([]);
    const [newToken, setNewToken] = useState(null);
    const [showAddNewTokenModal, setShowAddNewTokenModal] = useState(false);
    const canUpdate = hasAccess("settings", userInfo, "update");

    useEffect(() => {
        document.title = t("setting.setting.api_tokens") + " - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl('/api_tokens'), { skipCache }).then((res) => {
            setApiTokens(res);
        })
    }

    const addNewToken = () => {
        console.log("Add new token");
        const tokenData = {
            name: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10),
            accessLevel: 3,
            customAdminConfig: {
                booking: "viewOnlyNoFinancials",
                customers: "viewOnlyNoPII"
            }
        };
        serverPost(getApiUrl('/api_tokens'), tokenData).then((res) => {
            if (res) {
                setNewToken(res.jwtToken);
                fetchData(true);
            }
        })
    }

    const onDeleteToken = (token) => {
        serverDelete(getApiUrl(`/api_tokens/${token.id}`)).then((res) => {
            fetchData(true);
        })
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "setting-admins")}/>
            <div className="content-box">
                <div className="content-body">
                    <div className="d-flex flex-row justify-content-end align-items-center">
                        <div className="flex-grow-1">
                            <h5>{t("setting.api_token.title")}</h5>
                            {/* <p style={{ maxWidth: "550px" }}>*These API tokens will provide read only access to customers and bookings. For further access, please contact support@rectimes.com for details.</p> */}
                            <p style={{ maxWidth: "550px" }}>{t("setting.api_token.description")}</p>
                        </div>
                        <ConfirmationButton
                            variant="primary" title={"Add new API Token?"}
                            body={""} onConfirm={addNewToken}
                            confirmationText={"I UNDERSTAND"}>
                            {t("common.add_new")}
                        </ConfirmationButton>
                    </div>
                    <br/>
                    {
                        newToken &&
                        <Row>
                            <Col md="10">
                                <strong>New token Generated</strong>
                                <br/>
                                <p>Here is the new token to access the API. Please store this in a safe place as it will not be shown again.</p>
                                <CopyableComponent value={newToken}/>
                            </Col>
                        </Row>
                    }
                    <br/>
                    <Row>
                        <Col md="6">
                            <Table hover className="skinny">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>{t("common.name")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    _.map(apiTokens, (token, i) =>
                                        <tr key={i}>
                                            <td>{ token.id }</td>
                                            <td>{ token.name }</td>
                                            <td width="1%">
                                                <DeleteButton onDelete={() => onDeleteToken(token)} />
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </div>
            <SimpleModal
                show={showAddNewTokenModal} onClose={setShowAddNewTokenModal} titleIcon="fa-question-circle"
                title={t('header.how_to_book')} content={<span>ABC</span>} />
        </BaseContainer>
    );
}

export default APITokens;
