import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import BaseForm from '../../components/BaseForm';
import DeleteButton from '../../components/DeleteButton';
import TabHeader from '../../components/TabHeader';
import Notification from '../../components/Notification';
import SubmitButton from '../../components/SubmitButton';
import { serverFetch, serverPost, serverDelete } from '../../helpers/server';
import { BaseContext, hasAccess, currencyFormat, getFormattedImageLink, getStripePublishableKeyForFacility } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { useParams, useSearchParams, Link, useNavigate } from "react-router-dom";
import { Table, Button, Row, Col, ButtonToolbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classnames from 'classnames';
import { loadStripe } from '@stripe/stripe-js';
const _ = require("lodash");

function InvoicePrintView() {
    const { userInfo, isLoggedIn, getApiUrl, getFacilityName, settings } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [ searchParams ] = useSearchParams();
    let uuid = searchParams.get('uuid');

    // For the payment processing results
    let name = searchParams.get('name');
    let invoiceFee = searchParams.get('fee');
    let processor = searchParams.get('processor');
    let paymentIntent = searchParams.get('payment_intent');
    let paymentIntentClientSecret = searchParams.get('payment_intent_client_secret');
    let redirectStatus = searchParams.get('redirect_status');

    const [groupId, setGroupId] = useState(null);
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [allPaymentMethods, setAllPaymentMethods] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [initialPaymentFormFields, setInitialPaymentFormFields] = useState({});
    const [taxRateMap, setTaxRateMap] = useState({});

    const showPaymentForm = hasAccess("invoices", userInfo, "update") && invoiceDetails.amountDue > 0 && !invoiceDetails.isCancel;
    const isInvoiceAdmin = hasAccess("invoices", userInfo);

    useEffect(() => {
        if (!_.isEmpty(invoiceDetails)) {
            document.title = `${invoiceDetails.group.name} (${invoiceDetails.invoiceNumber}) - ${getFacilityName()}`;

            const taxRateMap = invoiceDetails.lineItems.reduce((map, line) => {
                const key = line.taxLabel ? `${line.taxLabel} ${line.taxRate}%` : `${invoiceDetails.taxLabel} ${line.taxRate}%`;
                map[key] = (map[key] || 0) + line.taxAmount;
                return map;
            }, {});
            setTaxRateMap({...taxRateMap})
        } else {
            document.title = `Invoice - ${getFacilityName()}`;
        }
    }, [invoiceDetails]);

    useEffect(() => {
        fetchData();
    }, [uuid]);

    const fetchData = (skipCache = false) => {
        serverFetch(getApiUrl(`/invoices/${uuid}`), { skipCache }).then((res) => {
            setInvoiceDetails(res);
            setGroupId(res.group.id);
            setLoading(false);

            setInitialPaymentFormFields({
                'amount': Number(res.amountDue).toFixed(2),
                'paymentDate': moment().format("YYYY-MM-DD"),
                'paymentMethod': !_.isEmpty(paymentMethods) ? paymentMethods[0].paymentTag: null
            });
        });
    }

    useEffect(() => {
        if (_.isEmpty(invoiceDetails)) {
            return;
        }
        if (isLoggedIn) {
            serverFetch(getApiUrl(`/payment_methods`)).then((res) => {
                setAllPaymentMethods(res);
                setPaymentMethods(_.filter(res, (r) => r.isInvOption));
            });
        }
    }, [invoiceDetails, isLoggedIn]);

    useEffect(() => {
        uuid = searchParams.get('uuid')
    }, [searchParams]);

    const getPaymentLabelForPaymentMethod = (paymentMethod) => {
        const method = _.find(allPaymentMethods, (m) => m.paymentTag === paymentMethod);
        return !_.isNil(method) ? method.paymentType : null;
    }

    const paymentMethodOptions = _.map(paymentMethods, (m) => { return { 'id': m.paymentTag, 'label': _.replace(m.paymentType, "Paid ", ""), 'icon': m.paymentIcon }; });

    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        settings: settings,
        groupId: groupId,
        userGroup: invoiceDetails.group && {
            'groupName': invoiceDetails.group.name
        }
    };
    const tabs = getTabItems(t, facilityLink, "invoices", tabsParams);
    if (tabs[1]) {
        tabs[1].active = true;
    }

    let tableColumnWidths = {
        "hours": "10%",
    };
    if (settings.hideTaxColumn) {
        tableColumnWidths["tax"] = "0%";
        tableColumnWidths["rate"] = "15%";
        tableColumnWidths["amount"] = "15%";
    } else {
        tableColumnWidths["tax"] = "10%";
        tableColumnWidths["rate"] = "10%";
        tableColumnWidths["amount"] = "10%";
    }
    if (settings.showAgrEventName) {
        tableColumnWidths["item"] = "10%";
        tableColumnWidths["venue"] = "14%";
        tableColumnWidths["description"] = "22%";
        tableColumnWidths["event"] = "14%";
    } else {
        tableColumnWidths["item"] = "10%";
        tableColumnWidths["venue"] = "20%";
        tableColumnWidths["description"] = "30%";
        tableColumnWidths["event"] = "0%";
    }

    return (
        <div>
            <Loader loading={loading}>
                <div className="content-box">
                    <div className={classnames("content-body", "invoice-container1", invoiceDetails.isCancel ? "cancelled" : "")}>
                        <Row className="invoice-header">
                            <Col md="3">
                                <h2>{ settings.invTitle }</h2>
                                { invoiceDetails.group && <strong>{ invoiceDetails.group.name }<br/></strong> }
                                { invoiceDetails.to && <span>{ invoiceDetails.to }<br/></span> }
                                { invoiceDetails.group && invoiceDetails.group.email && <span>{ invoiceDetails.group.email }<br/></span> }
                                { invoiceDetails.group && invoiceDetails.group.phone && <span>{ invoiceDetails.group.phone }<br/></span> }
                            </Col>
                            <Col md="3">
                                {
                                    !_.isEmpty(invoiceDetails.address) &&
                                        <>
                                            <strong><div dangerouslySetInnerHTML={ { __html: invoiceDetails.address.companyName } } /></strong>
                                            <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.address1 } } />
                                            <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.address2 } } /><br/>
                                            <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.city } } />
                                            <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.state } } />
                                            <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.zipCode } } /><br/>
                                            <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.phone } } />
                                        </>
                                }
                            </Col>
                            <Col md="3">
                                <Row>
                                    <Col><strong>Invoice date:</strong></Col>
                                    <Col className="text-end">{moment(invoiceDetails.invoiceDate).format("MMM DD, YYYY")}</Col>
                                    <Col md="12" className="spacing"></Col>
                                    <Col><strong>Invoice No:</strong></Col>
                                    <Col className="text-end">{invoiceDetails.invoiceNumber}</Col>
                                    <Col md="12" className="spacing"></Col>
                                    {
                                        settings.showBusinessNumber &&
                                            <>
                                                <Col><strong>Business No:</strong></Col>
                                                <Col className="text-end">{settings.businessNumber}</Col>
                                                <Col md="12" className="spacing"></Col>
                                            </>
                                    }
                                    <Col><strong>Due date:</strong></Col>
                                    <Col className="text-end">{moment(invoiceDetails.dueDate).format("MMM DD, YYYY")}</Col>
                                </Row>
                            </Col>
                            <Col md="3" className="text-end">
                                <img alt="Company Logo" className="invoice-logo" src={getFormattedImageLink(settings.logo, "https://rectimes.app/uploads/logoinv/")} />
                            </Col>
                        </Row>
                        <br/>
                        <div className="table-wrapper">
                        <Table>
                            <thead>
                                <tr>
                                    <th width={tableColumnWidths["item"]}>Item</th>
                                    <th width={tableColumnWidths["venue"]}>Venue</th>
                                    <th width={tableColumnWidths["description"]}>Description</th>
                                    {
                                        settings.showAgrEventName &&
                                            <th width={tableColumnWidths["event"]}>Event</th>
                                    }
                                    <th width={tableColumnWidths["hours"]} className="text-end">Hours</th>
                                    <th width={tableColumnWidths["rate"]} className="text-end">Rate</th>
                                    {
                                        !settings.hideTaxColumn &&
                                            <th width={tableColumnWidths["tax"]} className="text-end">Tax</th>
                                    }
                                    <th width={tableColumnWidths["amount"]} className="text-end">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                _.map(invoiceDetails.lineItems, (item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td width={tableColumnWidths["item"]}>{item.item}</td>
                                            <td width={tableColumnWidths["venue"]}>{item.venueName}</td>
                                            <td width={tableColumnWidths["description"]}>
                                                <span>{item.description}</span>
                                            </td>
                                            {
                                                settings.showAgrEventName &&
                                                    <td width={tableColumnWidths["event"]}>{item.eventName}</td>
                                            }
                                            <td width={tableColumnWidths["hours"]} className="text-end">{item.hours}</td>
                                            <td width={tableColumnWidths["rate"]} className="text-end">{item.rateFormatted}</td>
                                            {
                                                !settings.hideTaxColumn &&
                                                    <td width={tableColumnWidths["tax"]} className="text-end">{item.taxRate}%</td>
                                            }
                                            <td width={tableColumnWidths["amount"]} className="text-end">{item.amountFormatted}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                        </div>
                        <Table borderless className="skinny">
                            <tbody>
                                {
                                    !settings.hideInvoiceTax &&
                                        <>
                                            <tr>
                                                <td width="50%"></td>
                                                <td width="40%" className="text-end">Subtotal</td>
                                                <td width="10%" className="text-end">{invoiceDetails.subTotalFormatted}</td>
                                            </tr>
                                            { _.map(taxRateMap, (taxAmount, taxLabel) => 
                                                <tr>
                                                    <td width="50%"></td>
                                                    <td width="40%" className="text-end">{taxLabel}</td>
                                                    <td width="10%" className="text-end">${taxAmount.toFixed(2)}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td width="50%"></td>
                                                <td width="40%" className="text-end">Total Tax</td>
                                                <td width="10%" className="text-end">{invoiceDetails.totalTaxFormatted}</td>
                                            </tr>
                                        </>
                                }
                                <tr>
                                    <td width="50%"></td>
                                    <td width="40%" className="text-end upline"><strong>Total</strong></td>
                                    <td width="10%" className="text-end upline"><strong>{invoiceDetails.totalFormatted}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table borderless className="skinny">
                            <tbody>
                            {
                                _.map(invoiceDetails.payments, (payment, i) =>
                                    <tr key={i}>
                                        <td width="90%" className="text-end">
                                            Less Payment {moment(payment.datePaid).format("MMM D, YYYY")} ({payment.paymentMethodName}{payment.reference ? `, ${payment.reference}` : ""})
                                        </td>
                                        <td width="10%" className="text-end"><strong>{payment.amountPaidFormatted}</strong></td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                        <Table borderless>
                            <tbody>
                                <tr>
                                    <td width="50%"></td>
                                    <td width="40%" className="text-end"><strong>Amount Due</strong></td>
                                    <td width="10%" className="text-end"><strong>{invoiceDetails.amountDueFormatted}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                        {
                            invoiceDetails.notes && (isLoggedIn || facilityLink === "wkhc") &&
                                <div className="invoice-subdetails">
                                    <strong>Notes:</strong>
                                    <div dangerouslySetInnerHTML={ { __html: invoiceDetails.notes } } />
                                </div>
                        }
                    </div>
                    <div>
                        <br/>
                        <img src="/images/cut-mark.jpeg" alt="cut" style={{ width: "100%" }}/>
                        <br/>
                        <div className="d-flex flex-row gap-3" style={{ padding: "10px" }}>
                            <div className="flex-grow-1">
                                <h2>PAYMENT ADVICE</h2>
                                <div className="d-flex flex-row gap-3" style={{ marginLeft: "30px" }}>
                                    <div>
                                        <span className="body1">To:</span>
                                    </div>
                                    <div>
                                        {
                                            !_.isEmpty(invoiceDetails.address) &&
                                                <>
                                                    <strong><div dangerouslySetInnerHTML={ { __html: invoiceDetails.address.companyName } } /></strong>
                                                    <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.address1 } } />
                                                    <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.address2 } } /><br/>
                                                    <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.city } } />
                                                    <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.state } } />
                                                    <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.zipCode } } /><br/>
                                                    <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.phone } } />
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <Table borderless>
                                    <tbody>
                                        <tr className="thin-horizontal">
                                            <td className="py-1"><span className="body2 no-stretch">Customer</span></td>
                                            <td className="py-1"><span className="body1">{ invoiceDetails.group && <strong>{ invoiceDetails.group.name }</strong>}</span></td>
                                        </tr>
                                        <tr className="thin-horizontal" style={{ borderBottom: "1px solid #e2e2e2" }}>
                                            <td className="py-1"><span className="body2 no-stretch">Invoice Number</span></td>
                                            <td className="py-1"><span className="body1">{ invoiceDetails.invoiceNumber }</span></td>
                                        </tr>
                                        <tr className="thin-horizontal">
                                            <td className="py-1"><span className="body2 no-stretch">Amount Due</span></td>
                                            <td className="py-1"><span className="body2">{ invoiceDetails.amountDueFormatted }</span></td>
                                        </tr>
                                        <tr className="thin-horizontal" style={{ borderBottom: "1px solid #e2e2e2" }}>
                                            <td className="py-1"><span className="body2 no-stretch">Due Date</span></td>
                                            <td className="py-1"><span className="body1">{ moment(invoiceDetails.dueDate).format("MMM DD, YYYY") }</span></td>
                                        </tr>
                                        <tr className="thin-horizontal">
                                            <td className="py-1"><span className="body2 no-stretch">Amount Enclosed</span></td>
                                            <td className="py-1"></td>
                                        </tr>
                                        <tr className="thin-horizontal">
                                            <td className="py-1"><span className="body2 no-stretch"></span></td>
                                            <td className="py-1"><div style={{ borderTop: "1px solid #e2e2e2", width: "100%", paddingTop: "3px" }} className="caption">Enter the amount you are paying above</div></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </Loader>
        </div>
    );
}

export default InvoicePrintView;
