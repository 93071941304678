import '../../App.scss';
import '../../css/modals.scss';
import $ from 'jquery';
import 'magicsuggest/magicsuggest.js';
import 'jquery-ui/ui/widgets/autocomplete';
import React, { useEffect, useState, useContext, createRef } from 'react';
import BookingGroupsSelector from '../BookingGroupsSelector';
import Notification from '../Notification';
import Loader from '../Loader';
import BookingRow from '../BookingRow';
import TabHeader from '../TabHeader';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import classnames from 'classnames';
import { useParams } from "react-router-dom";
import {Row, Container, Col, Image} from 'react-bootstrap';
import { serverFetch, serverPost, notifyEvent } from '../../helpers/server';
import {
    currencyFormat,
    BaseContext,
    getNameForDayOfWeek,
    getDescriptionForRepeatBookings,
    isFullAdmin
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const _ = require("lodash");

function CancelBookingModal(props) {
    const { getApiUrl, isLoggedIn, userInfo, settings } = useContext(BaseContext);
    const { facilityLink } = useParams();
    const [loading, setLoading] = useState(true);
    const [booking, setBooking] = useState({});
    const [notifyCustomer, setNotifyCustomer] = useState(false);
    const [initialFields, setInitialFields] = useState({});
    const [eventTypes, setEventTypes] = useState([]);
    const [currentTab, setCurrentTab] = useState("details");
    const [cancelRepeatType, setCancelRepeatType] = useState("no");
    const { t } = useTranslation('common');
    const bookingRowRef = createRef();

    useEffect(() => {
        if (!props.show) {
            return;
        }
        if (_.isNil(props.booking)) {
            return;
        }
        setBooking(props.booking);
        setInitialFields({
            ...props.booking,
            taxRatePercent: props.booking.taxPercentage,
            startTime: moment(props.booking.startTimeLocal).format("HH:mm:00"),
            endTime: moment(props.booking.endTimeLocal).format("HH:mm:00"),
            repeatStartDate: moment(props.booking.startTimeLocal).format("YYYY-MM-DD"),
            repeatEndDate: moment(props.booking.startTimeLocal).add(7, 'days').format("YYYY-MM-DD"),
            updateInvoice: true,
            updateAgreement: true,
            costDescription: `${currencyFormat(props.booking.preCost)} + ${currencyFormat(props.booking.totalTax)} = ${currencyFormat(props.booking.preCost + props.booking.totalTax)}`,
            pushToPartner: true
        });
        setLoading(false);
        serverFetch(getApiUrl('/event_types', { venueIds: [props.booking.venueId] }), { skipAddingCategory: true }).then((res) => {
            setEventTypes(res);
        });
    }, [props.booking]);

    useEffect(() => {
        if (!props.show) {
            setCurrentTab("details");
        }
    }, [props.show]);

    const repeatTypeOptions = [
        { 'value': "no", 'label': 'No' },
        { 'value': "all", 'label': 'All' },
        { 'value': "following", 'label': 'Following' },
        { 'value': "previous", 'label': 'Previous' },
        { 'value': "dateRange", 'label': 'Date Range' },
    ];

    const cancelBooking = (bookingData) => {
        const data = {
            updateInvoice: bookingData.updateInvoice,
            updateAgreement: bookingData.updateAgreement,
            notifyCustomer: bookingData.notifyCustomer,
            note: bookingData.note,
            modifyRepeatConfig: {
                option: bookingData.repeatType || "no",
                dateStartTimeLocal: bookingData.repeatStartDate && moment(bookingData.repeatStartDate).utcOffset(0, true),
                dateEndTimeLocal: bookingData.repeatStartDate && moment(bookingData.repeatEndDate).endOf('day').utcOffset(0, true)
            }
        }
        if (!_.isNil(data.pushToPartner)) {
            bookingData['pushToPartner'] = data.pushToPartner
        }
        if (!_.isNil(data.notifyPartnerTeam)) {
            bookingData['notifyPartnerTeam'] = data.notifyPartnerTeam
        }
        serverPost(getApiUrl(`/bookings/${booking.id}/cancel`), data).then((res) => {
            if (res) {
                props.onClose(true);
                Notification.Show("Booking successfully cancelled.");
            }
        });

        notifyEvent("bookings");
    }

    const getRepeatDescription = (repeatInfo) => {
        if (_.isNil(repeatInfo)) {
            return "No Repeats"
        }

        let frequency = "";
        if (repeatInfo.repeatType === "week") {
            frequency = "Weekly"
        } else if (repeatInfo.repeatType === "month") {
            frequency = "Monthly"
        } else if (repeatInfo.repeatType === "day") {
            frequency = "Daily"
        }

        const dayStrings = [];
        if (repeatInfo.repeatOn) {
            const days = repeatInfo.repeatOn;
            _.map(_.range(7), r => {
                if (_.includes(days, String(r))) {
                    dayStrings.push(getNameForDayOfWeek(t, r))
                }
            })
        }
        let repeats = dayStrings.join(", ")

        let repeatTill = "";
        if (repeatInfo.ends === 3) {
            repeatTill = `Until ${moment(repeatInfo.endDate).format("ddd, D MMMM, YYYY")}`;
        } else if (repeatInfo.ends === 2) {
            repeatTill = `for ${repeatInfo.occurrence} times.`;
        }

        return `${frequency} on ${repeats}, ${repeatTill}`;
    }

    const onFieldChange = (name, value) => {
        if (name === "repeatType") {
            setCancelRepeatType(value);
        } else if (name === "notifyCustomer") {
            setNotifyCustomer(value);
        }

        if (_.includes(["venueId", "startTime", "endTime", "eventTypeId"], name)) {
            if (bookingRowRef.current) {
                bookingRowRef.current.onFieldChange(name, value);
            }
        }
    }

    const paddingSpan = 3;
    const tabItems = [
        { value: "details", label: "Details", icon: "fa-book", active: currentTab === "details", onClick: () => setCurrentTab("details") },
        { value: "repeat", label: "Repeat", icon: classnames("fa-retweet", booking.isRepeat ? "fa-c-success": ""), active: currentTab === "repeat", onClick: () => setCurrentTab("repeat") },
        { value: "notes", label: "Notes", icon: "fa-comment", active: currentTab === "notes", onClick: () => setCurrentTab("notes") },
    ];

    const rightTabItems = [];
    if (!_.isEmpty(booking.invoice)) {
        rightTabItems.push({ label: booking.invoice.invoiceNumber, icon: "fa-file", link: `/${facilityLink}/invoice/view?uuid=${booking.invoice.uuid}` });
    }
    if (!_.isEmpty(booking.agreement)) {
        rightTabItems.push({ label: booking.agreement.agreementNumber, icon: "fa-pencil-square fa-large", link: `/${facilityLink}/agreement/accept?uuid=${booking.agreement.uuid}` });
    }

    const isPartnerOrSuperAdmin = settings.isPartnerFacility && (userInfo.isSuperAdmin || isFullAdmin(userInfo));
    const showPartnerActions = !_.isEmpty(booking.partnerMapping) && isPartnerOrSuperAdmin;
    return (
        <BaseModal {...props} size="lg">
        <Loader loading={loading}>
        { () =>
            <BaseForm initialFormFields={initialFields} onSubmit={cancelBooking} onFieldChange={onFieldChange}>
                <BaseModal.Header>
                    <BaseModal.Title>Cancel Booking #{booking.id} - <span id="pop-date" className="pop-date">{moment(booking.startTimeLocal).format("ddd, MMM DD, YYYY")}&nbsp;&nbsp;<i className="fa fa-calendar"/></span></BaseModal.Title>
                </BaseModal.Header>
                <TabHeader items={tabItems} rightItems={rightTabItems}/>
                <BaseModal.Body>
                    <div className={classnames(currentTab === "details" ? "": "hide")}>
                        <BookingGroupsSelector name="bookingGroups" disabled={true} showGroupDetailsList={true} />
                        <BookingRow ref={bookingRowRef} eventTypes={eventTypes} disabled={true} showAllTimes={true} isAdminBooking={true} />
                    </div>
                    <Row className={classnames(currentTab === "repeat" ? "": "hide")}>
                        <div className="repeat-info">{ getDescriptionForRepeatBookings(t, booking.repeatInfo) }</div>
                        {
                            booking.isRepeat &&
                                <BaseForm.Input colSpan="12" name="repeatType" label="Cancel Repeats" type="select" options={repeatTypeOptions} showSearch={false} />
                        }
                    </Row>
                    {
                        cancelRepeatType === "dateRange" &&
                            <Row className={classnames(currentTab === "repeat" ? "": "hide")}>
                                <BaseForm.Input colSpan="6" name="repeatStartDate" label="Start Date" type="date" />
                                <BaseForm.Input colSpan="6" name="repeatEndDate" label="End Date" type="date" />
                            </Row>
                    }
                    <Row className={classnames(currentTab === "notes" ? "": "hide")}>
                        <BaseForm.Input colSpan="12" name="note" className="large" label={t('forms.notes')} type="textarea" placeholder={t('Private admin information about this booking')} />
                        {
                            cancelRepeatType !== "no" &&
                                <BaseForm.Input colSpan="6" name="updateLinkedAndRepeatNotes" type="check"
                                    label={<span>Update Repeat <i className="fa fa-retweet"/> and Linked <i className="fa fa-link"/> booking notes</span>} />
                        }
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Container>
                        {
                            showPartnerActions &&
                                <Row>
                                    <BaseForm.Input
                                        colSpan="5" name="pushToPartner"  type="check"
                                        label={<span>
                                            <Image src={"/images/la_logo.png"} width="15px" style={{marginRight: "5px"}}/> { "Sync to LeagueApps" }
                                        </span>}
                                    />
                                    <BaseForm.Input
                                        colSpan="5" name="notifyPartnerTeam"  type="check"
                                        label={<span>
                                            <Image src={"/images/la_logo.png"} width="15px" style={{marginRight: "5px"}}/> Notify Team Members
                                        </span>}
                                    />
                                </Row>
                        }
                        <Row>
                            {
                                !_.isEmpty(booking.invoice) &&
                                    <BaseForm.Input colSpan="3" type="check" label={<span><i className="fa fa-file"/> {t("booking_modal.update_invoice")}</span>} name="updateInvoice" />
                            }
                            {
                                !_.isEmpty(booking.agreement) &&
                                    <BaseForm.Input colSpan="3" type="check" label={<span><i className="fa fa-pencil-square"/> {t("booking_modal.update_agreement")}</span>} name="updateAgreement" />
                            }
                            {
                                notifyCustomer &&
                                    <BaseForm.Input type="text" name="message" label="Message to Customer" placeholder="Reason for cancellation"/>
                            }
                        </Row>
                        <Row>
                            <Col md="1"/>
                            <Col md="3"/>
                            <BaseForm.Input colSpan="2" name="notifyCustomer" label={t('Notify')} type="check" />
                            <BaseForm.Input colSpan="4" name="costDescription" label="Total Cost" type="text" readOnly={true} />
                            <Col md="2" className="d-flex align-items-center">
                                <SubmitButton variant="danger" className="btn-complete"><i className="fa fa-trash"/> Cancel</SubmitButton>
                            </Col>
                        </Row>
                    </Container>
                </BaseModal.Footer>
            </BaseForm>
        }
        </Loader>
        </BaseModal>
    );

}

export default CancelBookingModal;
