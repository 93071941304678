import $ from "jquery";
import 'jquery-ui/ui/widgets/sortable';
import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import AddOrEditPaymentMethodModal from '../../components/modals/AddOrEditPaymentMethodModal';
import TabHeader from '../../components/TabHeader';
import { serverFetch, serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
const _ = require("lodash");

function PaymentMethodList() {
    const { getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [initialSortedItems, setInitialSortedItems] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethodToEdit, setPaymentMethodToEdit] = useState(null);
    const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);

    useEffect(() => {
        document.title = "Payment Methods - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (loading) {
            return;
        }
        $(function() {
            $('#sortable-table').sortable({
                items:'tr',
                cursor: 'grabbing',
                stop: function(event, tbody) {
                    const sortedItems = $(this).sortable("toArray");
                    if (JSON.stringify(sortedItems) !== JSON.stringify(initialSortedItems)) {
                        updatePaymentMethodSort(sortedItems);
                    }
                },
                helper: function(e, tr) {
                    var $originals = tr.children();
                    var $helper = tr.clone();
                    $helper.children().each(function(index) {
                        // Set helper cell sizes to match the original sizes
                        $(this).width($originals.eq(index).width());
                    });
                    return $helper;
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialSortedItems, loading]);

    const fetchData = (skipCache = false) => {
        setLoading(true);
        serverFetch(getApiUrl('/payment_methods'), { skipCache }).then((res) => {
            setPaymentMethods(res);
            if (!_.isEmpty(res)) {
                setInitialSortedItems(res.map(r => r.id));
            } else {
                setInitialSortedItems([]);
            }
            setLoading(false);
        })
    }

    const updatePaymentMethodSort = (orderedItems) => {
        const data = {
            'ids': orderedItems
        };
        serverPost(getApiUrl('/payment_methods/sort'), data).then((res) => {
            fetchData(true);
        })
    }

    const hidePaymentMethod = (method) => {
        serverPost(getApiUrl(`/payment_methods/${method.id}/hide`)).then((res) => {
            fetchData(true);
        })
    }

    const showPaymentMethod = (method) => {
        serverPost(getApiUrl(`/payment_methods/${method.id}/show`)).then((res) => {
            fetchData(true);
        })
    }

    const addPaymentMethod = () => {
        setPaymentMethodToEdit(null);
        setShowAddPaymentMethodModal(true);
    }

    const editPaymentMethod = (account) => {
        setPaymentMethodToEdit(account);
        setShowAddPaymentMethodModal(true);
    }

    const onModalClose = () => {
        fetchData(true);
        setShowAddPaymentMethodModal(false);
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "payment-methods")}/>
            <div className="content-box">
                <div className="content-body">
                    <Button variant="outline-primary" onClick={() => addPaymentMethod()}><i className="fa fa-plus-circle"></i> {t('setting.payment_methods.add')}</Button>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Loader loading={loading}>
                        <Row>
                            <Col md="12">
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t('setting.payment_methods.payment_method')}</th>
                                            <th className="text-center">{t('setting.payment_methods.mark_as_paid')}</th>
                                            <th className="text-center">{t('setting.payment_methods.shows_on')}</th>
                                            <th className="text-center">{t('setting.payment_methods.default_method')}</th>
                                            <th className="text-center">{t('setting.payment_methods.show_method')}</th>
                                            <th className="text-center">{t('setting.payment_methods.order')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody id="sortable-table">
                                        {
                                            _.map(paymentMethods, (method, i) =>
                                                <tr key={i} className={classnames(method.isHide && "de-highlighted")} id={method.id}>
                                                    <td><i className="fa fa-arrows-v" aria-hidden="true"></i></td>
                                                    <td><Button variant="alink" onClick={() => editPaymentMethod(method)}><i className={method.paymentIcon === 'fa fa-money' ? 'fa fa-money-bill' : method.paymentIcon}/> {method.paymentType}</Button></td>
                                                    <td className="text-center">{ method.isPayment ? <i className="fa fa-check-circle fa-c-success fs-16"></i> : <span className="label">No</span> }</td>
                                                    <td className="text-center">
                                                        <BaseOverlayTrigger content={method.isInvOption ? "Shows on Invoices": null}>
                                                            <i className={classnames("fa", "fa-file", method.isInvOption ? "fa-c-success": "fa-unselected")}></i>
                                                        </BaseOverlayTrigger>
                                                        <span> / </span>
                                                        <BaseOverlayTrigger content={method.isAgrOption ? "Shows on Agreements": null}>
                                                            <i className={classnames("fa", "fa fa-pencil-square", method.isAgrOption ? "fa-c-success": "fa-unselected")}></i>
                                                        </BaseOverlayTrigger>
                                                        <span> / </span>
                                                        <BaseOverlayTrigger content={method.isClassOption ? "Shows on Classes": null}>
                                                            <i className={classnames("fa", "fa fa-user", method.isClassOption ? "fa-c-success": "fa-unselected")}></i>
                                                        </BaseOverlayTrigger>
                                                    </td>
                                                    <td className="text-center">{ method.isDefault ? <i className="fa fa-check-circle fa-c-success fs-16"></i> : <span className="label">No</span> }</td>
                                                    <td className="text-center">{ method.isHide ? <span className="label">No</span>: <i className="fa fa-check-circle fa-c-success fs-16"></i> }</td>
                                                    <td className="text-center">{method.sort}</td>
                                                    <td className="text-center">
                                                    {
                                                        method.isHide ?
                                                            <Button variant="primary" onClick={() => showPaymentMethod(method)}><i className="fa fa-eye"/> Show</Button>
                                                        : <Button variant="danger" onClick={() => hidePaymentMethod(method)}><i className="fa fa-eye-slash"/> Hide</Button>
                                                    }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Loader>
                </div>
            </div>
            <AddOrEditPaymentMethodModal show={showAddPaymentMethodModal} onClose={onModalClose} itemToEdit={paymentMethodToEdit}/>
        </BaseContainer>
    );
}

export default PaymentMethodList;
