import '../App.scss';
import {getUrl, serverFetch, subscribeToEvent} from '../helpers/server'
import { BaseContext } from '../helpers/common'
import { useEffect, useState, useContext } from 'react';
import { Outlet } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

function RequirePartnerFacility(props) {
    const { getApiUrl, userInfo } = useContext(BaseContext);
    const navigate = useNavigate();
    let { facilityLink } = useParams();
    const [loadingSettings, setLoadingSettings] = useState(true);
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState(null);
    const [authorized, setAuthorized] = useState(null);
    const isLoggedIn = localStorage.getItem("auth_token") !== null;

    useEffect(() => {
        fetchData();
        subscribeToEvent("settings", (data) => { fetchData(true) });
    }, [facilityLink]);

    useEffect(() => {
        if (isLoggedIn) {
            if (!loadingSettings) {
                setAuthorized(settings.isPartnerAdminFacility);
            }
        } else {
            setAuthorized(false);
        }
    }, [isLoggedIn, settings, loadingSettings]);

    useEffect(() => {
        if (authorized !== null) {
            if (!authorized) {
                navigate('/' + facilityLink);
            } else {
                setLoading(false);
            }
        }
    }, [authorized]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(''), { skipCache }).then((res) => {
            setSettings(res);
            setLoadingSettings(false);
        })
    }

    return (
      <>
      {
        !loading && <Outlet />
      }
      </>
    );
}

export default RequirePartnerFacility;
