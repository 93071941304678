import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import EditEmailModal from '../../components/modals/EditEmailModal';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import { serverFetch, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function EmailList() {
    const { userInfo, getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [emails, setEmails] = useState([]);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [showEditSMSModal, setShowEditSMSModal] = useState(false);

    useEffect(() => {
        document.title = "System Email - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (skipCache = false) => {
        setLoading(true);
        serverFetch(getApiUrl('/emails'), { skipCache }).then((res) => {
            setEmails(res);
            setLoading(false);
        })
    }

    const onModalClose = () => {
        fetchData(true);
        setShowEditSMSModal(false);
    }

    const editSMS = (event, sms) => {
        event.preventDefault();
        setItemToEdit(sms);
        setShowEditSMSModal(true);
    }

    const onFieldChange = (name, value) => {
        const index = parseInt(name.split(".")[0]);
        const emailId = emails[index].id;
        serverPatch(getApiUrl(`/emails/${emailId}`), { enabled: value }).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
            }
        });
    }

    const tabsParams = {
        isSuperAdmin: userInfo.isSuperAdmin
    };
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "email-sms", tabsParams)} />
            <div className="content-box">
                <div className="content-body">
                    <BaseForm initialFormFields={emails} onFieldChange={onFieldChange}>
                        <Row>
                            <Col md="9">
                                <Loader loading={loading}>
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>{t('setting.email_sms.title')}</th>
                                                <th>{t('setting.email_sms.subject')}</th>
                                                <th>{t('setting.email_sms.enabled')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                _.map(emails, (item, i) =>
                                                    <tr key={i} id={item.id}>
                                                        <td className="skinny"><Button variant="alink" onClick={(event) => editSMS(event, item)}>{item.title}</Button></td>
                                                        <td className="skinny">{item.subject}</td>
                                                        <td className="skinny">
                                                            <BaseForm.Check className="lg-switch" name={`${i}.enabled`}/>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Loader>
                            </Col>
                        </Row>
                    </BaseForm>
                </div>
            </div>
            <EditEmailModal show={showEditSMSModal} onClose={onModalClose} itemToEdit={itemToEdit} />
        </BaseContainer>
    );
}

export default EmailList;
