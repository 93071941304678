import '../../App.scss';
import '../../css/modals.scss';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function AddOrEditAccountModal(props) {
    const { t } = useTranslation('common');

    return (
        <BaseAddOrEditItemModal
            {...props}
            itemLabel={"Account"}
            itemBaseUrl={'/accounts'}
        >
            <Row>
                <BaseForm.Input colSpan="6" name="account" label={t('forms.account')} type="text" required />
                <BaseForm.Input type="select" colSpan="6" idField={"id"} name="parentAccountId" label={t('forms.parent_account')} options={props.options} showSearch={true} showAll={false}/>
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditAccountModal;
