import { useEffect, useState, useContext, useRef } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import Loader from '../../components/Loader';
import DeleteButton from '../../components/DeleteButton';
import { serverFetch, serverPost, serverPatch, serverDelete } from '../../helpers/server';
import {BaseContext, downloadBlob, getDateFormatForFacility, getDateFormatWithTimeForFacility,
    currencyFormat} from '../../helpers/common';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { Row, Col, Table, Button, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classnames from 'classnames';
import AddClassParticipantModal from "../../components/modals/AddClassParticipantModal";
import ParticipantPaymentsModal from "../../components/modals/ParticipantPaymentsModal";
import RemoveParticipantModal from "../../components/modals/RemoveParticipantModal";
import BaseForm from "../../components/BaseForm";
import Notification from "../../components/Notification";
import Select from 'react-select/async-creatable';
import classNames from 'classnames';
import MoveOffWaitlistParticipantModal from '../../components/modals/MoveOffWaitlistParticipantModal';
const _ = require("lodash");

function ClassesListParticipants() {
    const navigate = useNavigate();
    const { getApiUrl, getFacilityName, settings } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const [ searchParams ] = useSearchParams();
    const [participants, setParticipants] = useState(null);
    const [classDetails, setClassDetails] = useState({});
    const [currentSessionId, setCurrentSessionId] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [formFields, setFormFields] = useState([]);
    const [selectedParticipantIds, setSelectedParticipantIds] = useState([]);
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
    const [addParticipantToWaitlist, setAddParticipantToWaitlist] = useState(false);
    const [showParticipantPaymentsModal, setShowParticipantPaymentsModal] = useState(false);
    const [showRemoveParticipantModal, setShowRemoveParticipantModal] = useState(false);
    const [showMoveParticipantModal, setShowMoveParticipantModal] = useState(false);
    const [tags, setTags] = useState([]);
    const [isModifyingTags, setIsModifyingTags] = useState(false);
    const [hasPaymentOption, setHasPaymentOption] = useState(false);
    const classId = searchParams.get('id');
    const preselectedSessionId = searchParams.get('s');
    const selectRef = useRef(null);

    useEffect(() => {
        document.title = `Class Participants - ${getFacilityName()}`;
    }, []);

    useEffect(() => {
        fetchClassDetails();
    }, [classId]);

    useEffect(() => {
        fetchParticipants(true);
    }, [facilityLink, classId, currentSessionId]);

    useEffect(() => {
        setSelectedParticipants(_.filter(participants, (b) => selectedParticipantIds.includes(b.id)));
    }, [selectedParticipantIds]);

    const fetchClassDetails = (skipCache=false) => {
        serverFetch(getApiUrl(`/classes/${classId}`), { skipCache }).then((res) => {
            setClassDetails(res);
            setHasPaymentOption(res.paymentOptions !== "none");
            if (res.form) {
                const fields = [];
                _.each(res.form.sections, (s) => {
                    _.each(s.fields, (f) => {
                        fields.push(f);
                    });
                });
                setFormFields(fields);
            } else {
                setFormFields([]);
            }
            setTags(res.tags);
            setSessions(res.sessions);
            if (preselectedSessionId) {
                setCurrentSessionId(preselectedSessionId);
            } else if (res.sessions.length > 0) {
                setCurrentSessionId(res.sessions[0].id);
            }
        })
    }

    const fetchParticipants = (skipCache=false) => {
        if (!currentSessionId) {
            return;
        }
        serverFetch(getApiUrl(`/classes/${classId}/sessions/${currentSessionId}/participants`), { skipCache }).then((res) => {
            setParticipants(res);
        })
    }

    const updateClass = (data) => {
        serverPatch(getApiUrl(`/classes/${classId}`), data).then((res) => {
            if (res) {
                Notification.Show("Updated successfully");
                setClassDetails(res)
            }
        })
    }

    const exportClassParticipantsCSV = () => {
        const filename = `${"Class-Participants-Report"}-${moment().format("MMM-DD-YYYY")}.csv`;
        serverPost(getApiUrl(`/classes/${classId}/sessions/${currentSessionId}/participants/export`), { bookingId: currentSessionId }, { noJson: true }).then((res) => {
            downloadBlob(res, filename);
        })
    }

    const addParticipant = (addToWaitlist) => {
        setAddParticipantToWaitlist(addToWaitlist);
        setShowAddParticipantModal(true);
    }

    const deleteParticipant = (participant) => {
        setSelectedParticipant(participant);
        setShowRemoveParticipantModal(true);
    }

    const moveParticipant = (participant) => {
        setSelectedParticipant(participant);
        setShowMoveParticipantModal(true);
    }

    const editClass = () => {
        navigate(`/${facilityLink}/class/update?id=${classDetails.id}`);
    }

    const cancelClass = () => {
        console.log("Cancelling class " + classDetails.id);
        serverDelete(getApiUrl(`/classes/${classDetails.id}`)).then((res) => {
            if (res) {
                Notification.Show("Successfully deleted class");
                fetchClassDetails(true);
            }
        });
    }

    const onParticipantPaymentsSelected = (participant) => {
        setSelectedParticipant(participant);
        setShowParticipantPaymentsModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowAddParticipantModal(false);
        setShowParticipantPaymentsModal(false);
        setShowRemoveParticipantModal(false);
        setShowMoveParticipantModal(false);
        if (didUpdate) {
            fetchParticipants(true);
            fetchClassDetails(true);
        }
    }

    const getFormResponseForParticipant = (fieldName, participant) => {
        let fieldValue = null;
        _.each(participant.responses, (res) => {
            if (res.formField.name === fieldName) {
                fieldValue = res.data;
            }
        })
        return fieldValue;
    }

    const onFieldChange = (name, value) => {
        if (name === "hideFromPublic") {
            updateClass({ hideFromPublic: value })
        }
    }

    const onLinkClick = () => {
        prompt("Copy to clipboard: Ctrl+C, Enter", `${window.location.origin}/${facilityLink}/registration/step-2?id=${classDetails.id}`);
    }

    const priceTypeMap = {
        'totalPrice': 'Total Price For All Sessions',
        'pricePerSession': 'Price Per Session'
    };

    const tabItems = [{
        'label': t('tabs.class.list'),
        'link': '/' + facilityLink + '/class/list',
    }, {
        'label': classDetails.name,
        active: true
    }]

    const sessionTabItems = _.map(sessions, (session, i) => {
        return {
            label: `Session ${i+1}`,
            onClick: () => setCurrentSessionId(String(session.id)),
            active: String(session.id) === String(currentSessionId)
        }
    });

    const onChange = (value, meta) => {
        const action = meta.action;
        let selectedOptions = [];
        if (!_.isEmpty(action)) {
            selectedOptions = [...value];
        } else {
            return;
        }
        let selectedTags = [];
        _.each(selectedOptions, (s) => {
            selectedTags.push(_.trim(s.label));
        });
        selectedTags = _.uniq(selectedTags);
        console.log(selectedTags)
        setTags(selectedTags);
    }

    const saveTags = () => {
        serverPatch(getApiUrl(`/classes/${classId}`), { tags: tags }).then((res) => {
            if (res) {
                Notification.Show("Updated successfully");
                setClassDetails(res);
                setTags(res.tags);
                setIsModifyingTags(false);
            }
        })
    }

    const isValidNewOption = (inputValue, value, options, accessors) => {
        if (_.trim(inputValue) === "" || tags.includes(_.trim(inputValue))) {
            return false;
        }
        return true;
    }

    const getTagsDisplay = () => {
        return isModifyingTags ? (
            <Row>
                <Col md="4">
                    <div className={classNames("form-group", "input-group", "inline")}>
                        <Select
                            ref={selectRef}
                            isMulti
                            isValidNewOption={isValidNewOption}
                            defaultValue={_.uniq(tags.map((t) => _.trim(t))).map((t) => { return { label: t, value: t } })}
                            isClearable={false}
                            className="select-container"
                            classNamePrefix="select2"
                            formatCreateLabel={(inputValue) => { return `Create tag "${inputValue}"` }}
                            onChange={(value, meta) => onChange(value, meta)}
                            placeholder="Start adding tags..."
                        />
                    </div>
                </Col>
                <Col md="8" className={classNames("d-flex", "flex-row", "align-items-center")}>
                    <Button className={classNames("d-flex", "flex-row", "align-items-center")} size="sm" onClick={() => saveTags()}><i className='fa fa-floppy-disk' /></Button>
                </Col>
            </Row>
        ) : (
            <Row>
                <Col md="12">
                    <div className="d-flex flex-row gap-6">
                        {
                            _.map(tags, (tag, i) => {
                                return (
                                    <Badge className='my-2' bg='info' key={i}>{tag}</Badge>
                                )
                            })
                        }
                        {
                            tags.length !== 0 ?
                                <Button variant='link' onClick={() => setIsModifyingTags(true)}><i className='fa fa-edit' /> Manage Tags</Button>
                                : <Button variant='link' onClick={() => setIsModifyingTags(true)}><i className='fa fa-plus' /> Add Tags</Button>
                        }
                    </div>
                </Col>
            </Row>
        );
    }

    const dateWithTimeFormat = getDateFormatWithTimeForFacility(settings);
    const dateFormat = getDateFormatForFacility(settings);
    const totalColumns = formFields.length + 5;
    const endLeftColumn = totalColumns >= 12 ? 12 : totalColumns;
    const endRightColumn = totalColumns >= 12 ? totalColumns - 12 : 0;

    const registeredParticipants = _.filter(participants, participant => !participant.isWaitlisted)
    const waitlistedParticipants = _.filter(participants, participant => participant.isWaitlisted)

    return (
        <BaseContainer>
            <TabHeader items={tabItems}/>
            <div className="content-box">
                <div className="content-body">
                    <div className="pull-right">
                            <div className="d-flex flex-row gap-12 justify-content-end align-items-center">
                                <BaseForm initialFormFields={classDetails} onFieldChange={onFieldChange}>
                                    <BaseForm.Input colSpan={12} name={'hideFromPublic'} label={"Hide From Public"} type={'check'} formClassName={"borderless inline"}/>
                                </BaseForm>
                                <div>
                                    <Button variant="outline-primary" onClick={editClass}><i className="fa fa-edit"></i> {t('common.edit')}</Button>
                                    <DeleteButton
                                        size="md" onDelete={cancelClass}
                                        title={"Confirm Cancel?"}
                                        body={"Are you sure you want to cancel the class?"}
                                    />
                                </div>
                            </div>
                        
                        </div>
                    <Loader loading={classDetails.id === undefined}>
                    {
                        () =>
                            <div>
                                {getTagsDisplay()}
                                <br />
                                {
                                    classDetails.bannerURL &&
                                        <div>
                                            <img style={{ objectFit: "cover", maxHeight: "200px" }} src={classDetails.bannerURL} />
                                        </div>
                                }
                                <table className="descriptionTable">
                                    <tbody>
                                        <tr>
                                            <td><b>ID:</b></td>
                                            <td>{classDetails.id}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Name:</b></td>
                                            <td>{classDetails.name}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Description:</b></td>
                                            <td dangerouslySetInnerHTML={{ __html: classDetails.description }}></td>
                                        </tr>
                                        <tr>
                                            <td><b>Price Type:</b></td>
                                            <td>{priceTypeMap[classDetails.priceType]}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Cost:</b></td>
                                            <td>{currencyFormat(classDetails.costInCents / 100)}</td>
                                        </tr>
                                        <tr>
                                            <td style={{width: '150px'}}><b>Registration Link:</b></td>
                                            <td>
                                                {window.location.origin}/{facilityLink}/registration/step-2?id={classDetails.id}
                                                <i className="fa fa-copy" aria-hidden="true" onClick={onLinkClick} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <br/>
                                <b style={{fontSize: '14px'}}>Sessions</b>
                                <Row>
                                    <Col md="8">
                                        <Table>
                                            <tbody>
                                            {
                                                _.map(sessions, (session, i) =>
                                                    <tr key={i} className={classnames(String(session.id) === String(currentSessionId) ? "active": "")}>
                                                        <td><strong>{i+1}</strong></td>
                                                        <td>{session.venueName}</td>
                                                        <td>{ moment(session.startTimeLocal).format(dateFormat) }, { moment(session.startTimeLocal).format("h:mma") + " - " + moment(session.endTimeLocal).format("h:mma") }</td>
                                                        <td><span className="label">{session.spotsFilled}/{classDetails.capacity}</span></td>
                                                        <td><Button onClick={() => setCurrentSessionId(String(session.id))}><i className="fa fa-users"/></Button></td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                    }
                    </Loader>
                </div>
            </div>
            {
                sessions.length > 1 &&
                    <TabHeader items={sessionTabItems}/>
            }
            <div className="content-box">
                <div className="content-body">
                    <div className="d-flex flex-row align-items-center">
                        <div className="flex-grow-1">
                            <h5>Participants</h5>
                        </div>
                        <div className="flex-grow-0 d-flex gap-6">
                            <Button variant="primary" onClick={() => addParticipant(false)}><i className="fa fa-circle-plus"></i> {t('common.add')}</Button>
                            <Button variant="outline-primary" id="csvBtn" onClick={() => exportClassParticipantsCSV()}><i className="fa fa-download"></i> {t('common.export')}</Button>
                        </div>
                    </div>
                </div>
                <div className="content-body overflow-auto">
                    {
                        (classDetails.id !== undefined && participants !== null) ?
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>

                                        </th>
                                        {hasPaymentOption && <th>{t('registration.class_list.paid')}</th>}
                                        <th>{t('registration.class_list.date_registered')}</th>
                                        <th>{t('registration.class_list.name')}</th>
                                        <th>{t('registration.class_list.email')}</th>
                                        {
                                            _.map(formFields, (field, i) =>
                                                 <th key={i}>{ field.name }</th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.map(registeredParticipants, (participant, i) =>
                                            <tr key={i}>
                                                <td className="no-stretch">
                                                    <Button variant="danger" size="sm" onClick={() => deleteParticipant(participant)}><i className="fa fa-trash"/></Button>
                                                </td>
                                                {
                                                    hasPaymentOption &&
                                                    <td className="no-stretch">
                                                        <div style={{ cursor: "pointer" }} onClick={() => onParticipantPaymentsSelected(participant)}>
                                                            {
                                                                participant.isPaid ?
                                                                    <span className="label label-success">Yes</span>
                                                                    : <span className="label">No</span>
                                                            }
                                                        </div>
                                                    </td>
                                                }
                                                <td>{ moment(participant.createdAt).format(dateFormat) }</td>
                                                <td>{ participant.userInfo.name }</td>
                                                <td>{ participant.userInfo.emailId }</td>
                                                {
                                                    _.map(formFields, (field, i) => {
                                                        let fieldValue;
                                                        if (field.name in participant) {
                                                            fieldValue = participant[field.name];
                                                        } else if (field.name === "name") {
                                                            fieldValue = participant.enteredName;
                                                        } else if (field.name === "email") {
                                                            fieldValue = participant.enteredEmail;
                                                        } else if (field.name === "phone") {
                                                            fieldValue = participant.enteredPhone;
                                                        } else {
                                                            fieldValue = getFormResponseForParticipant(field.name, participant)
                                                        }
                                                        return <td key={i}>{fieldValue}</td>;
                                                    })
                                                }
                                            </tr>
                                        )
                                    }
                                    <tr>
                                        <td colSpan={endLeftColumn} className="center">
                                            <div className="label">{t('registration.class_list.end_of_participants')}</div>
                                        </td>
                                        {
                                            endRightColumn > 0 &&
                                                <td colSpan={endRightColumn} className="center"></td>
                                        }
                                    </tr>
                                </tbody>
                            </Table>
                        : <div className="spinner-border text-secondary" role="status"><span className="visually-hidden">Loading...</span></div>
                    }
                </div>
            </div>

            {
                classDetails.allowWaitlist && 
                <div className="content-box">
                <div className="content-body">
                    <div className="d-flex flex-row align-items-center">
                        <div className="flex-grow-1">
                            <h5>Waitlist</h5>
                        </div>
                        <div className="flex-grow-0 d-flex gap-6">
                            <Button variant="primary" onClick={() => addParticipant(true)}><i className="fa fa-circle-plus"></i> Add to Waitlist</Button>
                            <Button variant="outline-primary" id="csvBtn" onClick={() => exportClassParticipantsCSV()}><i className="fa fa-download"></i> {t('common.export')}</Button>
                        </div>
                    </div>
                </div>
                <div className="content-body overflow-auto">
                    {
                        (classDetails.id !== undefined && participants !== null) ?
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>

                                        </th>
                                        <th>{t('classes.move')}</th>
                                        {hasPaymentOption && <th>{t('registration.class_list.paid')}</th>}
                                        <th>{t('registration.class_list.date_registered')}</th>
                                        <th>{t('registration.class_list.name')}</th>
                                        <th>{t('registration.class_list.email')}</th>
                                        {
                                            _.map(formFields, (field, i) =>
                                                 <th key={i}>{ field.name }</th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.map(waitlistedParticipants, (participant, i) =>
                                            <tr key={i}>
                                                <td className="no-stretch">
                                                    <Button variant="danger" size="sm" onClick={() => deleteParticipant(participant)}><i className="fa fa-trash"/></Button>
                                                </td>
                                                <td className="no-stretch">
                                                    <Button variant="primary" size="sm" onClick={() => moveParticipant(participant)}><i className="fa fa-exchange-alt"/></Button>
                                                </td>
                                                
                                                {
                                                    hasPaymentOption &&
                                                    <td className="no-stretch">
                                                        <div style={{ cursor: "pointer" }} onClick={() => onParticipantPaymentsSelected(participant)}>
                                                            {
                                                                participant.isPaid ?
                                                                    <span className="label label-success">Yes</span>
                                                                    : <span className="label">No</span>
                                                            }
                                                        </div>
                                                    </td>
                                                }
                                                <td>{ moment(participant.createdAt).format(dateFormat) }</td>
                                                <td>{ participant.userInfo.name }</td>
                                                <td>{ participant.userInfo.emailId }</td>
                                                {
                                                    _.map(formFields, (field, i) => {
                                                        let fieldValue;
                                                        if (field.name in participant) {
                                                            fieldValue = participant[field.name];
                                                        } else if (field.name === "name") {
                                                            fieldValue = participant.enteredName;
                                                        } else if (field.name === "email") {
                                                            fieldValue = participant.enteredEmail;
                                                        } else if (field.name === "phone") {
                                                            fieldValue = participant.enteredPhone;
                                                        } else {
                                                            fieldValue = getFormResponseForParticipant(field.name, participant)
                                                        }
                                                        return <td key={i}>{fieldValue}</td>;
                                                    })
                                                }
                                            </tr>
                                        )    
                                    }
                                    <tr>
                                        <td colSpan={13} className="center">
                                            <div className="label">{t('registration.class_list.end_of_participants')}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        : <div className="spinner-border text-secondary" role="status"><span className="visually-hidden">Loading...</span></div>
                    }
                </div>
            </div>
            }
            
            <AddClassParticipantModal show={showAddParticipantModal} onClose={onModalClose} classDetails={classDetails} addToWaitlist={addParticipantToWaitlist} />
            <ParticipantPaymentsModal show={showParticipantPaymentsModal} onClose={onModalClose} participant={selectedParticipant} />
            <RemoveParticipantModal show={showRemoveParticipantModal} onClose={onModalClose} participant={selectedParticipant} />
            <MoveOffWaitlistParticipantModal show={showMoveParticipantModal} onClose={onModalClose} participant={selectedParticipant} />
        </BaseContainer>
    );
}

export default ClassesListParticipants;
