import '../../App.scss';
import '../../css/modals.scss';
import { useState, useEffect } from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row, InputGroup, Col } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
const _ = require("lodash");

function AddOrEditMediaScreenModal(props) {
    const { t } = useTranslation('common');
    const [venues, setVenues] = useState([]);

    useEffect(() => {
        const adjustedVenues = [...props.venues];
        _.each(adjustedVenues, (v) => {
            v.id = String(v.id);
        });
        setVenues(adjustedVenues);
    }, [props.venues]);

    const colorOptions = [
        { value: 'alert-info', label: t('colors.blue')},
        { value: 'alert-success', label: t('colors.green')},
        { value: 'alert-warning', label: t('colors.yellow')},
        { value: 'alert-danger', label: t('colors.red')},
    ];

    return (
        <BaseAddOrEditItemModal
            {...props}
            size="lg"
            itemLabel={"Media Screen"}
            itemBaseUrl={'/mediascreens'}
        >
            <Row>
                <BaseForm.Input colSpan="6" name="screenName" label={t('setting.media_screen.screen_name')} type="text" required />
                <BaseForm.Input colSpan="6" name="rinkId" label={t('common.venues')} type="multi-select" options={venues} labelField="name" valueAsCommaSeparated={true} defaultSelectAll={true} />
                <BaseForm.Input colSpan="6" name="noOfBookings" label={t('setting.media_screen.form.show_num_bookings')} type="text" required />
                <BaseForm.Input colSpan="6" name="msStacked" label={t('setting.media_screen.form.stack_multiple_venues')} type="check" />
                <BaseForm.Input colSpan="6" name="mshideTopBar" label={t('setting.media_screen.form.hide_top_bar')} type="check" />
            </Row>
            <br/>
            <p><strong>{t('setting.media_screen.form.custom_messages_title')}</strong></p>
            <Row>
                <BaseForm.Input colSpan="6" name="msTopMsg" label={t('setting.media_screen.form.top_message')} placeholder="If empty, no message is displayed" type="text" />
                <BaseForm.Input colSpan="3" name="msTopMsgColor" label={t('setting.media_screen.form.top_color')} type="select" options={colorOptions} />
                <BaseForm.Input colSpan="6" name="msBottomMsg" label={t('setting.media_screen.form.bottom_message')} placeholder="If empty, no message is displayed" type="text" />
                <BaseForm.Input colSpan="3" name="msBottomMsgColor" label={t('setting.media_screen.form.bottom_color')} type="select" options={colorOptions} />
                <BaseForm.Input colSpan="4" name="msMsgFS" label={t('setting.media_screen.form.message_font_size')} placeholder="If empty, standard size will apply" type="text" />
                <BaseForm.Input colSpan="4" name="msMsgLH" label={t('setting.media_screen.form.message_line_height')} placeholder="If empty, standard size will apply" type="text" />
            </Row>
            <br/>
            <p><strong>{t('setting.media_screen.form.ads_title')}</strong></p>
            <Row>
                <BaseForm.Input colSpan="6" name="bottomImage" label={t('setting.media_screen.form.bottom_ad')} placeholder="If empty, no message is displayed" type="file" fileType="mediascreen_ads" />
            </Row>
            <br/>
            <p><strong>{t('setting.media_screen.form.custom_column_title')}</strong></p>
            <Row>
                <BaseForm.Input colSpan="3" name="groupOne" label={t('setting.media_screen.form.group_1')} type="text" />
                <BaseForm.Input colSpan="3" name="roomOne" label={t('setting.media_screen.form.room_1')} type="text" />
                <BaseForm.Input colSpan="3" name="groupTwo" label={t('setting.media_screen.form.group_2')} type="text" />
                <BaseForm.Input colSpan="3" name="roomTwo" label={t('setting.media_screen.form.room_2')} type="text" />
                <Col md="3">
                <BaseForm.InputGroup>
                    <BaseForm.Control type="text" name="msTitleFS" label={t('setting.media_screen.form.label_font_size')}/>
                    <InputGroup.Text>px</InputGroup.Text>
                </BaseForm.InputGroup>
                </Col>
                <Col md="3">
                <BaseForm.InputGroup>
                    <BaseForm.Control type="text" name="msTimeFS" label={t('setting.media_screen.form.group_time_font_size')}/>
                    <InputGroup.Text>px</InputGroup.Text>
                </BaseForm.InputGroup>
                </Col>
            </Row>
            <span><i>Note: If the room names are empty, those columns will not be shown in the media screen.</i></span>

        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditMediaScreenModal;
